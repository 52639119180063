import React, { useState, memo, useEffect } from 'react';
import { Input, Tag, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from '../../../styles/index.module.scss';

const ComplexList = memo(({ list, copyId, loading }) => {
    let [items] = useState(list || null);
    let [items_data, set_items_data] = useState(null);

    useEffect(() => {
        set_items_data(list || null);
    }, [list]);

    const search = (word_val) => {
        let newItems = items.filter((x) =>
            x.name
                .toLowerCase()
                .startsWith(word_val ? word_val.toLowerCase() : '')
        );

        return set_items_data(newItems);
    };

    return (
        <div className={styles.log_list_apart_complex}>
            <div className={styles.formBlock}>
                <Input
                    size="large"
                    placeholder="Введите название жилого комплекса"
                    onChange={(val) => {
                        search(val.target.value);
                    }}
                    className={styles.log_list_apart_complex_input}
                />
            </div>

            {loading ? (
                <div className={styles.loadingBlock}>
                    <LoadingOutlined />
                </div>
            ) : items_data && items_data.length ? (
                <div className={styles.itemsRelaestateBlock}>
                    {items_data.map((item, index) => {
                        return (
                            <section
                                className={styles.item_apart_complex}
                                key={item?.uuid}
                            >
                                <Tooltip title={item?.address}>
                                    <p>
                                        #{index + 1}. {item?.name}
                                    </p>
                                </Tooltip>

                                <span>
                                    ID:{' '}
                                    <Tooltip title="Кликните, чтобы скопировать id">
                                        <Tag onClick={() => copyId(item?.uuid)}>
                                            {item?.uuid}
                                        </Tag>
                                    </Tooltip>
                                </span>
                            </section>
                        );
                    })}
                </div>
            ) : (
                <h4>Информации не найдено</h4>
            )}
        </div>
    );
});

export default ComplexList;
