import React, { useEffect, useState } from 'react';
import { api } from '@/Services';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles/index.module.scss';
import Item from './helpers/item';
import { Button, Input, Select, Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Search } = Input;

const LibraryComp = () => {
    const dispatch = useDispatch();
    const stateLibrary = useSelector((state) => state.library);

    let [page, set_page] = useState(1);
    let [text_search, set_text_search] = useState('');
    let [shop, set_shop] = useState('');

    useEffect(() => {
        dispatch(api.library.products(page, text_search, true));
        dispatch(api.library.shops());
    }, []);

    const moreProducts = (clearState = false) => {
        if (clearState) {
            page = 1;
            set_page(page);
        } else {
            page = page + 1;
            set_page(page);
        }

        return dispatch(
            api.library.products(page, text_search, clearState, shop)
        );
    };

    return (
        <div className={styles.mainBlock}>
            <div className={styles.header}>
                <h2 className="title-page">Товары</h2>
                <div className={styles.headerRight}>
                    <Link to="/library/products/criteria">Критерии</Link>
                    <Link to="/library/products/create">Добавить товар</Link>
                </div>
            </div>

            <div className={styles.filter}>
                <Select
                    loading={stateLibrary?.request}
                    mode="multiple"
                    className={styles.select}
                    placeholder="Выберите магазин"
                    onChange={(value) => {
                        set_shop(value);
                    }}
                    optionLabelProp="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label.toLowerCase() || '').includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label || '')
                            .toLowerCase()
                            .localeCompare((optionB?.label || '').toLowerCase())
                    }
                    size="large"
                    dropdownStyle={{
                        zIndex: 9999,
                    }}
                >
                    {stateLibrary?.shops && stateLibrary?.shops.length ? (
                        stateLibrary?.shops.map((item) => (
                            <Option
                                key={item?._id}
                                className={styles.optionStyle}
                                value={item?._id}
                                label={item?.title.toLowerCase()}
                            >
                                <b>{item?.title}</b>{' '}
                            </Option>
                        ))
                    ) : (
                        <></>
                    )}
                </Select>
                <Search
                    className={styles.searchInput}
                    placeholder="Поиск по названию товара, артикулу"
                    enterButton="Найти"
                    size="large"
                    loading={stateLibrary?.request}
                    onChange={(value) => set_text_search(value.target.value)}
                    onSearch={() => moreProducts(true)}
                />
            </div>

            <div className={styles.contentWrapper}>
                {stateLibrary?.products && stateLibrary?.products.length ? (
                    <div className={styles.content}>
                        {stateLibrary?.products.map((item) => (
                            <Item
                                api={api}
                                dispatch={dispatch}
                                key={`${item?._id}_${Math.random()}`}
                                item={item}
                            />
                        ))}
                    </div>
                ) : (
                    <></>
                )}

                {!stateLibrary?.products.length && !stateLibrary?.request ? (
                    <div className={styles.extraBlock}>
                        <Result
                            status="404"
                            title="Товаров не найдено"
                            subTitle="Ничего не было найдено. Попробуйте поискать что-то другое"
                            extra={null}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>

            {stateLibrary?.products.length && (
                <div className={styles.buttonMore}>
                    {stateLibrary?.request ? (
                        <Button type="primary">
                            <LoadingOutlined />
                        </Button>
                    ) : (
                        <Button
                            onClick={() => moreProducts(false)}
                            type="primary"
                        >
                            Показать еще
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default LibraryComp;
