import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import styles from '../styles/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';
import { useParams, Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

import Companies from './helpers/companies';

const Leads = () => {
    const [type_tab, set_type_tab] = useState('companies');

    const { id } = useParams();

    const dispatch = useDispatch();
    const stateRealtor = useSelector((state) => state.users);

    useEffect(() => {
        dispatch(api.users.getCompanies(id, type_tab));
    }, [type_tab]);

    return (
        <div className={styles.pageLeads}>
            <h1 className="title-page">
                Управление компаниями (риэлтор:{' '}
                {stateRealtor?.info_user_company?.settings?.company_name ? (
                    <Link
                        to={`/realtors/edit/${stateRealtor?.info_user_company?._id}`}
                    >
                        {
                            stateRealtor?.info_user_company?.settings
                                ?.company_name
                        }
                        <LinkOutlined style={{ fontSize: 15, marginLeft: 2 }} />
                    </Link>
                ) : (
                    'Не опредено'
                )}
                )
            </h1>

            <div className={styles.content}>
                <Tabs onTabClick={(key) => set_type_tab(key)}>
                    <Tabs.TabPane tab="Компании риэлтора" key="companies">
                        <Companies
                            type_tab={type_tab}
                            loading={stateRealtor?.realtor_leads_request}
                            data={stateRealtor?.realtor_companies}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Подарочные компании" key="gift">
                        <Companies
                            type_tab={type_tab}
                            loading={stateRealtor?.realtor_leads_request}
                            data={stateRealtor?.realtor_companies}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default Leads;
