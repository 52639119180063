import { __assign, __extends } from "tslib";
import { deepMix, isString, substitute } from '@antv/util';
import { modifyCSS } from '@antv/dom-util';
import DomStyles from './theme';
import { CONTAINER_CLASS, CONTAINER_TPL, ITEM_TPL, LIST_CLASS, TITLE_CLASS } from './constants';
import { Component } from '../core/component';
import { clearDom } from '../utils/dom';
var Tooltip = /** @class */ (function (_super) {
    __extends(Tooltip, _super);
    function Tooltip() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 获取默认配置
     */
    Tooltip.prototype.getDefaultOptions = function () {
        return deepMix({}, _super.prototype.getDefaultOptions.call(this), {
            id: 'l7plot-tooltip',
            name: 'l7plot-tooltip',
            title: '',
            showTitle: true,
            items: [],
            containerTpl: CONTAINER_TPL,
            itemTpl: ITEM_TPL,
            domStyles: DomStyles,
            className: CONTAINER_CLASS,
        });
    };
    /**
     * 初始化 container
     */
    Tooltip.prototype.initContainer = function () {
        var customContent = this.options.customContent;
        if (customContent) {
            var container = this.getHtmlContentNode(customContent);
            var parentContainer = this.getParentContainer();
            if (parentContainer) {
                parentContainer.appendChild(container);
            }
            return container;
        }
        else {
            return _super.prototype.initContainer.call(this);
        }
    };
    /**
     * 初始化 DOM
     */
    Tooltip.prototype.initDom = function () {
        this.cacheDoms();
    };
    /**
     * 初始化事件
     */
    Tooltip.prototype.initEvent = function () {
        //
    };
    /**
     * 清理事件
     */
    Tooltip.prototype.removeEvent = function () {
        //
    };
    /**
     * 缓存 DOM
     */
    Tooltip.prototype.cacheDoms = function () {
        var container = this.container;
        var titleDom = container.getElementsByClassName(TITLE_CLASS)[0];
        var listDom = container.getElementsByClassName(LIST_CLASS)[0];
        this.titleDom = titleDom;
        this.listDom = listDom;
    };
    /**
     * 绘制组件
     */
    Tooltip.prototype.render = function () {
        if (this.options.customContent) {
            this.renderCustomContent(this.options.customContent);
        }
        else {
            this.resetTitle();
            this.renderItems();
        }
    };
    /**
     * 显示
     */
    Tooltip.prototype.show = function () {
        var container = this.container;
        if (!container || this.destroyed)
            return;
        modifyCSS(container, {
            visibility: 'visible',
        });
    };
    /**
     * 隐藏
     */
    Tooltip.prototype.hide = function () {
        var container = this.container;
        if (!container || this.destroyed)
            return;
        modifyCSS(container, {
            visibility: 'hidden',
        });
    };
    /**
     * 更新
     */
    Tooltip.prototype.updateInner = function (options) {
        if (this.options.customContent) {
            this.renderCustomContent(this.options.customContent);
        }
        else {
            if (options.title) {
                this.resetTitle();
            }
            if (options.items) {
                this.renderItems();
            }
        }
        _super.prototype.updateInner.call(this, options);
    };
    /**
     * 根据 customContent 渲染 DOM
     */
    Tooltip.prototype.renderCustomContent = function (customContent) {
        var parentContainer = this.container.parentNode;
        var node = this.getHtmlContentNode(customContent);
        var curContainer = this.container;
        if (parentContainer) {
            parentContainer.replaceChild(node, curContainer);
        }
        this.container = node;
        this.applyStyles();
    };
    /**
     * 生成自定义内容 DOM
     */
    Tooltip.prototype.getHtmlContentNode = function (customContent) {
        var node;
        var element = customContent(this.options.title || '', this.options.items);
        if (isString(element)) {
            node = this.createDom(element);
        }
        else {
            node = element;
        }
        return node;
    };
    /**
     * 重置 title
     */
    Tooltip.prototype.resetTitle = function () {
        var title = this.options.title;
        var showTitle = this.options.showTitle;
        if (showTitle && title) {
            this.showTitle();
            this.setTitle(title);
        }
        else {
            this.hideTitle();
        }
    };
    /**
     * 显示 title
     */
    Tooltip.prototype.showTitle = function () {
        var titleDom = this.titleDom;
        if (titleDom) {
            modifyCSS(titleDom, {
                display: 'block',
            });
        }
    };
    /**
     * 隐藏 title
     */
    Tooltip.prototype.hideTitle = function () {
        var titleDom = this.titleDom;
        if (titleDom) {
            modifyCSS(titleDom, {
                display: 'none',
            });
        }
    };
    /**
     * 设置 title 内容
     */
    Tooltip.prototype.setTitle = function (content) {
        var titleDom = this.titleDom;
        if (titleDom) {
            titleDom.innerHTML = content;
        }
    };
    /**
     * 渲染每项 item
     */
    Tooltip.prototype.renderItems = function () {
        var _this = this;
        this.clearItemDoms();
        var items = this.options.items;
        var itemTpl = this.options.itemTpl || ITEM_TPL;
        var listDom = this.listDom;
        if (listDom) {
            items.forEach(function (item) {
                var substituteObj = __assign({}, item);
                var domStr = substitute(itemTpl, substituteObj);
                var itemDom = _this.createDom(domStr);
                listDom.appendChild(itemDom);
            });
            this.applyChildrenStyles(listDom, this.options.domStyles);
        }
    };
    /**
     * 清空 list DOM 下的 DOM 元素
     */
    Tooltip.prototype.clearItemDoms = function () {
        if (this.listDom) {
            clearDom(this.listDom);
        }
    };
    /**
     * 清空所有
     */
    Tooltip.prototype.clear = function () {
        this.setTitle('');
        this.clearItemDoms();
    };
    return Tooltip;
}(Component));
export { Tooltip };
