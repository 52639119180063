import { gql } from '@apollo/client';

const getDefaultCategories = gql`
    query {
        getPremiseBudgetCategories {
            _id
            title
        }

        getDefaultBudgetCategories {
            _id
            title
            marker
            marker_type
            premise
            premise_type
            child_categories {
                title
                _id
                marker
                marker_type
                premise
                premise_type

                child_categories {
                    title
                    _id
                    marker
                    marker_type
                    premise
                    premise_type
                    child_categories {
                        title
                        _id
                        marker
                        marker_type
                        premise
                        premise_type
                        child_categories {
                            title
                            _id
                            marker
                            marker_type
                            premise
                            premise_type
                        }
                    }
                }
            }
            parent_category {
                title
                _id
            }
        }
    }
`;

const createDefaultBudgetCategory = gql`
    mutation ($category: CategoryInput) {
        createDefaultBudgetCategory(category: $category)
    }
`;

const createGroupBudgetCategory = gql`
    mutation ($category: GroupCategoryInput) {
        createGroupBudgetCategory(category: $category)
    }
`;

const removeDefaultBudgetCategory = gql`
    mutation ($id: String!) {
        removeDefaultBudgetCategory(id: $id)
    }
`;

const createDefaultTemplate = gql`
    query {
        createDefaultBudgetTemplate {
            categories {
                _id
                title
                child_categories {
                    title
                    _id
                    child_categories {
                        title
                        _id
                        child_categories {
                            title
                            _id
                            child_categories {
                                title
                                _id
                            }
                        }
                    }
                }
                parent_category {
                    title
                    _id
                }
            }
            template_id
            template_title
        }
    }
`;

const getCategoriesOfBudgetTemplate = gql`
    query ($template: String) {
        getCategoriesOfBudgetTemplate(template: $template) {
            template {
                title
                total_price
                _id
                group_categories_id
                price_range {
                    one_room {
                        min
                        max
                    }
                    two_room {
                        min
                        max
                    }
                    three_room {
                        min
                        max
                    }
                    four_room {
                        min
                        max
                    }
                }
            }

            categories {
                _id
                title
                marker
                marker_type
                price
                area
                premise
                premise_type
                original_category_id

                products {
                    _id
                    calculate {
                        step_one {
                            type_calc
                            variable
                        }
                        step_two {
                            conditions
                            values
                        }
                        step_three {
                            conditions
                            values
                        }
                    }
                    marker {
                        _id
                        title
                        area
                        marker
                        marker_type
                        premise
                        premise_type
                        aperture_height
                        aperture_width
                        quantity_doors
                    }
                    id_product {
                        _id
                        title
                        price
                        discount
                        discount_description
                    }
                    unit
                    area
                    price_with_discount
                    sub_products {
                        _id
                        calculate {
                            step_one {
                                type_calc
                                variable
                            }
                            step_two {
                                conditions
                                values
                            }
                            step_three {
                                conditions
                                values
                            }
                        }
                        marker {
                            _id
                            area
                            title
                            quantity_doors
                            premise_type
                            marker_type
                            marker
                        }
                        price_with_discount
                        id_product {
                            _id
                            title
                            price
                            discount
                            discount_description
                        }
                    }
                }

                child_categories {
                    _id
                    title
                    marker
                    marker_type
                    price
                    area
                    premise
                    premise_type
                    original_category_id

                    products {
                        _id
                        calculate {
                            step_one {
                                type_calc
                                variable
                            }
                            step_two {
                                conditions
                                values
                            }
                            step_three {
                                conditions
                                values
                            }
                        }
                        marker {
                            _id
                            title
                            area
                            marker
                            marker_type
                            premise
                            premise_type
                            aperture_height
                            aperture_width
                            quantity_doors
                        }
                        id_product {
                            _id
                            title
                            price
                            discount
                            discount_description
                        }
                        unit
                        area
                        price_with_discount
                        sub_products {
                            _id
                            unit
                            area_and_count
                            price_with_discount
                            calculate {
                                step_one {
                                    type_calc
                                    variable
                                }
                                step_two {
                                    conditions
                                    values
                                }
                                step_three {
                                    conditions
                                    values
                                }
                            }
                            marker {
                                _id
                                area
                                title
                                quantity_doors
                                premise_type
                                marker_type
                                marker
                            }
                            id_product {
                                _id
                                title
                                price
                                discount
                                discount_description
                            }
                        }
                    }

                    child_categories {
                        _id
                        title
                        marker
                        marker_type
                        price
                        area
                        premise
                        premise_type
                        original_category_id

                        products {
                            _id
                            calculate {
                                step_one {
                                    type_calc
                                    variable
                                }
                                step_two {
                                    conditions
                                    values
                                }
                                step_three {
                                    conditions
                                    values
                                }
                            }
                            marker {
                                _id
                                title
                                area
                                marker
                                marker_type
                                premise
                                premise_type
                                aperture_height
                                aperture_width
                                quantity_doors
                            }
                            id_product {
                                _id
                                title
                                price
                                discount
                                discount_description
                            }
                            unit
                            area
                            price_with_discount
                            sub_products {
                                _id
                                unit
                                calculate {
                                    step_one {
                                        type_calc
                                        variable
                                    }
                                    step_two {
                                        conditions
                                        values
                                    }
                                    step_three {
                                        conditions
                                        values
                                    }
                                }
                                area_and_count
                                price_with_discount
                                marker {
                                    _id
                                    area
                                    title
                                    quantity_doors
                                    premise_type
                                    marker_type
                                    marker
                                }
                                id_product {
                                    _id
                                    title
                                    price
                                    discount
                                    discount_description
                                }
                            }
                        }

                        child_categories {
                            _id
                            title
                            marker
                            marker_type
                            price
                            area
                            premise
                            premise_type
                            original_category_id

                            products {
                                _id
                                calculate {
                                    step_one {
                                        type_calc
                                        variable
                                    }
                                    step_two {
                                        conditions
                                        values
                                    }
                                    step_three {
                                        conditions
                                        values
                                    }
                                }
                                marker {
                                    _id
                                    title
                                    area
                                    marker
                                    marker_type
                                    premise
                                    premise_type
                                    aperture_height
                                    aperture_width
                                    quantity_doors
                                }
                                id_product {
                                    _id
                                    title
                                    price
                                    discount
                                    discount_description
                                }

                                unit
                                area
                                price_with_discount
                                sub_products {
                                    _id
                                    unit
                                    calculate {
                                        step_one {
                                            type_calc
                                            variable
                                        }
                                        step_two {
                                            conditions
                                            values
                                        }
                                        step_three {
                                            conditions
                                            values
                                        }
                                    }
                                    area_and_count
                                    price_with_discount
                                    marker {
                                        _id
                                        area
                                        title
                                        quantity_doors
                                        premise_type
                                        marker_type
                                        marker
                                    }
                                    id_product {
                                        _id
                                        title
                                        price
                                        discount
                                        discount_description
                                    }
                                }
                            }

                            child_categories {
                                _id
                                title
                                marker
                                marker_type
                                price
                                area
                                premise
                                premise_type
                                original_category_id

                                products {
                                    _id
                                    calculate {
                                        step_one {
                                            type_calc
                                            variable
                                        }
                                        step_two {
                                            conditions
                                            values
                                        }
                                        step_three {
                                            conditions
                                            values
                                        }
                                    }
                                    marker {
                                        _id
                                        title
                                        area
                                        marker
                                        marker_type
                                        premise
                                        premise_type
                                        aperture_height
                                        aperture_width
                                        quantity_doors
                                    }
                                    id_product {
                                        _id
                                        title
                                        price
                                        discount
                                        discount_description
                                    }

                                    unit
                                    area
                                    price_with_discount
                                    sub_products {
                                        _id
                                        unit
                                        calculate {
                                            step_one {
                                                type_calc
                                                variable
                                            }
                                            step_two {
                                                conditions
                                                values
                                            }
                                            step_three {
                                                conditions
                                                values
                                            }
                                        }
                                        area_and_count
                                        price_with_discount
                                        marker {
                                            _id
                                            area
                                            title
                                            quantity_doors
                                            premise_type
                                            marker_type
                                            marker
                                        }
                                        id_product {
                                            _id
                                            title
                                            price
                                            discount
                                            discount_description
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const getCategoriesListOfTemplate = gql`
    query ($template: String) {
        getCategoriesListOfTemplate(template: $template) {
            _id
            title
        }
    }
`;

const getCategory = gql`
    query ($category: String) {
        getCategory(category: $category) {
            title
            _id
            child_categories {
                title
                _id
                parent_category {
                    title
                    _id
                }
                child_categories {
                    title
                    _id
                    parent_category {
                        title
                        _id
                    }
                }
            }
            parent_category {
                title
                _id
            }
        }
    }
`;

const addProductForCategoryOfTemplate = gql`
    mutation ($fields: addProductForTemplate) {
        addProductForCategoryOfTemplate(fields: $fields)
    }
`;

const removeProductFromBudget = gql`
    mutation ($fields: removeProductBudget) {
        removeProductFromBudget(fields: $fields)
    }
`;

const updateTitleTemplate = gql`
    mutation ($fields: TemplateEditInput) {
        updateTitleTemplate(fields: $fields)
    }
`;

const getAllTemplates = gql`
    query {
        getAllTemplates {
            title
            _id
        }
    }
`;

const removeTemplate = gql`
    mutation ($template_id: String) {
        removeTemplate(template_id: $template_id)
    }
`;

const createGroupBudgetCategories = gql`
    mutation ($title: String) {
        createGroupBudgetCategories(title: $title)
    }
`;

const removeGroupCategory = gql`
    mutation ($group_id: String) {
        removeGroupCategory(group_id: $group_id)
    }
`;

const getGroupBudgetCategories = gql`
    query {
        getGroupsBudgetCategories {
            title
            _id
        }
    }
`;

const createTemplate = gql`
    mutation ($fields: CreateTemplateInput) {
        createTemplate(fields: $fields)
    }
`;

const subUpdateTemplate = gql`
    subscription ($id: String) {
        updateBudgetTemplate(id: $id) {
            categories {
                title
            }
        }
    }
`;

const getBudgetOfFlatTemplate = gql`
    query ($flat_template_id: String, $budget_type: String) {
        getBudgetOfFlatTemplate(
            flat_template_id: $flat_template_id
            budget_type: $budget_type
        ) {
            FlatTemplate {
                title
                square_metres
                total_price
                count_rooms
                square_of_budget
            }

            timeOperation

            template {
                title
                total_price
                _id
                price_range {
                    one_room {
                        min
                        max
                    }
                    two_room {
                        min
                        max
                    }
                    three_room {
                        min
                        max
                    }
                    four_room {
                        min
                        max
                    }
                }
            }

            categories {
                _id
                title
                marker
                marker_type
                price
                area
                premise
                premise_type
                status_view

                products {
                    _id
                    marker {
                        _id
                        title
                        area
                        marker
                        marker_type
                        premise
                        premise_type
                        aperture_height
                        aperture_width
                        quantity_doors
                    }
                    id_product {
                        _id
                        title
                        price
                        discount
                        discount_description
                    }
                    unit
                    area
                    price_with_discount
                    sub_products {
                        area
                        price_with_discount
                        marker {
                            _id
                            area
                            title
                            quantity_doors
                            premise_type
                            marker_type
                            marker
                        }
                        id_product {
                            _id
                            title
                            price
                            discount
                            discount_description
                        }
                    }
                }

                child_categories {
                    _id
                    title
                    marker
                    marker_type
                    price
                    area
                    premise
                    premise_type
                    status_view

                    products {
                        _id
                        marker {
                            _id
                            title
                            area
                            marker
                            marker_type
                            premise
                            premise_type
                            aperture_height
                            aperture_width
                            quantity_doors
                        }
                        id_product {
                            _id
                            title
                            price
                            discount
                            discount_description
                        }
                        unit
                        area
                        price_with_discount
                        sub_products {
                            unit
                            area
                            price_with_discount
                            area_and_count
                            marker {
                                _id
                                area
                                title
                                quantity_doors
                                premise_type
                                marker_type
                                marker
                            }
                            id_product {
                                _id
                                title
                                price
                                discount
                                discount_description
                            }
                        }
                    }

                    child_categories {
                        _id
                        title
                        marker
                        marker_type
                        price
                        area
                        premise
                        premise_type
                        status_view

                        products {
                            _id
                            marker {
                                _id
                                title
                                area
                                marker
                                marker_type
                                premise
                                premise_type
                                aperture_height
                                aperture_width
                                quantity_doors
                            }
                            id_product {
                                _id
                                title
                                price
                                discount
                                discount_description
                            }
                            unit
                            area
                            price_with_discount
                            sub_products {
                                unit
                                area_and_count
                                area
                                price_with_discount
                                marker {
                                    _id
                                    area
                                    title
                                    quantity_doors
                                    premise_type
                                    marker_type
                                    marker
                                }
                                id_product {
                                    _id
                                    title
                                    price
                                    discount
                                    discount_description
                                }
                            }
                        }

                        child_categories {
                            _id
                            title
                            marker
                            marker_type
                            price
                            area
                            premise
                            premise_type
                            status_view

                            products {
                                _id
                                marker {
                                    _id
                                    title
                                    area
                                    marker
                                    marker_type
                                    premise
                                    premise_type
                                    aperture_height
                                    aperture_width
                                    quantity_doors
                                }
                                id_product {
                                    _id
                                    title
                                    price
                                    discount
                                    discount_description
                                }

                                unit
                                area
                                price_with_discount
                                sub_products {
                                    unit
                                    area_and_count
                                    area
                                    price_with_discount
                                    marker {
                                        _id
                                        area
                                        title
                                        quantity_doors
                                        premise_type
                                        marker_type
                                        marker
                                    }
                                    id_product {
                                        _id
                                        title
                                        price
                                        discount
                                        discount_description
                                    }
                                }
                            }

                            child_categories {
                                _id
                                title
                                marker
                                marker_type
                                price
                                area
                                premise
                                premise_type
                                status_view

                                products {
                                    _id
                                    marker {
                                        _id
                                        title
                                        area
                                        marker
                                        marker_type
                                        premise
                                        premise_type
                                        aperture_height
                                        aperture_width
                                        quantity_doors
                                    }
                                    id_product {
                                        _id
                                        title
                                        price
                                        discount
                                        discount_description
                                    }

                                    unit
                                    area
                                    price_with_discount
                                    sub_products {
                                        unit
                                        area_and_count
                                        area
                                        price_with_discount
                                        marker {
                                            _id
                                            area
                                            title
                                            quantity_doors
                                            premise_type
                                            marker_type
                                            marker
                                        }
                                        id_product {
                                            _id
                                            title
                                            price
                                            discount
                                            discount_description
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const updateCalculateOfProduct = gql`
    mutation ($fields: CalculateProductInput) {
        updateCalculateOfProduct(fields: $fields)
    }
`;

const cloneBudgetTemplate = gql`
    mutation ($fields: cloneBudgetTemplateInput!) {
        cloneBudgetTemplate(fields: $fields)
    }
`;

const getGroupCategories = gql`
    query ($group_id: String) {
        getGroupCategoryBudgetInfo(group_id: $group_id) {
            title
        }

        getPremisesGroup(group_id: $group_id) {
            title
            _id
        }

        getGroupCategories(group_id: $group_id) {
            _id
            title
            marker
            marker_type
            premise
            premise_type
            associate_premise_with_category_status
            associate_premise_with_category {
                title
                _id
            }

            child_categories {
                title
                _id
                marker
                marker_type
                premise
                premise_type
                associate_premise_with_category_status
                associate_premise_with_category {
                    title
                    _id
                }

                original_category_id

                child_categories {
                    title
                    _id
                    marker
                    marker_type
                    premise
                    premise_type
                    associate_premise_with_category_status
                    associate_premise_with_category {
                        title
                        _id
                    }

                    original_category_id

                    child_categories {
                        title
                        _id
                        marker
                        marker_type
                        premise
                        premise_type
                        associate_premise_with_category_status
                        associate_premise_with_category {
                            title
                            _id
                        }

                        original_category_id

                        child_categories {
                            title
                            _id
                            marker
                            marker_type
                            premise
                            premise_type
                            associate_premise_with_category_status
                            associate_premise_with_category {
                                title
                                _id
                            }

                            original_category_id
                        }
                    }
                }
            }
            parent_category {
                title
                _id
            }
        }
    }
`;

const removeBudgetGroupCategory = gql`
    mutation ($id: String!) {
        removeBudgetGroupCategory(id: $id)
    }
`;

const editGroupCategoryOfBudget = gql`
    mutation ($fields: editGroupCategoryOfBudgetType!) {
        editGroupCategoryOfBudget(fields: $fields)
    }
`;

const updateRangePrices = gql`
    mutation ($fields: updateRangePricesInput!) {
        updateRangePrices(fields: $fields)
    }
`;

export default {
    editGroupCategoryOfBudget,
    createTemplate,
    getGroupBudgetCategories,
    removeGroupCategory,
    getAllTemplates,
    updateCalculateOfProduct,
    createGroupBudgetCategories,
    getCategoriesOfBudgetTemplate,
    getBudgetOfFlatTemplate,
    getDefaultCategories,
    getGroupCategories,
    createDefaultBudgetCategory,
    createGroupBudgetCategory,
    removeDefaultBudgetCategory,
    removeBudgetGroupCategory,
    getCategory,
    createDefaultTemplate,
    addProductForCategoryOfTemplate,
    getCategoriesListOfTemplate,
    removeProductFromBudget,
    updateTitleTemplate,
    removeTemplate,
    cloneBudgetTemplate,
    updateRangePrices,

    //subs
    subUpdateTemplate,
};
