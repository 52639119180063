import React, { useRef } from 'react';
import { Form, Typography, Input } from 'antd';
import styles from '../../../styles/index.module.scss';
import SunEditor from 'suneditor-react';
import { editorOptions } from '../../options';

const { Text } = Typography;

const BuilderFormItemTitles = () => {

    const editorRef = useRef(null);

    const form = Form.useFormInstance();
    const description = Form.useWatch('description', form);

    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    return (
        <div className={styles.formSectionGroup}>

            <Text type="secondary">Наименование, год основания, почта, описание</Text>

            <Form.Item
                label="Наименование"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, заполните поле',
                    },
                ]}
            >
                <Input
                    placeholder="Наименование"
                />
            </Form.Item>

            <Form.Item
                label="Год основания"
                name="year"
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, заполните поле',
                    },
                ]}
            >
                <Input
                    placeholder="Год основания"
                />
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, заполните поле',
                    },
                ]}
            >
                <Input
                    placeholder="Email"
                />
            </Form.Item>

            <Form.Item
                name="description"
                label="Описание"
                className={styles.input}
                rules={[{
                    required: true,
                    message: 'Пожалуйста, заполните поле',
                }]}
            >
                <SunEditor 
                    setOptions={editorOptions}
                    defaultValue={description || ''}
                    getSunEditorInstance={getSunEditorInstance}
                    placeholder='Описание'
                />
            </Form.Item>

        </div>
    )
}

export default BuilderFormItemTitles;