import React from 'react';
import styles from './styles/index.module.scss';
import { Result } from 'antd';

const NoData = ({ height = 450, title = 'Нет данных' }) => {
    return (
        <div
            className={styles.noDataBlock}
            style={{
                height,
            }}
        >
            <Result
                status="404"
                title={title}
                subTitle="Данные не были найдены"
                extra={null}
            />
        </div>
    );
};

export default NoData;
