import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Result, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles/btv.module.scss';
import { api } from '@/Services';

const { Search } = Input;

const BindVT = ({ id, type_collection, button_title = 'Прикрепить туры' }) => {
    const dispatch = useDispatch();

    const [modal_form, set_modal_form] = useState(false);
    const [title, set_title] = useState('');

    let getVTState = useSelector((state) => state.apartments);

    useEffect(() => {
        if (title) {
            const timeOutId = setTimeout(() => {
                dispatch(api.apartments.searchVirtialTours(title));
            }, 850);
            return () => clearTimeout(timeOutId);
        }
    }, [title]);

    const eventVT = (vt_id, type) => {
        dispatch(api.apartments.BindVT(vt_id, id, type, type_collection));
    };

    return (
        <>
            <Modal
                title="Окно поиска виртуальных туров"
                open={modal_form}
                onCancel={() => set_modal_form(false)}
                footer={null}
                width="55%"
            >
                <Search
                    size="large"
                    value={title}
                    onChange={(val) => {
                        set_title(val.target.value);
                    }}
                    placeholder="Поиск по наименованию виртуального тура"
                />

                <div className={styles.formBlockVT}>
                    {getVTState?.request_virtual_tours ? (
                        <div className={styles.loadBlockVT}>
                            <LoadingOutlined />
                        </div>
                    ) : getVTState?.virtual_tours &&
                      getVTState?.virtual_tours.length ? (
                        <div className={styles.vtList}>
                            {getVTState?.virtual_tours.map((item) => {
                                return (
                                    <Tooltip
                                        key={item?._id}
                                        title="Прикрепить виртуальный тур"
                                    >
                                        <div
                                            className={`${styles.vtItem} ${
                                                item?.bind
                                                    ? styles.vtItemBind
                                                    : {}
                                            }`}
                                            onClick={() =>
                                                eventVT(item?._id, 'add')
                                            }
                                        >
                                            {item?.title} (
                                            {item?.secondary_title})
                                        </div>
                                    </Tooltip>
                                );
                            })}
                        </div>
                    ) : (
                        <Result
                            status="404"
                            title="Туров не найдено"
                            subTitle="Попробуйте поискать по другому наименованию"
                            extra={null}
                        />
                    )}
                </div>
            </Modal>
            <Button onClick={() => set_modal_form(true)} type="primary">
                {button_title}
            </Button>
        </>
    );
};

export default BindVT;
