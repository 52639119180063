var _a;
import Theme from '../../configs/theme';
import { CONTAINER_CLASS, LIST_CLASS, LIST_ITEM_CLASS, MARKER_CLASS, TITLE_CLASS, VALUE_CLASS } from './constants';
export default (_a = {},
    // css style for legend
    _a[CONTAINER_CLASS] = {
        visibility: 'visible',
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 12px 0px',
        borderRadius: '2px',
        color: 'rgb(87, 87, 87)',
        fontFamily: Theme.fontFamily,
        padding: '10px 10px 6px 10px',
        lineHeight: 1,
        fontSize: '12px',
    },
    _a[TITLE_CLASS] = {
        fontSize: '13px',
        lineHeight: '19px',
        marginBottom: '8px',
    },
    _a[LIST_CLASS] = {
        margin: '0px',
        listStyleType: 'none',
        padding: '0px',
    },
    _a[LIST_ITEM_CLASS] = {
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
    },
    _a[MARKER_CLASS] = {
        width: '10px',
        height: '10px',
    },
    _a[VALUE_CLASS] = {
        paddingLeft: '8px',
    },
    _a);
