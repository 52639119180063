import currencyFormatter from 'currency-formatter';
import {
    CheckOutlined,
    StopOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Space, Button, Popconfirm } from 'antd';
import { moment } from '@/Services';

const templateColumns =  (openFormTemplate, removeTemplate) => ([
    {
        title: "№",
        key: "number",
        render(item, record, index) {
            return index + 1;
        },
        width: 75
    },
    {
        title: "наименование",
        key: "title",
        dataIndex: 'title'
    },
    {
        title: "Стоимость",
        key: "total_price",
        render(item) {
            return currencyFormatter.format(item?.total_price, {
                code: 'EUR',
                symbol: '',
            }) + '₸';
        },
    },
    {
        title: "Коммерческая недвижимость",
        key: "commercial",
        render(item) {
            return item?.commercial ? <CheckOutlined /> : <StopOutlined />;
        },
    },
    {
        title: "Стоимость за м²",
        key: "square_metre_price",
        render(item) {
            return currencyFormatter.format(item?.square_metre_price, {
                code: 'EUR',
                symbol: '',
            }) + '₸';
        },
    },
    {
        title: "Площадь",
        key: "square_metres",
        render(item) {
            return item?.square_metres + 'м²';
        },
    },
    {
        title: "Дата создания",
        key: "createdAt",
        render(item) {
            return moment(item?.createdAt).format('DD MMMM YYYY в HH:mm');
        },
    },
    {
        title: "Управление",
        key: "_id",
        render(item) {
            return (
                <Space>
                    <Button
                        size="small"
                        onClick={() => openFormTemplate(item)}
                    >
                        <EditOutlined />
                    </Button>
                    <Popconfirm
                        title="Шаблон и связанные с ним квартиры будут удалены. Вы уверены?"
                        onConfirm={()=>removeTemplate(item?._id)}
                        okText="Удалить"
                        cancelText="Нет"
                    >
                        <Button size="small">
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Space>
            );
        },
    },
]);

export default templateColumns;