import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';
import styles from '../styles/index.module.scss';

import { Checkbox, Input } from 'antd';

const PricesApartment = () => {
    const { id } = useParams();

    const [type_update_price_flats, set_type_update_price_flats] =
        useState('AUTO'); //MANUAL
    const [type_price_tab, set_type_price_tab] = useState('AREA'); //ROOMS

    //area
    const [area_30_40, set_area_30_40] = useState(0);
    const [area_41_50, set_area_41_50] = useState(0);
    const [area_51_60, set_area_51_60] = useState(0);
    const [area_61_70, set_area_61_70] = useState(0);
    const [area_71_80, set_area_71_80] = useState(0);
    const [area_81_100, set_area_81_100] = useState(0);
    const [area_101_120, set_area_101_120] = useState(0);
    const [area_121, set_area_121] = useState(0);

    //rooms
    const [rooms_1, set_rooms_1] = useState(0);
    const [rooms_2, set_rooms_2] = useState(0);
    const [rooms_3, set_rooms_3] = useState(0);
    const [rooms_4, set_rooms_4] = useState(0);

    const [rooms_1_promotion, set_rooms_1_promotion] = useState(false);
    const [rooms_2_promotion, set_rooms_2_promotion] = useState(false);
    const [rooms_3_promotion, set_rooms_3_promotion] = useState(false);
    const [rooms_4_promotion, set_rooms_4_promotion] = useState(false);

    const dispatch = useDispatch();
    const getApartmentState = useSelector((state) => state.apartments);

    React.useEffect(() => {
        dispatch(api.apartments.getPricesApartmentComplex(id));

        updatePrices();
    }, [type_update_price_flats]);

    const updatePrices = () => {
        if (getApartmentState.price_apartment_complex) {
            //area
            set_area_30_40(
                getApartmentState.price_apartment_complex.area_30_40
            );
            set_area_41_50(
                getApartmentState.price_apartment_complex.area_41_50
            );
            set_area_51_60(
                getApartmentState.price_apartment_complex.area_51_60
            );
            set_area_61_70(
                getApartmentState.price_apartment_complex.area_61_70
            );
            set_area_71_80(
                getApartmentState.price_apartment_complex.area_71_80
            );
            set_area_81_100(
                getApartmentState.price_apartment_complex.area_81_100
            );
            set_area_101_120(
                getApartmentState.price_apartment_complex.area_101_120
            );
            set_area_121(getApartmentState.price_apartment_complex.area_121);

            //rooms
            set_rooms_1(
                getApartmentState?.price_apartment_complex?.rooms_1?.price
            );
            set_rooms_2(
                getApartmentState?.price_apartment_complex?.rooms_2?.price
            );
            set_rooms_3(
                getApartmentState?.price_apartment_complex?.rooms_3?.price
            );
            set_rooms_4(
                getApartmentState?.price_apartment_complex?.rooms_4?.price
            );
            set_rooms_1_promotion(
                getApartmentState?.price_apartment_complex?.rooms_1?.promotion
            );
            set_rooms_2_promotion(
                getApartmentState?.price_apartment_complex?.rooms_2?.promotion
            );
            set_rooms_3_promotion(
                getApartmentState?.price_apartment_complex?.rooms_3?.promotion
            );
            set_rooms_4_promotion(
                getApartmentState?.price_apartment_complex?.rooms_4?.promotion
            );
            return;
        }

        set_area_30_40(0);
        set_area_41_50(0);
        set_area_51_60(0);
        set_area_61_70(0);
        set_area_71_80(0);
        set_area_81_100(0);
        set_area_101_120(0);
        set_area_121(0);

        //rooms
        set_rooms_1(0);
        set_rooms_2(0);
        set_rooms_3(0);
        set_rooms_4(0);
    };

    const updatePricesApartmentComplexAUTO = () => {
        dispatch(api.apartments.updatePricesApartmentComplexAUTO(id));
    };

    const updatePricesApartmentComplex = () => {
        dispatch(
            api.apartments.updatePricesApartmentComplex({
                id,
                type_update_price_flats,
                type_price_tab,

                //area
                area_30_40,
                area_41_50,
                area_51_60,
                area_61_70,
                area_71_80,
                area_81_100,
                area_101_120,
                area_121,

                //rooms
                rooms_1: {
                    price: rooms_1,
                    promotion: rooms_1_promotion,
                },
                rooms_2: {
                    price: rooms_2,
                    promotion: rooms_2_promotion,
                },
                rooms_3: {
                    price: rooms_3,
                    promotion: rooms_3_promotion,
                },
                rooms_4: {
                    price: rooms_4,
                    promotion: rooms_4_promotion,
                },
            })
        );
    };

    return (
        <content className="page-create-apartment">
            <h1>Управление ценами</h1>
            <p>Если цена неизвестна, установите значение на 0</p>
            <div className="price-apartment-block-form-block">
                <div className="tabs-form-manual-or-auto">
                    <div className="tabs-form-manual-or-auto-body">
                        <span
                            onClick={() =>
                                set_type_update_price_flats('MANUAL')
                            }
                        >
                            Ручное управление
                        </span>
                        <span
                            onClick={() => {
                                set_type_update_price_flats('AUTO');
                                updatePricesApartmentComplexAUTO();
                            }}
                        >
                            Авто управление
                        </span>
                    </div>
                    <div
                        className={
                            type_update_price_flats == 'AUTO'
                                ? 'tabs-form-manual-or-auto-overlay tabs-form-manual-or-auto-overlay-left'
                                : 'tabs-form-manual-or-auto-overlay'
                        }
                    ></div>
                </div>

                {type_update_price_flats == 'MANUAL' && (
                    <div>
                        <div className="tabs-form-manual-or-auto">
                            <div className="tabs-form-manual-or-auto-body">
                                <span
                                    onClick={() => set_type_price_tab('AREA')}
                                >
                                    <b>Цены за м2</b>
                                    <b>по площади квартир</b>
                                </span>
                                <span
                                    onClick={() => set_type_price_tab('ROOMS')}
                                >
                                    <b>Цены за м2</b>
                                    <b>по кол-ву комнат</b>
                                </span>
                            </div>
                            <div
                                className={
                                    type_price_tab == 'ROOMS'
                                        ? 'tabs-form-manual-or-auto-overlay tabs-form-manual-or-auto-overlay-left'
                                        : 'tabs-form-manual-or-auto-overlay'
                                }
                            ></div>
                        </div>
                    </div>
                )}

                {type_update_price_flats == 'MANUAL' &&
                    type_price_tab == 'AREA' && (
                        <div
                            className={`prices-form-apartment-complex ${styles.listOptionPrices}`}
                        >
                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>30-40м2</p>
                                <Input
                                    value={area_30_40}
                                    onChange={(val) =>
                                        set_area_30_40(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>41-50м2</p>
                                <Input
                                    value={area_41_50}
                                    onChange={(val) =>
                                        set_area_41_50(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>51-60м2</p>
                                <Input
                                    value={area_51_60}
                                    onChange={(val) =>
                                        set_area_51_60(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>61-70м2</p>
                                <Input
                                    value={area_61_70}
                                    onChange={(val) =>
                                        set_area_61_70(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>71-80м2</p>
                                <Input
                                    value={area_71_80}
                                    onChange={(val) =>
                                        set_area_71_80(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>81-100м2</p>
                                <Input
                                    value={area_81_100}
                                    onChange={(val) =>
                                        set_area_81_100(
                                            Number(val.target.value)
                                        )
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>101-120м2</p>
                                <Input
                                    value={area_101_120}
                                    onChange={(val) =>
                                        set_area_101_120(
                                            Number(val.target.value)
                                        )
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>121-140м2 +</p>
                                <Input
                                    value={area_121}
                                    onChange={(val) =>
                                        set_area_121(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                            </div>
                        </div>
                    )}

                {type_update_price_flats == 'MANUAL' &&
                    type_price_tab == 'ROOMS' && (
                        <div
                            className={`prices-form-apartment-complex ${styles.listOptionPrices}`}
                        >
                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>1 ком.</p>
                                <Input
                                    value={rooms_1}
                                    onChange={(val) =>
                                        set_rooms_1(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                                <Checkbox
                                    checked={rooms_1_promotion}
                                    onChange={(value) =>
                                        set_rooms_1_promotion(
                                            value.target.checked
                                        )
                                    }
                                >
                                    Участвуют в акции
                                </Checkbox>
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>2 ком.</p>
                                <Input
                                    value={rooms_2}
                                    onChange={(val) =>
                                        set_rooms_2(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                                <Checkbox
                                    checked={rooms_2_promotion}
                                    onChange={(value) =>
                                        set_rooms_2_promotion(
                                            value.target.checked
                                        )
                                    }
                                >
                                    Участвуют в акции
                                </Checkbox>
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>3 ком.</p>
                                <Input
                                    value={rooms_3}
                                    onChange={(val) =>
                                        set_rooms_3(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                                <Checkbox
                                    checked={rooms_3_promotion}
                                    onChange={(value) =>
                                        set_rooms_3_promotion(
                                            value.target.checked
                                        )
                                    }
                                >
                                    Участвуют в акции
                                </Checkbox>
                            </div>

                            <div
                                className={`prices-form-apartment-complex-item ${styles.itemOption}`}
                            >
                                <p>4+ ком.</p>
                                <Input
                                    value={rooms_4}
                                    onChange={(val) =>
                                        set_rooms_4(Number(val.target.value))
                                    }
                                    placeholder="Цена"
                                    type="number"
                                />
                                <Checkbox
                                    checked={rooms_4_promotion}
                                    onChange={(value) =>
                                        set_rooms_4_promotion(
                                            value.target.checked
                                        )
                                    }
                                >
                                    Участвуют в акции
                                </Checkbox>
                            </div>
                        </div>
                    )}

                {getApartmentState.request_price_apartment_complex ? (
                    <div className="prices-form-apartment-complex-button-body prices-form-apartment-complex-button-body-warning">
                        <input type="submit" value="Подождите..." />
                    </div>
                ) : !getApartmentState.request_price_apartment_complex &&
                  type_update_price_flats == 'MANUAL' ? (
                    <div className="prices-form-apartment-complex-button-body">
                        <input
                            onClick={() => updatePricesApartmentComplex()}
                            type="submit"
                            value="Сохранить и обновить цены"
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </content>
    );
};

export default PricesApartment;
