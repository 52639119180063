import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { api } from '../../../../../Services';
import styles from './styles/custom-chess.module.scss';
import { Button, Result, Tour } from 'antd';
import BlockModalForm from './helpers/block-form';
import BlockItem from './helpers/block/item';
import {
    LoadingOutlined,
    RightOutlined,
    LeftOutlined,
} from '@ant-design/icons';
import FlatModalForm from './helpers/flat-form';
import { Space } from 'react-zoomable-ui';
import FlatPlanForm from './helpers/flat-plan-form';
import ModalFormPrices from './helpers/form-prices';

const ChessCustom = () => {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    let stateChess = useSelector((state) => state.chess);
    let stateChessData = useSelector((state) => state.chessData);

    const [start_tour, set_start_tour] = useState(false);

    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(api.chess.getChess(id));
    }, []);

    const modalBlockForm = (status, type) => {
        dispatch({
            type: 'STATUS_MODAL_FORM_BLOCK',
            modal_type: type,
            status,
        });
    };

    const closeFormMain = () => {
        dispatch({
            type: 'STATUS_MODAL_FORM',
            modal_type: '',
            status: false,
        });
    };

    const renderMemoList = useMemo(() =>
        stateChessData?.data?.blocks.map((item, index) => (
            <BlockItem
                index={index}
                item={item}
                key={item?._id}
                api={api}
                dispatch={dispatch}
            />
        ))
    );

    const tourSteps = [
        {
            title: 'Форма создания нового блока жк',
            description: 'На основе внесенных данных формируется шахматка',
            target: () => ref1.current,
            nextButtonProps: {
                children: <RightOutlined />,
            },
            prevButtonProps: {
                children: <LeftOutlined />,
            },
            arrow: true,
        },
        {
            title: 'Статус квартиры',
            description: 'Отображение статуса квартиры',
            target: () => ref2.current,
            nextButtonProps: {
                children: <RightOutlined />,
            },
            prevButtonProps: {
                children: <LeftOutlined />,
            },
        },
        {
            title: 'Основная область для работы с шахматкой',
            description:
                'Добавление и редактирование квартир, пентхаусов. Работа с этажами. Редактирование и удаление блоков',
            target: () => ref3.current,
            nextButtonProps: {
                children: 'Закрыть',
            },
            prevButtonProps: {
                children: <LeftOutlined />,
            },
        },
    ];

    return (
        <div className={styles.chessWrapper}>
            {stateChessData?.data?.chessboard === 'custom' ? (
                <>
                    <Tour
                        open={start_tour}
                        onClose={() => set_start_tour(!start_tour)}
                        steps={tourSteps}
                    />

                    <ModalFormPrices
                        api={api}
                        open={stateChess?.modal_form_prices}
                        dispatch={dispatch}
                        close={() => {
                            dispatch({
                                type: 'CHESS_MODAL_FORM_PRICES',
                            });
                        }}
                        styles={styles}
                        fields={{
                            block_id: stateChess?.block_id,
                            floor: stateChess?.floor,
                        }}
                    />

                    <FlatPlanForm
                        api={api}
                        open={stateChess?.modal_form_flat_plan}
                        close={() => {
                            dispatch({
                                type: 'UPDATE_FLAT_TEMPLATE_PLAN_FORM_MODAL',
                            });
                        }}
                    />

                    <FlatModalForm
                        api={api}
                        realestate_id={id}
                        open={stateChess?.modal_form_flat}
                        close={closeFormMain}
                        type={stateChess?.modal_form_flat_type}
                    />
                    <BlockModalForm
                        open={stateChess?.modal_form_block}
                        close={closeFormMain}
                        type={stateChess?.modal_form_block_type}
                        api={api}
                        realestate_id={id}
                        title={stateChess?.block_title}
                        floors_count={stateChess?.block_floors_count}
                        max_num_apartments_floor={
                            stateChess?.block_max_num_apartments_floor
                        }
                        data_sync_entrance={stateChess?.sync_entrance}
                        data_sync_id={stateChess?.sync_id}
                    />
                    <div className={styles.header}>
                        <h1>Жк {stateChessData?.data?.title || ''}</h1>
                        <Button onClick={() => set_start_tour(!start_tour)}>
                            Тур
                        </Button>
                    </div>
                    <div className={styles.tools}>
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => modalBlockForm(true, 'create')}
                            ref={ref1}
                        >
                            добавить блок
                        </Button>
                        <div
                            className={styles.descriptionFlatColors}
                            ref={ref2}
                        >
                            <span
                                style={{
                                    background: '#5bcd3e',
                                    color: '#fff',
                                }}
                            >
                                Свободные
                            </span>
                            <span
                                style={{
                                    background: '#dde9ff',
                                    color: '#444',
                                }}
                            >
                                Проданные
                            </span>
                        </div>
                    </div>

                    <div
                        className={styles.chess}
                        style={{
                            background: stateChessData?.data?.blocks.length
                                ? 'rgb(233,233,233)'
                                : 'transparent',
                        }}
                    >
                        {stateChessData?.first_request_status ? (
                            <div className={styles.loadBlock}>
                                <LoadingOutlined />
                            </div>
                        ) : stateChessData?.data?.blocks &&
                          stateChessData?.data?.blocks.length ? (
                            <div ref={ref3} className={styles.wrapperItems}>
                                <Space>
                                    <div className={styles.blockItems}>
                                        {renderMemoList}
                                    </div>
                                </Space>
                            </div>
                        ) : (
                            <Result
                                title="Блоки не были добавлены"
                                extra={null}
                            />
                        )}
                    </div>
                </>
            ) : (
                <Result
                    title="В данном жк используется стандартная шахматка"
                    extra={null}
                />
            )}
        </div>
    );
};

export default ChessCustom;
