import React, { useState } from 'react';
import { Checkbox, Input, message, Modal, Select } from 'antd';
import styles from '../../../../styles/index.module.scss';
import SelectCategory from '../../select';
import { MOList, MPList } from '@/Common/budget_markers';
import keyRandom from 'crypto-random-string';

const { Option } = Select;

const CreateGroupCategoryModal = ({
    isModalVisible = false,
    closeModal,
    loading = false,
    categories,
    premises,
    createGroupCategory,
    group_id,
    renderTypeMarker,
}) => {
    const [title, set_title] = useState('');
    const [select_category_modal_visible, set_select_category_modal_visible] =
        useState(false);
    const [select_category, set_select_category] = useState(null);
    const [marker_mo, set_marker_mo] = useState(null);
    const [marker_mp, set_marker_mp] = useState(null);
    const [type_marker, set_type_marker] = useState(null); //types: P помещение, MO общик маркер, MP маркер помещения
    const [
        associate_premise_with_category,
        set_associate_premise_with_category,
    ] = useState(null);
    const [
        associate_premise_with_category_status,
        set_associate_premise_with_category_status,
    ] = useState(false);

    const addCategory = () => {
        let variables = {};

        if (!title) {
            return message.warning(
                'Наименование категориии не должно быть пустым'
            );
        }

        if (type_marker) {
            if (type_marker == 'MO' && !marker_mo) {
                return message.warning('Выберите маркер МО');
            }

            if (type_marker == 'MP' && !marker_mp) {
                return message.warning('Выберите маркер МП');
            }

            variables.marker = type_marker == 'MO' ? true : false;
            variables.premise = type_marker == 'P' ? true : false;

            variables.marker_type = type_marker == 'MO' ? marker_mo : null;
            variables.premise_type = type_marker == 'MP' ? marker_mp : null;
        }

        variables.title = title;
        variables.group_id = group_id;
        variables.associate_premise_with_category =
            associate_premise_with_category;
        variables.associate_premise_with_category_status =
            associate_premise_with_category_status;

        if (select_category) {
            variables.parent_category = select_category._id;
        }

        if (!createGroupCategory[1].loading) {
            set_title('');
            set_select_category(null);
            set_type_marker(null);
            set_associate_premise_with_category(null);
            set_associate_premise_with_category_status(false);

            createGroupCategory[0]({
                variables: {
                    category: variables,
                },
            });

            return closeModal();
        }
    };

    const selectTypeMarker = (value) => {
        set_type_marker(value);
        set_marker_mo(null);
        set_marker_mp(null);
    };

    const selectMarker = (type, value) => {
        if (type == 'MO') {
            if (type_marker == 'MO') {
                set_marker_mp(null);
                set_marker_mo(value);
                return;
            }
        }

        if (type == 'MP') {
            if (type_marker == 'MP') {
                set_marker_mp(value);
                set_marker_mo(null);
                return;
            }
        }

        return message.warning('Маркер не соответсвует типу');
    };

    const selectAssociatePremise = (value) => {
        let getPremise = premises.filter(
            (item) => String(item?._id) == String(value)
        )[0];

        set_title(getPremise?.title || '');
        set_associate_premise_with_category(null);
        set_associate_premise_with_category(value);
    };

    const selectCategory = (cat) => {
        set_marker_mo(null);
        set_marker_mp(null);
        set_type_marker(null);
        set_associate_premise_with_category(null);
        set_associate_premise_with_category_status(false);

        set_select_category(cat);
    };

    return (
        <>
            {isModalVisible && (
                <SelectCategory
                    get_category={selectCategory}
                    categories={categories}
                    close_modal={() => set_select_category_modal_visible(false)}
                    is_visible={select_category_modal_visible}
                />
            )}
            <Modal
                title="Создание категории"
                wrapClassName={styles.modalCreateCloneTemplate}
                visible={isModalVisible}
                onOk={addCategory}
                onCancel={closeModal}
                width="45%"
                okText={loading ? 'Подождите...' : 'Создать'}
                cancelText="отмена"
            >
                <div className={styles.sectionFormInput}>
                    <Input
                        size="large"
                        value={title}
                        placeholder="Наименование категории"
                        onChange={(val) => {
                            if (!associate_premise_with_category) {
                                set_title(val.target.value);
                                return;
                            }

                            message.warning(
                                'Наименование нельзя менять, т.к. категория связана с помещением'
                            );
                        }}
                    />
                </div>

                <div
                    onClick={() => set_select_category_modal_visible(true)}
                    className={styles.sectionForm}
                >
                    <span>
                        {select_category
                            ? `${select_category?.title} (${renderTypeMarker(
                                  select_category
                              )})`
                            : 'Выбрать категорию'}
                    </span>
                </div>

                <div
                    className={`${styles.sectionFormInput} ${styles.sectionFormMarkers}`}
                >
                    <h5>Тип (необязательно)</h5>

                    <div className={styles.typeMarker}>
                        <Select
                            dropdownStyle={{ zIndex: 999999999 }}
                            className={styles.typeMarkerSelect}
                            placeholder="Выберите тип"
                            defaultValue=""
                            value={type_marker}
                            size="large"
                            onChange={selectTypeMarker}
                        >
                            {select_category &&
                            renderTypeMarker(select_category) === 'П' ? (
                                <></>
                            ) : (
                                <Option
                                    className={styles.typeMarkerOption}
                                    value="P"
                                >
                                    П (помещение)
                                </Option>
                            )}

                            <Option
                                className={styles.typeMarkerOption}
                                value="MO"
                            >
                                МО (общий маркер)
                            </Option>
                            {select_category &&
                                renderTypeMarker(select_category) === 'П' && (
                                    <Option
                                        className={styles.typeMarkerOption}
                                        value="MP"
                                    >
                                        МП (маркер помещения)
                                    </Option>
                                )}
                        </Select>
                    </div>

                    <div className={`${styles.typeMarker}`}>
                        {type_marker == 'MO' || type_marker == 'MP' ? (
                            <div className={`${styles.typeMarkersList}`}>
                                <Select
                                    dropdownStyle={{ zIndex: 999999999 }}
                                    className={styles.typeMarkerSelect}
                                    placeholder="Выберите маркер МО"
                                    defaultValue=""
                                    value={marker_mo}
                                    size="large"
                                    onChange={(val) => selectMarker('MO', val)}
                                >
                                    {MOList.map((item) => (
                                        <Option
                                            key={keyRandom({ length: 15 })}
                                            className={styles.typeMarkerOption}
                                            value={item?.value}
                                        >
                                            {item?.title}
                                        </Option>
                                    ))}
                                </Select>
                                <Select
                                    dropdownStyle={{ zIndex: 999999999 }}
                                    className={styles.typeMarkerSelect}
                                    placeholder="Выберите маркер МП"
                                    defaultValue=""
                                    value={marker_mp}
                                    size="large"
                                    onChange={(val) => selectMarker('MP', val)}
                                >
                                    {MPList.map((item) => (
                                        <Option
                                            key={keyRandom({ length: 15 })}
                                            className={styles.typeMarkerOption}
                                            value={item?.value}
                                        >
                                            {item?.title}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    {type_marker == 'P' && (
                        <div className={styles.typeMarker}>
                            <Checkbox
                                className={styles.checkbox}
                                onChange={(val) =>
                                    set_associate_premise_with_category_status(
                                        val.target.checked
                                    )
                                }
                            >
                                Создать новое помещение
                            </Checkbox>

                            {!associate_premise_with_category_status &&
                            premises &&
                            premises.length ? (
                                <Select
                                    dropdownStyle={{ zIndex: 999999999 }}
                                    className={styles.typeMarkerSelect}
                                    placeholder="Выбрать помещение из списка (необязательно)"
                                    defaultValue=""
                                    value={associate_premise_with_category}
                                    size="large"
                                    onChange={selectAssociatePremise}
                                >
                                    {premises.map((item) => (
                                        <Option
                                            key={keyRandom({ length: 15 })}
                                            className={styles.typeMarkerOption}
                                            value={item?._id}
                                        >
                                            {item?.title}
                                        </Option>
                                    ))}
                                </Select>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default CreateGroupCategoryModal;
