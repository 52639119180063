"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unregisterCacheArea = exports.hasCacheArea = exports.getCacheArea = exports.registerCacheAreas = exports.registerCacheArea = exports.AREA_CACHE = void 0;
/**
 * 缓存的行政数据
 */
exports.AREA_CACHE = new Map();
function registerCacheArea(key, area) {
    exports.AREA_CACHE.set(key, area);
}
exports.registerCacheArea = registerCacheArea;
function registerCacheAreas(areaList) {
    areaList.forEach(function (item) {
        exports.AREA_CACHE.set(item.key, item.area);
    });
}
exports.registerCacheAreas = registerCacheAreas;
function getCacheArea(key) {
    return exports.AREA_CACHE.get(key);
}
exports.getCacheArea = getCacheArea;
function hasCacheArea(key) {
    return exports.AREA_CACHE.has(key);
}
exports.hasCacheArea = hasCacheArea;
function unregisterCacheArea(key) {
    return exports.AREA_CACHE.delete(key);
}
exports.unregisterCacheArea = unregisterCacheArea;
