import React, { useEffect, useState } from 'react';

//import config from '../../../../Config';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';
import styles from '../styles/index.module.scss';
import {
    Input,
    Button,
    Result,
    Modal,
    Divider,
    Tag,
    Pagination,
    Popover,
} from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import currencyFormatter from 'currency-formatter';

const XmlFeeds = () => {
    const [xml_modal, set_xml_modal] = useState(false);
    const [xml_info, set_xml_info] = useState(null);
    const [xml_link, set_xml_link] = useState('');
    const [page, set_page] = useState(1);
    const [limit, set_limit] = useState(75);

    const [search_text, set_search_text] = useState('');
    const [search_brand, set_search_brand] = useState('');
    const [search_price, set_search_price] = useState('');

    const dispatch = useDispatch();
    const stateSettings = useSelector((state) => state.statistic.settings);
    const stateLogs = useSelector((state) => state.logs);

    useEffect(() => {
        dispatch(api.statistic.settings('xml-feeds'));
        dispatch(api.logs.xmlPrices());
    }, []);

    useEffect(() => {
        if (stateSettings && stateSettings.length) {
            set_xml_info(stateSettings[0]);
        }
    }, [stateSettings]);

    const uploadXml = () => {
        if (!stateLogs?.upload_xml_file) {
            return dispatch(api.logs.uploadXml(xml_link));
        }
    };

    useEffect(() => {
        dispatch(
            api.logs.xmlPrices(
                page,
                limit,
                search_text,
                search_brand,
                search_price
            )
        );
    }, [page, limit, search_text, search_brand, search_price]);

    const availabilitiesList = (data) => {
        if (data && data.length) {
            return data.map((item) => (
                <div className={styles.listAV}>
                    <h4>
                        {item?.storeId}: {item?.available}
                    </h4>
                </div>
            ));
        } else {
            return <h5>Ничего не найдено</h5>;
        }
    };

    return (
        <div className={`statistic-block ${styles.wrapper}`}>
            <Modal
                title="Загрузить xml файл"
                visible={xml_modal}
                onCancel={() => set_xml_modal(false)}
                onOk={uploadXml}
                okText={
                    stateLogs?.upload_xml_file ? (
                        <LoadingOutlined />
                    ) : (
                        'Загрузить'
                    )
                }
            >
                <div>
                    <h5>последняя сохраненная информация</h5>
                    {xml_info ? (
                        <div className={styles.xmlInfoBlock}>
                            <p>Xml файл: {xml_info?.value}</p>
                            <p>Магазин: {xml_info?.title}</p>
                        </div>
                    ) : (
                        <p>Настроек не найдено</p>
                    )}
                </div>

                <Divider />

                {stateLogs?.upload_xml_file && (
                    <Tag color="red">
                        Обновление может занять до 5 минут, в зависимости от
                        количества товаров
                    </Tag>
                )}

                <Input
                    onChange={(val) => set_xml_link(val.target.value)}
                    placeholder="Вставьте ссылку на xml файл"
                />
            </Modal>

            <div className={styles.header}>
                <h1>Отладчик/Xml feeds</h1>
                <Button onClick={() => set_xml_modal(true)} type="primary">
                    Загрузить xml файл
                </Button>
            </div>
            <div className={`${styles.wrapper} ${styles.wrapperFilter}`}>
                <Input
                    onChange={(val) => {
                        set_page(1);
                        set_search_text(val.target.value);
                    }}
                    placeholder="Поиск по наименованию товара"
                />
                <Input
                    onChange={(val) => {
                        set_page(1);
                        set_search_brand(val.target.value);
                    }}
                    placeholder="Поиск по модели товара"
                />
                <Input
                    onChange={(val) => {
                        set_page(1);
                        set_search_price(val.target.value);
                    }}
                    placeholder="Поиск по цене товара"
                />
            </div>

            <div className={`${styles.wrapper}`}>
                <Tag color="lime">
                    Найдено товаров: {stateLogs?.xml_prices?.count || 0}
                </Tag>
            </div>

            <div className={styles.wrapper}>
                {stateLogs?.request_xml_prices ? (
                    <LoadingOutlined />
                ) : stateLogs?.xml_prices &&
                  stateLogs?.xml_prices?.data &&
                  stateLogs?.xml_prices?.data.length ? (
                    <div className={styles.wrapper}>
                        <div className={styles.wrapperList}>
                            {stateLogs?.xml_prices.data.map((item) => (
                                <div className={styles.item}>
                                    <small>{item?.name}</small>
                                    <span>
                                        Бренд: <small>{item?.brand}</small>
                                    </span>
                                    <span>
                                        Цена:
                                        <small>
                                            {currencyFormatter.format(
                                                item?.price,
                                                {
                                                    code: 'EUR',
                                                    symbol: '',
                                                }
                                            ) + '₸'}
                                        </small>
                                    </span>
                                    <span>
                                        sku: <small>{item?.sku}</small>
                                    </span>
                                    <Popover
                                        content={() =>
                                            availabilitiesList(
                                                item?.availabilities
                                            )
                                        }
                                        title="Список складов"
                                    >
                                        <span>
                                            Склады: <InfoCircleOutlined />
                                        </span>
                                    </Popover>
                                </div>
                            ))}
                        </div>
                        <Divider />
                        <div className={styles.pagination}>
                            <Pagination
                                defaultPageSize={limit}
                                current={page}
                                onChange={(page, pageSize) => {
                                    set_page(page);
                                    set_limit(pageSize);
                                }}
                                pageSizeOptions={[25, 50, 75, 100]}
                                total={stateLogs?.xml_prices?.count}
                            />
                        </div>
                        <Divider />
                    </div>
                ) : (
                    <Result
                        title="Товаров не найдено."
                        subTitle="Попробуйте загрузить xml файл для загрузки товаров"
                        extra={
                            <Button
                                type="primary"
                                onClick={() => set_xml_modal(true)}
                                key="console"
                            >
                                Загрузить xml файл
                            </Button>
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default XmlFeeds;
