import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles/index.module.scss';

const Loader = ({ height = 450 }) => {
    return (
        <div
            className={styles.loadblock}
            style={{
                height,
            }}
        >
            <LoadingOutlined className={styles.loadIcon} />
        </div>
    );
};

export default Loader;
