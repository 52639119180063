import currencyFormatter from 'currency-formatter';
import styles from '../../styles/index.module.scss';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import React from 'react';
import { useDispatch } from 'react-redux';

const ProductItem = ({
    removeSubProduct,
    product,
    category_id,
    template_id,
    removeProductFromBudget,
    openAddSubProduct,
    listSubProducts,
    parent_product_id,
    main_parent_product_id,
    //cat_info,
    sub,
    removeProductFromBudgetLoading,
}) => {
    const dispatch = useDispatch();

    const renderAreaAndQuantity = () => {
        if (product?.marker) {
            return `${product?.marker?.area ? product?.marker?.area : 0}`;
        }
    };

    const renderItem = (product) => {
        return (
            <tr
                className={styles.trBody}
                key={product?._id + `_${Math.random() * 200000}`}
            >
                <td>{product?.id_product?.title}</td>
                <td>
                    <b title={product?.marker?._id}>{product?.marker?.title}</b>
                    .{' '}
                    {product?.id_product?.discount_description
                        ? product?.id_product?.discount_description
                        : '-'}
                </td>
                <td>
                    {Number(renderAreaAndQuantity()).toFixed(2)}
                    <br />

                    <b
                        onClick={(event) => {
                            event.stopPropagation();
                            dispatch({
                                type: 'BUDGET_CALC_OF_PRODUCT',
                                status: true,
                                product,
                            });
                        }}
                    >
                        Редактировать
                    </b>
                </td>
                <td>{product?.unit || '-'}</td>
                <td>
                    {product?.id_product?.price
                        ? currencyFormatter.format(product?.id_product?.price, {
                              code: 'EUR',
                              symbol: '',
                          }) + '₸'
                        : '-'}
                </td>
                <td>
                    {product?.id_product?.discount
                        ? product?.id_product?.discount + '%'
                        : '-'}
                </td>
                <td>-</td>
                <td>Название магазина, телефон, адрес, фото товара</td>
                <td>
                    {removeProductFromBudgetLoading.loading ? (
                        <h5>...</h5>
                    ) : (
                        <Icon
                            path={mdiDelete}
                            size={1}
                            onClick={(event) => {
                                event.stopPropagation();

                                if (sub) {
                                    removeSubProduct(product.id_product._id);
                                }

                                removeProductFromBudget({
                                    variables: {
                                        fields: {
                                            type: sub ? 'SUB' : 'MAIN',
                                            id_product: sub
                                                ? product.id_product._id
                                                : product._id,
                                            parent_product_id: sub
                                                ? parent_product_id
                                                : product._id,
                                            marker: product?.marker?._id
                                                ? product?.marker?._id
                                                : product?.marker,
                                            category_id,
                                            template_id,
                                        },
                                    },
                                });
                            }}
                        />
                    )}
                </td>
                {!sub ? (
                    <td>
                        <span
                            className={styles.buttonSubProduct}
                            onClick={(event) => {
                                event.stopPropagation();
                                openAddSubProduct(
                                    product._id,
                                    category_id,
                                    product.marker._id
                                );
                            }}
                        >
                            Добавить подтовар
                        </span>
                        {product.sub_products && product.sub_products.length ? (
                            <span
                                onClick={(event) => {
                                    event.stopPropagation();
                                    main_parent_product_id(product._id);
                                    listSubProducts(product.sub_products);
                                }}
                            >
                                Подтовары
                            </span>
                        ) : (
                            <></>
                        )}
                    </td>
                ) : (
                    <></>
                )}
            </tr>
        );
    };

    return renderItem(product);
};

export default ProductItem;
