import React, { useState, memo } from 'react';
import config from '@/Config';
import { Popover } from 'antd';
import LayoutDot from './dot';

const LayoutVT = memo(
    ({
        styles,
        virtual_tour,
        room_types,
        updateFields,
        scene_id,
        vt_id,
        scenes,
        eventPreviewPhoto,
        tour_index,
        refMain,
        yaw_def,
    }) => {
        const [type_modal, set_type_modal] = useState(false);
        const [coords, set_coords] = useState(null);

        const renderTypes = () => {
            return (
                <div className={styles.roomTypes}>
                    {room_types && room_types.length ? (
                        room_types.map((item) => (
                            <span
                                key={item?._id}
                                onClick={() => selectTypeRoom(item?._id)}
                            >
                                {item?.title}
                            </span>
                        ))
                    ) : (
                        <h5>Настройки не найдены</h5>
                    )}
                </div>
            );
        };

        const selectTypeRoom = (type) => {
            updateFields({
                variables: {
                    fields: {
                        scene_id,
                        dott_for_room: {
                            ...coords,
                        },
                        title_room: type,
                        vt_id,
                        type: 'room-coords',
                    },
                },
            });

            set_type_modal(false);
        };

        const roomCoords = (event) => {
            const getImageLayout = document.getElementById('layout-image');

            let posXnative = event.nativeEvent.offsetX;
            let posYnative = event.nativeEvent.offsetY;
            let blockHeight = getImageLayout.clientHeight;
            let blockwidth = getImageLayout.clientWidth;

            let percentX = (posXnative / blockwidth) * 100;
            let percentY = (posYnative / blockHeight) * 100;

            set_coords({
                left: percentX,
                top: percentY,
            });
            set_type_modal(true);
        };

        const renderDots = scenes.map((scene, index) => (
            <LayoutDot
                styles={styles}
                index={index}
                eventPreviewPhoto={eventPreviewPhoto}
                key={scene?._id}
                scene={scene}
                tour_index={tour_index}
                refMain={refMain}
                yaw_def={yaw_def}
                virtual_tour={virtual_tour}
                updateFields={updateFields}
                vt_id={vt_id}
            />
        ))

        return (
            <div className={styles.layout}>
                <Popover
                    placement="right"
                    open={type_modal}
                    title="Выберите тип комнаты"
                    content={renderTypes}
                >
                    {scenes && scenes.length ? (
                        <>{renderDots}</>
                    ) : (
                        <></>
                    )}

                    <img
                        id="layout-image"
                        onClick={roomCoords}
                        src={`${config.public}${virtual_tour?.layout.url}`}
                    />
                </Popover>
            </div>
        );
    }
);

export default LayoutVT;
