import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../Services';
import styles from './styles/index.module.scss';
import { Input, Table } from 'antd';
import { builderColumns } from './helpers/table/builder-colums';

const { Search } = Input;

const Builders = () => {
    const dispatch = useDispatch();
    const getBuilderState = useSelector((state) => state.builders);

    const [page, set_page] = useState(1);
    const [limit, set_limit] = useState(100);
    const [search_text, set_search_text] = useState('');

    React.useEffect(() => {
        
        dispatch(api.builders.getBuilders(page, limit, search_text));
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    }, [page, limit]);

    const search = (value) => {
        set_search_text(value);
        dispatch(api.builders.getBuilders(1, limit, value));
    }

    return (
        <content className={`cities-page-block`}>
            <h1>Застройщики</h1>

            <div className={styles.search}>
                <Search 
                    loading={getBuilderState.request_builder_status}
                    placeholder='Поиск застройщика по наименованию'
                    size='large'
                    onSearch={search}
                />
            </div>

            <Table 
                columns={builderColumns()} 
                dataSource={getBuilderState.builders} 
                size="large"
                sticky={true}
                loading={getBuilderState.request_builder_status}
                virtual={true}
                pagination={{
                    defaultCurrent: page,
                    pageSize: limit,
                    total: getBuilderState?.count_builders,
                    onChange: (page, pageSize) => {
                        set_page(page);
                        set_limit(pageSize);
                    },
                    position: 'bottomCenter'
                }}
                bordered
            />

        </content>
    );
};

export default Builders;
