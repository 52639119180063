import { deepMix, each, isString } from '@antv/util';
import { createDom, modifyCSS } from '@antv/dom-util';
import { hasClass } from '../utils/dom';
var Component = /** @class */ (function () {
    function Component(options) {
        this.destroyed = false;
        this.options = deepMix({}, this.getDefaultOptions(), options);
        this.container = this.initContainer();
        this.initDom();
        this.applyStyles();
        this.initEvent();
        this.initCapture();
        this.initVisible();
        this.render();
    }
    /**
     * 获取默认配置
     */
    Component.prototype.getDefaultOptions = function () {
        return {
            name: '',
            containerTpl: '<div></div>',
            visible: true,
            capture: true,
            domStyles: {},
        };
    };
    /**
     * 获取 container
     */
    Component.prototype.getContainer = function () {
        return this.container;
    };
    /**
     * 获取组件的父容器
     */
    Component.prototype.getParentContainer = function () {
        var parent = this.options.parent;
        var parentContainer;
        if (!parent) {
            return parentContainer;
        }
        if (isString(parent)) {
            var parentElement = document.getElementById(parent);
            if (parentElement) {
                parentContainer = parentElement;
            }
            else {
                throw new Error("No parent id ".concat(parent));
            }
        }
        else {
            parentContainer = parent;
        }
        return parentContainer;
    };
    /**
     * 初始化 container
     */
    Component.prototype.initContainer = function () {
        var container = this.createDom(this.options.containerTpl);
        var parentContainer = this.getParentContainer();
        if (parentContainer) {
            parentContainer.appendChild(container);
        }
        return container;
    };
    /**
     * 初始化 visible
     */
    Component.prototype.initVisible = function () {
        if (this.options.visible) {
            this.show();
        }
        else {
            this.hide();
        }
    };
    /**
     * 初始 capture
     */
    Component.prototype.initCapture = function () {
        this.setCapture(this.options.capture);
    };
    /**
     * 更新组件
     */
    Component.prototype.update = function (options) {
        this.options = deepMix({}, this.options, options);
        this.updateInner(options);
        this.afterUpdate(options);
    };
    // 更新组件样式
    Component.prototype.updateInner = function (options) {
        if (options.domStyles) {
            this.applyStyles();
        }
    };
    /**
     * 更新组件后
     */
    Component.prototype.afterUpdate = function (options) {
        // 更新时考虑capture
        if (options.capture) {
            this.setCapture(options.capture);
        }
    };
    /**
     * 是否允许捕捉事件
     */
    Component.prototype.setCapture = function (capture) {
        var container = this.container;
        var value = capture ? 'auto' : 'none';
        container.style.pointerEvents = value;
    };
    /**
     * 应用所有的样式
     */
    Component.prototype.applyStyles = function () {
        var domStyles = this.options.domStyles;
        if (!domStyles) {
            return;
        }
        var container = this.container;
        this.applyChildrenStyles(container, domStyles);
        var className = this.options.className;
        if (className && hasClass(container, className)) {
            var containerCss = domStyles[className];
            modifyCSS(container, containerCss);
        }
    };
    /**
     * 应用样式到 DOM
     */
    Component.prototype.applyChildrenStyles = function (element, styles) {
        each(styles, function (style, name) {
            var elements = element.getElementsByClassName(name);
            each(elements, function (el) {
                modifyCSS(el, style);
            });
        });
    };
    /**
     * 应用到单个 DOM
     */
    Component.prototype.applyStyle = function (cssName, dom) {
        var domStyles = this.options.domStyles;
        domStyles && modifyCSS(dom, domStyles[cssName]);
    };
    /**
     * 创建 DOM
     */
    Component.prototype.createDom = function (str) {
        if (str === void 0) { str = '<div></div>'; }
        return createDom(str);
    };
    /**
     * 清理 DOM
     */
    Component.prototype.removeDom = function () {
        var container = this.container;
        // 节点不一定有 parentNode
        container && container.parentNode && container.parentNode.removeChild(container);
    };
    Component.prototype.destroy = function () {
        this.removeEvent();
        this.removeDom();
        this.destroyed = true;
    };
    return Component;
}());
export { Component };
