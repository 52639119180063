const initialState = {
	saveComplexCache:null
}

const cache = (state = initialState, action) => {
	switch(action.type){

		case 'UPDATE_CACHE_COMPLEX' :

			return {
				...state,
				saveComplexCache:action.cacheComplex
			}

		default :
			return state
	}
}

export default cache;