const initialState = {
    data: null,

    request_status: false,
    first_request_status: true,

    selected_boxes: 0,
};

const chessData = (state = initialState, action) => {
    switch (action.type) {
        case 'CHESS_FLAT_UPDATE_PLAN_POINTS': {
            const blockIndex = state?.data?.blocks.findIndex(
                (block) => block?._id === action?.fields?.block_id
            );

            const updateFlats = state?.data?.blocks[blockIndex]?.chess_grid.map(
                (flat) => {
                    if (flat?.flat?._id === action?.fields?.flat_id) {
                        flat.flat.area_points_for_css =
                            action?.fields?.area_points_for_css || '';
                        flat.flat.area_points =
                            action?.fields?.area_points || [];
                    }
                    return flat;
                }
            );

            state.data.blocks[blockIndex].chess_grid = updateFlats;

            return {
                ...state,
            };
        }

        case 'CHESS_FLAT_DELETE': {
            const blockIndex = state?.data?.blocks.findIndex(
                (block) => block?._id === action?.fields?.block_id?._id
            );

            const updateFlats = state?.data?.blocks[blockIndex]?.chess_grid.map(
                (flat) => {
                    if (flat?.flat?._id === action?.fields?.flat_id) {
                        flat.flat = null;
                    }
                    return flat;
                }
            );

            state.data.blocks[blockIndex].chess_grid = updateFlats;

            return {
                ...state,
            };
        }

        case 'REQUEST_CUSTOM_CHESS_DATA':
            return {
                ...state,
                request_status: action?.status,
                first_request_status: false,
            };

        case 'SELECT_BOXES_BLOCK': {
            let getBlockGrid = action.data || [];

            getBlockGrid.map((item, index) => {
                if (index === action.boxIndex && !item?.flat) {
                    item.selected = !item?.selected;
                }
                return item;
            });

            state.data.blocks[action?.blockIndex].chess_grid = getBlockGrid;

            return {
                ...state,
            };
        }

        case 'UPDATE_CUSTOM_CHESS_DATA':
            return {
                ...state,
                data: action?.data || null,
            };

        default:
            return state;
    }
};

export default chessData;
