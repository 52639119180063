export function hasClass(elements, cName) {
    return !!elements.className.match(new RegExp("(\\s|^)".concat(cName, "(\\s|$)")));
}
export function clearDom(container) {
    var children = container.childNodes;
    var length = children.length;
    for (var i = length - 1; i >= 0; i--) {
        container.removeChild(children[i]);
    }
}
