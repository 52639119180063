const initialState = {
	information:null,

	request_status_statistic:false,

	settings:null,
	request_status_settings:false,

  analytics:null,
  request_status_analytics:false,
  analytics_count: null,

  mortgage:null
}

const statistic = (state = initialState, action) => {
	switch(action.type){

    case 'UPDATE_MORGAGE' :
      return {
        ...state,
        mortgage:action.data
      }

    case 'UPDATE_ANALYTIC_INFORMATION_COUNTS' :
      return {
        ...state,
        analytics_count:action.data
      }

    case 'UPDATE_ANALYTIC_INFORMATION' :
      return {
        ...state,
        analytics:action.data
      }

    case 'STATUS_REQUEST_ANALYTIC' :
      return {
        ...state,
        request_status_analytics:action.status
      }

		case 'UPDATE_STATISTIC_INFORMATION' :
			return {
				...state,
				information:action.data
			}

		case 'STATUS_REQUEST_STATISTIC' :
			return {
				...state,
				request_status_statistic:action.status
			}

		case 'STATUS_REQUEST_SETTINGS' :
			return {
				...state,
				request_status_settings:action.status
			}

		case 'UPDATE_STATISTIC_SETTINGS' :
			return {
				...state,
				settings:action.data
			}

		default :
			return state
	}
}

export default statistic;