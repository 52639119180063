import React, { useState, useEffect } from 'react';
import styles from '../styles/index.module.scss';
import {
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import { Button, Input, Popconfirm } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';

const ItemDistrict = ({ item, delDist }) => {
    const dispatch = useDispatch();
    const getCityState = useSelector((state) => state.cities);

    let [title, set_title] = useState(item?.title || '');
    let [edit, set_edit] = useState(false);

    const editDistrict = () => {
        return dispatch(api.cities.editDistrict(title, item?._id));
    };

    const deleteDistrict = () => {
        delDist(item?._id);
        return dispatch(api.cities.deleteDistrict(item?._id));
    };

    useEffect(() => {
        if (getCityState?.district_request_success) {
            dispatch({
                type: 'DISTRICT_REQUEST_SUCCESS',
                status: false,
            });
            set_edit(false);
        }
    }, [getCityState?.district_request_success]);

    return (
        <div className={styles.itemContent}>
            {edit ? (
                <>
                    <Input
                        size="large"
                        placeholder="Наименование района*"
                        onChange={(value) => set_title(value.target.value)}
                        value={title}
                    />
                    <Button type="primary" size="large" onClick={editDistrict}>
                        <CheckOutlined />
                    </Button>
                    <Button size="large" onClick={() => set_edit(false)}>
                        <CloseOutlined />
                    </Button>
                </>
            ) : (
                <>
                    <h5>{title}</h5>
                    <div className={styles.buttons}>
                        <Button onClick={() => set_edit(true)}>
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            zIndex={999999}
                            title="Район и связанные районы будут удалены"
                            onConfirm={deleteDistrict}
                            okText="Удалить"
                            cancelText="Нет"
                        >
                            <Button>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </div>
                </>
            )}
        </div>
    );
};

export default ItemDistrict;
