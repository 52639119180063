import React, { useState } from 'react';
import styles from '../styles/main.module.scss';
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { api } from '@/Services';

const FlatItem = ({ flat, contentPopover }) => {
    const [title] = useState(flat.title);
    const dispatch = useDispatch();

    const soldOrFreeFlat = () => {
        return dispatch(
            api.apartments.chessForm({
                type_trigger: 'FLAT',
                flat,
                square_metres_price: flat?.square_metre_price,
                secondary: flat?.secondary,
                sold: !flat?.sold,
                title: flat?.title,
                floor: flat?.floor,
                block: flat?.block_id?._id,
            })
        );
    };

    return (
        <div
            className={`${styles.columnGridFlat} ${
                flat.sold
                    ? styles.flatSold
                    : flat.secondary
                    ? styles.flatSecondary
                    : {}
            }`}
            key={flat._id}
        >
            <Tooltip
                trigger={['contextMenu']}
                zIndex={999999999999}
                title={contentPopover('FLAT', flat, Number(flat?.floor))}
                content={contentPopover('FLAT', flat, Number(flat?.floor))}
                onClick={soldOrFreeFlat}
            >
                <h5>{title}</h5>
            </Tooltip>
        </div>
    );
};

export default FlatItem;
