import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { api } from '@/Services/';
import { Button } from 'antd';

import styles from './styles/index.module.scss';
import UserFilter from './Helpers/filter';
import ItemUser from './Helpers/user-item';
import UserTools from './Helpers/user-tools';

const Users = () => {
    const dispatch = useDispatch();
    const getUsers = useSelector((state) => state.users);

    const [search_text, set_search_text] = useState('');
    const [sort_type, set_sort_type] = useState(null);

    useEffect(() => {
        dispatch(api.users.getRoles());

        if (!getUsers.FR) {
            dispatch({
                type: 'USERS_FR',
            });

            dispatch({
                type: 'USERS_PAGE',
                page: getUsers.page + 1,
            });

            loadUsers(getUsers.page + 1);
        }
    }, []);

    const loadUsers = (pgn) => {
        dispatch(api.users.get(pgn, false, search_text, sort_type));
    };

    const searchUsers = (value) => {
        set_search_text(value);
        dispatch(api.users.get(1, true, value, sort_type));
    };

    const sortUser = (value) => {
        set_sort_type(value);
        dispatch(api.users.get(1, true, search_text, value));
    };

    return (
        <content>

            <UserTools/>

            <h1 className="title-page">Пользователи</h1>

            <UserFilter
                sort={(value) => sortUser(value)}
                userState={getUsers}
                search={searchUsers}
                roles={getUsers?.roles || []}
            />

            {getUsers.list && getUsers.list.length ? (
                <div className={`block-users-items ${styles.listUsers}`}>
                    {getUsers.list.map((user) => (
                        <ItemUser
                            key={user?._id}
                            roles={getUsers?.roles || []}
                            api={api}
                            dispatch={dispatch}
                            user={user}
                        />
                    ))}
                </div>
            ) : (
                <h5>Информации нет</h5>
            )}

            <div className={styles.buttonMore}>
                <Button
                    type="primary"
                    loading={getUsers?.request_status_users}
                    onClick={() => {
                        dispatch({
                            type: 'USERS_PAGE',
                            page: getUsers.page + 1,
                        });
                        return loadUsers(getUsers.page + 1);
                    }}
                >
                    {getUsers?.request_status_users
                        ? 'Идет поиск...'
                        : 'Показать еще'}
                </Button>
            </div>
        </content>
    );
};

export default Users;
