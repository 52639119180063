import React, { useEffect, useState } from 'react';
import styles from '../../styles/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { api, moment } from '../../../../../Services';
import { useParams, Link } from 'react-router-dom';
import { LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { Result, Button, Modal, Input, Popover, Drawer, Timeline } from 'antd';

const ListLeads = () => {
    const { realtor_id, company_id } = useParams();

    const dispatch = useDispatch();
    const stateRealtor = useSelector((state) => state.users);

    const [modal_form_lead, set_modal_form_lead] = useState(false);
    const [client_name, set_client_name] = useState('');
    const [client_phone, set_client_phone] = useState('');
    const [link, set_link] = useState('');
    const [message, set_message] = useState('');
    const [timeline_drawer, set_timeline_drawer] = useState(false);
    const [timeline_events, set_timeline_events] = useState([]);

    useEffect(() => {
        dispatch(api.users.getLeadsOfCompany(realtor_id, company_id));
        dispatch(api.apartments.getApartmentsComplexForRealtors());
    }, []);

    const createLead = () => {
        dispatch(
            api.users.createLead({
                client_name,
                client_phone,
                link,
                message,
                company_id,
                realtor_id,
            })
        );
    };

    return (
        <div className={styles.pageLeads}>
            <Drawer
                title="Таймлайн событий"
                placement="right"
                onClose={() => {
                    set_timeline_events([]);
                    set_timeline_drawer(false);
                }}
                visible={timeline_drawer}
                style={{ zIndex: 99999999 }}
                width="500px"
            >
                <Timeline mode="left">
                    {timeline_events && timeline_events.length ? (
                        timeline_events.map((item) => {
                            return (
                                <Timeline.Item
                                    label={moment(item?.time).format(
                                        'DD MMM YYYY, HH:mm'
                                    )}
                                >
                                    {item?.message}
                                </Timeline.Item>
                            );
                        })
                    ) : (
                        <div>Событий нет</div>
                    )}
                </Timeline>
            </Drawer>

            {stateRealtor?.info_user_company?.company?.gift && (
                <Modal
                    width="60%"
                    title="Форма добавления лида"
                    visible={modal_form_lead}
                    onOk={createLead}
                    okText="Добавить"
                    onCancel={() => set_modal_form_lead(false)}
                >
                    <Input
                        className={styles.inputFormLead}
                        size="large"
                        value={client_name}
                        placeholder="Введите имя клиента*"
                        onChange={(value) =>
                            set_client_name(value.target.value)
                        }
                    />
                    <Input
                        className={styles.inputFormLead}
                        size="large"
                        value={client_phone}
                        placeholder="Введите телефон клиента*"
                        onChange={(value) =>
                            set_client_phone(value.target.value)
                        }
                    />
                    <Input
                        className={styles.inputFormLead}
                        size="large"
                        value={link}
                        placeholder="Ссылка (откуда была сделана заявка)*"
                        onChange={(value) => set_link(value.target.value)}
                    />

                    <Input
                        className={styles.inputFormLead}
                        size="large"
                        value={message}
                        placeholder="Примечание"
                        onChange={(value) => set_message(value.target.value)}
                    />
                </Modal>
            )}

            <h1 className="title-page">
                Управление лидами (риэлтор:{' '}
                {stateRealtor?.info_user_company?.settings?.company_name ? (
                    <Link
                        to={`/realtors/edit/${stateRealtor?.info_user_company?._id}`}
                    >
                        {
                            stateRealtor?.info_user_company?.settings
                                ?.company_name
                        }
                        <LinkOutlined style={{ fontSize: 15, marginLeft: 2 }} />
                    </Link>
                ) : (
                    'Не опредено'
                )}
                ,{' '}
                {stateRealtor?.info_user_company?.company?.gift
                    ? 'подарочная компания'
                    : 'компания'}
                :{' '}
                {stateRealtor?.info_user_company?.company ? (
                    <Link to={`/realtors/edit/${realtor_id}/leads`}>
                        {stateRealtor?.info_user_company?.company?.title}
                        <LinkOutlined style={{ fontSize: 15, marginLeft: 2 }} />
                    </Link>
                ) : (
                    'Не опредено'
                )}
                )
            </h1>

            <div className={styles.content}>
                {stateRealtor?.info_user_company?.company?.gift &&
                stateRealtor?.realtor_company_leads.length ? (
                    <Button
                        style={{ marginBottom: 10 }}
                        onClick={() => set_modal_form_lead(true)}
                        type="primary"
                    >
                        Добавить лид
                    </Button>
                ) : (
                    <></>
                )}

                <div>
                    {stateRealtor?.realtor_leads_request ? (
                        <div className={styles.blockInfo}>
                            <LoadingOutlined />
                        </div>
                    ) : stateRealtor?.realtor_company_leads &&
                      stateRealtor?.realtor_company_leads.length ? (
                        <div className={styles.companyItems}>
                            {stateRealtor?.realtor_company_leads.map((item) => {
                                return (
                                    <div
                                        key={item?._id}
                                        className={styles.item}
                                    >
                                        <div className={styles.itemSection}>
                                            <Popover
                                                content="Имя клиента"
                                                title=""
                                            >
                                                <p>{item?.client_name}</p>
                                            </Popover>
                                        </div>
                                        <div className={styles.line}></div>
                                        <div className={styles.itemSection}>
                                            <Popover
                                                content="Телефон клиента"
                                                title=""
                                            >
                                                <p>
                                                    <a
                                                        href={`tel:${item?.client_phone}`}
                                                    >
                                                        {item?.client_phone}
                                                    </a>
                                                </p>
                                            </Popover>
                                        </div>
                                        <div className={styles.line}></div>
                                        <div className={styles.itemSection}>
                                            <Popover
                                                content="Ссылка на страницу"
                                                title=""
                                            >
                                                <p>
                                                    <a
                                                        target="_blank"
                                                        href={`${item?.link}`}
                                                    >
                                                        Ссылка
                                                    </a>
                                                </p>
                                            </Popover>
                                        </div>
                                        <div className={styles.line}></div>
                                        <div className={styles.itemSection}>
                                            <Popover
                                                content="Жилой комплекс"
                                                title=""
                                            >
                                                <p>
                                                    {item?.realestate?.title ||
                                                        'Не найдено'}
                                                </p>
                                            </Popover>
                                        </div>
                                        <div className={styles.line}></div>
                                        <div className={styles.itemSection}>
                                            <Popover
                                                content="Дата создания"
                                                title=""
                                            >
                                                <p>
                                                    {moment(
                                                        item?.createdAt
                                                    ).format(
                                                        'DD MMMM YYYY в HH:mm'
                                                    )}
                                                </p>
                                            </Popover>
                                        </div>
                                        <div className={styles.line}></div>
                                        <div className={styles.itemSection}>
                                            <Popover
                                                content="статус лида"
                                                title=""
                                            >
                                                <p>
                                                    {item?.status
                                                        ? item?.status?.title
                                                        : 'Новый'}
                                                </p>
                                            </Popover>
                                        </div>
                                        <div className={styles.line}></div>
                                        <div
                                            className={styles.itemSection}
                                            onClick={() => {
                                                set_timeline_drawer(true);
                                                set_timeline_events(
                                                    item?.timeline_events || []
                                                );
                                            }}
                                        >
                                            <p>Посмотреть события</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <Result
                            status="404"
                            title="Лидов не найдено"
                            subTitle="Информации не найдено"
                            extra={
                                stateRealtor?.info_user_company?.company
                                    ?.gift ? (
                                    <Button
                                        onClick={() =>
                                            set_modal_form_lead(true)
                                        }
                                        type="primary"
                                    >
                                        Добавить лид
                                    </Button>
                                ) : null
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListLeads;
