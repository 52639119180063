import React, { useEffect, useState } from 'react';
import styles from '../../styles/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, Select, TreeSelect, Popover } from 'antd';
import { api } from '@/Services';
import {
    ArrowDownOutlined,
    DeleteOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import config from '@/Config';
import randomKey from 'crypto-random-string';

const { TextArea } = Input;
const { Option } = Select;

const EditProduct = () => {
    const routerParams = useParams();
    const history = useNavigate();
    const dispatch = useDispatch();

    const getLibrary = useSelector((state) => state.library);
    const getFiles = useSelector((state) => state.files);
    const getLocation = useSelector((state) => state.cities);

    const [modal_color, set_modal_color] = useState(false);

    const [categories, set_categories] = useState([]);
    const [shop_categories, set_shop_categories] = useState([]);
    const [select_category, set_select_category] = useState();
    const [select_shop_category, set_select_shop_category] = useState();
    const [title, set_title] = useState('');
    const [instagram, set_instagram] = React.useState('');
    const [link, set_link] = useState('');
    const [price, set_price] = useState('');
    const [discount_description, set_discount_description] = useState('');
    const [discount, set_discount] = useState('');
    const [article_number, set_article_number] = useState('');
    const [select_shop, set_select_shop] = useState('');
    const [id, set_id] = useState('');

    const [height, set_height] = useState('');
    const [width, set_width] = useState('');
    const [depth, set_depth] = useState('');
    const [length, set_length] = useState('');
    const [color, set_color] = useState('');
    const [material, set_material] = useState('');
    const [country, set_country] = useState('');
    const [unit, set_unit] = useState('');

    const [parameters, set_parameters] = useState([]);
    const [parameter_type, set_parameter_type] = useState('');
    const [parameter_title, set_parameter_title] = useState('');
    const [parameters_modal, set_parameters_modal] = useState(false);

    const addParametr = () => {
        clearStateParameterModal();

        parameters.push({
            type: parameter_type,
            title: parameter_title,
        });

        set_parameters(parameters);
    };

    const clearStateParameterModal = () => {
        set_parameter_type('');
        set_parameter_title('');
        set_parameters_modal(false);
    };

    useEffect(() => {
        dispatch({
            type: 'UPDATE_LIBRARY_TREE_CATEGORIES',
            categories: [],
        });

        dispatch(api.library.getCriteria());
        dispatch(api.cities.getCountries());
        dispatch(api.library.treeCategories());
        dispatch(api.library.treeCategories('SHOP'));
        dispatch(api.library.shops());

        dispatch(api.library.getProductByID(routerParams?.id));
    }, []);

    useEffect(() => {
        set_select_category(getLibrary?.library_product_edit?.category?._id);
        set_select_shop_category(
            getLibrary?.library_product_edit?.shop_category?._id
        );
        set_title(getLibrary?.library_product_edit?.title);
        set_instagram(getLibrary?.library_product_edit?.instagram);
        set_link(getLibrary?.library_product_edit?.link);
        set_price(getLibrary?.library_product_edit?.price);
        set_discount_description(
            getLibrary?.library_product_edit?.discount_description
        );
        set_discount(getLibrary?.library_product_edit?.discount);
        set_article_number(getLibrary?.library_product_edit?.article_number);
        set_select_shop(getLibrary?.library_product_edit?.shop?._id);
        set_id(getLibrary?.library_product_edit?._id);
        set_material(
            getLibrary?.library_product_edit?.material &&
                getLibrary?.library_product_edit?.material.length
                ? getLibrary.library_product_edit.material.map((item) => {
                      return item?._id;
                  })
                : []
        );
        set_country(getLibrary?.library_product_edit?.country);
        set_parameters(getLibrary?.library_product_edit?.parameters || []);
        set_height(getLibrary?.library_product_edit?.height);
        set_width(getLibrary?.library_product_edit?.width);
        set_depth(getLibrary?.library_product_edit?.depth);
        set_length(getLibrary?.library_product_edit?.length);
        set_color(getLibrary?.library_product_edit?.color);
        set_color(getLibrary?.library_product_edit?.color);
        set_unit(getLibrary?.library_product_edit?.unit?._id || null);

        let photos = [];

        if (
            getLibrary?.library_product_edit?.photos &&
            getLibrary?.library_product_edit?.photos.length
        ) {
            photos = getLibrary?.library_product_edit?.photos
                ? getLibrary?.library_product_edit?.photos.map((item) => {
                      if (
                          item?._id ==
                          getLibrary?.library_product_edit?.photo?._id
                      ) {
                          return {
                              id: item?._id,
                              url: item?.url,
                              full_url: `${config.public}${item?.url}`,
                              select: true,
                          };
                      }

                      return {
                          id: item?._id,
                          url: item?.url,
                          full_url: `${config.public}${item?.url}`,
                      };
                  })
                : [];
        } else {
            if (getLibrary?.library_product_edit?.photo?._id) {
                photos.push({
                    id: getLibrary?.library_product_edit?.photo?._id,
                    url: getLibrary?.library_product_edit?.photo?.url,
                    full_url: `${config.public}${getLibrary?.library_product_edit?.photo?.url}`,
                    select: true,
                });
            }
        }

        dispatch({
            type: 'APARTMENT_INTERIOR_ARRAY',
            photos,
        });
    }, [getLibrary?.library_product_edit]);

    useEffect(() => {
        set_categories(getLibrary?.tree_categories || []);
        set_shop_categories(getLibrary?.shop_categories || []);
    }, [getLibrary?.tree_categories, getLibrary?.shop_categories]);

    const editProduct = () => {
        return dispatch(
            api.library.editProduct(
                {
                    category: select_category,
                    shop_category: select_shop_category,
                    title,
                    link,
                    price,
                    discount_description,
                    article_number,
                    shop: select_shop,
                    discount,
                    instagram,
                    id: id,

                    height,
                    width,
                    depth,
                    length,
                    color,
                    material,
                    unit,
                    country,
                    parameters,
                    photos: getFiles.APARTMENT_INTERIOR,
                },
                history
            )
        );
    };

    const selectColor = (clr) => {
        set_modal_color(false);
        set_color(clr);
    };

    return (
        <div className={styles.mainBlock}>
            <Modal
                width={'55%'}
                title="Форма добавления характеристики"
                visible={parameters_modal}
                onOk={addParametr}
                onCancel={clearStateParameterModal}
            >
                <Input
                    value={parameter_type}
                    onChange={(value) => set_parameter_type(value.target.value)}
                    placeholder="Тип характеристики"
                />
                <Input
                    value={parameter_title}
                    onChange={(value) =>
                        set_parameter_title(value.target.value)
                    }
                    placeholder="Название характеристики"
                />
            </Modal>

            <div className={styles.header}>
                <h2>Форма редактирования товара</h2>
            </div>

            <div className={styles.formBlock}>
                <Popover content="Выберите категорию" title="">
                    <section className={styles.formSection}>
                        <TreeSelect
                            virtual={false}
                            size="large"
                            treeDataSimpleMode
                            style={{
                                width: '100%',
                            }}
                            value={select_shop_category}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Выберите категорию"
                            onChange={(value) =>
                                set_select_shop_category(value)
                            }
                            treeData={
                                shop_categories && shop_categories.length
                                    ? shop_categories
                                    : []
                            }
                        />
                    </section>
                </Popover>

                <Popover content="Выберите категорию для 360" title="">
                    <section className={styles.formSection}>
                        <TreeSelect
                            virtual={false}
                            size="large"
                            treeDataSimpleMode
                            style={{
                                width: '100%',
                            }}
                            value={select_category}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Выберите категорию для 360"
                            onChange={(value) => set_select_category(value)}
                            treeData={
                                categories && categories.length
                                    ? categories
                                    : []
                            }
                        />
                    </section>
                </Popover>

                <Popover content="Наименование товара" title="">
                    <section className={styles.formSection}>
                        <Input
                            size="large"
                            value={title}
                            onChange={(value) => set_title(value.target.value)}
                            placeholder="Наименование товара"
                        />
                    </section>
                </Popover>

                <Popover content="Выберите магазин" title="">
                    <section className={styles.formSection}>
                        <Select
                            value={select_shop}
                            onChange={(value) => set_select_shop(value)}
                            className={styles.formSectionSelect}
                            filterOption={(input, option) =>
                                option.children.includes(input)
                            }
                            showSearch
                            size="large"
                            placeholder="Выберите магазин"
                        >
                            {getLibrary?.shops && getLibrary?.shops.length ? (
                                getLibrary?.shops.map((item) => (
                                    <Option key={item?._id} value={item?._id}>
                                        {item?.title}
                                    </Option>
                                ))
                            ) : (
                                <></>
                            )}
                        </Select>
                    </section>
                </Popover>

                <Popover content="Instagram" title="">
                    <section className={styles.formSection}>
                        <Input
                            size="large"
                            value={instagram}
                            onChange={(value) => {
                                set_instagram(value.target.value);
                                set_link('');
                            }}
                            placeholder="Instagram"
                        />
                    </section>
                </Popover>

                <Popover content="Ссылка на товар (магазин)" title="">
                    <section className={styles.formSection}>
                        <Input
                            size="large"
                            value={link}
                            onChange={(value) => {
                                set_link(value.target.value);
                                set_instagram('');
                            }}
                            placeholder="Ссылка на товар (магазин)"
                        />
                    </section>
                </Popover>

                <Popover content="Цена (необязательно)" title="">
                    <section className={styles.formSection}>
                        <Input
                            size="large"
                            value={price}
                            onChange={(value) => {
                                set_price(value.target.value);
                            }}
                            placeholder="Цена (необязательно)"
                        />
                    </section>
                </Popover>

                <Popover content="Описание" title="">
                    <section className={styles.formSection}>
                        <TextArea
                            size="large"
                            value={discount_description}
                            onChange={(value) => {
                                set_discount_description(value.target.value);
                            }}
                            rows={3}
                            placeholder="Описание"
                        />
                    </section>
                </Popover>

                <Popover content="Скидка (укажите скидку в процентах)" title="">
                    <section className={styles.formSection}>
                        <Input
                            size="large"
                            value={discount}
                            onChange={(value) => {
                                set_discount(value.target.value);
                            }}
                            placeholder="Скидка (укажите скидку в процентах)"
                        />
                    </section>
                </Popover>

                <Popover content="Артикул" title="">
                    <section className={styles.formSection}>
                        <Input
                            size="large"
                            value={article_number}
                            onChange={(value) => {
                                set_article_number(value.target.value);
                            }}
                            placeholder="Артикул"
                        />
                    </section>
                </Popover>

                <section className={styles.formSection}>
                    <p>Размер</p>
                    <div className={styles.formSectionRows}>
                        <Popover content="Высота" title="">
                            <Input
                                size="large"
                                value={height}
                                onChange={(value) => {
                                    set_height(value.target.value);
                                }}
                                placeholder="Высота"
                                className={styles.formSectionRowInput}
                            />
                        </Popover>
                        <Popover content="Ширина" title="">
                            <Input
                                size="large"
                                value={width}
                                onChange={(value) => {
                                    set_width(value.target.value);
                                }}
                                placeholder="Ширина"
                                className={styles.formSectionRowInput}
                            />
                        </Popover>
                        <Popover content="Глубина" title="">
                            <Input
                                size="large"
                                value={depth}
                                onChange={(value) => {
                                    set_depth(value.target.value);
                                }}
                                placeholder="Глубина"
                                className={styles.formSectionRowInput}
                            />
                        </Popover>
                        <Popover content="Длина" title="">
                            <Input
                                size="large"
                                value={length}
                                onChange={(value) => {
                                    set_length(value.target.value);
                                }}
                                placeholder="Длина"
                                className={styles.formSectionRowInput}
                            />
                        </Popover>
                    </div>
                </section>

                <Popover content="Выберите материал" title="">
                    <section className={styles.formSection}>
                        <Select
                            mode="multiple"
                            allowClear
                            value={material}
                            onChange={(value) => set_material(value)}
                            className={styles.formSectionSelect}
                            filterOption={(input, option) =>
                                option.children.includes(input)
                            }
                            showSearch
                            size="large"
                            placeholder="Выберите материал"
                        >
                            {getLibrary?.criteria &&
                            getLibrary?.criteria.filter(
                                (item) => item?.type == 'PRODUCT_CRITERIA'
                            ).length ? (
                                getLibrary?.criteria
                                    .filter(
                                        (item) =>
                                            item?.type == 'PRODUCT_CRITERIA'
                                    )
                                    .map((item) => (
                                        <Option
                                            key={item?._id}
                                            value={item?._id}
                                        >
                                            {item?.value}
                                        </Option>
                                    ))
                            ) : (
                                <></>
                            )}
                        </Select>
                    </section>
                </Popover>

                <Popover content="Выберите еденицу измерения" title="">
                    <section className={styles.formSection}>
                        <Select
                            allowClear
                            value={unit}
                            onChange={(value) => set_unit(value)}
                            className={styles.formSectionSelect}
                            filterOption={(input, option) =>
                                option.children.includes(input)
                            }
                            showSearch
                            size="large"
                            placeholder="Выберите еденицу измерения"
                        >
                            {getLibrary?.criteria &&
                            getLibrary?.criteria.filter(
                                (item) => item?.type == 'PRODUCT_UNIT'
                            ).length ? (
                                getLibrary?.criteria
                                    .filter(
                                        (item) => item?.type == 'PRODUCT_UNIT'
                                    )
                                    .map((item) => (
                                        <Option
                                            key={item?._id}
                                            value={item?._id}
                                        >
                                            {item?.value}
                                        </Option>
                                    ))
                            ) : (
                                <></>
                            )}
                        </Select>
                    </section>
                </Popover>

                <Popover content="Страна производитель" title="">
                    <section className={styles.formSection}>
                        <Select
                            allowClear
                            value={country}
                            onChange={(value) => set_country(value)}
                            className={styles.formSectionSelect}
                            filterOption={(input, option) =>
                                option.children.includes(input)
                            }
                            showSearch
                            size="large"
                            placeholder="Страна производитель"
                        >
                            {getLocation?.countries &&
                            getLocation?.countries.length ? (
                                getLocation?.countries.map((item) => (
                                    <Option key={item?._id} value={item?._id}>
                                        {item?.title}
                                    </Option>
                                ))
                            ) : (
                                <></>
                            )}
                        </Select>
                    </section>
                </Popover>

                <Popover
                    content="Дополнительные характеристики товара"
                    title=""
                >
                    <section
                        className={`${styles.formSection} ${styles.formSectionOther}`}
                    >
                        <p>Дополнительные характеристики товара</p>
                        <Button
                            onClick={() => set_parameters_modal(true)}
                            type="primary"
                        >
                            Добавить характеристику
                        </Button>

                        <div>
                            {parameters && parameters.length ? (
                                parameters.map((item) => (
                                    <div
                                        className={styles.paramentItem}
                                        key={randomKey({ length: 15 })}
                                    >
                                        <b>{item?.type}</b>
                                        <h6>{item?.title}</h6>
                                        <DeleteOutlined
                                            onClick={() => {
                                                set_parameters(
                                                    parameters.filter(
                                                        (param) =>
                                                            param?.type !=
                                                            item?.type
                                                    )
                                                );
                                            }}
                                            className={
                                                styles.paramentItemDelete
                                            }
                                        />
                                    </div>
                                ))
                            ) : (
                                <h5>Нет дополнительной информации</h5>
                            )}
                        </div>
                    </section>
                </Popover>

                <Popover content="Цвет" title="" placement="topLeft">
                    <section className={styles.formSection}>
                        <p>Цвет</p>
                        <div className={styles.formSectionRows}>
                            <div
                                className={styles.button}
                                onClick={() => set_modal_color(!modal_color)}
                                style={{
                                    background: color || '#fff',
                                }}
                            >
                                <div
                                    className={styles.button}
                                    style={{
                                        padding: 0,
                                    }}
                                >
                                    <span>
                                        {color
                                            ? 'Изменить цвет'
                                            : 'Выбрать цвет'}
                                    </span>
                                    <ArrowDownOutlined />
                                </div>

                                <div
                                    className={`${styles.colorsBlock} ${
                                        modal_color
                                            ? styles.colorsBlockOpened
                                            : {}
                                    }`}
                                >
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(255, 255, 255)')
                                        }
                                        className={styles.color1}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(233, 214, 190)')
                                        }
                                        className={styles.color2}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(254, 203, 218)')
                                        }
                                        className={styles.color3}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(102, 197, 253)')
                                        }
                                        className={styles.color4}
                                    ></div>

                                    <div
                                        onClick={() =>
                                            selectColor('rgb(128, 128, 128)')
                                        }
                                        className={styles.color5}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(254, 211, 48)')
                                        }
                                        className={styles.color6}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(194, 24, 168)')
                                        }
                                        className={styles.color7}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(60, 212, 198)')
                                        }
                                        className={styles.color8}
                                    ></div>

                                    <div
                                        onClick={() =>
                                            selectColor('rgb(0, 0, 0)')
                                        }
                                        className={styles.color9}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(253, 144, 38)')
                                        }
                                        className={styles.color10}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(90, 19, 154)')
                                        }
                                        className={styles.color11}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(125, 229, 45)')
                                        }
                                        className={styles.color12}
                                    ></div>

                                    <div
                                        onClick={() =>
                                            selectColor('rgb(119, 60, 11)')
                                        }
                                        className={styles.color13}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(190, 7, 18)')
                                        }
                                        className={styles.color14}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(42, 86, 215)')
                                        }
                                        className={styles.color15}
                                    ></div>
                                    <div
                                        onClick={() =>
                                            selectColor('rgb(53, 101, 13)')
                                        }
                                        className={styles.color16}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popover>

                <Popover content="Фотографии товара" title="">
                    <section className={styles.formSection}>
                        <div className={styles.formSectionUploadFile}>
                            <h3>Фотографии товара</h3>
                            <Button
                                type="primary"
                                className={styles.formSectionFile}
                            >
                                {getFiles.request_upload_status ? (
                                    <LoadingOutlined />
                                ) : (
                                    <span>Выбрать картинку</span>
                                )}
                                {!getFiles.request_upload_status && (
                                    <input
                                        onChange={(val) =>
                                            dispatch(
                                                api.files.uploadFile(
                                                    val.target.files[0],
                                                    'IMAGE'
                                                )
                                            )
                                        }
                                        type="file"
                                    />
                                )}
                            </Button>

                            <div className={styles.photosProduct}>
                                {getFiles?.APARTMENT_INTERIOR &&
                                getFiles?.APARTMENT_INTERIOR.length ? (
                                    getFiles?.APARTMENT_INTERIOR.map(
                                        (image) => {
                                            return (
                                                <div
                                                    key={randomKey({
                                                        length: 15,
                                                    })}
                                                    className={`icon-preview-category ${styles.photoProduct}`}
                                                    style={{
                                                        background: `url(${config.public}${image.url}) center / contain no-repeat`,
                                                    }}
                                                >
                                                    <DeleteOutlined
                                                        onClick={() => {
                                                            dispatch({
                                                                type: 'DELETE_PHOTO_FROM_PHOTOS',
                                                                id: image?.id,
                                                            });
                                                        }}
                                                        className={
                                                            styles.deletePhoto
                                                        }
                                                    />
                                                    {image?.select ? (
                                                        <div
                                                            className={
                                                                styles.MainPhoto
                                                            }
                                                        >
                                                            Главное фото
                                                        </div>
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: 'SELECT_PREVIEW_PHOTO',
                                                                    id: image?.id,
                                                                });
                                                            }}
                                                        >
                                                            Поставить на превью
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    )
                                ) : (
                                    <h5>Фотографий нет</h5>
                                )}
                            </div>
                        </div>
                    </section>
                </Popover>
            </div>

            <Button onClick={editProduct} type="primary">
                Редактировать
            </Button>
        </div>
    );
};

export default EditProduct;
