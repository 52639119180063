import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../Services';

import styles from './styles/index.module.scss';

import ItemRedirect from './helpers/item';
import { Input, Pagination, Result, Button, Popconfirm } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const RedirectComp = () => {
    const dispatch = useDispatch();
    const getRedirectState = useSelector((state) => state.redirect);

    const [search_text, set_search_text] = useState('');
    const [page, set_page] = useState(1);
    const [limit, set_limit] = useState(50);
    const [check_status, set_check_status] = useState(false);
    const [select_items, set_select_items] = useState([]);

    useEffect(() => {
        api.redirect.getRedirects(page, search_text, limit);
    }, []);

    useEffect(() => {
        set_page(1);

        dispatch(api.redirect.getRedirects(1, search_text, limit));
    }, [search_text]);

    useEffect(() => {
        dispatch(api.redirect.getRedirects(page, search_text, limit));
    }, [page, limit]);

    const selectItem = (checked, id) => {
        let arr = select_items;
        if (checked) {
            arr.push(id);
            set_select_items(arr);
        } else {
            let newArr = arr.filter((item) => String(item) != String(id));
            set_select_items(newArr);
        }
    };

    const deleteSelectedLinks = () => {
        console.log(select_items);
        dispatch(
            api.redirect.deleteRedirect(
                '',
                'selected',
                JSON.stringify(select_items)
            )
        );
    };

    return (
        <content className="cities-page-block">
            <div className={styles.headPage}>
                <h1>Редирект ссылки</h1>

                <Input
                    size="large"
                    placeholder="Поиск: введите ссылку"
                    onChange={(val) => set_search_text(val.target.value)}
                />

                <div className={styles.optionsBlock}>
                    <Button
                        onClick={() => {
                            set_select_items([]);
                            set_check_status(!check_status);
                        }}
                        type="primary"
                    >
                        Режим выделения
                    </Button>
                    {check_status && (
                        <Popconfirm
                            placement="bottom"
                            title="Редиректы будет удалены?"
                            onConfirm={deleteSelectedLinks}
                            okText="Удалить"
                            cancelText="нет"
                        >
                            <Button>удалить выделенные</Button>
                        </Popconfirm>
                    )}
                </div>
            </div>

            {getRedirectState?.request_status ? (
                <div className={styles.blockDefault}>
                    <LoadingOutlined />
                </div>
            ) : getRedirectState?.data?.list &&
              getRedirectState?.data?.list.length ? (
                <div className={styles.items}>
                    {getRedirectState?.data?.list.map((item, index) => (
                        <ItemRedirect
                            check_status={check_status}
                            key={item._id}
                            styles={styles}
                            api={api}
                            dispatch={dispatch}
                            index={index}
                            item={item}
                            selectItem={selectItem}
                        />
                    ))}
                </div>
            ) : (
                <div className={styles.blockDefault}>
                    <Result
                        status="403"
                        title="Ничего не найдено"
                        subTitle="По запросу ничего не найдено"
                        extra={null}
                    />
                </div>
            )}

            <div className={styles.blockDefault} style={{ height: 50 }}>
                <Pagination
                    current={page}
                    pageSize={limit}
                    pageSizeOptions={[50, 100, 150, 200]}
                    onChange={(pageNum, pageSize) => {
                        set_page(pageNum);
                        set_limit(pageSize);
                    }}
                    total={getRedirectState?.data?.count || 0}
                />
            </div>
        </content>
    );
};

export default RedirectComp;
