import { __extends } from "tslib";
import { deepMix, isString, substitute } from '@antv/util';
import { modifyCSS } from '@antv/dom-util';
import DomStyles from './theme';
import { CONTAINER_CLASS, CONTAINER_TPL, TITLE_CLASS, RIBBON_CONTAINER_CLASS, RIBBON_TPL } from './constants';
import { Component } from '../../core/component';
import { clearDom } from '../../utils/dom';
var ContinueLegend = /** @class */ (function (_super) {
    __extends(ContinueLegend, _super);
    function ContinueLegend() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 获取默认配置
     */
    ContinueLegend.prototype.getDefaultOptions = function () {
        return deepMix({}, _super.prototype.getDefaultOptions.call(this), {
            id: 'l7plot-continue-legend',
            name: 'l7plot-continue-legend',
            title: '',
            containerTpl: CONTAINER_TPL,
            ribbonTpl: RIBBON_TPL,
            domStyles: DomStyles,
            className: CONTAINER_CLASS,
        });
    };
    /**
     * 初始化 container
     */
    ContinueLegend.prototype.initContainer = function () {
        var customContent = this.options.customContent;
        if (customContent) {
            var container = this.getHtmlContentNode(customContent);
            var parentContainer = this.getParentContainer();
            if (parentContainer) {
                parentContainer.appendChild(container);
            }
            return container;
        }
        else {
            return _super.prototype.initContainer.call(this);
        }
    };
    /**
     * 初始化 DOM
     */
    ContinueLegend.prototype.initDom = function () {
        this.cacheDoms();
    };
    /**
     * 初始化事件
     */
    ContinueLegend.prototype.initEvent = function () {
        //
    };
    /**
     * 清理事件
     */
    ContinueLegend.prototype.removeEvent = function () {
        //
    };
    /**
     * 缓存 DOM
     */
    ContinueLegend.prototype.cacheDoms = function () {
        var container = this.container;
        var titleDom = container.getElementsByClassName(TITLE_CLASS)[0];
        var ribbonContainerDom = container.getElementsByClassName(RIBBON_CONTAINER_CLASS)[0];
        this.titleDom = titleDom;
        this.ribbonContainerDom = ribbonContainerDom;
    };
    /**
     * 绘制组件
     */
    ContinueLegend.prototype.render = function () {
        if (this.options.customContent) {
            this.renderCustomContent(this.options.customContent);
        }
        else {
            this.resetTitle();
            this.renderRibbon();
        }
    };
    /**
     * 显示
     */
    ContinueLegend.prototype.show = function () {
        var container = this.container;
        if (!container || this.destroyed)
            return;
        modifyCSS(container, {
            visibility: 'visible',
        });
    };
    /**
     * 隐藏
     */
    ContinueLegend.prototype.hide = function () {
        var container = this.container;
        if (!container || this.destroyed)
            return;
        modifyCSS(container, {
            visibility: 'hidden',
        });
    };
    /**
     * 更新
     */
    ContinueLegend.prototype.updateInner = function (options) {
        if (this.options.customContent) {
            this.renderCustomContent(this.options.customContent);
        }
        else {
            if (options.title) {
                this.resetTitle();
            }
            if (options.colors) {
                this.renderRibbon();
            }
        }
        _super.prototype.updateInner.call(this, options);
    };
    /**
     * 根据 customContent 渲染 DOM
     */
    ContinueLegend.prototype.renderCustomContent = function (customContent) {
        var parentContainer = this.container.parentNode;
        var node = this.getHtmlContentNode(customContent);
        var curContainer = this.container;
        if (parentContainer) {
            parentContainer.replaceChild(node, curContainer);
        }
        this.container = node;
        this.applyStyles();
    };
    /**
     * 生成自定义内容 DOM
     */
    ContinueLegend.prototype.getHtmlContentNode = function (customContent) {
        var node;
        var element = customContent(this.options.title || '', this.options.min, this.options.max, this.options.colors);
        if (isString(element)) {
            node = this.createDom(element);
        }
        else {
            node = element;
        }
        return node;
    };
    /**
     * 重置 title
     */
    ContinueLegend.prototype.resetTitle = function () {
        var title = this.options.title;
        if (title) {
            this.showTitle();
            this.setTitle(title);
        }
        else {
            this.hideTitle();
        }
    };
    /**
     * 显示 title
     */
    ContinueLegend.prototype.showTitle = function () {
        var titleDom = this.titleDom;
        if (titleDom) {
            modifyCSS(titleDom, {
                display: 'block',
            });
        }
    };
    /**
     * 隐藏 title
     */
    ContinueLegend.prototype.hideTitle = function () {
        var titleDom = this.titleDom;
        if (titleDom) {
            modifyCSS(titleDom, {
                display: 'none',
            });
        }
    };
    /**
     * 设置 title 内容
     */
    ContinueLegend.prototype.setTitle = function (content) {
        var titleDom = this.titleDom;
        if (titleDom) {
            titleDom.innerHTML = content;
        }
    };
    /**
     * 渲染色带
     */
    ContinueLegend.prototype.renderRibbon = function () {
        this.clearRibbonContainerDoms();
        var _a = this.options, min = _a.min, max = _a.max, colors = _a.colors, _b = _a.ribbonTpl, ribbonTpl = _b === void 0 ? RIBBON_TPL : _b;
        var ribbonContainerDom = this.ribbonContainerDom;
        if (ribbonContainerDom) {
            var backgroundImage = "linear-gradient(to right, ".concat(colors.join(', '), ")");
            var substituteObj = { min: min, max: max, backgroundImage: backgroundImage };
            var domStr = substitute(ribbonTpl, substituteObj);
            var dom = this.createDom(domStr);
            ribbonContainerDom.appendChild(dom);
            this.applyChildrenStyles(ribbonContainerDom, this.options.domStyles);
        }
    };
    /**
     * 清空色带 DOM 下的 DOM 元素
     */
    ContinueLegend.prototype.clearRibbonContainerDoms = function () {
        if (this.ribbonContainerDom) {
            clearDom(this.ribbonContainerDom);
        }
    };
    /**
     * 清空所有
     */
    ContinueLegend.prototype.clear = function () {
        this.setTitle('');
        this.clearRibbonContainerDoms();
    };
    return ContinueLegend;
}(Component));
export { ContinueLegend };
