import React from 'react';
import styles from './styles/index.module.scss';

const BudgetCategories = ({
	status,
	data,
	close,

	select_category,
	isMarker = false
}) => {

	const renderItems = (cats) => {
		return (
			<div className={styles.catsList}>
				{
					cats && cats.length ?
						cats.map((cat)=>{
							return (
								<div key={cat._id}>
									{
										isMarker ?
											<p onClick={()=>(cat.marker_type || cat.premise_type) ? select_category(cat) : alert('Доступны только общие маркеры (МО) и маркеры помещения (МП)')}>{ cat.title } { cat.marker_type ? <small>МО</small> : cat.premise ? <b>П</b> : cat.premise_type ? <small>MП</small> : <b>K</b> }</p>
											:
											<p onClick={()=>(!cat.marker_type && !cat.premise_type) ? select_category(cat) : alert('Доступны только категории (К) и помещения (П)')}>{ cat.title } { cat.marker_type ? <small>МО</small> : cat.premise ? <b>П</b> : cat.premise_type ? <small>MП</small> : <b>K</b> }</p>

									}

									{
										cat.child_categories && cat.child_categories.length ? renderItems(cat.child_categories) : <></>
									}
								</div>
							)
						})
					:
						<></>
				}
			</div>
		)
	}

	return (
		<div className={`${styles.mainModal} ${status ? styles.mainModalOpen : false}`}>
			<div onClick={()=>close()} className={styles.overlay}></div>
			<div className={styles.content}>
				{renderItems(data)}
			</div>
		</div>
	)
}

export default BudgetCategories;