import Widgets from '@/Containers/Admin/Widgets';
import WidgetsCreate from '@/Containers/Admin/Widgets/create';
import WidgetsEdit from '@/Containers/Admin/Widgets/edit';
import WidgetGroupEdit from '@/Containers/Admin/Widgets/edit/Helpers/Group';

export default [
    {
        path: '/widgets',
        element: <Widgets />,
    },
    {
        path: '/widgets/create',
        element: <WidgetsCreate />,
    },
    {
        path: '/widgets/edit/group/:id',
        element: <WidgetGroupEdit />,
    },
    {
        path: '/widgets/edit/:id',
        element: <WidgetsEdit />,
    },
];
