import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { api } from '../../../Services';

import Prices from './Helpers/prices';
import FreeDownload from './Helpers/free-downloadd';
import Mortgage from './Helpers/Mortgage/mortgage';
import RoomNames from './Helpers/RoomNames';
import StatePrograms from './Helpers/StatePrograms';

import styles from './styles/index.module.scss';

const Settings = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(api.statistic.get());
        dispatch(api.statistic.settings());
    }, []);

    return (
        <div className={`statistic-block ${styles.blockPage}`}>
            <h1>Настройки</h1>

            <Prices api={api} />
            <StatePrograms api={api} />
            <Mortgage api={api} />
            <RoomNames api={api} />
            <FreeDownload api={api} />
        </div>
    );
};

export default Settings;
