import React from 'react';
import { Pannellum } from 'pannellum-react';
import '../../../styles/point.css';
import Config from '@/Config';
import currencyFormatter from 'currency-formatter';

const RenderPoint = (item, set_tour_index, scenes) => {
    const hotspotSceneCustom = (hotSpotDiv, args) => {
        hotSpotDiv.innerHTML = `
            <div class="hotspot-button">
                ${
                    scenes[args?.indexScene]?.title_room?.title ||
                    'Не определено'
                }
                <div class="hotspot-wrapper">
                    <div class="hotspot-content">
                        <h5>Переход на другую сцену</h5>
                        <div class="hotspot-cover" style="background: url(${
                            Config.public
                        }${
            scenes[args?.indexScene]?.url || 'uploads/default.jpg'
        }) center / cover no-repeat;"></div>
                        <h6>${
                            scenes[args?.indexScene]?.title_room?.title ||
                            'Не определено'
                        }</h6>
                    </div>
                </div>
            </div>
        `;
    };

    const hotspotProductCustom = (hotSpotDiv) => {
        hotSpotDiv.innerHTML = `
            <div class="hotspot-button hotspot-button-product">
                <div class="hotspot-button-product-icon"></div>
                <div class="hotspot-wrapper hotspot-wrapper-product">
                    <div class="hotspot-content">
                        <h5>Описание товара</h5>
                        <div class="hotspot-cover" style="background: url(${
                            Config.public
                        }${
            item?.product?.photo?.url || 'uploads/default.jpg'
        }) center / cover no-repeat;"></div>
                        <h6>${item?.product?.title}</h6>
                        <b>
                        ${
                            currencyFormatter.format(
                                item?.product?.price || 0,
                                {
                                    code: 'EUR',
                                    symbol: '',
                                }
                            ) + '₸'
                        }
                        </b>
                    </div>
                </div>
            </div>
        `;
    };

    if (item.type === 'NEW_SCENE') {
        return (
            <Pannellum.Hotspot
                key={Math.random() + item?.indexScene}
                type="custom"
                pitch={item.position.pitch}
                yaw={item.position.yaw}
                handleClick={(e, { indexScene }) => {
                    set_tour_index(indexScene);
                }}
                handleClickArg={{ indexScene: item?.indexScene }}
                tooltipArg={item}
                tooltip={hotspotSceneCustom}
            />
        );
    } else if (item.type === 'NEW_POINT') {
        return (
            <Pannellum.Hotspot
                key={Math.random() + set_tour_index}
                type="custom"
                pitch={item.position.pitch}
                yaw={item.position.yaw}
                tooltipArg={item}
                tooltip={hotspotProductCustom}
            />
        );
    }
};

export default RenderPoint;
