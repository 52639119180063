const initialState = {
	blocks:null,

	blocks_for_create_form:null,

	request_blocks_status:false
}

const blocks = (state = initialState, action) => {
	switch(action.type){

		case 'UPDATE_BLOCKS' :
			return {
				...state,
				blocks:action.data
			}

		case 'UPDATE_BLOCKS_FOR_CREATE_FORM' :
			return {
				...state,
				blocks_for_create_form:action.data
			}

		case 'REQUEST_BLOCKS_STATUS' :
			return {
				...state,
				request_blocks_status:action.status
			}

		default :
			return state
	}
}

export default blocks;