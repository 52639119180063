import axios from '../axios';
import blocks from '../Blocks';
import { message } from 'antd';

const apiblocks = blocks;

const flats = {
    getTemplatesOfRealestate: (realestate_id) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: true,
            });

            let { data } = await axios.get(
                `/v1/admin/realestate/templates/${realestate_id}`
            );

            dispatch({
                type: 'UPDATE_TEMPLATES_OF_REALESTATE',
                data: data.length ? data : [],
            });

            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });
            message.error('Ошибка. Проверьте Ваши права администратора');
            return;
        }
    },
    getTemplatesForRealestate: (realestate_id) => async (dispatch) => {
        try {
            let { data } = await axios.get(
                `/v1/admin/apartments/template/${realestate_id}`
            );

            dispatch({
                type: 'TEMPLATES_OF_REALESTATE',
                data,
            });

            return;
        } catch (err) {
            message.error('Ошибка. Проверьте Ваши права администратора');
            return;
        }
    },
    promotionFlat: (flat_id) => async (dispatch) => {
        try {
            await axios.post('/v1/admin/apartments/flats/promotion', {
                flat_id,
            });

            dispatch({
                type: 'PROMOTIOM_SINGLE_FLAT',
                flat_id,
            });

            return;
        } catch (err) {
            message.error('Ошибка. Проверьте Ваши права администратора');
            return;
        }
    },
    filterFlats:
        (fields, page = 1) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: true,
                });

                let { data } = await axios.get(
                    '/v1/admin/apartments/flats/filter',
                    {
                        params: {
                            ...fields,
                            page,
                        },
                    }
                );

                if (!data.length) {
                    message.success('Квартир не найдено');
                }

                dispatch({
                    type: 'UPDATE_FLATS',
                    data,
                });

                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
                return;
            }
        },
    saveRedirectFlat: (id_flat, redirect_url) => async (dispatch) => {
        try {
            await axios.post(`/v1/admin/apartments/flats/update/redirect-url`, {
                id: id_flat,
                redirect_url,
            });

            dispatch({
                type: 'UPDATE_REDIRECT_URL_FLAT',
                id: id_flat,
                redirect_url,
            });
        } catch (err) {
            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    get:
        (page = 1) =>
        async (dispatch) => {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: true,
            });

            try {
                let { data } = await axios.get(
                    `/v1/admin/apartments?page=${page}`
                );

                dispatch({
                    type: 'UPDATE_FLATS',
                    data: data.length ? data : null,
                });

                setTimeout(() => {
                    dispatch({
                        type: 'REQUEST_FLATS_STATUS',
                        status: false,
                    });
                }, 1000);
            } catch (err) {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    updateFlatAreaPoints:
        (flat_id, EditBlockAreas, EditAreaPoints) => async () => {
            try {
                let { data } = await axios.post(
                    '/v1/admin/apartments/flat/update-flat-area-points',
                    {
                        id: flat_id,
                        blockArea: EditBlockAreas,
                        areaPointsString: EditAreaPoints,
                    }
                );

                message.success(data);
            } catch (err) {
                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    getComplexAll: () => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_FLATS_REALESTATE_STATUS',
                status: true,
            });

            let complexApartments = await axios.get(
                `/v1/admin/apartments-complex`,
                {
                    params: {
                        all: true,
                    },
                }
            );

            dispatch({
                type: 'UPDATE_COMPLEX_APARTMENTS_FOR_TEMPLATES',
                data: complexApartments.data.length
                    ? complexApartments.data
                    : [],
            });

            dispatch({
                type: 'REQUEST_FLATS_REALESTATE_STATUS',
                status: false,
            });

            return;
        } catch (err) {
            message.error('Ошибка. Проверьте Ваши права администратора');

            dispatch({
                type: 'REQUEST_FLATS_REALESTATE_STATUS',
                status: false,
            });

            return;
        }
    },
    getTemplates: (page, id, statusPage) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_FLATS_STATUS',
            status: true,
        });

        id = id == '' ? undefined : id;

        try {
            let { data } = await axios.get(
                `/v1/admin/apartments/flats/templates/${id}?page=${page}&statusPage=${statusPage}`
            );

            if (data.length) {
                dispatch({
                    type: 'UPDATE_TEMPLATES',
                    data: data.length ? data : [],
                });
            } else {
                message.warning('Записей не найдено');
            }

            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    editTitle: (title, id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_FLATS_STATUS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/apartments/flats/update/title', {
                title,
                id,
            });

            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });

            message.success('Название квартиры успешно изменено');
        } catch (err) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });
        }
    },
    deleteTemplate: (id) => async (dispatch) => {

        try {
            await axios.post('/v1/admin/apartments/flats/templates/remove', {
                id,
            });

            return dispatch({
                type: 'REMOVE_TEMPLATE',
                id,
            });

        } catch (err) {
            return message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    copyFloor: (fields) => async (dispatch) => {
        let { block_id, apartment_complex_id, floor, copyFloor } = fields;

        dispatch({
            type: 'REQUEST_FLATS_STATUS',
            status: true,
        });

        try {
            let getFloor = await apiblocks.getFloorByBlockNotDispatch(
                block_id,
                apartment_complex_id,
                copyFloor
            );

            if (getFloor.flats && getFloor.flats.length >= 2) {
                let { data } = await axios.post(
                    '/v1/admin/apartments/floors/copy',
                    {
                        ...fields,
                        flats: getFloor.flats,
                    }
                );

                dispatch(
                    apiblocks.getFloorByBlock(
                        block_id,
                        apartment_complex_id,
                        floor
                    )
                );

                return message.success(data);
            } else {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                return message.error(
                    'Квартир не было найдено на этом этаже, либо их слишком мало'
                );
            }
        } catch (err) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });
        }
    },
    editTemplateFromRealestate: (fields, close = null) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_FORM_TEMPLATE_STATUS',
            status: true
        });

        try {
            const { data } = await axios.post('/v1/admin/apartments/update/template', {
                ...fields,
                realestate: JSON.stringify(fields?.apartmentComplex),
            });

            dispatch({
                type: 'UPDATE_ONE_TEMPLATE_OF_REALESTATE',
                item: data
            });

            dispatch({
                type: 'REQUEST_FORM_TEMPLATE_STATUS',
                status: false
            });

            if(close){
                close();
            }

            message.success('Шаблон успешно обновлен');
            return {
                status: true,
            };
        } catch (err) {
            dispatch({
                type: 'REQUEST_FORM_TEMPLATE_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
            return {
                status: false,
            };
        }
    },
    createTemplateFromRealestate: (fields, close = null) => async (dispatch) => {

        dispatch({
            type: 'REQUEST_FORM_TEMPLATE_STATUS',
            status: true
        });

        try {
            const { data } = await axios.post('/v1/admin/apartments/create', {
                ...fields,
                realestate: JSON.stringify(fields?.apartmentComplex),
            });

            dispatch({
                type: 'ADD_ONE_TEMPLATE_OF_REALESTATE',
                item: data,
            });

            dispatch({
                type: 'REQUEST_FORM_TEMPLATE_STATUS',
                status: false
            });

            if(close){
                close();
            }

            message.success('Шаблон успешно создан');
        } catch (err) {
            dispatch({
                type: 'REQUEST_FORM_TEMPLATE_STATUS',
                status: false
            });
            message.error('Ошибка. Проверьте Ваши права администратора ', err);
        }
    },
    create: (fields) => async (dispatch) => {
        let {
            title,
            square_metres,
            total_price,
            square_metre_price,
            plan_photo,
            ceiling_height,
            apartmentComplex,
        } = fields;

        if (
            apartmentComplex &&
            apartmentComplex.length &&
            plan_photo &&
            title &&
            ceiling_height &&
            square_metres &&
            total_price &&
            square_metre_price
        ) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: true,
            });

            try {
                await axios.post('/v1/admin/apartments/create', {
                    ...fields,
                    plan_photo: plan_photo.id,
                    realestate: JSON.stringify(apartmentComplex),
                });

                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.success('Шаблон успешно создан');
                return {
                    status: true,
                };
            } catch (err) {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
                return {
                    status: false,
                };
            }
        }

        message.error('Заполните все поля!');
        return {
            status: false,
        };
    },
    createwithPoints: (fields) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_FLATS_STATUS',
            status: true,
        });

        if (fields.title && fields.template && fields.template != 'none') {
            try {
                await axios.post('/v1/admin/apartments/create-with-points', {
                    ...fields,
                });

                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                await dispatch(
                    apiblocks.getFloorByBlock(
                        fields.block_id,
                        fields.apartment_complex_id,
                        fields.floor
                    )
                );

                message.success('Квартира успешно создана');
                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
                return {
                    status: false,
                };
            }
        }

        message.error('Заполните все поля!');
    },
    getFlatsForBlock: (id, floor) => async (dispatch) => {
        try {
            let { data } = await axios.get(
                `/v1/admin/apartments/block/${id}/${floor}`
            );
            dispatch({
                type: 'UPDATE_FLATS_FOR_BLOCK',
                data,
            });
        } catch (err) {
            message.error('ошибка при загрузке квартир. Попробуйте позже');
        }
    },
    updatePointsPlanSVG: (x, y, id, floor) => async () => {
        try {
            await axios.post(`/v1/admin/apartments/points/${id}/${floor}`, {
                x,
                y,
            });

            message.success('Информация обновлена');
        } catch (err) {
            message.error('ошибка при загрузке квартир. Попробуйте позже');
        }
    },
    delete: (id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_FLATS_STATUS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/apartments/delete', {
                id,
            });

            dispatch({
                type: 'REMOVE_SINGLE_FLAT',
                id,
            });

            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });

            message.success('Квартира успешно удалена');
        } catch (err) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    getflat: (id) => async () => {
        try {
            let { data } = await axios.get(`/v1/admin/apartments/${id}`);
            console.log(222, data);
            return {
                status: true,
                ...data,
            };
        } catch (err) {
            return {
                status: false,
            };
        }
    },
    getTemplate: (id) => async () => {
        try {
            let { data } = await axios.get(`/v1/admin/apartments/templates/${id}`);

            return {
                status: true,
                ...data,
            };
        } catch (err) {
            return {
                status: false,
            };
        }
    },
    update: (fields) => async (dispatch) => {
        let {
            title,
            square_metres,
            count_rooms,
            material_price,
            total_price,
            square_metre_price,
            floor,
            files,
            plan_photo,
            photos,
            ceiling_height,
            preview_photo,
        } = fields;

        if (
            title &&
            preview_photo &&
            ceiling_height &&
            square_metres &&
            count_rooms &&
            material_price &&
            total_price &&
            square_metre_price &&
            floor &&
            files &&
            plan_photo &&
            photos
        ) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: true,
            });

            try {
                await axios.post('/v1/admin/apartments/update', {
                    ...fields,
                    plan_photo: plan_photo.id,
                    preview_photo: preview_photo.id,
                });

                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.success('Информация успешно обновлена');
                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
                return;
            }
        }

        message.error('Заполните все поля!');
    },
    updateTemplate: (fields, history) => async (dispatch) => {
        let {
            title,
            square_metres,
            total_price,
            square_metre_price,
            plan_photo,
            ceiling_height,
            preview_photo,
            apartmentComplex,
        } = fields;

        if (
            apartmentComplex &&
            apartmentComplex.length > 0 &&
            plan_photo &&
            title &&
            String(ceiling_height) &&
            String(square_metres) &&
            String(total_price) &&
            String(square_metre_price)
        ) {
            dispatch({
                type: 'REQUEST_FLATS_STATUS',
                status: true,
            });

            try {
                await axios.post('/v1/admin/apartments/update/template', {
                    ...fields,
                    plan_photo: plan_photo.id,
                    preview_photo: preview_photo.id,
                    realestate: JSON.stringify(apartmentComplex),
                });

                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.success('Информация успешно обновлена');
                return history('/apartments/flats/templates');
                //window.location.href = '/apartments/flats/templates';
            } catch (err) {
                dispatch({
                    type: 'REQUEST_FLATS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
                return;
            }
        }

        message.error('Заполните все поля!');
    },
    soldFlat: (id, status) => async (dispatch) => {
        try {
            await axios.post('/v1/admin/apartments/update/sold', {
                id,
                status,
            });

            dispatch({
                type: 'UPDATE_FLAT_SOLD',
                id,
            });

            message.success('Информация успешно обновлена');
        } catch (err) {
            message.error(err.response.data);
        }
    },
};

export default flats;
