"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unregisterIconFont = exports.registerIconFonts = exports.registerIconFont = exports.ICON_FONT_CACHE = exports.unregisterFontFace = exports.registerFontFace = exports.FONT_FACE_CACHE = exports.unregisterImage = exports.hasImage = exports.getImage = exports.registerImages = exports.registerImage = exports.IMAGES_CACHE = void 0;
/**
 * 自定义添加图片资源
 */
exports.IMAGES_CACHE = new Map();
function registerImage(id, image) {
    exports.IMAGES_CACHE.set(id, image);
}
exports.registerImage = registerImage;
function registerImages(images) {
    images.forEach(function (iconFont) {
        exports.IMAGES_CACHE.set(iconFont.id, iconFont.image);
    });
}
exports.registerImages = registerImages;
function getImage(id) {
    return exports.IMAGES_CACHE.get(id);
}
exports.getImage = getImage;
function hasImage(id) {
    return exports.IMAGES_CACHE.has(id);
}
exports.hasImage = hasImage;
function unregisterImage(id) {
    return exports.IMAGES_CACHE.delete(id);
}
exports.unregisterImage = unregisterImage;
/**
 * 自定义添加第三方字体
 */
exports.FONT_FACE_CACHE = new Map();
function registerFontFace(fontFamily, fontPath) {
    exports.FONT_FACE_CACHE.set(fontFamily, fontPath);
}
exports.registerFontFace = registerFontFace;
function unregisterFontFace(fontFamily) {
    return exports.FONT_FACE_CACHE.delete(fontFamily);
}
exports.unregisterFontFace = unregisterFontFace;
/**
 * 自定义添加 iconfont
 */
exports.ICON_FONT_CACHE = new Map();
function registerIconFont(name, fontUnicode) {
    exports.ICON_FONT_CACHE.set(name, fontUnicode);
}
exports.registerIconFont = registerIconFont;
function registerIconFonts(iconFonts) {
    iconFonts.forEach(function (iconFont) {
        exports.ICON_FONT_CACHE.set(iconFont.name, iconFont.fontUnicode);
    });
}
exports.registerIconFonts = registerIconFonts;
function unregisterIconFont(name) {
    return exports.ICON_FONT_CACHE.delete(name);
}
exports.unregisterIconFont = unregisterIconFont;
