import axios from '../axios';
import { message } from 'antd';

const cities = {
    deleteDistrict: (id) => async (dispatch) => {
        dispatch({
            type: 'DISTRICT_REQUEST_STATUS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/cities/district/delete', {
                id,
            });

            dispatch({
                type: 'DISTRICT_REQUEST_STATUS',
                status: false,
            });

            message.success('Район успешно удален');

            return dispatch(cities.getCities());
        } catch (err) {
            dispatch({
                type: 'DISTRICT_REQUEST_STATUS',
                status: false,
            });
            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    editDistrict: (title, id) => async (dispatch) => {
        if (!title) {
            return message.warning('Заполните необходимые поля*');
        }

        dispatch({
            type: 'DISTRICT_REQUEST_STATUS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/cities/district/edit', {
                title,
                id,
            });

            dispatch({
                type: 'DISTRICT_REQUEST_STATUS',
                status: false,
            });

            dispatch({
                type: 'DISTRICT_REQUEST_SUCCESS',
                status: true,
            });

            message.success('Район успешно обновлен');

            return dispatch(cities.getCities());
        } catch (err) {
            dispatch({
                type: 'DISTRICT_REQUEST_STATUS',
                status: false,
            });
            dispatch({
                type: 'DISTRICT_REQUEST_SUCCESS',
                status: false,
            });
            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    createDistrict: (fields) => async (dispatch) => {
        let { select_city, district_title, select_district } = fields;

        if (!select_city || !district_title) {
            return message.warning('Заполните необходимые поля*');
        }

        dispatch({
            type: 'DISTRICT_REQUEST_STATUS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/cities/district/add', {
                title: district_title,
                city: select_city,
                disctrict: select_district,
            });

            dispatch({
                type: 'DISTRICT_REQUEST_STATUS',
                status: false,
            });

            dispatch({
                type: 'DISTRICT_REQUEST_SUCCESS',
                status: true,
            });

            message.success('Район успешно добавлен');

            return dispatch(cities.getCities());
        } catch (err) {
            dispatch({
                type: 'DISTRICT_REQUEST_STATUS',
                status: false,
            });
            dispatch({
                type: 'DISTRICT_REQUEST_SUCCESS',
                status: false,
            });
            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    getCities: () => async (dispatch) => {
        dispatch({
            type: 'REQUEST_CITY_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/cities');

            dispatch({
                type: 'UPDATE_CITIES',
                data: data.length ? data : [],
            });

            dispatch({
                type: 'REQUEST_CITY_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_CITY_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    getCountries: () => async (dispatch) => {
        dispatch({
            type: 'REQUEST_COUNTRY_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/countries');

            dispatch({
                type: 'UPDATE_COUNTRIES',
                data: data.length ? data : null,
            });

            dispatch({
                type: 'REQUEST_COUNTRY_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_COUNTRY_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    addCity: (fields) => async (dispatch) => {
        let {
            title_city,
            seo_title,
            seo_keywords,
            seo_description,
            seo_content,
        } = fields;

        if (title_city) {
            dispatch({
                type: 'REQUEST_CITY_STATUS',
                status: true,
            });

            try {
                await axios.post('/v1/admin/cities', {
                    title_city,
                    seo_title,
                    seo_keywords,
                    seo_description,
                    seo_content,
                });

                dispatch({
                    type: 'REQUEST_CITY_STATUS',
                    status: false,
                });

                dispatch(cities.getCities());
                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_CITY_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
                return;
            }
        }

        return message.warning('Заполните обязательные поля');
    },
    addCountry: (fields) => async (dispatch) => {
        let { title, seo_title, seo_keywords, seo_description } = fields;

        if (title) {
            dispatch({
                type: 'REQUEST_COUNTRY_STATUS',
                status: true,
            });

            try {
                await axios.post('/v1/admin/countries', {
                    title,
                    seo_title,
                    seo_keywords,
                    seo_description,
                });

                dispatch({
                    type: 'REQUEST_COUNTRY_STATUS',
                    status: false,
                });

                dispatch(cities.getCountries());
                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_COUNTRY_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
                return;
            }
        }

        return message.warning('Заполните обязательные поля');
    },
    changeCity:
        (title, id, seoTitle, seoKeywords, seoDescription, content) =>
        async (dispatch) => {
            if (title) {
                try {
                    await axios.post('/v1/admin/cities/update', {
                        title,
                        id,
                        seoTitle,
                        seoKeywords,
                        seoDescription,
                        content,
                    });

                    dispatch(cities.getCities());

                    return message.success('Информация успешно изменена');
                } catch (err) {
                    message.error(
                        'Ошибка. Проверьте Ваши права администратора'
                    );
                    return;
                }
            }

            return message.warning('Заполните обязательные поля');
        },
    changeCountry:
        (title, id, seoTitle, seoKeywords, seoDescription) =>
        async (dispatch) => {
            if (title) {
                try {
                    await axios.post('/v1/admin/countries/update', {
                        title,
                        id,
                        seoTitle,
                        seoKeywords,
                        seoDescription,
                    });

                    dispatch(cities.getCountries());

                    return message.success('Информация успешно изменена');
                } catch (err) {
                    message.error(
                        'Ошибка. Проверьте Ваши права администратора'
                    );
                    return;
                }
            }

            return message.warning('Заполните обязательные поля');
        },
    removeCity: (id) => async (dispatch) => {
        try {
            await axios.delete(`/v1/admin/cities/${id}`);

            dispatch(cities.getCities());

            return message.success('Город успешно удален');
        } catch (err) {
            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
};

export default cities;
