import React from 'react';
import { Link } from 'react-router-dom';
import LogoLogovo from '../../assets/new-logo.svg';
import styles from './styles/index.module.scss';
import { useSelector } from 'react-redux';
import { api } from '@/Services';

const Header = () => {
    const userState = useSelector((state) => state.user);

    return (
        <header className={styles.headerMain}>
            <Link to="/">
                <img src={LogoLogovo} />
            </Link>
            <div className={styles.rightHeader}>
                <small
                    onClick={() => {
                        api.logs.rebuild();
                    }}
                    className={styles.buttonBuild}
                >
                    BUILD
                </small>

                <b>{userState?.user?.email}</b>
                <span className={styles.superadmin}>
                    {userState?.user?.role?.title || 'Администратор'}
                </span>
            </div>
        </header>
    );
};

export default Header;
