"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.L7Plot = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var map_1 = require("../core/map");
var utils_1 = require("../utils");
var layer_group_1 = require("../core/layer/layer-group");
var types_1 = require("./types");
var DEFAULT_OPTIONS = {};
var L7Plot = /** @class */ (function (_super) {
    tslib_1.__extends(L7Plot, _super);
    function L7Plot(container, options) {
        var _this = _super.call(this, options) || this;
        /**
         * 图表实例
         */
        _this.plots = [];
        _this.container = _this.createContainer(container);
        _this.theme = _this.createTheme();
        _this.scene = _this.createScene();
        _this.registerResources();
        _this.render();
        _this.inited = true;
        return _this;
    }
    /**
     * 获取默认配置
     */
    L7Plot.prototype.getDefaultOptions = function () {
        return (0, utils_1.deepAssign)({}, map_1.Map.DefaultOptions, L7Plot.DefaultOptions);
    };
    /**
     * 创建所有图层
     */
    L7Plot.prototype.createLayers = function () {
        var layerGroup = new layer_group_1.LayerGroup([]);
        var layers = this.options.layers || [];
        for (var index = 0; index < layers.length; index++) {
            var layer = this.createLayer(layers[index]);
            layerGroup.addLayer(layer);
        }
        return layerGroup;
    };
    /**
     * 创建图层实例
     */
    L7Plot.prototype.createLayer = function (layerConfig) {
        var type = layerConfig.type, options = tslib_1.__rest(layerConfig, ["type"]);
        var LayerClass = types_1.LAYERS_MAP[type];
        if ((0, util_1.isUndefined)(LayerClass)) {
            throw new Error("Don't exist ".concat(type, " layer"));
        }
        var layer = new LayerClass(options);
        return layer;
    };
    /**
     * 添加图层
     */
    L7Plot.prototype.addLayer = function (layer) {
        var isLayerClass = function (layer) {
            return typeof layer['render'] === 'function';
        };
        if (isLayerClass(layer)) {
            _super.prototype.addLayer.call(this, layer);
        }
        else {
            var plotLayer = this.createLayer(layer);
            _super.prototype.addLayer.call(this, plotLayer);
        }
    };
    /**
     * 移除图层
     */
    L7Plot.prototype.removeLayerByName = function (name) {
        var layer = this.layerGroup.getLayerByName(name);
        if (layer) {
            return this.layerGroup.removeLayer(layer);
        }
        return false;
    };
    /**
     * 更新图层
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    L7Plot.prototype.updateLayers = function (layers) {
        //
    };
    /**
     * 更新 Plot
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    L7Plot.prototype.updatePlots = function (plots) {
        //
    };
    /**
     * 渲染
     */
    L7Plot.prototype.render = function () {
        var _this = this;
        var layerGroup = this.createLayers();
        if (this.inited) {
            this.layerGroup.removeAllLayer();
            layerGroup.addTo(this.scene);
            this.layerGroup = layerGroup;
            this.updateControls();
        }
        else {
            this.layerGroup = layerGroup;
            if (this.scene['sceneService'].loaded) {
                this.onSceneLoaded();
            }
            else {
                this.scene.once('loaded', function () {
                    _this.onSceneLoaded();
                });
            }
        }
    };
    /**
     * scene 加载成功回调
     */
    L7Plot.prototype.onSceneLoaded = function () {
        var _this = this;
        this.sceneLoaded = true;
        if (this.layerGroup.isEmpty()) {
            this.onLayersLoaded();
        }
        else {
            this.layerGroup.once('inited-all', function () {
                _this.onLayersLoaded();
            });
        }
        this.layerGroup.addTo(this.scene);
    };
    /**
     * 图层加载成功回调
     */
    L7Plot.prototype.onLayersLoaded = function () {
        this.layersLoaded = true;
        this.renderPlots();
        this.initControls();
        this.loaded = true;
        this.emit('loaded');
    };
    /**
     * 渲染 plots
     */
    L7Plot.prototype.renderPlots = function () {
        var plots = this.options.plots || [];
        for (var index = 0; index < plots.length; index++) {
            var plot = plots[index];
            var plotInstance = this.createPlot(plot);
            this.plots.push(plotInstance);
        }
    };
    /**
     * 创建 plot
     */
    L7Plot.prototype.createPlot = function (plot) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var type = plot.type, legend = plot.legend, layerMenu = plot.layerMenu, options = tslib_1.__rest(plot, ["type", "legend", "layerMenu"]);
        var PlotClass = types_1.PLOTS_MAP[type];
        if ((0, util_1.isUndefined)(PlotClass)) {
            throw new Error("Don't exist ".concat(type, " plot"));
        }
        var plotInstance = new PlotClass(options);
        plotInstance.attachToScene(this.scene, this.theme);
        return plotInstance;
    };
    /**
     * 添加图表
     */
    L7Plot.prototype.addPlot = function (plotConfig) {
        // TODO: duplicate plot
        var plotInstance = this.createPlot(plotConfig);
        this.plots.push(plotInstance);
    };
    /**
     * 获取所有图表
     */
    L7Plot.prototype.getPlots = function () {
        return this.plots;
    };
    /**
     * 根据图表名称获取图表
     */
    L7Plot.prototype.getPlotByName = function (name) {
        return this.plots.find(function (plot) { var _a; return ((_a = plot.options) === null || _a === void 0 ? void 0 : _a.name) === name; });
    };
    /**
     * 根据图表名称移除图表
     */
    L7Plot.prototype.removePlotByName = function (name) {
        var layerIndex = this.plots.findIndex(function (plot) { var _a; return ((_a = plot.options) === null || _a === void 0 ? void 0 : _a.name) === name; });
        if (layerIndex === -1)
            return false;
        var _a = tslib_1.__read(this.plots.splice(layerIndex, 1), 1), plot = _a[0];
        plot.unattachFromScene();
        return true;
    };
    /**
     * 移除所有的图表
     */
    L7Plot.prototype.removeAllPlot = function () {
        this.plots.forEach(function (plot) {
            plot.unattachFromScene();
        });
        this.plots = [];
    };
    /**
     * 默认的 options 配置项
     */
    L7Plot.DefaultOptions = DEFAULT_OPTIONS;
    return L7Plot;
}(map_1.Map));
exports.L7Plot = L7Plot;
