import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import moment from 'moment';
import { Input, Button, Tooltip } from 'antd';
import styles from './styles/index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Login = () => {
    let dispatch = useDispatch();
    let getAuthState = useSelector((state) => state.auth);

    let [phoneOrEmail, setPhoneOrEmail] = useState('');
    let [password, setPassword] = useState('');

    useEffect(() => {
        dispatch({
            type: 'REQUEST_STATUS_LOGIN',
            status: false,
        });
    }, []);

    const loginAdmin = () => {
        return dispatch(api.auth.login(phoneOrEmail, password));
    };

    return (
        <div className={styles.authBlock}>
            <div className={styles.blockForm}>
                <h1>Авторизация</h1>

                <Tooltip
                    trigger={['hover', 'blur']}
                    title="Email"
                    placement="topLeft"
                    className={styles.tooltip}
                >
                    <Input
                        size="large"
                        onChange={(value) => {
                            setPhoneOrEmail(value.target.value);
                        }}
                        type="text"
                        placeholder="Телефон или email"
                    />
                </Tooltip>

                <Tooltip
                    trigger={['hover', 'blur']}
                    title="пароль"
                    placement="topLeft"
                    className={styles.tooltip}
                >
                    <Input
                        size="large"
                        onChange={(value) => {
                            setPassword(value.target.value);
                        }}
                        type="password"
                        placeholder="Пароль"
                    />
                </Tooltip>

                <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                        if (!getAuthState.requestLogin) {
                            return loginAdmin();
                        }
                    }}
                >
                    {getAuthState.requestLogin ? (
                        <>
                            <LoadingOutlined /> Подождите...
                        </>
                    ) : (
                        'Войти'
                    )}
                </Button>

                <div className={styles.footer}>
                    <p>Logovo.kz ©{moment().format('YYYY')}</p>
                </div>
            </div>
        </div>
    );
};

export default Login;
