import React from 'react';
import { api } from '../../../../../../Services';
import { NotificationManager } from 'react-notifications';
import styles from '../../../styles/index.module.scss';
import FilItem from './item';

const FilesFlat = ({ template = null }) => {
    return (
        <div className={`block-flat-files ${styles.filesForm}`}>
            <FilItem
                template={template}
                api={api}
                paidTitleOne="Autocad"
                paidTitleOneTypeForDispatch="FLAT_ARRANGEMENT_AUTOCAD"
                NotificationManager={NotificationManager}
                title="ПЛАНИРОВКА КВАРТИРЫ"
            />

            <FilItem
                template={template}
                api={api}
                paidTitleOne="3ds max"
                paidTitleOneTypeForDispatch="APARTMENT_MODEL_3DMAX"
                NotificationManager={NotificationManager}
                title="3Д МОДЕЛЬ КВАРТИРЫ"
            />
        </div>
    );
};

export default FilesFlat;
