"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mappingLayer = void 0;
var layer_1 = require("../../adaptor/layer");
function mappingLayer(layer, options) {
    var field = options.field, content = options.content, 
    // rotate,
    _a = options.style, 
    // rotate,
    style = _a === void 0 ? {} : _a, state = options.state;
    var fontSize = style.fontSize, fill = style.fill;
    // mapping shape
    var txet = field ? field : content ? content : '';
    var shape = { field: txet, value: 'text' };
    layer_1.MappingLayer.shape(layer, shape);
    // mapping size
    fontSize && layer_1.MappingLayer.size(layer, fontSize);
    // mapping color
    fill && layer_1.MappingLayer.color(layer, fill);
    // mapping style
    style && layer_1.MappingLayer.style(layer, style);
    // mapping rotate
    // rotate && MappingLayer.rotate(layer, rotate);
    // mapping state
    state && layer_1.MappingLayer.state(layer, state);
}
exports.mappingLayer = mappingLayer;
