import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Select, Button, Tag } from 'antd';
import styles from '../../styles/index.module.scss';
import {
    MERCHANT_SERVICE,
    BUILDER
} from './constants';
import { api } from '../../../../../Services';

const labelStyle = {
    margin: 0,
    height: 35
}

const UserAccesses = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { edit_user, access_data_service, request_access_data_service } = useSelector(state => state?.users);

    const typeRoleWatch = Form.useWatch('type', form);
    Form.useWatch('shop', form);
    Form.useWatch('builder', form);

    const onFinish = (values) => {
        return dispatch(api.users.userAccess(values, edit_user?.user?._id));
    }

    useEffect(()=>{

        form.setFieldsValue({
            shop: null,
            builder: null
        });

        if((typeRoleWatch === MERCHANT_SERVICE) || (typeRoleWatch === BUILDER)){
            dispatch(api.users.getSBR(typeRoleWatch));
        }

    }, [typeRoleWatch]);

    return (
        <>

            <div>
                <h4>{edit_user?.user?.lastname + ' ' + edit_user?.user?.name} { edit_user?.user?.email }</h4>
                <Tag color='magenta'>Роль на данный момент: { edit_user?.user?.role?.title } ({edit_user?.user?.role?.description})</Tag>
                {
                    edit_user?.access ? (
                        <Tag>{ edit_user?.access?.title || '-' }</Tag>
                    ) : <></>
                }
            </div>

            <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                initialValues={{
                    type: null,
                    shop: null,
                    builder: null
                }}
                autoComplete="off"
                size='large'
                layout='vertical'
            >
                <Form.Item
                    label="Выберите роль"
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: 'Выберите роль пользователя',
                        },
                    ]}
                    className={styles.itemForm}
                    labelCol={{ style: labelStyle}}
                >
                    <Select
                        showSearch
                        placeholder="Выберите роль пользователя"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={edit_user?.roles || []}
                    />
                </Form.Item>

                {
                    typeRoleWatch === MERCHANT_SERVICE || typeRoleWatch === BUILDER ?

                        <Form.Item
                            label={typeRoleWatch === MERCHANT_SERVICE ? "Выберите магазин" : typeRoleWatch === BUILDER ? "Выберите застройщика" : ''}
                            name={typeRoleWatch === MERCHANT_SERVICE ? "shop" : typeRoleWatch === BUILDER ? "builder" : ''}
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                            className={styles.itemForm}
                            labelCol={{ style: labelStyle}}
                        >
                            <Select
                                loading={request_access_data_service}
                                showSearch
                                placeholder={typeRoleWatch === MERCHANT_SERVICE ? "Выберите магазин" : typeRoleWatch === BUILDER ? "Выберите застройщика" : ''}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={access_data_service || []}
                            />
                        </Form.Item> 
                    :
                        <></>
                }

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
        </>
    )

}

export default UserAccesses;