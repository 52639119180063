import React, { useState } from 'react';
import styles from '../styles/index.module.scss';
import { Button, Tag, Popconfirm, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { moment } from '@/Services/';
import {
    PlusOutlined,
    MinusOutlined,
    EditOutlined,
    DeleteOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';

const ItemWidget = ({ item, dispatch, api }) => {
    const [opened, set_opened] = useState(false);

    let link =
        item?.type == '3DTOURS'
            ? `/widgets/edit/group/${item._id}`
            : `/widgets/edit/${item._id}`;

    const deleteWidget = () => {
        return dispatch(api.widgets.remove(item?._id));
    };

    const content = (
        <div className={styles.contentPopover}>
            {
                item?.referers && item?.referers.length ?
                    item?.referers.map((referer, index)=>(
                        <h5 key={index + Math.random()}>{index + 1}: {referer && referer.length ? <a title={referer} href={referer} target="_blank">{referer}</a> : 'Url не удалось определить'}</h5>
                    ))
                :
                    <h5>Не определены</h5>
            }
        </div>
      );

    return (
        <div className={styles.mainItemWrapper}>
            <div className={styles.mainItem}>
                <div className={styles.group}>
                    <div className={styles.section} style={{ width: 30 }}>
                        {opened ? (
                            <Button
                                size="small"
                                onClick={() => set_opened(!opened)}
                            >
                                <MinusOutlined />
                            </Button>
                        ) : (
                            <Button
                                size="small"
                                onClick={() => set_opened(!opened)}
                            >
                                <PlusOutlined />
                            </Button>
                        )}
                    </div>
                    <div className={styles.section} style={{ width: 45 }}>
                        <div className={styles.photo}>
                            { item?.type == '3DTOURS' ? '360' : 'STR' }
                        </div>
                    </div>
                    <div className={styles.section} style={{ width: 250 }}>
                        <p>
                            {item?.title} 
                            <Popover content={content} title="Источники запроса">
                                <InfoCircleOutlined className={styles.infoWidgetIcon}/>
                            </Popover>
                        </p>
                        <span>
                            {moment(item?.createdAt).format(
                                'DD MMMM YYYY в HH:mm'
                            )}
                        </span>
                    </div>
                </div>

                <div className={styles.section}>
                    <span>Тип виджета</span>
                    <p>
                        <Tag
                            color={
                                item.type === 'DEFAULT' ? 'geekblue' : 'purple'
                            }
                        >
                            {item.type === 'DEFAULT' ? 'Планировки' : '360'}
                        </Tag>
                    </p>
                </div>

                <div className={styles.section}>
                    <span>Заявок с каталога</span>
                    <p>{item?.stats?.catalog || 0}</p>
                </div>

                <div className={styles.section}>
                    <span>Заявок с шахматки</span>
                    <p>{item?.stats?.chess || 0}</p>
                </div>

                <div className={styles.section}>
                    <span>Заявок с 3D тура</span>
                    <p>{item?.stats?.tour || 0}</p>
                </div>

                <div className={styles.section}>
                    <span>Звонки</span>
                    <p>{item?.stats?.phone_clicks || 0}</p>
                </div>

                <div className={styles.section}>
                    <span>Запросов</span>
                    <p>{item?.requests ? `${item?.requests}` : 0}</p>
                </div>

                <div
                    className={`${styles.section} ${styles.sectionRow}`}
                    style={{ width: 60 }}
                >
                    <Link
                        to={link}
                        onClick={() => {
                            dispatch({
                                type: 'UPDATE_WIDGET_FOR_EDIT',
                                data: null,
                            });
                        }}
                    >
                        <Button size="small"><EditOutlined /></Button>
                    </Link>
                    <Popconfirm
                        placement="left"
                        title="Виджет будет удален"
                        onConfirm={deleteWidget}
                        onCancel={() => {}}
                        okText="Удалить"
                        cancelText="Нет"
                    >
                        <Button size="small"><DeleteOutlined /></Button>
                    </Popconfirm>
                </div>
            </div>

            <div
                className={`${styles.mainItem} ${styles.mainItemClosed} ${
                    !!opened && styles.mainItemOpened
                }`}
            >
                <div className={styles.section}>
                    <p>Калькуляция (просмотры)</p>
                    <span>
                        {item?.stats?.clicks?.['calculation_button'] || 0}
                    </span>
                </div>

                <div className={styles.section}>
                    <p>3д туры (просмотры)</p>
                    <span>
                        {item?.stats?.clicks?.['3d_tour_views'] || 0}
                    </span>
                </div>

                <div className={styles.section}>
                    <p>Расчет (соц. сети)</p>
                    <span>
                        {item?.stats?.clicks?.['share_calculation'] || 0}
                    </span>
                </div>

                <div className={styles.section}>
                    <p>Кварт. лист (просмотры)</p>
                    <span>
                        {item?.stats?.clicks?.['apartment_sheet'] || 0}
                    </span>
                </div>

                <div className={styles.section}>
                    <p>3д тур (соц. сеть)</p>
                    <span>
                        {item?.stats?.clicks?.['share_3d_tour'] || 0}
                    </span>
                </div>

                <div className={styles.section}>
                    <p>Планировка (соц. сеть)</p>
                    <span>
                        {item?.stats?.clicks?.['share_plan'] || 0}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ItemWidget;

/*
                                            <h5>{widget.title}</h5>
                                        <span className={styles.typeWidget}>
                                            {widget.type === 'DEFAULT'
                                                ? 'Планировки'
                                                : '360'}
                                        </span>
                                        <div
                                            key={widget._id}
                                            className={styles.flat_item_}
                                        >
                                            <h5>
                                                {moment(
                                                    widget.createdAt
                                                ).format('Создано: DD/MM/YYYY')}
                                            </h5>
                                            <div
                                                className={
                                                    styles.flat_item_control
                                                }
                                            >
                                                <Link to={link}>
                                                    <EditOutlined />
                                                </Link>
                                                <div>
                                                    <Popconfirm
                                                        placement="left"
                                                        title="Виджет будет удален"
                                                        onConfirm={() => {
                                                            deleteWG(
                                                                widget._id
                                                            );
                                                        }}
                                                        onCancel={() => {}}
                                                        okText="Удалить"
                                                        cancelText="Нет"
                                                    >
                                                        <DeleteOutlined />
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        </div>
                                            */
