import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import styles from '../../styles/index.module.scss';
import { Select, Button } from 'antd';
import {
    CheckOutlined,
    CloseSquareOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import ListRealestate from '../Helpers/realestate-macro';

const { Option } = Select;

const SyncLogMacro = () => {
    const [select_realestate, set_select_realestate] = useState('');
    const [list_realestate, set_list_realestate] = useState(true);

    const dispatch = useDispatch();
    const logState = useSelector((state) => state.logs);

    useEffect(() => {
        dispatch({
            type: 'MACRO_REALESTATE_FLATS'
        });

        dispatch(api.logs.syncRealEstateMacro());
    }, []);

    const selectRealEstate = (value) => {
        set_select_realestate(value);
        dispatch(api.logs.getFlatsMacro(value));
    };

    const syncFlatsFilter = (status, flats) => {
        let flatsData = [];

        if (typeof flats == 'object' && flats.length) {
            flatsData =
                status && flats && flats.length
                    ? flats.filter((flat) => flat?.sync_with_logovo)
                    : flats.filter((flat) => !flat?.sync_with_logovo);
        }

        return flatsData.length;
    };

    return (
        <div className="statistic-block">
            <h1>Отладчик/Интеграция/Macro</h1>

            <div className={styles.selectRealEstateBlock}>
                <h5>Выберите жилой комплекс</h5>
                <div className={styles.flexRowForm}>
                    <Select
                        size="large"
                        className={styles.selectInput}
                        defaultValue={select_realestate}
                        onChange={selectRealEstate}
                        disabled={logState?.macro_request_api}
                    >
                        <Option value="">Выберите жилой комплекс</Option>
                        {logState?.macro_realestate_list &&
                        logState?.macro_realestate_list.length ? (
                            logState?.macro_realestate_list.map((item) => (
                                <Option key={item?._id} value={item?._id}>
                                    {item?.title}
                                </Option>
                            ))
                        ) : (
                            <Option value="">Информации не найдено</Option>
                        )}
                    </Select>

                    {(!logState?.macro_request_api && select_realestate) && (
                        <Button
                            size="large"
                            type="primary"
                            onClick={() =>
                                set_list_realestate(!list_realestate)
                            }
                            className={styles.buttonRealestate}
                        >
                            Список доступных жк
                        </Button>
                    )}
                </div>
            </div>

            <div className={styles.contentBlock}>
                <ListRealestate
                    close={() => set_list_realestate(!list_realestate)}
                    logState={logState}
                    select_realestate={select_realestate}
                    dispatch={dispatch}
                    api={api}
                    status_hide={list_realestate}
                />

                {logState?.macro_request_api ? (
                    <div className={styles.defaultBlock}>
                        <LoadingOutlined
                            className={styles.defaultBlockLoadIcon}
                        />
                    </div>
                ) : typeof logState?.macro_realestate_flats === 'string' ? (
                    <h5>{logState?.macro_realestate_flats}</h5>
                ) : typeof logState?.flats === 'object' &&
                  logState?.macro_realestate_flats &&
                  logState?.macro_realestate_flats.length ? (
                    <div className={styles.parentItemsBlock}>
                        <div className={styles.parentItemsHeadBlock}>
                            <div className={styles.parentItemsHeadLeftBlock}>
                                <section>
                                    Всего квартир: {logState?.macro_realestate_flats.length || 0}
                                </section>
                                <section>
                                    Синхронизировано:{' '}
                                    {syncFlatsFilter(
                                        true,
                                        logState?.macro_realestate_flats || []
                                    )}
                                </section>
                                <section>
                                    Не прошли синхронизацию:{' '}
                                    {syncFlatsFilter(false, logState?.macro_realestate_flats)}
                                </section>
                            </div>
                        </div>
                        <div className={styles.itemsBlock}>
                            {logState?.macro_realestate_flats.map((item) => (
                                <div
                                    className={styles.itemBlock}
                                    key={item?.id}
                                >
                                    <h4>
                                        <b>Номер:</b> {item?.title}
                                    </h4>
                                    <h4>
                                        <b>Этаж:</b> {item?.floor}
                                    </h4>
                                    <h4>
                                        <b>Статус:</b> {item?.sold ? 'Продана' : 'Свободно'}
                                    </h4>
                                    <h4>
                                        <b>ЖК:</b>{' '}
                                        {item?.apartment_complex_id?.title}
                                    </h4>
                                    <h4>
                                        <b>м2:</b>{' '}
                                        {item?.square_metres || '0'}
                                    </h4>
                                    <h4>
                                        <b>Секция:</b>{' '}
                                        {item?.geo_house_section || '-'}
                                    </h4>
                                    <h4>
                                        <b>Подъезд:</b>{' '}
                                        {item?.geo_house_entrance || '-'}
                                    </h4>
                                    <h4>
                                        <b>Блок:</b> {item?.block_id?.title || '-'}
                                    </h4>
                                    <h4>
                                        <b>Синх. статус:</b>{' '}
                                        {item?.sync_with_logovo ? (
                                            <CheckOutlined
                                                className={
                                                    styles.LikeOutlinedIcon
                                                }
                                            />
                                        ) : (
                                            <CloseSquareOutlined
                                                className={
                                                    styles.DislikeOutlinedIcon
                                                }
                                            />
                                        )}
                                    </h4>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className={styles.defaultBlock}>
                        <h4>Квартир не найдено</h4>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SyncLogMacro;
