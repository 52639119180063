import React from 'react';
import config from '../../../../Config';
import { Dropdown, Menu, Popconfirm } from 'antd';
import styles from '../styles/index.module.scss';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { api } from '@/Services';

const Item = ({ item }) => {
    const dispatch = useDispatch();

    const menuSettings = (
        <Menu>
            <Menu.Item key="edit_product" onClick={() => {}}>
                <Link to={`/library/shops/edit/${item?._id}`}>
                    Редактировать
                </Link>
            </Menu.Item>
            <Menu.Item key="remove_product" onClick={() => {}}>
                <Popconfirm
                    title="Магазин и все связанные товары будут удалены"
                    onConfirm={() =>
                        dispatch(api.library.removeShop(item?._id))
                    }
                    onCancel={() => {}}
                    okText="Удалить"
                    cancelText="Нет"
                    placement="bottomLeft"
                >
                    Удалить
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="category-item-block">
            <Dropdown
                trigger={['click']}
                className={styles.Dropdown}
                overlay={menuSettings}
            >
                <EllipsisOutlined
                    onClick={(e) => e.preventDefault()}
                    rotate={90}
                    className={styles.userItemSettingIcon}
                />
            </Dropdown>

            {/*
				* <div className="category-item-block-controll">
				<span onClick={()=>removeShop()} title="Удалить магазин"><Icon size={2} path={mdiBeakerRemoveOutline} /></span>
				<span onClick={()=>editShop()} title="Редактировать магазин"><Icon size={2} path={mdiFileEditOutline} /></span>
			</div>
				* */}

            <div
                className="category-item-block-icon"
                style={{
                    background: `url(${config.public}140/140/${item.icon.url}) center / contain no-repeat`,
                }}
            ></div>
            <p>{item.title}</p>
        </div>
    );
};

export default Item;
