import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles/index.module.scss';
import { Tabs } from 'antd';
import WidgetWebhook from './Helpers/webhook';
import { useParams } from 'react-router-dom';
import FormEdit from './Helpers/form-edit';
import WidgetEmail from './Helpers/email';
import { api } from '../../../../Services';
import { Loader } from '../../../../Components';
import WidgetConnection from './Helpers/connection';
import WidgetPlans from './Helpers/plans';

const WidgetEdit = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const widgetState = useSelector((state) => state.widgets);

    useEffect(()=>{
        dispatch(api.widgets.getById(id));
    }, []);

    const items = [
        {
            key: '1',
            label: `Настройки`,
            children: (
                <FormEdit
                    id={id}
                    dispatch={dispatch}
                    widgetState={widgetState}
                    widget={widgetState?.widget_edit || null}
                    styles={styles}
                    api={api}
                />
            ),
        },
        {
            key: '2',
            label: `Планировки`,
            children: <WidgetPlans 
                api={api} 
                styles={styles} 
                widget={widgetState?.widget_edit || null}
                id={id}
            />,
        },
        {
            key: '3',
            label: `Webhook`,
            children: (
                <WidgetWebhook
                    styles={styles}
                    id={id}
                    dispatch={dispatch}
                    widget={widgetState?.widget_edit || null}
                />
            ),
        },
        {
            key: '4',
            label: `Контакты`,
            children: (
                <WidgetEmail
                    styles={styles}
                    id={id}
                    dispatch={dispatch}
                    widget={widgetState?.widget_edit || null}
                />
            ),
        },
        {
            key: '5',
            label: `Подключение`,
            children: <WidgetConnection styles={styles} widget_id={id}/>,
        },
    ];

    return (
        <content className={`cities-page-block`}>
            <h1>Редактирование виджета</h1>

            {
                widgetState?.widget_edit ? 
                    <Tabs defaultActiveKey="1" items={items} />
                : 
                    <Loader/>
            }
            
        </content>
    );
};

export default WidgetEdit;
