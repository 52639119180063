import React, { useEffect, useRef, useState } from 'react';
import { ChessGrid } from '../../../../Components';
import { useParams } from 'react-router-dom';
import { api } from '../../../../Services';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from './Helpers/modal';
import { Result } from 'antd';

const ChessStandart = () => {
    const [modal_info, set_modal_info] = useState(null);

    const scrollRef = useRef(null);

    const { id } = useParams();
    const dispatch = useDispatch();

    let stateApartmentChess = useSelector((state) => state.apartments);

    useEffect(() => {
        dispatch(api.apartments.getApartmentChessById(id));
    }, []);

    const onWheel = (e) => {
        const container = scrollRef.current;
        const containerScrollPosition = scrollRef.current.scrollLeft;

        container.scrollTo({
            left: containerScrollPosition + e.deltaY,
        });
    };

    return (
        <div>
            <ModalForm
                modal_info={modal_info}
                set_modal_info={set_modal_info}
                dispatch={dispatch}
                api={api}
            />

            {stateApartmentChess.chess_apartment_loading ? (
                <h2>Загрузка</h2>
            ) : stateApartmentChess.chess_apartment ? (
                <div className="page-create-apartment">
                    <h1>{stateApartmentChess?.chess_apartment?.a_complex}</h1>

                    {stateApartmentChess?.chess_apartment?.chessboard ===
                    'standart' ? (
                        <div
                            ref={scrollRef}
                            className="main-section-grid-chess"
                            id="main-section-grid-chess"
                            onWheel={onWheel}
                        >
                            {stateApartmentChess.chess_apartment.blocks.map(
                                (block, index) => {
                                    return (
                                        <ChessGrid
                                            block_quantity={
                                                stateApartmentChess
                                                    ?.chess_apartment?.blocks
                                                    .length
                                            }
                                            key={block._id}
                                            dispatch={dispatch}
                                            api={api}
                                            block={block}
                                            blockCount={
                                                stateApartmentChess
                                                    .chess_apartment.blocks
                                                    .length - 1
                                            }
                                            set_modal_info={set_modal_info}
                                            blockIndex={index}
                                        />
                                    );
                                }
                            )}
                        </div>
                    ) : (
                        <Result
                            title="В данном жк используется кастомная шахматка"
                            extra={null}
                        />
                    )}
                </div>
            ) : (
                <h2>Блоков не найдено</h2>
            )}
        </div>
    );
};

export default ChessStandart;
