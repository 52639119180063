import React from 'react';
import { Result, Typography } from 'antd';

const { Title } = Typography;

const Analytics = () => {
	return (
		<>
			<Title level={4}>Аналитика</Title>

			<Result
				status="warning"
				title="Раздел в разработке"
				extra={null}
			/>

		</>
	)
}

export default Analytics;