"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mappingLayer = void 0;
var layer_1 = require("../../adaptor/layer");
function getRampColors(style) {
    var colorsRamp = style.colorsRamp;
    var rampColors = { colors: [], positions: [] };
    colorsRamp.forEach(function (_a) {
        var color = _a.color, position = _a.position;
        rampColors.colors.push(color);
        rampColors.positions.push(position);
    });
    return rampColors;
}
function mappingLayer(layer, options) {
    var shape = options.shape, size = options.size, style = options.style, state = options.state;
    // mapping shape
    shape && layer_1.MappingLayer.shape(layer, shape);
    // mapping size
    size && layer_1.MappingLayer.size(layer, size);
    // mapping style
    if (style) {
        if ('colorsRamp' in style) {
            style.rampColors = getRampColors(style);
        }
        layer_1.MappingLayer.style(layer, style);
    }
    // mapping state
    state && layer_1.MappingLayer.state(layer, state);
}
exports.mappingLayer = mappingLayer;
