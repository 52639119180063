import React, { useEffect, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Editor from './editor';
import { Autorenew, Delete } from '@material-ui/icons';
import { Map, Placemark, YMaps } from 'react-yandex-maps';
import config from '../../../../../Config';
import { useDispatch, useSelector } from 'react-redux';
import { api, moment, formatBytes } from '../../../../../Services';
import styles from '../../styles/index.module.scss';
import keyRnd from 'crypto-random-string';
import { BindVT } from '../../../../../Components';

import {
    CheckCircleOutlined,
    LoadingOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import {
    Button,
    DatePicker,
    Input,
    Popover,
    Select,
    Collapse,
    notification,
    Form,
    Tag,
    Popconfirm,
    Tooltip,
    Checkbox,
} from 'antd';

const { Option } = Select;
const { Panel } = Collapse;

const ApartmentСomplex = ({ item, getStatePrograms, getMortgage }) => {
    const history = useNavigate();
    const formRef = createRef();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch({
            type: 'APARTMENT_INTERIOR_ARRAY',
            photos: item.photos
                ? item.photos.map((photo) => {
                      return {
                          ...photo,
                          url: photo.url,
                          full_url: config.public + photo.url,
                          id: photo._id,
                      };
                  })
                : null,
        });

        formRef.current.setFieldsValue({
            ...item,
            sync_domain: item?.sync_domain || '',
            city: item?.city?._id,
            delivery_time: item?.delivery_time,
            delivery_time_month: item?.delivery_time_month,
            builder: item?.builder?._id,
            coords: [Number(item?.coords?.lat), Number(item?.coords?.lon)],
            visibility_status:
                item?.visibility_status ||
                (item?.publication_status ? 'public' : 'hide'),
            type_form: item?.commercial
                ? 'COMMERCIAL'
                : item?.real_estate_house
                ? 'REAL_ESTATE_HOUSE'
                : 'COMPLEX',
        });
    }, []);

    let getCities = useSelector((state) => state.cities.cities);
    let getBuilders = useSelector((state) => state.builders.builders);
    let getObjects = useSelector((state) => state.apartments.objects);
    let getVTByRealestate = useSelector(
        (state) => state.apartments.vt_of_realestate
    );
    let getAdminRole =
        useSelector((state) => state.user?.user?.role?.type) === 'SUPERADMIN';

    const getApartmentsState = useSelector((state) => state.apartments);
    const getFilesState = useSelector((state) => state.files);

    const getListTemplates = useSelector(
        (state) => state.apartments.templatesOfRealestate
    );

    const [deliveryTime, setDeliveryTime] = React.useState(
        item.delivery_time ? item.delivery_time : moment().format('YYYY-MM-DD')
    );
    const [deliveryFormatDate, setdeliveryFormatDate] = React.useState(null);

    const [min_price_square_metres, setMin_price_square_metres] =
        React.useState(
            item.min_price_square_metres ? item.min_price_square_metres : ''
        );

    const [city, setCity] = React.useState(item.city._id);
    const [builder, setBuilder] = React.useState(item.builder._id);
    const [coords, setCoords] = React.useState([
        item.coords.lat,
        item.coords.lon,
    ]);
    const [title, setTitle] = React.useState(item?.title || '');
    const [address, setAddress] = React.useState(item.address);
    const [housing_class, setHousing_class] = React.useState(
        item.housing_class
    );
    //const [mortgage, set_mortgage] = React.useState(
    //    item.mortgages ? item.mortgages : []
    //);

    const [state_programs, set_state_programs] = React.useState(
        item.state_programs ? item.state_programs : []
    );

    const [seo_title, setSeoTitle] = React.useState(
        item?.seo_title ? item?.seo_title : ''
    );
    const [seo_keywords, setSeoKeywords] = React.useState(
        item.seo_keywords ? item.seo_keywords : ''
    );
    const [seo_description, setSeoDescription] = React.useState(
        item.seo_description ? item.seo_description : ''
    );

    const [type_form, set_type_form] = React.useState(
        item.commercial
            ? 'COMMERCIAL'
            : item.real_estate_house
            ? 'REAL_ESTATE_HOUSE'
            : 'COMPLEX'
    ); // REAL_ESTATE_HOUSE COMMERCIAL

    const [commercial, set_commercial] = React.useState(
        item.commercial ? 1 : 0
    );
    const [real_estate_house, set_real_estate_house] = React.useState(
        item.real_estate_house ? 1 : 0
    );

    const [sync_id, set_sync_id] = React.useState(item.sync_id || '');
    const [sync_type, set_sync_type] = React.useState(item?.sync_type || '');
    const [sync_api_url_method, set_sync_api_url_method] = React.useState(
        item?.sync_api_url_method || ''
    );
    const [sync_api_key, set_sync_api_key] = React.useState(
        item?.sync_api_key || ''
    );
    const [sync_api_login, set_sync_api_login] = React.useState(
        item?.sync_api_login || ''
    );
    const [sync_domain, set_sync_domain] = React.useState(
        item?.sync_domain || ''
    );
    const [type_block, set_type_block] = React.useState(item?.type_block || '');
    const [sync_tour_transfer, set_sync_tour_transfer] = React.useState(
        item?.sync_tour_transfer || false
    );

    {
        /*New state fields*/
    }
    const [interior, set_interior] = React.useState(
        item.interior ? item.interior : ''
    );
    const [number_of_apartments, set_number_of_apartments] = React.useState(
        item.number_of_apartments ? item.number_of_apartments : ''
    );
    const [window_height, set_window_height] = React.useState(
        item.window_height ? item.window_height : ''
    );
    const [construction_phase, set_construction_phase] = React.useState(
        item.construction_phase ? item.construction_phase : ''
    );
    const [building_construction, set_building_construction] = React.useState(
        item.building_construction ? item.building_construction : ''
    );
    const [building_facade, set_building_facade] = React.useState(
        item.building_facade ? item.building_facade : ''
    );
    const [installment, set_installment] = React.useState(
        item.installment ? item.installment : ''
    );
    const [bank_installment, set_bank_installment] = React.useState(
        item.bank_installment ? item.bank_installment : ''
    );
    const [parking, set_parking] = React.useState(
        item.parking ? item.parking : ''
    );
    const [ceiling_height, set_ceiling_height] = React.useState(
        item.ceiling_height ? item.ceiling_height : ''
    );

    const [seo_content, set_seo_content] = React.useState(
        item.seo_content ? item.seo_content : ''
    );

    const [commission, set_commission] = React.useState(
        item.commission ? item.commission : 0
    );
    const [crm_price_lead, set_crm_price_lead] = React.useState(
        item.crm_price_lead ? item.crm_price_lead : 0
    );

    const [slug, setSlug] = React.useState(item?.slug);

    const [documents, set_documents] = React.useState(item?.documents || []);
    const [document_status_upload, set_document_status_upload] =
        React.useState(false);

    const [view_tour, set_view_tour] = React.useState(
        item?.view_tour?.template || ''
    );

    const [select_object, set_select_object] = React.useState(
        item?.object || ''
    );

    const [number_queue, set_number_queue] = React.useState(
        item?.number_queue || ''
    );

    const [districts, set_districts] = React.useState([]);
    const [select_districts, set_select_districts] = React.useState(
        item?.districts || []
    );

    const [chessboard, set_chessboard] = React.useState(
        item?.chessboard || 'standart'
    );

    const [type_content, set_type_content] = React.useState(
        item?.type_content || 'standart'
    );

    useEffect(() => {
        formRef.current.setFieldsValue({
            seo_content,
        });
    }, [seo_content]);

    useEffect(() => {
        let distArr = [];

        let getCity = getCities.filter(
            (item) => String(item?._id) === String(city)
        );

        if (
            getCity.length &&
            getCity[0].districts &&
            getCity[0].districts.length
        ) {
            getCity[0].districts.map((item) => {
                distArr.push(item);
                if (item?.districts && item?.districts.length) {
                    item?.districts.map((subItem) => {
                        distArr.push(subItem);
                    });
                }
            });
        }

        set_districts(distArr);
    }, [city]);

    const handleChangeCity = (value) => {
        formRef.current.setFieldsValue({
            city: value,
        });
        set_select_districts([]);
        setCity(value);
    };

    //const handleChangeMortgage = (value) => {
    //    formRef.current.setFieldsValue({
    //        mortgage: value,
    //    });
    //    set_mortgage(value);
    //};

    const handleChangeBuilder = (value) => {
        formRef.current.setFieldsValue({
            builder: value,
        });
        setBuilder(value);
    };

    const handleDropBlocks = (files) => {
        dispatch(
            api.files.uploadFile(files[0], 'images-complex-with-all-blocks')
        );
    };

    const handleHousingClass = (value) => {
        formRef.current.setFieldsValue({
            housing_class: value,
        });
        setHousing_class(value);
    };

    const uploadDocument = async (val) => {
        set_document_status_upload(true);
        let file = await api.files.uploadDocument(val.target.files[0]);
        let newDocument = [...documents, file];
        set_document_status_upload(false);
        set_documents(newDocument);
    };

    useEffect(() => {
        formRef.current.setFieldsValue({
            documents,
        });
    }, [documents]);

    const editApartment = (values) => {
        return dispatch(
            api.apartments.editApartment(
                {
                    ...values,
                    title,
                    id: item._id,
                    commercial,
                    real_estate_house,
                },
                history
            )
        );
    };

    const changeMainPhoto = (selectPhoto) => {
        dispatch(api.files.changePhoto(selectPhoto));
    };

    const formErrors = (errors) => {
        let errorText = '';

        errors.errorFields.map((err) => {
            errorText += `${err?.errors[0]} \n`;
        });

        notification['error']({
            message: 'Не все поля были заполнены.',
            description: errorText,
        });
    };

    useEffect(() => {
        formRef.current.setFieldsValue({
            photos: getFilesState.APARTMENT_INTERIOR,
        });
    }, [getFilesState.APARTMENT_INTERIOR]);

    useEffect(() => {
        formRef.current.setFieldsValue({
            image_complex_with_all_blocks:
                getFilesState.photo_apartment_complex_with_blocks,
        });
    }, [getFilesState.photo_apartment_complex_with_blocks]);

    useEffect(() => {
        formRef.current.setFieldsValue({
            photo: getFilesState.photo_apartment_complex,
        });
    }, [getFilesState.photo_apartment_complex]);

    const numberDays = moment().diff(moment(item?.createdAt), 'days');

    return (
        <div className={`ApartmentСomplex ${styles.blockRealEstate}`}>
            <section className={styles.blockRealEstateHead}>
                <small>
                    {moment(item.createdAt).format(
                        'Создан DD MMMM YYYY в HH:mm'
                    )}
                </small>
            </section>

            <Form
                name="basic"
                onFinish={editApartment}
                onFinishFailed={formErrors}
                autoComplete="off"
                ref={formRef}
                size="large"
            >
                <section
                    className={`input-section-form ${styles.inputSectionForm}`}
                >
                    <Form.Item
                        label=""
                        name="type_content"
                        rules={[
                            {
                                required: true,
                                message: 'Выберите тип контента',
                            },
                        ]}
                        className={styles.formItemStyle}
                    >
                        <Popover
                            placement="bottom"
                            content="Выберите тип контента"
                            title=""
                        >
                            <Select
                                size="large"
                                value={type_content}
                                onChange={(value) => {
                                    set_type_content(value);
                                    formRef.current.setFieldsValue({
                                        type_content: value,
                                    });
                                }}
                                placeholder="Выберите тип контента"
                                className={styles.selectInput}
                            >
                                <Option
                                    style={{ fontSize: 17 }}
                                    value="standart"
                                >
                                    Standart
                                </Option>
                                <Option style={{ fontSize: 17 }} value="custom">
                                    Custom
                                </Option>
                                <Option style={{ fontSize: 17 }} value="demo">
                                    Demo
                                </Option>
                            </Select>
                        </Popover>
                    </Form.Item>
                </section>

                <section
                    className={`input-section-form ${styles.inputSectionForm}`}
                >
                    <Form.Item
                        label=""
                        name="type_form"
                        rules={[
                            {
                                required: true,
                                message: 'Выберите тип жк',
                            },
                        ]}
                        className={styles.formItemStyle}
                    >
                        <Popover content="Тип ЖК" title="">
                            <Select
                                size="large"
                                value={type_form}
                                onChange={(value) => {
                                    set_type_form(value);

                                    formRef.current.setFieldsValue({
                                        type_form: value,
                                    });

                                    if (value == 'REAL_ESTATE_HOUSE') {
                                        set_real_estate_house(1);
                                        set_commercial(0);
                                    } else if (value == 'COMMERCIAL') {
                                        set_commercial(1);
                                        set_real_estate_house(0);
                                    } else {
                                        set_commercial(0);
                                        set_real_estate_house(0);
                                    }
                                }}
                                className={styles.selectInput}
                            >
                                <Option
                                    style={{ fontSize: 17 }}
                                    value="COMPLEX"
                                >
                                    Жилой комплекс
                                </Option>
                                <Option
                                    style={{ fontSize: 17 }}
                                    value="REAL_ESTATE_HOUSE"
                                >
                                    недвижимость «Дом»
                                </Option>
                                <Option
                                    style={{ fontSize: 17 }}
                                    value="COMMERCIAL"
                                >
                                    Коммерческая недвижимость
                                </Option>
                            </Select>
                        </Popover>
                    </Form.Item>
                </section>

                <div className={styles.groupInputs}>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Form.Item
                            label=""
                            name="object"
                            rules={[
                                {
                                    required: true,
                                    message: 'Выберите жк',
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Popover content="Выберите жк" title="">
                                <Select
                                    disabled
                                    showArrow
                                    showSearch
                                    size="large"
                                    value={select_object}
                                    onChange={(value) => {
                                        formRef.current.setFieldsValue({
                                            object: value,
                                        });
                                        set_select_object(value);
                                    }}
                                    className={styles.formSelect}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children
                                            .toLowerCase()
                                            .localeCompare(
                                                optionB.children.toLowerCase()
                                            )
                                    }
                                >
                                    <Option style={{ fontSize: 17 }} value="">
                                        Выберите жк
                                    </Option>
                                    {getObjects ? (
                                        getObjects.map((item) => {
                                            return (
                                                <Option
                                                    key={item?._id}
                                                    style={{ fontSize: 17 }}
                                                    value={item._id}
                                                >
                                                    {item.title}
                                                </Option>
                                            );
                                        })
                                    ) : (
                                        <Option
                                            style={{ fontSize: 17 }}
                                            value=""
                                        >
                                            Жк не найдено.
                                        </Option>
                                    )}
                                </Select>
                            </Popover>
                        </Form.Item>
                    </section>

                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Form.Item
                            label=""
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: `Заполните наименование ${
                                        item?.type_realestate === 'queue'
                                            ? 'очереди'
                                            : 'жк'
                                    }`,
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Popover
                                content={`Наименование ${
                                    item?.type_realestate === 'queue'
                                        ? 'очереди'
                                        : 'жк'
                                }`}
                                title=""
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        formRef.current.setFieldsValue({
                                            title: val.target.value,
                                        });
                                        setTitle(val.target.value);
                                    }}
                                    placeholder={`Наименование ${
                                        item?.type_realestate === 'queue'
                                            ? 'очереди'
                                            : 'жк'
                                    }`}
                                    value={title}
                                />
                            </Popover>
                        </Form.Item>
                    </section>

                    {!item?.main ? (
                        <>
                            <section
                                style={{ margin: '0 0 10px 0' }}
                                className={`input-section-form ${styles.sectionFormFlex}`}
                            >
                                <Form.Item
                                    label=""
                                    name="number_queue"
                                    rules={[
                                        {
                                            required: true,
                                            pattern: new RegExp(/^[0-9 -]*$/),
                                            message:
                                                'Заполните номер очереди (Валидация поля: цифры и знак тире)',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    <Popover content="Номер очереди" title="">
                                        <Input
                                            size="large"
                                            onChange={(val) => {
                                                formRef.current.setFieldsValue({
                                                    number_queue:
                                                        val.target.value,
                                                });
                                                set_number_queue(
                                                    val.target.value
                                                );
                                            }}
                                            placeholder="Номер очереди"
                                            value={number_queue}
                                        />
                                    </Popover>
                                </Form.Item>
                            </section>
                        </>
                    ) : (
                        <></>
                    )}

                    <section
                        style={{ margin: 0 }}
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Tag color="red">
                            Изменить url может только суперадмин
                        </Tag>

                        {!getAdminRole && (
                            <>
                                <Tag color="red">
                                    Возможность изменять url действует в течении
                                    24 часов с момента создания
                                </Tag>
                            </>
                        )}

                        <Tag color="red">
                            {item?.main
                                ? 'Жк был создан'
                                : 'Очередь была создана'}{' '}
                            {numberDays} дней назад
                        </Tag>

                        <Form.Item
                            hidden={true}
                            label=""
                            name="slug_change"
                            className={styles.formItemStyle}
                        ></Form.Item>

                        <Form.Item
                            label=""
                            name="slug"
                            className={styles.formItemStyle}
                        >
                            <Popover content="Url" title="">
                                <Input
                                    disabled={
                                        getAdminRole
                                            ? false
                                            : numberDays > 1
                                            ? true
                                            : false
                                    }
                                    size="large"
                                    onChange={(val) => {
                                        setSlug(val.target.value);
                                        formRef.current.setFieldsValue({
                                            slug: val.target.value,
                                        });

                                        formRef.current.setFieldsValue({
                                            slug_change: true,
                                        });
                                    }}
                                    placeholder="Url*"
                                    value={`${
                                        getAdminRole
                                            ? slug
                                            : numberDays > 1
                                            ? `URL: /${slug}`
                                            : slug
                                    }`}
                                />
                            </Popover>
                        </Form.Item>
                    </section>
                </div>

                <div className={styles.groupInputs}>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Form.Item
                            label=""
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: 'Заполните адрес ЖК',
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Popover content="Адрес ЖК" title="">
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        formRef.current.setFieldsValue({
                                            address: val.target.value,
                                        });
                                        setAddress(val.target.value);
                                    }}
                                    placeholder="Адрес ЖК*"
                                    value={address}
                                />
                            </Popover>
                        </Form.Item>
                    </section>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Form.Item
                            label=""
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: 'Заполните адрес ЖК',
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Popover content="Город" title="">
                                <Select
                                    size="large"
                                    value={city}
                                    onChange={handleChangeCity}
                                    className={styles.formSelect}
                                >
                                    {getCities ? (
                                        getCities.map((item) => {
                                            return (
                                                <Option
                                                    key={
                                                        item?._id +
                                                        Math.random()
                                                    }
                                                    style={{ fontSize: 17 }}
                                                    value={item._id}
                                                >
                                                    {item.title}
                                                </Option>
                                            );
                                        })
                                    ) : (
                                        <Option
                                            style={{ fontSize: 17 }}
                                            value=""
                                        >
                                            Городов не найдено. Добавьте в
                                            разделе "Города"
                                        </Option>
                                    )}
                                </Select>
                            </Popover>
                        </Form.Item>
                    </section>
                    {districts && districts.length ? (
                        <section
                            style={{ margin: 0 }}
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="districts"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Выберите район" title="">
                                    <Select
                                        mode="multiple"
                                        size="large"
                                        onChange={(value) => {
                                            formRef.current.setFieldsValue({
                                                districts: value,
                                            });
                                            set_select_districts(value);
                                        }}
                                        value={select_districts}
                                        placeholder="Выберите район"
                                        className={styles.formSelect}
                                    >
                                        {districts.map((item) => {
                                            return (
                                                <Option
                                                    key={item?._id}
                                                    style={{ fontSize: 17 }}
                                                    value={item._id}
                                                >
                                                    {item.title}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Popover>
                            </Form.Item>
                        </section>
                    ) : (
                        <></>
                    )}
                </div>

                <div className={styles.groupInputs}>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Form.Item
                            label=""
                            name="builder"
                            rules={[
                                {
                                    required: true,
                                    message: 'Выберите застройщика',
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Popover content="Выберите застройщика" title="">
                                <Select
                                    showSearch
                                    size="large"
                                    className={styles.formSelect}
                                    value={builder}
                                    onChange={handleChangeBuilder}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={
                                        getBuilders && getBuilders.length
                                            ? getBuilders.map((item) => ({
                                                  label: item?.title,
                                                  value: item?._id,
                                              }))
                                            : []
                                    }
                                />
                            </Popover>
                        </Form.Item>
                    </section>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Form.Item
                            label=""
                            name="housing_class"
                            rules={[
                                {
                                    required: true,
                                    message: 'Заполните класс жилья',
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Popover content="Класс жилья" title="">
                                <Select
                                    size="large"
                                    className={styles.formSelect}
                                    value={housing_class}
                                    onChange={handleHousingClass}
                                >
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="STANDART"
                                    >
                                        СТАНДАРТ
                                    </Option>
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="COMFORT"
                                    >
                                        КОМФОРТ
                                    </Option>
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="BUSINESS"
                                    >
                                        БИЗНЕС
                                    </Option>
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="PREMIUM"
                                    >
                                        ПРЕМИУМ
                                    </Option>
                                </Select>
                            </Popover>
                        </Form.Item>
                    </section>
                </div>

                <section
                    className={`input-section-form ${styles.datePickBlock} ${styles.groupInputs}`}
                >
                    <Form.Item
                        hidden
                        label=""
                        name="delivery_time_month"
                        className={styles.formItemStyle}
                    ></Form.Item>
                    <Form.Item
                        label=""
                        name="delivery_time"
                        rules={[
                            {
                                required: true,
                                message: 'Выберите срок сдачи жк',
                            },
                        ]}
                        className={styles.formItemStyle}
                    >
                        <Popover content="Срок сдачи жк" title="">
                            <h5>Срок сдачи жк</h5>
                            <DatePicker
                                size="large"
                                className={styles.datePickInput}
                                value={deliveryFormatDate}
                                onChange={(date, dateString) => {
                                    let quarter = dateString.slice(-2);
                                    quarter =
                                        quarter == 'Q1'
                                            ? 'I Квартал'
                                            : quarter == 'Q2'
                                            ? 'II Квартал'
                                            : quarter == 'Q3'
                                            ? 'III Квартал'
                                            : quarter == 'Q4'
                                            ? 'IV Квартал'
                                            : '';
                                    setDeliveryTime(
                                        `${quarter} ${date.format('YYYY')}`
                                    );
                                    setdeliveryFormatDate(date);

                                    formRef.current.setFieldsValue({
                                        delivery_time: `${quarter} ${date.format(
                                            'YYYY'
                                        )}`,
                                    });
                                    formRef.current.setFieldsValue({
                                        delivery_time_month: date,
                                    });
                                }}
                                picker="quarter"
                            />

                            <b>{deliveryTime}</b>
                        </Popover>
                    </Form.Item>
                </section>

                <section
                    className={`input-section-form ${styles.inputSectionForm}`}
                >
                    <Form.Item
                        label=""
                        name="construction_phase"
                        rules={[
                            {
                                required: true,
                                message: 'Выберите этап строительства',
                            },
                        ]}
                        className={styles.formItemStyle}
                    >
                        <Popover content="Этап строительства" title="">
                            <Select
                                size="large"
                                className={styles.formSelect}
                                value={construction_phase}
                                onChange={(value) => {
                                    formRef.current.setFieldsValue({
                                        construction_phase: value,
                                    });
                                    set_construction_phase(value);
                                }}
                            >
                                <Option value="">
                                    Выберите этап строительства
                                </Option>
                                <Option value="Не строится">Не строится</Option>
                                <Option value="Строится">Строится</Option>
                                <Option value="Построен">Построен</Option>
                            </Select>
                        </Popover>
                    </Form.Item>
                </section>

                <Collapse>
                    <Panel forceRender header="Узнать больше" key="1">
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="building_construction"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Конструкция здания" title="">
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                building_construction:
                                                    val.target.value,
                                            });
                                            set_building_construction(
                                                val.target.value
                                            );
                                        }}
                                        placeholder="Конструкция здания"
                                        value={building_construction}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="building_facade"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Фасад здания" title="">
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                building_facade:
                                                    val.target.value,
                                            });
                                            set_building_facade(
                                                val.target.value
                                            );
                                        }}
                                        placeholder="Фасад здания"
                                        value={building_facade}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="interior"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Внутренняя отделка" title="">
                                    <Select
                                        size="large"
                                        className={styles.formSelect}
                                        value={interior}
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                interior: val,
                                            });
                                            set_interior(val);
                                        }}
                                    >
                                        <Option value="Черновая">
                                            Черновая
                                        </Option>
                                        <Option value="Предчистовая">
                                            Предчистовая
                                        </Option>
                                        <Option value="Чистовая">
                                            Чистовая
                                        </Option>
                                    </Select>
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="number_of_apartments"
                                className={styles.formItemStyle}
                            >
                                <Popover
                                    content="Количество квартир на площадке"
                                    title=""
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                number_of_apartments:
                                                    val.target.value,
                                            });
                                            set_number_of_apartments(
                                                val.target.value
                                            );
                                        }}
                                        placeholder="Количество квартир на площадке"
                                        value={number_of_apartments}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="ceiling_height"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Высота потолков" title="">
                                    <Input
                                        suffix="м"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                ceiling_height:
                                                    val.target.value,
                                            });
                                            set_ceiling_height(
                                                val.target.value
                                            );
                                        }}
                                        placeholder="Высота потолков"
                                        value={ceiling_height}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="window_height"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Высота окон" title="">
                                    <Input
                                        size="large"
                                        suffix="м"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                window_height: val.target.value,
                                            });
                                            set_window_height(val.target.value);
                                        }}
                                        placeholder="Высота окон"
                                        value={window_height}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>

                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="parking"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Паркинг" title="">
                                    <Select
                                        size="large"
                                        className={styles.formSelect}
                                        value={parking}
                                        onChange={(value) => {
                                            formRef.current.setFieldsValue({
                                                parking: value,
                                            });
                                            set_parking(value);
                                        }}
                                        placeholder="Паркинг"
                                    >
                                        <Option value="Подземный">
                                            Подземный
                                        </Option>
                                        <Option value="Наземный">
                                            Наземный
                                        </Option>
                                        <Option value="Гостевой">
                                            Гостевой
                                        </Option>
                                        <Option value="Отапливаемый гараж">
                                            Отапливаемый гараж
                                        </Option>
                                        <Option value="Нет">Нет</Option>
                                    </Select>
                                </Popover>
                            </Form.Item>
                        </section>
                    </Panel>
                    <Panel forceRender header="Картинки/Местоположение" key="2">
                        <Collapse accordion>
                            <Panel
                                forceRender
                                header="Местоположение ЖК на карте"
                                key="3"
                            >
                                <div className={styles.inputSectionFormShadow}>
                                    <Form.Item
                                        label=""
                                        name="coords"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Выберите местоположение на карте',
                                            },
                                        ]}
                                        className={styles.formItemStyle}
                                    >
                                        {coords ? (
                                            <small>
                                                {coords[0].toFixed(3)},{' '}
                                                {coords[1].toFixed(3)}
                                            </small>
                                        ) : (
                                            <div></div>
                                        )}

                                        <YMaps>
                                            <Map
                                                defaultState={{
                                                    center: [50.698, 70.191],
                                                    zoom: 4,
                                                }}
                                                style={{
                                                    width: '100%',
                                                    height: 500,
                                                }}
                                                onClick={(e) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            coords: e.get(
                                                                'coords'
                                                            ),
                                                        }
                                                    );
                                                    setCoords(e.get('coords'));
                                                }}
                                            >
                                                {coords ? (
                                                    <Placemark
                                                        geometry={coords}
                                                    />
                                                ) : (
                                                    <div></div>
                                                )}
                                            </Map>
                                        </YMaps>
                                    </Form.Item>
                                </div>
                            </Panel>
                            <Panel forceRender header="Картинки ЖК" key="1">
                                <Form.Item
                                    hidden
                                    label=""
                                    name="photo"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Загрузите главную фотографию жк',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                ></Form.Item>
                                <Form.Item
                                    label=""
                                    name="photos"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Загрузите фотографии жк',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    <div
                                        className={
                                            styles.inputSectionFormShadow
                                        }
                                    >
                                        {getFilesState.request_upload_status ? (
                                            <div
                                                className={
                                                    styles.uploadButtonBlock
                                                }
                                            >
                                                <small>Подождите</small>
                                                <LoadingOutlined
                                                    className={
                                                        styles.uploadButtonBlockIconLoad
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    styles.uploadButtonBlock
                                                }
                                            >
                                                <Button
                                                    size="large"
                                                    type="primary"
                                                >
                                                    Загрузить картинку
                                                    <Input
                                                        className={
                                                            styles.inputFile
                                                        }
                                                        type="file"
                                                        onChange={(val) => {
                                                            dispatch(
                                                                api.files.uploadFile(
                                                                    val.target
                                                                        .files[0],
                                                                    'IMAGE'
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Button>
                                            </div>
                                        )}
                                        <div
                                            className={`images-block-interior-flat ${styles.listImagesBlock}`}
                                        >
                                            {getFilesState.APARTMENT_INTERIOR ? (
                                                getFilesState.APARTMENT_INTERIOR.map(
                                                    (item, index) => (
                                                        <div
                                                            key={index}
                                                            className={`image-interior-flat ${styles.listImageBlock}`}
                                                            style={{
                                                                background: `url(${config.public}450/340/${item.url}) center / cover no-repeat`,
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.imageSizeBlock
                                                                }
                                                            >
                                                                <h5>
                                                                    <b>
                                                                        Оригинал:{' '}
                                                                    </b>{' '}
                                                                    {formatBytes(
                                                                        Number(
                                                                            (item?.original_size ||
                                                                                0) *
                                                                                (1024 *
                                                                                    1024)
                                                                        )
                                                                    )}
                                                                </h5>
                                                                <h5>
                                                                    <b>
                                                                        После
                                                                        сжатия:{' '}
                                                                    </b>{' '}
                                                                    {formatBytes(
                                                                        Number(
                                                                            (item?.size ||
                                                                                0) *
                                                                                (1024 *
                                                                                    1024)
                                                                        )
                                                                    )}
                                                                </h5>
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.controlBlockImages
                                                                }
                                                            >
                                                                <Popover
                                                                    content={
                                                                        <div
                                                                            className={
                                                                                styles.contentPopover
                                                                            }
                                                                        >
                                                                            <p>
                                                                                Установит
                                                                                главной
                                                                                фотографией
                                                                            </p>
                                                                            <p>
                                                                                Будет
                                                                                первой
                                                                                в
                                                                                слайдере
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                    title="Пояснение"
                                                                >
                                                                    <CheckCircleOutlined
                                                                        onClick={() =>
                                                                            changeMainPhoto(
                                                                                item
                                                                            )
                                                                        }
                                                                        className={
                                                                            index ==
                                                                            0
                                                                                ? styles.getFirstPhoto
                                                                                : false
                                                                        }
                                                                    />
                                                                </Popover>

                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            {
                                                                                type: 'DELETE_IMAGE_APARTMENT_INTERIOR',
                                                                                index,
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    <Delete />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div>
                                                    <p>Картинок нет</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Form.Item>
                            </Panel>
                            {type_content === 'standart' ? (
                                <Panel
                                    forceRender
                                    header="Панорамная фотография жк с блоками"
                                    key="2"
                                >
                                    <div
                                        className={
                                            styles.inputSectionFormShadow
                                        }
                                    >
                                        <Form.Item
                                            label=""
                                            name="image_complex_with_all_blocks"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Загрузите панорамную фотографию жк с блоками',
                                                },
                                            ]}
                                            className={styles.formItemStyle}
                                        >
                                            <div className="section-block-photos">
                                                <Dropzone
                                                    onDrop={handleDropBlocks.bind(
                                                        undefined
                                                    )}
                                                >
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                    }) => (
                                                        <section className="drag-photo-complex">
                                                            {getFilesState.request_upload_status ? (
                                                                <div>
                                                                    <Autorenew
                                                                        size={
                                                                            55
                                                                        }
                                                                        className="spin"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    {...getRootProps()}
                                                                >
                                                                    <input
                                                                        {...getInputProps()}
                                                                    />
                                                                    <p>
                                                                        Для
                                                                        загрузки
                                                                        фотографии
                                                                        можете
                                                                        перенести
                                                                        файл
                                                                        сюда или
                                                                        нажать
                                                                        на
                                                                        область
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </section>
                                                    )}
                                                </Dropzone>

                                                {getFilesState.photo_apartment_complex_with_blocks ? (
                                                    <div
                                                        className="photo-apartment-url"
                                                        style={{
                                                            background: `url(${config.public}450/340/${getFilesState.photo_apartment_complex_with_blocks.url}) center / cover no-repeat`,
                                                        }}
                                                    ></div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </div>
                                        </Form.Item>
                                    </div>
                                </Panel>
                            ) : (
                                <></>
                            )}
                        </Collapse>
                    </Panel>
                    <Panel forceRender header="Способы покупки" key="3">
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="min_price_square_metres"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Заполните минимальную стоимость за м2',
                                    },
                                ]}
                                className={styles.formItemStyle}
                            >
                                <Popover
                                    content="Минимальная стоимость квадратного метра"
                                    title=""
                                >
                                    <Input
                                        size="large"
                                        suffix="₸"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                min_price_square_metres:
                                                    val.target.value,
                                            });
                                            setMin_price_square_metres(
                                                val.target.value
                                            );
                                        }}
                                        placeholder="Минимальная стоимость квадратного метра (тг)*"
                                        type="number"
                                        value={min_price_square_metres}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="bank_installment"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Ипотека" title="">
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                bank_installment:
                                                    val.target.value,
                                            });
                                            set_bank_installment(
                                                val.target.value
                                            );
                                        }}
                                        placeholder="Ипотека"
                                        value={bank_installment}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="installment"
                                className={styles.formItemStyle}
                            >
                                <Popover
                                    content="Рассрочка от Застройщика"
                                    title=""
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                installment: val.target.value,
                                            });
                                            set_installment(val.target.value);
                                        }}
                                        placeholder="Рассрочка от Застройщика"
                                        value={installment}
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Popover content="Рассрочка" title="">
                                <Form.Item
                                    label=""
                                    name="mortgages"
                                    className={styles.formItemStyle}
                                >
                                    <Select
                                        size="large"
                                        mode="tags"
                                        showSearch
                                        placeholder="выберите способ покупки"
                                        className={styles.formSelect}
                                    >
                                        {getMortgage && getMortgage.length ? (
                                            getMortgage.map((item) => {
                                                return (
                                                    <Option
                                                        key={item._id}
                                                        style={{ fontSize: 17 }}
                                                        value={item._id}
                                                    >
                                                        {item.title}
                                                    </Option>
                                                );
                                            })
                                        ) : (
                                            <Option
                                                style={{ fontSize: 17 }}
                                                value={null}
                                            >
                                                Не найдено
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Popover>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="state_programs"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Госпрограммы" title="">
                                    <Select
                                        size="large"
                                        mode="tags"
                                        showSearch
                                        placeholder="Выберите госпрограмму"
                                        className={styles.formSelect}
                                        value={state_programs}
                                        defaultValue={[]}
                                        onChange={(value) => {
                                            formRef.current.setFieldsValue({
                                                state_programs: value,
                                            });
                                            set_state_programs(value);
                                        }}
                                    >
                                        {getStatePrograms &&
                                        getStatePrograms.length ? (
                                            getStatePrograms.map((item) => {
                                                return (
                                                    <Option
                                                        key={keyRnd({
                                                            length: 15,
                                                        })}
                                                        style={{ fontSize: 17 }}
                                                        value={item._id}
                                                    >
                                                        {item?.title}
                                                    </Option>
                                                );
                                            })
                                        ) : (
                                            <Option
                                                style={{ fontSize: 17 }}
                                                value={null}
                                            >
                                                Не найдено
                                            </Option>
                                        )}
                                    </Select>
                                </Popover>
                            </Form.Item>
                        </section>

                        <Collapse accordion>
                            <Panel forceRender header="Документы" key="1">
                                <Button
                                    type="primary"
                                    size="large"
                                    className={styles.uploadFileButton}
                                >
                                    {document_status_upload ? (
                                        <>Подождите...</>
                                    ) : (
                                        <>
                                            Загрузить документ
                                            <input
                                                className={styles.inputFile}
                                                type="file"
                                                onChange={uploadDocument}
                                            />
                                        </>
                                    )}
                                </Button>
                                <Form.Item
                                    label=""
                                    name="documents"
                                    className={styles.formItemStyle}
                                >
                                    <div className={styles.listDocuments}>
                                        {documents.length ? (
                                            documents.map((item) => (
                                                <div
                                                    key={item?.id || item?._id}
                                                    className={
                                                        styles.itemDocument
                                                    }
                                                >
                                                    <h5>
                                                        {item?.full_url ||
                                                            item?.url}
                                                    </h5>
                                                    <DeleteOutlined
                                                        onClick={() => {
                                                            set_documents(
                                                                documents.filter(
                                                                    (doc) =>
                                                                        doc?.id !=
                                                                            item?.id ||
                                                                        doc?._id !=
                                                                            item?._id
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <h6>Документов нет</h6>
                                        )}
                                    </div>
                                </Form.Item>
                            </Panel>
                        </Collapse>
                    </Panel>
                    <Panel forceRender header="настройки" key="4">
                        <Collapse accordion>
                            <Panel forceRender header="CRM настройки" key="1">
                                <section
                                    className={`input-section-form ${styles.inputSectionForm}`}
                                >
                                    <Form.Item
                                        label=""
                                        name="commission"
                                        className={styles.formItemStyle}
                                    >
                                        <Popover
                                            content="Процент от продажи"
                                            title=""
                                        >
                                            <Input
                                                suffix="%"
                                                onChange={(val) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            commission:
                                                                val.target
                                                                    .value,
                                                        }
                                                    );
                                                    set_commission(
                                                        val.target.value
                                                    );
                                                }}
                                                placeholder="Процент от продажи"
                                                value={commission}
                                            />
                                        </Popover>
                                    </Form.Item>
                                </section>
                                <section
                                    className={`input-section-form ${styles.inputSectionForm}`}
                                >
                                    <Form.Item
                                        label=""
                                        name="crm_price_lead"
                                        className={styles.formItemStyle}
                                    >
                                        <Popover
                                            content="Стоимость лида"
                                            title=""
                                        >
                                            <Input
                                                size="large"
                                                suffix="₸"
                                                onChange={(val) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            crm_price_lead:
                                                                val.target
                                                                    .value,
                                                        }
                                                    );
                                                    set_crm_price_lead(
                                                        val.target.value
                                                    );
                                                }}
                                                placeholder="Стоимость лида"
                                                value={crm_price_lead}
                                            />
                                        </Popover>
                                    </Form.Item>
                                </section>
                            </Panel>
                            <Panel
                                forceRender
                                header="Настройка синхронизации"
                                key="2"
                            >
                                <div>
                                    <section
                                        className={`input-section-form ${styles.inputSectionForm}`}
                                    >
                                        <Form.Item
                                            label=""
                                            name="sync_type"
                                            className={styles.formItemStyle}
                                        >
                                            <Popover
                                                content="Тип синхронизации"
                                                title=""
                                            >
                                                <Select
                                                    size="large"
                                                    value={sync_type}
                                                    className={
                                                        styles.formSelect
                                                    }
                                                    onChange={(value) => {
                                                        formRef.current.setFieldsValue(
                                                            {
                                                                sync_type:
                                                                    value,
                                                            }
                                                        );
                                                        set_sync_type(value);
                                                    }}
                                                >
                                                    <Option
                                                        style={{ fontSize: 17 }}
                                                        value=""
                                                    >
                                                        Нет синхронизации
                                                    </Option>
                                                    <Option
                                                        style={{ fontSize: 17 }}
                                                        value="BI_GROUP"
                                                    >
                                                        Bi group
                                                    </Option>
                                                    <Option
                                                        style={{ fontSize: 17 }}
                                                        value="BITRIX"
                                                    >
                                                        Битрикс (flatris)
                                                    </Option>
                                                    <Option
                                                        style={{ fontSize: 17 }}
                                                        value="BITRIX_PROFITBASE"
                                                    >
                                                        Битрикс (profitbase)
                                                    </Option>
                                                    <Option
                                                        style={{ fontSize: 17 }}
                                                        value="MACRO"
                                                    >
                                                        Macro
                                                    </Option>
                                                    <Option
                                                        style={{ fontSize: 17 }}
                                                        value="MWC"
                                                    >
                                                        MWC
                                                    </Option>
                                                </Select>
                                            </Popover>
                                        </Form.Item>
                                    </section>

                                    {sync_type != '' && (
                                        <>
                                            <section
                                                className={`input-section-form ${styles.inputSectionForm}`}
                                            >
                                                <Form.Item
                                                    label=""
                                                    name="type_block"
                                                    className={
                                                        styles.formItemStyle
                                                    }
                                                >
                                                    <Popover
                                                        content="Тип блока"
                                                        title=""
                                                    >
                                                        <Select
                                                            size="large"
                                                            value={type_block}
                                                            className={
                                                                styles.formSelect
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                formRef.current.setFieldsValue(
                                                                    {
                                                                        type_block:
                                                                            value,
                                                                    }
                                                                );
                                                                set_type_block(
                                                                    value
                                                                );
                                                            }}
                                                        >
                                                            <Option
                                                                style={{
                                                                    fontSize: 17,
                                                                }}
                                                                value=""
                                                            >
                                                                Стандартный
                                                            </Option>
                                                            <Option
                                                                style={{
                                                                    fontSize: 17,
                                                                }}
                                                                value="BLOCK"
                                                            >
                                                                Блок
                                                            </Option>
                                                            <Option
                                                                style={{
                                                                    fontSize: 17,
                                                                }}
                                                                value="SECTION"
                                                            >
                                                                Секция
                                                            </Option>
                                                        </Select>
                                                    </Popover>
                                                </Form.Item>
                                            </section>

                                            <section
                                                className={`input-section-form ${styles.inputSectionForm}`}
                                            >
                                                <Form.Item
                                                    label=""
                                                    name="sync_id"
                                                    className={
                                                        styles.formItemStyle
                                                    }
                                                >
                                                    <Popover
                                                        content="ID жилого комплекса"
                                                        title=""
                                                    >
                                                        <Input
                                                            size="large"
                                                            onChange={(val) => {
                                                                formRef.current.setFieldsValue(
                                                                    {
                                                                        sync_id:
                                                                            val
                                                                                .target
                                                                                .value,
                                                                    }
                                                                );
                                                                set_sync_id(
                                                                    val.target
                                                                        .value
                                                                );
                                                            }}
                                                            placeholder="ID жилого комплекса (необязательно)"
                                                            value={sync_id}
                                                        />
                                                    </Popover>
                                                </Form.Item>
                                            </section>
                                            {(sync_type == 'BITRIX' ||
                                                sync_type == 'MWC') && (
                                                <section
                                                    className={`input-section-form ${styles.inputSectionForm}`}
                                                >
                                                    <Form.Item
                                                        label=""
                                                        name="sync_api_url_method"
                                                        className={
                                                            styles.formItemStyle
                                                        }
                                                    >
                                                        <Popover
                                                            content="Api метод"
                                                            title=""
                                                        >
                                                            <Input
                                                                size="large"
                                                                onChange={(
                                                                    val
                                                                ) => {
                                                                    formRef.current.setFieldsValue(
                                                                        {
                                                                            sync_api_url_method:
                                                                                val
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    set_sync_api_url_method(
                                                                        val
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Api метод (необязательно)"
                                                                value={
                                                                    sync_api_url_method
                                                                }
                                                            />
                                                        </Popover>
                                                    </Form.Item>
                                                </section>
                                            )}
                                            {sync_type != 'MWC' && (
                                                <section
                                                    className={`input-section-form ${styles.inputSectionForm}`}
                                                >
                                                    <Form.Item
                                                        label=""
                                                        name="sync_api_key"
                                                        className={
                                                            styles.formItemStyle
                                                        }
                                                    >
                                                        <Popover
                                                            content="Api key"
                                                            title=""
                                                        >
                                                            <Input.Password
                                                                size="large"
                                                                onChange={(
                                                                    val
                                                                ) => {
                                                                    formRef.current.setFieldsValue(
                                                                        {
                                                                            sync_api_key:
                                                                                val
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    set_sync_api_key(
                                                                        val
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Api key (необязательно)"
                                                                value={
                                                                    sync_api_key
                                                                }
                                                            />
                                                        </Popover>
                                                    </Form.Item>
                                                </section>
                                            )}

                                            {sync_type === 'MACRO' ? (
                                                <section
                                                    className={`input-section-form ${styles.inputSectionForm}`}
                                                >
                                                    <Form.Item
                                                        label=""
                                                        name="sync_domain"
                                                        className={
                                                            styles.formItemStyle
                                                        }
                                                    >
                                                        <Popover
                                                            content="Добавьте домен, который вы указали в системе macro"
                                                            title=""
                                                        >
                                                            <Input
                                                                size="large"
                                                                onChange={(
                                                                    val
                                                                ) => {
                                                                    formRef.current.setFieldsValue(
                                                                        {
                                                                            sync_domain:
                                                                                val
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    set_sync_domain(
                                                                        val
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Добавьте домен, который вы указали в системе macro"
                                                                value={
                                                                    sync_domain
                                                                }
                                                            />
                                                        </Popover>
                                                    </Form.Item>
                                                </section>
                                            ) : sync_type != 'MWC' ? (
                                                <section
                                                    className={`input-section-form ${styles.inputSectionForm}`}
                                                >
                                                    <Form.Item
                                                        label=""
                                                        name="sync_api_login"
                                                        className={
                                                            styles.formItemStyle
                                                        }
                                                    >
                                                        <Popover
                                                            content="Api login"
                                                            title=""
                                                        >
                                                            <Input
                                                                size="large"
                                                                onChange={(
                                                                    val
                                                                ) => {
                                                                    formRef.current.setFieldsValue(
                                                                        {
                                                                            sync_api_login:
                                                                                val
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    set_sync_api_login(
                                                                        val
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Api login (необязательно)"
                                                                value={
                                                                    sync_api_login
                                                                }
                                                            />
                                                        </Popover>
                                                    </Form.Item>
                                                </section>
                                            ) : (
                                                <></>
                                            )}
                                            {sync_type == 'BI_GROUP' && (
                                                <section
                                                    className={`input-section-form ${styles.inputSectionForm}`}
                                                >
                                                    <Form.Item
                                                        label=""
                                                        name="sync_tour_transfer"
                                                        valuePropName="checked"
                                                        className={
                                                            styles.formItemStyle
                                                        }
                                                    >
                                                        <Popover
                                                            content="Передача 3D туров"
                                                            title=""
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    sync_tour_transfer
                                                                }
                                                                onChange={(
                                                                    val
                                                                ) => {
                                                                    formRef.current.setFieldsValue(
                                                                        {
                                                                            sync_tour_transfer:
                                                                                val
                                                                                    .target
                                                                                    .checked,
                                                                        }
                                                                    );
                                                                    set_sync_tour_transfer(
                                                                        val
                                                                            .target
                                                                            .checked
                                                                    );
                                                                }}
                                                            >
                                                                Передача 3D
                                                                туров(необязательно)
                                                            </Checkbox>
                                                        </Popover>
                                                    </Form.Item>
                                                </section>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Panel>
                            <Panel forceRender header="SEO контент" key="3">
                                <div className={styles.inputSectionFormShadow}>
                                    <section>
                                        <Form.Item
                                            label=""
                                            name="seo_content"
                                            className={styles.formItemStyle}
                                        >
                                            <Editor
                                                dispatch={dispatch}
                                                api={api}
                                                seo_content={seo_content}
                                                setContent={(val) => {
                                                    set_seo_content(val);
                                                }}
                                            />
                                        </Form.Item>
                                    </section>

                                    <section>
                                        <div
                                            className={`plan-block-file seo-block-form ${styles.blockSeoRealEstate}`}
                                        >
                                            <h4>SEO</h4>

                                            <Popover
                                                content="Keywords"
                                                title=""
                                            >
                                                <Form.Item
                                                    label=""
                                                    name="seo_keywords"
                                                    className={
                                                        styles.formItemStyle
                                                    }
                                                >
                                                    <Input
                                                        size="large"
                                                        type="text"
                                                        value={seo_keywords}
                                                        onChange={(val) => {
                                                            formRef.current.setFieldsValue(
                                                                {
                                                                    seo_keywords:
                                                                        val
                                                                            .target
                                                                            .value,
                                                                }
                                                            );
                                                            setSeoKeywords(
                                                                val.target.value
                                                            );
                                                        }}
                                                        placeholder="Keywords"
                                                    />
                                                </Form.Item>
                                            </Popover>

                                            <Popover
                                                content="Description"
                                                title=""
                                            >
                                                <Form.Item
                                                    label=""
                                                    name="seo_description"
                                                    className={
                                                        styles.formItemStyle
                                                    }
                                                >
                                                    <Input
                                                        size="large"
                                                        type="text"
                                                        value={seo_description}
                                                        onChange={(val) => {
                                                            formRef.current.setFieldsValue(
                                                                {
                                                                    seo_description:
                                                                        val
                                                                            .target
                                                                            .value,
                                                                }
                                                            );
                                                            setSeoDescription(
                                                                val.target.value
                                                            );
                                                        }}
                                                        placeholder="Description"
                                                    />
                                                </Form.Item>
                                            </Popover>

                                            <Popover content="Title" title="">
                                                <Form.Item
                                                    label=""
                                                    name="seo_title"
                                                    className={
                                                        styles.formItemStyle
                                                    }
                                                >
                                                    <Input
                                                        size="large"
                                                        type="text"
                                                        value={seo_title}
                                                        onChange={(val) => {
                                                            formRef.current.setFieldsValue(
                                                                {
                                                                    seo_title:
                                                                        val
                                                                            .target
                                                                            .value,
                                                                }
                                                            );
                                                            setSeoTitle(
                                                                val.target.value
                                                            );
                                                        }}
                                                        placeholder="Title"
                                                    />
                                                </Form.Item>
                                            </Popover>
                                        </div>
                                    </section>
                                </div>
                            </Panel>
                            <Panel
                                forceRender
                                header="Дополнительная информация"
                                key="4"
                            >
                                <div className={styles.inputSectionFormShadow}>
                                    <section>
                                        <div
                                            className={`plan-block-file seo-block-form ${styles.blockSeoRealEstate}`}
                                        >
                                            <h4 style={{ marginBottom: 5 }}>
                                                3D Тур (выберите шаблон)
                                            </h4>

                                            <Popover
                                                content="3D Тур (выберите шаблон)"
                                                title=""
                                            >
                                                <Form.Item
                                                    label=""
                                                    name="view_tour"
                                                    className={
                                                        styles.formItemStyle
                                                    }
                                                >
                                                    <Select
                                                        value={view_tour}
                                                        className={
                                                            styles.formSelect
                                                        }
                                                        onChange={(value) => {
                                                            formRef.current.setFieldsValue(
                                                                {
                                                                    view_tour:
                                                                        value,
                                                                }
                                                            );
                                                            set_view_tour(
                                                                value
                                                            );
                                                        }}
                                                        placeholder="Выберите шаблон"
                                                    >
                                                        {getListTemplates &&
                                                        getListTemplates.length ? (
                                                            getListTemplates.map(
                                                                (template) => {
                                                                    return (
                                                                        <Option
                                                                            key={
                                                                                item._id
                                                                            }
                                                                            style={{
                                                                                fontSize: 17,
                                                                            }}
                                                                            value={
                                                                                template?._id
                                                                            }
                                                                        >
                                                                            {
                                                                                template?.title
                                                                            }
                                                                        </Option>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <Option
                                                                style={{
                                                                    fontSize: 17,
                                                                }}
                                                                value="NONE"
                                                            >
                                                                Шаблонов не
                                                                найдено
                                                            </Option>
                                                        )}
                                                        <Option
                                                            style={{
                                                                fontSize: 17,
                                                            }}
                                                            value="NONE"
                                                        >
                                                            Скрыть
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </Popover>
                                        </div>
                                    </section>
                                </div>
                            </Panel>
                            <Panel forceRender header="Шахматка" key="5">
                                <section
                                    className={`input-section-form ${styles.inputSectionForm}`}
                                >
                                    <Popover
                                        placement="bottom"
                                        content="Выберите тип шахматки"
                                        title=""
                                    >
                                        <Form.Item
                                            label=""
                                            name="chessboard"
                                            className={styles.formItemStyle}
                                        >
                                            <Select
                                                size="large"
                                                value={chessboard}
                                                onChange={(value) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            chessboard: value,
                                                        }
                                                    );
                                                    set_chessboard(value);
                                                }}
                                                placeholder="Выберите тип шахматки"
                                                className={styles.selectInput}
                                            >
                                                <Option
                                                    style={{ fontSize: 17 }}
                                                    value="standart"
                                                >
                                                    Стандартная
                                                </Option>
                                                <Option
                                                    style={{ fontSize: 17 }}
                                                    value="custom"
                                                >
                                                    Кастомная
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </Popover>
                                </section>
                            </Panel>
                            <Panel
                                forceRender
                                header="Виртуальные туры"
                                key="6"
                            >
                                <section
                                    className={`input-section-form ${styles.inputSectionForm}`}
                                >
                                    <BindVT
                                        id={item?._id}
                                        type_collection="realestate"
                                    />

                                    <div className={styles.listVT}>
                                        {getVTByRealestate &&
                                        getVTByRealestate.length
                                            ? getVTByRealestate.map(
                                                  (vt_item) => {
                                                      return (
                                                          <div
                                                              className={
                                                                  styles.item_vt
                                                              }
                                                              key={vt_item?._id}
                                                          >
                                                              <b>
                                                                  {
                                                                      vt_item?.title
                                                                  }
                                                              </b>
                                                              <Tooltip
                                                                  placement="bottom"
                                                                  title="Открепить виртуальный тур"
                                                              >
                                                                  <Popconfirm
                                                                      title="Виртуальный тур будет откреплен"
                                                                      onConfirm={() => {
                                                                          dispatch(
                                                                              api.apartments.BindVT(
                                                                                  vt_item?._id,
                                                                                  item?._id,
                                                                                  'remove',
                                                                                  'realestate'
                                                                              )
                                                                          );
                                                                      }}
                                                                      okText="Открепить"
                                                                      cancelText="Нет"
                                                                  >
                                                                      <DeleteOutlined />
                                                                  </Popconfirm>
                                                              </Tooltip>
                                                          </div>
                                                      );
                                                  }
                                              )
                                            : 'Виртуальные туры не привязаны'}
                                    </div>
                                </section>
                            </Panel>
                        </Collapse>
                    </Panel>
                </Collapse>

                <section
                    className={`input-section-form ${styles.inputSectionForm}`}
                    style={{ marginTop: 10 }}
                >
                    <Popover content="Статус публикации" title="">
                        <Form.Item
                            label=""
                            name="visibility_status"
                            className={styles.formItemStyle}
                        >
                            <Select size="large" className={styles.selectInput}>
                                <Option style={{ fontSize: 17 }} value="public">
                                    Опубликован
                                </Option>
                                <Option style={{ fontSize: 17 }} value="hide">
                                    Скрытый
                                </Option>
                                <Option style={{ fontSize: 17 }} value="closed">
                                    Закрытый
                                </Option>
                            </Select>
                        </Form.Item>
                    </Popover>
                </section>

                <section>
                    {item.updatedAt ? (
                        <small>
                            {moment(item.updatedAt).format(
                                'Последнее изменение DD MMMM YYYY в HH:mm'
                            )}
                        </small>
                    ) : (
                        <div></div>
                    )}
                </section>

                <section style={{ marginTop: 10 }}>
                    {getApartmentsState.request_apartments_status ? (
                        <Button type="primary">
                            <LoadingOutlined />
                            Подождите
                        </Button>
                    ) : (
                        <Button type="primary" htmlType="submit">
                            Редактировать
                        </Button>
                    )}
                </section>
            </Form>
        </div>
    );
};

export default ApartmentСomplex;
