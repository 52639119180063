import axios from '../axios';
import { message } from 'antd';
import statistic from '../Statistic';

const logs = {
    getSyncStatusFlatsRealestateMWC: (realestate_id) => async dispatch => {

        dispatch({
            type: 'SYNC_REQUEST_OWNER_API',
            status: true,
        });

        try {

            let { data } = await axios.get('/v1/admin/logs/mwc/realestate/sync-status-flats', {
                params: {
                    realestate_id
                }
            });

            dispatch({
                type: 'SYNC_OWNER_FLATS_DATA',
                data
            });

            return dispatch({
                type: 'SYNC_REQUEST_OWNER_API',
                status: false,
            });

        }catch(err){
            dispatch({
                type: 'SYNC_REQUEST_OWNER_API',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }

    },
    getRealestateOwnerMWC: () => async dispatch => {

        dispatch({
            type: 'SYNC_REQUEST_OWNER_API',
            status: true,
        });

        try {

            let { data } = await axios.get('/v1/admin/logs/mwc/realestate/owner');

            dispatch({
                type: 'SYNC_OWNER_REALESTATE_DATA',
                data
            })

            return dispatch({
                type: 'SYNC_REQUEST_OWNER_API',
                status: false,
            });

        }catch(err){
            dispatch({
                type: 'SYNC_REQUEST_OWNER_API',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }

    },
    getRealestateMWC: () => async (dispatch) => {
        dispatch({
            type: 'SYNC_REQUEST_API',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/mwc/realestate');

            dispatch({
                type: 'SYNC_REALESTATE_DATA',
                data,
            });

            dispatch({
                type: 'SYNC_REQUEST_API',
                status: false,
            });

        } catch (err) {
            dispatch({
                type: 'SYNC_REQUEST_API',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    getFlatsMacro: (realestate_id) => async (dispatch) => {
        dispatch({
            type: 'MACRO_REQUEST_API',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/flats-macro', {
                params: {
                    realestate_id,
                },
            });

            dispatch({
                type: 'MACRO_REALESTATE_FLATS',
                data,
            });

            dispatch({
                type: 'MACRO_REQUEST_API',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'MACRO_REQUEST_API',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    realestateOfMacroAPI: (realestate) => async (dispatch) => {
        dispatch({
            type: 'MACRO_REQUEST_API',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/admin/logs/sync-realestate-macro-api',
                {
                    realestate,
                }
            );

            dispatch({
                type: 'MACRO_REALESTATE_LIST_API',
                data,
            });

            dispatch({
                type: 'MACRO_REQUEST_API',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'MACRO_REQUEST_API',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    uploadXml: (xml_link) => async (dispatch) => {
        if (!xml_link) {
            return message.error('Добавьте ссылку');
        }

        try {
            dispatch({
                type: 'STATUS_UPLOAD_XML_FILE',
                status: true,
            });

            await axios.post('/v1/admin/logs/upload-xml-file', {
                xml_link,
            });

            dispatch({
                type: 'STATUS_UPLOAD_XML_FILE',
                status: false,
            });

            dispatch(logs.xmlPrices(1, 75));
            dispatch(statistic.settings('xml-feeds'));
            return message.success('Данные успешно обновлены');
        } catch (err) {
            dispatch({
                type: 'STATUS_UPLOAD_XML_FILE',
                status: false,
            });
        }
    },
    xmlPrices:
        (page, limit, search_text, search_brand, search_price) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: 'STATUS_REQUEST_XML_PRICES',
                    status: true,
                });

                let { data } = await axios.get('/v1/admin/logs/xml-prices', {
                    params: {
                        page,
                        limit,
                        search: search_text,
                        search_brand,
                        search_price,
                    },
                });

                dispatch({
                    type: 'DATA_XML_PRICES',
                    data,
                });

                return dispatch({
                    type: 'STATUS_REQUEST_XML_PRICES',
                    status: false,
                });
            } catch (err) {
                dispatch({
                    type: 'STATUS_REQUEST_XML_PRICES',
                    status: false,
                });
            }
        },
    realestateOfBitrixAPI: (realestate) => async (dispatch) => {
        dispatch({
            type: 'STATUS_REQUEST_REALESTATE_API_BITRIX',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/admin/logs/sync-realestate-bitrix-api',
                {
                    realestate,
                }
            );

            dispatch({
                type: 'UPDATE_API_BITRIX_REALESTATE',
                data,
            });

            dispatch({
                type: 'STATUS_REQUEST_REALESTATE_API_BITRIX',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'STATUS_REQUEST_REALESTATE_API_BITRIX',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    searchFlatAI:
        (price = 10000000, area = 35, rooms = 1, count_iterations = 1000) =>
        async (dispatch) => {
            dispatch({
                type: 'LOG_SYNC_REQUEST_STATUS',
                status: true,
            });

            try {
                let { data } = await axios.post('/filter-ai', {
                    price,
                    area,
                    rooms,
                    count_iterations,
                });

                dispatch({
                    type: 'UPDATE_LOGS_FLATS',
                    data,
                });

                dispatch({
                    type: 'LOG_SYNC_REQUEST_STATUS',
                    status: false,
                });

                return;
            } catch (err) {
                dispatch({
                    type: 'LOG_SYNC_REQUEST_STATUS',
                    status: false,
                });
                return message.error('Что-то пошло не так, попробуйте позже');
            }
        },
    syncRealEstateBitrix: (realestate_id) => async (dispatch) => {
        dispatch({
            type: 'STATUS_REALESTATE_SYNC_BITRIX',
            status: true,
        });

        try {
            await axios.get('/v1/admin/logs/sync-realestate-bitrix', {
                params: {
                    realestate_id
                },
            });

            dispatch({
                type: 'STATUS_REALESTATE_SYNC_BITRIX',
                status: false,
            });

            message.success('ЖК успешно синхронизирован');

            return;
        } catch (err) {
            dispatch({
                type: 'STATUS_REALESTATE_SYNC_BITRIX',
                status: false,
            });

            if (err.response.status == 400) {
                return message.error(
                    'Синхронизация завершилась неудачей, обратитесь к администратору сервера'
                );
            }

            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    syncRealEstateMacro: (realestate_id, type = 'realestate_list') => async (dispatch) => {
        dispatch({
            type: 'MACRO_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/sync-realestate-macro', {
                params: {
                    realestate_id,
                    type
                },
            });

            dispatch({
                type: 'MACRO_REALESTATE_LIST',
                data,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'MACRO_REQUEST',
                status: false,
            });

            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    getFlatsBitrix: (realestate_id) => async (dispatch) => {
        dispatch({
            type: 'LOG_SYNC_REQUEST_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/flats-bitrix', {
                params: {
                    realestate_id,
                },
            });

            dispatch({
                type: 'UPDATE_LOGS_FLATS',
                data,
            });

            dispatch({
                type: 'LOG_SYNC_REQUEST_STATUS',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'LOG_SYNC_REQUEST_STATUS',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    getRealEstateBitrix: () => async (dispatch) => {
        dispatch({
            type: 'LOG_REQUEST_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/realestate-bitrix');

            dispatch({
                type: 'UPDATE_LOGS_APART_COMPLEX',
                data,
            });

            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    getConnectionsInformation: () => async (dispatch) => {
        dispatch({
            type: 'STATUS_LOADING_CONNECTION_INFORMATION',
            status: true,
        });

        try {
            let { data } = await axios.get(
                '/v1/admin/logs/connection-information'
            );
            dispatch({
                type: 'STATUS_LOADING_CONNECTION_INFORMATION',
                status: false,
            });

            dispatch({
                type: 'CONNECTION_INFORMATION',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'STATUS_LOADING_CONNECTION_INFORMATION',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    getConnections: (page) => async (dispatch) => {
        dispatch({
            type: 'STATUS_LOADING_CONNECTIONS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/connections', {
                params: {
                    page,
                },
            });
            dispatch({
                type: 'STATUS_LOADING_CONNECTIONS',
                status: false,
            });

            dispatch({
                type: 'CONNECTIONS_LIST',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'STATUS_LOADING_CONNECTIONS',
                status: false,
            });
            return message.error('Что-то пошло не так, попробуйте позже');
        }
    },
    rebuild: async () => {
        axios.defaults.headers.post['Content-Type'] =
            'application/x-www-form-urlencoded';

        message.success('Происходит сборка проекта, ожидайте', 7);

        try {
            await fetch(
                'https://api.vercel.com/v1/integrations/deploy/prj_QkWmdjP9XwpDXLnYItS2hQIZNcQn/68QfX3rfSS'
            );

            return;
        } catch (err) {
            console.log('error rebuild -> ', err);
            return message.error('Ошибка при сборке проекта');
        }
    },
    syncBIG: () => async (dispatch) => {
        dispatch({
            type: 'LOG_SYNC_REQUEST_STATUS',
            status: true,
        });

        try {
            await axios.get('/v1/admin/logs/sync/big');

            dispatch({
                type: 'LOG_SYNC_REQUEST_STATUS',
                status: false,
            });

            return message.success('Синхронизация прошла успешно');
        } catch (err) {
            dispatch({
                type: 'LOG_SYNC_REQUEST_STATUS',
                status: false,
            });

            message.error('Ошибка.');
        }
    },
    getFlats: (type, guid) => async (dispatch) => {
        dispatch({
            type: 'LOG_REQUEST_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/flats', {
                params: {
                    type,
                    guid,
                },
            });

            dispatch({
                type: 'UPDATE_LOGS_FLATS',
                data,
            });

            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });

            message.error('Ошибка');
        }
    },
    getBlocks: (guid) => async (dispatch) => {
        dispatch({
            type: 'LOG_REQUEST_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/blocks', {
                params: {
                    guid,
                },
            });

            dispatch({
                type: 'UPDATE_LOGS_BLOCKS',
                data,
            });

            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });

            message.error('Ошибка');
        }
    },
    getComplex: () => async (dispatch) => {
        dispatch({
            type: 'LOG_REQUEST_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/logs/apart_complex');

            dispatch({
                type: 'UPDATE_LOGS_APART_COMPLEX',
                data,
            });

            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'LOG_REQUEST_STATUS',
                status: false,
            });

            message.error('Ошибка');
        }
    },
};

export default logs;
