import React, { useState } from 'react';
import styles from './styles/image.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Image = ({src}) => {

    const [loading, set_loading] = useState(true);

    return (
        <div className={styles.imageWrapper}>

            <div className={`${styles.loadingOverlay} ${loading ? styles.loadingOverlayActive : ''}`}>
                <LoadingOutlined />
            </div>

            <img
                alt="image"
                style={{
                    width: '100%',
                }}
                src={src}
                onLoad={()=>set_loading(false)}

            />
        </div>
    )

}

export default Image;