import React from 'react';
import { Form, Typography, Select, Input } from 'antd';
import styles from '../../../styles/index.module.scss';

const { Text } = Typography;

const BuilderFormItemWebhook = () => {

    const form = Form.useFormInstance();
    const type_webhook = Form.useWatch('type_webhook', form);

    return (
        <div className={styles.formSectionGroup}>

            <Text type="secondary">Webhook (настройка интеграции)</Text>

            <Form.Item
                label="Способ интеграции"
                name="type_webhook"
            >
                <Select
                    options={options}
                    placeholder="Способ интеграции"
                />
            </Form.Item>

            {
                !!(type_webhook) && (
                    <>
                        {
                            type_webhook === 'macro' ?
                            <>
                                <Form.Item
                                    label="Domain (введите домен, зарегистрированный в системе macro)"
                                    name="macro_domain"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Поле обязательно для заполнения',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Domain (введите домен, зарегистрированный в системе macro)"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Защищенный ключ (App_secret)"
                                    name="macro_app_secret"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Поле обязательно для заполнения',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Защищенный ключ (App_secret)"
                                    />
                                </Form.Item>
                            </>
                        :
                            <Form.Item
                                label="Url (webhook)"
                                name="url_webhook"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Поле обязательно для заполнения',
                                        type: 'url'
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Url (webhook)"
                                />
                            </Form.Item>                            
                        }
                    </>
                )
            }

        </div>
    )
}

const options = [
    {
        label: 'Bitrix',
        value: 'bitrix',
    },
    {
        label: 'Profitbase',
        value: 'profitbase',
    },
    {
        label: 'Macro',
        value: 'macro',
    },
];

export default BuilderFormItemWebhook;