import { message } from 'antd';
import { checkPoints, checkColumnPoints } from './helpers/checkPoints';

const initialState = {
    coords: [],
    polygon: '',

    //block: select points
    sync_entrance: '',
    sync_id: '',
    request_status_create_block: false,
    modal_form_block: false,
    modal_form_block_type: '',
    block_title: '',
    block_floors_count: '',
    block_max_num_apartments_floor: '',
    edit_block_id: '',

    //flat: form
    realestate_id: '',
    block_id: '',
    request_status_create_flat: false,
    modal_form_flat: false,
    modal_form_flat_type: '',
    modal_form_create_type_flat: '',
    flat_boxes: [],
    floor_info: null,
    floor_select_box: 0,
    request_floor_status: true,
    floor_plan_id: '',

    //flat plan form
    flat_id: '',
    modal_form_flat_plan: false,
    form_flat_preview: null,
    form_flat_plan: null,
    form_flat_floor: '',
    request_form_flat_plan: false,
    flat_number: '',

    //modal form prices
    modal_form_prices: false,
    floor: null,
    request_modal_form_prices: false,

    //files chess
    request_status_upload_file: false,

    select_boxes_of_block: [],
    selected_boxes: 0,
};

const chess = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_CHESS_MODAL_FORM_PRICES': {
            return {
                ...state,
                request_modal_form_prices: action.status,
            };
        }
        case 'CHESS_MODAL_FORM_PRICES': {
            return {
                ...state,
                modal_form_prices: action.status || false,
                floor: action?.floor || null,
                block_id: action?.block_id || '',
            };
        }

        case 'SELECT_BOX_BLOCK': {
            let selectBox = [...state.select_boxes_of_block];
            const checkBox = state.select_boxes_of_block.includes(action.flat);

            if (checkBox) {
                selectBox = selectBox.filter((item) => item != action.flat);
            } else {
                selectBox = [...selectBox, action.flat];
            }

            return {
                ...state,
                select_boxes_of_block: selectBox,
                selected_boxes: selectBox.length || 0,
            };
        }

        case 'REQUEST_FLAT_TEMPLATE_PLAN_FORM': {
            return {
                ...state,
                request_form_flat_plan: action.status,
            };
        }

        case 'UPDATE_FLAT_TEMPLATE_PLAN_FORM_MODAL': {
            return {
                ...state,
                modal_form_flat_plan: action.status || false,
                flat_id: action.flat_id || '',
                form_flat_preview: action.form_flat_preview || null,
                form_flat_plan: action.form_flat_plan || null,
                form_flat_floor: action.flat_floor || '',
                realestate_id: action.realestate_id || '',
                block_id: action.block_id || '',
                coords: action.coords || [],
                polygon: action.polygon || '',
                flat_number: action.flat_number || '',
            };
        }

        case 'UPDATE_FLOOR_PLAN_ID': {
            return {
                ...state,
                floor_plan_id: action.id,
            };
        }

        case 'TRIGGER_EDIT_BLOCK_FORM': {
            return {
                ...state,
                sync_entrance: action.sync_entrance,
                sync_id: action.sync_id,
                modal_form_block: true,
                modal_form_block_type: 'edit',
                block_title: action.title,
                block_floors_count: action.floors_count,
                block_max_num_apartments_floor: action.max_num_apartments_floor,
                coords: action.area_points,
                polygon: action.area_points_string,
                edit_block_id: action.edit_block_id,
            };
        }

        case 'SELECT_MIN_FLOOR_BOX_FORM': {
            return {
                ...state,
                floor_select_box: action.floor,
            };
        }

        case 'UPLOAD_STATUS_FILE_OF_CHESS': {
            return {
                ...state,
                request_status_upload_file: action.status,
            };
        }

        case 'UPDATE_CHESS_FORM_FLOOR_INFO': {
            return {
                ...state,
                floor_info: action.data || null,
            };
        }

        case 'STATUS_REQUEST_FLOOR': {
            return {
                ...state,
                request_floor_status: action.status,
            };
        }

        case 'STATUS_MODAL_FORM_FLAT': {
            return {
                ...state,
                modal_form_flat: action.status,
                modal_form_flat_type: action.modal_type || '',
            };
        }

        case 'ADD_FLATS_FORM_MODAL': {
            let fields = {};
            let flat_boxes = [];

            let getSelectedBoxes = state.select_boxes_of_block;

            if (getSelectedBoxes.length > 0) {
                const cheskCoords = checkColumnPoints(getSelectedBoxes);

                if (!cheskCoords) {
                    message.error('Недопустимый выбор ячеек');

                    return state;
                }
            } else if (getSelectedBoxes.length === 0) {
                message.error('Выберите ячейки');
                return state;
            }

            getSelectedBoxes.forEach((item) =>
                flat_boxes.push([Number(item?.floor), Number(item?.box)])
            );

            fields.modal_form_flat = true;
            fields.modal_form_flat_type = 'create';
            fields.realestate_id = action.realestate_id;
            fields.block_id = action.block_id;
            fields.flat_boxes = flat_boxes;
            fields.modal_form_create_type_flat = 'create_flats';

            return {
                ...state,
                ...fields,
            };
        }

        case 'ADD_PENTHOUSE_FORM_MODAL': {
            let fields = {};
            let flat_boxes = [];

            let getSelectedBoxes = state.select_boxes_of_block;

            if (getSelectedBoxes.length > 3) {
                message.error('Максимально доступное количество ячеек: 3');

                return state;
            } else if (
                getSelectedBoxes.length === 3 ||
                getSelectedBoxes.length === 2
            ) {
                const cheskCoords = checkPoints(getSelectedBoxes);

                if (!cheskCoords) {
                    message.error('Недопустимый выбор ячеек');

                    return state;
                }
            } else if (getSelectedBoxes.length === 0) {
                message.error('Выберите ячейки');
                return state;
            }

            getSelectedBoxes.forEach((item) =>
                flat_boxes.push([Number(item?.floor), Number(item?.box)])
            );

            fields.modal_form_flat = true;
            fields.modal_form_flat_type = 'create';
            fields.realestate_id = action.realestate_id;
            fields.block_id = action.block_id;
            fields.flat_boxes = flat_boxes;
            fields.modal_form_create_type_flat = 'create_penthouse';

            return {
                ...state,
                ...fields,
            };
        }

        case 'CLEAR_SELECT_BLOCK_FORM': {
            return {
                ...state,
                coords: [],
                polygon: '',
                select_boxes_of_block: [],
                selected_boxes: 0,
            };
        }

        case 'STATUS_MODAL_FORM_BLOCK': {
            return {
                ...state,
                modal_form_block: action.status,
                modal_form_block_type: action.modal_type || '',
            };
        }

        case 'STATUS_MODAL_FORM': {
            return {
                ...state,
                modal_form_block: action.status,
                modal_form_block_type: action.modal_type || '',
                modal_form_flat: action.status,
                modal_form_flat_type: action.modal_type || '',
                coords: [],
                polygon: '',

                sync_entrance: '',
                sync_id: '',
                block_title: '',
                block_floors_count: '',
                block_max_num_apartments_floor: '',
                edit_block_id: '',
            };
        }

        case 'REQUEST_STATUS_CREATE': {
            return {
                ...state,
                request_status_create_block: action.status,
                request_status_create_flat: action.status,
            };
        }

        case 'BLOCK_CHESS_UPDATE_SYNC_FORM': {
            let fields = {};

            if (action.field === 'sync_id') {
                fields.sync_id = action.data;
            } else if (action.field === 'sync_entrance') {
                fields.sync_entrance = action.data;
            }

            return {
                ...state,
                ...fields,
            };
        }
        case 'BLOCK_CHESS_UPDATE_COORDS': {
            let points = action.coords;

            let areaPointsString = '';
            points.forEach((point) => {
                areaPointsString =
                    areaPointsString + `${point[0]}% ${point[1]}%,`;
            });

            areaPointsString = areaPointsString.substring(
                0,
                areaPointsString.length - 1
            );

            return {
                ...state,
                polygon: areaPointsString,
                coords: points,
            };
        }
        case 'BLOCK_CHESS_DELETE_COORDS': {
            let points = state.coords.filter((_, idx) => {
                return Number(idx) != Number(action.index);
            });

            let areaPointsString = '';
            points.map((point) => {
                areaPointsString =
                    areaPointsString + `${point[0]}% ${point[1]}%,`;
            });

            areaPointsString = areaPointsString.substring(
                0,
                areaPointsString.length - 1
            );

            return {
                ...state,
                coords: [...points],
                polygon: areaPointsString,
            };
        }

        case 'BLOCK_CHESS_ADD_COORDS': {
            let points = [...state.coords, [action.x, action.y]];

            let areaPointsString = '';
            points.map((point) => {
                areaPointsString =
                    areaPointsString + `${point[0]}% ${point[1]}%,`;
            });

            areaPointsString = areaPointsString.substring(
                0,
                areaPointsString.length - 1
            );

            return {
                ...state,
                polygon: areaPointsString,
                coords: points,
            };
        }

        default:
            return state;
    }
};

export default chess;
