import React, { useState } from 'react';
import { Input } from 'antd';
import styles from '../../styles/index.module.scss';

const { Search } = Input;

const FilterObjects = ({ loading, searchObjects }) => {
    const [text, set_text] = useState('');

    return (
        <div className={styles.filterObjects}>
            <Search
                onChange={(val) => set_text(val.target.value)}
                placeholder="Поиск объектов по наименованию"
                enterButton="Поиск"
                size="large"
                loading={loading}
                onSearch={() => searchObjects(text)}
            />
        </div>
    );
};

export default FilterObjects;
