import axios from '../axios';
import {NotificationManager} from 'react-notifications';

const blocks = {
	addBlock: (block) => async (dispatch) => {

		let {
			title,
			floors_count,
			complex_id,
			area_points
		} = block;

		if(title && floors_count && complex_id && area_points.length > 2) {

			dispatch({
				type: 'REQUEST_BLOCKS_STATUS',
				status: true
			});

			try {

				let {data} = await axios.post('/v1/admin/blocks', block);

				dispatch({
					type: 'UPDATE_BLOCKS_FOR_CREATE_FORM',
					data
				});

				dispatch({
					type: 'UPDATE_APARTMENT_FOR_EDIT',
					data
				});

				dispatch({
					type: 'REQUEST_BLOCKS_STATUS',
					status: false
				});

				return

			} catch(err) {

				dispatch({
					type: 'REQUEST_BLOCKS_STATUS',
					status: false
				});

				NotificationManager.error('Упс, ошибка. Проверьте Ваши права администратора');
				return

			}

		}

		NotificationManager.warning('Заполните все поля');


	},
	addPointForBlock: (point) => async (dispatch) => {

		let {
			title,
			icon
		} = point;

		if(title && icon) {

			dispatch({
				type: 'REQUEST_BLOCKS_STATUS',
				status: true
			});

			try {

				let {data} = await axios.post('/v1/admin/blocks/add-point', point);

				dispatch({
					type: 'UPDATE_BLOCKS_FOR_CREATE_FORM',
					data
				});

				dispatch({
					type: 'UPDATE_APARTMENT_FOR_EDIT',
					data
				});

				dispatch({
					type: 'REQUEST_BLOCKS_STATUS',
					status: false
				});

				NotificationManager.success('Точка успешно добавлена');

				return

			} catch(err) {

				dispatch({
					type: 'REQUEST_BLOCKS_STATUS',
					status: false
				});

				NotificationManager.error('Упс, ошибка. Проверьте Ваши права администратора');
				return

			}

		}

		NotificationManager.warning('Заполните все поля');


	},
	updateInformationBlock: (x, y, id) => async () => {

		try {

			await axios.post('/v1/admin/blocks/update-points', {
				y,
				x,
				id
			});


		} catch(err) {
			NotificationManager.error('Упс, не удалось обновить инофрмацию');

		}

	},
	changeTitle: (title, id) => async () => {

		try {
			await axios.post('/v1/admin/blocks/change-title', {
				title,
				id
			});

			NotificationManager.success('Информация успешно обновлена');


		} catch(err) {
			NotificationManager.error('Упс, не удалось обновить инофрмацию');

		}

	},
	changeFloorCount: (floors, id) => async () => {

		try {
			await axios.post('/v1/admin/blocks/change-floor-count', {
				floors,
				id
			});

			NotificationManager.success('Информация успешно обновлена');


		} catch(err) {
			NotificationManager.error('Упс, не удалось обновить инофрмацию');

		}

	},
	deleteBlock: (id, complex_id) => async dispatch => {

		try {
			let {data} = await axios.post('/v1/admin/blocks/delete', {
				id,
				complex_id
			});

			dispatch({
				type: 'UPDATE_BLOCKS',
				blocks: data
			});

			NotificationManager.success('Блок успешно удален');


		} catch(err) {
			NotificationManager.error('Упс, не удалось обновить инофрмацию');

		}

	},
	deletePoint: (id, complex_id) => async dispatch => {

		try {
			let {data} = await axios.post('/v1/admin/blocks/point-delete', {
				id,
				complex_id
			});

			dispatch({
				type: 'UPDATE_BLOCKS_FOR_CREATE_FORM',
				data
			});

			dispatch({
				type: 'UPDATE_APARTMENT_FOR_EDIT',
				data
			});

			NotificationManager.success('Точка успешно удалена');


		} catch(err) {
			NotificationManager.error('Упс, не удалось обновить инофрмацию');

		}

	},
	changeImageBlock: (id, complex_id, file) => async dispatch => {

		dispatch({
			type: 'REQUEST_UPDATE_BLOCKS_FOR_APARTMENTS',
			status: true
		});

		try {

			let formData = new FormData();
			formData.append('uploadType', 'block-image');
			formData.append('file', file);

			let {data} = await axios({
				url: '/v1/admin/files',
				method: 'post',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			let changeImageData = await axios.post('/v1/admin/blocks/change-image', {
				id,
				file_id: data.id,
				complex_id
			});

			dispatch({
				type: 'UPDATE_BLOCKS',
				blocks: changeImageData.data
			});

			dispatch({
				type: 'REQUEST_UPDATE_BLOCKS_FOR_APARTMENTS',
				status: false
			});

			NotificationManager.success('Информация успешно обновлена');


		} catch(err) {

			dispatch({
				type: 'REQUEST_UPDATE_BLOCKS_FOR_APARTMENTS',
				status: false
			});

			NotificationManager.error('Упс, не удалось обновить информацию');

		}

	},
	getBlockById: (id) => async dispatch => {

		dispatch({
			type: 'REQUEST_FLATS_STATUS',
			status: true
		});

		try {

			let {data} = await axios.get(`/v1/admin/blocks/information/${id}`);

			dispatch({
				type: 'UPDATE_BLOCK_FOR_FLATS',
				data
			});

			return dispatch({
				type: 'REQUEST_FLATS_STATUS',
				status: false
			});

		} catch(err) {

			dispatch({
				type: 'REQUEST_FLATS_STATUS',
				status: false
			});

			NotificationManager.error('Упс, ошибка. Попробуйте позже');


		}

	},
	getFloorByBlock: (id, complexId, floor) => async dispatch => {

		dispatch({
			type: 'REQUEST_FLATS_STATUS',
			status: true
		});

		try {

			let {data} = await axios.get(`/v1/admin/blocks/information/${id}/${complexId}/${floor}`);

			dispatch({
				type: 'UPDATE_BLOCK_FOR_FLATS',
				data
			});

			return dispatch({
				type: 'REQUEST_FLATS_STATUS',
				status: false
			});

		} catch(err) {

			dispatch({
				type: 'REQUEST_FLATS_STATUS',
				status: false
			});

			NotificationManager.error('Упс, ошибка. Попробуйте позже');


		}

	},
	getFloorByBlockNotDispatch: async (id, complexId, floor) => {


		let {data} = await axios.get(`/v1/admin/blocks/information/${id}/${complexId}/${floor}`);
		return data;

	},
	addFloorInfo: (block_id, complex_id, floor, floor_image) => async dispatch => {

		dispatch({
			type: 'REQUEST_FLATS_STATUS',
			status: true
		});

		try {

			let {data} = await axios.post(`/v1/admin/blocks/information/add-floor`, {
				block_id,
				complex_id,
				floor,
				fileId: floor_image.id
			});

			dispatch({
				type: 'UPDATE_BLOCK_FOR_FLATS',
				data
			});

			dispatch({
				type: 'PHOTO_PLAN_FLOOR',
				photo: null
			});

			return dispatch({
				type: 'REQUEST_FLATS_STATUS',
				status: false
			});

		} catch(err) {

			dispatch({
				type: 'REQUEST_FLATS_STATUS',
				status: false
			});

			NotificationManager.error('Упс, ошибка. Попробуйте позже');


		}

	},
}

export default blocks;