import React, {useState} from 'react';
import {api} from '@/Services';
import config from '@/Config';
import {useDispatch, useSelector} from 'react-redux';

const AddBankForm = ({
						 styles,
						 modal,
						 addBank
					 }) => {

	const dispatch = useDispatch();
	const getFiles = useSelector(state => state.files);

	const [title, set_title] = useState('');
	const [url_api, set_url_api] = useState('');

	const uploadLogo = (file) => {
		dispatch(api.files.uploadFile(file, 'IMAGE_PREVIEW'));

	}

	const addBlockEvent = () => {
		if(title && getFiles.PHOTO_PREVIEW) {

			addBank({
				variables: {
					fields: {
						logo: getFiles.PHOTO_PREVIEW ? getFiles.PHOTO_PREVIEW.id : null,
						title,
						url_api
					}
				}
			});

			dispatch({
				type: 'APARTMENT_PREVIEW_PHOTO',
				photo: null
			});

			set_title('');
			set_url_api('');

			return
		}

		alert('Заполните поля');
	}

	return (
		<div className={`${styles.addBankModal} ${modal ? styles.addBankModalOpened : false}`}>
			<div className={styles.formContent}>
				<h3>Форма добавления банка</h3>

				<section>
					<p>Наименование*</p>
					<input placeholder="Наименование*" value={title} onChange={(val) => set_title(val.target.value)}
						   type="text"/>
				</section>

				<section>
					<p>Метод api банка для синхронизации (необязательно)</p>
					<input placeholder="Метод api банка для синхронизации (необязательно)" value={url_api}
						   onChange={(val) => set_url_api(val.target.value)} type="text"/>
				</section>

				<section>
					<p>Логотип банка</p>
					<input onChange={(val) => uploadLogo(val.target.files[0])} type="file"/>

					{
						getFiles.request_upload_status && (
							<h5>Загружаем логотип, подождите...</h5>
						)
					}

					{
						getFiles.PHOTO_PREVIEW && (
							<div>
								<img src={`${config.public}150/150/${getFiles.PHOTO_PREVIEW.url}`}/>
							</div>
						)
					}

				</section>

				<input className={styles.addButtonBank} type="submit" value="Добавить" onClick={addBlockEvent}/>

			</div>
		</div>
	)

}

export default AddBankForm;