"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayerType = exports.LineStyleType = void 0;
/**
 * 线图层 线类型
 */
var LineStyleType;
(function (LineStyleType) {
    LineStyleType[LineStyleType["solid"] = 0] = "solid";
    LineStyleType[LineStyleType["dash"] = 1] = "dash";
})(LineStyleType = exports.LineStyleType || (exports.LineStyleType = {}));
/**
 * L7Plot 内置图层类型
 */
var LayerType;
(function (LayerType) {
    LayerType["TextLayer"] = "textLayer";
    LayerType["DotLayer"] = "dotLayer";
    LayerType["IconLayer"] = "iconLayer";
    LayerType["DotDensity"] = "dotDensityLayer";
    LayerType["ColumnLayer"] = "columnLayer";
    LayerType["HeatmapLayer"] = "heatmapLayer";
    LayerType["GridLayer"] = "gridLayer";
    LayerType["HexbinLayer"] = "hexbinLayer";
    LayerType["LinesLayer"] = "linesLayer";
    LayerType["PathLayer"] = "pathLayer";
    LayerType["ArcLayer"] = "arcLayer";
    LayerType["AreaLayer"] = "areaLayer";
    LayerType["PrismLayer"] = "prismLayer";
})(LayerType = exports.LayerType || (exports.LayerType = {}));
