import React from 'react';
import config from '@/Config';
import styles from '../styles/index.module.scss';
import {DeleteOutlined, EditOutlined, FileImageOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import randomKey from "crypto-random-string";
import {Popconfirm} from "antd";
import {useDispatch} from 'react-redux';
import {api} from '@/Services';

const CategoryItem = ({
	item,
	type
}) => {

	const dispatch = useDispatch();

	const renderItemCategory = (cats, iteration) => {

		iteration += 1;

		return (<>
			{
				cats && cats.length ? cats.map((cat)=>{
					return (
						<>
							<div key={randomKey({length:15})} className={`${styles.itemCategoryMain} ${styles.itemCategoryMainSub}`} style={{
								marginLeft: 30 * iteration
							}}>
								<div className={styles.itemCategoryMainLeft}>
									{
										cat?.icon ?
											<div className={styles.itemCategoryMainLogo} style={{
												background: `url(${config.public}110/110/${cat?.icon?.url}) center / cover no-repeat`
											}}></div>
											:
											<div className={`${styles.itemCategoryMainLogo} ${styles.noIcon}`}><FileImageOutlined /></div>
									}
									<p>{cat?.title}</p>
								</div>
								<div className={styles.itemCategoryMainRight}>
									<Link to={`/library/categories/edit/${cat?._id}?type=${type == 'shop' ? 'shop' : ''}`}><EditOutlined className={styles.cat_button}/></Link>
									<Popconfirm
										title="Категория и все связанные подкатегории будут удалены"
										onConfirm={()=>deleteCategory(cat?._id)}
										onCancel={()=>{}}
										okText="Удалить"
										cancelText="Нет"
										placement="bottomLeft"
									>
										<DeleteOutlined className={styles.cat_button}/>
									</Popconfirm>
								</div>
							</div>
							{
								cat?.child_cats && cat?.child_cats.length ?
									renderItemCategory(cat?.child_cats, iteration)
								: <></>
							}
						</>
					)
				})
				:
					<></>
			}
		</>)
	}

	const deleteCategory = (id) => {
		dispatch(api.library.removeCategory(id, type));
	}

	return (
		<div  className={styles.itemCategory}>
			<div className={styles.itemCategoryMain}>
				<div className={styles.itemCategoryMainLeft}>

					{
						item?.icon ?
							<div className={styles.itemCategoryMainLogo} style={{
								background: `url(${config.public}110/110/${item?.icon?.url}) center / cover no-repeat`
							}}></div>
						:
							<div className={`${styles.itemCategoryMainLogo} ${styles.noIcon}`}><FileImageOutlined /></div>
					}

					<p>{item?.title}</p>
				</div>
				<div className={styles.itemCategoryMainRight}>
					<Link to={`/library/categories/edit/${item?._id}?type=${type == 'shop' ? 'shop' : ''}`}><EditOutlined className={styles.cat_button}/></Link>
					<Popconfirm
						title="Категория и все связанные подкатегории будут удалены"
						onConfirm={()=>deleteCategory(item?._id)}
						onCancel={()=>{}}
						okText="Удалить"
						cancelText="Нет"
						placement="bottomLeft"
					>
						<DeleteOutlined className={styles.cat_button}/>
					</Popconfirm>
				</div>
			</div>

			{renderItemCategory(item?.child_cats, 0)}

		</div>
	)
}

export default CategoryItem;