import React, { useEffect } from 'react';
import { Drawer, Result } from 'antd';
import { useSelector } from 'react-redux';
import { ReSelectOwnerRealestateMWC } from '@/Services/Store/reducers/logs/selectors';
import { LoadingOutlined } from '@ant-design/icons';

const ListRealestateMWC = ({
    dispatch,
    api,
    styles,
    status,
    close
}) => {

    const { sync_owner_logs_request_status, sync_owner_realestate_data } = useSelector(ReSelectOwnerRealestateMWC);

    useEffect(()=>{
        dispatch(api.logs.getRealestateOwnerMWC())
    }, [])

    return (
        <Drawer 
            title="Список жк MWC" 
            placement="right" 
            onClose={close} 
            open={status}
            width={600}
        >
            {
                sync_owner_logs_request_status ?
                    <div className={styles.loader}>
                        <LoadingOutlined size={45} />
                    </div>
                : sync_owner_realestate_data && sync_owner_realestate_data.length ?
                    <div className={styles.wrapperOwnerList}>
                        {
                            sync_owner_realestate_data.map((item)=>(
                                <div 
                                    key={`${item?.NAME}_${item?.ID}`}
                                    className={styles.itemOwnerRealestate}
                                >
                                    <h4>Наименование: { item?.NAME }</h4>
                                    <h3>ID: { item?.ID }</h3>
                                </div>
                            ))
                        }
                    </div>
                :
                <Result
                    status="404"
                    title="Информации не найдено"
                    subTitle="Мы ничего не нашли :("
                    extra={null}
                />
            }
        </Drawer>
    )
}

export default ListRealestateMWC;