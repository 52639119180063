import React, { useEffect, useState } from 'react';
import styles from '../../../styles/index.module.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Result, Modal, Select } from 'antd';

const { Option } = Select;

const SelectBudgetTemplate = ({ id, api, dispatch, budget_template }) => {
    const [budget_template_value, set_budget_template_value] = useState(null);
    const [confirm_modal, set_confirm_modal] = useState(false);
    const [select_template_budget, set_select_template_budget] = useState('');

    useEffect(() => {
        set_budget_template_value(
            budget_template?._id ? budget_template?._id : null
        );
        dispatch(api.budget.getBudgetTemplates());
    }, [budget_template]);

    const budgetState = useSelector((state) => state.budget);

    const saveBudgetTemplate = (val) => {
        set_confirm_modal(true);
        set_select_template_budget(val);
    };

    return (
        <div className={styles.selectBudgetBlock}>
            <Modal
                title="Внимание!"
                visible={confirm_modal}
                okText="Изменить шаблон и сбросить данные"
                cancelText="Не изменять"
                onOk={() => {
                    set_confirm_modal(false);
                    set_budget_template_value(select_template_budget);
                    dispatch(
                        api.budget.saveBudgetTemplate(
                            id,
                            select_template_budget
                        )
                    );
                }}
                onCancel={() => set_confirm_modal(false)}
            >
                <Result
                    status="warning"
                    title="При выборе другого шаблона бюджета вся информация в калькуляторе для этого шаблона квартиры будет сброшена. Вы уверены?"
                    extra={null}
                />
            </Modal>

            <div>
                <h5>Выберите шаблон бюджета</h5>
                <Select
                    size="large"
                    style={{ width: '100%' }}
                    value={budget_template_value}
                    onChange={saveBudgetTemplate}
                >
                    <Option value="">Выберите шаблон бюджета</Option>
                    {budgetState.list && budgetState.list.length ? (
                        budgetState.list.map((item) => (
                            <Option key={item?._id} value={item?._id}>
                                {item.title}
                            </Option>
                        ))
                    ) : (
                        <Option value="">Шаблонов не найдено</Option>
                    )}
                </Select>
            </div>
            {budget_template_value ? (
                <div className={styles.linkCalculate}>
                    <Link to={`/apartments/flats/${id}/edit/calculate`}>
                        Калькулятор
                    </Link>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default SelectBudgetTemplate;
