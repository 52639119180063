import axios from '../axios';
import { NotificationManager } from 'react-notifications';

const Budget = {
    triggerModalListCategories:
        (type, parent_cat_id = '') =>
        (dispatch) => {
            return dispatch({
                type: 'BUDGET_MODAL_LIST_CATEGORIES',
                status: true,
                type_modal: type,
                parent_cat_id,
            });
        },
    removePremiseOrDoor: (cat_id, params, type_render) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_BUDGET',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/admin/budget/remove-premise-or-door',
                {
                    flat_template_id: params.id,
                    cat_id,
                    type_render,
                }
            );

            dispatch({
                type: 'BUDGET_TEMPLATE_UPDATE',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    addPremise:
        (id, cat_id, type, parent_cat_id = null) =>
        async (dispatch) => {
            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: true,
            });

            try {
                let { data } = await axios.post('/v1/admin/budget/add-premise', {
                    id,
                    cat_id,
                    type,
                    parent_cat_id,
                });

                dispatch({
                    type: 'BUDGET_TEMPLATE_UPDATE',
                    data,
                });

                dispatch({
                    type: 'REQUEST_STATUS_BUDGET',
                    status: false,
                });

                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_STATUS_BUDGET',
                    status: false,
                });

                NotificationManager.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    removeSectionFromBudgetRoom:
        (info, cat_id, params, type_section) => async (dispatch) => {
            try {
                await axios.post('/v1/admin/budget/remove-section-budget-room', {
                    info,
                    cat_id,
                    type_section,
                    flat_template_id: params?.id,
                });

                await dispatch(Budget.getBudgetTemplate(params.id));
            } catch (err) {
                NotificationManager.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
                return;
            }
        },
    getBudgetTemplate: (id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_BUDGET',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/budget/template', {
                params: {
                    id,
                },
            });

            dispatch({
                type: 'BUDGET_TEMPLATE_UPDATE',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    saveBudgetCalculateInput:
        (
            id,
            type,
            value,
            type_sub_section,
            furniture_id,
            budget_template_id,
            parent_cat_id,
            params
        ) =>
        async (dispatch) => {
            try {
                await axios.post('/v1/admin/budget/save-budget-calculate-input', {
                    id,
                    type,
                    value,
                    type_sub_section,
                    furniture_id,

                    budget_template_id,
                    parent_cat_id,

                    flat_template_id: params.id,
                });

                await dispatch(Budget.getBudgetTemplate(params.id));

                return NotificationManager.success(
                    'Информация сохранена',
                    'Сообщение',
                    390
                );
            } catch (err) {
                NotificationManager.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    getPremiseBudgetCategories:
        (id, type, parent_cat_id) => async (dispatch) => {
            dispatch({
                type: 'REQUEST_STATUS_PREMISE_STATUS',
                status: true,
            });

            try {
                let { data } = await axios.get(
                    '/v1/admin/budget/get-premise-budget-categories',
                    {
                        params: {
                            id,
                            type,
                            parent_cat_id,
                        },
                    }
                );

                dispatch({
                    type: 'BUDGET_PREMISE_CATEGORIES_LIST',
                    data,
                });

                dispatch({
                    type: 'REQUEST_STATUS_PREMISE_STATUS',
                    status: false,
                });

                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_STATUS_PREMISE_STATUS',
                    status: false,
                });

                NotificationManager.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    saveBudgetTemplate: (id, budget_template_id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_BUDGET',
            status: true,
        });

        try {
            await axios.post('/v1/admin/budget/templates/save', {
                budget_template_id,
                id,
            });

            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });

            return NotificationManager.success('Шаблон успешно сохранен');
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    getBudgetTemplates: () => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_BUDGET',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/budget/templates');

            dispatch({
                type: 'BUDGET_TEMPLATES_LIST',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_BUDGET',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
};

export default Budget;
