import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../styles/index.module.scss';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { api } from '@/Services';
import { Loader } from '@/Components';
import WidgetConnection from '../connection';
import WidgetGroupFormEdit from './form';
import GroupTours from './tours';
import { LoadingOutlined } from '@ant-design/icons';

const WidgetGroupEdit = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const widgetState = useSelector((state) => state.widgets);

    useEffect(()=>{
        dispatch(api.widgets.getById(id));
    }, []);

    const items = [
        {
            key: '1',
            label: `Настройки`,
            children: (
                <WidgetGroupFormEdit
                    id={id}
                    dispatch={dispatch}
                    widgetState={widgetState}
                    widget={widgetState?.widget_edit || null}
                    styles={styles}
                    api={api}
                />
            ),
        },
        {
            key: '2',
            label: `Туры 360`,
            children: <GroupTours 
                api={api} 
                styles={styles}
                dispatch={dispatch}
                widget={widgetState?.widget_edit || null}
                id={id}
            />,
        },
        {
            key: '3',
            label: `Подключение`,
            children: <WidgetConnection styles={styles} widget_id={id}/>,
        },
    ];

    return (
        <content className={`cities-page-block`}>
            <h1>Редактирование виджета 360 - { widgetState?.widget_edit?.title || <LoadingOutlined /> }</h1>

            {
                widgetState?.widget_edit ? 
                    <Tabs defaultActiveKey="1" items={items} />
                : 
                    <Loader/>
            }
            
        </content>
    );
};

export default WidgetGroupEdit;
