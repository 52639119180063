import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ComplexList from './Helpers/complex';
import BlockList from './Helpers/blocks';
import FlatList from './Helpers/flats';

import { api } from '../../../../../Services';
import styles from '../../styles/index.module.scss';

import { Tabs, message } from 'antd';

const { TabPane } = Tabs;

const SyncLogBig = () => {
    let [tab, set_tab] = useState('realestate'); //BLOCK FLAT

    const dispatch = useDispatch();
    const logState = useSelector((state) => state.logs);

    useEffect(() => {
        dispatch({
            type: 'UPDATE_LOGS_FLATS',
            data: null,
        });

        requestData();
    }, []);

    const requestData = () => {
        if (tab === 'realestate') {
            dispatch(api.logs.getComplex());
        } else if (tab == 'BLOCK') {
            //BLOCK
            return;
        } else if (tab == 'FLAT') {
            dispatch({
                type: 'UPDATE_LOGS_FLATS',
                data: null,
            });
        }
    };

    const requestBlocks = (val) => {
        dispatch(api.logs.getBlocks(val));
    };

    const requestFlats = (type, guid) => {
        dispatch(api.logs.getFlats(type, guid));
    };

    const copyId = useCallback((text) => {
        navigator.clipboard.writeText(text);
        message.success(`ID успешно скопирован ${text}`);
    }, []);

    return (
        <div className="statistic-block">
            <div className={styles.list_big_controll_head}>
                <h1>Отладчик/Интеграция/Bi group</h1>
            </div>

            <Tabs
                size="large"
                defaultActiveKey="realestate"
                onChange={(tab) => set_tab(tab)}
            >
                <TabPane tab="Жилые комплексы" key="realestate">
                    <ComplexList
                        loading={logState.request_status}
                        copyId={copyId}
                        list={logState.apart_complex}
                    />
                </TabPane>
                <TabPane tab="Очереди" key="BLOCK">
                    <BlockList
                        loading={logState.request_status}
                        copyId={copyId}
                        requestBlocks={(val) => requestBlocks(val)}
                        list={logState.blocks}
                    />
                </TabPane>
                <TabPane tab="Квартиры" key="FLAT">
                    <FlatList
                        loading={logState.request_status}
                        requestFlats={(type, id) => requestFlats(type, id)}
                        list={logState.flats}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default SyncLogBig;
