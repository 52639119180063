import React, { memo } from 'react';
import { Line } from '@ant-design/plots';
import { GeographicHeatmap } from '@ant-design/maps';

const ChartComp = memo(({ styles, data, title }) => {
    const COLOR_PLATE_10 = [
        '#5B8FF9',
        '#5AD8A6',
        '#5D7092',
        '#F6BD16',
        '#E8684A',
        '#6DC8EC',
        '#9270CA',
        '#FF9D4D',
        '#269A99',
        '#FF99C3',
    ];

    const config = {
        data,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        color: COLOR_PLATE_10,
        point: {
            style: ({ year }) => {
                return {
                    r: Number(year) % 4 ? 0 : 3,
                };
            },
        },
    };

    return (
        <div className={styles.chartMultiLines}>
            <div className={styles.chartMultiLineshead}>
                <h2>{title}</h2>
            </div>
            <Line {...config} />
        </div>
    );
});

export const GeographicHeatMap = memo(
    ({ styles, data, title, connections }) => {
        const config = {
            map: {
                viewMode: '3D',
                type: 'yandex',
                //token: '23',
                zoom: 2.7,
                center: [68.19660949707033, 48.234747338474293],
                pitch: 0,
                style: 'amap://styles/light',
            },
            source: {
                data: data,
                parser: {
                    type: 'geojson',
                },
            },
            size: {
                field: 'count',
                value: [0.145, 1],
            },
            style: {
                intensity: 5,
                radius: 16,
                opacity: 1,
                colorsRamp: [
                    {
                        color: 'rgba(33,102,172,0.0)',
                        position: 0,
                    },
                    {
                        color: 'rgb(103,169,207)',
                        position: 0.2,
                    },
                    {
                        color: 'rgb(209,229,240)',
                        position: 0.4,
                    },
                    {
                        color: 'rgb(253,219,199)',
                        position: 0.6,
                    },
                    {
                        color: 'rgb(239,138,98)',
                        position: 0.8,
                    },
                    {
                        color: 'rgb(178,24,43,1.0)',
                        position: 1,
                    },
                ],
            },
            zoom: {
                position: 'bottomright',
            },
            legend: {
                position: 'topleft',
            },
        };

        return (
            <div className={styles.chartMultiLines} style={{ height: 550 }}>
                <div className={styles.chartMultiLineshead}>
                    <h2>
                        {title} | Общее число пользователей за месяц:{' '}
                        {connections}
                    </h2>
                </div>
                <GeographicHeatmap {...config} />
            </div>
        );
    }
);

export default ChartComp;
