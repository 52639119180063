"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_OPTIONS = void 0;
var plot_1 = require("../../core/plot");
var utils_1 = require("../../utils");
/**
 * 默认配置项
 */
exports.DEFAULT_OPTIONS = (0, utils_1.deepAssign)({}, plot_1.Plot.DefaultOptions, {
    source: {
        data: [],
        parser: {
            type: 'json',
            x: 'x',
            y: 'y',
        },
        aggregation: {
            type: 'grid',
            radius: 15000,
            method: 'sum',
        },
    },
});
