import axios from '../axios';
import { NotificationManager } from 'react-notifications';

const pages = {
    get: () => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_PAGES',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/pages');

            dispatch({
                type: 'UPDATE_PAGES',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_PAGES',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_PAGES',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    getById: (id) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_PAGE_BY_ID',
            data: null,
        });

        dispatch({
            type: 'REQUEST_STATUS_PAGES',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/pages/get-by-id/${id}`);

            dispatch({
                type: 'UPDATE_PAGE_BY_ID',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_PAGES',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_PAGES',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    create: (fields, history) => async () => {
        let { title, metaTitle, metaDesc, metaKeyWords, content } = fields;

        if (
            title &&
            metaTitle &&
            metaDesc &&
            metaDesc &&
            metaKeyWords &&
            content
        ) {
            try {
                let { data } = await axios.post('/v1/admin/pages/create', fields);

                NotificationManager.success(data);
                return history('/pages');
            } catch (err) {
                NotificationManager.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
                return;
            }
        }

        NotificationManager.error('Заполните поля');
    },
    update: (fields, history, id) => async () => {
        let { title, metaTitle, metaDesc, metaKeyWords, content } = fields;

        fields.id = id;

        if (
            title &&
            metaTitle &&
            metaDesc &&
            metaDesc &&
            metaKeyWords &&
            content
        ) {
            try {
                let { data } = await axios.post('/v1/admin/pages/update', fields);

                NotificationManager.success(data);
                return history('/pages');
            } catch (err) {
                NotificationManager.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
                return;
            }
        }

        NotificationManager.error('Заполните поля');
    },
    delete: (id) => async (dispatch) => {
        try {
            let resRemove = window.confirm('Страница будет удалена');

            if (resRemove) {
                let { data } = await axios.post('/v1/admin/pages/remove', {
                    id,
                });

                NotificationManager.success(data);
                return dispatch(pages.get());
            }
        } catch (err) {
            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    saveSettingSeo:
        (seoTitle, seoKeywords, seoDescription, type) => async () => {
            if (seoTitle && seoKeywords && seoDescription) {
                try {
                    let { data } = await axios.post(
                        '/v1/admin/pages/update-seo-settings',
                        {
                            title: seoTitle,
                            keywords: seoKeywords,
                            description: seoDescription,
                            type,
                        }
                    );

                    NotificationManager.success(data);
                    return;
                } catch (err) {
                    NotificationManager.error(
                        'Упс, ошибка. Проверьте Ваши права администратора'
                    );
                    return;
                }
            }

            return NotificationManager.error('Заполните все поля', 'Ошибка');
        },
    getSettings: () => async (dispatch) => {
        try {
            let { data } = await axios.get('/v1/admin/pages/settings');

            dispatch({
                type: 'UPDATE_PAGES_SETTING',
                data,
            });
        } catch (err) {
            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    getSettingsSeo: (type) => async () => {
        try {
            let { data } = await axios.post('/v1/admin/pages/settings-seo', {
                type,
            });

            return data;
        } catch (err) {
            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
            return null;
        }
    },
};

export default pages;
