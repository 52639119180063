import React from 'react';
import { Drawer, Tabs, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import UserInfo from './info';
import UserAvatar from './avatar';
import UserAccesses from './accesses';

const UserTools = () => {
    const dispatch = useDispatch();
    const { edit_user_drawer, edit_user } = useSelector(state => state?.users);

    const closeDrawer = () => {

        dispatch({
            type: 'DRAWER_USER_TOOLS_INFO',
            status: false
        });

        return dispatch({
            type: 'DATA_USER_TOOLS_INFO'
        });
    }

    const items = [
        {
            key: '1',
            label: `Информация`,
            children: <UserInfo/>,
        },
        {
            key: '2',
            label: `Аватар`,
            children: <UserAvatar/>,
        },
        {
            key: '3',
            children: <UserAccesses/>,
            disabled: edit_user?.user?.mail_confirmed ? false : true,
            label: (
                <Tooltip
                    placement='bottom'
                    title={
                        !edit_user?.user?.mail_confirmed &&
                        'Управления доступами для этого аккаунт будет доступно после подтверждения почты'
                    }
                >Доступы</Tooltip>
            ),
        },
    ];

    return (
        <Drawer 
            title="Настройки пользователя" 
            placement="right"
            open={edit_user_drawer}
            width='65%'
            onClose={closeDrawer}
            destroyOnClose={true}
        >
            <Tabs defaultActiveKey="1" items={items} />
        </Drawer>
    )

}

export default UserTools;