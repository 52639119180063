import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import Switch from 'react-input-switch';

const FreeDownload = ({
  api
}) => {

  const [free_download, set_free_download] = useState(0);

  const dispatch = useDispatch();

  const getSettings = useSelector(state => state.statistic.settings);

  useEffect(()=>{

    if(getSettings && getSettings.length){
      getSettings.map((item)=>{
        if(item.type == 'FREE_DOWNLOAD'){
          set_free_download(item.free_download ? 1 : 0);
        }
      })
    }

  }, [getSettings]);

  const saveSetting = (value) => {

    return dispatch(api.statistic.saveSettingFreeDownload(value));
    
  }

  return (
    <div className="section-settings-block">
      <div className="section-download-settings-block-head">
        <h3>Свободное скачивание файлов</h3>
        <Switch onChange={(val)=>{
          set_free_download(val);
          saveSetting(val);
          
        }} value={free_download} />
      </div>
    </div>
  )

}

export default FreeDownload;