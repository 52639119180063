import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import styles from '../../styles/index.module.scss';
import { Select, Button } from 'antd';
import {
    CheckOutlined,
    CloseSquareOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import ListRealestate from '../Helpers/realestate-flatris';

const { Option } = Select;

const SyncLogBitrix = () => {
    const [select_realestate, set_select_realestate] = useState('');
    const [list_realestate, set_list_realestate] = useState(true);

    const dispatch = useDispatch();
    const logState = useSelector((state) => state.logs);

    useEffect(() => {
        dispatch({
            type: 'UPDATE_LOGS_FLATS',
            data: null,
        });

        dispatch(api.logs.getRealEstateBitrix());
    }, []);

    const selectRealEstate = (value) => {
        set_select_realestate(value);
        dispatch(api.logs.getFlatsBitrix(value));
    };

    const syncRealEstate = () => {
        if (select_realestate) {
            dispatch(api.logs.syncRealEstateBitrix(select_realestate));
        }
    };

    const syncFlatsFilter = (status, flats) => {
        let flatsData = [];

        if (typeof flats == 'object' && flats.length) {
            flatsData =
                status && flats && flats.length
                    ? flats.filter((flat) => flat?.sync_with_logovo)
                    : flats.filter((flat) => !flat?.sync_with_logovo);
        }

        return flatsData.length;
    };

    return (
        <div className="statistic-block">
            <h1>Отладчик/Интеграция/Битрикс</h1>

            <div className={styles.selectRealEstateBlock}>
                <h5>Выберите жилой комплекс</h5>
                <div className={styles.flexRowForm}>
                    {logState?.request_status ? (
                        <LoadingOutlined />
                    ) : (
                        <Select
                            size="large"
                            className={styles.selectInput}
                            defaultValue={select_realestate}
                            onChange={selectRealEstate}
                        >
                            <Option value="">Выберите жилой комплекс</Option>
                            {logState?.apart_complex &&
                            logState?.apart_complex.length ? (
                                logState?.apart_complex.map((item) => (
                                    <Option key={item?._id} value={item?._id}>
                                        {item?.title}
                                    </Option>
                                ))
                            ) : (
                                <Option value="">Информации не найдено</Option>
                            )}
                        </Select>
                    )}

                    {select_realestate && (
                        <Button
                            size="large"
                            type="primary"
                            onClick={() =>
                                set_list_realestate(!list_realestate)
                            }
                            className={styles.buttonRealestate}
                        >
                            Список жк
                        </Button>
                    )}
                </div>
            </div>

            <div className={styles.contentBlock}>
                <ListRealestate
                    close={() => set_list_realestate(!list_realestate)}
                    logState={logState}
                    select_realestate={select_realestate}
                    dispatch={dispatch}
                    api={api}
                    status_hide={list_realestate}
                />

                {logState?.sync_request_status ? (
                    <div className={styles.defaultBlock}>
                        <LoadingOutlined
                            className={styles.defaultBlockLoadIcon}
                        />
                    </div>
                ) : typeof logState?.flats === 'string' ? (
                    <h5>{logState?.flats}</h5>
                ) : typeof logState?.flats === 'object' &&
                  logState?.flats &&
                  logState?.flats.length ? (
                    <div className={styles.parentItemsBlock}>
                        <div className={styles.parentItemsHeadBlock}>
                            <div className={styles.parentItemsHeadLeftBlock}>
                                <section>
                                    Всего квартир: {logState?.flats.length || 0}
                                </section>
                                <section>
                                    Синхронизировано:{' '}
                                    {syncFlatsFilter(
                                        true,
                                        logState?.flats || []
                                    )}
                                </section>
                                <section>
                                    Не прошли синхронизацию:{' '}
                                    {syncFlatsFilter(false, logState?.flats)}
                                </section>
                            </div>
                            <div className={styles.buttonsRight}>
                                <Button type="primary" onClick={syncRealEstate}>
                                    {logState?.sync_realestate_bitrix_status
                                        ? 'Подождите...'
                                        : 'Синхронизировать'}{' '}
                                    {logState?.sync_realestate_bitrix_status ? (
                                        <LoadingOutlined
                                            className={
                                                styles.parentItemsHeadBlockLoadIcon
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Button>
                            </div>
                        </div>
                        <div className={styles.itemsBlock}>
                            {logState?.flats.map((item) => (
                                <div
                                    className={styles.itemBlock}
                                    key={item?.id}
                                >
                                    <h4>
                                        <b>Номер:</b> {item?.number}
                                    </h4>
                                    <h4>
                                        <b>Этаж:</b> {item?.floor}
                                    </h4>
                                    <h4>
                                        <b>Статус:</b> {item?.status}
                                    </h4>
                                    <h4>
                                        <b>ЖК:</b>{' '}
                                        {item?.gproject || item?.houseName}
                                    </h4>
                                    <h4>
                                        <b>м2:</b>{' '}
                                        {item?.square_total ||
                                            item?.area?.area_total}
                                    </h4>
                                    <h4>
                                        <b>Секция:</b>{' '}
                                        {item?.section || item?.sectionName}
                                    </h4>
                                    <h4>
                                        <b>Блок:</b> {item?.building || '-'}
                                    </h4>
                                    <h4>
                                        <b>Синх. статус:</b>{' '}
                                        {item?.sync_with_logovo ? (
                                            <CheckOutlined
                                                className={
                                                    styles.LikeOutlinedIcon
                                                }
                                            />
                                        ) : (
                                            <CloseSquareOutlined
                                                className={
                                                    styles.DislikeOutlinedIcon
                                                }
                                            />
                                        )}
                                    </h4>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className={styles.defaultBlock}>
                        <h4>Квартир не найдено</h4>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SyncLogBitrix;
