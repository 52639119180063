import { produce } from 'immer';

const initialState = {
    user: null,

    sider_collapsed: false,
    hne: false,

    hide_status_recent_events: true,
    data_recent_events: []
};

const user = (state = initialState, action) => {
    switch (action.type) {

        case 'ADD_SINGLE_RECENT_EVENT':

            return produce(state, draftState => {

                const data = state?.data_recent_events || [];
                draftState.data_recent_events = [action?.data, ...data];
                
            });

        case 'UPDATE_DATA_RECENT_EVENTS':

            return produce(state, draftState => {
                draftState.data_recent_events = action.data || [];
            });

        case 'CHANGE_STATUS_RECENT_EVENTS':

            return produce(state, draftState => {
                draftState.hide_status_recent_events = !state?.hide_status_recent_events;
            });

        case 'UPDATE_HNE':
            return {
                ...state,
                hne: action.status,
            };

        case 'SIDER_COLLAPSED_STATUS':
            return {
                ...state,
                sider_collapsed: action.status,
            };

        case 'UPDATE_USER':
            return {
                ...state,
                user: action.user,
            };

        default:
            return state;
    }
};

export default user;
