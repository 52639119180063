"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GridLayer = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var l7_layers_1 = require("@antv/l7-layers");
var plot_layer_1 = require("../../core/layer/plot-layer");
var adaptor_1 = require("./adaptor");
var DEFAULT_OPTIONS = {
    source: {
        data: [],
        parser: {
            type: 'json',
            x: 'x',
            y: 'y',
        },
        aggregation: {
            type: 'grid',
            field: '',
            radius: 15000,
            method: 'sum',
        },
    },
    shape: 'square',
    color: '#5FD3A6',
    style: {
        coverage: 0.9,
        angle: 0,
        opacity: 1.0,
    },
};
var LAYER_OPTIONS_KEYS = ['shape', 'color', 'size', 'style', 'state'];
var GridLayer = /** @class */ (function (_super) {
    tslib_1.__extends(GridLayer, _super);
    function GridLayer(options) {
        var _this = _super.call(this, options) || this;
        /**
         * 图层类型
         */
        _this.type = plot_layer_1.PlotLayer.LayerType.GridLayer;
        /**
         * 图层是否具有交互属性
         */
        _this.interaction = false;
        var _a = _this.options, name = _a.name, source = _a.source;
        var config = _this.pickLayerConfig(_this.options);
        _this.name = name ? name : (0, util_1.uniqueId)(_this.type);
        _this.layer = new l7_layers_1.HeatmapLayer(tslib_1.__assign(tslib_1.__assign({}, config), { name: _this.name }));
        _this.mappingLayer(_this.layer, _this.options);
        _this.setSource(source);
        return _this;
    }
    /**
     * 获取默认配置
     */
    GridLayer.prototype.getDefaultOptions = function () {
        return DEFAULT_OPTIONS;
    };
    GridLayer.prototype.mappingLayer = function (layer, options) {
        (0, adaptor_1.mappingLayer)(layer, options);
    };
    GridLayer.prototype.update = function (options) {
        _super.prototype.update.call(this, options);
        this.mappingLayer(this.layer, this.options);
    };
    /**
     * 默认配置项
     */
    GridLayer.DefaultOptions = DEFAULT_OPTIONS;
    /**
     * 图层配置项 Keys
     */
    GridLayer.LayerOptionsKeys = plot_layer_1.PlotLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return GridLayer;
}(plot_layer_1.PlotLayer));
exports.GridLayer = GridLayer;
