import currencyFormatter from "currency-formatter";
import styles from '@/Containers/Admin/Budget/styles/index.module.scss';
import React from "react";

const ProductItem = ({
	product,
	listSubProducts,
	main_parent_product_id,
	sub
	}) => {

	const procentRes = (price) => {

		return currencyFormatter.format((price), {code: 'EUR', symbol: ''}) + '₸';
	}

	const renderAreaAndQuantity = () => {

		if(product?.marker){
			return `${product?.marker?.area || product?.area}`
		}

	}

	const renderItem = (product) => {
		return (
			<tr className={styles.trBody}
				key={product._id + `_${Math.random() * 200000}`}>
				<td>{product.id_product.title}</td>
				<td><b>{product?.marker?.title}</b>. {product.id_product.discount_description ? product.id_product.discount_description : '-'}</td>
				<td>{ Number(renderAreaAndQuantity()).toFixed(2) }</td>
				<td>{product.unit ? product.unit : '-'}</td>
				<td>{product.id_product.price ? currencyFormatter.format(product.id_product.price, {
					code: 'EUR',
					symbol: ''
				}) + '₸' : '-'}</td>
				<td>{product.id_product.discount ? product.id_product.discount + '%' : '-'}</td>
				<td>{product?.price_with_discount ? procentRes(Number(product?.price_with_discount)) : '-'}</td>
				<td>Название магазина, телефон, адрес, фото товара</td>
				{
					!sub ?
						product.sub_products && product.sub_products.length ?
							<td>
								<span onClick={(event)=>{
									event.stopPropagation();
									main_parent_product_id(product._id)
									listSubProducts(product.sub_products)
								}}>Подтовары</span>
							</td>
						:
						<></>
					:
						<></>
				}
			</tr>
		)
	}

	return renderItem(product)
}

export default ProductItem;