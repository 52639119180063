export const minFloor = (boxes) => {
    let floor = 0;

    let floorsData = [];

    if (boxes && boxes.length) {
        boxes.forEach((box) => {
            floorsData.push(Number(box[0]));
        });
    }

    floor = Math.min(...floorsData);

    if (floor === Infinity) {
        floor = 0;
    }

    return floor;
};
