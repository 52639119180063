import { useRoutes } from 'react-router-dom';
import routes from '../../Common/routes';
import { useQuery } from '@apollo/client';
import { users } from '@/Services/apollo/gqls';
import Sidebar from './Helpers/sidebar';
import { useDispatch } from 'react-redux';

const Routing = () => {
    const dispatch = useDispatch();
    const { data, loading } = useQuery(users.getUser); 

    dispatch({
        type: 'UPDATE_USER',
        user: data?.getUser,
    });

    const routing = useRoutes(routes(loading, data?.getUser || null, Sidebar));

    return routing;
};

export default Routing;
