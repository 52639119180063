import React, { useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { api } from '../../../../Services/';

import EditPageForm from './Helpers/form';

const EditPage = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const getBuilder = useSelector((state) => state.builders);
    const getPages = useSelector((state) => state.pages);

    useEffect(() => {
        dispatch(api.pages.getById(id));
        dispatch(api.builders.getBuilders());
    }, []);

    return useMemo(
        () => (
            <content className="page-editor-block">
                <h1 className="title-page">Редактирование страницы</h1>

                {getPages.request_status ? (
                    <h4>Загружаем информацию</h4>
                ) : (
                    <EditPageForm
                        api={api}
                        id={id}
                        builders={getBuilder.builders}
                        history={history}
                        dispatch={dispatch}
                        item={getPages.page_by_id}
                    />
                )}
            </content>
        ),
        [getBuilder, getPages]
    );
};

export default EditPage;
