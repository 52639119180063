import React, { memo, useId } from 'react';
import { Input, Tag, Tooltip } from 'antd';
import styles from '../../../styles/index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const BlockList = ({ list, requestBlocks, copyId, loading }) => {
    const id = useId();

    return (
        <div className={styles.log_list_apart_complex}>
            <div className={styles.formBlock}>
                <Input
                    size="large"
                    placeholder="Введите guid жилого комплекса"
                    onKeyDown={(val) => {
                        if (val.key === 'Enter') {
                            return requestBlocks(
                                val.target.value.replace(' ', '')
                            );
                        }
                    }}
                    className={styles.log_list_apart_complex_input}
                />
            </div>

            {loading ? (
                <div className={styles.loadingBlock}>
                    <LoadingOutlined />
                </div>
            ) : list && list.length ? (
                <div className={styles.itemsRelaestateBlock}>
                    {list.map((item) => {
                        return (
                            <section
                                className={styles.item_apart_complex}
                                key={`${id}-${item?.realEstateId}`}
                            >
                                <p>{item.blockName}</p>
                                <span>
                                    ID:{' '}
                                    <Tooltip title="Кликните, чтобы скопировать id">
                                        <Tag
                                            onClick={() =>
                                                copyId(item?.blockId)
                                            }
                                        >
                                            {item?.blockId}
                                        </Tag>
                                    </Tooltip>
                                </span>
                            </section>
                        );
                    })}
                </div>
            ) : (
                <h4>Информации не найдено</h4>
            )}
        </div>
    );
};

export default memo(BlockList);
