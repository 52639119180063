import React, {useEffect, useState} from 'react';

const SettingItem = ({
						 dispatch,
						 api,
						 type,
						 title
					 }) => {

	const [seoTitle, setSeoTitle] = useState('');
	const [seoKeywords, setSeoKeywords] = useState('');
	const [seoDescription, setSeoDescription] = useState('');

	useEffect(() => {

		getSettingsSEO();

	}, [])

	const getSettingsSEO = async () => {

		let data = await dispatch(api.pages.getSettingsSeo(type));

		setSeoTitle(data && data.page_seo ? data.page_seo.title : '')
		setSeoKeywords(data && data.page_seo ? data.page_seo.keywords : '');
		setSeoDescription(data && data.page_seo ? data.page_seo.description : '');

	}

	const saveSeoHomePage = () => {
		dispatch(api.pages.saveSettingSeo(seoTitle, seoKeywords, seoDescription, type));


	}

	return (
		<section>
			<div className="modal-setting-for-pages-content-body-section-head">
				<h4>{title}</h4>
			</div>
			<div className="modal-setting-for-pages-content-body-section-inputs">

				<p>Title</p>
				<input value={seoTitle} onChange={(val) => setSeoTitle(val.target.value)} type="text"/>

				<p>Keywords</p>
				<input value={seoKeywords} onChange={(val) => setSeoKeywords(val.target.value)} type="text"/>

				<p>Description</p>
				<input value={seoDescription} onChange={(val) => setSeoDescription(val.target.value)} type="text"/>

				<div className="modal-setting-for-pages-content-body-section-input-save">
					<input onClick={() => saveSeoHomePage()} value="Сохранить" type="submit"/>
				</div>

			</div>
		</section>
	)
}

export default SettingItem;