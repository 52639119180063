import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {api} from '@/Services';
import {EditOutlined, LoadingOutlined} from "@ant-design/icons";
import styles from '../styles/index.module.scss';
import {Button, Input, Modal} from "antd";

const Countries = () => {

	const [is_modal, set_is_modal] = useState(false);
	const [title, set_title] = useState('');
	const [seo_title, set_seo_title] = React.useState('');
	const [seo_keywords, set_seo_keywords] = React.useState('');
	const [seo_description, set_seo_description] = React.useState('');
	const [id, set_id] = React.useState('');
	const [edit_status, set_edit_status] = React.useState(false);

	const dispatch = useDispatch();
	const getState = useSelector(state => state.cities);

	React.useEffect(() => {

		dispatch(api.cities.getCountries());

	}, []);

	const create = () => {

		clearState();

		return dispatch(api.cities.addCountry({
			title,
			seo_title,
			seo_keywords,
			seo_description
		}));
	}

	const edit = () => {
		clearState();
		return dispatch(api.cities.changeCountry(
			title,
			id,
			seo_title,
			seo_keywords,
			seo_description
		));
	}

	const clearState = () => {
		set_title('');
		set_seo_title('');
		set_seo_keywords('');
		set_seo_description('');
		set_id('');
		set_is_modal(false)
		set_edit_status(false);
	}

	return (
		<div className="cities-page-block">
			<Modal
				width={'75%'}
				title={edit_status ? "Форма редактирования страны" : "Форма добавления страны"}
				visible={is_modal}
				onOk={edit_status ? edit : create}
				onCancel={clearState}
			>
				<Input value={title} onChange={(value)=>set_title(value.target.value)} placeholder="Название страны" />

				<div className={styles.optionBlock}>
					<h6>SEO</h6>
					<Input value={seo_keywords} onChange={(value)=>set_seo_keywords(value.target.value)} placeholder="Keywords" />
					<Input value={seo_description} onChange={(value)=>set_seo_description(value.target.value)} placeholder="Description" />
					<Input value={seo_title} onChange={(value)=>set_seo_title(value.target.value)} placeholder="Title" />
				</div>

			</Modal>

			<p>Список стран для магазина logovo.kz</p>

			<Button type="primary" onClick={()=>{
				set_is_modal(true);
			}}>Добавить</Button>

			{
				getState.request_country_status ?
					<LoadingOutlined className={styles.spinLoader} spin />
					: getState.countries ?
						getState.countries.map((item) => {
							return (
								<div className={styles.itemCity} key={item?._id}>
									<h4>{item?.title}</h4>

									<div className={styles.itemCityRight}>
										<EditOutlined onClick={()=>{

											set_is_modal(true);
											set_title(item?.title);
											set_seo_title(item?.seo_title);
											set_seo_keywords(item?.seo_keywords);
											set_seo_description(item?.seo_description);
											set_edit_status(true);
											set_id(item?._id);

										}} className={styles.itemButton} />
									</div>
								</div>
							)
						})
						:
						<h2>Информации нет</h2>
			}
		</div>
	)
}

export default Countries;