import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const PremiseMarkersListWindow = ({
    styles,
    api,
    params,
    type,
    getPremise,
    //parent_cat_id
}) => {
    const dispatch = useDispatch();
    const stateBudget = useSelector((state) => state.budget);

    useEffect(() => {
        dispatch(
            api.budget.getPremiseBudgetCategories(
                params.id,
                type,
                stateBudget.budget_modal_parent_category
            )
        );
    }, []);

    return (
        <div className={styles.premiseListCategories}>
            {stateBudget.request_premise_budget_status ? (
                <h6>Загружаем...</h6>
            ) : stateBudget.list_premise_budget_categories &&
              stateBudget.list_premise_budget_categories.length ? (
                stateBudget.list_premise_budget_categories.map((item) => {
                    return (
                        <h6 key={item._id} onClick={() => getPremise(item)}>
                            {item?.parent_category
                                ? item?.parent_category?.title + '/'
                                : ''}
                            {item.title}
                        </h6>
                    );
                })
            ) : (
                <h6>Ничего не найдено</h6>
            )}
        </div>
    );
};

export default PremiseMarkersListWindow;
