import React, { useEffect, useState } from 'react';
import styles from '../../styles/index.module.scss';
import { Modal, Input, Select } from 'antd';
import keyRandom from 'crypto-random-string';

const { Option } = Select;

const FormObject = ({
    isModalVisible = false,
    closeModal,
    realestate,
    dispatch,
    api,
    status_object,
    type_modal,
    edit_object = null,
}) => {
    const [title, set_title] = useState('');
    const [select_realestate, set_select_realestate] = useState([]);

    const eventObjectForm = () => {
        if (type_modal == 'create') {
            dispatch(api.apartments.createObject(title, select_realestate));
        } else {
            dispatch(
                api.apartments.editObject(
                    title,
                    select_realestate,
                    edit_object?._id
                )
            );
        }
    };

    useEffect(() => {
        if (edit_object) {
            set_title(edit_object?.title);
            set_select_realestate(edit_object?.realestate);
        }
    }, [edit_object]);

    useEffect(() => {
        if (status_object == 'CREATED' || status_object == 'EDIT') {
            clearState();
        }
    }, [status_object]);

    const clearState = () => {
        closeModal();

        set_title('');
        set_select_realestate([]);
    };

    return (
        <Modal
            width="65%"
            title={
                type_modal == 'create'
                    ? 'Добавление нового объекта'
                    : 'Редактирование объекта'
            }
            visible={isModalVisible}
            onOk={eventObjectForm}
            okText={type_modal == 'create' ? 'Добавить' : 'Редактировать'}
            onCancel={clearState}
            zIndex={99999999}
        >
            <Input
                size="large"
                value={title}
                placeholder="Наименование объекта"
                onChange={(val) => set_title(val.target.value)}
            />

            <div className={styles.blockRealestateList}>
                <p>Добавьте жк</p>

                <div className={styles.realestateList}>
                    {realestate && realestate.length ? (
                        <Select
                            onChange={(val) => set_select_realestate(val)}
                            size="large"
                            value={select_realestate}
                            placeholder="Выберите жк"
                            mode="multiple"
                            className={styles.selectStyle}
                            dropdownStyle={{
                                zIndex: 9999999999,
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                        optionB.children.toLowerCase()
                                    )
                            }
                        >
                            {realestate.map((item) => (
                                <Option
                                    value={item?._id}
                                    key={keyRandom({ length: 15 })}
                                >
                                    {item?.title}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <span>Информации не найдено</span>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default FormObject;
