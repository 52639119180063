import React, { useEffect, useState } from 'react';
import styles from '../styles/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import { Tabs } from 'antd';
import Categories360 from './360';
import ShopCategories from './shopCategories';
import { useNavigate, useLocation } from 'react-router-dom';

const { TabPane } = Tabs;

const CategoriesLibrary = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const typeForm = query.get('type'); //shop

    const history = useNavigate();
    const dispatch = useDispatch();
    const stateLibrary = useSelector((state) => state.library);

    let [tab_active, set_tab_active] = useState('360');

    useEffect(() => {
        tab_active = typeForm == 'shop' ? 'shop_categories' : '360';
        set_tab_active(tab_active);

        dispatch(api.library.categories());
    }, []);

    return (
        <div className={styles.mainBlock}>
            <div className={styles.header}>
                <h2 className="title-page">категории</h2>
            </div>

            <div className={styles.pageCategories}>
                <Tabs
                    size="large"
                    type="line"
                    activeKey={tab_active}
                    defaultActiveKey={tab_active}
                    onTabClick={(key) => {
                        set_tab_active(key);

                        const params = new URLSearchParams({
                            type: key == 'shop_categories' ? 'shop' : '',
                        });
                        history(
                            `/library/categories?search=${params.toString()}`
                        );
                    }}
                >
                    <TabPane tab="360" key="360">
                        <Categories360
                            loading={stateLibrary?.request}
                            dispatch={dispatch}
                            categories={stateLibrary?.categories}
                            api={api}
                        />
                    </TabPane>
                    <TabPane tab="Категории для магазина" key="shop_categories">
                        <ShopCategories
                            loading={stateLibrary?.request}
                            dispatch={dispatch}
                            categories={stateLibrary?.shop_categories}
                            api={api}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default CategoriesLibrary;
