import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import styles from '../styles/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import { VList } from 'virtuallist-antd';
import { Table, Button, Input, Typography } from 'antd';
import realestateTableColumns from './helpers/table/realestateTableColumns';
import { LoadingOutlined } from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

const { Title } = Typography;

const SortRealestate = () => {

    const dispatch = useDispatch();
    const {
        realestate_list_sort,
        request_status_realestate,
        cities_list
    } = useSelector(state => state?.realestate);

    const [data, set_data] = useState([]);
    const [search_text, set_search_text] = useState('');

    useEffect(()=>{
        set_data(realestate_list_sort);
    }, [realestate_list_sort]);

    useEffect(()=>{
        dispatch(api.realestate.getAllRealestate());
    }, []);

    const components = useMemo(() => {
        return {
            ...vlistComponent,
            body: {
                ...vlistComponent.body,
                row: DragableBodyRow
            }
        };
    }, []);

    const customSort = (active_id, over_index) => {
        if(over_index >= 0){
            const getOver = data.filter((item, index) => index === over_index);

            set_data((prev) => {

                const activeIndex = prev.findIndex((i) => i._id === active_id);
                const overIndex = prev.findIndex((i) => i._id === getOver[0]?._id);
                const dragRow = prev[activeIndex];

                return update(prev, {
                    $splice: [
                    [activeIndex, 1],
                    [overIndex, 0, dragRow]
                    ]
                })

            });
            return;
        }
        return;
    }

    const saveSortNumbers = () => {
        dispatch(api.realestate.saveSortNumbers(data));
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];
            set_data(
                update(data, {
                    $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow]
                    ]
                })
            );
        },
        [data]
    );

    return (
        <div className={`page-apartment ${styles.pageContent}`}>
            <div className={styles.pageHeader}>
                <Title level={4}>Сортировка жк</Title>
            </div>
            <div>

                <Input
                    placeholder="Введите наименование жк"
                    onChange={(value)=>set_search_text(value.target.value)}
                    size='large'
                />

                <DndProvider backend={HTML5Backend}>
                    <Table
                        rowClassName={styles.tableRow}
                        dataSource={data}
                        columns={realestateTableColumns(customSort, cities_list, search_text)}
                        scroll={{ 
                            y: 'calc(100vh - 300px)'
                        }}
                        components={components}
                        rowKey="_id"
                        pagination={false}
                        onRow={(record, index) => ({
                            index,
                            moveRow
                        })}
                        loading={request_status_realestate}
                        onChange={(pagination, filters) => {
                            if(filters['3']){
                                const filterData = realestate_list_sort.filter(value => filters['3'].includes(value?.city?.title));
                                set_data(filterData);
                            }else{
                                set_data(realestate_list_sort);
                            }
                        }}
                    />
                </DndProvider>

                <Button 
                    type='primary'
                    size='large'
                    className={styles.buttonSaveSortNumber}
                    onClick={saveSortNumbers}
                    disabled={request_status_realestate}
                >
                    { request_status_realestate ? <LoadingOutlined/> : 'Обновить сортировочные номера' }
                </Button>
            </div>
        </div>
    )

}

const vlistComponent = VList({
    height: 'calc(100vh - 300px)',
    resetTopWhenDataChange: false,
});

const DragableBodyRow = (props) => {
    const { index, moveRow, className, style, ...restProps } = props;
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: 'DragableBodyRow',
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName:
                dragIndex < index ? " drop-over-downward" : " drop-over-upward"
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        }
    });
    const [, drag] = useDrag({
        type: 'DragableBodyRow',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    useEffect(() => {
        drop(drag(ref));
    }, [drag, drop]);

    const components = useMemo(() => vlistComponent.body.row, []);

    const tempProps = useMemo(() => {
        return {
            ref: ref,
            className: `${className}${isOver ? dropClassName : ""}`,
            style: { 
                cursor: "move",
                ...style 
            },
            ...restProps
        };
    }, [className, dropClassName, restProps, style, isOver]);

    return <> {components(tempProps, ref)} </>;
};

export default SortRealestate;