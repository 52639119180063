import React, { useState, useEffect } from 'react';
import styles from '../../styles/index.module.scss';
import { Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ItemTitleRoom from './item';
import genKey from 'crypto-random-string';

const RoomNames = ({ api }) => {
    const dispatch = useDispatch();
    const getSettings = useSelector((state) => state.statistic);

    const [status, setStatus] = useState(false);
    const [title, set_title] = useState('');
    const [titles, set_titles] = useState([]);

    useEffect(() => {
        if (getSettings.settings && getSettings.settings.length) {
            set_titles(
                getSettings.settings.filter((item) => item.type == 'NAME_ROOM')
            );
        }
    }, [getSettings.settings]);

    const addRoomName = () => {
        dispatch(api.statistic.savesetting(title, 'NAME_ROOM'));
    };

    return (
        <div className={styles.wrapper}>
            <div className="section-settings-block-head">
                <h3>Наименование комнат</h3>
                <Button
                    type="primary"
                    onClick={() => {
                        setStatus(!status);
                    }}
                >
                    {status ? 'Скрыть' : 'Показать'}
                </Button>
            </div>

            {status ? (
                <div className={styles.form}>
                    <div className={styles.form_add_setting}>
                        <Input
                            size="large"
                            value={title}
                            onChange={(val) => set_title(val.target.value)}
                            type="text"
                            placeholder="Название"
                        />
                        <Button size="large" onClick={addRoomName}>
                            Добавить
                        </Button>
                    </div>

                    <hr />

                    <div className={styles.list}>
                        {titles.length &&
                            titles.map((item) => (
                                <ItemTitleRoom
                                    key={genKey({ length: 15 })}
                                    styles={styles}
                                    item={item}
                                    dispatch={dispatch}
                                    api={api}
                                />
                            ))}
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default RoomNames;
