import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';
import config from '../../../../Config';
import { Button, Image } from 'antd';
import Switch from 'react-input-switch';
import FilesFlat from '../Create/Helpers/files/';
import SelectBudgetTemplate from './Helpers/Budget/select';
import styles from '../styles/index.module.scss';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { virtual_tours } from '@/Services/apollo/gqls';
import { Select, Input, Tooltip, Collapse, Result, Tag } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';

const { Option } = Select;
const { Panel } = Collapse;

const EditFlat = () => {
    const history = useNavigate();
    const { id } = useParams();

    let [createVirtualTour, createVirtualTourData] = useMutation(
        virtual_tours.createVirtualTours,
        {
            onCompleted: (data) => {
                return history(
                    `/virtual-tours/edit/${data?.createVirtualTour?._id}`
                );
            },
        }
    );

    const createVirtualTourEvent = (fields) => {
        createVirtualTour({
            variables: {
                fields,
            },
        });
    };

    const [title, setTitle] = useState('');
    const [squareMetres, setSquareMetres] = useState('');
    const [countRooms, setCountRooms] = useState('');
    const [materialPrice, setMaterialPrice] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [squareMetrePrice, setSquareMetrePrice] = useState('');
    const [ceilingHeight, setCeilingHeight] = useState('');

    const [budget_template, set_budget_template] = useState(null);

    const [infoFlat, setInfoFlat] = useState(null);

    const [seoTitle, setSeoTitle] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');
    const [seoDescription, setSeoDescription] = useState('');

    const [free_download, set_free_download] = useState(0);

    const [commercial, set_commercial] = React.useState(0);

    const dispatch = useDispatch();
    const getFlatsState = useSelector((state) => state.flats);
    const getFilesState = useSelector((state) => state.files);

    const getApartmentsState = useSelector((state) => state.apartments);
    const [apartmentComplex, setApartmentComplex] = useState([]);
    const [mainItemTemplate, setMainItemTemplate] = useState(null);
    const [prevRE, serPrevRE] = useState(null);
    const [virtual_tour, set_virtual_tour] = useState(null);

    useEffect(() => {
        getTemplate();
    }, [getApartmentsState?.vt_of_apartment_template]);

    const getTemplate = async () => {
        let data = await dispatch(api.flats.getTemplate(id));

        if (data.status) {
            set_virtual_tour(data?.virtual_tour || null);
        }
    };

    useEffect(() => {
        dispatch({
            type: 'CLEAR_APARTMENTS',
            data: [],
        });

        dispatch(api.apartments.getApartmentsComplex());
        loadAndUpdate();
    }, []);

    const clearInformation = () => {
        setInfoFlat(null);

        setTitle('');
        setSquareMetres('');
        setCountRooms('');
        setMaterialPrice('');
        setTotalPrice('');
        setSquareMetrePrice('');
        set_free_download(0);
        set_commercial(0);

        dispatch({
            type: 'MATERIAL_BUDGET_EXCEL',
            id: null,
        });
        dispatch({
            type: 'MATERIAL_BUDGET_PDF',
            id: null,
        });
        dispatch({
            type: 'FLAT_ARRANGEMENT_AUTOCAD',
            id: null,
        });
        dispatch({
            type: 'APARTMENT_MODEL_3DMAX',
            id: null,
        });
        dispatch({
            type: 'APARTMENT_DESIGN_REVIT',
            id: null,
        });
        dispatch({
            type: 'APARTMENT_DESIGN_PDF',
            id: null,
        });

        dispatch({
            type: 'PLAN_FLAT',
            photo: null,
        });

        dispatch({
            type: 'APARTMENT_INTERIOR_ARRAY',
            photos: null,
        });
    };

    const loadAndUpdate = async () => {
        clearInformation();

        let data = await dispatch(api.flats.getTemplate(id));

        if (data.status) {
            updateInformation(data);
            setMainItemTemplate(data);
        }
    };

    const updateInformation = (data) => {
        setInfoFlat(data);

        serPrevRE(data.apartment_complex_id);

        setTitle(data.title);
        setSquareMetres(data.square_metres);
        setCountRooms(data.count_rooms);
        setMaterialPrice(data.material_price);
        setTotalPrice(data.total_price);
        setSquareMetrePrice(data.square_metre_price);
        setCeilingHeight(data.ceiling_height);
        set_virtual_tour(data?.virtual_tour || null);

        set_budget_template(
            data?.budget_template ? data?.budget_template : null
        );

        setSeoTitle(data.seo_title ? data.seo_title : '');
        setSeoKeywords(data.seo_keywords ? data.seo_keywords : '');
        setSeoDescription(data.seo_description ? data.seo_description : '');

        set_free_download(data.free_download ? 1 : 0);

        set_commercial(data.commercial ? 1 : 0);

        setApartmentComplex(data.realestate ? data.realestate : []);

        data.files.map((file) => {
            if (file && file.type) {
                if (file.type == 'MATERIAL_BUDGET_EXCEL') {
                    dispatch({
                        type: 'MATERIAL_BUDGET_EXCEL',
                        id: file._id,
                    });
                } else if (file.type == 'MATERIAL_BUDGET_PDF') {
                    dispatch({
                        type: 'MATERIAL_BUDGET_PDF',
                        id: file._id,
                    });
                } else if (file.type == 'FLAT_ARRANGEMENT_AUTOCAD') {
                    dispatch({
                        type: 'FLAT_ARRANGEMENT_AUTOCAD',
                        id: file._id,
                    });
                } else if (file.type == 'APARTMENT_MODEL_3DMAX') {
                    dispatch({
                        type: 'APARTMENT_MODEL_3DMAX',
                        id: file._id,
                    });
                } else if (file.type == 'APARTMENT_DESIGN_REVIT') {
                    dispatch({
                        type: 'APARTMENT_DESIGN_REVIT',
                        id: file._id,
                    });
                } else if (file.type == 'APARTMENT_DESIGN_PDF') {
                    dispatch({
                        type: 'APARTMENT_DESIGN_PDF',
                        id: file._id,
                    });
                }
            }
        });

        let planPhotoURL =
            data.plan_photo && data.plan_photo.url ? data.plan_photo.url : '';

        dispatch({
            type: 'PLAN_FLAT',
            photo: {
                url: planPhotoURL,
                full_url: config.public + planPhotoURL,
                id:
                    data.plan_photo && data.plan_photo._id
                        ? data.plan_photo._id
                        : '',
            },
        });

        let previewPhotoURL =
            data.preview_photo && data.preview_photo.url
                ? data.preview_photo.url
                : '';

        dispatch({
            type: 'APARTMENT_PREVIEW_PHOTO',
            photo: {
                url: previewPhotoURL,
                full_url: config.public + previewPhotoURL,
                id:
                    data.preview_photo && data.preview_photo._id
                        ? data.preview_photo._id
                        : '',
            },
        });
    };

    const updateTemplate = () => {
        let fields = {
            title,
            square_metres: squareMetres,
            count_rooms: countRooms,
            material_price: materialPrice,
            total_price: totalPrice,
            square_metre_price: squareMetrePrice,
            ceiling_height: ceilingHeight,
            files: [
                getFilesState.MATERIAL_BUDGET_EXCEL,
                getFilesState.MATERIAL_BUDGET_PDF,
                getFilesState.FLAT_ARRANGEMENT_AUTOCAD,
                getFilesState.APARTMENT_MODEL_3DMAX,
                getFilesState.APARTMENT_DESIGN_REVIT,
                getFilesState.APARTMENT_DESIGN_PDF,
            ],
            plan_photo: getFilesState.PLAN_FLAT,
            id: infoFlat._id,
            preview_photo: getFilesState.PHOTO_PREVIEW,
            apartmentComplex,
            seoKeywords,
            seoTitle,
            seoDescription,
            free_download: free_download ? 1 : 0,
            commercial,
        };

        return dispatch(api.flats.updateTemplate(fields, history));
    };

    return (
        <content className="cities-page-block">
            {getFlatsState.request_flats_status ? (
                <h3>Обновляем информацию...</h3>
            ) : infoFlat ? (
                <div className="create-flat-block-form">
                    <div className="header-content-page">
                        <h1>Редактирование шаблона</h1>
                        <Tag color="orange">
                            Связано квартир:{' '}
                            {mainItemTemplate?.countApartments || 0}
                        </Tag>
                    </div>
                    <div className="form-flat-block">
                        <div className="row">
                            <div className="col-md-8">
                                <section
                                    className="input-section-form input-section-form-switch"
                                    style={{ marginTop: 15 }}
                                >
                                    <p>Коммерческая недвижимость</p>
                                    <Switch
                                        value={commercial}
                                        onChange={set_commercial}
                                    />
                                </section>

                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            setTitle(val.target.value);
                                        }}
                                        placeholder="Название*"
                                        value={title}
                                        suffix={
                                            <Tooltip title="Название">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </section>

                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            setSquareMetres(val.target.value);
                                        }}
                                        placeholder="Квадратные метры"
                                        value={squareMetres}
                                        suffix={
                                            <Tooltip title="Квадратные метры">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </section>

                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Количество комнат"
                                        onChange={(val) => {
                                            setCountRooms(val.target.value);
                                        }}
                                        value={countRooms}
                                        type="text"
                                        suffix={
                                            <Tooltip title="Количество комнат">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </section>

                                <section
                                    className={`input-section-form ${styles.sectionform}`}
                                    style={{ marginTop: 15 }}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Стоимость"
                                        onChange={(val) => {
                                            setTotalPrice(val.target.value);
                                        }}
                                        value={totalPrice}
                                        type="text"
                                        suffix={
                                            <Tooltip title="Стоимость">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </section>

                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            setSquareMetrePrice(
                                                val.target.value
                                            );
                                        }}
                                        value={squareMetrePrice}
                                        placeholder="Стоимость за квадратный метр"
                                        type="text"
                                        suffix={
                                            <Tooltip title="Стоимость за квадратный метр">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </section>

                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            setCeilingHeight(val.target.value);
                                        }}
                                        value={ceilingHeight}
                                        placeholder="Высота потолка"
                                        type="text"
                                        suffix={
                                            <Tooltip title="Высота потолка">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </section>

                                {!commercial && (
                                    <section
                                        className="input-section-form"
                                        style={{ marginTop: 15 }}
                                    >
                                        <Input
                                            size="large"
                                            label="Стоимость за материал"
                                            onChange={(val) => {
                                                setMaterialPrice(
                                                    val.target.value
                                                );
                                            }}
                                            value={materialPrice}
                                            placeholder="Стоимость за материал"
                                            type="text"
                                            suffix={
                                                <Tooltip title="Стоимость за материал">
                                                    <InfoCircleOutlined
                                                        style={{
                                                            color: 'rgba(0,0,0,.45)',
                                                        }}
                                                    />
                                                </Tooltip>
                                            }
                                        />
                                    </section>
                                )}

                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <h5>
                                        Закрепленный жк {prevRE?.title} (старая
                                        версия)
                                    </h5>
                                    {getApartmentsState.request_apartments_status ? (
                                        <h6>
                                            Загружается список жилых комплексов
                                        </h6>
                                    ) : getApartmentsState &&
                                      getApartmentsState.apartments ? (
                                        <Select
                                            showSearch={true}
                                            filterOption={(input, option) =>
                                                option.props.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0 ||
                                                option.props.value
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            optionFilterProp="children"
                                            size="large"
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Выберите жк"
                                            onChange={(val) => {
                                                setApartmentComplex(val);
                                            }}
                                            value={apartmentComplex}
                                            optionLabelProp="label"
                                        >
                                            {getApartmentsState?.apartments.map(
                                                (item) => {
                                                    return (
                                                        <Option
                                                            value={item?._id}
                                                            label={item?.title}
                                                        >
                                                            {item.title}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    ) : (
                                        <h5>Жилых комплексов не найдено</h5>
                                    )}
                                </section>

                                <SelectBudgetTemplate
                                    budget_template={budget_template || ''}
                                    dispatch={dispatch}
                                    api={api}
                                    id={id}
                                />

                                <section
                                    className="input-section-form switch-block-free-download"
                                    style={{ marginTop: 15 }}
                                >
                                    <p>Свободное скачивание файлов</p>
                                    <Switch
                                        value={free_download}
                                        onChange={set_free_download}
                                    />
                                </section>

                                <Collapse accordion>
                                    <Panel header="Файлы" key="1">
                                        <FilesFlat
                                            template={mainItemTemplate}
                                        />
                                    </Panel>
                                    <Panel
                                        header="Прикрепить виртуальный тур"
                                        key="2"
                                    >
                                        {virtual_tour ? (
                                            <Result
                                                status="success"
                                                title="Тур прикреплен"
                                                subTitle={`${virtual_tour?.title}`}
                                                extra={
                                                    <Link
                                                        to={`/virtual-tours/edit/${virtual_tour?._id}`}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            key="console"
                                                        >
                                                            Редактировать
                                                            виртуальный тур
                                                        </Button>
                                                    </Link>
                                                }
                                            />
                                        ) : (
                                            <Result
                                                title="Тур не найден"
                                                subTitle="Вы можете создать тур, нажав на кнопку ниже, после чего Вас автоматически перекинет на страницу редактирования тура."
                                                extra={
                                                    <Button
                                                        type="primary"
                                                        key="console"
                                                        onClick={() => {
                                                            if (
                                                                !createVirtualTourData?.loading
                                                            ) {
                                                                createVirtualTourEvent(
                                                                    {
                                                                        title: title,
                                                                        secondary_title:
                                                                            title,
                                                                        template_id:
                                                                            id,
                                                                        type: 'apartment_template',
                                                                        layout: getFilesState
                                                                            .PLAN_FLAT
                                                                            .id,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {createVirtualTourData?.loading ? (
                                                            <>
                                                                <LoadingOutlined />
                                                                создаем...
                                                            </>
                                                        ) : (
                                                            'Создать виртуальный тур'
                                                        )}
                                                    </Button>
                                                }
                                            />
                                        )}
                                    </Panel>
                                </Collapse>
                            </div>

                            <div className="col-md-4">
                                <div className="plan-block-file">
                                    <h4>Планировка квартиры</h4>

                                    {getFilesState.request_upload_status ? (
                                        <Button
                                            type="primary"
                                            className={styles.buttonLoad}
                                        >
                                            <h4>
                                                Загружаем...{' '}
                                                <LoadingOutlined
                                                    className={
                                                        styles.buttonLoadIcon
                                                    }
                                                />
                                            </h4>
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            className={styles.buttonLoad}
                                        >
                                            <h4>Загрузить файл</h4>
                                            <input
                                                type="file"
                                                onChange={(val) => {
                                                    dispatch(
                                                        api.files.uploadFile(
                                                            val.target.files[0],
                                                            'svg-image'
                                                        )
                                                    );
                                                }}
                                            />
                                        </Button>
                                    )}

                                    {getFilesState.PLAN_FLAT ? (
                                        <Image
                                            src={
                                                config.public +
                                                getFilesState.PLAN_FLAT.url
                                            }
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div className="plan-block-file">
                                    <h4>Превью квартиры</h4>
                                    {getFilesState.request_upload_status ? (
                                        <Button
                                            type="primary"
                                            className={styles.buttonLoad}
                                        >
                                            <h4>
                                                Загружаем...{' '}
                                                <LoadingOutlined
                                                    className={
                                                        styles.buttonLoadIcon
                                                    }
                                                />
                                            </h4>
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            className={styles.buttonLoad}
                                        >
                                            <h4>Загрузить файл</h4>
                                            <input
                                                type="file"
                                                onChange={(val) => {
                                                    dispatch(
                                                        api.files.uploadFile(
                                                            val.target.files[0],
                                                            'IMAGE_PREVIEW'
                                                        )
                                                    );
                                                }}
                                            />
                                        </Button>
                                    )}
                                    {getFilesState.PHOTO_PREVIEW ? (
                                        <Image
                                            src={`${config.public}${getFilesState.PHOTO_PREVIEW.url}`}
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>

                                <div className="plan-block-file seo-block-form">
                                    <h4>SEO</h4>

                                    <p>Keywords</p>
                                    <Input
                                        size="large"
                                        type="text"
                                        value={seoKeywords}
                                        onChange={(val) =>
                                            setSeoKeywords(val.target.value)
                                        }
                                        placeholder="Keywords"
                                        suffix={
                                            <Tooltip title="Keywords">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />

                                    <p>Description</p>
                                    <Input
                                        size="large"
                                        type="text"
                                        value={seoDescription}
                                        onChange={(val) =>
                                            setSeoDescription(val.target.value)
                                        }
                                        placeholder="Description"
                                        suffix={
                                            <Tooltip title="Description">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />

                                    <p>Title</p>
                                    <Input
                                        size="large"
                                        type="text"
                                        value={seoTitle}
                                        onChange={(val) =>
                                            setSeoTitle(val.target.value)
                                        }
                                        placeholder="Title"
                                        suffix={
                                            <Tooltip title="Title">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button
                        type="primary"
                        onClick={updateTemplate.bind(undefined)}
                    >
                        Редактировать информацию
                    </Button>
                </div>
            ) : (
                <h3>Информация не валидна</h3>
            )}
        </content>
    );
};

export default EditFlat;
