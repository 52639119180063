import { gql } from '@apollo/client';

const getVirtualTours = gql`
    query ($fields: VirtualTourInput) {
        getVirtualTours(fields: $fields) {
            data {
                _id
                title
                secondary_title
                createdAt
                views
                author {
                    _id
                    email
                    firstname
                }
            }
            total
        }
    }
`;

const createVirtualTours = gql`
    mutation ($fields: createVirtualTourInput) {
        createVirtualTour(fields: $fields) {
            _id
            title
            secondary_title
        }
    }
`;

const getVirtualTourByID = gql`
    query ($id: String, $type: String) {
        getVTRoomTypes(type: $type) {
            _id
            value
            title
        }

        getVirtualTourByID(id: $id) {
            _id
            title
            secondary_title
            yaw
            hfov
            type
            views
            layout {
                _id
                url
            }
            author {
                _id
                email
                firstname
            }
            createdAt
            template_id {
                _id
            }
            photos {
                yaw_layout_diff
                url
                _id
                size
                original_size
                dott_for_room {
                    top
                    left
                }
                title_room {
                    title
                    value
                }
                vr_data {
                    position {
                        pitch
                        yaw
                    }
                    type
                    indexScene
                    url
                    title
                    price
                    product {
                        _id
                        title
                        price
                        photo {
                            url
                        }
                    }
                }
            }
        }
    }
`;

const updateScene = gql`
    mutation ($fields: vt_updateSceneInput) {
        vt_updateScene(fields: $fields) {
            url
            _id
            size
            original_size
            dott_for_room {
                top
                left
            }
            title_room {
                title
                value
            }
            vr_data {
                position {
                    pitch
                    yaw
                }
                type
                indexScene
                url
                title
                price
                product {
                    _id
                    title
                    price
                    photo {
                        url
                    }
                }
            }
        }
    }
`;

const updateFields = gql`
    mutation ($fields: vt_updateFieldsInput) {
        vt_updateFields(fields: $fields) {
            _id
            title
            secondary_title
            yaw
            hfov
            type
            views
            layout {
                _id
                url
            }
            createdAt
            author {
                _id
                email
                firstname
            }
            template_id {
                _id
            }
            photos {
                yaw_layout_diff
                url
                _id
                size
                original_size
                dott_for_room {
                    top
                    left
                }
                title_room {
                    title
                    value
                }
                vr_data {
                    position {
                        pitch
                        yaw
                    }
                    type
                    indexScene
                    url
                    title
                    price
                    product {
                        _id
                        title
                        price
                        photo {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export default {
    getVirtualTours,
    createVirtualTours,
    getVirtualTourByID,
    updateScene,
    updateFields,
};
