import axios from '../axios';
import { message } from 'antd';

const chess = {
    updatePrices: (fields) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_CHESS_MODAL_FORM_PRICES',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/chess/realestate-flat/prices',
                fields
            );

            dispatch({
                type: 'REQUEST_CHESS_MODAL_FORM_PRICES',
                status: false,
            });

            return message.success(data);
        } catch (err) {
            dispatch({
                type: 'REQUEST_CHESS_MODAL_FORM_PRICES',
                status: false,
            });
        }
    },
    modalFormPrices: (fields) => async (dispatch) => {
        dispatch({
            type: 'CHESS_MODAL_FORM_PRICES',
            ...fields,
        });
    },
    deleteFlat: (fields) => async (dispatch) => {
        try {
            dispatch({
                type: 'CHESS_FLAT_DELETE',
                fields,
            });

            await axios.delete('/v1/chess/realestate-flat', {
                params: fields,
            });

            return;
        } catch (err) {
            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    updateFlat: (fields) => async (dispatch) => {
        try {
            await axios.post('/v1/chess/realestate-flat/update', fields);

            if (fields?.type_form === 'update_points') {
                dispatch({
                    type: 'CHESS_FLAT_UPDATE_PLAN_POINTS',
                    fields,
                });
                message.success('Данные успешно сохранены');
            }

            return;
        } catch (err) {
            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    createFlat: (fields) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: true,
            });

            await axios.post('/v1/chess/realestate-flat', fields);

            dispatch(chess.getChess(fields?.realestate_id));

            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: false,
            });

            dispatch({
                type: 'STATUS_MODAL_FORM',
                status: false,
            });

            dispatch({
                type: 'CLEAR_SELECT_BLOCK_FORM',
            });

            dispatch(chess.getChess(fields?.realestate_id));

            if (fields?.flat_id) {
                message.success('Информация сохранена');
            }

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: false,
            });

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    uploadFile: (file, realestate_id, block_id, floor) => async (dispatch) => {
        dispatch({
            type: 'UPLOAD_STATUS_FILE_OF_CHESS',
            status: true,
        });

        try {
            let formData = new FormData();
            formData.append('uploadType', 'IMAGE');
            formData.append('file', file);

            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            let updateFloor = await axios.post('/v1/chess/update-floor', {
                floor: floor,
                realestate_id,
                block_id,
                file_id: data?.id,
            });

            dispatch({
                type: 'UPDATE_CHESS_FORM_FLOOR_INFO',
                data: updateFloor?.data || null,
            });

            dispatch({
                type: 'UPLOAD_STATUS_FILE_OF_CHESS',
                status: false,
            });

            dispatch({
                type: 'UPDATE_FLOOR_PLAN_ID',
                id: data?.id,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'UPLOAD_STATUS_FILE_OF_CHESS',
                status: false,
            });
        }
    },
    getFloorInfo: (floor, realestate_id, block_id) => async (dispatch) => {
        dispatch({
            type: 'STATUS_REQUEST_FLOOR',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/chess/realestate/block/floor', {
                params: {
                    floor,
                    realestate_id,
                    block_id,
                },
            });

            dispatch({
                type: 'UPDATE_CHESS_FORM_FLOOR_INFO',
                data: data || null,
            });

            dispatch({
                type: 'STATUS_REQUEST_FLOOR',
                status: false,
            });

            dispatch({
                type: 'SELECT_MIN_FLOOR_BOX_FORM',
                floor: Number(floor),
            });

            return;
        } catch (err) {
            dispatch({
                type: 'STATUS_REQUEST_FLOOR',
                status: false,
            });
        }
    },
    formBlock: (fields) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: true,
            });

            let { data } = await axios.post('/v1/chess/realestate-block', fields);

            dispatch(chess.getChess(fields?.realestate_id));

            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: false,
            });

            dispatch({
                type: 'STATUS_MODAL_FORM',
                status: false,
            });

            dispatch({
                type: 'CLEAR_SELECT_BLOCK_FORM',
            });

            if (data?.message) {
                message.error(data?.message);
            }

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: false,
            });

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    removeBlock: (fields) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: true,
            });

            await axios.delete(`/v1/chess/realestate-block`, {
                params: fields,
            });

            dispatch(chess.getChess(fields?.realestate_id));

            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: false,
            });

            dispatch({
                type: 'STATUS_MODAL_FORM',
                status: false,
            });

            dispatch({
                type: 'CLEAR_SELECT_BLOCK_FORM',
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_CREATE',
                status: false,
            });

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    getChess: (id) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_CUSTOM_CHESS_DATA',
                status: true,
            });

            let { data } = await axios.get(`/v1/chess/${id}`);

            dispatch({
                type: 'UPDATE_CUSTOM_CHESS_DATA',
                data,
            });

            dispatch({
                type: 'REQUEST_CUSTOM_CHESS_DATA',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_CUSTOM_CHESS_DATA',
                status: false,
            });

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
};

export default chess;
