import React, { useState } from 'react';

import { EditOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import Editor from '@/Containers/Admin/Apartments/Edit/Helpers/editor';

const ItemMortgage = ({ api, item, styles, dispatch }) => {
    const [edit_status, set_edit_status] = useState(false);
    const [title, set_title] = useState(item.title);
    const [seo_content, set_seo_content] = useState(item.content || '');

    const editMortgage = () => {
        dispatch(api.statistic.updateSetting(item._id, title, seo_content));

        //dispatch(api.statistic.editMortgage(item._id, title));
        set_edit_status(!edit_status);
    };

    const deleteMortgage = () => {
        dispatch(api.statistic.deleteMortgage(item._id));
    };

    return (
        <div
            key={item._id}
            className={`${styles.ItemMortgage} ${styles.itemSetting}`}
            style={{
                boxShadow: edit_status ? '0 0 15px rgba(0,0,0,0.2)' : '',
                border: edit_status ? '1px solid rgb(0 0 0 / 9%)' : '',
                marginBottom: edit_status ? '15px' : '',
            }}
        >
            {edit_status ? (
                <div className={styles.formWithTA}>
                    <Input
                        size="large"
                        onChange={(val) => set_title(val.target.value)}
                        value={title}
                    />
                    <Editor
                        dispatch={dispatch}
                        api={api}
                        seo_content={seo_content}
                        setContent={(val) => set_seo_content(val)}
                    />
                </div>
            ) : (
                <h4>{title}</h4>
            )}

            <div className={styles.itemControllButtons}>
                {edit_status ? (
                    <div className={styles.itemSettingControl}>
                        <CheckOutlined
                            onClick={editMortgage}
                            className={styles.itemSettingControlIcon}
                        />
                    </div>
                ) : (
                    <div className={styles.itemSettingControl}>
                        <EditOutlined
                            onClick={() => set_edit_status(!edit_status)}
                            className={styles.itemSettingControlIcon}
                        />
                        <DeleteOutlined size={1} onClick={deleteMortgage} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ItemMortgage;
