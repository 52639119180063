const initialState = {
    cities: [],
    countries: null,

    request_city_status: false,
    request_country_status: false,

    district_request_status: false,
    district_request_success: false,
};

const cities = (state = initialState, action) => {
    switch (action.type) {
        case 'DISTRICT_REQUEST_SUCCESS':
            return {
                ...state,
                district_request_success: action.status,
            };

        case 'DISTRICT_REQUEST_STATUS':
            return {
                ...state,
                district_request_status: action.status,
            };

        case 'UPDATE_CITIES':
            return {
                ...state,
                cities: [...action.data],
            };

        case 'UPDATE_COUNTRIES':
            return {
                ...state,
                countries: action.data,
            };

        case 'CHANGE_CITY':
            return {
                ...state,
                cities: state.cities.map((item, index) => {
                    if (index == action.index) {
                        item.title = action.title;
                    }

                    return item;
                }),
            };

        case 'REQUEST_COUNTRY_STATUS':
            return {
                ...state,
                request_country_status: action.status,
            };

        case 'REQUEST_CITY_STATUS':
            return {
                ...state,
                request_city_status: action.status,
            };

        default:
            return state;
    }
};

export default cities;
