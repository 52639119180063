const initialState = {
    apartments: [],
    apartmentsForRealtors: [],
    getApartmentForEdit: null,

    templatesOfRealestate: [],

    objects: [],
    total_objects: 0,
    status_object: '',

    request_apartments_status: true,
    request_update_blocks: false,

    price_apartment_complex: null,
    request_price_apartment_complex: false,

    template: null,

    //360
    apartment_360: null,

    page: 0,
    FR: false,

    request_no_data: false,
    first_request_realestate: true,

    //chess
    chess_apartment: null,
    chess_apartment_loading: false,
    contextMenu: {
        blockIndex: null,
        floorIndex: null,
        flatIndex: null,
        changePriceFlat: false,
        changeTitle: false,
        status: false,
    },

    virtual_tours: [],
    request_virtual_tours: false,
    vt_of_realestate: [],
    vt_of_apartment_template: null,
};

const apartments = (state = initialState, action) => {
    switch (action.type) {
        case 'VERTUAL_TOUR_UPDATE_APARTMENT': {
            return {
                ...state,
                vt_of_apartment_template: action.vt_id || null,
            };
        }

        case 'VERTUAL_TOURS_BY_REALESTATE': {
            return {
                ...state,
                vt_of_realestate: action.data || [],
            };
        }

        case 'VERTUAL_TOUR_UPDATE_SEARCH_LIST': {
            return {
                ...state,
                virtual_tours: state.virtual_tours.map((item) => {
                    if (item?._id === action?.vt_id) {
                        return {
                            ...item,
                            bind: true,
                        };
                    }

                    return item;
                }),
            };
        }
        case 'UPDATE_VIRTUAL_TOURS': {
            return {
                ...state,
                virtual_tours: action.data,
            };
        }
        case 'REQUEST_VIRTUAL_TOURS': {
            return {
                ...state,
                request_virtual_tours: action.status,
            };
        }

        case 'FIRST_REQUEST_REALESTATE': {
            return {
                ...state,
                first_request_realestate: false,
            };
        }

        case 'TEMPLATES_OF_REALESTATE': {
            return {
                ...state,
                templatesOfRealestate: action.data,
            };
        }

        case 'UPDATE_CHESS': {
            return {
                ...state,
                chess_apartment: {
                    ...state.chess_apartment,
                    blocks: state.chess_apartment.blocks.map((block) => {
                        if (block?.floors && block?.floors.length) {
                            block?.floors.map((floor) => {
                                if (floor?.flats && floor?.flats.length) {
                                    floor?.flats.map((flat) => {
                                        if (
                                            action.data.type_trigger === 'FLAT'
                                        ) {
                                            if (
                                                action.data.flat?._id ===
                                                String(flat?._id)
                                            ) {
                                                flat.sold = action.data.sold;
                                                flat.secondary =
                                                    action.data.secondary;
                                                flat.title = action.data.title;
                                                flat.square_metre_price =
                                                    action.data.square_metres_price;
                                                flat.total_price =
                                                    Number(
                                                        action.data
                                                            .square_metres_price
                                                    ) *
                                                    (flat?.square_metres ||
                                                        flat?.template
                                                            ?.square_metres);
                                            }
                                        } else if (
                                            action.data.type_trigger === 'BLOCK'
                                        ) {
                                            if (
                                                action.data.block ===
                                                String(flat?.block_id?._id)
                                            ) {
                                                flat.square_metre_price =
                                                    action.data.square_metres_price;
                                                flat.total_price =
                                                    Number(
                                                        action.data
                                                            .square_metres_price
                                                    ) *
                                                    (flat?.square_metres ||
                                                        flat?.template
                                                            ?.square_metres);
                                            }
                                        } else if (
                                            action.data.type_trigger === 'FLOOR'
                                        ) {
                                            if (
                                                action.data.block ===
                                                    String(
                                                        flat?.block_id?._id
                                                    ) &&
                                                String(action.data.floor) ===
                                                    String(flat?.floor)
                                            ) {
                                                flat.square_metre_price =
                                                    action.data.square_metres_price;
                                                flat.total_price =
                                                    Number(
                                                        action.data
                                                            .square_metres_price
                                                    ) *
                                                    (flat?.square_metres ||
                                                        flat?.template
                                                            ?.square_metres);
                                            }
                                        }
                                        return flat;
                                    });
                                }
                                return floor;
                            });
                        }
                        return block;
                    }),
                },
            };
        }

        case 'UPDATE_OBJECTS_PAGES': {
            return {
                ...state,
                total_objects: action?.total,
            };
        }

        case 'UPDATE_MAIN_REALESTATE_BY_OBJECT': {
            return {
                ...state,
                objects: state.objects.map((object) => {
                    if (String(object?._id) === String(action.object_id)) {
                        object.realestate.map((re) => {
                            if (
                                String(re?._id) === String(action.realestate_id)
                            ) {
                                re.main = true;
                            } else {
                                re.main = false;
                            }
                        });
                    }
                    return object;
                }),
            };
        }

        case 'CHANGE_STATUS_OBJECT': {
            return {
                ...state,
                status_object: action.payload,
            };
        }

        case 'UPDATE_OBJECTS': {
            return {
                ...state,
                objects: action.data,
            };
        }

        case 'UPDATE_PREVIEW_PHOTO_REALESTATE': {
            let updateRealEstate = state.apartments.map((item) => {
                if (item?._id == action?.id) {
                    item.photo.url = action?.photo?.url;
                }
                return item;
            });

            return {
                ...state,
                apartments: updateRealEstate,
            };
        }

        case 'REAL_ESTATE_FOR_REALTORS':
            return {
                ...state,
                apartmentsForRealtors: action.data,
            };

        case 'CLEAR_REAL_ESTATE':
            return {
                ...state,
                apartments: [],
            };

        case 'APART_COMPLEX_REQ_NO_DATA':
            return {
                ...state,
                request_no_data: action.status,
            };

        case 'APART_COMPLEX_FR':
            return {
                ...state,
                FR: true,
            };

        case 'APART_COMPLEX_PAGE':
            return {
                ...state,
                page: action.page,
            };

        case 'UPDATE_SORT_APARTMENTS': {
            let updateSortApart = state.apartments.map((apart) => {
                if (String(apart._id) === String(action.drag_complex)) {
                    apart.sort = Number(action.select_complex_sort_number);
                }

                if (String(apart._id) === String(action.select_complex)) {
                    apart.sort = Number(action.drag_complex_sort_number);
                }

                return apart;
            });

            return {
                ...state,
                apartments: updateSortApart.sort((a, b) => {
                    return a.sort - b.sort;
                }),
            };
        }

        case 'FLAT_TOGGLE_CONTEXT_MENU':
            return {
                ...state,
                contextMenu: {
                    blockIndex: action.blockIndex,
                    floorIndex: action.floorIndex,
                    flatIndex: action.flatIndex,
                    changePriceFlat: false,
                    changeTitle: false,
                    status: action.status,
                },
            };

        case 'FLAT_CHANGE_PRICE_CHESS':
            return {
                ...state,
                contextMenu: {
                    blockIndex: action.blockIndex,
                    floorIndex: action.floorIndex,
                    flatIndex: action.flatIndex,
                    changePriceFlat: action.status,
                    status: action.menu,
                },
            };

        case 'FLAT_CHANGE_TITLE_CHESS':
            return {
                ...state,
                contextMenu: {
                    blockIndex: action.blockIndex,
                    floorIndex: action.floorIndex,
                    flatIndex: action.flatIndex,
                    changeTitle: action.status,
                    status: action.menu,
                },
            };

        case 'UPDATE_TITLE_FLAT_CHESS': {
            let { title } = action;

            let mutationFlatTitle = state.chess_apartment;
            mutationFlatTitle.blocks[action.blockIndex].floors[
                action.floorIndex
            ].flats[action.flatIndex].title = title;

            return {
                ...state,
                chess_apartment: mutationFlatTitle,
            };
        }

        case 'UPDATE_SOLD_FLAT_CHESS': {
            let { status } = action;

            let mutationFlatStatus = state.chess_apartment;
            mutationFlatStatus.blocks[action.blockIndex].floors[
                action.floorIndex
            ].flats[action.flatIndex].sold = status;

            return {
                ...state,
                chess_apartment: mutationFlatStatus,
            };
        }

        case 'REQUEST_APARTMENT_COMPLEX_CHESS':
            return {
                ...state,
                chess_apartment_loading: action.status,
            };

        case 'UPDATE_APARTMENT_COMPLEX_CHESS':
            return {
                ...state,
                chess_apartment: action.data,
            };

        case 'REQUEST_APARTMENT_COMPLEX_PRICES':
            return {
                ...state,
                request_price_apartment_complex: action.status,
            };

        case 'UPDATE_APARTMENT_COMPLEX_PRICES':
            return {
                ...state,
                price_apartment_complex: action.data,
            };

        case 'UPDATE_APARTMENT_ONE_SOLD':
            return {
                ...state,
                apartments: state.apartments.map((item) => {
                    if (item._id == action.id) {
                        item.sold = action.status;
                    }
                    return item;
                }),
            };

        case 'UPDATE_TEMPLATE_FOR_360':
            return {
                ...state,
                template: action.template,
            };

        case 'CLEAR_TEMPLATE_FOR_360':
            return {
                ...state,
                template: null,
            };

        case 'REMOVE_REALESTATE':
            return {
                ...state,
                apartments: state.apartments.filter(
                    (item) => item._id != action.id
                ),
            };

        case 'CLEAR_APARTMENTS':
            return {
                ...state,
                apartments: [],
            };

        case 'UPDATE_APARTMENTS':
            return {
                ...state,
                apartments: [...state.apartments, ...action.data],
            };

        case 'UPDATE_APARTMENTS_360':
            return {
                ...state,
                apartment_360: action.data,
            };

        case 'UPDATE_APARTMENT_FOR_EDIT':
            return {
                ...state,
                getApartmentForEdit: action.data,
            };

        case 'RESET_APARTMENT_BLOCKS':
            return {
                ...state,
                getApartmentForEdit: {
                    ...state.getApartmentForEdit,
                    blocks: state.getApartmentForEdit.blocks.map((block) => {
                        return {
                            ...block,
                            photo_points: {
                                x: 0,
                                y: 0,
                            },
                        };
                    }),
                },
            };

        case 'UPDATE_POINTS_BLOCK_APARTMENT_COMPLEX':
            return {
                ...state,
                getApartmentForEdit: {
                    ...state.getApartmentForEdit,
                    blocks: state.getApartmentForEdit.blocks,
                },
            };

        case 'UPDATE_BLOCKS':
            return {
                ...state,
                getApartmentForEdit: {
                    ...state.getApartmentForEdit,
                    blocks: action.blocks,
                },
            };

        case 'REQUEST_APARTMENTS_STATUS':
            return {
                ...state,
                request_apartments_status: action.status,
            };

        case 'REQUEST_UPDATE_BLOCKS_FOR_APARTMENTS':
            return {
                ...state,
                request_update_blocks: action.status,
            };

        default:
            return state;
    }
};

export default apartments;
