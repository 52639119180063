"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MappingSource = void 0;
var MappingSource = /** @class */ (function () {
    function MappingSource() {
    }
    MappingSource.aggregation = function (source, aggregation) {
        var _a = aggregation.type, type = _a === void 0 ? 'grid' : _a, radius = aggregation.radius, method = aggregation.method, field = aggregation.field;
        var config = { type: type, size: radius, method: method, field: field };
        if (source.transforms) {
            // 过滤 transform 有相同配置情况
            source.transforms = source.transforms.filter(function (transform) { return transform.type !== config.type; });
            source.transforms.push(config);
        }
        else {
            source.transforms = [config];
        }
    };
    return MappingSource;
}());
exports.MappingSource = MappingSource;
