import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../../../Config';
import { Button, TextField } from '@material-ui/core';

import { DeleteForever } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';
import ItemBlock from './Helpers/itemBlock';

const CreateBlocks = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const refPoint = useRef();

    const getStateApartments = useSelector((state) => state.apartments);

    const [title, setTitle] = React.useState('');
    const [floors, setFloors] = React.useState('');

    const [previewBlocksPointsStatus, setPreviewBlocksPointsStatus] =
        React.useState(false);

    const [statusAreaBlock, setStatusAreaBlock] = React.useState(false);
    const [blockAreas, setBlockAreas] = React.useState([]);
    const [areaPoints, setAreaPoints] = React.useState('');

    const [statusSinglePoint, setStatusSinglePoint] = React.useState(false);
    const [titleSinglePoint, setTitleSinglePoint] = React.useState('');
    const [coordsSinglePoint, setCoordsSinglePoint] = React.useState(null);
    const [singlePointIcon, setSinglePointIcon] = React.useState(null);

    const [downIndexPointStatus, setDownIndexPointStatus] =
        React.useState(false);
    const [downIndexPoint, setDownIndexPoint] = React.useState(null);

    const [viewBlock, setViewBlock] = React.useState(null);
    const [viewPoint, setViewPoint] = React.useState(null);

    const [tabSelect, setTabSelect] = React.useState('BLOCKS');

    //edit area points block
    const [editAreaPointBlock, setEditAreaPointBlock] = React.useState([]);
    const [editAreaPointBlockSTR, setEditAreaPointBlockSTR] =
        React.useState('');
    const [statusEditAreaPointBlock, setStatusEditAreaPointBlock] =
        React.useState(false);

    const [seoTitle, setSeoTitle] = React.useState('');
    const [seoKeywords, setSeoKeywords] = React.useState('');
    const [seoDescription, setSeoDescription] = React.useState('');

    React.useEffect(() => {
        dispatch(api.apartments.getApartmentById(id, true));
    }, []);

    const saveEditAreaPointBlock = (idBlockEdit) => {
        setStatusEditAreaPointBlock(false);
        setEditAreaPointBlockSTR('');
        setEditAreaPointBlock([]);
        dispatch(
            api.apartments.saveEditBlockareaPoint(
                idBlockEdit,
                editAreaPointBlock,
                editAreaPointBlockSTR
            )
        );
    };

    const mathAreaPoints = (areas) => {
        let areaPointsString = '';
        setBlockAreas(areas);
        setEditAreaPointBlock(areas);
        areas.map((point) => {
            areaPointsString = areaPointsString + `${point[0]}% ${point[1]}%,`;
        });

        setAreaPoints(
            areaPointsString.substring(0, areaPointsString.length - 1)
        );
        setEditAreaPointBlockSTR(
            areaPointsString.substring(0, areaPointsString.length - 1)
        );
    };

    const addPoint = (event) => {
        let posXnative = event.nativeEvent.offsetX;
        let posYnative = event.nativeEvent.offsetY;
        let blockHeight = document.getElementById(
            'area-block-panorama-select'
        ).clientHeight;
        let blockwidth = document.getElementById(
            'area-block-panorama-select'
        ).clientWidth;

        let percentX = (posXnative / blockwidth) * 100;
        let percentY = (posYnative / blockHeight) * 100;

        let newBlockAreas;

        if (statusEditAreaPointBlock) {
            newBlockAreas = [...editAreaPointBlock, [percentX, percentY]];
            mathAreaPoints(newBlockAreas);
        } else {
            newBlockAreas = [...blockAreas, [percentX, percentY]];
            mathAreaPoints(newBlockAreas);
        }
    };

    const addSinglePoint = (event) => {
        let posXnative = event.nativeEvent.offsetX;
        let posYnative = event.nativeEvent.offsetY;
        let blockHeight = document.getElementById(
            'main-photo-panorama'
        ).clientHeight;
        let blockwidth = document.getElementById(
            'main-photo-panorama'
        ).clientWidth;

        let percentX = (posXnative / blockwidth) * 100;
        let percentY = (posYnative / blockHeight) * 100;

        setCoordsSinglePoint({
            x: percentX,
            y: percentY,
        });
    };

    const moveMousePoint = (event) => {
        if (downIndexPointStatus || statusEditAreaPointBlock) {
            let posXnative = event.nativeEvent.offsetX;
            let posYnative = event.nativeEvent.offsetY;
            let blockHeight = document.getElementById(
                'area-block-panorama-select'
            ).clientHeight;
            let blockwidth = document.getElementById(
                'area-block-panorama-select'
            ).clientWidth;

            let percentX = (posXnative / blockwidth) * 100;
            let percentY = (posYnative / blockHeight) * 100;

            if (statusEditAreaPointBlock) {
                editAreaPointBlock[downIndexPoint] = [percentX, percentY];
                mathAreaPoints(editAreaPointBlock);
            } else {
                blockAreas[downIndexPoint] = [percentX, percentY];
                mathAreaPoints(blockAreas);
            }
        }
    };

    const removePointFromAreas = (index) => {
        let resRemovePoint = window.confirm(
            'Вы дейтсвительно хотите удалить точку?'
        );

        if (resRemovePoint) {
            let newBlockAreas = [];

            blockAreas.map((point, indexPoint) => {
                if (indexPoint != index) {
                    newBlockAreas = [...newBlockAreas, point];
                }
            });

            mathAreaPoints(newBlockAreas);
        }
    };

    const addBlock = () => {
        let block = {
            title,
            floors_count: floors,
            complex_id: id,
            area_points: blockAreas,
            area_points_string: areaPoints,
            seoTitle,
            seoKeywords,
            seoDescription,
        };

        setBlockAreas([]);
        setAreaPoints('');
        setTitle('');
        setFloors('');
        setStatusAreaBlock(false);

        return dispatch(api.blocks.addBlock(block));
    };

    const addPointForBlock = () => {
        let pointInfo = {
            title: titleSinglePoint,
            icon: singlePointIcon,
            coords: coordsSinglePoint,
            complex_id: id,
        };

        if (titleSinglePoint && singlePointIcon) {
            setStatusSinglePoint(false);
            setTitleSinglePoint('');
            setCoordsSinglePoint(null);
            setSinglePointIcon(null);
        }

        return dispatch(api.blocks.addPointForBlock(pointInfo));
    };

    const deleteBlock = (blockId) => {
        let confirmDelete = window.confirm(
            'Вы действительно хотите удалить блок?'
        );

        if (confirmDelete) {
            return dispatch(api.blocks.deleteBlock(blockId, id));
        }
    };

    const deletePoint = (pointId) => {
        let confirmDelete = window.confirm(
            'Вы действительно хотите удалить точку?'
        );

        if (confirmDelete) {
            return dispatch(api.blocks.deletePoint(pointId, id));
        }
    };

    return (
        <div>
            {getStateApartments?.getApartmentForEdit ? (
                <content className="cities-page-block">
                    <h1>Управление блоками</h1>
                    <p>{getStateApartments.getApartmentForEdit.title}</p>

                    {getStateApartments.getApartmentForEdit.blocks.length >
                    0 ? (
                        <Button
                            variant="contained"
                            onClick={() => {
                                setPreviewBlocksPointsStatus(
                                    !previewBlocksPointsStatus
                                );
                            }}
                        >
                            {previewBlocksPointsStatus
                                ? 'Скрыть превью'
                                : 'Показать превью'}
                        </Button>
                    ) : (
                        <div></div>
                    )}

                    <section
                        style={{
                            marginBottom: 15,
                        }}
                        className="upload-photo-block"
                    >
                        <h1 className="description-text-panorama">
                            Выделите области с блоками и заполните информацию.
                            Также добавьте соответствующие метки
                        </h1>

                        <div className="panorama-buttons-controll">
                            <span
                                onClick={() => {
                                    setStatusAreaBlock(!statusAreaBlock);
                                }}
                            >
                                Выделить блок
                            </span>
                            <span
                                onClick={() => {
                                    setStatusSinglePoint(!statusSinglePoint);
                                    if (statusSinglePoint) {
                                        setTitleSinglePoint('');
                                        setSinglePointIcon(null);
                                    }
                                }}
                            >
                                Добавить метку
                            </span>
                        </div>
                    </section>

                    <section>
                        {statusSinglePoint ? (
                            <h5>Выберите точку на картинке</h5>
                        ) : (
                            <div></div>
                        )}
                    </section>

                    {previewBlocksPointsStatus ? (
                        <section className="blocks-content-visual">
                            <div className="main-block-with-pamorama">
                                <div className="main-photo-panorama">
                                    {getStateApartments.getApartmentForEdit
                                        .image_complex_with_all_blocks ? (
                                        <img
                                            src={`${config.public}${getStateApartments.getApartmentForEdit.image_complex_with_all_blocks.url}`}
                                            alt="panorama"
                                        />
                                    ) : (
                                        <h5>
                                            Картинки не найдено, обновите
                                            информацию ЖК
                                        </h5>
                                    )}

                                    {getStateApartments.getApartmentForEdit.blocks.map(
                                        (block, index) => {
                                            return (
                                                <div
                                                    className="area-block-panorama-select area-block-panorama-select-preview"
                                                    key={index}
                                                    style={{
                                                        clipPath: `polygon(${block.area_points_string})`,
                                                    }}
                                                ></div>
                                            );
                                        }
                                    )}

                                    {getStateApartments.getApartmentForEdit
                                        .points &&
                                    getStateApartments.getApartmentForEdit
                                        .points.length ? (
                                        getStateApartments.getApartmentForEdit.points.map(
                                            (point, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`icon-point-preview-position icon-point-preview icon-point-preview-${point.icon}`}
                                                        style={{
                                                            top: `${point.coords.y}%`,
                                                            left: `${point.coords.x}%`,
                                                        }}
                                                    >
                                                        <div className="point-title">
                                                            <p>{point.title}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                        </section>
                    ) : (
                        <section className="blocks-content-visual">
                            <div className="main-block-with-pamorama">
                                <div
                                    id="main-photo-panorama"
                                    className="main-photo-panorama"
                                >
                                    {viewBlock ? (
                                        <div
                                            id="area-block-panorama-select"
                                            className="area-block-panorama-select-background area-block-panorama-select"
                                            style={{
                                                clipPath: `polygon(${viewBlock.area_points_string})`,
                                            }}
                                        ></div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {viewPoint ? (
                                        <div
                                            className={`icon-point-preview-position icon-point-preview icon-point-preview-${viewPoint.icon}`}
                                            style={{
                                                top: `${viewPoint.coords.y}%`,
                                                left: `${viewPoint.coords.x}%`,
                                            }}
                                        >
                                            <div className="point-title point-title-view">
                                                <p>{viewPoint.title}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {singlePointIcon ? (
                                        <div
                                            className={`icon-point-preview-position icon-point-preview icon-point-preview-${singlePointIcon}`}
                                            style={{
                                                top: `${coordsSinglePoint.y}%`,
                                                left: `${coordsSinglePoint.x}%`,
                                            }}
                                        ></div>
                                    ) : statusSinglePoint &&
                                      coordsSinglePoint ? (
                                        <div
                                            className="coord-point-block-with-icons"
                                            style={{
                                                top: `${coordsSinglePoint.y}%`,
                                                left: `${coordsSinglePoint.x}%`,
                                            }}
                                        >
                                            <div
                                                onClick={() => {
                                                    setSinglePointIcon('1');
                                                }}
                                                className="icon-point-preview icon-point-preview-1"
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    setSinglePointIcon('2');
                                                }}
                                                className="icon-point-preview icon-point-preview-2"
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    setSinglePointIcon('3');
                                                }}
                                                className="icon-point-preview icon-point-preview-3"
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    setSinglePointIcon('4');
                                                }}
                                                className="icon-point-preview icon-point-preview-4"
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    setSinglePointIcon('5');
                                                }}
                                                className="icon-point-preview icon-point-preview-5"
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    setSinglePointIcon('6');
                                                }}
                                                className="icon-point-preview icon-point-preview-6"
                                            ></div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    <div
                                        className="event-points-block"
                                        onClick={(event) => {
                                            if (
                                                statusAreaBlock ||
                                                statusEditAreaPointBlock
                                            ) {
                                                return addPoint(
                                                    event,
                                                    'default'
                                                );
                                            } else {
                                                return addSinglePoint(event);
                                            }
                                        }}
                                        onMouseMove={(e) => {
                                            if (
                                                statusAreaBlock ||
                                                statusEditAreaPointBlock
                                            ) {
                                                return moveMousePoint(e);
                                            }
                                        }}
                                    ></div>

                                    {getStateApartments.getApartmentForEdit
                                        .image_complex_with_all_blocks ? (
                                        <img
                                            src={`${config.public}${getStateApartments.getApartmentForEdit.image_complex_with_all_blocks.url}`}
                                            alt="panorama"
                                        />
                                    ) : (
                                        <h5>
                                            Картинки не найдено, обновите
                                            информацию ЖК
                                        </h5>
                                    )}

                                    {statusAreaBlock &&
                                    blockAreas &&
                                    blockAreas.length ? (
                                        blockAreas.map((point, index) => {
                                            return (
                                                <div
                                                    onDoubleClick={() => {
                                                        return removePointFromAreas(
                                                            index
                                                        );
                                                    }}
                                                    ref={refPoint}
                                                    onMouseDown={() => {
                                                        setDownIndexPointStatus(
                                                            true
                                                        );
                                                        setDownIndexPoint(
                                                            index
                                                        );
                                                    }}
                                                    onMouseUp={() => {
                                                        setDownIndexPointStatus(
                                                            false
                                                        );
                                                        setDownIndexPoint(null);
                                                    }}
                                                    key={index}
                                                    className="dot-point-area"
                                                    style={{
                                                        left: `${point[0]}%`,
                                                        top: `${point[1]}%`,
                                                    }}
                                                ></div>
                                            );
                                        })
                                    ) : (
                                        <div></div>
                                    )}
                                    {
                                        //edit area pointa block
                                        statusEditAreaPointBlock &&
                                        editAreaPointBlock &&
                                        editAreaPointBlock.length ? (
                                            editAreaPointBlock.map(
                                                (point, index) => {
                                                    return (
                                                        <div
                                                            onDoubleClick={() => {
                                                                return removePointFromAreas(
                                                                    index
                                                                );
                                                            }}
                                                            ref={refPoint}
                                                            onMouseDown={() => {
                                                                setDownIndexPointStatus(
                                                                    true
                                                                );
                                                                setDownIndexPoint(
                                                                    index
                                                                );
                                                            }}
                                                            onMouseUp={() => {
                                                                setDownIndexPointStatus(
                                                                    false
                                                                );
                                                                setDownIndexPoint(
                                                                    null
                                                                );
                                                            }}
                                                            key={index}
                                                            className="dot-point-area"
                                                            style={{
                                                                left: `${point[0]}%`,
                                                                top: `${point[1]}%`,
                                                            }}
                                                        ></div>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div></div>
                                        )
                                    }

                                    {statusAreaBlock ? (
                                        <div
                                            onMouseMove={(e) => {
                                                if (statusAreaBlock) {
                                                    return moveMousePoint(e);
                                                }
                                            }}
                                            onClick={(event) => {
                                                if (statusAreaBlock) {
                                                    return addPoint(
                                                        event,
                                                        'area'
                                                    );
                                                }
                                            }}
                                            id="area-block-panorama-select"
                                            className="area-block-panorama-select-background area-block-panorama-select"
                                            style={{
                                                clipPath: `polygon(${areaPoints})`,
                                            }}
                                        ></div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {statusEditAreaPointBlock ? (
                                        <div
                                            onMouseMove={(e) => {
                                                if (statusEditAreaPointBlock) {
                                                    return moveMousePoint(e);
                                                }
                                            }}
                                            onClick={(event) => {
                                                if (statusEditAreaPointBlock) {
                                                    return addPoint(
                                                        event,
                                                        'area'
                                                    );
                                                }
                                            }}
                                            id="area-block-panorama-select"
                                            className="area-block-panorama-select-background area-block-panorama-select"
                                            style={{
                                                clipPath: `polygon(${editAreaPointBlockSTR})`,
                                            }}
                                        ></div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                        </section>
                    )}

                    {statusAreaBlock ? (
                        <div>
                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <TextField
                                    fullWidth
                                    onChange={(val) => {
                                        setTitle(val.target.value);
                                    }}
                                    id="standard-basic"
                                    label="Название блока*"
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    value={title}
                                    InputLabelProps={{
                                        style: { fontSize: 16 },
                                    }}
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <TextField
                                    fullWidth
                                    onChange={(val) => {
                                        setFloors(val.target.value);
                                    }}
                                    id="standard-basic"
                                    label="Количество этажей*"
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    value={floors}
                                    InputLabelProps={{
                                        style: { fontSize: 16 },
                                    }}
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <TextField
                                    fullWidth
                                    onChange={(val) => {
                                        setSeoKeywords(val.target.value);
                                    }}
                                    id="standard-basic"
                                    label="SEO Keywords"
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    value={seoKeywords}
                                    InputLabelProps={{
                                        style: { fontSize: 16 },
                                    }}
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <TextField
                                    fullWidth
                                    onChange={(val) => {
                                        setSeoTitle(val.target.value);
                                    }}
                                    id="standard-basic"
                                    label="SEO Title"
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    value={seoTitle}
                                    InputLabelProps={{
                                        style: { fontSize: 16 },
                                    }}
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <TextField
                                    fullWidth
                                    onChange={(val) => {
                                        setSeoDescription(val.target.value);
                                    }}
                                    id="standard-basic"
                                    label="SEO Description"
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    value={seoDescription}
                                    InputLabelProps={{
                                        style: { fontSize: 16 },
                                    }}
                                />
                            </section>

                            <section>
                                <Button
                                    variant="contained"
                                    onClick={addBlock.bind(undefined)}
                                >
                                    Добавить
                                </Button>
                            </section>
                        </div>
                    ) : statusSinglePoint ? (
                        <div>
                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <TextField
                                    fullWidth
                                    onChange={(val) => {
                                        setTitleSinglePoint(val.target.value);
                                    }}
                                    id="standard-basic"
                                    label="Название точки*"
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    value={titleSinglePoint}
                                    InputLabelProps={{
                                        style: { fontSize: 16 },
                                    }}
                                />
                            </section>

                            <section>
                                <Button
                                    variant="contained"
                                    onClick={addPointForBlock.bind(undefined)}
                                >
                                    Добавить
                                </Button>
                            </section>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <section className="tabs-blocks-and-block">
                        <span
                            className={tabSelect == 'BLOCKS' ? 'active' : ''}
                            onClick={() => {
                                setTabSelect('BLOCKS');
                            }}
                        >
                            Блоки
                        </span>
                        <span
                            className={tabSelect != 'BLOCKS' ? 'active' : ''}
                            onClick={() => {
                                setTabSelect('POINTS');
                            }}
                        >
                            Точки
                        </span>
                    </section>

                    {tabSelect == 'BLOCKS' ? (
                        <section className="list-block-main-block">
                            <h2>Добавленные блоки</h2>
                            {getStateApartments.getApartmentForEdit.blocks
                                .length > 0 ? (
                                getStateApartments.getApartmentForEdit.blocks.map(
                                    (block) => {
                                        return (
                                            <ItemBlock
                                                editPointPlan={(
                                                    areaPoints,
                                                    areaPointsSTR
                                                ) => {
                                                    setStatusEditAreaPointBlock(
                                                        true
                                                    );
                                                    setEditAreaPointBlock(
                                                        areaPoints
                                                    );
                                                    setEditAreaPointBlockSTR(
                                                        areaPointsSTR
                                                    );
                                                }}
                                                dispatch={dispatch}
                                                api={api}
                                                id={id}
                                                item={block}
                                                setViewBlock={(f) => {
                                                    setViewBlock(f);
                                                }}
                                                deleteBlock={(f) => {
                                                    deleteBlock(f);
                                                }}
                                                saveEditAreaPointBlock={(
                                                    blockIdForAreaPointEdit
                                                ) =>
                                                    saveEditAreaPointBlock(
                                                        blockIdForAreaPointEdit
                                                    )
                                                }
                                            />
                                        );
                                    }
                                )
                            ) : (
                                <h6>Информации нет</h6>
                            )}
                        </section>
                    ) : (
                        <div></div>
                    )}

                    {tabSelect != 'BLOCKS' ? (
                        <section
                            className="list-block-main-block"
                            style={{ marginBottom: 55 }}
                        >
                            <h2>Добавленные точки</h2>
                            {getStateApartments.getApartmentForEdit.points &&
                            getStateApartments.getApartmentForEdit.points
                                .length > 0 ? (
                                getStateApartments.getApartmentForEdit.points.map(
                                    (point) => {
                                        return (
                                            <div
                                                onMouseEnter={() =>
                                                    setViewPoint(point)
                                                }
                                                onMouseLeave={() =>
                                                    setViewPoint(null)
                                                }
                                                className="block-list-item"
                                            >
                                                <p>{point.title}</p>
                                                <div>
                                                    <span
                                                        onClick={() => {
                                                            deletePoint(
                                                                point._id
                                                            );
                                                        }}
                                                        title="Удалить"
                                                        className="block-icon-remove"
                                                    >
                                                        <DeleteForever />
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }
                                )
                            ) : (
                                <h6>Информации нет</h6>
                            )}
                        </section>
                    ) : (
                        <div></div>
                    )}
                </content>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default CreateBlocks;
