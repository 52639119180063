import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services/';
import styles from '../styles/index.module.scss';
import { Input, Button, Checkbox, Select } from 'antd';

const { Option } = Select;

let configSun = {
    height: 401,
    buttonList: [
        [
            'undo',
            'redo',
            'font',
            'fontSize',
            'formatBlock',
            'paragraphStyle',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'textStyle',
            'removeFormat',
            'outdent',
            'indent',
            'align',
            'horizontalRule',
            'list',
            'lineHeight',
            'table',
            'link',
            'image',
            'fullScreen',
            'showBlocks',
            'codeView',
            'preview',
        ],
    ],
};

const CreateBlog = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const editorRef = useRef();

    const [title, setTitle] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [metaKeyWords, setMetaKeyWords] = useState('');
    const [realestate, set_realestate] = useState([]);
    const [promotion, set_promotion] = useState(false);

    const [toggleCodeView, setToggleCodeView] = useState(false);

    const realestateList = useSelector(
        (state) => state?.flats?.complexApartments
    );

    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    useEffect(() => {
        dispatch(api.flats.getComplexAll());
    }, []);

    const handleImageUploadBefore = (files, info, uploadHandler) => {
        (async () => {
            let imgSrc = await dispatch(
                api.files.uploadFile(files[0], 'IMAGE_FOR_POSTS')
            );

            const response = {
                result: [
                    {
                        url: imgSrc?.full_url,
                        name: files[0].name,
                        size: files[0].size,
                    },
                ],
            };

            uploadHandler(response);
        })();

        uploadHandler();
    };

    const createPost = () => {
        if (toggleCodeView) {
            editorRef.current.core.toggleCodeView();
        }

        //let edR = editorRef.current.editor.core.context.element.wysiwyg;

        dispatch(
            api.blog.create(
                {
                    title,
                    metaTitle,
                    metaDesc,
                    metaKeyWords,
                    content: editorRef.current.getContents(),
                    category: 'POPULAR',
                    realestate,
                    promotion,
                },
                history
            )
        );
    };

    return (
        <content className={`page-editor-block ${styles.blockEditor}`}>
            <h1 className="title-page">Создание поста</h1>

            <Input
                size="large"
                className="form-page-input"
                type="text"
                placeholder="Заголовок"
                onChange={(val) => {
                    setTitle(val.target.value);
                }}
            />

            <SunEditor
                getSunEditorInstance={getSunEditorInstance}
                onImageUploadBefore={handleImageUploadBefore}
                setOptions={configSun}
                toggleCodeView={(statusCodeView) =>
                    setToggleCodeView(statusCodeView)
                }
            />

            <div className="meta-seo-page-block">
                <h5>Seo</h5>
                <Input
                    size="large"
                    className="form-page-input"
                    type="text"
                    onChange={(val) => {
                        setMetaTitle(val.target.value);
                    }}
                    placeholder="Meta title"
                />
                <Input
                    size="large"
                    className="form-page-input"
                    type="text"
                    onChange={(val) => {
                        setMetaDesc(val.target.value);
                    }}
                    placeholder="Meta description"
                />
                <Input
                    size="large"
                    className="form-page-input"
                    type="text"
                    onChange={(val) => {
                        setMetaKeyWords(val.target.value);
                    }}
                    placeholder="Meta keywords"
                />
            </div>

            <div className="meta-seo-page-block">
                <Checkbox
                    checked={promotion}
                    onChange={(val) => set_promotion(val.target.checked)}
                >
                    Акция
                </Checkbox>
            </div>

            <div className="meta-seo-page-block">
                <h5>Связанные жилые комплексы</h5>
                <Select
                    onChange={(val) => set_realestate(val)}
                    size="large"
                    value={realestate}
                    placeholder="Выберите жк"
                    mode="multiple"
                    className={styles.selectStyle}
                    dropdownStyle={{
                        zIndex: 9999999999,
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {realestateList && realestateList.length ? (
                        realestateList.map((item) => (
                            <Option value={item?._id} key={item?._id}>
                                {item?.title}
                            </Option>
                        ))
                    ) : (
                        <></>
                    )}
                </Select>
            </div>

            <Button type="primary" onClick={createPost}>
                Создать
            </Button>
        </content>
    );
};

export default CreateBlog;
