"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeatmapLayer = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var l7_layers_1 = require("@antv/l7-layers");
var plot_layer_1 = require("../../core/layer/plot-layer");
var adaptor_1 = require("./adaptor");
var DEFAULT_OPTIONS = {
    source: {
        data: [],
        parser: {
            type: 'json',
            x: 'x',
            y: 'y',
        },
    },
    shape: 'heatmap',
    size: {
        value: [0, 1],
    },
    style: {
        intensity: 3,
        radius: 20,
        opacity: 1,
        colorsRamp: [
            { color: 'rgba(33,102,172,0.0)', position: 0 },
            { color: 'rgb(103,169,207)', position: 0.2 },
            { color: 'rgb(209,229,240)', position: 0.4 },
            { color: 'rgb(253,219,199)', position: 0.6 },
            { color: 'rgb(239,138,98)', position: 0.8 },
            { color: 'rgb(178,24,43,1.0)', position: 1 },
        ],
    },
};
var LAYER_OPTIONS_KEYS = ['shape', 'color', 'size', 'style', 'state'];
var HeatmapLayer = /** @class */ (function (_super) {
    tslib_1.__extends(HeatmapLayer, _super);
    function HeatmapLayer(options) {
        var _this = _super.call(this, options) || this;
        /**
         * 图层类型
         */
        _this.type = plot_layer_1.PlotLayer.LayerType.HeatmapLayer;
        /**
         * 图层是否具有交互属性
         */
        _this.interaction = false;
        var _a = _this.options, name = _a.name, source = _a.source;
        var config = _this.pickLayerConfig(_this.options);
        _this.name = name ? name : (0, util_1.uniqueId)(_this.type);
        _this.layer = new l7_layers_1.HeatmapLayer(tslib_1.__assign(tslib_1.__assign({}, config), { name: _this.name }));
        _this.mappingLayer(_this.layer, _this.options);
        _this.setSource(source);
        return _this;
    }
    /**
     * 获取默认配置
     */
    HeatmapLayer.prototype.getDefaultOptions = function () {
        return DEFAULT_OPTIONS;
    };
    HeatmapLayer.prototype.mappingLayer = function (layer, options) {
        (0, adaptor_1.mappingLayer)(layer, options);
    };
    HeatmapLayer.prototype.update = function (options) {
        _super.prototype.update.call(this, options);
        this.mappingLayer(this.layer, this.options);
    };
    /**
     * 默认配置项
     */
    HeatmapLayer.DefaultOptions = DEFAULT_OPTIONS;
    /**
     * 图层配置项 Keys
     */
    HeatmapLayer.LayerOptionsKeys = plot_layer_1.PlotLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return HeatmapLayer;
}(plot_layer_1.PlotLayer));
exports.HeatmapLayer = HeatmapLayer;
