import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from 'antd';

const Prices = ({ api }) => {
    const [status, setStatus] = useState(false);
    const [standart, setStandart] = useState('');
    const [business, setBusiness] = useState('');
    const [comfort, setComfort] = useState('');
    const [premium, setPremium] = useState('');
    const dispatch = useDispatch();

    const getSettings = useSelector((state) => state.statistic.settings);

    useEffect(() => {
        updatePrices();
    }, [getSettings]);

    const updatePrices = () => {
        if (getSettings && getSettings.length) {
            getSettings.map((item) => {
                if (item.type == 'COMFORT') {
                    setComfort(item.value);
                } else if (item.type == 'BUSINESS') {
                    setBusiness(item.value);
                } else if (item.type == 'PREMIUM') {
                    setPremium(item.value);
                } else if (item.type == 'STANDART') {
                    setStandart(item.value);
                }
            });
        }
    };

    const saveSettingPrices = () => {
        let fileds = {
            standart,
            business,
            comfort,
            premium,
        };

        return dispatch(api.statistic.saveSettingPrices(fileds));
    };

    return (
        <div className="section-settings-block">
            <div className="section-settings-block-head">
                <h3>Средние цены на жилье</h3>
                <Button
                    type="primary"
                    onClick={() => {
                        setStatus(!status);
                    }}
                >
                    {status ? 'Скрыть' : 'Показать'}
                </Button>
            </div>

            {status ? (
                <div className="section-settings-block-body">
                    <div className="setting-block">
                        <p>Стандарт</p>
                        <div className="setting-block-input">
                            <Input
                                size="large"
                                onChange={(val) => {
                                    setStandart(val.target.value);
                                }}
                                type="text"
                                value={standart}
                            />
                            <h5>тг.</h5>
                        </div>
                    </div>
                    <div className="setting-block">
                        <p>Комфорт</p>
                        <div className="setting-block-input">
                            <Input
                                size="large"
                                onChange={(val) => {
                                    setComfort(val.target.value);
                                }}
                                type="text"
                                value={comfort}
                            />
                            <h5>тг.</h5>
                        </div>
                    </div>
                    <div className="setting-block">
                        <p>Бизнес</p>
                        <div className="setting-block-input">
                            <Input
                                size="large"
                                onChange={(val) => {
                                    setBusiness(val.target.value);
                                }}
                                type="text"
                                value={business}
                            />
                            <h5>тг.</h5>
                        </div>
                    </div>
                    <div className="setting-block">
                        <p>Премиум</p>
                        <div className="setting-block-input">
                            <Input
                                size="large"
                                onChange={(val) => {
                                    setPremium(val.target.value);
                                }}
                                type="text"
                                value={premium}
                            />
                            <h5>тг.</h5>
                        </div>
                    </div>
                    <Button onClick={saveSettingPrices.bind(undefined)}>
                        Сохранить
                    </Button>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default Prices;
