import Budget from '@/Containers/Admin/Budget';
import CreateBudget from '@/Containers/Admin/Budget/Create';
import BudgetCategories from '@/Containers/Admin/Budget/Categories';
import BudgetEdit from '@/Containers/Admin/Budget/Edit';
import GroupsCategories from '@/Containers/Admin/Budget/Categories/Helpers/Groups';
import EditBudgetGroupCategories from '@/Containers/Admin/Budget/Categories/Helpers/Groups/Edit';

export default [
    {
        path: '/budget',
        element: <Budget />,
    },
    {
        path: '/budget/create',
        element: <CreateBudget />,
    },
    {
        path: '/budget/categories',
        element: <BudgetCategories />,
    },
    {
        path: '/budget/categories/groups',
        element: <GroupsCategories />,
    },
    {
        path: '/budget/categories/groups/:id/:template_id?',
        element: <EditBudgetGroupCategories />,
    },
    {
        path: '/budget/edit/:id',
        element: <BudgetEdit />,
    },
];
