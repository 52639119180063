import React from 'react';
import { Result } from 'antd';

const UserAvatar = () => {

    return (
        <div>
            <Result
                status="500"
                title="В разработке"
                subTitle="Функция будет доступна позже"
                extra={null}
            />
        </div>
    )

}

export default UserAvatar;