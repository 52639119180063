import React, { useEffect, useState } from 'react';
import styles from '../../styles/index.module.scss';
import { Button, Input, Switch, TreeSelect } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import config from '@/Config';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const EditCategory360 = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const typeForm = query.get('type'); //shop

    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const stateLibrary = useSelector((state) => state.library);
    const stateFiles = useSelector((state) => state.files);
    const categories =
        typeForm == 'shop'
            ? stateLibrary?.shop_categories || null
            : stateLibrary?.tree_categories || null;

    const [title, set_title] = useState('');
    const [sub_category, set_sub_category] = useState(false);
    const [select_category, set_select_category] = useState(null);

    useEffect(() => {
        let typeRequest = typeForm == 'shop' ? 'SHOP' : '';

        dispatch(api.library.treeCategories(typeRequest));
        dispatch(api.library.getCategoryById(params?.id, typeRequest));
    }, []);

    useEffect(() => {
        set_title(stateLibrary?.category_edit_item?.title);

        if (stateLibrary?.category_edit_item?.type == 'SUB') {
            set_sub_category(true);
            set_select_category(
                stateLibrary?.category_edit_item?.parent_category?._id
            );
        } else {
            set_sub_category(false);
            set_select_category(null);
        }

        if (stateLibrary?.category_edit_item?.icon) {
            dispatch({
                type: 'APARTMENT_PREVIEW_PHOTO',
                photo: {
                    id: stateLibrary?.category_edit_item?.icon?._id,
                    url: stateLibrary?.category_edit_item?.icon?.url,
                    full_url: stateLibrary?.category_edit_item?.icon?.url,
                },
            });
        }
    }, [stateLibrary?.category_edit_item]);

    const editCategory = () => {
        dispatch(
            api.library.editCategory(
                {
                    title,
                    file: stateFiles.PHOTO_PREVIEW,
                    id: params?.id,
                    sub: sub_category,
                    select_category,
                    typeForm,
                },
                history
            )
        );
    };

    return (
        <div className={styles.mainBlock}>
            <div className={styles.header}>
                <h2 className="title-page">Форма редактирования категории</h2>
            </div>

            <div className={styles.pageCategories}>
                <div className={styles.sectionForm}>
                    <Input
                        size="large"
                        value={title}
                        onChange={(value) => set_title(value.target.value)}
                        placeholder="Название категории"
                    />
                </div>

                <div className={styles.sectionForm}>
                    <div className={styles.optionForm}>
                        <div className={styles.optionFormSwitch}>
                            <p>Подкатегория</p>
                            <Switch
                                size="small"
                                defaultChecked={sub_category}
                                checked={sub_category}
                                onChange={(value) => {
                                    set_sub_category(value);
                                    set_select_category(null);
                                }}
                            />
                        </div>

                        <div className={styles.optionFormSelect}>
                            {sub_category ? (
                                categories && categories.length ? (
                                    <TreeSelect
                                        virtual={false}
                                        size="large"
                                        treeDataSimpleMode
                                        style={{
                                            width: '100%',
                                        }}
                                        value={select_category}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                        }}
                                        placeholder="Выберите категорию"
                                        onChange={(value) =>
                                            set_select_category(value)
                                        }
                                        treeData={categories || []}
                                    />
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>

                <div className={styles.sectionForm}>
                    <div className={styles.optionForm}>
                        {stateFiles?.request_upload_status ? (
                            <Button type="primary" className={styles.button}>
                                <LoadingOutlined />
                            </Button>
                        ) : (
                            <Button type="primary" className={styles.button}>
                                Загрузить картинку
                                <input
                                    type="file"
                                    onChange={(val) =>
                                        dispatch(
                                            api.files.uploadFile(
                                                val.target.files[0],
                                                'IMAGE_PREVIEW'
                                            )
                                        )
                                    }
                                />
                            </Button>
                        )}

                        {stateFiles?.PHOTO_PREVIEW ? (
                            <div
                                className={styles.imageBlock}
                                style={{
                                    background: `url(${config.public}${stateFiles?.PHOTO_PREVIEW?.url}) center / cover no-repeat`,
                                }}
                            ></div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <Button
                    type="primary"
                    className={styles.button}
                    onClick={editCategory}
                >
                    Редактировать
                </Button>
            </div>
        </div>
    );
};

export default EditCategory360;
