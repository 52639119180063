const initialState = {
	list:null,
	page_by_id:null,
	
	request_status:false
}

const posts = (state = initialState, action) => {
	switch(action.type){

		case 'UPDATE_POSTS' :
			return {
				...state,
				list:action.data
			}

		case 'UPDATE_POST_BY_ID' :
			return {
				...state,
				page_by_id:action.data
			}

		case 'REQUEST_STATUS_POSTS' :
			return {
				...state,
				request_status:action.status
			}

		default :
			return state
	}
}

export default posts;