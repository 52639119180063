import React, {useRef, useState} from 'react';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

let configSun = {
    height: 800,
    buttonList: [
        ['undo', 'redo',
        'font', 'fontSize', 'formatBlock',
        'paragraphStyle', 'blockquote',
        'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
        'fontColor', 'hiliteColor', 'textStyle',
        'removeFormat',
        'outdent', 'indent',
        'align', 'horizontalRule', 'list', 'lineHeight',
        'table', 'link', 'image', /** 'video', 'audio' 'math', */ // You must add the 'katex' library at options to use the 'math' plugin.
        /** 'imageGallery', */ // You must add the "imageGalleryUrl".
        'fullScreen', 'showBlocks', 'codeView',
        'preview'] //'print', 'save'
    ]
};

const EditPageForm = ({
	item,
	api,
	dispatch,
	builders,
	history,
	id
}) => {

	const editorRef = useRef();

	const [builder, setBuilder] = useState(item && item.builder ? item.builder : '');
	const [title, setTitle] = useState(item ? item.title : '');
	const [metaTitle, setMetaTitle] = useState(item ? item.meta_title : '');
	const [metaDesc, setMetaDesc] = useState(item ? item.meta_desc : '');
	const [metaKeyWords, setMetaKeyWords] = useState(item ? item.meta_keywords : '');
	const [content, setContent] = useState(item ? item.content : '');

  const [toggleCodeView, setToggleCodeView] = React.useState(false);

	const handleImageUploadBefore = (files, info, uploadHandler) => {

		(async () => {

			let imgSrc = await dispatch(api.files.uploadFile(files[0], 'IMAGE_FOR_POSTS'));

			const response = {
	            "result": [
	                {
	                    "url": imgSrc,
	                    "name": files[0].name,
	                    "size": files[0].size
	                },
	        ]}
	        
	        uploadHandler(response)

		})();

		uploadHandler();

	}

	const editPost = () => {
		//let edR = editorRef.current.editor.core.context.element.wysiwyg;

    if(toggleCodeView){
      editorRef.current.core.toggleCodeView()
    }

		dispatch(api.pages.update({
			builder,
			title,
			metaTitle,
			metaDesc,
			metaKeyWords,
			content:editorRef.current.getContents(),
			category:'PAGE'
		}, history, id));

		
	}

  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

	return (
		<div>
			<input type="text" className="form-page-input" placeholder="Заголовок" value={title} onChange={(val)=>{
				setTitle(val.target.value);
			}} />

			<SunEditor
        getSunEditorInstance={getSunEditorInstance}
				onImageUploadBefore={handleImageUploadBefore}
				setOptions={configSun}
				defaultValue={content}
				onChange={(val)=>setContent(val)}
        toggleCodeView={(statusCodeView)=>setToggleCodeView(statusCodeView)}
			/>

			<div className="meta-seo-page-block">
				<h5>Seo</h5>

				<p>Meta title</p>
				<input type="text" className="form-page-input" value={metaTitle} onChange={(val)=>{
					setMetaTitle(val.target.value);
				}} placeholder="Meta title" />

				<p>Meta description</p>
				<input type="text" className="form-page-input" value={metaDesc} onChange={(val)=>{
					setMetaDesc(val.target.value);
				}} placeholder="Meta description" />

				<p>Meta keywords</p>
				<input type="text" className="form-page-input" value={metaKeyWords} onChange={(val)=>{
					setMetaKeyWords(val.target.value);
				}} placeholder="Meta keywords" />
			</div>

			<div className="meta-seo-page-block">
				<h5>Выберите застройщика (необязательно)</h5>

				<select value={builder} onChange={(val)=>{
					setBuilder(val.target.value);
					
				}}>
					<option value=''>Список застройщиков</option>
					{
						builders.map((item, index)=>{
							return <option key={index} value={item._id}>{ item.title }</option>
						})
					}
				</select>
				
			</div>

			<input type="submit" className="form-page-input" onClick={editPost} value="Редактировать"/>
		</div>
	)
}

export default EditPageForm;