import React from 'react';
import { Form, Button, Typography } from 'antd';
import MaskedInput from 'antd-mask-input';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styles from '../../../styles/index.module.scss';

const { Text } = Typography;

const BuilderFormItemPhones = () => {

    return (
        <div className={styles.formSectionGroup}>

            <Text type="secondary">Телефонные номера застройщика</Text>

            <Form.Item
                name="phones"
            >
                <Form.List name="phones">
                    {
                        (fields, { add, remove }) => (
                            <>
                                {
                                    fields.map((field)=>(
                                        <div 
                                            key={field?.key}
                                            className={styles.listPhoneItem}
                                        >
                                            <Form.Item
                                                name={[field.name]}
                                                rules={[
                                                    {
                                                    required: true,
                                                    message: 'Пожалуйста, заполните поле',
                                                    },
                                                ]}
                                            >
                                                <MaskedInput
                                                    size='large'
                                                    mask="+7 (000) 000 00 00"
                                                    placeholder="Телефон"
                                                />
                                            </Form.Item>

                                            <Button onClick={() => remove(field.name)} type="link" danger size="small">
                                                <MinusCircleOutlined />
                                            </Button>

                                        </div> 
                                    ))
                                }

                                <Form.Item style={{margin: 0}}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Добавить телефон
                                    </Button>
                                </Form.Item>

                            </>
                        )
                    }
                </Form.List>
            </Form.Item>
        </div>
    )
}

export default BuilderFormItemPhones;