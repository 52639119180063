import React, { createRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { Autorenew, Delete } from '@material-ui/icons';
import { Map, Placemark, YMaps } from 'react-yandex-maps';
import { useDispatch, useSelector } from 'react-redux';
import { api, formatBytes } from '../../../../../Services';
import Editor from '../../Edit/Helpers/editor';
import styles from '../../styles/index.module.scss';
import keyRnd from 'crypto-random-string';
import {
    Button,
    DatePicker,
    Input,
    Popover,
    Select,
    Collapse,
    Form,
    notification,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
    CheckCircleOutlined,
    LoadingOutlined,
    //UpOutlined,
} from '@ant-design/icons';
import keyRandom from 'crypto-random-string';
import config from '../../../../../Config';
const { Panel } = Collapse;

notification.config({
    placement: 'topRight',
    tio: 150,
    duration: 10,
});

const ApartmentСomplex = ({ type_content, type }) => {
    const history = useNavigate();
    const [document_status_upload, set_document_status_upload] =
        useState(false);
    const [documents, set_documents] = useState([]);
    const [deliveryTime, setDeliveryTime] = useState('');
    const [seo_content, set_seo_content] = useState('');

    const dispatch = useDispatch();
    const formRef = createRef();

    const addRealestate = (values) => {
        let fields = {
            ...values,
            number: String(values?.number),
            type_content,
            type_realestate: type,
        };

        return dispatch(api.apartments.addApartment(fields, history));
    };

    useEffect(() => {
        formRef.current.setFieldsValue({
            seo_content,
        });
    }, [seo_content]);

    useEffect(() => {
        dispatch({
            type: 'PHOTO_APARTMENT_COMPLEX',
            photo: null,
        });

        dispatch({
            type: 'REQUEST_APARTMENTS_STATUS',
            status: false,
        });

        dispatch({
            type: 'APARTMENT_INTERIOR_ARRAY',
            photos: null,
        });

        dispatch({
            type: 'PHOTO_COMPLEX_WITH_BLOCKS',
            photo_apartment_complex_with_blocks: null,
        });

        dispatch(api.cities.getCities());
        dispatch(api.builders.getBuilders(null, null, null, 'all'));
        dispatch(api.builders.getAllObjects());
        dispatch(api.statistic.getMortgage());
        dispatch(api.statistic.settings());

        formRef.current.setFieldsValue({
            type_form: 'COMPLEX',
        });
    }, []);

    let getCities = useSelector((state) => state.cities.cities);
    let getBuilders = useSelector((state) => state.builders.builders);
    let getObjects = useSelector((state) => state.apartments.objects);

    const getApartmentsState = useSelector((state) => state.apartments);
    const getFilesState = useSelector((state) => state.files);
    const getMortgage = useSelector((state) => state.statistic.mortgage);
    const getStatePrograms = useSelector((state) =>
        (state?.statistic?.settings || []).filter(
            (item) => item.type == 'STATE_PROGRAMS'
        )
    );
    const [coords, setCoords] = React.useState(null);
    const [districts, set_districts] = React.useState([]);
    const [select_districts, set_select_districts] = React.useState([]);

    const handleChangeCity = (value) => {
        let distArr = [];
        let distSubArr = [];

        let getCity = getCities.filter(
            (item) => String(item?._id) === String(value)
        );

        if (
            getCity.length &&
            getCity[0].districts &&
            getCity[0].districts.length
        ) {
            getCity[0].districts.map((item) => {
                distArr.push(item);
                if (item?.districts && item?.districts.length) {
                    item?.districts.map((subItem) => {
                        distSubArr.push(subItem);
                    });
                }
            });
        }

        set_districts(distSubArr.length ? distSubArr : distArr);
        set_select_districts([]);

        formRef.current.setFieldsValue({
            city: value,
        });
    };

    const handleChangeMortgage = (value) => {
        formRef.current.setFieldsValue({
            mortgage: value,
        });
    };

    const handleHousingClass = (value) => {
        formRef.current.setFieldsValue({
            housing_class: value,
        });
    };

    const handleChangeBuilder = (value) => {
        formRef.current.setFieldsValue({
            builder: value,
        });
    };

    const handleDropBlocks = (files) => {
        dispatch(
            api.files.uploadFile(files[0], 'images-complex-with-all-blocks')
        );
    };

    const uploadDocument = async (val) => {
        set_document_status_upload(true);
        let file = await api.files.uploadDocument(val.target.files[0]);
        let newDocument = [...documents, file];
        set_document_status_upload(false);

        set_documents(newDocument);
    };

    useEffect(() => {
        formRef.current.setFieldsValue({
            documents,
        });
    }, [documents]);

    useEffect(() => {
        formRef.current.setFieldsValue({
            photos: getFilesState.APARTMENT_INTERIOR,
        });
    }, [getFilesState.APARTMENT_INTERIOR]);

    useEffect(() => {
        formRef.current.setFieldsValue({
            image_complex_with_all_blocks:
                getFilesState.photo_apartment_complex_with_blocks,
        });
    }, [getFilesState.photo_apartment_complex_with_blocks]);

    useEffect(() => {
        formRef.current.setFieldsValue({
            photo: getFilesState.photo_apartment_complex,
        });
    }, [getFilesState.photo_apartment_complex]);

    const changeMainPhoto = (selectPhoto) => {
        dispatch(api.files.changePhoto(selectPhoto));
    };

    const formErrors = (errors) => {
        let errorText = '';

        errors.errorFields.map((err) => {
            errorText += `${err?.errors[0]} \n`;
        });

        notification['error']({
            message: 'Не все поля были заполнены.',
            description: errorText,
        });
    };

    return (
        <div className={`ApartmentСomplex ${styles.blockRealEstate}`}>
            <Form
                name="basic"
                onFinish={addRealestate}
                onFinishFailed={formErrors}
                autoComplete="off"
                ref={formRef}
            >
                <section
                    className={`input-section-form ${styles.inputSectionForm}`}
                >
                    <Popover placement="bottom" content="Тип ЖК" title="">
                        <Form.Item
                            label=""
                            name="type_form"
                            rules={[
                                {
                                    required: true,
                                    message: 'Выберите тип жк',
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Select
                                size="large"
                                onChange={(value) => {
                                    formRef.current.setFieldsValue({
                                        type_form: value,
                                    });
                                }}
                                className={styles.selectInput}
                                placeholder="Тип ЖК"
                            >
                                <Option
                                    style={{ fontSize: 17 }}
                                    value="COMPLEX"
                                >
                                    Жилой комплекс
                                </Option>
                                <Option
                                    style={{ fontSize: 17 }}
                                    value="REAL_ESTATE_HOUSE"
                                >
                                    недвижимость «Дом»
                                </Option>
                                <Option
                                    style={{ fontSize: 17 }}
                                    value="COMMERCIAL"
                                >
                                    Коммерческая недвижимость
                                </Option>
                            </Select>
                        </Form.Item>
                    </Popover>
                </section>

                {type === 'queue' ? (
                    <div className={styles.groupInputs}>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Popover content="Выберите жк" title="">
                                <Form.Item
                                    label=""
                                    name="object"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Выберите жк',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    <Select
                                        showArrow
                                        showSearch
                                        size="large"
                                        onChange={(value) => {
                                            formRef.current.setFieldsValue({
                                                object: value,
                                            });
                                        }}
                                        className={styles.formSelect}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children
                                                .toLowerCase()
                                                .localeCompare(
                                                    optionB.children.toLowerCase()
                                                )
                                        }
                                        placeholder="Выберите жк"
                                    >
                                        {getObjects ? (
                                            getObjects.map((item) => {
                                                return (
                                                    <Option
                                                        key={item?._id}
                                                        style={{ fontSize: 17 }}
                                                        value={item._id}
                                                    >
                                                        {item.title}
                                                    </Option>
                                                );
                                            })
                                        ) : (
                                            <Option
                                                style={{ fontSize: 17 }}
                                                value=""
                                            >
                                                Жк не найдено.
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Popover>
                        </section>
                        <section
                            className={`input-section-form ${styles.sectionFormFlex}`}
                        >
                            <Popover content="Наименование очереди" title="">
                                <Form.Item
                                    label=""
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Заполните наименование очереди',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                title: val.target.value,
                                            });
                                        }}
                                        placeholder="Наименование очереди"
                                    />
                                </Form.Item>
                            </Popover>
                        </section>

                        <section
                            style={{ margin: 0 }}
                            className={`input-section-form ${styles.sectionFormFlex}`}
                        >
                            <Popover content="Номер очереди" title="">
                                <Form.Item
                                    label=""
                                    name="number"
                                    rules={[
                                        {
                                            required: true,
                                            pattern: new RegExp(/^[0-9 -]*$/),
                                            message:
                                                'Заполните номер очереди (Валидация поля: цифры и знак тире)',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                number: val.target.value,
                                            });
                                        }}
                                        placeholder="Номер очереди"
                                    />
                                </Form.Item>
                            </Popover>
                        </section>
                    </div>
                ) : (
                    <></>
                )}

                {type === 'realestate' ? (
                    <div className={styles.groupInputs}>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Popover content="Наименование объекта" title="">
                                <Form.Item
                                    label=""
                                    name="object_title"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Заполните наименование объекта',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                object_title: val.target.value,
                                            });
                                        }}
                                        placeholder="наименование объекта*"
                                    />
                                </Form.Item>
                            </Popover>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Popover content="Наименование жк" title="">
                                <Form.Item
                                    label=""
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Заполните наименование жк',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                title: val.target.value,
                                            });
                                        }}
                                        placeholder="наименование жк*"
                                    />
                                </Form.Item>
                            </Popover>
                        </section>
                    </div>
                ) : (
                    <></>
                )}

                <div className={styles.groupInputs}>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Popover content="Адрес ЖК" title="">
                            <Form.Item
                                label=""
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Заполните адрес жк',
                                    },
                                ]}
                                className={styles.formItemStyle}
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        formRef.current.setFieldsValue({
                                            address: val.target.value,
                                        });
                                    }}
                                    placeholder="Адрес ЖК*"
                                />
                            </Form.Item>
                        </Popover>
                    </section>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Popover content="Выберите город" title="">
                            <Form.Item
                                label=""
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Выберите город',
                                    },
                                ]}
                                className={styles.formItemStyle}
                            >
                                <Select
                                    size="large"
                                    onChange={handleChangeCity}
                                    className={styles.formSelect}
                                    placeholder="Выберите город"
                                >
                                    {getCities ? (
                                        getCities.map((item) => {
                                            return (
                                                <Option
                                                    key={keyRandom({
                                                        length: 15,
                                                    })}
                                                    style={{ fontSize: 17 }}
                                                    value={item._id}
                                                >
                                                    {item.title}
                                                </Option>
                                            );
                                        })
                                    ) : (
                                        <Option
                                            style={{ fontSize: 17 }}
                                            value=""
                                        >
                                            Городов не найдено. Добавьте в
                                            разделе "Города"
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Popover>
                    </section>

                    {districts && districts.length ? (
                        <section
                            style={{ margin: 0 }}
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Popover content="Выберите район" title="">
                                <Form.Item
                                    label=""
                                    name="districts"
                                    className={styles.formItemStyle}
                                >
                                    <Select
                                        mode="multiple"
                                        size="large"
                                        onChange={(value) => {
                                            formRef.current.setFieldsValue({
                                                districts: value,
                                            });
                                            set_select_districts(value);
                                        }}
                                        value={select_districts}
                                        placeholder="Выберите район"
                                        className={styles.formSelect}
                                    >
                                        {districts.map((item) => {
                                            return (
                                                <Option
                                                    key={keyRandom({
                                                        length: 15,
                                                    })}
                                                    style={{ fontSize: 17 }}
                                                    value={item._id}
                                                >
                                                    {item.title}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Popover>
                        </section>
                    ) : (
                        <></>
                    )}
                </div>

                <div className={styles.groupInputs}>
                    <section
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Popover content="Выберите застройщика" title="">
                            <Form.Item
                                label=""
                                name="builder"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Выберите застройщика',
                                    },
                                ]}
                                className={styles.formItemStyle}
                            >
                                <Select
                                    showSearch
                                    size="large"
                                    className={styles.formSelect}
                                    onChange={handleChangeBuilder}
                                    placeholder="Выберите застройщика"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={getBuilders && getBuilders.length ? getBuilders.map((item)=>({
                                        label: item?.title,
                                        value: item?._id
                                    })) : []}
                                />
                            </Form.Item>
                        </Popover>
                    </section>
                    <section
                        style={{ margin: 0 }}
                        className={`input-section-form ${styles.inputSectionForm}`}
                    >
                        <Form.Item
                            label=""
                            name="housing_class"
                            rules={[
                                {
                                    required: true,
                                    message: 'Выберите класс жилья',
                                },
                            ]}
                            className={styles.formItemStyle}
                        >
                            <Popover content="Класс жилья" title="">
                                <Select
                                    size="large"
                                    className={styles.formSelect}
                                    onChange={handleHousingClass}
                                    placeholder="Выберите класс жилья"
                                >
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="STANDART"
                                    >
                                        СТАНДАРТ
                                    </Option>
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="COMFORT"
                                    >
                                        КОМФОРТ
                                    </Option>
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="BUSINESS"
                                    >
                                        БИЗНЕС
                                    </Option>
                                    <Option
                                        style={{ fontSize: 17 }}
                                        value="PREMIUM"
                                    >
                                        ПРЕМИУМ
                                    </Option>
                                </Select>
                            </Popover>
                        </Form.Item>
                    </section>
                </div>

                <section
                    className={`input-section-form ${styles.datePickBlock} ${styles.groupInputs}`}
                >
                    <Form.Item
                        hidden
                        label=""
                        name="deliveryFormatDate"
                        className={styles.formItemStyle}
                    ></Form.Item>
                    <Form.Item
                        label=""
                        name="deliveryTime"
                        className={styles.formItemStyle}
                    >
                        <Popover content="Срок сдачи жк" title="">
                            <h5>Срок сдачи дома</h5>
                            <DatePicker
                                size="large"
                                className={styles.datePickInput}
                                onChange={(date, dateString) => {
                                    let quarter = dateString.slice(-2);
                                    quarter =
                                        quarter == 'Q1'
                                            ? 'I Квартал'
                                            : quarter == 'Q2'
                                            ? 'II Квартал'
                                            : quarter == 'Q3'
                                            ? 'III Квартал'
                                            : quarter == 'Q4'
                                            ? 'IV Квартал'
                                            : '';

                                    setDeliveryTime(
                                        `${quarter} ${date.format('YYYY')}`
                                    );

                                    formRef.current.setFieldsValue({
                                        deliveryTime: `${quarter} ${date.format(
                                            'YYYY'
                                        )}`,
                                    });
                                    formRef.current.setFieldsValue({
                                        deliveryFormatDate: date,
                                    });
                                }}
                                picker="quarter"
                            />

                            <b>{deliveryTime}</b>
                        </Popover>
                    </Form.Item>
                </section>

                <section
                    className={`input-section-form ${styles.inputSectionForm}`}
                >
                    <Form.Item
                        label=""
                        name="construction_phase"
                        rules={[
                            {
                                required: true,
                                message: 'Выберите этап строительства',
                            },
                        ]}
                        className={styles.formItemStyle}
                    >
                        <Popover content="Этап строительства" title="">
                            <Select
                                size="large"
                                className={styles.formSelect}
                                onChange={(value) => {
                                    formRef.current.setFieldsValue({
                                        construction_phase: value,
                                    });
                                }}
                                placeholder="Выберите этап строительства"
                            >
                                <Option value="Не строится">Не строится</Option>
                                <Option value="Строится">Строится</Option>
                                <Option value="Построен">Построен</Option>
                            </Select>
                        </Popover>
                    </Form.Item>
                </section>

                <Collapse style={{ marginBottom: 15 }}>
                    <Panel forceRender header="Узнать больше" key="1">
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="building_construction"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Конструкция здания" title="">
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                building_construction:
                                                    val.target.value,
                                            });
                                        }}
                                        placeholder="Конструкция здания"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="building_facade"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Фасад здания" title="">
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                building_facade:
                                                    val.target.value,
                                            });
                                        }}
                                        placeholder="Фасад здания"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="interior"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Внутренняя отделка" title="">
                                    <Select
                                        size="large"
                                        className={styles.formSelect}
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                interior: val,
                                            });
                                        }}
                                        placeholder="Внутренняя отделка"
                                    >
                                        <Option value="Черновая">
                                            Черновая
                                        </Option>
                                        <Option value="Предчистовая">
                                            Предчистовая
                                        </Option>
                                        <Option value="Чистовая">
                                            Чистовая
                                        </Option>
                                    </Select>
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="number_of_apartments"
                                className={styles.formItemStyle}
                            >
                                <Popover
                                    content="Количество квартир на площадке"
                                    title=""
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                number_of_apartments:
                                                    val.target.value,
                                            });
                                        }}
                                        placeholder="Количество квартир на площадке"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="ceiling_height"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Высота потолков" title="">
                                    <Input
                                        size="large"
                                        suffix="м"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                ceiling_height:
                                                    val.target.value,
                                            });
                                        }}
                                        placeholder="Высота потолков"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="window_height"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Высота окон" title="">
                                    <Input
                                        size="large"
                                        suffix="м"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                window_height: val.target.value,
                                            });
                                        }}
                                        placeholder="Высота окон"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="parking"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Паркинг" title="">
                                    <Select
                                        size="large"
                                        className={styles.formSelect}
                                        onChange={(value) => {
                                            formRef.current.setFieldsValue({
                                                parking: value,
                                            });
                                        }}
                                        placeholder="Выберите паркинг"
                                    >
                                        <Option value="Подземный">
                                            Подземный
                                        </Option>
                                        <Option value="Наземный">
                                            Наземный
                                        </Option>
                                        <Option value="Гостевой">
                                            Гостевой
                                        </Option>
                                        <Option value="Отапливаемый гараж">
                                            Отапливаемый гараж
                                        </Option>
                                        <Option value="Нет">Нет</Option>
                                    </Select>
                                </Popover>
                            </Form.Item>
                        </section>
                    </Panel>
                    <Panel forceRender header="Картинки/Местоположение" key="2">
                        <Collapse accordion>
                            <Panel
                                forceRender
                                header="Местоположение ЖК на карте"
                                key="1"
                            >
                                <div
                                    className={`input-section-form ${styles.inputSectionForm}`}
                                >
                                    <Form.Item
                                        label=""
                                        name="coords"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Выберите местоположение на карте',
                                            },
                                        ]}
                                        className={styles.formItemStyle}
                                    >
                                        {coords ? (
                                            <small>
                                                {coords[0].toFixed(3)},{' '}
                                                {coords[1].toFixed(3)}
                                            </small>
                                        ) : (
                                            <div></div>
                                        )}

                                        <YMaps>
                                            <Map
                                                defaultState={{
                                                    center: [50.698, 70.191],
                                                    zoom: 4,
                                                }}
                                                style={{
                                                    width: '100%',
                                                    height: 500,
                                                }}
                                                onClick={(e) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            coords: e.get(
                                                                'coords'
                                                            ),
                                                        }
                                                    );
                                                    setCoords(e.get('coords'));
                                                }}
                                            >
                                                {coords ? (
                                                    <Placemark
                                                        geometry={coords}
                                                    />
                                                ) : (
                                                    <div></div>
                                                )}
                                            </Map>
                                        </YMaps>
                                    </Form.Item>
                                </div>
                            </Panel>
                            {type_content === 'standart' ? (
                                <Panel
                                    forceRender
                                    header="Панорамная фотография жк с блоками"
                                    key="2"
                                >
                                    <Form.Item
                                        label=""
                                        name="image_complex_with_all_blocks"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Загрузите панорамное фото жк',
                                            },
                                        ]}
                                        className={styles.formItemStyle}
                                    >
                                        <div className="section-block-photos">
                                            <Dropzone
                                                onDrop={handleDropBlocks.bind(
                                                    undefined
                                                )}
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                }) => (
                                                    <section className="drag-photo-complex">
                                                        {getFilesState.request_upload_status ? (
                                                            <div>
                                                                <Autorenew
                                                                    size={55}
                                                                    className="spin"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                {...getRootProps()}
                                                            >
                                                                <input
                                                                    {...getInputProps()}
                                                                />
                                                                <p>
                                                                    Для загрузки
                                                                    фотографии
                                                                    можете
                                                                    перенести
                                                                    файл сюда
                                                                    или нажать
                                                                    на область
                                                                </p>
                                                            </div>
                                                        )}
                                                    </section>
                                                )}
                                            </Dropzone>

                                            {getFilesState.photo_apartment_complex_with_blocks ? (
                                                <div
                                                    className="photo-apartment-url"
                                                    style={{
                                                        background: `url(${config.public}450/340/${getFilesState.photo_apartment_complex_with_blocks.url}) center / cover no-repeat`,
                                                    }}
                                                ></div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </Form.Item>
                                </Panel>
                            ) : (
                                <></>
                            )}

                            <Panel forceRender header="Картинки ЖК" key="3">
                                <Form.Item
                                    hidden
                                    label=""
                                    name="photo"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Загрузите главную фотографию жк',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                ></Form.Item>
                                <Form.Item
                                    label=""
                                    name="photos"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Загрузите фотографии жк',
                                        },
                                    ]}
                                    className={styles.formItemStyle}
                                >
                                    {getFilesState.request_upload_status ? (
                                        <div
                                            className={styles.uploadButtonBlock}
                                        >
                                            <small>Подождите</small>
                                            <LoadingOutlined
                                                className={
                                                    styles.uploadButtonBlockIconLoad
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.uploadButtonBlock}
                                        >
                                            <Button size="large" type="primary">
                                                Загрузить картинку
                                                <Input
                                                    className={styles.inputFile}
                                                    type="file"
                                                    onChange={(val) => {
                                                        dispatch(
                                                            api.files.uploadFile(
                                                                val.target
                                                                    .files[0],
                                                                'IMAGE',
                                                                null,
                                                                !getFilesState?.APARTMENT_INTERIOR
                                                                    ? true
                                                                    : false
                                                            )
                                                        );
                                                    }}
                                                />
                                            </Button>
                                        </div>
                                    )}
                                    <div
                                        className={`images-block-interior-flat ${styles.listImagesBlock}`}
                                    >
                                        {getFilesState.APARTMENT_INTERIOR ? (
                                            getFilesState.APARTMENT_INTERIOR.map(
                                                (item, index) => (
                                                    <div
                                                        key={index}
                                                        className={`image-interior-flat ${styles.listImageBlock}`}
                                                        style={{
                                                            background: `url(${config.public}450/340/${item.url}) center / cover no-repeat`,
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.imageSizeBlock
                                                            }
                                                        >
                                                            <h5>
                                                                <b>
                                                                    Оригинал:{' '}
                                                                </b>{' '}
                                                                {formatBytes(
                                                                    Number(
                                                                        (item?.original_size ||
                                                                            0) *
                                                                            (1024 *
                                                                                1024)
                                                                    )
                                                                )}
                                                            </h5>
                                                            <h5>
                                                                <b>
                                                                    После
                                                                    сжатия:{' '}
                                                                </b>{' '}
                                                                {formatBytes(
                                                                    Number(
                                                                        (item?.size ||
                                                                            0) *
                                                                            (1024 *
                                                                                1024)
                                                                    )
                                                                )}
                                                            </h5>
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.controlBlockImages
                                                            }
                                                        >
                                                            <Popover
                                                                content={
                                                                    <div
                                                                        className={
                                                                            styles.contentPopover
                                                                        }
                                                                    >
                                                                        <p>
                                                                            Установит
                                                                            главной
                                                                            фотографией
                                                                        </p>
                                                                        <p>
                                                                            Будет
                                                                            первой
                                                                            в
                                                                            слайдере
                                                                        </p>
                                                                    </div>
                                                                }
                                                                title="Пояснение"
                                                            >
                                                                <CheckCircleOutlined
                                                                    onClick={() =>
                                                                        changeMainPhoto(
                                                                            item
                                                                        )
                                                                    }
                                                                    className={
                                                                        index ==
                                                                        0
                                                                            ? styles.getFirstPhoto
                                                                            : false
                                                                    }
                                                                />
                                                            </Popover>

                                                            <span
                                                                onClick={() => {
                                                                    dispatch({
                                                                        type: 'DELETE_IMAGE_APARTMENT_INTERIOR',
                                                                        index,
                                                                    });
                                                                }}
                                                            >
                                                                <Delete />
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            <div>
                                                <p>Картинок нет</p>
                                            </div>
                                        )}
                                    </div>
                                </Form.Item>
                            </Panel>
                        </Collapse>
                    </Panel>
                    <Panel forceRender header="Способы покупки" key="3">
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="min_price_square_metres"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Заполните стоимость м2',
                                    },
                                ]}
                                className={styles.formItemStyle}
                            >
                                <Popover
                                    content="Минимальная стоимость квадратного метра"
                                    title=""
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        suffix="₸"
                                        keyboard={false}
                                        type="number"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                min_price_square_metres:
                                                    val.target.value,
                                            });
                                        }}
                                        placeholder="Минимальная стоимость квадратного метра (тг)*"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="bank_installment"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Ипотека" title="">
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                bank_installment:
                                                    val.target.value,
                                            });
                                        }}
                                        placeholder="Ипотека"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="installment"
                                className={styles.formItemStyle}
                            >
                                <Popover
                                    content="Рассрочка от Застройщика"
                                    title=""
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            formRef.current.setFieldsValue({
                                                installment: val.target.value,
                                            });
                                        }}
                                        placeholder="Рассрочка от Застройщика"
                                    />
                                </Popover>
                            </Form.Item>
                        </section>
                        {getMortgage && getMortgage.length ? (
                            <section
                                className={`input-section-form ${styles.inputSectionForm}`}
                            >
                                <Form.Item
                                    label=""
                                    name="mortgage"
                                    className={styles.formItemStyle}
                                >
                                    <Popover content="Рассрочка" title="">
                                        <Select
                                            size="large"
                                            mode="tags"
                                            showSearch
                                            placeholder="Выберите способ покупки"
                                            className={styles.formSelect}
                                            onChange={handleChangeMortgage}
                                        >
                                            {getMortgage.map((item) => {
                                                return (
                                                    <Option
                                                        key={keyRandom({
                                                            length: 15,
                                                        })}
                                                        style={{ fontSize: 17 }}
                                                        value={item._id}
                                                    >
                                                        {item.title}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Popover>
                                </Form.Item>
                            </section>
                        ) : (
                            <></>
                        )}
                        <section
                            className={`input-section-form ${styles.inputSectionForm}`}
                        >
                            <Form.Item
                                label=""
                                name="state_programs"
                                className={styles.formItemStyle}
                            >
                                <Popover content="Госпрограммы" title="">
                                    <Select
                                        size="large"
                                        mode="tags"
                                        showSearch
                                        placeholder="Выберите госпрограмму"
                                        className={styles.formSelect}
                                        defaultValue={[]}
                                        onChange={(value) => {
                                            formRef.current.setFieldsValue({
                                                state_programs: value,
                                            });
                                        }}
                                    >
                                        {getStatePrograms &&
                                        getStatePrograms.length ? (
                                            getStatePrograms.map((item) => {
                                                return (
                                                    <Option
                                                        key={keyRnd({
                                                            length: 15,
                                                        })}
                                                        style={{ fontSize: 17 }}
                                                        value={item._id}
                                                    >
                                                        {item.title}
                                                    </Option>
                                                );
                                            })
                                        ) : (
                                            <Option
                                                style={{ fontSize: 17 }}
                                                value={null}
                                            >
                                                Не найдено
                                            </Option>
                                        )}
                                    </Select>
                                </Popover>
                            </Form.Item>
                        </section>

                        <Collapse accordion>
                            <Panel forceRender header="Документы" key="1">
                                <Form.Item
                                    label=""
                                    name="documents"
                                    className={styles.formItemStyle}
                                >
                                    <Button
                                        type="primary"
                                        size="large"
                                        className={styles.uploadFileButton}
                                    >
                                        {document_status_upload ? (
                                            <>Подождите...</>
                                        ) : (
                                            <>
                                                Загрузить документ
                                                <input
                                                    className={styles.inputFile}
                                                    type="file"
                                                    onChange={uploadDocument}
                                                />
                                            </>
                                        )}
                                    </Button>

                                    <div className={styles.listDocuments}>
                                        {documents.length ? (
                                            documents.map((item) => (
                                                <div
                                                    key={item?.id}
                                                    className={
                                                        styles.itemDocument
                                                    }
                                                >
                                                    <h5>{item?.full_url}</h5>
                                                    <DeleteOutlined
                                                        onClick={() => {
                                                            set_documents(
                                                                documents.filter(
                                                                    (doc) =>
                                                                        doc.id !=
                                                                        item.id
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <h6>Документов нет</h6>
                                        )}
                                    </div>
                                </Form.Item>
                            </Panel>
                        </Collapse>
                    </Panel>
                    <Panel forceRender header="настройки" key="4">
                        <Collapse accordion>
                            <Panel forceRender header="SEO контент" key="1">
                                <section>
                                    <Form.Item
                                        label=""
                                        name="seo_content"
                                        className={styles.formItemStyle}
                                    >
                                        <Editor
                                            dispatch={dispatch}
                                            api={api}
                                            setContent={(val) => {
                                                set_seo_content(val);
                                            }}
                                        />
                                    </Form.Item>
                                </section>

                                <section>
                                    <div
                                        className={`plan-block-file seo-block-form ${styles.blockSeoRealEstate}`}
                                    >
                                        <h4>SEO</h4>

                                        <Popover content="Keywords" title="">
                                            <Form.Item
                                                label=""
                                                name="seo_keywords"
                                                className={styles.formItemStyle}
                                            >
                                                <Input
                                                    size="large"
                                                    type="text"
                                                    onChange={(val) => {
                                                        formRef.current.setFieldsValue(
                                                            {
                                                                seo_keywords:
                                                                    val.target
                                                                        .value,
                                                            }
                                                        );
                                                    }}
                                                    placeholder="Keywords"
                                                />
                                            </Form.Item>
                                        </Popover>

                                        <Popover content="Description" title="">
                                            <Form.Item
                                                label=""
                                                name="seo_description"
                                                className={styles.formItemStyle}
                                            >
                                                <Input
                                                    size="large"
                                                    type="text"
                                                    onChange={(val) => {
                                                        formRef.current.setFieldsValue(
                                                            {
                                                                seo_description:
                                                                    val.target
                                                                        .value,
                                                            }
                                                        );
                                                    }}
                                                    placeholder="Description"
                                                />
                                            </Form.Item>
                                        </Popover>

                                        <Popover content="Title" title="">
                                            <Form.Item
                                                label=""
                                                name="seo_title"
                                                className={styles.formItemStyle}
                                            >
                                                <Input
                                                    size="large"
                                                    type="text"
                                                    onChange={(val) => {
                                                        formRef.current.setFieldsValue(
                                                            {
                                                                seo_title:
                                                                    val.target
                                                                        .value,
                                                            }
                                                        );
                                                    }}
                                                    placeholder="Title"
                                                />
                                            </Form.Item>
                                        </Popover>
                                    </div>
                                </section>
                            </Panel>
                            <Panel forceRender header="CRM настройки" key="2">
                                <section
                                    className={`input-section-form ${styles.inputSectionForm}`}
                                >
                                    <Popover
                                        content="Процент от продажи"
                                        title=""
                                    >
                                        <Form.Item
                                            label=""
                                            name="commission"
                                            className={styles.formItemStyle}
                                        >
                                            <Input
                                                style={{ marginBottom: 5 }}
                                                size="large"
                                                suffix="%"
                                                onChange={(val) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            commission:
                                                                val.target
                                                                    .value,
                                                        }
                                                    );
                                                }}
                                                placeholder="Процент от продажи"
                                            />
                                        </Form.Item>
                                    </Popover>
                                    <Popover content="Стоимость лида" title="">
                                        <Form.Item
                                            label=""
                                            name="crm_price_lead"
                                            className={styles.formItemStyle}
                                        >
                                            <Input
                                                size="large"
                                                suffix="₸"
                                                onChange={(val) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            crm_price_lead:
                                                                val.target
                                                                    .value,
                                                        }
                                                    );
                                                }}
                                                placeholder="Стоимость лида"
                                            />
                                        </Form.Item>
                                    </Popover>
                                </section>
                            </Panel>
                            <Panel forceRender header="Шахматка" key="3">
                                <section
                                    className={`input-section-form ${styles.inputSectionForm}`}
                                >
                                    <Popover
                                        placement="bottom"
                                        content="Выберите тип шахматки"
                                        title=""
                                    >
                                        <Form.Item
                                            label=""
                                            name="chessboard"
                                            className={styles.formItemStyle}
                                        >
                                            <Select
                                                size="large"
                                                onChange={(value) => {
                                                    formRef.current.setFieldsValue(
                                                        {
                                                            chessboard: value,
                                                        }
                                                    );
                                                }}
                                                placeholder="Выберите тип шахматки"
                                                className={styles.selectInput}
                                            >
                                                <Option
                                                    style={{ fontSize: 17 }}
                                                    value="standart"
                                                >
                                                    Стандартная
                                                </Option>
                                                <Option
                                                    style={{ fontSize: 17 }}
                                                    value="custom"
                                                >
                                                    Кастомная
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </Popover>
                                </section>
                            </Panel>
                        </Collapse>
                    </Panel>
                </Collapse>

                <section
                    className={`input-section-form ${styles.inputSectionForm}`}
                >
                    <Form.Item>
                        {getApartmentsState.request_apartments_status ? (
                            <Button type="primary" size="large">
                                <LoadingOutlined />
                                Подождите
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                            >
                                Добавить и перейти к шаблонам
                            </Button>
                        )}
                    </Form.Item>
                </section>
            </Form>
        </div>
    );
};

export default ApartmentСomplex;
