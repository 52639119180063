import React from 'react';
import { Layout, Menu } from 'antd';
import {
    BarsOutlined,
    BorderlessTableOutlined,
    BranchesOutlined,
    BuildOutlined,
    CodeOutlined,
    DashOutlined,
    DollarOutlined,
    FileDoneOutlined,
    FolderAddOutlined,
    FundOutlined,
    HomeOutlined,
    LogoutOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    ShoppingCartOutlined,
    ToolOutlined,
    UsergroupAddOutlined,
    GlobalOutlined,
    FileImageOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { api } from '@/Services';
import styles from '../styles/index.module.scss';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { SubMenu } = Menu;
const { Sider } = Layout;

const SideBar = ({ path, user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sider_collapsed_status = useSelector(
        (state) => state.user.sider_collapsed
    );

    return (
        <>
            {user && (
                <div className={`parent-block-menu`}>
                    <div className={styles.menuMain}>
                        <Sider
                            collapsible
                            collapsed={sider_collapsed_status}
                            onCollapse={() =>
                                dispatch({
                                    type: 'SIDER_COLLAPSED_STATUS',
                                    status: !sider_collapsed_status,
                                })
                            }
                            className="site-layout-background"
                            style={{
                                background: '#fff',
                                borderRight: '1px solid rgba(0,0,0,0.08)',
                            }}
                        >
                            <div className="logo" />
                            <Menu
                                theme=""
                                defaultSelectedKeys={[path]}
                                mode="inline"
                            >
                                <Menu.Item
                                    onClick={() => navigate('/')}
                                    key="/"
                                    icon={<DashOutlined />}
                                >
                                    Главная панель
                                </Menu.Item>

                                {user?.role?.type == 'EDITOR' ||
                                user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'CONTENT_MANAGER' ? (
                                    <SubMenu
                                        key="/apartments/sub"
                                        icon={<BuildOutlined />}
                                        title="Недвижимость"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/apartments/objects')
                                            }
                                            key="/apartments/objects"
                                        >
                                            Объекты
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/apartments')
                                            }
                                            key="/apartments"
                                        >
                                            Очереди и жк
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/realestate/sort')
                                            }
                                            key="/realestate/sort"
                                        >
                                            Сортировка жк
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'EDITOR' ||
                                user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'MERCHANT' ||
                                user?.role?.type == 'CONTENT_MANAGER' ? (
                                    <SubMenu
                                        key="/apartments/flats/sub"
                                        icon={<HomeOutlined />}
                                        title="Квартиры, дома"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/apartments/flats')
                                            }
                                            key="/apartments/flats"
                                        >
                                            Квартиры
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate(
                                                    '/apartments/flats/templates'
                                                )
                                            }
                                            key="/apartments/flats/templates"
                                        >
                                            Шаблоны
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                <Menu.Item
                                    icon={<FileImageOutlined />}
                                    onClick={() => navigate('/virtual-tours')}
                                    key="/virtual-tours"
                                >
                                    Виртуальные туры
                                </Menu.Item>

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'MERCHANT' ? (
                                    <SubMenu
                                        key="/library/1"
                                        icon={<ShoppingCartOutlined />}
                                        title="Библиотека"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() => navigate('/library')}
                                            key="/library/2"
                                        >
                                            Товары
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/library/categories')
                                            }
                                            key="/library/categories"
                                        >
                                            Категории
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/library/shops')
                                            }
                                            key="/library/shops"
                                        >
                                            Магазины
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/library/bonus-cards')
                                            }
                                            key="/library/bonus-cards"
                                        >
                                            Бонусные карты
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'CONTENT_MANAGER' ? (
                                    <Menu.Item
                                        icon={<GlobalOutlined />}
                                        onClick={() => navigate('/cities')}
                                        key="/cities"
                                    >
                                        Локация
                                    </Menu.Item>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'CONTENT_MANAGER' ? (
                                    <SubMenu
                                        key="/builders/sub"
                                        icon={<UsergroupAddOutlined />}
                                        title="Застройщики"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/builders')
                                            }
                                            key="/builders"
                                        >
                                            Список застройщиков
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<FolderAddOutlined />}
                                            onClick={() =>
                                                navigate('/builders/form')
                                            }
                                            key="/builders/form"
                                        >
                                            Добавить
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <Menu.Item
                                        onClick={() => navigate('/users')}
                                        key="/users"
                                        icon={<UsergroupAddOutlined />}
                                    >
                                        Пользователи
                                    </Menu.Item>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <SubMenu
                                        key="/realtors/sub"
                                        icon={<UsergroupAddOutlined />}
                                        title="Риэлторы"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/realtors')
                                            }
                                            key="/realtors"
                                        >
                                            Список риэлторов
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<FolderAddOutlined />}
                                            onClick={() =>
                                                navigate('/realtors/create')
                                            }
                                            key="/realtors/create"
                                        >
                                            Добавить
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <SubMenu
                                        key="/pages/sub"
                                        icon={<FileDoneOutlined />}
                                        title="Страницы"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() => navigate('/pages')}
                                            key="/pages"
                                        >
                                            Список страниц
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<FolderAddOutlined />}
                                            onClick={() =>
                                                navigate('/pages/create')
                                            }
                                            key="/pages/create"
                                        >
                                            Добавить
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <SubMenu
                                        key="/blog/sub"
                                        icon={<FileDoneOutlined />}
                                        title="Блог"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() => navigate('/blog')}
                                            key="/blog"
                                        >
                                            Список постов
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<FolderAddOutlined />}
                                            onClick={() =>
                                                navigate('/blog/create')
                                            }
                                            key="/blog/create"
                                        >
                                            Добавить
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <Menu.Item
                                        onClick={() => navigate('/analytics')}
                                        key="/analytics"
                                        icon={<PieChartOutlined />}
                                    >
                                        Аналитика
                                    </Menu.Item>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'CONTENT_MANAGER' ? (
                                    <SubMenu
                                        key="/widgets/sub"
                                        icon={<BarsOutlined />}
                                        title="Виджеты"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() => navigate('/widgets')}
                                            key="/widgets"
                                        >
                                            Список виджетов
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<FolderAddOutlined />}
                                            onClick={() =>
                                                navigate('/widgets/create')
                                            }
                                            key="/widgets/create"
                                        >
                                            Добавить
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'CONTENT_MANAGER' ? (
                                    <SubMenu
                                        key="/logs/sub"
                                        icon={<CodeOutlined />}
                                        title="Отладчик"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() => navigate('/logs')}
                                            key="/logs"
                                        >
                                            Список
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <SubMenu
                                        key="/redirects/1"
                                        icon={<BranchesOutlined />}
                                        title="Редиректы"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/redirects')
                                            }
                                            key="/redirects"
                                        >
                                            Список ссылок
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<FolderAddOutlined />}
                                            onClick={() =>
                                                navigate('/redirects/create')
                                            }
                                            key="/redirects/create"
                                        >
                                            Добавить
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <SubMenu
                                        key="/crediting/applications/sub"
                                        icon={<FundOutlined />}
                                        title="Кредитование"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate(
                                                    '/crediting/applications'
                                                )
                                            }
                                            key="/crediting/applications"
                                        >
                                            заявки
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() =>
                                                navigate('/crediting/banks')
                                            }
                                            key="/crediting/banks"
                                        >
                                            Банки
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ||
                                user?.role?.type == 'MERCHANT' ? (
                                    <SubMenu
                                        key="/budget/sub"
                                        icon={<DollarOutlined />}
                                        title="Бюджет"
                                    >
                                        <Menu.Item
                                            icon={<MenuUnfoldOutlined />}
                                            onClick={() => navigate('/budget')}
                                            key="/budget"
                                        >
                                            Список шаблонов
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<FolderAddOutlined />}
                                            onClick={() =>
                                                navigate('/budget/create')
                                            }
                                            key="/budget/create"
                                        >
                                            Добавить
                                        </Menu.Item>
                                    </SubMenu>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <Menu.Item
                                        onClick={() => navigate('/games')}
                                        key="/games"
                                        icon={<BorderlessTableOutlined />}
                                    >
                                        Игры
                                    </Menu.Item>
                                ) : (
                                    <></>
                                )}

                                {user?.role?.type == 'ADMINISTRATOR' ||
                                user?.role?.type ==
                                    'CONFIGURATION_MANAGEMENT' ||
                                user?.role?.type == 'SUPERADMIN' ? (
                                    <Menu.Item
                                        onClick={() => navigate('/settings')}
                                        key="/settings"
                                        icon={<ToolOutlined />}
                                    >
                                        Настройки
                                    </Menu.Item>
                                ) : (
                                    <></>
                                )}

                                <Menu.Item
                                    onClick={() => dispatch(api.auth.logout())}
                                    key="/logout"
                                    icon={<LogoutOutlined />}
                                >
                                    Завершить сессию
                                </Menu.Item>
                            </Menu>
                        </Sider>
                    </div>
                </div>
            )}
        </>
    );
};

export default SideBar;
