import React from 'react';
import PremiseMarkersListWindow from "./premise_markers_list_window";
import {Add} from "@material-ui/icons";
import {useSelector} from "react-redux";

const AddListButton = ({
	styles,
	dispatch,
	api,
	params,
	title,
	type,
	zIndex,
	open_cat = false,
	parent_cat_id = null,
	doorway_length = 1
 }) => {

	const stateBudget = useSelector(state => state.budget);

	const saveCat = (id) => {

		dispatch({
			type: 'BUDGET_MODAL_LIST_CATEGORIES',
			status: false,
			type_modal:false,
			parent_cat_id:''
		});

		if(type == 'PREMISE'){
			dispatch(api.budget.addPremise(params.id, id, 'PREMISE'));
		}else if(type == 'DOORS'){
			dispatch(api.budget.addPremise(params.id, id, 'DOORS'));
		}else if(type == 'WINDOW'){
			dispatch(api.budget.addPremise(params.id, id, 'WINDOW', parent_cat_id));
		}else if(type == 'MEBEL'){
			dispatch(api.budget.addPremise(params.id, id, 'MEBEL', parent_cat_id));
		}else if(type == 'DOORWAY'){
			dispatch(api.budget.addPremise(params.id, `DOORWAY_${doorway_length}`, 'DOORWAY', parent_cat_id));
		}
	}

	const renderListCat = () => {
		if(open_cat && stateBudget.budget_modal_list_categories && type == stateBudget.budget_type_list_categories){
			return (
				<PremiseMarkersListWindow parent_cat_id={parent_cat_id} getPremise={(premise)=>saveCat(premise._id)} type={type} api={api} params={params} styles={styles} />
			)
		}else if(stateBudget.budget_modal_list_categories && type == stateBudget.budget_type_list_categories){
			return (
				<PremiseMarkersListWindow parent_cat_id={parent_cat_id} getPremise={(premise)=>saveCat(premise._id)} type={type} api={api} params={params} styles={styles} />
			)
		}else{
			return false;
		}
	}

	return (
		<section className={styles.helperSection} style={{
			zIndex
		}}>
			{ renderListCat() }

			<div className={styles.addPremiseButton} onClick={(event)=>{
				event.stopPropagation();
				if(type == 'DOORWAY'){
					saveCat()
				}else{
					dispatch(api.budget.triggerModalListCategories(type, parent_cat_id));
				}
			}}>
				<p>{title}</p>
				<Add className={styles.iconAddPremise}/>
			</div>

		</section>
	)
}

export default AddListButton;