import { Form, Typography, Select } from 'antd';
import styles from '../../../styles/index.module.scss';

const { Text } = Typography;
const { Option } = Select;

const BuilderFormItemComplementary = () => {
    return (
        <div className={styles.formSectionGroup}>
            <Text type="secondary">Дополнительные опции</Text>

            <Form.Item
                label="Статус видимости"
                name="visibility_status"
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, заполните поле',
                    },
                ]}
            >
                <Select size="large" className={styles.selectInput}>
                    <Option style={{ fontSize: 17 }} value="public">
                        Опубликован
                    </Option>
                    <Option style={{ fontSize: 17 }} value="closed">
                        Закрытый
                    </Option>
                </Select>
            </Form.Item>
        </div>
    );
};

export default BuilderFormItemComplementary;
