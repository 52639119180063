"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColorLegendItems = void 0;
var tslib_1 = require("tslib");
var lodash_es_1 = require("lodash-es");
var isLegendItemI = function (legendItems) {
    return !Array.isArray(legendItems[0].value);
};
var getColorLegendItems = function (legendItems) {
    if (isLegendItemI(legendItems)) {
        return legendItems;
    }
    // TODO: scale nice
    var items = legendItems.map(function (item) { return (tslib_1.__assign(tslib_1.__assign({}, item), { value: [(0, lodash_es_1.round)(item.value[0], 2), (0, lodash_es_1.round)(item.value[1], 2)] })); });
    return items;
};
exports.getColorLegendItems = getColorLegendItems;
