"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AreaLayer = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var l7_layers_1 = require("@antv/l7-layers");
var plot_layer_1 = require("../../core/layer/plot-layer");
var adaptor_1 = require("./adaptor");
var helper_1 = require("../dot-layer/helper");
var DEFAULT_OPTIONS = {
    visible: true,
    state: {
        active: false,
        select: false,
    },
    enabledMultiSelect: false,
};
var LAYER_OPTIONS_KEYS = ['color', 'style', 'state', 'enabledMultiSelect'];
var AreaLayer = /** @class */ (function (_super) {
    tslib_1.__extends(AreaLayer, _super);
    function AreaLayer(options) {
        var _this = _super.call(this, options) || this;
        /**
         * 选中数据
         */
        _this.selectData = [];
        /**
         * 图层类型
         */
        _this.type = plot_layer_1.PlotLayer.LayerType.AreaLayer;
        /**
         * 图层是否具有交互属性
         */
        _this.interaction = true;
        _this.onHighlighHandle = function (event) {
            var feature = event.feature, featureId = event.featureId;
            _this.setHighlightLayerSource(feature, featureId);
        };
        _this.onUnhighlighHandle = function () {
            _this.setHighlightLayerSource();
        };
        _this.onSelectHandle = function (event) {
            var enabledMultiSelect = _this.options.enabledMultiSelect;
            var feature = event.feature, featureId = event.featureId;
            var selectData = (0, util_1.clone)(_this.selectData);
            var index = selectData.findIndex(function (item) { return item.featureId === featureId; });
            if (index === -1) {
                if (enabledMultiSelect) {
                    selectData.push({ feature: feature, featureId: featureId });
                }
                else {
                    selectData = [{ feature: feature, featureId: featureId }];
                }
                _this.emit('select', feature, (0, util_1.clone)(selectData));
            }
            else {
                var unselectFeature = selectData[index];
                if (enabledMultiSelect) {
                    selectData.splice(index, 1);
                }
                else {
                    selectData = [];
                }
                _this.emit('unselect', unselectFeature, (0, util_1.clone)(selectData));
            }
            _this.setSelectLayerSource(selectData);
        };
        var _a = _this.options, name = _a.name, source = _a.source, visible = _a.visible, minZoom = _a.minZoom, maxZoom = _a.maxZoom, _b = _a.zIndex, zIndex = _b === void 0 ? 0 : _b;
        var config = _this.pickLayerConfig(_this.options);
        var defaultState = (0, adaptor_1.getDefaultState)(_this.options.state);
        _this.name = name ? name : (0, util_1.uniqueId)(_this.type);
        _this.layer = new l7_layers_1.PolygonLayer(tslib_1.__assign(tslib_1.__assign({}, config), { name: _this.name }));
        _this.strokeLayer = new l7_layers_1.LineLayer({ name: 'strokeLayer', visible: visible, zIndex: zIndex, minZoom: minZoom, maxZoom: maxZoom });
        _this.highlightLayer = new l7_layers_1.LineLayer({
            name: 'highlightLayer',
            visible: visible && Boolean(defaultState.active.stroke),
            zIndex: zIndex + 0.1,
            minZoom: minZoom,
            maxZoom: maxZoom,
        });
        _this.selectFillLayer = new l7_layers_1.PolygonLayer({
            name: 'selectFillLayer',
            visible: visible && Boolean(defaultState.select.fill),
            zIndex: zIndex + 0.1,
            minZoom: minZoom,
            maxZoom: maxZoom,
        });
        _this.selectStrokeLayer = new l7_layers_1.LineLayer({
            name: 'selectStrokeLayer',
            visible: visible && Boolean(defaultState.select.stroke),
            zIndex: zIndex + 0.1,
            minZoom: minZoom,
            maxZoom: maxZoom,
        });
        _this.mappingLayer(_this.options);
        _this.setSource(source);
        _this.initEvent();
        return _this;
    }
    /**
     * 获取默认配置
     */
    AreaLayer.prototype.getDefaultOptions = function () {
        return DEFAULT_OPTIONS;
    };
    AreaLayer.prototype.mappingLayer = function (options) {
        (0, adaptor_1.mappingLayer)(this.layer, this.strokeLayer, this.highlightLayer, this.selectFillLayer, this.selectStrokeLayer, options);
    };
    AreaLayer.prototype.setSource = function (source) {
        _super.prototype.setSource.call(this, source);
        this.setStrokeLayerSource();
        this.setHighlightLayerSource();
        this.selectFillLayer.source({ type: 'FeatureCollection', features: [] }, { parser: { type: 'geojson' } });
        this.selectStrokeLayer.source({ type: 'FeatureCollection', features: [] }, { parser: { type: 'geojson' } });
    };
    AreaLayer.prototype.setStrokeLayerSource = function () {
        var layerSource = this.layer.getSource();
        if (layerSource) {
            this.strokeLayer.setSource(layerSource);
        }
        else {
            var _a = this.layer.sourceOption, data = _a.data, options = _a.options;
            this.strokeLayer.source(data, options);
        }
    };
    AreaLayer.prototype.setHighlightLayerSource = function (feature, featureId) {
        if (featureId === void 0) { featureId = -999; }
        if (this.highlightLayerData === featureId) {
            return;
        }
        var features = feature ? [feature] : [];
        this.highlightLayer.setData({ type: 'FeatureCollection', features: features }, { parser: { type: 'geojson' } });
        this.highlightLayerData = featureId;
    };
    AreaLayer.prototype.setSelectLayerSource = function (selectData) {
        if (selectData === void 0) { selectData = []; }
        if (this.selectData.length === selectData.length &&
            (0, util_1.isEqual)(this.selectData.map(function (_a) {
                var featureId = _a.featureId;
                return featureId;
            }), selectData.map(function (_a) {
                var featureId = _a.featureId;
                return featureId;
            }))) {
            return;
        }
        var features = selectData.map(function (_a) {
            var feature = _a.feature;
            return feature;
        });
        this.selectFillLayer.setData({ type: 'FeatureCollection', features: features }, { parser: { type: 'geojson' } });
        this.selectStrokeLayer.setData({ type: 'FeatureCollection', features: features }, { parser: { type: 'geojson' } });
        this.selectData = selectData;
    };
    AreaLayer.prototype.initEvent = function () {
        this.layer.off('mousemove', this.onHighlighHandle);
        this.layer.off('unmousemove', this.onHighlighHandle);
        this.layer.off('click', this.onSelectHandle);
        this.selectData = [];
        this.highlightLayerData = null;
        if (!this.options.state)
            return;
        // active
        if (this.options.state.active) {
            this.layer.on('mousemove', this.onHighlighHandle);
            this.layer.on('unmousemove', this.onUnhighlighHandle);
        }
        // select
        if (this.options.state.select) {
            this.layer.on('click', this.onSelectHandle);
        }
    };
    AreaLayer.prototype.addTo = function (scene) {
        scene.addLayer(this.layer);
        scene.addLayer(this.strokeLayer);
        scene.addLayer(this.highlightLayer);
        scene.addLayer(this.selectFillLayer);
        scene.addLayer(this.selectStrokeLayer);
    };
    AreaLayer.prototype.remove = function (scene) {
        scene.removeLayer(this.layer);
        scene.removeLayer(this.strokeLayer);
        scene.removeLayer(this.highlightLayer);
        scene.removeLayer(this.selectFillLayer);
        scene.removeLayer(this.selectStrokeLayer);
    };
    AreaLayer.prototype.update = function (options) {
        _super.prototype.update.call(this, options);
        this.mappingLayer(this.options);
        if (this.options.visible) {
            if (!(0, util_1.isUndefined)(options.state) && !(0, util_1.isEqual)(this.lastOptions.state, this.options.state)) {
                this.updateHighlightLayer();
            }
            var defaultState = (0, adaptor_1.getDefaultState)(this.options.state);
            if (defaultState.active.stroke) {
                this.setHighlightLayerSource();
            }
            if (defaultState.select.fill || defaultState.select.stroke) {
                this.setSelectLayerSource();
            }
        }
        this.initEvent();
    };
    AreaLayer.prototype.updateHighlightLayer = function () {
        var defaultState = (0, adaptor_1.getDefaultState)(this.options.state);
        var lasetDefaultState = (0, adaptor_1.getDefaultState)(this.lastOptions.state);
        if (lasetDefaultState.active.stroke !== defaultState.active.stroke) {
            defaultState.active.stroke ? this.highlightLayer.show() : this.highlightLayer.hide();
        }
        if (lasetDefaultState.select.fill !== defaultState.select.fill) {
            defaultState.select.fill ? this.selectFillLayer.show() : this.selectFillLayer.hide();
        }
        if (lasetDefaultState.select.stroke !== defaultState.select.stroke) {
            defaultState.select.stroke ? this.selectStrokeLayer.show() : this.selectStrokeLayer.hide();
        }
    };
    AreaLayer.prototype.setIndex = function (zIndex) {
        this.layer.setIndex(zIndex);
        this.strokeLayer.setIndex(zIndex);
        this.highlightLayer.setIndex(zIndex + 0.1);
        this.selectFillLayer.setIndex(zIndex + 0.1);
        this.selectStrokeLayer.setIndex(zIndex + 0.1);
    };
    AreaLayer.prototype.setMinZoom = function (minZoom) {
        this.layer.setMinZoom(minZoom);
        this.strokeLayer.setMinZoom(minZoom);
        this.highlightLayer.setMinZoom(minZoom);
        this.selectFillLayer.setMinZoom(minZoom);
        this.selectStrokeLayer.setMinZoom(minZoom);
    };
    AreaLayer.prototype.setMaxZoom = function (maxZoom) {
        this.layer.setMaxZoom(maxZoom);
        this.strokeLayer.setMaxZoom(maxZoom);
        this.highlightLayer.setMaxZoom(maxZoom);
        this.selectFillLayer.setMaxZoom(maxZoom);
        this.selectStrokeLayer.setMaxZoom(maxZoom);
    };
    AreaLayer.prototype.show = function () {
        if (!this.layer.inited)
            return;
        this.layer.show();
        this.strokeLayer.show();
        this.selectFillLayer.show();
        this.selectStrokeLayer.show();
    };
    AreaLayer.prototype.hide = function () {
        if (!this.layer.inited)
            return;
        this.layer.hide();
        this.strokeLayer.hide();
        this.selectFillLayer.hide();
        this.selectStrokeLayer.hide();
    };
    AreaLayer.prototype.getColorLegendItems = function () {
        var colorLegendItems = this.layer.getLegendItems('color');
        if (Array.isArray(colorLegendItems) && colorLegendItems.length !== 0) {
            var items = (0, helper_1.getColorLegendItems)(colorLegendItems);
            return items;
        }
        return [];
    };
    AreaLayer.prototype.setActive = function (id) {
        // TODO: L7 method pickFeature(id|{x,y})
    };
    AreaLayer.prototype.setSelect = function (id) {
        // TODO: L7 method pickFeature(id|{x,y})
    };
    /**
     * 默认配置项
     */
    AreaLayer.DefaultOptions = DEFAULT_OPTIONS;
    /**
     * 图层配置项 Keys
     */
    AreaLayer.LayerOptionsKeys = plot_layer_1.PlotLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return AreaLayer;
}(plot_layer_1.PlotLayer));
exports.AreaLayer = AreaLayer;
