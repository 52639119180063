import { gql } from '@apollo/client';

const getBanks = gql`
  query {
    getBanks {
      _id
      title
      url_api
      logo {
        url
      }
      credit_period
      interest_rate
      down_payment
    }
  }
`;

const addBank = gql`
  mutation($fields:FieldAddBank) {
    addBank(fields:$fields)
  }
`;

const hideBank = gql`
  mutation($id:String) {
    hideBank(id:$id)
  }
`;

export default {
  getBanks,
  addBank,
  hideBank
}