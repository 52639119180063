import React from 'react';

import {Link} from 'react-router-dom';

import styles from './styles/index.module.scss';

const Games = () => {

	return (
		<div className="statistic-block">
			<h1>Игры</h1>
			<div className={styles.contentListGames}>
				<Link to="/games/squid-game" style={{
					background: `url(${require('../../../assets/squid-game.jpg')}) center /cover no-repeat`
				}}>
					<div className={styles.gameCardOverlay}></div>
					<h5>Игра в кальмара</h5>
				</Link>
			</div>
		</div>
	)
}

export default Games;