import React from 'react';

import styles from '../styled/index.module.scss';

const Categories = ({
	data,
	selectCategory
}) => {

	const renderCategories = (cats) => {
		return (
			<div>
				{
					cats && cats.length ?
						cats.map((cat)=>{
							return (
								<div key={cat._id} className={styles.itemCategory}>
									<section className={styles.itemCategoryChild}>
										<h4 onClick={() => selectCategory(cat)}>{cat.title} <b>{cat.marker_type ? 'MО' : cat.premise ? 'П' : cat.premise_type ? 'МП' : 'K'}</b></h4>
									</section>

									{
										cat.child_categories && cat.child_categories.length ? renderCategories(cat.child_categories) : <></>
									}
								</div>
							)
						})
					:
						<></>
				}
			</div>
		)
	}

	return (
		<>
			{
				data && data.length ?

					<div className={styles.listCategories}>
						{renderCategories(data)}
					</div>
					:
					<div className={styles.defaultBlockCenter}>
						<h2>Категорий не найдено</h2>
					</div>
			}
		</>
	)
}

export default Categories;