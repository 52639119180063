import axios from '../axios';
import { NotificationManager } from 'react-notifications';
import FormData from 'form-data';
import { message } from 'antd';

const files = {
    standartUploadFile: (file, type) => async () => {

        try {

            let formData = new FormData();

            formData.append('uploadType', type);
            formData.append('file', file);

            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return data;

        }catch(err){
            return null;
        }

    },
    changePhoto: (photo) => (dispatch) => {
        dispatch({
            type: 'CHANGE_PRIVEW_PHOTO_REALESTATE__EDIT',
            photo,
        });

        dispatch({
            type: 'CHANGE_PHOTOS_REALESTATE__EDIT',
            photo,
        });

        return;
    },
    uploadDocument: async (file) => {
        try {
            let formData = new FormData();

            formData.append('uploadType', 'DOCUMENT');
            formData.append('file', file);

            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return data;
        } catch (err) {
            return message.error('Ошибка, попробуйте позже');
        }
    },
    uploadFile:
        (file, type, id = null, mainPhoto = false, settings = null) =>
        async (dispatch) => {
            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: true,
            });

            let formData = new FormData();
            formData.append('uploadType', type === 'layout_360' ? '360' : type);
            formData.append('file', file);
            formData.append('id', id);

            if (settings) {
                formData.append('settings', JSON.stringify(settings));
            }

            try {
                let { data } = await axios({
                    url: '/v1/admin/files',
                    method: 'post',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                dispatch({
                    type: 'REQUEST_UPLOAD_FILE',
                    status: false,
                });

                if (type == 'complex-image') {
                    dispatch({
                        type: 'PHOTO_APARTMENT_COMPLEX',
                        photo: data,
                    });
                } else if (type == 'images-complex-with-all-blocks') {
                    dispatch({
                        type: 'PHOTO_COMPLEX_WITH_BLOCKS',
                        photo: data,
                    });
                } else if (type == 'block-image') {
                    dispatch({
                        type: 'PHOTO_BLOCK',
                        photo: data,
                    });
                } else if (type == 'svg-image') {
                    dispatch({
                        type: 'PLAN_FLAT',
                        photo: data,
                    });
                } else if (type == 'IMAGE') {
                    if (mainPhoto) {
                        dispatch({
                            type: 'PHOTO_APARTMENT_COMPLEX',
                            photo: data,
                        });
                    }

                    dispatch({
                        type: 'APARTMENT_INTERIOR',
                        photo: data,
                    });
                } else if (type == 'IMAGE_PREVIEW') {
                    dispatch({
                        type: 'APARTMENT_PREVIEW_PHOTO',
                        photo: data,
                    });

                    if (settings && settings?.type === 'plan_photo') {
                        dispatch({
                            type: 'TEMPLATE_PLAN_PHOTO_FORM',
                            data,
                        });
                    }

                    if (settings && settings?.type === 'preview_photo') {
                        dispatch({
                            type: 'TEMPLATE_PREVIEW_PHOTO_FORM',
                            data,
                        });
                    }
                } else if (type === '360') {
                    dispatch({
                        type: 'PHOTO_APARTMENT_360',
                        photo: data,
                    });
                } else if (type === 'layout_360') {
                    dispatch({
                        type: 'PHOTO_LAYOUT_360',
                        photo: data,
                    });
                } else if (type === '360_PREVIEW') {
                    dispatch({
                        type: 'PHOTO_APARTMENT_360_PREVIEW',
                        photo: data,
                    });
                } else if (type === 'plan-floor') {
                    dispatch({
                        type: 'PHOTO_PLAN_FLOOR',
                        photo: data,
                    });
                } else if (type === 'IMAGE_FOR_POSTS') {
                    return data;
                } else if (type === 'LOGO_BUILDER') {
                    dispatch({
                        type: 'LOGO_PREVIEW_BUILDER',
                        photo: data,
                    });
                }
            } catch (e) {
                dispatch({
                    type: 'REQUEST_UPLOAD_FILE',
                    status: false,
                });

                if (e.response.status == 400) {
                    NotificationManager.warning(e.response.data);
                } else {
                    NotificationManager.warning(
                        'Ошибка при загрузке файла. Попробуйте позже'
                    );
                }
            }
        },
    uploadFile360: (file, type) => async (dispatch) => {
        //360
        //360_PREVIEW

        dispatch({
            type: 'REQUEST_UPLOAD_FILE',
            status: true,
        });

        let formData = new FormData();
        formData.append('uploadType', type);
        formData.append('file', file);

        try {
            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: false,
            });

            if (type == 'complex-image') {
                dispatch({
                    type: 'PHOTO_APARTMENT_COMPLEX',
                    photo: data,
                });
            } else if (type == 'block-image') {
                dispatch({
                    type: 'PHOTO_BLOCK',
                    photo: data,
                });
            } else if (type == 'svg-image') {
                dispatch({
                    type: 'PLAN_FLAT',
                    photo: data,
                });
            } else if (type == 'IMAGE') {
                dispatch({
                    type: 'APARTMENT_INTERIOR',
                    photo: data,
                });
            }
        } catch (e) {
            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: false,
            });

            if (e.response.status == 400) {
                NotificationManager.warning(e.response.data);
            } else {
                NotificationManager.warning(
                    'Ошибка при загрузке файла. Попробуйте позже'
                );
            }
        }
    },
    saveFileFlat:
        (file, price = 0) =>
        async (dispatch) => {
            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: true,
            });

            try {
                let { data } = await axios.post('/v1/admin/files/save-url', {
                    price,
                    paid: file.paid,
                    type: file.type,
                    filePath: file.url,
                });

                if (file.type == 'FLAT_ARRANGEMENT_AUTOCAD') {
                    dispatch({
                        type: 'FLAT_ARRANGEMENT_AUTOCAD',
                        id: data.id,
                    });
                } else if (file.type == 'APARTMENT_MODEL_3DMAX') {
                    dispatch({
                        type: 'APARTMENT_MODEL_3DMAX',
                        id: data.id,
                    });
                }

                NotificationManager.success('Файл успешно сохранен');

                dispatch({
                    type: 'REQUEST_UPLOAD_FILE',
                    status: false,
                });
            } catch (err) {
                dispatch({
                    type: 'REQUEST_UPLOAD_FILE',
                    status: false,
                });

                NotificationManager.warning('Ошибка. Попробуйте позже');
            }
        },
    uploadFileFlat: (file, price) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_UPLOAD_FILE',
            status: true,
        });

        let formData = new FormData();
        formData.append('uploadType', file.type);
        formData.append('price', price);
        formData.append('paid', file.paid);
        formData.append('file', file.file);

        try {
            let { data } = await axios({
                url: '/v1/admin/files',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (file.type == 'MATERIAL_BUDGET_EXCEL') {
                dispatch({
                    type: 'MATERIAL_BUDGET_EXCEL',
                    id: data.id,
                });
            } else if (file.type == 'MATERIAL_BUDGET_PDF') {
                dispatch({
                    type: 'MATERIAL_BUDGET_PDF',
                    id: data.id,
                });
            } else if (file.type == 'FLAT_ARRANGEMENT_AUTOCAD') {
                dispatch({
                    type: 'FLAT_ARRANGEMENT_AUTOCAD',
                    id: data.id,
                });
            } else if (file.type == 'APARTMENT_MODEL_3DMAX') {
                dispatch({
                    type: 'APARTMENT_MODEL_3DMAX',
                    id: data.id,
                });
            } else if (file.type == 'APARTMENT_DESIGN_REVIT') {
                dispatch({
                    type: 'APARTMENT_DESIGN_REVIT',
                    id: data.id,
                });
            } else if (file.type == 'APARTMENT_DESIGN_PDF') {
                dispatch({
                    type: 'APARTMENT_DESIGN_PDF',
                    id: data.id,
                });
            }

            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: false,
            });
        } catch (e) {
            dispatch({
                type: 'REQUEST_UPLOAD_FILE',
                status: false,
            });

            if (e.response.status == 400) {
                NotificationManager.warning(e.response.data);
            } else {
                NotificationManager.warning(
                    'Ошибка при загрузке файла. Попробуйте позже'
                );
            }
        }
    },
    searchFileOnLogovoServer: (word) => async (dispatch) => {
        dispatch({
            type: 'SEARCH_FILE_REQUEST_LOGOVO_SERVER',
            status: true,
        });

        try {
            let { data } = await axios.get(
                `https://file.logovo.kz/v1/files/search?word=${word}`
            );

            dispatch({
                type: 'SEARCH_FILE_LOGOVO_SERVER',
                files: data && data.length ? data : null,
            });

            dispatch({
                type: 'SEARCH_FILE_REQUEST_LOGOVO_SERVER',
                status: false,
            });
        } catch (err) {
            console.log(333, err);

            dispatch({
                type: 'SEARCH_FILE_REQUEST_LOGOVO_SERVER',
                status: false,
            });
        }
    },
};

export default files;
