import React, { useState } from 'react';
import styles from '../../../../styles/index.module.scss';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiDeleteSweep } from '@mdi/js';
import AddListButton from './add_list_button';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

const Item = ({
    item,
    api,
    params,
    dispatch,
    index,
    type_render,

    budget_template_info,
}) => {
    const [open, set_open] = useState(false);
    const [timeout, set_timeout] = useState(null);
    const stateBudget = useSelector((state) => state.budget);

    const ChangeInput = (val, type, type_sub_section, furniture_id) => {
        if (timeout) clearTimeout(timeout);

        set_timeout(
            setTimeout(() => {
                dispatch(
                    api.budget.saveBudgetCalculateInput(
                        item?.cat_id?._id,
                        type,
                        val.target.value,
                        type_sub_section,
                        furniture_id,
                        budget_template_info?._id,
                        item?.parent_category,
                        params
                    )
                );
            }, 500)
        );
    };

    const removeSection = (info, type_section) => {
        dispatch(
            api.budget.removeSectionFromBudgetRoom(
                info,
                item?.cat_id?._id,
                params,
                type_section
            )
        );
    };

    const renderAreaText = () => {
        if (type_render != 'DOOR') {
            return <>{`- ${item?.area ? item?.area : 0} м²`}</>;
        }
    };

    const removeItem = () => {
        dispatch(api.budget.removePremiseOrDoor(item._id, params, type_render));
    };

    return (
        <div
            className={`${styles.itemSectionPremise} ${
                stateBudget.toggle_calculate_cat == item?.cat_id?._id
                    ? styles.itemSectionPremiseOpen
                    : false
            }`}
            style={{
                zIndex: index,
            }}
        >
            <div
                className={`${styles.itemSectionPremiseHead} ${
                    stateBudget.toggle_calculate_cat == item?.cat_id?._id
                        ? styles.itemSectionPremiseHeadOpen
                        : false
                }`}
            >
                <div
                    className={styles.itemSectionPremiseHead}
                    onClick={() => {
                        set_open(!open);
                        dispatch({
                            type: 'BUDGET_MODAL_LIST_CATEGORIES',
                            status: false,
                            type_modal: false,
                            parent_cat_id: '',
                        });
                        dispatch({
                            type: 'TOGGLE_CALCULATE_CAT',
                            cat_id: item?.cat_id?._id,
                        });
                    }}
                >
                    <Icon
                        className={`${styles.iconItemPremise} ${
                            stateBudget.toggle_calculate_cat ==
                            item?.cat_id?._id
                                ? styles.iconItemPremiseOpened
                                : false
                        }`}
                        path={mdiChevronRight}
                        size={1.5}
                    />
                    <h5>
                        {item?.cat_id?.title} {renderAreaText()}
                    </h5>
                </div>
                <Popconfirm
                    title={
                        type_render == 'DOOR'
                            ? 'Дверь будет удалена'
                            : 'Помещение будет удалено'
                    }
                    onConfirm={removeItem}
                    onCancel={() => {}}
                    okText="Удалить"
                    cancelText="Нет"
                    placement="bottomLeft"
                >
                    <DeleteOutlined />
                </Popconfirm>
            </div>

            {
                <div
                    className={`${styles.itemSectionPremiseContent} ${
                        stateBudget.toggle_calculate_cat == item?.cat_id?._id
                            ? styles.itemSectionPremiseContentOpen
                            : false
                    }`}
                >
                    {type_render == 'DOOR' ? (
                        <div
                            className={`${styles.itemSectionPremiseContentSection}`}
                        >
                            <div
                                className={
                                    styles.itemSectionPremiseContentSectionInputs
                                }
                            >
                                <div
                                    className={
                                        styles.itemSectionPremiseContentSectionInputLeft
                                    }
                                    style={{
                                        width: '100%',
                                        marginTop: 10,
                                    }}
                                >
                                    <div>
                                        <b>Высота проема</b>
                                        <input
                                            placeholder={
                                                item?.aperture_height
                                                    ? item?.aperture_height
                                                    : 0
                                            }
                                            onChange={(val) =>
                                                ChangeInput(
                                                    val,
                                                    'aperture_height'
                                                )
                                            }
                                            type="text"
                                        />
                                        <small>мм</small>
                                    </div>
                                    <div>
                                        <b>Ширина проема</b>
                                        <input
                                            placeholder={
                                                item?.aperture_width
                                                    ? item?.aperture_width
                                                    : 0
                                            }
                                            onChange={(val) =>
                                                ChangeInput(
                                                    val,
                                                    'aperture_width'
                                                )
                                            }
                                            type="text"
                                        />
                                        <small>мм</small>
                                    </div>
                                    <div>
                                        <b>Количество</b>
                                        <input
                                            placeholder={
                                                item?.quantity_doors
                                                    ? item?.quantity_doors
                                                    : 0
                                            }
                                            onChange={(val) =>
                                                ChangeInput(
                                                    val,
                                                    'quantity_doors'
                                                )
                                            }
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${styles.itemSectionPremiseContentSection}`}
                        >
                            <h5>Площадь / Периметр</h5>
                            <div
                                className={
                                    styles.itemSectionPremiseContentSectionInputs
                                }
                            >
                                <div
                                    className={
                                        styles.itemSectionPremiseContentSectionInputLeft
                                    }
                                >
                                    <div>
                                        <b>Площадь</b>
                                        <input
                                            placeholder={
                                                item?.area ? item?.area : 0
                                            }
                                            onChange={(val) =>
                                                ChangeInput(val, 'area')
                                            }
                                            type="text"
                                        />
                                        <small>м²</small>
                                    </div>
                                    <div>
                                        <b>Периметр</b>
                                        <input
                                            placeholder={
                                                item?.perimeter
                                                    ? item?.perimeter
                                                    : 0
                                            }
                                            onChange={(val) =>
                                                ChangeInput(val, 'perimeter')
                                            }
                                            type="text"
                                        />
                                        <small>мм</small>
                                    </div>
                                </div>
                                <div
                                    className={
                                        styles.itemSectionPremiseContentSectionInputRight
                                    }
                                >
                                    <div>
                                        <b>L</b>
                                        <input
                                            placeholder={
                                                item?.wall_decor_l
                                                    ? item?.wall_decor_l
                                                    : 0
                                            }
                                            onChange={(val) =>
                                                ChangeInput(val, 'wall_decor_l')
                                            }
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <b>H</b>
                                        <input
                                            placeholder={
                                                item?.wall_decor_h
                                                    ? item?.wall_decor_h
                                                    : 0
                                            }
                                            onChange={(val) =>
                                                ChangeInput(val, 'wall_decor_h')
                                            }
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {type_render != 'DOOR' ? (
                        <>
                            <div className={styles.sectionTypeBlock}>
                                <div
                                    className={
                                        styles.itemSectionPremiseContentSection
                                    }
                                >
                                    <AddListButton
                                        open_cat={open}
                                        doorway_length={
                                            item?.doorways &&
                                            item?.doorways.length
                                                ? item?.doorways.length + 1
                                                : 1
                                        }
                                        parent_cat_id={item?.cat_id?._id}
                                        zIndex={10}
                                        type="DOORWAY"
                                        title="ДОБАВИТЬ ДВЕРНОЙ ПРОЕМ"
                                        params={params}
                                        styles={styles}
                                        dispatch={dispatch}
                                        api={api}
                                    />
                                </div>

                                <div className={styles.subCatItem}>
                                    {item?.doorways && item?.doorways.length ? (
                                        <>
                                            <h5>
                                                {item?.doorways.length > 1
                                                    ? 'Дверные проемы'
                                                    : 'Дверной проем'}
                                            </h5>
                                            <div
                                                className={
                                                    styles.subCatItemContent
                                                }
                                            >
                                                {item?.doorways.map(
                                                    (doorway) => (
                                                        <div
                                                            className={`${styles.itemSectionPremiseContentSection} ${styles.itemSectionPremiseContentSectionHelper}`}
                                                        >
                                                            <span
                                                                onClick={() =>
                                                                    removeSection(
                                                                        doorway,
                                                                        'DOORWAY'
                                                                    )
                                                                }
                                                                className={
                                                                    styles.deleteSectionButton
                                                                }
                                                                style={{
                                                                    top: 6,
                                                                }}
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiDeleteSweep
                                                                    }
                                                                    color="#fff"
                                                                    size={1}
                                                                />
                                                            </span>

                                                            <div
                                                                className={
                                                                    styles.itemSectionPremiseContentSectionInputs
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.itemSectionPremiseContentSectionInputLeft
                                                                    }
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <b>
                                                                            Высота
                                                                        </b>
                                                                        <input
                                                                            placeholder={
                                                                                doorway?.height
                                                                                    ? doorway?.height
                                                                                    : 0
                                                                            }
                                                                            onChange={(
                                                                                val
                                                                            ) =>
                                                                                ChangeInput(
                                                                                    val,
                                                                                    'doorway_height',
                                                                                    doorway?.type_doorway
                                                                                )
                                                                            }
                                                                            type="text"
                                                                        />
                                                                        <small>
                                                                            мм
                                                                        </small>
                                                                    </div>
                                                                    <div>
                                                                        <b>
                                                                            Ширина
                                                                        </b>
                                                                        <input
                                                                            placeholder={
                                                                                doorway?.width
                                                                                    ? doorway?.width
                                                                                    : 0
                                                                            }
                                                                            onChange={(
                                                                                val
                                                                            ) =>
                                                                                ChangeInput(
                                                                                    val,
                                                                                    'doorway_width',
                                                                                    doorway?.type_doorway
                                                                                )
                                                                            }
                                                                            type="text"
                                                                        />
                                                                        <small>
                                                                            мм
                                                                        </small>
                                                                    </div>
                                                                    <div>
                                                                        <b>
                                                                            Количество
                                                                        </b>
                                                                        <input
                                                                            placeholder={
                                                                                doorway?.quantity
                                                                                    ? doorway?.quantity
                                                                                    : 0
                                                                            }
                                                                            onChange={(
                                                                                val
                                                                            ) =>
                                                                                ChangeInput(
                                                                                    val,
                                                                                    'doorway_quantity',
                                                                                    doorway?.type_doorway
                                                                                )
                                                                            }
                                                                            type="text"
                                                                        />
                                                                        <small>
                                                                            шт.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <h6>Дверных проемом не добавлено</h6>
                                    )}
                                </div>
                            </div>

                            <div className={styles.sectionTypeBlock}>
                                <div
                                    className={`${styles.itemSectionPremiseContentSection} ${styles.itemSectionPremiseContentSectionIndex}`}
                                >
                                    <AddListButton
                                        open_cat={open}
                                        parent_cat_id={item?.cat_id?._id}
                                        zIndex={199}
                                        type="WINDOW"
                                        title="Добавить окно"
                                        params={params}
                                        styles={styles}
                                        dispatch={dispatch}
                                        api={api}
                                    />
                                </div>

                                <div className={styles.subCatItem}>
                                    {item.windows && item.windows.length ? (
                                        <div
                                            className={styles.subCatItemContent}
                                        >
                                            {item.windows.map(
                                                (window, index) => (
                                                    <div
                                                        className={`${styles.itemSectionPremiseContentSection} ${styles.itemSectionPremiseContentSectionHelper}`}
                                                    >
                                                        <span
                                                            onClick={() =>
                                                                removeSection(
                                                                    window,
                                                                    'WINDOW'
                                                                )
                                                            }
                                                            className={
                                                                styles.deleteSectionButton
                                                            }
                                                        >
                                                            <Icon
                                                                path={
                                                                    mdiDeleteSweep
                                                                }
                                                                color="#fff"
                                                                size={1}
                                                            />
                                                        </span>

                                                        <h5>
                                                            Окно #{index + 1}:{' '}
                                                            {window?.title}
                                                        </h5>
                                                        <div
                                                            className={
                                                                styles.itemSectionPremiseContentSectionInputs
                                                            }
                                                        >
                                                            {window.type_window ==
                                                            'WINDOW_1' ? (
                                                                <div
                                                                    className={
                                                                        styles.itemSectionPremiseContentSectionInputLeft
                                                                    }
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <b>
                                                                            Высота
                                                                        </b>
                                                                        <input
                                                                            placeholder={
                                                                                window?.height
                                                                                    ? window?.height
                                                                                    : 0
                                                                            }
                                                                            onChange={(
                                                                                val
                                                                            ) =>
                                                                                ChangeInput(
                                                                                    val,
                                                                                    'window_height',
                                                                                    window?.type_window_uniq
                                                                                )
                                                                            }
                                                                            type="text"
                                                                        />
                                                                        <small>
                                                                            мм
                                                                        </small>
                                                                    </div>
                                                                    <div>
                                                                        <b>
                                                                            Ширина
                                                                        </b>
                                                                        <input
                                                                            placeholder={
                                                                                window?.width
                                                                                    ? window?.width
                                                                                    : 0
                                                                            }
                                                                            onChange={(
                                                                                val
                                                                            ) =>
                                                                                ChangeInput(
                                                                                    val,
                                                                                    'window_width',
                                                                                    window?.type_window_uniq
                                                                                )
                                                                            }
                                                                            type="text"
                                                                        />
                                                                        <small>
                                                                            мм
                                                                        </small>
                                                                    </div>
                                                                    <div>
                                                                        <b>
                                                                            Количество
                                                                        </b>
                                                                        <input
                                                                            placeholder={
                                                                                window?.quantity
                                                                                    ? window?.quantity
                                                                                    : 0
                                                                            }
                                                                            onChange={(
                                                                                val
                                                                            ) =>
                                                                                ChangeInput(
                                                                                    val,
                                                                                    'window_quantity',
                                                                                    window?.type_window_uniq
                                                                                )
                                                                            }
                                                                            type="text"
                                                                        />
                                                                        <small>
                                                                            шт.
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={`${styles.itemSectionPremiseContentSectionInputLeftWidnowTwo}`}
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={`${styles.itemSectionPremiseContentSectionInputLeftWidnowTwoContent}`}
                                                                    >
                                                                        <div>
                                                                            <b>
                                                                                Высота
                                                                            </b>
                                                                            <input
                                                                                placeholder={
                                                                                    window?.height
                                                                                        ? window?.height
                                                                                        : 0
                                                                                }
                                                                                onChange={(
                                                                                    val
                                                                                ) =>
                                                                                    ChangeInput(
                                                                                        val,
                                                                                        'window_height',
                                                                                        window?.type_window_uniq
                                                                                    )
                                                                                }
                                                                                type="text"
                                                                            />
                                                                            <small>
                                                                                мм
                                                                            </small>
                                                                        </div>
                                                                        <div>
                                                                            <b>
                                                                                Ширина
                                                                            </b>
                                                                            <input
                                                                                placeholder={
                                                                                    window?.width
                                                                                        ? window?.width
                                                                                        : 0
                                                                                }
                                                                                onChange={(
                                                                                    val
                                                                                ) =>
                                                                                    ChangeInput(
                                                                                        val,
                                                                                        'window_width',
                                                                                        window?.type_window_uniq
                                                                                    )
                                                                                }
                                                                                type="text"
                                                                            />
                                                                            <small>
                                                                                мм
                                                                            </small>
                                                                        </div>
                                                                        <div>
                                                                            <p>
                                                                                Окно
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`${styles.itemSectionPremiseContentSectionInputLeftWidnowTwoContent}`}
                                                                    >
                                                                        <div>
                                                                            <b>
                                                                                Высота
                                                                            </b>
                                                                            <input
                                                                                placeholder={
                                                                                    window?.height
                                                                                        ? window?.height_two
                                                                                        : 0
                                                                                }
                                                                                onChange={(
                                                                                    val
                                                                                ) =>
                                                                                    ChangeInput(
                                                                                        val,
                                                                                        'window_height_two',
                                                                                        window?.type_window_uniq
                                                                                    )
                                                                                }
                                                                                type="text"
                                                                            />
                                                                            <small>
                                                                                мм
                                                                            </small>
                                                                        </div>
                                                                        <div>
                                                                            <b>
                                                                                Ширина
                                                                            </b>
                                                                            <input
                                                                                placeholder={
                                                                                    window?.width
                                                                                        ? window?.width_two
                                                                                        : 0
                                                                                }
                                                                                onChange={(
                                                                                    val
                                                                                ) =>
                                                                                    ChangeInput(
                                                                                        val,
                                                                                        'window_width_two',
                                                                                        window?.type_window_uniq
                                                                                    )
                                                                                }
                                                                                type="text"
                                                                            />
                                                                            <small>
                                                                                мм
                                                                            </small>
                                                                        </div>
                                                                        <div>
                                                                            <p>
                                                                                Окно
                                                                                -
                                                                                Балконная
                                                                                дверь
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`${styles.itemSectionPremiseContentSectionInputLeftWidnowTwoContent}`}
                                                                    >
                                                                        <div>
                                                                            <b>
                                                                                Количество
                                                                            </b>
                                                                            <input
                                                                                placeholder={
                                                                                    window?.quantity
                                                                                        ? window?.quantity
                                                                                        : 0
                                                                                }
                                                                                onChange={(
                                                                                    val
                                                                                ) =>
                                                                                    ChangeInput(
                                                                                        val,
                                                                                        'window_quantity',
                                                                                        window?.type_window_uniq
                                                                                    )
                                                                                }
                                                                                type="text"
                                                                            />
                                                                            <small>
                                                                                шт.
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        <h6>Окон не добавлено</h6>
                                    )}
                                </div>
                            </div>

                            <div className={styles.sectionTypeBlock}>
                                <div
                                    className={
                                        styles.itemSectionPremiseContentSection
                                    }
                                >
                                    <AddListButton
                                        open_cat={open}
                                        parent_cat_id={item?.cat_id?._id}
                                        zIndex={10}
                                        type="MEBEL"
                                        title="Добавить мебель"
                                        params={params}
                                        styles={styles}
                                        dispatch={dispatch}
                                        api={api}
                                    />
                                </div>

                                <div className={styles.subCatItem}>
                                    {item?.furniture &&
                                    item?.furniture.length ? (
                                        <div
                                            className={styles.subCatItemContent}
                                        >
                                            {item?.furniture.map(
                                                (furniture) => (
                                                    <div
                                                        className={`${styles.itemSectionPremiseContentSection} ${styles.itemSectionPremiseContentSectionHelper}`}
                                                    >
                                                        <span
                                                            onClick={() =>
                                                                removeSection(
                                                                    furniture,
                                                                    'FURNITURE'
                                                                )
                                                            }
                                                            className={
                                                                styles.deleteSectionButton
                                                            }
                                                        >
                                                            <Icon
                                                                path={
                                                                    mdiDeleteSweep
                                                                }
                                                                color="#fff"
                                                                size={1}
                                                            />
                                                        </span>

                                                        <h5>
                                                            {furniture?.type_mebel ==
                                                            'MEBEL_1'
                                                                ? 'Мебель'
                                                                : 'Мебель - Кухня'}
                                                        </h5>
                                                        <div
                                                            className={
                                                                styles.itemSectionPremiseContentSectionInputs
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.itemSectionPremiseContentSectionInputLeft
                                                                }
                                                            >
                                                                <div>
                                                                    <b>Длина</b>
                                                                    <input
                                                                        placeholder={
                                                                            furniture?.width
                                                                                ? furniture?.width
                                                                                : 0
                                                                        }
                                                                        onChange={(
                                                                            val
                                                                        ) =>
                                                                            ChangeInput(
                                                                                val,
                                                                                'furniture_length',
                                                                                '',
                                                                                furniture?.type_furniture
                                                                            )
                                                                        }
                                                                        type="text"
                                                                    />
                                                                    <small>
                                                                        мм
                                                                    </small>
                                                                </div>
                                                                <div>
                                                                    <b>
                                                                        Количество
                                                                    </b>
                                                                    <input
                                                                        placeholder={
                                                                            furniture?.quantity
                                                                                ? furniture?.quantity
                                                                                : 0
                                                                        }
                                                                        onChange={(
                                                                            val
                                                                        ) =>
                                                                            ChangeInput(
                                                                                val,
                                                                                'furniture_quantity',
                                                                                '',
                                                                                furniture?.type_furniture
                                                                            )
                                                                        }
                                                                        type="text"
                                                                    />
                                                                    <small>
                                                                        шт.
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        <h6>Мебель не добавлена</h6>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            }
        </div>
    );
};

export default Item;
