import React, { useState } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm } from 'antd';

const CriteriaItems = ({ stateLibrary, dispatch, api, styles, type }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [status_edit, set_status_edit] = useState(false);
    const [title_material, set_title_material] = useState('');
    const [material_id, set_material_id] = useState('');

    const addCriteria = () => {
        clearState();
        return dispatch(api.library.addCriteria(title_material, type));
    };

    const editCriteria = () => {
        clearState();

        return dispatch(
            api.library.editCriteria(title_material, material_id, type)
        );
    };

    const deleteMaterial = (id) => {
        return dispatch(api.library.deleteCriteria(id, type));
    };

    const clearState = () => {
        set_title_material('');
        setIsModalVisible(false);
        set_status_edit(false);
        set_material_id('');
    };

    return (
        <>
            <Modal
                title={
                    status_edit
                        ? 'Форма редактирования материала'
                        : 'Форма добавления материала'
                }
                visible={isModalVisible}
                onOk={() => {
                    if (status_edit) {
                        editCriteria();
                    } else {
                        addCriteria();
                    }
                }}
                onCancel={clearState}
            >
                <Input
                    value={title_material}
                    onChange={(value) => set_title_material(value.target.value)}
                    placeholder="Название материала"
                />
            </Modal>

            {stateLibrary?.criteria && stateLibrary?.criteria.length ? (
                <Button
                    onClick={() => setIsModalVisible(!isModalVisible)}
                    type="primary"
                >
                    Добавить
                </Button>
            ) : (
                <></>
            )}

            {stateLibrary?.request ? (
                <div className={styles.defaultBlock}>
                    <LoadingOutlined className={styles.defaultBlockLoader} />
                </div>
            ) : stateLibrary?.criteria && stateLibrary?.criteria.length ? (
                stateLibrary?.criteria.map((item) => {
                    return (
                        <div className={styles.itemCriteria}>
                            <span>{item?.value}</span>
                            <div className={styles.itemCriteriaButtons}>
                                <EditOutlined
                                    onClick={() => {
                                        set_status_edit(true);
                                        set_title_material(item?.value);
                                        setIsModalVisible(true);
                                        set_material_id(item?._id);
                                    }}
                                    className={styles.itemCriteriaButton}
                                    style={{ marginRight: 5 }}
                                />

                                <Popconfirm
                                    title="Материал будет удален"
                                    onConfirm={() => deleteMaterial(item?._id)}
                                    onCancel={() => {}}
                                    okText="Удалить"
                                    cancelText="Нет"
                                    placement="bottomLeft"
                                >
                                    <DeleteOutlined
                                        className={styles.itemCriteriaButton}
                                    />
                                </Popconfirm>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className={styles.defaultBlock}>
                    <h5>Информации нет</h5>
                    <Button
                        onClick={() => setIsModalVisible(!isModalVisible)}
                        type="primary"
                    >
                        Добавить
                    </Button>
                </div>
            )}
        </>
    );
};

export default CriteriaItems;
