"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerTheme = exports.getTheme = void 0;
var util_1 = require("@antv/util");
var util_2 = require("./util");
var defaultTheme = (0, util_2.createTheme)({});
var darkTheme = (0, util_2.createDarkTheme)({});
// 所有已经存在的主题
var Themes = {
    default: defaultTheme,
    light: defaultTheme,
    dark: darkTheme,
};
/**
 * 获取主题配置信息。
 */
function getTheme(theme) {
    if (theme === void 0) { theme = 'default'; }
    return (0, util_1.get)(Themes, (0, util_1.lowerCase)(theme), Themes['default']);
}
exports.getTheme = getTheme;
/**
 * 注册新的主题配置信息。
 */
function registerTheme(theme, value, type) {
    if (type === void 0) { type = 'light'; }
    Themes[(0, util_1.lowerCase)(theme)] = type === 'light' ? (0, util_2.createTheme)(value) : (0, util_2.createDarkTheme)(value);
}
exports.registerTheme = registerTheme;
