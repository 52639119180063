import VirtualTours from '@/Containers/Admin/VirtualTours';
import EditVirtualTour from '@/Containers/Admin/VirtualTours/forms/Edit';

export default [
    {
        path: '/virtual-tours',
        element: <VirtualTours />,
    },
    {
        path: '/virtual-tours/edit/:id',
        element: <EditVirtualTour />,
    },
];
