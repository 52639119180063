const initialState = {
    list: null,

    search_plans: null,

    widget_info: null,

    widget_edit: null,

    request_status: false,
    create_status: false,

    widgets_of_group: [],
};

const widgets = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_WIDGET_FLATS_TOUR_EDIT':
            return {
                ...state,
                widget_edit: {
                    ...state?.widget_edit,
                    flats: action?.data || []
                }
            };
        case 'UPDATE_WIDGET_REALESTATE_EDIT':
            return {
                ...state,
                widget_edit: {
                    ...state?.widget_edit,
                    realestate: action?.data || []
                }
            };
        case 'REMOVE_WIDGET':
            return {
                ...state,
                list: {
                    ...state.list,
                    data: state?.list?.data?.filter(item => String(item?._id) != String(action?.id))
                }
            };
        case 'UPDATE_WIDGET_EDIT':
            return {
                ...state,
                widget_edit: action.data || null
            };
        case 'DELETE_WIDGET_Of_GROUP':
            return {
                ...state,
                widgets_of_group: state.widgets_of_group.filter(
                    (item) => item?._id != action?.id
                ),
            };

        case 'UPDATE_WIDGETS_OF_GROUP':
            return {
                ...state,
                widgets_of_group: action.data,
            };

        case 'UPDATE_WIDGETS':
            return {
                ...state,
                list: action.data,
            };

        case 'UPDATE_WIDGET_FOR_EDIT':
            return {
                ...state,
                widget_edit: action.data || null,
            };

        case 'SEARCH_PLANS':
            return {
                ...state,
                search_plans: action.data,
            };

        case 'UPDATE_WIDGET_INFO':
            return {
                ...state,
                widget_info: action.data,
            };

        case 'REQUEST_STATUS_WIDGET':
            return {
                ...state,
                request_status: action.status,
            };

        case 'CREATE_STATUS_WIDGET':
            return {
                ...state,
                create_status: action.status,
            };

        default:
            return state;
    }
};

export default widgets;
