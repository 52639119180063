export const checkPoints = (points = []) => {
    points = points.sort(
        (a, b) =>
            Number(b.floor) - Number(a.floor) || Number(b.box) - Number(a.box)
    );

    if (points.length === 2) {
        const p1 = points[0];
        const p2 = points[1];

        const stepOne =
            Number(p1.floor) !== Number(p2.floor) &&
            Number(p1.box) !== Number(p2.box);

        const stepTwo = Math.abs(Number(p1.floor) - Number(p2.floor));
        const stepThree = Math.abs(Number(p1.box) - Number(p2.box));
        return !stepOne && stepTwo <= 1 && stepThree <= 1;
    } else if (points.length === 3) {
        const p1 = points[0];
        const p2 = points[1];
        const p3 = points[2];
        return isValid(p1, p2, p3);
    } else {
        return false;
    }
};

export const checkColumnPoints = (points) => {
    if (points.length > 0) {
        const firstElement = Number(points[0].box);

        for (let i = 1; i < points.length; i++) {
            if (Number(points[i].box) !== firstElement) {
                return false;
            }
        }

        return true;
    } else {
        return false;
    }
};

const isValid = (p1, p2, p3) => {
    const p1Floor = Number(p1.floor);
    const p2Floor = Number(p2.floor);
    const p3Floor = Number(p2.floor);
    const p1Box = Number(p1.box);
    const p2Box = Number(p2.box);
    const p3Box = Number(p3.box);

    const isHorizontal = p1Floor === p2Floor && p2Floor === p3Floor;
    const isVertical = p1Box === p2Box && p2Box === p3Box;

    const dx1 = p2Box - p1Box;
    const dy1 = p2Floor - p1Floor;
    const dx2 = p3Box - p2Box;
    const dy2 = p3Floor - p2Floor;
    const angle = Math.atan2(dy1, dx1) - Math.atan2(dy2, dx2);
    const angleDegrees = Math.abs((angle * 180) / Math.PI);
    const isAngleValid = angleDegrees < 180;

    const isDistanceValid =
        Math.abs(p1Box - p2Box) <= 1 &&
        Math.abs(p1Floor - p2Floor) <= 1 &&
        Math.abs(p2Box - p3Box) <= 1 &&
        Math.abs(p2Floor - p3Floor) <= 1;

    return (isHorizontal || isVertical) && isAngleValid && isDistanceValid;
};
