import React, { useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { api } from '../../../../Services/';

import EditPostForm from './Helpers/form';

const EditBlog = () => {
    const history = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();

    const getPosts = useSelector((state) => state.posts);

    useEffect(() => {
        dispatch(api.blog.getById(id));
    }, []);

    return useMemo(
        () => (
            <content className="page-editor-block">
                <h1 className="title-page">Редактирование поста</h1>

                {getPosts.request_status ? (
                    <h4>Загружаем информацию</h4>
                ) : (
                    <EditPostForm
                        api={api}
                        id={id}
                        history={history}
                        dispatch={dispatch}
                        item={getPosts.page_by_id}
                    />
                )}
            </content>
        ),
        [getPosts]
    );
};

export default EditBlog;
