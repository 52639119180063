import React, { useEffect, useState } from 'react';
import styles from '../../styles/index.module.scss';
import { Button, Input, Popover } from 'antd';
import MaskInput from 'react-maskinput';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import config from '@/Config';

const EditShop = () => {
    const routeParams = useParams();
    const dispatch = useDispatch();
    const history = useNavigate();
    let getFiles = useSelector((state) => state.files);
    let stateLibrary = useSelector((state) => state.library);

    const [title, set_title] = useState('');
    const [phone, set_phone] = useState('');
    const [address, set_address] = useState('');
    const [instagram, set_instagram] = useState('');
    const [whatsapp, set_whatsapp] = useState('');

    useEffect(() => {
        dispatch(api.library.getShop(routeParams?.id));
    }, []);

    useEffect(() => {
        set_title(stateLibrary?.shop_item_edit?.title || '');
        set_phone(stateLibrary?.shop_item_edit?.phone || '');
        set_address(stateLibrary?.shop_item_edit?.address || '');
        set_instagram(stateLibrary?.shop_item_edit?.instagram || '');
        set_whatsapp(stateLibrary?.shop_item_edit?.whatsapp || '');

        dispatch({
            type: 'APARTMENT_PREVIEW_PHOTO',
            photo: {
                full_url: `${config.public}${stateLibrary?.shop_item_edit?.icon?.url}`,
                id: stateLibrary?.shop_item_edit?.icon?._id,
                url: stateLibrary?.shop_item_edit?.icon?.url,
            },
        });
    }, [stateLibrary?.shop_item_edit]);

    const editShop = () => {
        return dispatch(
            api.library.editShop(
                title,
                getFiles?.PHOTO_PREVIEW?.id,
                routeParams?.id,
                phone,
                address,
                instagram,
                history,
                whatsapp
            )
        );
    };

    return (
        <div className={styles.mainBlock}>

            <div className={styles.formBlock}>
                <section className={styles.formSection}>
                    <Popover title="" content="Название магазина">
                        <Input
                            size="large"
                            value={title}
                            onChange={(val) => set_title(val.target.value)}
                            placeholder="Название магазина"
                        />
                    </Popover>
                </section>

                <section className={styles.formSection}>
                    <Popover title="" content="Адрес магазина">
                        <Input
                            size="large"
                            value={address}
                            onChange={(val) => set_address(val.target.value)}
                            placeholder="Адрес магазина"
                        />
                    </Popover>
                </section>

                <section className={styles.formSection}>
                    <Popover title="" content="Instagram магазина">
                        <Input
                            size="large"
                            value={instagram}
                            onChange={(val) => set_instagram(val.target.value)}
                            placeholder="Instagram магазина"
                        />
                    </Popover>
                </section>

                <section className={styles.formSection}>
                    <Popover title="" content="Контактный телефон">
                        <MaskInput
                            className={styles.formSectionMaskInput}
                            onChange={(e) => set_phone(e.target.value)}
                            alwaysShowMask
                            value={phone}
                            mask={'+7 (000) 000 - 0000'}
                            size={20}
                            showMask
                            maskChar="_"
                        />
                    </Popover>
                </section>

                <section className={styles.formSection}>
                    <Popover title="" content="Whatsapp">
                        <MaskInput
                            className={styles.formSectionMaskInput}
                            onChange={(e) => set_whatsapp(e.target.value)}
                            alwaysShowMask
                            value={whatsapp}
                            mask={'+7 (000) 000 - 0000'}
                            size={20}
                            showMask
                            maskChar="_"
                        />
                    </Popover>
                </section>

                <section className={styles.formSection}>
                    <div className={styles.formSectionUploadFile}>
                        <h3>Логотип магазина</h3>
                        <Button
                            type="primary"
                            className={styles.formSectionFile}
                        >
                            {getFiles.request_upload_status ? (
                                <LoadingOutlined />
                            ) : (
                                <span>Выбрать картинку</span>
                            )}
                            {!getFiles.request_upload_status && (
                                <input
                                    onChange={(val) =>
                                        dispatch(
                                            api.files.uploadFile(
                                                val.target.files[0],
                                                'IMAGE_PREVIEW'
                                            )
                                        )
                                    }
                                    type="file"
                                />
                            )}
                        </Button>

                        {getFiles.PHOTO_PREVIEW ? (
                            <div
                                className="icon-preview-category"
                                style={{
                                    background: `url(${getFiles.PHOTO_PREVIEW.full_url}) center / contain no-repeat`,
                                }}
                            ></div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </section>
            </div>

            <Button type="primary" onClick={editShop}>
                Редактировать
            </Button>
        </div>
    );
};

export default EditShop;
