"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Area = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var plot_1 = require("../../core/plot");
var constants_1 = require("./constants");
var area_layer_1 = require("../../layers/area-layer");
var layer_group_1 = require("../../core/layer/layer-group");
var Area = /** @class */ (function (_super) {
    tslib_1.__extends(Area, _super);
    function Area() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图表类型
         */
        _this.type = plot_1.Plot.PlotType.Area;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Area.prototype.getDefaultOptions = function () {
        return Area.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Area.prototype.createLayers = function (source) {
        this.areaLayer = new area_layer_1.AreaLayer(tslib_1.__assign({ source: source }, (0, util_1.pick)(this.options, area_layer_1.AreaLayer.LayerOptionsKeys)));
        var layerGroup = new layer_group_1.LayerGroup([this.areaLayer]);
        if (this.options.label) {
            this.labelLayer = this.createLabelLayer(source, this.options.label, this.options);
            layerGroup.addLayer(this.labelLayer);
        }
        return layerGroup;
    };
    /**
     * 更新图层
     */
    Area.prototype.updateLayers = function (options) {
        var polygonLayerConfig = (0, util_1.pick)(options, area_layer_1.AreaLayer.LayerOptionsKeys);
        this.areaLayer.update(polygonLayerConfig);
        this.updateLabelLayer(this.source, options.label, this.options, this.labelLayer);
    };
    /**
     * 初始化图层事件
     */
    Area.prototype.initLayersEvent = function () {
        //
    };
    /**
     * 实现 legend 配置项
     */
    Area.prototype.getLegendOptions = function () {
        var colorLegendItems = this.areaLayer.getColorLegendItems();
        if (colorLegendItems.length !== 0) {
            return { type: 'category', items: colorLegendItems };
        }
        return {};
    };
    /**
     * 默认配置项
     */
    Area.DefaultOptions = constants_1.DEFAULT_OPTIONS;
    return Area;
}(plot_1.Plot));
exports.Area = Area;
