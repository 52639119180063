"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCountryBoundaryLayer = void 0;
var utils_1 = require("../../utils");
var path_layer_1 = require("../../layers/path-layer");
var constants_1 = require("./constants");
/**
 * 创建中国国界线图层
 */
var createCountryBoundaryLayer = function (data, plotConfig) {
    var _a = plotConfig || {}, visible = _a.visible, minZoom = _a.minZoom, maxZoom = _a.maxZoom, _b = _a.zIndex, zIndex = _b === void 0 ? 0 : _b, chinaBorder = _a.chinaBorder;
    var borderStyle = typeof chinaBorder === 'object' ? (0, utils_1.deepAssign)({}, constants_1.CHINA_BOUNDARY_STYLE, chinaBorder) : constants_1.CHINA_BOUNDARY_STYLE;
    var chinaBoundaryFeatures = data.features.filter(function (_a) {
        var properties = _a.properties;
        return ['coast', 'national'].includes(properties === null || properties === void 0 ? void 0 : properties['type']);
    });
    var disputeBoundaryFeatures = data.features.filter(function (_a) {
        var properties = _a.properties;
        return (properties === null || properties === void 0 ? void 0 : properties['type']) === 'dispute';
    });
    var hkmBoundaryFeatures = data.features.filter(function (_a) {
        var properties = _a.properties;
        return (properties === null || properties === void 0 ? void 0 : properties['type']) === 'hkm';
    });
    var chinaBoundaryLayer = new path_layer_1.PathLayer({
        name: 'chinaBoundaryLayer',
        visible: visible,
        minZoom: minZoom,
        maxZoom: maxZoom,
        zIndex: zIndex + 0.1,
        source: {
            data: { type: 'FeatureCollection', features: chinaBoundaryFeatures },
            parser: { type: 'geojson' },
        },
        color: {
            field: 'type',
            value: function (_a) {
                var type = _a.type;
                return borderStyle[type].color;
            },
        },
        size: {
            field: 'type',
            value: function (_a) {
                var type = _a.type;
                return borderStyle[type].width;
            },
        },
        style: {
            opacity: ['type', function (type) { return borderStyle[type].opacity; }],
        },
    });
    var chinaHkmBoundaryLayer = new path_layer_1.PathLayer({
        name: 'chinaHkmBoundaryLayer',
        visible: visible,
        minZoom: minZoom,
        maxZoom: maxZoom,
        zIndex: zIndex + 0.1,
        source: {
            data: { type: 'FeatureCollection', features: hkmBoundaryFeatures },
            parser: { type: 'geojson' },
        },
        color: borderStyle.hkm.color,
        size: borderStyle.hkm.width,
        style: {
            opacity: borderStyle.hkm.opacity,
            lineType: 'dash',
            dashArray: borderStyle.hkm.dashArray,
        },
    });
    var chinaDisputeBoundaryLayer = new path_layer_1.PathLayer({
        name: 'chinaDisputeBoundaryLayer',
        visible: visible,
        minZoom: minZoom,
        maxZoom: maxZoom,
        zIndex: zIndex + 0.1,
        source: {
            data: { type: 'FeatureCollection', features: disputeBoundaryFeatures },
            parser: { type: 'geojson' },
        },
        color: borderStyle.dispute.color,
        size: borderStyle.dispute.width,
        style: {
            opacity: borderStyle.dispute.opacity,
            lineType: 'dash',
            dashArray: borderStyle.dispute.dashArray,
        },
    });
    return { chinaBoundaryLayer: chinaBoundaryLayer, chinaHkmBoundaryLayer: chinaHkmBoundaryLayer, chinaDisputeBoundaryLayer: chinaDisputeBoundaryLayer };
};
exports.createCountryBoundaryLayer = createCountryBoundaryLayer;
