const initialState = {
    logedIn: false,

    canvas: null,
    requestLogin: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_CANVAS':
            return {
                ...state,
                canvas: action.canvas,
            };

        case 'REQUEST_STATUS_LOGIN':
            return {
                ...state,
                requestLogin: action.status,
            };

        case 'UPDATE_USER_LOGGEDIN':
            return {
                ...state,
                logedIn: action.logedIn,
            };

        default:
            return state;
    }
};

export default auth;
