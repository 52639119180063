import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import ReactPaginate from 'react-paginate';

import {api} from '../../../../Services';
import styles from '../styles/index.module.scss';

const ConnectionsLog = () => {

	const dispatch = useDispatch();
	const logState = useSelector(state => state.logs);

	useEffect(() => {

		dispatch(api.logs.getConnectionsInformation());
		dispatch(api.logs.getConnections(1));

	}, [])

	const handlePageClick = (event) => {
		dispatch(api.logs.getConnections(Number(event.selected + 1)));
	};

	return (
		<div className="statistic-block">
			<h1>Отладчик/Посещаемость</h1>

			{
				!logState.status_loading_connection_information ?
					<div className={styles.pagination}>
						<ReactPaginate
							breakLabel="..."
							nextLabel=">"
							onPageChange={handlePageClick}
							pageRangeDisplayed={2}
							pageCount={logState?.connection_information?.pages || 1}
							previousLabel="<"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					</div>
					:
					<p>...</p>
			}

			<div className={styles.listConnections}>
				{
					!logState.status_loading_connections ?
						logState.connections && logState.connections.length ?
							logState.connections.map((item) => {
								return (
									<div className={styles.itemConnection} key={item._id}>
										<h6><b>IP: </b>{item.ip}</h6>
										<h6><b>Страна: </b>{item.country}</h6>
										<h6><b>Запросы: </b>{item.requests}</h6>
										<h6><b>referer: </b>{item.referer}</h6>
										<h6><b>url: </b>{item.url_request}</h6>
									</div>
								)
							})
							:
							<h5>Информации нет</h5>
						:
						<h1>...</h1>
				}
			</div>

		</div>
	)
}

export default ConnectionsLog;