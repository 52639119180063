import axios from '../axios';
import { message } from 'antd';

const blog = {
    get: () => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_POSTS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/blog');

            dispatch({
                type: 'UPDATE_POSTS',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_POSTS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_POSTS',
                status: false,
            });

            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
    getById: (id) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_POST_BY_ID',
            data: null,
        });

        dispatch({
            type: 'REQUEST_STATUS_POSTS',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/blog/get-by-id/${id}`);

            dispatch({
                type: 'UPDATE_POST_BY_ID',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_POSTS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_POSTS',
                status: false,
            });

            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
    create: (fields, history) => async () => {
        let { title, metaTitle, metaDesc, metaKeyWords, content } = fields;

        if (
            title &&
            metaTitle &&
            metaDesc &&
            metaDesc &&
            metaKeyWords &&
            content
        ) {
            try {
                let { data } = await axios.post('/v1/admin/blog/create', fields);

                message.success(data);
                return history('/blog');
            } catch (err) {
                message.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
                return;
            }
        }

        message.error('Заполните поля');
    },
    update: (fields, history, id) => async () => {
        let { title, metaTitle, metaDesc, metaKeyWords, content } = fields;

        fields.id = id;

        if (
            title &&
            metaTitle &&
            metaDesc &&
            metaDesc &&
            metaKeyWords &&
            content
        ) {
            try {
                let { data } = await axios.post('/v1/admin/blog/update', fields);

                message.success(data);
                return history('/blog');
            } catch (err) {
                message.error(
                    'Упс, ошибка. Проверьте Ваши права администратора'
                );
                return;
            }
        }

        message.error('Заполните поля');
    },
    delete: (id) => async (dispatch) => {
        try {
            let { data } = await axios.post('/v1/admin/blog/remove', {
                id,
            });

            message.success(data);
            return dispatch(blog.get());
        } catch (err) {
            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
};

export default blog;
