import React, { useState } from 'react';
import styles from '../styles/index.module.scss';
import { Dropdown, Menu, message, Modal, Select, Input, Tooltip } from 'antd';
import {
    EllipsisOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
    CheckOutlined,
    StopOutlined
} from '@ant-design/icons';
import { AccountCircle } from '@material-ui/icons';
import moment from 'moment';
import config from '@/Config';
const { Option } = Select;

const ItemUser = ({ user, dispatch, api, roles }) => {
    const [selectUserRole, setSelectUserRole] = useState('');
    const [isModalRoleVicible, setIsModalRoleVicible] = useState(false);
    const [type_modal, set_type_modal] = useState('');

    const [password, set_password] = useState('');
    const [repeat_password, set_repeat_password] = useState('');

    const blockUser = () => {
        dispatch(api.users.block(user));
    };

    const devStatus = () => {
        return message.warning('Находится в разработке');
    };

    const getUserTools = () => {
        return dispatch(api.users.getUserToolsInfo(user?._id));
    }

    const menuSettings = (
        <Menu>
            <Menu.Item key="2" onClick={() => blockUser()}>
                {user?.is_banned ? 'Разблокировать' : 'Заблокировать'}
            </Menu.Item>
            <Menu.Item key="3" onClick={() => devStatus()}>
                Изменить аватар
            </Menu.Item>
            <Menu.Item
                key="4"
                onClick={() => {
                    set_type_modal('RESET_PWD');
                    setIsModalRoleVicible(true);
                }}
            >
                Изменить пароль
            </Menu.Item>
            <Menu.Item key="5" onClick={() => devStatus()}>
                Отправить смс
            </Menu.Item>
            <Menu.Item key="6" onClick={() => devStatus()}>
                Отправить push уведомление
            </Menu.Item>
            <Menu.Item key="7" onClick={getUserTools}>
                Настройки
            </Menu.Item>
        </Menu>
    );

    const eventFormUser = () => {
        if (type_modal === 'RESET_PWD') {
            if (!password && !repeat_password) {
                return message.warning('Заполните поля');
            }

            if (password !== repeat_password) {
                return message.warning('Пароли не совпадают');
            }

            setIsModalRoleVicible(false);

            set_type_modal('');
            return dispatch(
                api.users.resetPwdUser(password, repeat_password, user?._id)
            );
        }
        setIsModalRoleVicible(false);
        return dispatch(api.users.changeRoleUser(user, selectUserRole));
    };

    return (
        <div className={`item-user ${styles.userItem}`}>
            <Modal
                title={
                    type_modal === 'RESET_PWD'
                        ? 'Изменить пароль пользователя'
                        : 'Изменить права пользователя'
                }
                open={isModalRoleVicible}
                onOk={eventFormUser}
                onCancel={() => setIsModalRoleVicible(false)}
            >
                {type_modal === 'RESET_PWD' ? (
                    <>
                        <Input.Password
                            size="large"
                            onChange={(val) => {
                                set_password(val.target.value);
                            }}
                            placeholder="Пароль"
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                        />
                        <Input.Password
                            size="large"
                            placeholder="Повторите пароль"
                            onChange={(val) => {
                                set_repeat_password(val.target.value);
                            }}
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                        />
                    </>
                ) : (
                    <>
                        <h5>
                            Выберите права для {user?.firstname} ({user?.email})
                        </h5>
                        <h6>
                            В данный момент пользователь владеет правами:{' '}
                            <b>{user?.role?.title}</b>
                        </h6>

                        <Select
                            value={selectUserRole}
                            className={styles.select}
                            placeholder="Выберите роль"
                            onChange={(value) => {
                                setSelectUserRole(value);
                            }}
                            style={{
                                width: 450,
                            }}
                            size="large"
                            dropdownStyle={{
                                zIndex: 999999,
                            }}
                        >
                            <Option value="">Выберите роль</Option>
                            {roles && roles.length ? (
                                roles.map((item) => (
                                    <Option
                                        value={item?._id}
                                        label={item?.title}
                                    >
                                        {item?.title}
                                    </Option>
                                ))
                            ) : (
                                <></>
                            )}
                        </Select>
                    </>
                )}
            </Modal>

            <Dropdown
                trigger={['click']}
                className={styles.Dropdown}
                overlay={menuSettings}
            >
                <EllipsisOutlined
                    onClick={(e) => e.preventDefault()}
                    rotate={90}
                    className={styles.userItemSettingIcon}
                />
            </Dropdown>

            <div className={`user-block-avatar ${styles.useravatarBlock}`}>
                <div className="user-block-avatar-overlay">
                    {user?.shop?.icon?.url ? (
                        <div
                            style={{
                                background: `url(${config.public}${user?.shop?.icon?.url}) center / cover no-repeat`,
                            }}
                            className={styles.userAvatar}
                        ></div>
                    ) : (
                        <AccountCircle
                            style={{
                                fontSize: 84,
                                color: 'rgba(0,0,0,0.18)',
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={`user-information ${styles.userInfo}`}>
                <h4>{user.firstname}</h4>
                <Tooltip
                    title={user?.mail_confirmed ? 'Подтверждено' : 'Не подтверждено'}
                >
                    <div className={styles.email}>
                        <h5>{user.email}</h5>
                        {user?.mail_confirmed ? <CheckOutlined style={{background: '#9cbb3e'}}/> : <StopOutlined style={{background: '#df6464'}} />}
                    </div>
                </Tooltip>
                <span>{user?.role?.title}</span>
                <span
                    style={{
                        background: user?.is_banned ? '#c82847' : '#74bf18',
                    }}
                    className={styles.blockUserStatus}
                >
                    {user?.is_banned ? 'Заблокирован' : 'Активен'}
                </span>

                <div className={`user-date-regisration ${styles.dateReg}`}>
                    <h4>
                        На сайте с{' '}
                        {moment(user.createdAt).format('DD.MM.YYYY hh:mm')}
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default ItemUser;
