import Logs from '@/Containers/Admin/Logs/index';
import SyncLog from '@/Containers/Admin/Logs/Sync';
import SyncLogBig from '@/Containers/Admin/Logs/Sync/Big';
import ShopLog from '@/Containers/Admin/Logs/shops';
import ConnectionsLog from '@/Containers/Admin/Logs/Connections';
import SyncLogBitrix from '@/Containers/Admin/Logs/Sync/Bitrix';
import AI_search from '@/Containers/Admin/Logs/AI_search';
import XmlFeeds from '@/Containers/Admin/Logs/xml-feeds';
import SyncLogMacro from '@/Containers/Admin/Logs/Sync/Macro';
import SyncMWC from '@/Containers/Admin/Logs/Sync/MWC';

export default [
    {
        path: '/logs',
        element: <Logs />,
    },
    {
        path: '/logs/shops',
        element: <ShopLog />,
    },
    {
        path: '/logs/xml-feeds',
        element: <XmlFeeds />,
    },
    {
        path: '/logs/sync',
        element: <SyncLog />,
    },
    {
        path: '/logs/sync/big',
        element: <SyncLogBig />,
    },
    {
        path: '/logs/sync/bitrix',
        element: <SyncLogBitrix />,
    },
    {
        path: '/logs/sync/mwc',
        element: <SyncMWC />,
    },
    {
        path: '/logs/sync/macro',
        element: <SyncLogMacro />,
    },
    {
        path: '/logs/connections',
        element: <ConnectionsLog />,
    },
    {
        path: '/logs/ai-search',
        element: <AI_search />,
    },
];
