"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Path = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var plot_1 = require("../../core/plot");
var constants_1 = require("./constants");
var path_layer_1 = require("../../layers/path-layer");
var layer_group_1 = require("../../core/layer/layer-group");
var Path = /** @class */ (function (_super) {
    tslib_1.__extends(Path, _super);
    function Path() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图表类型
         */
        _this.type = plot_1.Plot.PlotType.Path;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Path.prototype.getDefaultOptions = function () {
        return Path.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Path.prototype.createLayers = function (source) {
        this.pathLayer = new path_layer_1.PathLayer(tslib_1.__assign({ source: source }, (0, util_1.pick)(this.options, path_layer_1.PathLayer.LayerOptionsKeys)));
        var layerGroup = new layer_group_1.LayerGroup([this.pathLayer]);
        return layerGroup;
    };
    /**
     * 更新图层
     */
    Path.prototype.updateLayers = function (options) {
        var dotLayerConfig = (0, util_1.pick)(options, path_layer_1.PathLayer.LayerOptionsKeys);
        this.pathLayer.update(dotLayerConfig);
    };
    /**
     * 实现 legend 配置项
     */
    Path.prototype.getLegendOptions = function () {
        var colorLegendItems = this.pathLayer.getColorLegendItems();
        if (colorLegendItems.length !== 0) {
            return { type: 'category', items: colorLegendItems };
        }
        return {};
    };
    /**
     * 默认配置项
     */
    Path.DefaultOptions = constants_1.DEFAULT_OPTIONS;
    return Path;
}(plot_1.Plot));
exports.Path = Path;
