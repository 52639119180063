import React from 'react';

import { Button, TextField } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';

const CreateCity = () => {
    const dispatch = useDispatch();
    const getCityState = useSelector((state) => state.cities);

    const [city, setCity] = React.useState('');
    const [description, setDescription] = React.useState('');

    const addCity = () => {
        return dispatch(api.cities.addCity(city, description));
    };

    return (
        <content className="cities-page-block">
            <h1>Добавление города</h1>

            <section className="input-section-form">
                <TextField
                    fullWidth
                    id="standard-basic"
                    label="Название"
                    inputProps={{
                        style: {
                            fontSize: 16,
                        },
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    onChange={(val) => {
                        setCity(val.target.value);
                    }}
                />
            </section>

            <section className="input-section-form">
                <TextField
                    fullWidth
                    id="standard-basic"
                    label="Описание (не обязательно)"
                    inputProps={{
                        style: {
                            fontSize: 16,
                        },
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    onChange={(val) => {
                        setDescription(val.target.value);
                    }}
                />
            </section>

            {getCityState.request_city_status ? (
                <Button variant="contained">Подождите</Button>
            ) : (
                <Button
                    variant="contained"
                    onClick={() => {
                        addCity();
                    }}
                >
                    Добавить
                </Button>
            )}
        </content>
    );
};

export default CreateCity;
