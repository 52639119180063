import React, { useEffect } from 'react';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { BottomScrollListener } from 'react-bottom-scroll-listener';

import { api } from '@/Services';
import styles from '../../styles/index.module.scss';

const SquidGame = () => {
    const dispatch = useDispatch();
    const getPlayers = useSelector((state) => state.games);

    useEffect(() => {
        if (!getPlayers.FR) {
            dispatch({
                type: 'PLAYERS_FR',
            });

            dispatch({
                type: 'PLAYERS_PAGE',
                page: getPlayers.page + 1,
            });

            loadPlayers(getPlayers.page + 1);
        }
    }, []);

    const loadPlayers = (pgn) => {
        dispatch(api.games.getPlayersOfSquidGame(pgn));
    };

    const banPlayer = (player_id, player_index) => {
        dispatch(api.games.banPlayer(player_id, player_index));
    };

    return (
        <div className="statistic-block">
            <h1>Игра в кальмара. Участники</h1>

            <BottomScrollListener
                onBottom={() => {
                    dispatch({
                        type: 'PLAYERS_PAGE',
                        page: getPlayers.page + 1,
                    });
                    return loadPlayers(getPlayers.page + 1);
                }}
            >
                <div className={styles.listPlayers}>
                    {getPlayers.list && getPlayers.list.length ? (
                        getPlayers.list.map((player, index) => (
                            <div
                                key={player._id + index}
                                className={styles.itemPlayer}
                            >
                                {player.is_banned && (
                                    <div className={styles.banPlayer}>
                                        <h5>Вне игры</h5>
                                    </div>
                                )}

                                {!player.is_banned && (
                                    <input
                                        type="submit"
                                        onClick={() =>
                                            banPlayer(player._id, index)
                                        }
                                        value="Выбыл"
                                    />
                                )}

                                <div className={styles.itemPlayerContent}>
                                    <div className={styles.itemPlayerLeft}>
                                        <span>
                                            {player.player_info
                                                ? player.player_info.number
                                                : '-'}
                                        </span>
                                        <div
                                            className={
                                                styles.phoneAndEmailPlayer
                                            }
                                        >
                                            <p>
                                                #{index + 1} {player.firstname}
                                            </p>
                                            {player.email && player.email && (
                                                <small>{player.email}</small>
                                            )}
                                            <a href={`tel:+7${player.phone}`}>
                                                +7{player.phone}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6>
                                    Дата регистрации:{' '}
                                    <b>
                                        {moment(player.createdAt).format(
                                            'DD/MM/YYYY в HH:mm'
                                        )}
                                    </b>
                                </h6>
                            </div>
                        ))
                    ) : (
                        <h6>Не найдено зарегистрированных игроков</h6>
                    )}
                </div>
            </BottomScrollListener>
        </div>
    );
};

export default SquidGame;
