import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import styles from '../styles/index.module.scss';
import { Link } from 'react-router-dom';
import selectLibrary from '@/Services/Store/reducers/library/selector';
import {
    LoadingOutlined,
    FileOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';
import config from '@/Config';
import { Dropdown, Menu, Popconfirm } from 'antd';

import FormType from './helpers/form';

const typeCards = () => {
    const dispatch = useDispatch();
    const stateLibrary = useSelector(selectLibrary);

    const [modal_status, set_modal_status] = useState(false);
    const [edit_type_card, set_edit_type_card] = useState(null);

    useEffect(() => {
        dispatch(api.library.getTypeCards());
    }, []);

    useEffect(() => {
        if (modal_status && !stateLibrary?.shops) {
            dispatch(api.library.shops());
        }
    }, [modal_status]);

    const menuSettings = (item) => {
        return (
            <Menu>
                <Menu.Item
                    key="1"
                    onClick={() => {
                        set_modal_status(true);
                        set_edit_type_card(item);
                    }}
                >
                    Редактировать
                </Menu.Item>
                <Menu.Item key="2">
                    <Popconfirm
                        title="Тип карты будет удален"
                        onConfirm={() =>
                            dispatch(api.library.deleteTypeCard(item?._id))
                        }
                        onCancel={() => {}}
                        okText="Удалить"
                        cancelText="Нет"
                        placement="bottomLeft"
                    >
                        Удалить
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div className={styles.mainBlock}>
            <FormType
                item={edit_type_card}
                close={() => {
                    set_modal_status(false);
                    set_edit_type_card(null);
                }}
                visible={modal_status}
            />

            <div className={styles.header}>
                <h2 className="title-page">Типы карт</h2>
                <div className={styles.headerRight}>
                    <Link to="/library/bonus-cards">Бонусные карты</Link>
                    <span onClick={() => set_modal_status(!modal_status)}>
                        Добавить тип
                    </span>
                </div>
            </div>

            <div className={styles.container}>
                {stateLibrary.status_cards_request ? (
                    <div className={styles.blockAwait}>
                        <LoadingOutlined className={styles.blockAwaitIcon} />
                    </div>
                ) : stateLibrary.type_cards &&
                  stateLibrary.type_cards.length ? (
                    <div className={styles.list}>
                        {stateLibrary.type_cards.map((item) => {
                            return (
                                <div key={item?._id} className={styles.item}>
                                    <Dropdown
                                        trigger={['click']}
                                        className={styles.DropdownItem}
                                        overlay={menuSettings(item)}
                                    >
                                        <EllipsisOutlined
                                            onClick={(e) => e.preventDefault()}
                                            rotate={90}
                                            className={
                                                styles.userItemSettingIcon
                                            }
                                        />
                                    </Dropdown>

                                    <h4>{item?.name || 'Нет наименования'}</h4>
                                    <div className={styles.shops}>
                                        {item.shops.map((shop, index) => {
                                            return (
                                                <div
                                                    key={shop?._id}
                                                    className={styles.shop}
                                                    title={shop?.shop?.title}
                                                    style={{
                                                        left: index * 20,
                                                        background: `url(${config.public}${shop?.shop?.icon?.url}) center / cover no-repeat`,
                                                    }}
                                                ></div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={styles.blockAwait}>
                        <FileOutlined className={styles.blockAwaitIcon} />
                        <h5>Информации нет</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default typeCards;
