import { produce } from 'immer';

const initialState = {
    apart_complex: null,
    blocks: null,
    flats: null,

    api_butrix_realestate: null,
    api_butrix_realestate_request: false,

    request_status: false,
    sync_request_status: false,
    sync_realestate_bitrix_status: false,

    status_loading_connection_information: false,
    connection_information: null,

    status_loading_connections: false,
    connections: null,

    xml_prices: [],
    request_xml_prices: false,
    upload_xml_file: false,

    macro_realestate_list: null,
    macro_realestate_flats: null,
    macro_request: false,
    macro_request_api: false,
    macro_realestate_list_from_api: null,

    sync_logs_request_status: false,
    sync_owner_logs_request_status: false,
    sync_realestate_data: null,
    sync_owner_realestate_data: null,
    sync_owner_flats_data: null
};

const logs = (state = initialState, action) => {
    switch (action.type) {

        case 'SYNC_OWNER_FLATS_DATA':
            return produce(state, draftState => {
                draftState.sync_owner_flats_data = action.data || null;
            });

        case 'SYNC_OWNER_REALESTATE_DATA':
            return produce(state, draftState => {
                draftState.sync_owner_realestate_data = action.data || null;
            });

        case 'SYNC_REQUEST_OWNER_API':
            return produce(state, draftState => {
                draftState.sync_owner_logs_request_status = action.status || false;
            });

        case 'SYNC_REALESTATE_DATA':
            return produce(state, draftState => {
                draftState.sync_realestate_data = action.data || null;
            });

        case 'SYNC_REQUEST_API':
            return produce(state, draftState => {
                draftState.sync_logs_request_status = action.status || false;
            });

        case 'MACRO_REALESTATE_LIST_API':
            return produce(state, draftState => {
                draftState.macro_realestate_list_from_api = action.data;
            });

        case 'MACRO_REQUEST_API':
            return produce(state, draftState => {
                draftState.macro_request_api = action.status;
            });

        case 'MACRO_REQUEST':
            return produce(state, draftState => {
                draftState.macro_request = action.status;
            });

        case 'MACRO_REALESTATE_LIST':
            return produce(state, draftState => {
                draftState.macro_realestate_list = action.data;
            });

        case 'MACRO_REALESTATE_FLATS':
            return produce(state, draftState => {
                draftState.macro_realestate_flats = action.data || null;
            });

        case 'DATA_XML_PRICES':
            return produce(state, draftState => {
                draftState.xml_prices = action.data;
            });

        case 'STATUS_UPLOAD_XML_FILE':
            return produce(state, draftState => {
                draftState.upload_xml_file = action.status;
            });

        case 'STATUS_REQUEST_XML_PRICES':
            return produce(state, draftState => {
                draftState.request_xml_prices = action.status;
            });

        case 'STATUS_REQUEST_REALESTATE_API_BITRIX':
            return produce(state, draftState => {
                draftState.api_butrix_realestate_request = action.status;
            });

        case 'UPDATE_API_BITRIX_REALESTATE':
            return produce(state, draftState => {
                draftState.api_butrix_realestate = action.data;
            });

        case 'STATUS_REALESTATE_SYNC_BITRIX':
            return produce(state, draftState => {
                draftState.sync_realestate_bitrix_status = action.status;
            });

        case 'STATUS_LOADING_CONNECTIONS':
            return produce(state, draftState => {
                draftState.status_loading_connections = action.status;
            });

        case 'CONNECTIONS_LIST':
            return produce(state, draftState => {
                draftState.connections = action.data;
            });

        case 'STATUS_LOADING_CONNECTION_INFORMATION':
            return produce(state, draftState => {
                draftState.status_loading_connection_information = action.status;
            });

        case 'CONNECTION_INFORMATION':
            return produce(state, draftState => {
                draftState.connection_information = action.data;
            });

        case 'UPDATE_LOGS_APART_COMPLEX':
            return produce(state, draftState => {
                draftState.apart_complex = action.data;
            });

        case 'UPDATE_LOGS_BLOCKS':
            return produce(state, draftState => {
                draftState.blocks = action.data;
            });

        case 'UPDATE_LOGS_FLATS':
            return produce(state, draftState => {
                draftState.flats = action.data;
            });

        case 'LOG_REQUEST_STATUS':
            return produce(state, draftState => {
                draftState.request_status = action.status;
            });

        case 'LOG_SYNC_REQUEST_STATUS':
            return produce(state, draftState => {
                draftState.sync_request_status = action.status;
            });

        default:
            return state;
    }
};

export default logs;
