import React, { useEffect, memo } from 'react';
import styles from '../styles/recent_events.module.scss';
import { DashOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Tooltip, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api, moment } from '@/Services';

const Sidebar = () => {

    const dispatch = useDispatch();
    const { 
        hide_status_recent_events,
        data_recent_events
    } = useSelector(state => state?.user);

    useEffect(()=>{
        dispatch(api.users.getLastRecentEvents())
    }, []);

    return (
        <>
            <Tooltip 
                title={hide_status_recent_events ? 'Показать последние события' : 'Скрыть последние события'}
                placement='left'
            >
                <Button
                    icon={hide_status_recent_events ? <DashOutlined /> : <MinusOutlined /> }
                    onClick={()=>dispatch({type: 'CHANGE_STATUS_RECENT_EVENTS'})}
                    className={styles.buttonSidebarEvents}
                    type="primary"
                    size="large"
                    shape="circle"
                />
            </Tooltip>

            <div className={`${styles.left} ${hide_status_recent_events ? styles.leftHide : ''}`}>

                {
                    !hide_status_recent_events ?
                        <div className={styles.content}>
                            <h1>Последние события</h1>
                            <div className={styles.listEvents}>
                                {
                                    data_recent_events && data_recent_events.length ?
                                        <>
                                            {
                                                data_recent_events.map((item)=>(
                                                    <div className={styles.eventInfo} key={item?._id}>
                                                        <p>В {moment(item?.createdAt).format('HH:mm')} { item?.text }</p>
                                                        <Tag>{moment(item?.createdAt).format('DD MMMM YYYY')}</Tag>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    :
                                        <h1>Нет событий</h1>
                                }
                            </div>
                        </div>
                    :
                        <></>
                }

            </div>
            
        </>
    )
}

export default memo(Sidebar);