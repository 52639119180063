import React from 'react';
import ItemCat from './item-cat';

const CategoryItems = ({
    data,
    styles,
    template_id,
    removeProductFromBudget,
    openAddSubProduct,
    removeProductFromBudgetLoading,
}) => {
    const renderItemCat = (cats) => {
        return (
            <>
                {cats && cats.length ? (
                    cats.map((item) => {
                        return (
                            <ItemCat
                                removeProductFromBudgetLoading={
                                    removeProductFromBudgetLoading
                                }
                                openAddSubProduct={openAddSubProduct}
                                removeProductFromBudget={
                                    removeProductFromBudget
                                }
                                template_id={template_id}
                                key={item._id}
                                item={item}
                                renderItemCat={renderItemCat(
                                    item.child_categories
                                )}
                            />
                        );
                    })
                ) : (
                    <h5>Данных нет</h5>
                )}
            </>
        );
    };

    return <div className={styles.list_block}>{renderItemCat(data)}</div>;
};

export default CategoryItems;
