import React, { useState, useEffect } from 'react';
import { Modal, Select, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { api } from '@/Services';

const { Option } = Select;

const FormDistrics = ({ statusModalDistrics, closeFormDistrics }) => {
    const getCityState = useSelector((state) => state.cities);
    const dispatch = useDispatch();

    const [select_city, set_select_city] = useState(null);
    const [select_district, set_select_district] = useState(null);
    const [district_title, set_district_title] = useState('');
    const [city_districts, set_city_districts] = useState([]);

    const addDistrict = () => {
        return dispatch(
            api.cities.createDistrict({
                select_city,
                district_title,
                select_district,
            })
        );
    };

    useEffect(() => {
        if (getCityState?.district_request_success) {
            set_city_districts([]);
            set_select_city(null);
            set_select_district(null);
            set_district_title('');
            dispatch({
                type: 'DISTRICT_REQUEST_SUCCESS',
                status: false,
            });
            closeFormDistrics();
        }
    }, [getCityState?.district_request_success]);

    return (
        <>
            <Modal
                width="55%"
                title={`Форма добавления района`}
                visible={statusModalDistrics}
                onOk={addDistrict}
                onCancel={closeFormDistrics}
                okText="Добавить"
            >
                <Input
                    onChange={(value) => set_district_title(value.target.value)}
                    value={district_title}
                    size="large"
                    style={{ marginBottom: 10 }}
                    placeholder="Наименование района*"
                />
                <Select
                    showArrow
                    showSearch
                    dropdownStyle={{ zIndex: 9999999 }}
                    size="large"
                    style={{ width: '100%', marginBottom: 10 }}
                    placeholder="Выберите город*"
                    onChange={(value) => {
                        let getDistricsOfCity = getCityState?.cities.filter(
                            (item) => String(item?._id) === String(value)
                        );
                        set_city_districts(
                            getDistricsOfCity && getDistricsOfCity.length
                                ? getDistricsOfCity[0].districts || []
                                : []
                        );
                        set_select_district(null);
                        set_select_city(value);
                    }}
                    value={select_city}
                >
                    {getCityState?.cities.map((item) => (
                        <Option key={item?._id} value={item?._id}>
                            {item?.title}
                        </Option>
                    ))}
                </Select>

                {city_districts && city_districts.length ? (
                    <Select
                        showArrow
                        showSearch
                        dropdownStyle={{ zIndex: 9999999 }}
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Выберите район (необязательно)"
                        onChange={(value) => {
                            set_select_district(value);
                        }}
                        value={select_district}
                    >
                        {city_districts.map((item) => (
                            <Option key={item?._id} value={item?._id}>
                                {item?.title}
                            </Option>
                        ))}
                    </Select>
                ) : (
                    <></>
                )}
            </Modal>
        </>
    );
};

export default FormDistrics;
