import { Navigate } from 'react-router-dom';
import { Layer, Loader } from '@/Components';
import { authComponents, appComponents } from './components';

const routes = (loading = false, user = null, Sidebar) => {
    return [
        {
            path: '/',
            element: loading ? (
                <Loader height="100vh" />
            ) : user ? (
                <Layer Sidebar={Sidebar} user={user} />
            ) : (
                <Navigate to="/auth" />
            ),
            children: appComponents,
        },
        {
            path: '/auth',
            element: loading ? (
                <Loader height="100vh" />
            ) : user ? (
                <Navigate to="/" />
            ) : (
                <Layer login />
            ),
            children: authComponents,
        },
    ];
};

export default routes;
