"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextLayer = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var l7_layers_1 = require("@antv/l7-layers");
var plot_layer_1 = require("../../core/layer/plot-layer");
var adaptor_1 = require("./adaptor");
var DEFAULT_OPTIONS = {
    style: {
        fontSize: 12,
    },
};
var LAYER_OPTIONS_KEYS = [];
var TextLayer = /** @class */ (function (_super) {
    tslib_1.__extends(TextLayer, _super);
    function TextLayer(options) {
        var _this = _super.call(this, options) || this;
        /**
         * 图层类型
         */
        _this.type = plot_layer_1.PlotLayer.LayerType.TextLayer;
        /**
         * 图层是否具有交互属性
         */
        _this.interaction = false;
        var _a = _this.options, name = _a.name, source = _a.source;
        var config = _this.pickLayerConfig(_this.options);
        _this.name = name ? name : (0, util_1.uniqueId)(_this.type);
        _this.layer = new l7_layers_1.PointLayer(tslib_1.__assign(tslib_1.__assign({}, config), { name: _this.name }));
        _this.mappingLayer(_this.layer, _this.options);
        _this.setSource(source);
        return _this;
    }
    /**
     * 获取默认配置
     */
    TextLayer.prototype.getDefaultOptions = function () {
        return DEFAULT_OPTIONS;
    };
    TextLayer.prototype.mappingLayer = function (layer, options) {
        (0, adaptor_1.mappingLayer)(layer, options);
    };
    TextLayer.prototype.update = function (options) {
        _super.prototype.update.call(this, options);
        this.mappingLayer(this.layer, this.options);
    };
    /**
     * 默认配置项
     */
    TextLayer.DefaultOptions = DEFAULT_OPTIONS;
    /**
     * 图层配置项 Keys
     */
    TextLayer.LayerOptionsKeys = plot_layer_1.PlotLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return TextLayer;
}(plot_layer_1.PlotLayer));
exports.TextLayer = TextLayer;
