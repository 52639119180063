import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import { ReSelectMWC } from '@/Services/Store/reducers/logs/selectors';
import styles from '../../../styles/index.module.scss';
import { LoadingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Result, Select, Button, Tooltip } from 'antd';
import ListRealestateMWC from './list-realestate-mwc';

const Filter = ({loading}) => {
    const dispatch = useDispatch();
    const { sync_logs_request_status, sync_realestate_data } = useSelector(ReSelectMWC);

    const [owner_realestate_list_status, set_owner_realestate_list_status] = useState(false);

    useEffect(()=>{
        dispatch(api.logs.getRealestateMWC());
    }, []);

    const selectRealestate = (id) => {
        dispatch(api.logs.getSyncStatusFlatsRealestateMWC(id));
    }

    return (
        <div className={styles.filter}>
            <h5>Список жк</h5>
            {
                sync_logs_request_status ?
                    <div className={styles.loader}>
                        <LoadingOutlined size={45} />
                    </div>
                : sync_realestate_data && sync_realestate_data.length ?
                    <div className={styles.wrapper}>
                        <Select
                            showSearch
                            placeholder="Выберите ЖК"
                            optionFilterProp="children"
                            onChange={selectRealestate}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            options={sync_realestate_data.map((item)=>({
                                label: item?.title,
                                value: item?._id
                            }))}
                            size='large'
                            style={{
                                width: '100%'
                            }}
                            loading={loading}
                            disabled={loading}
                        />
                        <Tooltip title="Показать список доступных жк" placement='left'>
                            <Button 
                                size='large'
                                onClick={()=>set_owner_realestate_list_status(!owner_realestate_list_status)}
                            ><UnorderedListOutlined /></Button>
                        </Tooltip>
                    </div>
                :
                <Result
                    status="404"
                    title="Информации не найдено"
                    subTitle="Обновите настройки синхронизации на странице реадктирования жк или попробуйте позже"
                    extra={null}
                />
            }

            <ListRealestateMWC
                dispatch={dispatch}
                api={api}
                styles={styles}
                status={owner_realestate_list_status}
                close={()=>set_owner_realestate_list_status(!owner_realestate_list_status)}
            />

        </div>
    )
}

export default Filter;