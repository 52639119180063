import React, { useEffect } from 'react';
import styles from './styles/recent_events.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from './helpers/sidebar';
import config from '@/Config';
import { useSubscription } from '@apollo/client';
import { users } from '@/Services/apollo/gqls';

const RecentEvents = ({children}) => {

    const dispatch = useDispatch();
    const { data } = useSubscription(users.recentEvent);

    const authToken = localStorage.getItem(config.TOKEN);
    const { hide_status_recent_events } = useSelector(state => state?.user);

    useEffect(()=>{
        if(data?.recentEvent){
            dispatch({
                type: 'ADD_SINGLE_RECENT_EVENT',
                data: data?.recentEvent
            });
        }
    }, [data?.recentEvent]);

    return (
        <div className={styles.wrapper}>

            {
                authToken ?
                    <Sidebar/>
                :
                    <></>
            }

            <div className={`${styles.right} ${hide_status_recent_events ? styles.rightDefault : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default RecentEvents;