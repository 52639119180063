import React, { useEffect } from 'react';
import { Form, Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import BuilderFormItemPhones from './items/phones';
import BuilderFormItemTitles from './items/titles';
import BuilderFormItemLogo from './items/logo';
import BuilderFormItemSeo from './items/seo';
import BuilderFormItemWebhook from './items/webhook';
import { useLocation } from 'react-router-dom';
import config from '@/Config';
import BuilderFormItemComplementary from './items/complementary';

const { Title } = Typography;

const BuilderForm = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const builder_id = searchParams.get('builder_id');

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { builder_form_edit, request_status_form } = useSelector(
        (state) => state?.builders
    );

    useEffect(() => {
        dispatch(api.builders.getBuilder(builder_id));
    }, []);

    useEffect(() => {
        form.resetFields();

        if (builder_form_edit) {
            form.setFieldsValue({
                description: builder_form_edit?.description || '',
                email: builder_form_edit?.email || '',
                logo:
                    [
                        {
                            uid: builder_form_edit?.logo?._id,
                            name: builder_form_edit?.logo?.url,
                            status: 'done',
                            url: `${config?.public}${builder_form_edit?.logo?.url}`,
                        },
                    ] || [],
                macro_app_secret:
                    builder_form_edit?.webhook?.macro_app_secret || '',
                macro_domain: builder_form_edit?.webhook?.macro_domain || '',
                phones: builder_form_edit?.phones || [],
                seo_description: builder_form_edit?.seo_description || '',
                seo_keywords: builder_form_edit?.seo_keywords || '',
                seo_title: builder_form_edit?.seo_title || '',
                title: builder_form_edit?.title || '',
                type_webhook: builder_form_edit?.webhook?.type_webhook || null,
                year: builder_form_edit?.year || '',
                url_webhook: builder_form_edit?.webhook?.url_webhook || '',
                visibility_status:
                    builder_form_edit?.visibility_status || 'public',
            });
        } else {
            form.setFieldsValue({
                visibility_status: 'closed',
            });
        }
    }, [builder_form_edit]);

    const onFinish = (values) => {
        let phone = '';

        if (values?.phones) {
            values?.phones.forEach((item) => {
                phone += `${item}, `;
            });
        }

        const fields = {
            ...values,
            builder_id: builder_form_edit?._id,
            logo: values?.logo[0]?.uid,
            phone: phone,
            webhook: {
                url_webhook: values?.url_webhook || '',
                type_webhook: values?.type_webhook || '',
                macro_app_secret: values?.macro_app_secret || '',
                macro_domain: values?.macro_domain || '',
            },
        };

        if (builder_form_edit?._id) {
            dispatch(api.builders.editBuilderv2(fields, form));
        } else {
            dispatch(api.builders.addBuilderv2(fields, form));
        }
    };

    return (
        <>
            <Title level={5}>Форма застройщика</Title>
            <Form
                form={form}
                size="large"
                autoComplete="off"
                onFinish={onFinish}
                layout="vertical"
                disabled={request_status_form}
            >
                <BuilderFormItemTitles />
                <BuilderFormItemPhones />
                <BuilderFormItemLogo />
                <BuilderFormItemSeo />
                <BuilderFormItemWebhook />
                <BuilderFormItemComplementary />

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {builder_form_edit?._id ? 'Редактировать' : 'Добавить'}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default BuilderForm;
