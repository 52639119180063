import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { api } from '../../../../Services/';

let configSun = {
    height: 800,
    buttonList: [
        [
            'undo',
            'redo',
            'font',
            'fontSize',
            'formatBlock',
            'paragraphStyle',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'textStyle',
            'removeFormat',
            'outdent',
            'indent',
            'align',
            'horizontalRule',
            'list',
            'lineHeight',
            'table',
            'link',
            'image' /** 'video', 'audio' 'math', */, // You must add the 'katex' library at options to use the 'math' plugin.
            /** 'imageGallery', */ // You must add the "imageGalleryUrl".
            'fullScreen',
            'showBlocks',
            'codeView',
            'preview',
        ], //'print', 'save'
    ],
};

const CreatePage = () => {
    const history = useNavigate();

    const dispatch = useDispatch();
    const editorRef = useRef();

    const [builder, setBuilder] = useState('');
    const [title, setTitle] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const [metaKeyWords, setMetaKeyWords] = useState('');

    const [toggleCodeView, setToggleCodeView] = React.useState(false);

    const getBuilder = useSelector((state) => state.builders.builders);

    useEffect(() => {
        dispatch(api.builders.getBuilders());
    }, []);

    const handleImageUploadBefore = (files, info, uploadHandler) => {
        (async () => {
            let imgSrc = await dispatch(
                api.files.uploadFile(files[0], 'IMAGE_FOR_POSTS')
            );

            const response = {
                result: [
                    {
                        url: imgSrc,
                        name: files[0].name,
                        size: files[0].size,
                    },
                ],
            };

            uploadHandler(response);
        })();

        uploadHandler();
    };

    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    const createPost = () => {
        //let edR = editorRef.current.editor.core.context.element.wysiwyg;

        if (toggleCodeView) {
            editorRef.current.core.toggleCodeView();
        }

        dispatch(
            api.pages.create(
                {
                    builder,
                    title,
                    metaTitle,
                    metaDesc,
                    metaKeyWords,
                    content: editorRef.current.getContents(),
                    category: 'PAGE',
                },
                history
            )
        );
    };

    return (
        <content className="page-editor-block">
            <h1 className="title-page">Создание страницы</h1>

            <input
                className="form-page-input"
                type="text"
                placeholder="Заголовок"
                onChange={(val) => {
                    setTitle(val.target.value);
                }}
            />

            <SunEditor
                getSunEditorInstance={getSunEditorInstance}
                onImageUploadBefore={handleImageUploadBefore}
                setOptions={configSun}
                toggleCodeView={(statusCodeView) =>
                    setToggleCodeView(statusCodeView)
                }
            />

            <div className="meta-seo-page-block">
                <h5>Seo</h5>

                <p>Meta title</p>
                <input
                    className="form-page-input"
                    type="text"
                    onChange={(val) => {
                        setMetaTitle(val.target.value);
                    }}
                    placeholder="Meta title"
                />

                <p>Meta description</p>
                <input
                    className="form-page-input"
                    type="text"
                    onChange={(val) => {
                        setMetaDesc(val.target.value);
                    }}
                    placeholder="Meta description"
                />

                <p>Meta keywords</p>
                <input
                    className="form-page-input"
                    type="text"
                    onChange={(val) => {
                        setMetaKeyWords(val.target.value);
                    }}
                    placeholder="Meta keywords"
                />
            </div>

            <div className="meta-seo-page-block">
                <h5>Выберите застройщика (необязательно)</h5>
                {getBuilder && getBuilder.length ? (
                    <select
                        onChange={(val) => {
                            setBuilder(val.target.value);
                        }}
                    >
                        <option value="">Список застройщиков</option>
                        {getBuilder.map((item, index) => {
                            return (
                                <option key={index} value={item._id}>
                                    {item.title}
                                </option>
                            );
                        })}
                    </select>
                ) : (
                    <h6>Застройщиков не найдено</h6>
                )}
            </div>

            <input
                className="form-page-input"
                type="submit"
                onClick={createPost}
                value="Создать"
            />
        </content>
    );
};

export default CreatePage;
