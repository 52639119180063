import RedirectComp from '@/Containers/Admin/Redirect';
import CreateRedirect from '@/Containers/Admin/Redirect/Create';

export default [
    {
        path: '/redirects',
        element: <RedirectComp />,
    },
    {
        path: '/redirects/create',
        element: <CreateRedirect />,
    },
];
