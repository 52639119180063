import React, { useState } from 'react';
import { Modal, Input, Tooltip } from 'antd';
import { budget } from '@/Services/apollo/gqls';
import { useMutation } from '@apollo/client';

const RangePrices = ({ status, styles, close, template_id, template }) => {
    const [prices, set_prices] = useState({
        one_room: {
            min: template?.price_range?.one_room?.min || 0,
            max: template?.price_range?.one_room?.max || 0,
        },
        two_room: {
            min: template?.price_range?.two_room?.min || 0,
            max: template?.price_range?.two_room?.max || 0,
        },
        three_room: {
            min: template?.price_range?.three_room?.min || 0,
            max: template?.price_range?.three_room?.max || 0,
        },
        four_room: {
            min: template?.price_range?.four_room?.min || 0,
            max: template?.price_range?.four_room?.max || 0,
        },
    });

    const [updateRangePrices] = useMutation(budget.updateRangePrices);

    return (
        <Modal
            title="Диапазон цен"
            centered
            zIndex={9999999}
            visible={status}
            cancelText="Отмена"
            onOk={() => {
                updateRangePrices({
                    variables: {
                        fields: {
                            template_id,
                            prices,
                        },
                    },
                    onCompleted: () => {
                        close();
                    },
                });
            }}
            onCancel={close}
            wrapClassName={styles.modalFormForProduct}
            width="55%"
        >
            <section className={styles.priceSection}>
                <p>1к квартиры</p>
                <div className={styles.priceFormSection}>
                    <Tooltip zIndex={9999999} title="От">
                        <Input
                            value={prices?.one_room?.min || 0}
                            placeholder="От"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    one_room: {
                                        ...prices.one_room,
                                        min: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip zIndex={9999999} title="До">
                        <Input
                            value={prices?.one_room?.max || 0}
                            placeholder="До"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    one_room: {
                                        ...prices.one_room,
                                        max: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                </div>
            </section>
            <section className={styles.priceSection}>
                <p>2к квартиры</p>
                <div className={styles.priceFormSection}>
                    <Tooltip zIndex={9999999} title="От">
                        <Input
                            value={prices?.two_room?.min || 0}
                            placeholder="От"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    two_room: {
                                        ...prices.two_room,
                                        min: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip zIndex={9999999} title="До">
                        <Input
                            value={prices?.two_room?.max || 0}
                            placeholder="До"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    two_room: {
                                        ...prices.two_room,
                                        max: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                </div>
            </section>
            <section className={styles.priceSection}>
                <p>3к квартиры</p>
                <div className={styles.priceFormSection}>
                    <Tooltip zIndex={9999999} title="От">
                        <Input
                            value={prices?.three_room?.min || 0}
                            placeholder="От"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    three_room: {
                                        ...prices.three_room,
                                        min: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip zIndex={9999999} title="До">
                        <Input
                            value={prices?.three_room?.max || 0}
                            placeholder="До"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    three_room: {
                                        ...prices.three_room,
                                        max: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                </div>
            </section>
            <section className={styles.priceSection}>
                <p>4к+ квартиры</p>
                <div className={styles.priceFormSection}>
                    <Tooltip zIndex={9999999} title="От">
                        <Input
                            value={prices?.four_room?.min || 0}
                            placeholder="От"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    four_room: {
                                        ...prices.four_room,
                                        min: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip zIndex={9999999} title="До">
                        <Input
                            value={prices?.four_room?.max || 0}
                            placeholder="До"
                            size="large"
                            onChange={(val) => {
                                set_prices({
                                    ...prices,
                                    four_room: {
                                        ...prices.four_room,
                                        max: Number(val.target.value),
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                </div>
            </section>
        </Modal>
    );
};

export default RangePrices;
