import React, { useState } from 'react';
import styles from '../styles/index.module.scss';

import { Input, Select } from 'antd';

const { Search } = Input;
const { Option } = Select;

const UserFilter = ({ search, roles, userState, sort }) => {
    const [search_text, set_search_text] = useState('');

    return (
        <div className={styles.filter}>
            <div className={styles.filterSort}>
                <Select
                    loading={userState?.request_status_users}
                    mode="tags"
                    className={styles.select}
                    placeholder="Выберите роль"
                    onChange={(value) => {
                        sort(value);
                    }}
                    optionLabelProp="label"
                    size="large"
                    dropdownStyle={{
                        zIndex: 9999,
                    }}
                >
                    {roles && roles.length ? (
                        roles.map((item) => (
                            <Option
                                key={item?._id}
                                className={styles.optionStyle}
                                value={item?._id}
                                label={item?.title}
                            >
                                <b>{item?.title}</b>{' '}
                                <small>({item?.description})</small>
                            </Option>
                        ))
                    ) : (
                        <></>
                    )}
                </Select>
            </div>

            <div className={styles.filterSearch}>
                <Search
                    placeholder="Поиск по номеру телефона или email"
                    enterButton="Найти"
                    size="large"
                    loading={userState?.request_status_roles}
                    onChange={(value) => set_search_text(value.target.value)}
                    onSearch={() => search(search_text)}
                />
            </div>
        </div>
    );
};

export default UserFilter;
