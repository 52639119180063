import React from 'react';
import { Tabs } from 'antd';
import ChessStandart from './standart';
import ChessCustom from './custom';

const Chess = () => {
    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: 'standart',
            label: `Стандартная`,
            children: <ChessStandart />,
        },
        {
            key: 'custom',
            label: `Кастомная`,
            children: <ChessCustom />,
        },
    ];

    return (
        <Tabs defaultActiveKey="standart" items={items} onChange={onChange} />
    );
};

export default Chess;
