import axios from '../axios';
import { message } from 'antd';

const realestate = {
    searchRealestate: (params, status_meta) => async dispatch => {

        params.meta = status_meta;

        dispatch({
            type: 'REQUEST_STATUS_REALESTATE',
            status: true
        });

        try {

            const { data } = await axios.get(`/v2/admin/realestate/search`, {
                params
            });  

            dispatch({
                type: 'DATA_REALESTATE',
                data
            });

            return dispatch({
                type: 'REQUEST_STATUS_REALESTATE',
                status: false
            });

        }catch(err){
            return dispatch({
                type: 'REQUEST_STATUS_REALESTATE',
                status: false
            });
        }
 
    },
    saveSortNumbers: (data) => async dispatch => {

        dispatch({
            type: 'REQUEST_STATUS_REALESTATE',
            status: true
        });

        try {

            await axios.post(`/v2/admin/realestate/update-sort-numbers`, {
                data
            });  

            dispatch({
                type: 'REQUEST_STATUS_REALESTATE',
                status: false
            });

            return message.success('Сортировочные номера успешно обновлены');

        }catch(err){
            dispatch({
                type: 'REQUEST_STATUS_REALESTATE',
                status: false
            });
            return message.warning('попробуйте позже, сервис сейчас недоступен');
        }

    },
    getAllRealestate: () => async dispatch => {

        dispatch({
            type: 'REQUEST_STATUS_REALESTATE',
            status: true
        });

        try {

            const { data } = await axios.get(`/v2/admin/realestate`, {
                params: {
                    request_type: 'all_sort'
                }
            });

            dispatch({
                type: 'DATA_REALESTATE_SORT',
                data
            });

            return dispatch({
                type: 'REQUEST_STATUS_REALESTATE',
                status: false
            });

        }catch(err){

            return dispatch({
                type: 'REQUEST_STATUS_REALESTATE',
                status: false
            });

        }

    }
};

export default realestate;
