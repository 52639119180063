import LibraryComp from '@//Containers/Admin/Library';
import CreateProduct from '@//Containers/Admin/Library/forms/products/create';
import EditProduct from '@//Containers/Admin/Library/forms/products/edit';
import CategoriesLibrary from '@//Containers/Admin/Library/categories';
import ShopsLibrary from '@//Containers/Admin/Library/shops';
import CreateShop from '@//Containers/Admin/Library/forms/shops/create';
import EditShop from '@//Containers/Admin/Library/forms/shops/edit';
import EditCategory from '@//Containers/Admin/Library/forms/categories/edit-category';
import CreateSubCategory from '@//Containers/Admin/Library/forms/categories/sub-category-create';
import ProductCriteria from '@//Containers/Admin/Library/criteria';
import CreateCategory from '@//Containers/Admin/Library/forms/categories/create-category';
import BonusCards from '@//Containers/Admin/Library/bonusСards';
import TypeCards from '@//Containers/Admin/Library/typeCards';
import ChecksOfBonusCard from '@/Containers/Admin/Library/checks';

export default [
    {
        path: '/library',
        element: <LibraryComp />,
    },
    {
        path: '/library/products/create',
        element: <CreateProduct />,
    },
    {
        path: '/library/products/edit/:id',
        element: <EditProduct />,
    },
    {
        path: '/library/categories',
        element: <CategoriesLibrary />,
    },
    {
        path: '/library/categories/edit/:id',
        element: <EditCategory />,
    },
    {
        path: '/library/categories/create/:id',
        element: <CreateSubCategory />,
    },
    {
        path: '/library/shops',
        element: <ShopsLibrary />,
    },
    {
        path: '/library/shops/create',
        element: <CreateShop />,
    },
    {
        path: '/library/shops/edit/:id',
        element: <EditShop />,
    },
    {
        path: '/library/products/criteria',
        element: <ProductCriteria />,
    },
    {
        path: '/library/categories/create',
        element: <CreateCategory />,
    },
    {
        path: '/library/bonus-cards',
        element: <BonusCards />,
    },
    {
        path: '/library/bonus-cards/types',
        element: <TypeCards />,
    },
    {
        path: '/library/bonus-cards/checks/:id',
        element: <ChecksOfBonusCard />,
    },
];
