import React, {useEffect, useRef, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {api} from '@/Services';
import styles from '../styles/index.module.scss';
import {Button, Input} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const AI_search = () => {

	const refSVG = useRef();

	const [price, set_price] = useState('');
	const [area, set_area] = useState('');
	const [rooms, set_rooms] = useState('');
	const [count_iterations, set_count_iterations] = useState('');

	const dispatch = useDispatch();
	const logState = useSelector(state => state.logs);

	useEffect(()=>{

		dispatch({
			type: 'UPDATE_LOGS_FLATS',
			data: null
		});

	}, []);

	const searchFlat = () => {
		dispatch(api.logs.searchFlatAI(price || null, area || null, rooms || null, count_iterations || null));
	}

	return (
		<div className="statistic-block">

			<h1>Отладчик/Интеграция/AI поиск квартиры</h1>

			<div className={styles.selectRealEstateBlock}>
				<h5>Заполните поля</h5>
				<div className={styles.selectRealEstateBlockInputs}>
					<Input type="text" placeholder="Укажите примерную цену" onChange={(val)=>set_price(val.target.value)} value={price} />
					<Input type="text" placeholder="Укажите примерную площадь" onChange={(val)=>set_area(val.target.value)} value={area} />
					<Input type="text" placeholder="Количество комнат" onChange={(val)=>set_rooms(val.target.value)} value={rooms} />
					<Input type="text" placeholder="Количество итераций (количество итераций влияет на время рассчета)" onChange={(val)=>set_count_iterations(val.target.value)} value={count_iterations} />
				</div>
				<Button onClick={searchFlat}>Рассчитать</Button>
			</div>

			<div className={styles.contentBlock}>

				{
					logState?.sync_request_status ?
						<div className={styles.defaultBlock}>
							<LoadingOutlined className={styles.defaultBlockLoadIcon}/>
						</div>
					: logState?.flats && logState?.flats?.output ?
						<div className={styles.parentItemsBlock}>
							<h1>{logState?.flats?.msg}</h1>
							<div className={styles.itemsBlockAI}>
								{
									Object.keys(logState?.flats?.output).map((item, index)=>(
										<div className={styles.itemBlock} key={index}>
											<h4><b>Вероятность:</b> <small>{logState?.flats?.output[item]}</small>  -> {item.toUpperCase()}</h4>
										</div>
									))
								}
							</div>
						</div>
					:
						<div className={styles.defaultBlock}>
							<h4>Квартир не найдено</h4>
						</div>
				}

				<div ref={refSVG}></div>

			</div>

		</div>
	)
}

export default AI_search;