import Blog from '../../../../../Containers/Admin/Blog';
import CreateBlog from '../../../../../Containers/Admin/Blog/Create';
import EditBlog from '../../../../../Containers/Admin/Blog/Edit';

export default [
    {
        path: '/blog',
        element: <Blog />,
    },
    {
        path: '/blog/create',
        element: <CreateBlog />,
    },
    {
        path: '/blog/edit/:id',
        element: <EditBlog />,
    },
];
