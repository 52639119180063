import React, { useState, useEffect } from 'react';
import { Modal, Result } from 'antd';
import styles from '../styles/index.module.scss';

import ItemDistrict from './item-district';

const ListDistrics = ({ statusModalDistrics, closeFormDistrics, city }) => {
    let [districts, set_districts] = useState(city?.districts || []);

    useEffect(() => {
        set_districts(city?.districts);
    }, [city, city?.districts]);

    const delDist = (id) => {
        let getDists = districts.filter(
            (item) => String(item?._id) !== String(id)
        );

        set_districts(getDists);
    };

    return (
        <>
            <Modal
                width="55%"
                title={`${city?.title || ''}: управление районами`}
                visible={statusModalDistrics}
                onCancel={closeFormDistrics}
                footer={null}
            >
                {districts && districts.length ? (
                    <div className={styles.list}>
                        {districts.map((item) => (
                            <div className={styles.item} key={item?._id}>
                                <ItemDistrict
                                    delDist={(id) => delDist(id)}
                                    item={item}
                                />
                                {item?.districts && item?.districts.length ? (
                                    <div>
                                        {item?.districts.map((itemSub) => (
                                            <div
                                                className={styles.itemSub}
                                                key={itemSub?._id}
                                            >
                                                <ItemDistrict
                                                    delDist={(id) =>
                                                        delDist(id)
                                                    }
                                                    item={itemSub}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <Result
                        status="404"
                        title="Районов не найдено"
                        subTitle="Информации не найдено"
                        extra={null}
                    />
                )}
            </Modal>
        </>
    );
};

export default ListDistrics;
