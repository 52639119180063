import { combineReducers } from 'redux';

import auth from './auth';
import user from './user';
import cities from './cities';
import builders from './builders';
import apartments from './apartments';
import files from './files';
import blocks from './blocks';
import flats from './flats';
import statistic from './statistic';
import users from './users';
import cache from './cache';
import pages from './pages';
import posts from './blog';
import library from './library';
import widgets from './widgets';
import logs from './logs';
import redirect from './redirect';
import games from './games';
import budget from './budget';
import virtualTours from './virtualTours';
import chess from './chess';
import chessData from './chessData';
import realestate from './realestate';

export default combineReducers({
    realestate,
    auth,
    user,
    cities,
    builders,
    apartments,
    files,
    blocks,
    flats,
    statistic,
    users,
    cache,
    pages,
    posts,
    library,
    games,
    widgets,
    logs,
    redirect,
    budget,
    virtualTours,
    chess,
    chessData,
});
