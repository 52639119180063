import React, {useEffect} from 'react';
import styles from '../styles/index.module.scss';
import {LoadingOutlined} from "@ant-design/icons";
import {DatePicker, Input, Select} from "antd";
import {moment} from '@/Services';

const { Search } = Input;
const {Option} = Select;
const { RangePicker } = DatePicker;

const Filter = ({
	dispatch,
	api,
	flatState,
	search,

	set_search_text,
	search_text = '',

	sort
}) => {

	useEffect(() => {
		dispatch(api.flats.getComplexAll());
	}, []);

	return (
		<div className={styles.filter}>

			{
				flatState?.request_flats_realestate_status ?
					<LoadingOutlined/>
					:
					<Select
						showSearch
						mode="tags"
						size="large"
						className={styles.selectInput}
						placeholder="Выберите ЖК"
						onChange={(value)=>search(value, 'select')}
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						filterSort={(optionA, optionB) =>
							optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
						}
					>
						{
							flatState?.complexApartments && flatState?.complexApartments.length ?
								flatState?.complexApartments.map((item)=>(
									<Option key={item?._id} value={item?._id}>{item?.title}</Option>
								))
							:
								<></>
						}
					</Select>
			}

			<RangePicker
				size="large"
				className={styles.datePicker}
				ranges={{
					'Месяц': [moment().startOf('month'), moment().endOf('month')],
				}}
				onChange={(dates, dateStrings)=>search(dateStrings, 'date')}
			/>

			<Select size="large" className={`${styles.selectInput} ${styles.selectInputSort}`} defaultValue={sort} onChange={(value)=>search(value, 'sort')}>
				<Option value=''>Сортировка</Option>
				<Option value='POPULAR'>Популярные</Option>
				<Option value='ASC'>Сначало новые</Option>
				<Option value='DESC'>Сначало старые</Option>
			</Select>

			<Search
				placeholder="Поиск по номеру квартиры"
				className={styles.inputSearch}
				enterButton="Найти" size="large"
				loading={flatState?.request_flats_status}
				onChange={(value)=>set_search_text(value.target.value)}
				onSearch={()=>search(search_text, 'text')}
			/>

		</div>
	)
}

export default Filter;