import React, { useEffect } from 'react';
import Filter from './helpers/filter';
import { useSelector, useDispatch } from 'react-redux';
import { ReSelectFlats } from '@/Services/Store/reducers/logs/selectors';
import styles from '../../styles/index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { Result, Tag, Tooltip } from 'antd';
import currencyFormatter from 'currency-formatter';

const SyncMWC = () => {
    const dispatch = useDispatch();
    const { sync_owner_logs_request_status, sync_owner_flats_data } = useSelector(ReSelectFlats);

    useEffect(()=>{
        dispatch({
            type: 'SYNC_OWNER_FLATS_DATA',
            data: null
        });
    }, []);

    const checkStatus = (flat) => {

        if(flat?.status === 'BOOKING'){
            return 'Бронь';
        }else if(flat?.sold || flat?.status === 'SOLD'){
            return 'Продано';
        }else if(!flat?.sold || flat?.status === 'FREE'){
            return 'Свободно';
        }

    }

    return (
        <div className="statistic-block">
            <h1>Отладчик/Интеграция/MWC</h1>
            <Filter loading={sync_owner_logs_request_status}/>

            {
                sync_owner_flats_data && (
                    <div className={styles.wrapperHeader}>
                        <h4>Всего квартир: {sync_owner_flats_data.length}</h4>
                        <h4>Синхронизировано: {sync_owner_flats_data.filter(flat => flat?.sync_status).length}</h4>
                        <h4>Не прошли синхронизацию: {sync_owner_flats_data.filter(flat => !flat?.sync_status).length}</h4>
                    </div>
                )
            }

            {
                sync_owner_logs_request_status ?
                    <div className={styles.loader}>
                        <LoadingOutlined size={45} />
                    </div>
                : sync_owner_flats_data && sync_owner_flats_data.length ?
                    <div className={styles.wrapperRealestateList}>
                        {
                            sync_owner_flats_data.map((item)=>(
                                <div 
                                    key={`${item?.title}_${item?._id}`}
                                    className={styles.itemRealestate}
                                >
                                    <Tooltip title={`MWC: ${item?.ownerFlat?.NUM}`}>
                                        <h4>Номер квартиры: { item?.title }</h4>
                                    </Tooltip>
                                    <div className={styles.line}></div>
                                    <Tooltip title={`MWC: ${item?.ownerFlat?.FLOOR}`}>
                                        <h4>Этаж: { item?.floor }</h4>
                                    </Tooltip>
                                    <div className={styles.line}></div>
                                    <Tooltip title={`MWC: ${item?.ownerFlat?.AREA}м²`}>
                                        <h4>Площадь: { item?.square_metres }м²</h4>
                                    </Tooltip>
                                    <div className={styles.line}></div>
                                    <Tooltip title={`MWC: ${checkStatus(item?.ownerFlat?.status_flat)}`}>
                                        <h4>Статус: { checkStatus(item) }</h4>
                                    </Tooltip>
                                    <div className={styles.line}></div>
                                    <Tooltip title={`MWC: ${currencyFormatter.format(
                                            Number(item?.ownerFlat?.PRICE || 0),
                                            {
                                                code: 'EUR',
                                                symbol: '',
                                            }
                                    )}₸/м²`}>
                                        <h4>Стоимость м²: {currencyFormatter.format(
                                            Number(item?.square_metre_price || 0),
                                            {
                                                code: 'EUR',
                                                symbol: '',
                                            }
                                        )} ₸/м²</h4>
                                    </Tooltip>
                                    <div className={styles.line}></div>
                                    <Tooltip title={`MWC: ${currencyFormatter.format(
                                            Number(item?.ownerFlat?.PRICE || 0) * Number(item?.ownerFlat?.AREA),
                                            {
                                                code: 'EUR',
                                                symbol: '',
                                            }
                                    )}₸/м²`}>
                                        <h4>Стоимость: {currencyFormatter.format(
                                            Number(item?.total_price || 0),
                                            {
                                                code: 'EUR',
                                                symbol: '',
                                            }
                                        )} ₸</h4>
                                    </Tooltip>
                                    <div className={styles.line}></div>
                                    <div className={styles.row}>
                                        <h5>Статус синх.</h5>
                                        {
                                            item?.sync_status ?
                                            <Tag color="lime">Да</Tag>
                                            :
                                                <Tag color="red">Нет</Tag>
                                        }
                                    </div>
                                    <div className={styles.line}></div>
                                    <div className={styles.row}>
                                        <h5>ID:</h5>
                                        {
                                            item?._id
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                :
                <Result
                    status="404"
                    title="Информации не найдено"
                    subTitle="Мы ничего не нашли :("
                    extra={null}
                />
            }

        </div>
    )
}

export default SyncMWC;