"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MappingLayer = exports.getMappingFunction = void 0;
var util_1 = require("@antv/util");
/**
 * 获得映射函数
 * @param mappingFields
 * @param callback
 */
function getMappingFunction(mappingFields, callback) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var params = {};
        mappingFields.forEach(function (f, index) {
            params[f] = args[index];
        });
        delete params['undefined'];
        return callback(params);
    };
}
exports.getMappingFunction = getMappingFunction;
var MappingLayer = /** @class */ (function () {
    function MappingLayer() {
    }
    MappingLayer.shape = function (layer, shape) {
        /**
         * shape 的几种情况
         * layer.shape('cicle');
         * layer.shape('shape', ['cicle', 'square']);
         * layer.shape('x', (x) => 'cicle');
         * layer.shape('x*y', (x, y) => 'cicle');
         */
        if ((0, util_1.isString)(shape)) {
            layer.shape(shape);
        }
        else if ((0, util_1.isFunction)(shape)) {
            // TODO: shape mappingFields
            var mappingFields = [];
            layer.shape(mappingFields.join('*'), getMappingFunction(mappingFields, shape));
        }
        else if ((0, util_1.isObject)(shape)) {
            var field = shape.field ? shape.field : '';
            if ((0, util_1.isFunction)(shape.value)) {
                var mappingFields = (0, util_1.isArray)(field) ? field : field.split('*');
                layer.shape(field, getMappingFunction(mappingFields, shape.value));
            }
            else {
                layer.shape(field, shape.value);
            }
            // scale
            if ((0, util_1.isString)(field) && shape.scale) {
                MappingLayer.scale(layer, field, shape.scale);
            }
        }
    };
    MappingLayer.size = function (layer, size) {
        /**
         * size 的几种情况
         * layer.size(10);
         * layer.size('size', [10, 20]);
         * layer.size('x', (x) => 10);
         * layer.size('x*y', (x, y) => 10);
         */
        if ((0, util_1.isNumber)(size)) {
            layer.size(size);
        }
        else if ((0, util_1.isArray)(size)) {
            layer.size(size);
        }
        else if ((0, util_1.isFunction)(size)) {
            // TODO: size mappingFields
            var mappingFields = [];
            layer.size(mappingFields.join('*'), getMappingFunction(mappingFields, size));
        }
        else if ((0, util_1.isObject)(size)) {
            var field = size.field ? size.field : '';
            if ((0, util_1.isFunction)(size.value)) {
                var mappingFields = (0, util_1.isArray)(field) ? field : field.split('*');
                layer.size(field, getMappingFunction(mappingFields, size.value));
            }
            else {
                layer.size(field, size.value);
            }
            // scale
            if ((0, util_1.isString)(field) && size.scale) {
                MappingLayer.scale(layer, field, size.scale);
            }
        }
    };
    MappingLayer.color = function (layer, color) {
        /**
         * color 的几种情况
         * layer.color('red');
         * layer.color('color', ['red', 'blue']);
         * layer.color('x', (x) => 'red');
         * layer.color('x*y', (x, y) => 'red');
         */
        if ((0, util_1.isString)(color)) {
            layer.color(color);
        }
        else if ((0, util_1.isFunction)(color)) {
            // TODO: color mappingFields
            var mappingFields = [];
            layer.color(mappingFields.join('*'), getMappingFunction(mappingFields, color));
        }
        else if ((0, util_1.isObject)(color)) {
            var field = color.field ? color.field : '';
            if ((0, util_1.isFunction)(color.value)) {
                var mappingFields = (0, util_1.isArray)(field) ? field : field.split('*');
                layer.color(field, getMappingFunction(mappingFields, color.value));
            }
            else {
                layer.color(field, color.value);
            }
            // scale
            if ((0, util_1.isString)(field) && color.scale) {
                MappingLayer.scale(layer, field, color.scale);
            }
        }
    };
    MappingLayer.style = function (layer, style) {
        style && layer.style(style);
    };
    MappingLayer.state = function (layer, state) {
        var active = state.active, select = state.select;
        active && layer.active(active);
        select && layer.select(select);
    };
    MappingLayer.rotate = function (layer, rotate) {
        /**
         * rotate 的几种情况
         * layer.rotate(45);
         * layer.rotate('rotate', [45, 90]);
         * layer.rotate('x', (x) => 45);
         * layer.rotate('x*y', (x, y) => 45);
         */
        if ((0, util_1.isString)(rotate)) {
            // TODO: L7 rotate
            // layer.rotate(rotate);
        }
        else if ((0, util_1.isFunction)(rotate)) {
            // TODO: rotate isFunction
        }
        else if ((0, util_1.isObject)(rotate)) {
            // TODO: L7 rotate
        }
    };
    MappingLayer.texture = function (layer, texture) {
        /**
         * texture 的几种情况
         * layer.texture('plane');
         */
        if ((0, util_1.isString)(texture)) {
            layer.texture(texture);
        }
    };
    MappingLayer.animate = function (layer, animate) {
        /**
         * animate 的几种情况
         * layer.animate(true);
         * layer.animate({rings: 10});
         */
        if ((0, util_1.isBoolean)(animate) || (0, util_1.isObject)(animate)) {
            layer.animate(animate);
        }
    };
    MappingLayer.scale = function (layer, field, cfg) {
        /**
         * scale 的几种情况
         * layer.scale('name', {type: 'cat'});
         * layer.scale({name: {type: 'cat'}, value: {type: 'linear'}});
         */
        layer.scale(field, cfg);
    };
    MappingLayer.filter = function (layer, filter) {
        /**
         * scale 的几种情况
         * layer.filter('name', ({ name }) => name === 'name');
         */
        var field = filter.field ? filter.field : '';
        var mappingFields = (0, util_1.isArray)(field) ? field : field.split('*');
        layer.filter(mappingFields.join('*'), getMappingFunction(mappingFields, filter.value));
    };
    return MappingLayer;
}());
exports.MappingLayer = MappingLayer;
