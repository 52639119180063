import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Result, Modal, Input, Select, Statistic, message } from 'antd';
import styles from '../../styles/index.module.scss';
import currencyFormatter from 'currency-formatter';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../../Services';

const Companies = ({ data, loading, type_tab }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const stateRalestate = useSelector(
        (state) => state?.apartments?.apartmentsForRealtors
    );

    const [modal_form_company, set_modal_form_company] = useState(false);
    const [title, set_title] = useState('');
    const [realestate, set_realestate] = useState(null);
    const [crm_price_lead, set_crm_price_lead] = useState(0);
    const [quantity_leads, set_quantity_leads] = useState('');

    useEffect(() => {
        if (type_tab === 'gift' && modal_form_company) {
            dispatch(
                api.apartments.getApartmentsComplexForRealtors('crm-companies')
            );
        }
    }, [type_tab, modal_form_company]);

    const createGiftCompany = () => {
        if (title && realestate && quantity_leads) {
            set_modal_form_company(false);
            return dispatch(
                api.users.createCompanyLeads({
                    title,
                    realestate,
                    quantity_leads,
                    gift: true,
                    realtor: id,
                })
            );
        }

        return message.warning('Заполните поля');
    };

    return (
        <div>
            {type_tab === 'gift' && (
                <>
                    <Modal
                        title="Создание подарочной компании"
                        visible={modal_form_company}
                        onOk={createGiftCompany}
                        onCancel={() => set_modal_form_company(false)}
                        width="65%"
                        okText="Создать"
                    >
                        <Input
                            value={title}
                            size="large"
                            placeholder="Введите наименование компании"
                            onChange={(val) => set_title(val.target.value)}
                            style={{ marginBottom: 10 }}
                        />
                        <Select
                            size="large"
                            placeholder="выберите жилой комплекс"
                            onChange={(value) => {
                                let getRealestate = stateRalestate.filter(
                                    (re) => String(re?._id) === String(value)
                                )[0];

                                set_crm_price_lead(
                                    getRealestate?.crm_price_lead || 0
                                );

                                set_realestate(value);
                            }}
                            style={{ width: '100%', marginBottom: 10 }}
                            dropdownStyle={{ zIndex: 9999999999 }}
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                        optionB.children.toLowerCase()
                                    )
                            }
                        >
                            {stateRalestate &&
                                stateRalestate.length &&
                                stateRalestate.map((item) => {
                                    return (
                                        <Select.Option
                                            key={item?._id}
                                            value={item?._id}
                                        >
                                            {item?.title}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                        <Input
                            value={quantity_leads}
                            size="large"
                            placeholder="Введите требуемое количество лидов"
                            onChange={(val) =>
                                set_quantity_leads(val.target.value)
                            }
                            style={{ marginBottom: 10 }}
                        />
                        <div className={styles.blockInfoStat}>
                            <Statistic
                                title={`Бюджет (стоимость 1 лида равен ${crm_price_lead}₸)`}
                                value={
                                    Number(crm_price_lead) *
                                    Number(quantity_leads)
                                }
                            />
                        </div>
                    </Modal>
                    <Button
                        style={{ marginBottom: 15 }}
                        type="primary"
                        onClick={() => set_modal_form_company(true)}
                    >
                        Добавить подарочную компанию
                    </Button>
                </>
            )}

            <div>
                {loading ? (
                    <div className={styles.blockInfo}>
                        <LoadingOutlined />
                    </div>
                ) : data && data.length ? (
                    <div className={styles.companyItems}>
                        {data.map((item) => {
                            return (
                                <div key={item?._id} className={styles.item}>
                                    <div className={styles.itemSection}>
                                        <p>{item?.title}</p>
                                    </div>
                                    <div className={styles.line}></div>
                                    <div className={styles.itemSection}>
                                        <p>
                                            {item?.collected_leads || 0} из{' '}
                                            {item?.quantity_leads || 0} лидов
                                        </p>
                                    </div>
                                    <div className={styles.line}></div>
                                    <div className={styles.itemSection}>
                                        <p>
                                            {currencyFormatter.format(
                                                item?.budget,
                                                {
                                                    code: 'EUR',
                                                    symbol: '',
                                                }
                                            ) + '₸'}
                                        </p>
                                    </div>
                                    <div className={styles.line}></div>
                                    <div className={styles.itemSection}>
                                        <p>
                                            {item?.status === 'await'
                                                ? 'Ожидает оплаты'
                                                : item?.status === 'active'
                                                ? 'Активно'
                                                : 'Завершена'}
                                        </p>
                                    </div>

                                    <div className={styles.line}></div>
                                    <div className={styles.itemSection}>
                                        <p>
                                            {item?.realestate?.title ||
                                                'Не найдено'}
                                        </p>
                                    </div>

                                    {item?.gift && (
                                        <div className={styles.line}></div>
                                    )}

                                    {item?.gift && (
                                        <div className={styles.itemSection}>
                                            <p>
                                                Предоставлено:{' '}
                                                {item?.presented?.email} (
                                                {item?.presented?.role?.title})
                                            </p>
                                        </div>
                                    )}

                                    <div className={styles.line}></div>
                                    <div className={styles.itemSection}>
                                        <Link
                                            to={`/realtors/edit/${id}/leads/${item?._id}`}
                                        >
                                            смотреть лиды
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Result
                        status="404"
                        title="Компаний не найдено"
                        subTitle="Информации не найдено"
                        extra={null}
                    />
                )}
            </div>
        </div>
    );
};

export default Companies;
