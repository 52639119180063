import { gql } from '@apollo/client';

const searchProduct = gql`
  query($word:String) {
    searchProducts(word:$word) {
      _id
      title
    }
  }
`;

export default {
  searchProduct
}