import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/lib/integration/react';
import createStore from './Services/Store/';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloSetup } from './Services';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import { RecentEvents } from './Components';
import Routing from './Containers/Initial/routing';
import './Services/Styles/app.css';

const { store, persistor } = createStore();

const apollo = new ApolloSetup();
const root = createRoot(document.getElementById('root'));

root.render(
    <ConfigProvider locale={ru_RU}>
        <ApolloProvider client={apollo.init()}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <RecentEvents>
                            <Routing />
                        </RecentEvents>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </ApolloProvider>
    </ConfigProvider>
);

serviceWorker.unregister();
