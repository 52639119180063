"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mappingLayer = void 0;
var layer_1 = require("../../adaptor/layer");
function mappingLayer(layer, options) {
    var shape = options.shape, color = options.color, size = options.size, style = options.style, state = options.state, animate = options.animate, texture = options.texture;
    // mapping shape
    shape && layer_1.MappingLayer.shape(layer, shape);
    // mapping size
    size && layer_1.MappingLayer.size(layer, size);
    // mapping color
    color && layer_1.MappingLayer.color(layer, color);
    // mapping style
    style && layer_1.MappingLayer.style(layer, style);
    // mapping state
    state && layer_1.MappingLayer.state(layer, state);
    // mapping animate
    animate && layer_1.MappingLayer.animate(layer, animate);
    // mapping texture
    texture && layer_1.MappingLayer.texture(layer, texture);
}
exports.mappingLayer = mappingLayer;
