import React, { useEffect, useState, memo } from 'react';
import { Popover, Slider, Tooltip } from 'antd';

const LayoutDot = ({
    scene,
    eventPreviewPhoto,
    index,
    styles,
    refMain,
    tour_index,
    virtual_tour,
    updateFields,
    vt_id,
}) => {
    const [yaw_status, set_yaw_status] = useState(false);
    const [yaw_layout_diff, set_yaw_layout_diff] = useState(
        scene?.yaw_layout_diff || 0
    );
    const [yaw, set_yaw] = useState(0);
    useEffect(() => {
        if (virtual_tour) {
            let mouseDawn = false;

            let getElementDragPnm = document.querySelector('.pnlm-container');

            getElementDragPnm.addEventListener('mousedown', () => {
                if (!yaw_status) {
                    set_yaw_status(true);
                }

                mouseDawn = true;
            });
            getElementDragPnm.addEventListener('mouseup', () => {
                mouseDawn = false;
            });
            getElementDragPnm.addEventListener('mousemove', () => {
                if (mouseDawn) {
                    set_yaw(refMain?.current?.panorama?.getYaw());
                }
            });

            getElementDragPnm.addEventListener('touchmove', () => {
                if (!yaw_status) {
                    set_yaw_status(true);
                }
                set_yaw(refMain?.current?.panorama?.getYaw());
            });
        }
    }, [virtual_tour]);

    useEffect(() => {
        set_yaw_status(false);
    }, [tour_index]);

    const updateYAWDot = (val) => {
        updateFields({
            variables: {
                fields: {
                    scene_id: scene?._id,
                    type: 'yaw_layout_diff',
                    yaw_layout_diff: val,
                    vt_id,
                },
            },
        });
    }

    const renderAngleUI = () => {
        return (
            <Slider
                autoAdjustOverflow={false}
                min={1}
                max={360}
                onAfterChange={updateYAWDot}
                onChange={(val)=>{
                    set_yaw_layout_diff(val)
                }}
                value={yaw_layout_diff}
            />
        );
    };

    return (
        <div key={scene?._id}>
            <Tooltip title={scene?.title_room?.title || 'Не опредено'}>
                <Popover
                    autoAdjustOverflow={true}
                    placement="right"
                    title="Укажите правильный угол обзора"
                    content={renderAngleUI}
                >
                    <div
                        onClick={() => eventPreviewPhoto(index)}
                        className={`${styles.dot} ${
                            tour_index === index ? styles.dotActive : {}
                        }`}
                        style={{
                            top: `${scene?.dott_for_room?.top}%`,
                            left: `${scene?.dott_for_room?.left}%`,
                        }}
                        key={scene?._id + Math.random()}
                    >
                        {tour_index === index ? (
                        <div
                            className={`${styles.triangleView}`}
                            style={{
                                transform: `rotateZ(${
                                    yaw -
                                    (yaw_layout_diff ||
                                        scene?.yaw_layout_diff ||
                                        0)
                                }deg)`,
                            }}
                        ></div>
                        ) : (
                            <></>
                        )}
                    </div>
                </Popover>
            </Tooltip>
        </div>
    );
};

export default memo(LayoutDot);
