import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteForever, Edit, FlipToBack } from '@material-ui/icons';

const ItemBlock = ({
    item,
    id,
    setViewBlock,
    deleteBlock,
    api,
    dispatch,
    editPointPlan,
    saveEditAreaPointBlock,
}) => {
    const [editBlock, setEditBlock] = useState(false);

    const [statusEditAreaPointBlock, setStatusEditAreaPointBlock] =
        useState(false);

    const [title, setTitle] = useState(item.title);
    const [floors_count, setFloors_count] = useState(item.floors_count);

    const [seoTitle, setSeoTitle] = useState(
        item.seo_title ? item.seo_title : ''
    );
    const [seoKeywords, setSeoKeywords] = useState(
        item.seo_keywords ? item.seo_keywords : ''
    );
    const [seoDescription, setSeoDescription] = useState(
        item.seo_description ? item.seo_description : ''
    );

    const [sync_id, set_sync_id] = useState(item.sync_id ? item.sync_id : '');
    const [sync_entrance, set_sync_entrance] = useState(
        item.sync_entrance ? item.sync_entrance : ''
    );
    const [redirect_url, set_redirect_url] = useState(
        item.redirect_url ? item.redirect_url : ''
    );

    const editBlockService = () => {
        if (!title && !floors_count) {
            setTitle(item.title);
            setFloors_count(item.floors_count);
        }

        setEditBlock(false);
        dispatch(
            api.apartments.editBlockApartment(
                title,
                floors_count,
                item._id,
                seoTitle,
                seoKeywords,
                seoDescription,
                sync_id,
                sync_entrance,
                redirect_url
            )
        );
    };

    return (
        <div
            onMouseEnter={() => setViewBlock(item)}
            onMouseLeave={() => setViewBlock(null)}
            className="block-list-item block-list-item-block"
            style={
                editBlock
                    ? {
                          background: '#ffffff',
                          boxShadow: '0 0 15px #bbb',
                          zIndex: 1111,
                          borderRadius: '10px',
                          margin: '15px 0',
                      }
                    : {}
            }
        >
            {editBlock ? (
                <div className="block-list-item-flex-form">
                    <p>Название блока</p>
                    <input
                        onChange={(value) => setTitle(value.target.value)}
                        type="text"
                        value={title}
                    />

                    <p>Количество этажей</p>
                    <input
                        onChange={(value) =>
                            setFloors_count(value.target.value)
                        }
                        type="text"
                        value={floors_count}
                    />

                    <p>SEO Keywords</p>
                    <input
                        onChange={(value) => setSeoKeywords(value.target.value)}
                        type="text"
                        value={seoKeywords}
                    />

                    <p>SEO Title</p>
                    <input
                        onChange={(value) => setSeoTitle(value.target.value)}
                        type="text"
                        value={seoTitle}
                    />

                    <p>SEO Description</p>
                    <input
                        onChange={(value) =>
                            setSeoDescription(value.target.value)
                        }
                        type="text"
                        value={seoDescription}
                    />

                    <p>ID block for sync</p>
                    <input
                        onChange={(value) => set_sync_id(value.target.value)}
                        type="text"
                        value={sync_id}
                    />

                    <p>Entrance (Только для bi group)</p>
                    <input
                        onChange={(value) =>
                            set_sync_entrance(value.target.value)
                        }
                        type="text"
                        value={sync_entrance}
                    />

                    <p>Redirect url</p>
                    <div className="redirect_url_input">
                        <b>https://logovo.kz/</b>
                        <input
                            onChange={(value) =>
                                set_redirect_url(value.target.value)
                            }
                            type="text"
                            value={redirect_url}
                        />
                    </div>

                    <input
                        type="submit"
                        onClick={() => editBlockService()}
                        value="Сохранить"
                    />
                </div>
            ) : statusEditAreaPointBlock ? (
                <div
                    className="block-list-item-flex-form"
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <p>{title}</p>
                    <input
                        type="submit"
                        className="block-list-item-flex-form-save"
                        onClick={() => {
                            saveEditAreaPointBlock(item._id);
                            setStatusEditAreaPointBlock(false);
                        }}
                        value="Сохранить"
                    />
                </div>
            ) : (
                <div className="block-list-item-flex">
                    <p>{title}</p>
                    <div className="block-list-item-flex-button-control">
                        <span
                            onClick={() => {
                                setStatusEditAreaPointBlock(true);
                                editPointPlan(
                                    item.area_points,
                                    item.area_points_string
                                );
                            }}
                            className="edit-block-button"
                        >
                            <FlipToBack />
                        </span>
                        <span
                            onClick={() => setEditBlock(!editBlock)}
                            className="edit-block-button"
                        >
                            <Edit />
                        </span>
                        <span
                            onClick={() => {
                                deleteBlock(item._id);
                            }}
                            title="Удалить"
                            className="block-icon-remove"
                        >
                            <DeleteForever />
                        </span>
                        <Link
                            to={`/apartments/blocks/${item._id}/add-floors/${id}`}
                        >
                            Этажи и квартиры
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ItemBlock;
