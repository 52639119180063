import React, {useEffect} from 'react';
import styles from '../../styles/index.module.scss';
import {LoadingOutlined} from '@ant-design/icons';

const ListRealestate = ({
	status_hide,
	dispatch,
	api,
	select_realestate,
	logState,
	close
}) => {

	useEffect(()=>{

		if(!status_hide){
			dispatch(api.logs.realestateOfMacroAPI(select_realestate));
		}

	}, [status_hide]);

	return (
		<div className={`${styles.modalRealestate} ${!status_hide && styles.modalRealestateOpened}`}>

			<div onClick={()=>close()} className={styles.contentRealestateOverflow}></div>

			<div className={styles.contentRealestate}>

				<h5>Список Жк</h5>

				{
					logState?.macro_request_api ?
						<div className={styles.awaitInfo}>
							<LoadingOutlined className={styles.awaitInfoIcon} />
						</div>
					:
						logState?.macro_realestate_list_from_api && logState?.macro_realestate_list_from_api.length ?
							logState?.macro_realestate_list_from_api.map((item)=>{
								return (
									<div key={item?.id} className={`${styles.itemRealestate} ${styles.itemRealestateWithHouses}`}>
										<h4>{item?.title || item?.name}</h4>
										{
										item?.houses && item?.houses ?
											item?.houses.map((itemHouse)=>{
												return (
													<div key={itemHouse?.id} className={`${styles.itemRealestateHouse}`}>
														<h5>{itemHouse?.name}</h5>
														<p>{itemHouse?.address}</p>
														<b>{itemHouse?.id}</b>
													</div>
												)
											})
										:
											<h6>Информации не найдено</h6>	
										}
									</div>
								)
							})
					:
						<div className={styles.awaitInfo}>
							<h6>Ничего не найдено</h6>
						</div>
				}

			</div>
		</div>
	)
}

export default ListRealestate;