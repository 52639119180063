const initialState = {
	list:null,
	page_by_id:null,

	settings:null,

  setting_seo:null,
	
	request_status:false
}

const pages = (state = initialState, action) => {
	switch(action.type){

		case 'UPDATE_PAGES' :
			return {
				...state,
				list:action.data
			}

		case 'UPDATE_PAGES_SETTING' :
			return {
				...state,
				settings:action.data
			}

    case 'UPDATE_PAGE_SETTING_SEO' :
      return {
        ...state,
        setting_seo:action.data
      }

		case 'UPDATE_PAGE_BY_ID' :
			return {
				...state,
				page_by_id:action.data
			}

		case 'REQUEST_STATUS_PAGES' :
			return {
				...state,
				request_status:action.status
			}

		default :
			return state
	}
}

export default pages;