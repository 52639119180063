import { produce } from 'immer';

const initialState = {
    realestate_list: [],
    realestate_count: 0,
    cities_list: [],
    request_status_realestate: false,

    realestate_list_sort: [],

    request_status_filter_meta: false,
    data_filter_meta: null
};

const realestate = (state = initialState, action) => {
    switch (action.type) {

        case 'REQUEST_STATUS_FILTER_META':
            return produce(state, draftState => {
                draftState.request_status_filter_meta = action.status || false;
            });

        case 'REQUEST_STATUS_REALESTATE':
            return produce(state, draftState => {
                draftState.request_status_realestate = action.status || false;
            });

        case 'DATA_REALESTATE':
            return produce(state, draftState => {
                draftState.realestate_list = action.data?.data || [];
                draftState.realestate_count = action.data?.count || 0;

                if(!state?.data_filter_meta){
                    draftState.data_filter_meta = action.data?.filter_meta || null;
                }

            });

        case 'DATA_REALESTATE_SORT':
            return produce(state, draftState => {
                draftState.realestate_list_sort = action.data?.data || [];
                draftState.cities_list = action.data?.cities || [];
            });

        default:
            return state;
    }
};

export default realestate;
