import React from 'react';
import { CaretLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

const Scene = ({
    scene,
    styles,
    config,
    index,
    tour_index,
    eventPreviewPhoto,
    updateScene,
    vt_id,
}) => {
    return (
        <div
            className={`${styles.scene}`}
            style={{
                background: `url(${config.public}${scene?.url}) center / cover no-repeat`,
            }}
            onClick={() => eventPreviewPhoto(index)}
        >
            {index === tour_index ? (
                <>
                    <CaretLeftOutlined className={`${styles.activeScene}`} />
                    <Popconfirm
                        title="Сцена будет удалена"
                        onConfirm={() => {
                            updateScene({
                                variables: {
                                    fields: {
                                        photo_id: scene._id,
                                        vt_id: vt_id,
                                        type: 'pull',
                                    },
                                },
                            });
                        }}
                        okText="Удалить"
                        cancelText="Нет"
                    >
                        <Button size="small" className={styles.sceneRemove}>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Scene;
