import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, message, Modal, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './styles/index.module.scss';
import { Loader } from '@/Components';
import { useMutation, useQuery } from '@apollo/client';
import { budget } from '@/Services/apollo/gqls';

const Budget = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [template_id, set_template_id] = useState('');
    const [title_template, set_title_template] = useState('');
    const [title_group_categories, set_title_group_categories] = useState('');

    let { data, loading } = useQuery(budget.getAllTemplates, {
        fetchPolicy: 'cache-and-network',
    });

    let [removeTemplate] = useMutation(budget.removeTemplate, {
        refetchQueries: [{ query: budget.getAllTemplates }],
    });

    let [cloneBudgetTemplate, cloneBudgetTemplateInfo] = useMutation(
        budget.cloneBudgetTemplate,
        {
            refetchQueries: [
                {
                    query: budget.getAllTemplates,
                },
                {
                    query: budget.getGroupBudgetCategories,
                },
            ],
        }
    );

    const createCopyBudgetTemplate = () => {
        if (cloneBudgetTemplateInfo?.loading) {
            return;
        }

        if (title_template && title_group_categories) {
            cloneBudgetTemplate({
                variables: {
                    fields: {
                        template_id,
                        title_template,
                        title_group_categories,
                    },
                },
                onError: () => {
                    setIsModalVisible(false);
                    message.info({
                        duration: 1.5,
                        content: 'Произошла ошибка, попробуйте позже',
                        className: `${styles.messageNotify}`,
                    });
                },
                onCompleted: () => {
                    setIsModalVisible(false);
                    message.success({
                        duration: 1.5,
                        content: 'Копия шаблона успешно создана',
                        className: `${styles.messageNotify}`,
                    });
                },
            });

            return;
        }

        message.error({
            content: 'Заполните все поля',
            className: `${styles.messageNotify}`,
        });
    };

    return (
        <content className="cities-page-block">
            <div className={styles.headPageBudget}>
                <h1>Бюджет шаблоны</h1>
                <div className={styles.rightButtonCategories}>
                    <Link to="/budget/categories/groups">Группы</Link>
                    <Link to="/budget/categories">Категории</Link>
                    <Link to="/budget/create">Создать шаблон</Link>
                </div>
            </div>

            <div className={styles.listTemplates}>
                <Modal
                    title="Создание копии шаблона"
                    wrapClassName={styles.modalCreateCloneTemplate}
                    visible={isModalVisible}
                    onOk={createCopyBudgetTemplate}
                    onCancel={() => setIsModalVisible(false)}
                    width="45%"
                    okText={
                        cloneBudgetTemplateInfo?.loading
                            ? 'Подождите...'
                            : 'Создать'
                    }
                    cancelText="отмена"
                >
                    <Input
                        value={title_template}
                        onChange={(val) => set_title_template(val.target.value)}
                        placeholder="Введите название шаблона"
                    />
                    <Input
                        value={title_group_categories}
                        onChange={(val) =>
                            set_title_group_categories(val.target.value)
                        }
                        placeholder="Введите название группы категорий"
                    />
                </Modal>

                {loading ? (
                    <Loader height={450} />
                ) : data &&
                  data.getAllTemplates &&
                  data.getAllTemplates.length ? (
                    data.getAllTemplates.map((item, index) => {
                        return (
                            <div className={styles.itemTemplate} key={item._id}>
                                <div>
                                    <div className={styles.itemTemplateLeft}>
                                        <Link to={`/budget/edit/${item._id}`}>
                                            <span>#{index + 1}</span>
                                            <span> {item.title}</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className={styles.rightPanelListBudget}>
                                    <small
                                        onClick={() => {
                                            setIsModalVisible(true);
                                            set_template_id(item?._id);
                                            set_title_template('');
                                            set_title_group_categories('');
                                        }}
                                    >
                                        Создать копию шаблона
                                    </small>

                                    <Popconfirm
                                        placement="left"
                                        title="Шаблон будет удален?"
                                        onConfirm={() => {
                                            removeTemplate({
                                                variables: {
                                                    template_id: item._id,
                                                },
                                            });
                                        }}
                                        onCancel={() => {}}
                                        okText="Удалить"
                                        cancelText="Нет"
                                    >
                                        <DeleteOutlined />
                                    </Popconfirm>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <h4>Шаблонов не найдено</h4>
                )}
            </div>
        </content>
    );
};

export default Budget;
