import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import styles from '../../styles/custom-chess.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { minFloor } from '../funcs/min-floor';

const FlatForm = ({ api, realestate_id, area_points, area_points_for_css }) => {
    const dispatch = useDispatch();
    const stateChess = useSelector((state) => state.chess);
    const stateChessData = useSelector((state) => state.chessData);

    const eventForm = (fields) => {
        if (!stateChess?.request_status_create_block) {
            let getMinFloor = minFloor(stateChess?.flat_boxes || []);

            dispatch(
                api.chess.createFlat({
                    ...fields,
                    area_points: area_points || [],
                    area_points_for_css: area_points_for_css || '',
                    grid: stateChess?.flat_boxes || [],
                    floor: getMinFloor || '',
                    block_id: stateChess?.block_id || '',
                    type_form: stateChess?.modal_form_flat_type || '',
                    flat_type: stateChess?.modal_form_create_type_flat || '',
                    boxes: stateChess?.flat_boxes || [],
                    floor_plan_id: stateChess?.floor_plan_id || '',
                    realestate_id,
                })
            );
        }
    };

    return (
        <Form
            name="basic"
            initialValues={{
                title: '',
                template: null,
            }}
            onFinish={eventForm}
            className={styles.form}
        >
            <Form.Item
                label=""
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Заполните номер квартиры',
                    },
                ]}
                className={styles.formInput}
            >
                <Input size="large" placeholder="Введите номер квартиры" />
            </Form.Item>

            <Form.Item
                label=""
                name="template"
                rules={[
                    {
                        required: true,
                        message: 'Выберите шаблон квартиры',
                    },
                ]}
                className={styles.formInput}
            >
                <Select
                    size="large"
                    showSearch
                    placeholder="Выберите шаблон квартиры"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.title ?? '').includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.title ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.title ?? '').toLowerCase())
                    }
                    options={stateChessData?.data?.templates || []}
                    fieldNames={{
                        value: '_id',
                        label: 'title',
                    }}
                />
            </Form.Item>

            <Form.Item>
                <Button size="medium" type="primary" htmlType="submit">
                    {stateChess?.request_status_create_block ? (
                        <LoadingOutlined />
                    ) : (
                        'Добавить'
                    )}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FlatForm;
