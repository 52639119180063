import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { budget } from '@/Services/apollo/gqls';
import styles from '../styles/index.module.scss';
import { useMutation, useQuery } from '@apollo/client';

const CreateBudget = () => {
    const history = useNavigate();

    const [title, set_title] = useState('');
    const [group_id, set_group_id] = useState(null);

    let getGroups = useQuery(budget.getGroupBudgetCategories);
    let createTemplate = useMutation(budget.createTemplate, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            return history(`/budget/edit/${data.createTemplate}`);
        },
    });

    const createTemplateEvent = () => {
        if (title && group_id) {
            createTemplate[0]({
                variables: {
                    fields: {
                        title,
                        group_id,
                    },
                },
            });
            return;
        }

        alert('Заполните все поля');
    };

    return useMemo(
        () => (
            <content
                className={`${styles.mainBlock} ${styles.mainBlockGroupTemplateForm}`}
            >
                <h1>Создание шаблона</h1>

                <div className={styles.listGroupsForm}>
                    <h5>Выберите группу категорий</h5>
                    {getGroups.loading ? (
                        <h6>Загружаем...</h6>
                    ) : getGroups.data.getGroupsBudgetCategories &&
                      getGroups.data.getGroupsBudgetCategories.length ? (
                        <select
                            onChange={(val) => set_group_id(val.target.value)}
                        >
                            <option value={null}>Выберите группу</option>
                            {getGroups.data.getGroupsBudgetCategories.map(
                                (group) => {
                                    return (
                                        <option
                                            лун={group._id}
                                            value={group._id}
                                        >
                                            {group.title}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                    ) : (
                        <h6>Групп не найдено</h6>
                    )}
                </div>

                <input
                    placeholder="Введите название шаблона"
                    onChange={(value) => set_title(value.target.value)}
                    value={title}
                />
                <input
                    type="submit"
                    value="Создать шаблон"
                    onClick={createTemplateEvent}
                />
            </content>
        ),
        [title, getGroups]
    );
};

export default CreateBudget;
