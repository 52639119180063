import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CheckLoadedFile from './checkLoadedFile';
import config from '@/Config';
import { Input, Select } from 'antd';

const { Option } = Select;

const FilItem = ({
    title,
    NotificationManager,
    api,
    paidTitleOne,
    paidTitleTwo,
    freetitle,
    paidTitleOneTypeForDispatch,
    paidTitleTwoTypeForDispatch,
    freetitleTypeForDispatch,
    template,
}) => {
    const [price, setPrice] = useState('');
    const [file_name, set_file_name] = useState('');
    const [type_file_name, set_type_file_name] = useState('');
    const [select_file, set_select_file] = useState(null);

    const dispatch = useDispatch();
    const getFilesState = useSelector((state) => state.files);

    const uploadFile = () => {
        if (!select_file || (select_file && select_file.url == '')) {
            return NotificationManager.warning('Выберите файл');
        }

        dispatch(api.files.saveFileFlat(select_file, price));

        setPrice('');
        set_type_file_name('');
        set_file_name('');
        set_select_file(null);
    };

    const getFilesSearch = (val, type) => {
        set_file_name(val);
        set_type_file_name(type);
        dispatch(api.files.searchFileOnLogovoServer(val));
    };

    const selectMainFileFromServer = (val) => {
        let filename = val.replace('/var/files/', '');
        let urlFile = config.server_file_logovo + filename;

        set_select_file({
            url: urlFile,
            paid: true,
            type: paidTitleOneTypeForDispatch,
        });
    };

    return (
        <div>
            <section>
                <h4>{title}</h4>

                <div>
                    {paidTitleOne && (
                        <div className="paid-block">
                            <h3>{paidTitleOne}</h3>

                            <Input
                                size="large"
                                type="text"
                                value={
                                    type_file_name == 'FIRST' ? file_name : ''
                                }
                                onChange={(val) => {
                                    getFilesSearch(val.target.value, 'FIRST');
                                }}
                                className="price-file-input"
                                placeholder="Начните вводить название файла"
                            />

                            {type_file_name == 'FIRST' &&
                            getFilesState.search_file_result &&
                            getFilesState.search_file_result.length ? (
                                <Select
                                    showSearch
                                    size="large"
                                    style={{ width: '100%' }}
                                    onChange={(val) => {
                                        selectMainFileFromServer(val);
                                    }}
                                >
                                    <Option value={null}>Выберите файл</Option>

                                    {getFilesState.search_file_result.map(
                                        (item) => {
                                            let fileName = item.replace(
                                                '/var/files',
                                                ''
                                            );
                                            return (
                                                <Option
                                                    key={`item_${Math.random()}`}
                                                    value={item}
                                                >
                                                    {fileName}
                                                </Option>
                                            );
                                        }
                                    )}
                                </Select>
                            ) : (
                                <></>
                            )}

                            <Input
                                size="large"
                                type="text"
                                value={price}
                                onChange={(val) => {
                                    setPrice(val.target.value);
                                }}
                                className="price-file-input"
                                placeholder="Стоимость файла"
                            />

                            {getFilesState.request_upload_status ? (
                                <Input
                                    size="large"
                                    type="submit"
                                    className="button-upload-file"
                                    value="Подождите..."
                                />
                            ) : (
                                <Input
                                    size="large"
                                    type="submit"
                                    onClick={() => {
                                        uploadFile();
                                    }}
                                    className="button-upload-file"
                                    value="Загрузить и прикрепить данные к форме"
                                />
                            )}

                            <CheckLoadedFile
                                files={template ? template.files : null}
                                state={getFilesState}
                                type={paidTitleOneTypeForDispatch}
                            />
                        </div>
                    )}
                </div>

                <div>
                    {paidTitleTwo && (
                        <div className="paid-block" style={{ marginTop: 10 }}>
                            <h3>{paidTitleTwo}</h3>

                            <Input
                                size="large"
                                type="text"
                                value={type_file_name == 'TWO' ? file_name : ''}
                                onChange={(val) => {
                                    getFilesSearch(val.target.value, 'TWO');
                                }}
                                className="price-file-input"
                                placeholder="Начните вводить название файла"
                            />

                            {type_file_name == 'TWO' &&
                            getFilesState.search_file_result &&
                            getFilesState.search_file_result.length ? (
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    size="large"
                                    onChange={(val) => {
                                        set_select_file({
                                            url: val.target.value,
                                            paid: true,
                                            type: paidTitleTwoTypeForDispatch,
                                        });
                                    }}
                                >
                                    <Option value={null}>Выберите файл</Option>

                                    {getFilesState.search_file_result.map(
                                        (item) => {
                                            return (
                                                <Option
                                                    key={`item_${Math.random()}`}
                                                    value={item}
                                                >
                                                    {item}
                                                </Option>
                                            );
                                        }
                                    )}
                                </Select>
                            ) : (
                                <></>
                            )}

                            <Input
                                size="large"
                                type="text"
                                value={price}
                                onChange={(val) => {
                                    setPrice(val.target.value);
                                }}
                                className="price-file-input"
                                placeholder="Стоимость файла"
                            />

                            {getFilesState.request_upload_status ? (
                                <Input
                                    size="large"
                                    type="submit"
                                    className="button-upload-file"
                                    value="Подождите..."
                                />
                            ) : (
                                <Input
                                    size="large"
                                    type="submit"
                                    onClick={() => {
                                        uploadFile();
                                    }}
                                    className="button-upload-file"
                                    value="Сохранить"
                                />
                            )}

                            <CheckLoadedFile
                                files={template ? template.files : null}
                                state={getFilesState}
                                type={paidTitleTwoTypeForDispatch}
                            />
                        </div>
                    )}
                </div>

                <div>
                    {freetitle && (
                        <div className="free-block">
                            <h3>{freetitle}</h3>

                            <Input
                                size="large"
                                type="text"
                                value={
                                    type_file_name == 'THREE' ? file_name : ''
                                }
                                onChange={(val) => {
                                    getFilesSearch(val.target.value, 'THREE');
                                }}
                                className="price-file-input"
                                placeholder="Начните вводить название файла"
                            />

                            {type_file_name == 'THREE' &&
                            getFilesState.search_file_result &&
                            getFilesState.search_file_result.length ? (
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    size="large"
                                    onChange={(val) => {
                                        set_select_file({
                                            url: val.target.value,
                                            paid: false,
                                            type: freetitleTypeForDispatch,
                                        });
                                    }}
                                >
                                    <Option value={null}>Выберите файл</Option>

                                    {getFilesState.search_file_result.map(
                                        (item) => {
                                            return (
                                                <Option
                                                    key={`item_${Math.random()}`}
                                                    value={item}
                                                >
                                                    {item}
                                                </Option>
                                            );
                                        }
                                    )}
                                </Select>
                            ) : (
                                <></>
                            )}

                            {getFilesState.request_upload_status ? (
                                <Input
                                    size="large"
                                    type="submit"
                                    className="button-upload-file"
                                    value="Подождите..."
                                />
                            ) : (
                                <Input
                                    size="large"
                                    type="submit"
                                    onClick={() => {
                                        uploadFile();
                                    }}
                                    className="button-upload-file"
                                    value="Сохранить"
                                />
                            )}

                            <CheckLoadedFile
                                files={template ? template.files : null}
                                state={getFilesState}
                                type={freetitleTypeForDispatch}
                            />
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};

export default FilItem;
