"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconLayer = void 0;
var tslib_1 = require("tslib");
var utils_1 = require("../../utils");
var dot_layer_1 = require("../dot-layer");
var IconLayer = /** @class */ (function (_super) {
    tslib_1.__extends(IconLayer, _super);
    function IconLayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图层类型
         */
        _this.type = dot_layer_1.DotLayer.LayerType.IconLayer;
        return _this;
    }
    /**
     * 获取默认配置
     */
    IconLayer.prototype.getDefaultOptions = function () {
        return (0, utils_1.deepAssign)({}, dot_layer_1.DotLayer.DefaultOptions, {
            color: undefined,
        });
    };
    return IconLayer;
}(dot_layer_1.DotLayer));
exports.IconLayer = IconLayer;
