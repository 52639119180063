import React, {useEffect} from 'react';
import styles from '../../styles/index.module.scss';
import {LoadingOutlined} from '@ant-design/icons';

const ListRealestate = ({
	status_hide,
	dispatch,
	api,
	select_realestate,
	logState,
	close
}) => {

	useEffect(()=>{

		if(!status_hide){
			dispatch(api.logs.realestateOfBitrixAPI(select_realestate));
		}

	}, [status_hide]);

	return (
		<div className={`${styles.modalRealestate} ${!status_hide && styles.modalRealestateOpened}`}>

			<div onClick={()=>close()} className={styles.contentRealestateOverflow}></div>

			<div className={styles.contentRealestate}>

				<h5>Список Жк</h5>

				{
					logState?.api_butrix_realestate_request ?
						<div className={styles.awaitInfo}>
							<LoadingOutlined className={styles.awaitInfoIcon} />
						</div>
					:
						logState?.api_butrix_realestate && logState?.api_butrix_realestate.length ?
							logState?.api_butrix_realestate.map((item)=>{
								return (
									<div key={item?.id} className={`${styles.itemRealestate} ${styles.itemRealestateWithHouses}`}>
										<h4>{item?.title || item?.name}</h4>
										<h4>ID: {item?.id}</h4>
									</div>
								)
							})
					:
						<div className={styles.awaitInfo}>
							<h6>Ничего не найдено</h6>
						</div>
				}

			</div>
		</div>
	)
}

export default ListRealestate;