import axios from '../axios';
import { message } from 'antd';

const users = {
    getLastRecentEvents: () => async dispatch => {

        try {

            const { data } = await axios.get(`/v1/admin/logs/last-recent-events`);

            return dispatch({
                type: 'UPDATE_DATA_RECENT_EVENTS',
                data
            });

        }catch(err){

            return false;

        }

    },
    mailConfirmTrigger: (user_id) => async () => {

        try {

            const { data } = await axios.get(`/v1/admin/users/mail-confirm-trigger`, {
                params: {
                    user_id
                }
            });

            return message.success(data);

        }catch(err){

            return false;

        }

    },
    userAccess: (fields, user_id) => async dispatch => {

        dispatch({
            type: 'REQUEST_ACCESS_DATA_SERVICE',
            status: true
        });

        try {

            await axios.post(`/v1/admin/users/access`, {
                ...fields,
                user_id
            });

            dispatch(users.getUserToolsInfo(user_id))

            dispatch({
                type: 'REQUEST_ACCESS_DATA_SERVICE',
                status: false
            });

            return message.success('Доступы успешно выданы');

        }catch(err){

            return dispatch({
                type: 'REQUEST_ACCESS_DATA_SERVICE',
                status: false
            });

        }

    },
    getSBR: (role_id) => async dispatch => {

        dispatch({
            type: 'REQUEST_ACCESS_DATA_SERVICE',
            status: true
        });

        try {

            const { data } = await axios.get(`/v1/admin/users/sbr`, {
                params: {
                    role_id
                }
            }); 

            dispatch({
                type: 'ACCESS_DATA_SERVICE',
                data: data?.data || []
            });

            return dispatch({
                type: 'REQUEST_ACCESS_DATA_SERVICE',
                status: false
            });

        }catch(err){
            return dispatch({
                type: 'REQUEST_ACCESS_DATA_SERVICE',
                status: false
            });
        }

    },
    getUserToolsInfo: (user_id) => async dispatch => {

        dispatch({
            type: 'REQUEST_USER_TOOLS_INFO',
            status: true
        });

        dispatch({
            type: 'DRAWER_USER_TOOLS_INFO',
            status: true
        });

        try {

            const { data } = await axios.get(`/v1/admin/users/user-tools-info`, {
                params: {
                    user_id
                }
            });

            dispatch({
                type: 'DATA_USER_TOOLS_INFO',
                data
            });

            dispatch({
                type: 'REQUEST_USER_TOOLS_INFO',
                status: true
            });

            return

        }catch(err){

            return dispatch({
                type: 'REQUEST_USER_TOOLS_INFO',
                status: false
            });

        }

    },
    createLead: (fields) => async (dispatch) => {
        if (!fields?.client_name && !fields?.link && !fields?.client_phone) {
            return message.warning('Заполните поля');
        }

        try {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: true,
            });

            await axios.post(
                `/v1/admin/users/realtor/companies/lead/create`,
                fields
            );

            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            dispatch(
                users.getLeadsOfCompany(fields?.realtor_id, fields?.company_id)
            );

            return message.success('Лид успешно добавлен');
        } catch (err) {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    createCompanyLeads: (fields) => async (dispatch) => {
        try {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: true,
            });

            let { data } = await axios.post(
                `/v1/admin/users/realtor/companies/create`,
                fields
            );

            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            dispatch({
                type: 'REALTOR_INFO_COMPANY',
                user: data?.user || null,
            });

            dispatch({
                type: 'REALTOR_LEAD_UPDATE_LIST',
                data: data?.data || [],
            });

            dispatch(users.getCompanies(fields?.realtor, 'gift'));

            message.success('Компания успешно создана');

            return;
        } catch (err) {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    getLeadsOfCompany: (realtor, company) => async (dispatch) => {
        try {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: true,
            });

            let { data } = await axios.get(
                `/v1/admin/users/realtor/companies/leads`,
                {
                    params: {
                        realtor,
                        company,
                    },
                }
            );

            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            dispatch({
                type: 'REALTOR_INFO_COMPANY',
                user: data?.user || null,
            });

            dispatch({
                type: 'REALTOR_LEAD_UPDATE_LIST',
                data: data?.data || [],
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    getCompanies: (id, type) => async (dispatch) => {
        try {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: true,
            });

            let { data } = await axios.get(`/v1/admin/users/realtor/companies`, {
                params: {
                    id,
                    type,
                },
            });

            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            dispatch({
                type: 'REALTOR_INFO_COMPANY',
                user: data?.user || null,
            });

            dispatch({
                type: 'REALTOR_LEAD_UPDATE_COMPANIES',
                data: data?.data || [],
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REALTOR_LEAD_REQUEST',
                status: false,
            });

            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    cashIn: (fields) => async (dispatch) => {
        let { balance, id } = fields;

        if (!balance) {
            return message.error('Заполните поле');
        }

        try {
            await axios.post(`/v1/admin/users/realtor/cash-in`, fields);

            dispatch(users.getRealtor(id));
            message.success(`Баланс успешно пополнен`);
        } catch (err) {
            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    resetPwdUser: (password, repeat_password, user_id) => async () => {
        try {
            await axios.post(`/v1/admin/users/reset-password-user`, {
                password,
                repeat_password,
                user_id,
            });

            message.success(`Пароль успешно изменен`);
        } catch (err) {
            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    changeRoleUser: (user, newRole) => async (dispatch) => {
        try {
            await axios.post(`/v1/admin/users/change-role`, {
                role: newRole,
                user_id: user?._id,
            });

            dispatch({
                type: 'NEW_ROLE_USER',
                id: user?._id,
                role: newRole,
            });

            message.success(`Права успешно сохранены`);
        } catch (err) {
            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    block: (user) => async (dispatch) => {
        let status_ban = !user?.is_banned;

        try {
            await axios.post(`/v1/admin/users/block`, {
                status: status_ban,
                user_id: user?._id,
            });

            dispatch({
                type: 'BLOCK_USER',
                status: status_ban,
                id: user?._id,
            });

            message.success(
                `Пользователь успешно ${
                    status_ban ? 'заблокирован' : 'разблокирован'
                }`
            );
        } catch (err) {
            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }
            message.error('Ошибка. Попробуйте позже');
        }
    },
    deleteRealtor: (id) => async (dispatch) => {
        let checkDelete = window.confirm('Риелтор будет удален');

        if (checkDelete) {
            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: true,
            });

            try {
                await axios.delete(`/v1/admin/users/realtor/${id}`);

                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });

                return dispatch({
                    type: 'DELETE_REALTOR',
                    id,
                });
            } catch (err) {
                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });

                if (err?.response?.status == 400) {
                    message.error(err?.response?.data);
                    return;
                }

                message.error('Ошибка. Попробуйте позже');
            }
        }
    },
    getRealtor: (id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_USERS',
            status: true,
        });

        dispatch({
            type: 'CLEAR_REALTOR',
        });

        try {
            let { data } = await axios.get(`/v1/admin/users/realtor/${id}`);

            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: false,
            });

            return dispatch({
                type: 'SET_REALTOR',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: false,
            });

            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }

            message.error('Ошибка. Попробуйте позже');
        }
    },
    getRealtors: () => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_USERS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/users/realtors');

            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: false,
            });

            return dispatch({
                type: 'UPDATE_REALTORS',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: false,
            });

            if (err?.response?.status == 400) {
                message.error(err?.response?.data);
                return;
            }

            message.error('Ошибка. Попробуйте позже');
        }
    },
    editRealtor: (fields, history) => async (dispatch) => {
        let {
            name_company,
            phone,
            address,
            password,
            repeat_password,
            id,
            expiration,
        } = fields;

        let options = {
            company_name: name_company,
            phone,
            address,
            id,
            expiration,
        };

        if (password && repeat_password) {
            if (password != repeat_password) {
                message.error('Пароли не совпадают');
                return;
            }
            options.password = password;
        }

        if (name_company && phone && address) {
            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: true,
            });

            try {
                let { data } = await axios.post(
                    '/v1/admin/users/realtors/edit',
                    options
                );

                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });

                message.success(data);

                return history('/realtors');
            } catch (err) {
                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });

                if (err?.response?.status == 400) {
                    message.error(err?.response?.data);
                    return;
                }

                message.error('Ошибка. Попробуйте позже');
                return;
            }
        }

        message.error('Заполните все поля');
    },
    addRealtor: (fields, history) => async (dispatch) => {
        let { name_company, phone, email, address, password, repeat_password } =
            fields;

        if (
            name_company &&
            phone &&
            email &&
            address &&
            password &&
            repeat_password
        ) {
            if (password != repeat_password) {
                message.error('Пароли не совпадают');
                return;
            }

            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: true,
            });

            try {
                let { data } = await axios.post('/v1/admin/users/realtors/create', {
                    company_name: name_company,
                    phone,
                    email,
                    address,
                    password,
                });

                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });

                message.success(data);

                return history('/realtors');
            } catch (err) {
                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });

                if (err?.response?.status == 400) {
                    message.error(err?.response?.data);
                    return;
                }

                message.error('Ошибка. Попробуйте позже');
                return;
            }
        }

        message.error('Заполните все поля');
    },
    getRoles: () => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_ROLES',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/users/roles');

            dispatch({
                type: 'REQUEST_STATUS_ROLES',
                status: false,
            });

            dispatch({
                type: 'UPDATE_ROLES',
                data,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_ROLES',
                status: false,
            });

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    get:
        (page, clean = false, search_text, sort_type = []) =>
        async (dispatch) => {
            if (clean) {
                dispatch({
                    type: 'USERS_PAGE',
                    page: 1,
                });
            }

            dispatch({
                type: 'REQUEST_STATUS_USERS',
                status: true,
            });

            try {
                let { data } = await axios.get('/v1/admin/users', {
                    params: {
                        page,
                        search_text,
                        type_role: JSON.stringify(sort_type),
                    },
                });

                dispatch({
                    type: 'UPDATE_USERS',
                    data,
                    clean,
                });

                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });
            } catch (err) {
                dispatch({
                    type: 'REQUEST_STATUS_USERS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
};

export default users;
