import React, { useState, memo, useEffect } from 'react';
import { Input, Select, Tag } from 'antd';
import styles from '../../../styles/index.module.scss';
import currencyFormatter from 'currency-formatter';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const FlatList = ({ list, requestFlats, loading }) => {
    let [items_data, set_items_data] = useState(null);
    let [flat_num, set_flat_num] = useState('');
    let [flat_entrance, set_flat_entrance] = useState('');
    let [sync_err, set_sync_err] = useState(false);

    useEffect(() => {
        set_items_data(list && list.data ? list.data : null);
    }, [list]);

    const search = (word_val, typeIntrance) => {
        let newItems;

        if (word_val == '') {
            set_flat_num('');
            set_flat_entrance('');
            set_items_data(list?.data);
            syncErr(sync_err);
            return;
        }

        if (typeIntrance) {
            set_flat_num('');
            set_flat_entrance(word_val);

            newItems = list?.data.filter(
                (x) => String(x.entrance) === String(word_val)
            );
        } else {
            set_flat_entrance('');
            set_flat_num(word_val);

            newItems = list?.data.filter(
                (x) => String(x.name) === String(word_val)
            );
        }

        return set_items_data(newItems);
    };

    const syncErr = (status) => {
        set_sync_err(status);

        set_flat_num('');
        set_flat_entrance('');

        if (status === 'sync_success') {
            let newItems = list?.data.filter(
                (x) => x.sync_logovo_check == true
            );
            set_items_data(newItems);
        } else if (status === 'sync_error') {
            let newItems = list?.data.filter(
                (x) => x.sync_logovo_check == false
            );
            set_items_data(newItems);
        } else {
            set_items_data(list?.data);
        }
    };

    return (
        <div className={styles.log_list_apart_complex}>
            <div className={styles.formBlock}>
                <Input
                    size="large"
                    style={{
                        marginBottom: 0,
                    }}
                    placeholder="Введите guid жилого комплекса"
                    onKeyDown={(val) => {
                        if (val.key === 'Enter') {
                            return requestFlats(
                                'realestate',
                                val.target.value.replace(' ', '')
                            );
                        }
                    }}
                    className={styles.log_list_apart_complex_input}
                />

                <Input
                    size="large"
                    style={{
                        marginBottom: 0,
                    }}
                    placeholder="Введите guid очереди"
                    onKeyDown={(val) => {
                        if (val.key === 'Enter') {
                            return requestFlats(
                                'BLOCK',
                                val.target.value.replace(' ', '')
                            );
                        }
                    }}
                    className={styles.log_list_apart_complex_input}
                />

                <div className={styles.input_search}>
                    <Input
                        size="large"
                        style={{
                            marginRight: 10,
                        }}
                        value={flat_num}
                        placeholder="Введите номер квартиры"
                        onChange={(val) => {
                            search(val.target.value);
                        }}
                        className={styles.log_list_apart_complex_input}
                    />

                    <Input
                        size="large"
                        value={flat_entrance}
                        placeholder="Введите номер подьезда"
                        onChange={(val) => {
                            search(val.target.value, true);
                        }}
                        className={styles.log_list_apart_complex_input}
                    />

                    <section className={styles.input_search_checkbox}>
                        <Select
                            size="large"
                            defaultValue="sync_all"
                            style={{
                                width: 210,
                                marginLeft: 10,
                            }}
                            onChange={syncErr}
                        >
                            <Option value="sync_all">Все квартиры</Option>
                            <Option value="sync_success">
                                Синхронизированно
                            </Option>
                            <Option value="sync_error">
                                Не синхронизированно
                            </Option>
                        </Select>
                    </section>
                </div>
            </div>

            <div className={styles.log_count_block}>
                <Tag color="orange">
                    Найдено: {items_data ? items_data.length : 0}
                </Tag>
            </div>

            {loading ? (
                <div className={styles.loadingBlock}>
                    <LoadingOutlined />
                </div>
            ) : items_data && items_data.length ? (
                <div className={styles.listFlatsBlock}>
                    {items_data.map((item) => {
                        return (
                            <section
                                className={styles.item_apart_complex}
                                key={item?.uuid}
                            >
                                <p>
                                    <b>Номер квартиры</b> -{' '}
                                    {item?.name || 'No data'}
                                </p>
                                <p>
                                    <b>Площадь (застройщик)</b> -{' '}
                                    {item?.square || 'No data'} кв.м.
                                </p>
                                <p>
                                    <b>Площадь (logovo.kz)</b> -{' '}
                                    {typeof item?.logovo_square == 'string'
                                        ? `${item?.logovo_square}`
                                        : `${item?.logovo_square} кв.м.`}
                                </p>
                                <p>
                                    <b>Кол-во комнат (застройщик)</b> -{' '}
                                    {item?.roomCount || 'No data'}
                                </p>
                                <p>
                                    <b>Кол-во комнат (logovo.kz)</b> -{' '}
                                    {item?.logovo_rooms_count || 'No data'}
                                </p>
                                <p>
                                    <b>Цена</b> -{' '}
                                    {currencyFormatter.format(
                                        Number(item?.totalPrice || 0),
                                        {
                                            code: 'EUR',
                                            symbol: '',
                                        }
                                    ) + '₸'}
                                </p>
                                <p>
                                    <b>Цена за кв.м.</b> -{' '}
                                    {currencyFormatter.format(
                                        Number(item?.priceBySquare || 0),
                                        {
                                            code: 'EUR',
                                            symbol: '',
                                        }
                                    ) + '₸'}
                                </p>
                                <p>
                                    <b>Блок</b> - {item?.blockName || 'No data'}{' '}
                                    (guid: {item?.blockId})
                                </p>
                                <p>
                                    <b>Подъезд</b> -{' '}
                                    {item?.entrance || 'No data'}
                                </p>
                                <p>
                                    <b>Этаж</b> - {item?.floor || 'No data'}
                                </p>
                                <p>
                                    <b>Статус</b> -{' '}
                                    {item?.for_sale ? 'Свободна' : 'Продана'}
                                </p>
                                <p>
                                    <b>Синх. статус</b> -{' '}
                                    {item?.sync_logovo_check
                                        ? 'Успешно'
                                        : 'Нет'}
                                </p>
                                <span>
                                    <b>ID:</b> {item?.uuid || 'No data'}
                                </span>
                            </section>
                        );
                    })}
                </div>
            ) : (
                <h4>Информации не найдено</h4>
            )}
        </div>
    );
};

export default memo(FlatList);
