import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { api } from '../../../Services';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import styles from './styles/index.module.scss';
import { Popconfirm, Dropdown, Menu } from 'antd';

import { EllipsisOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';

const Realtors = () => {
    const dispatch = useDispatch();
    const getRealtors = useSelector((state) => state.users);

    useEffect(() => {
        dispatch(api.users.getRealtors());
    }, []);

    const deleteRealtor = (id) => {
        return dispatch(api.users.deleteRealtor(id));
    };

    const menuSettings = (item) => (
        <Menu>
            <Menu.Item key="1">
                <Link to={`/realtors/edit/${item._id}`}>Редактировать</Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Popconfirm
                    placement="left"
                    title={`Риэлтор ${item?.settings?.company_name} будет удален`}
                    onConfirm={() => deleteRealtor(item._id)}
                    onCancel={() => {}}
                    okText="Удалить"
                    cancelText="Нет"
                >
                    Удалить
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <content>
            <h1 className="title-page">Риэлторы</h1>

            <BottomScrollListener onBottom={() => {}}>
                <div className={styles.listRealtors}>
                    {getRealtors.request_status_users ? (
                        <h1 className="title-page">Подождите...</h1>
                    ) : getRealtors.realtors && getRealtors.realtors.length ? (
                        getRealtors.realtors.map((item) => {
                            return (
                                <div
                                    key={item._id}
                                    className={styles.itemRealtor}
                                >
                                    <Dropdown
                                        trigger={['click']}
                                        className={styles.Dropdown}
                                        overlay={() => menuSettings(item)}
                                    >
                                        <EllipsisOutlined
                                            onClick={(e) => e.preventDefault()}
                                            rotate={90}
                                            className={
                                                styles.userItemSettingIcon
                                            }
                                        />
                                    </Dropdown>
                                    <h6>
                                        Компания: {item.settings.company_name}
                                    </h6>
                                    <h5>{item.email}</h5>
                                </div>
                            );
                        })
                    ) : (
                        <h1 className="title-page">Риелторов не найдено</h1>
                    )}
                </div>
            </BottomScrollListener>
        </content>
    );
};

export default Realtors;
