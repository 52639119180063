const initialState = {
	list:[],
	budget_template:null,

	request_status: false,

	list_premise_budget_categories: [],
	request_premise_budget_status: false,

	budget_modal_list_categories: false,
	budget_modal_parent_category: null,
	budget_type_list_categories: '',
	toggle_calculate_cat: '',

	//calc of product
	product:null,
	modal_calc_product: false
}

const budget = (state = initialState, action) => {
	switch(action.type){

		case 'BUDGET_CALC_OF_PRODUCT' :
			return {
				...state,
				product: action.product,
				modal_calc_product: action.status
			}

		case 'TOGGLE_CALCULATE_CAT' :
			return {
				...state,
				toggle_calculate_cat:action.cat_id == state.toggle_calculate_cat ? '' : action.cat_id
			}

		case 'BUDGET_MODAL_LIST_CATEGORIES' :
			return {
				...state,
				budget_modal_list_categories: action.status,
				budget_modal_parent_category: action.parent_cat_id ? action.parent_cat_id : null,
				budget_type_list_categories: action.type_modal == state.budget_type_list_categories ? '' : action.type_modal
			}

		case 'BUDGET_TEMPLATE_UPDATE' :
			return {
				...state,
				budget_template:action.data
			}

		case 'BUDGET_TEMPLATES_LIST' :
			return {
				...state,
				list:action.data
			}

		case 'BUDGET_PREMISE_CATEGORIES_LIST' :
			return {
				...state,
				list_premise_budget_categories:action.data
			}

		case 'REQUEST_STATUS_PREMISE_STATUS' :
			return {
				...state,
				request_premise_budget_status:action.status
			}

		case 'REQUEST_STATUS_BUDGET' :
			return {
				...state,
				request_status:action.status
			}

		default :
			return state
	}
}

export default budget;