"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LAYERS_MAP = exports.PLOTS_MAP = void 0;
var types_1 = require("../types");
var dot_1 = require("../plots/dot");
var dot_density_1 = require("../plots/dot-density");
var heatmap_1 = require("../plots/heatmap");
var grid_1 = require("../plots/grid");
var hexbin_1 = require("../plots/hexbin");
var path_1 = require("../plots/path");
var flow_1 = require("../plots/flow");
var area_1 = require("../plots/area");
var choropleth_1 = require("../plots/choropleth");
var text_layer_1 = require("../layers/text-layer");
var dot_layer_1 = require("../layers/dot-layer");
var icon_layer_1 = require("../layers/icon-layer");
var column_layer_1 = require("../layers/column-layer");
var dot_density_layer_1 = require("../layers/dot-density-layer");
var grid_layer_1 = require("../layers/grid-layer");
var hexbin_layer_1 = require("../layers/hexbin-layer");
var path_layer_1 = require("../layers/path-layer");
var arc_layer_1 = require("../layers/arc-layer");
var heatmap_layer_1 = require("../layers/heatmap-layer");
var area_layer_1 = require("../layers/area-layer");
var prism_layer_1 = require("../layers/prism-layer");
/**
 * plots 的 class
 */
exports.PLOTS_MAP = (_a = {},
    _a[types_1.PlotType.Dot] = dot_1.Dot,
    _a[types_1.PlotType.DotDensity] = dot_density_1.DotDensity,
    _a[types_1.PlotType.Heatmap] = heatmap_1.Heatmap,
    _a[types_1.PlotType.Grid] = grid_1.Grid,
    _a[types_1.PlotType.Hexbin] = hexbin_1.Hexbin,
    _a[types_1.PlotType.Path] = path_1.Path,
    _a[types_1.PlotType.Flow] = flow_1.Flow,
    _a[types_1.PlotType.Area] = area_1.Area,
    _a[types_1.PlotType.Choropleth] = choropleth_1.Choropleth,
    _a);
/**
 * L7Plot 内置的 layers 的 class
 */
exports.LAYERS_MAP = (_b = {},
    _b[types_1.LayerType.TextLayer] = text_layer_1.TextLayer,
    _b[types_1.LayerType.DotLayer] = dot_layer_1.DotLayer,
    _b[types_1.LayerType.IconLayer] = icon_layer_1.IconLayer,
    _b[types_1.LayerType.DotDensity] = dot_density_layer_1.DotDensityLayer,
    _b[types_1.LayerType.ColumnLayer] = column_layer_1.ColumnLayer,
    _b[types_1.LayerType.HeatmapLayer] = heatmap_layer_1.HeatmapLayer,
    _b[types_1.LayerType.GridLayer] = grid_layer_1.GridLayer,
    _b[types_1.LayerType.HexbinLayer] = hexbin_layer_1.HexbinLayer,
    _b[types_1.LayerType.PathLayer] = path_layer_1.PathLayer,
    _b[types_1.LayerType.ArcLayer] = arc_layer_1.ArcLayer,
    _b[types_1.LayerType.AreaLayer] = area_layer_1.AreaLayer,
    _b[types_1.LayerType.PrismLayer] = prism_layer_1.PrismLayer,
    _b);
