import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import FlatPlanPreview from './preview';
import FlatPlanPhoto from './plan';
import FlatSelect from '../flat-form/select-flat';
import { useSelector } from 'react-redux';

const FlatPlanForm = ({ api, open, close }) => {
    const [modal_width, set_modal_width] = useState(65);

    const { form_flat_preview, form_flat_plan, flat_number } = useSelector(
        (state) => state?.chess
    );

    const items = [
        {
            key: 'FlatPlanPreview',
            label: `Превью квартиры`,
            children: (
                <FlatPlanPreview
                    form_flat_preview={form_flat_preview}
                    api={api}
                />
            ),
        },
        {
            key: 'FlatPlanPhoto',
            label: `Планировка квартиры (svg)`,
            children: (
                <FlatPlanPhoto form_flat_plan={form_flat_plan} api={api} />
            ),
        },
        {
            key: 'FlatSelect',
            label: `Выделить квартиру на этаже`,
            children: <FlatSelect api={api} />,
        },
    ];

    return (
        <Modal
            title={`Планировка (квартира ${flat_number})`}
            open={open}
            onCancel={() => {
                close();
                set_modal_width(55);
            }}
            footer={null}
            width={`${modal_width}%`}
            destroyOnClose={true}
            centered={true}
        >
            <Tabs
                onChange={(key) => {
                    if (key === 'FlatSelect') {
                        set_modal_width(95);
                    } else {
                        set_modal_width(65);
                    }
                }}
                defaultActiveKey="FlatPlanPreview"
                items={items}
            />
        </Modal>
    );
};

export default FlatPlanForm;
