import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { api } from '@/Services/';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import styles from './styles/index.module.scss';
import config from '@/Config';
import GetUrlImage from '@/Services/utils/urlImage';

import { Dropdown, Popconfirm, Menu } from 'antd';

const Blog = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const getPosts = useSelector((state) => state.posts);

    useEffect(() => {
        dispatch({
            type: 'UPDATE_POST_BY_ID',
            data: null,
        });

        dispatch(api.blog.get());
    }, []);

    const removePost = (id) => {
        dispatch(api.blog.delete(id));
    };

    const menuSettings = (item) => (
        <Menu>
            <Menu.Item
                key="1"
                onClick={() => history(`/blog/edit/${item?._id}`)}
            >
                Редактировать
            </Menu.Item>
            <Menu.Item key="2">
                <a
                    target="_blank"
                    href={`${config.domain}/blog/popular/${item?.slug}`}
                >
                    Посмотреть на logovo.kz
                </a>
            </Menu.Item>
            <Menu.Item key="3">
                <Popconfirm
                    title="Пост будет удален?"
                    onConfirm={() => removePost(item?._id)}
                    onCancel={() => {}}
                    okText="Удалить"
                    cancelText="Нет"
                    placement="bottomLeft"
                >
                    Удалить
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <content>
            <h1 className="title-page">Блог</h1>

            {getPosts.request_status ? (
                <h4>Загружаем информацию</h4>
            ) : getPosts.list && getPosts.list.length ? (
                <div className={`block-users-items ${styles.blogItems}`}>
                    {getPosts.list.map((item) => {
                        let initImageReg = new GetUrlImage(item?.content);
                        const imgUrl = `${config.public}400/400${initImageReg.regexLink()}`;

                        return (
                            <div
                                className={`item-page ${styles.blogItem}`}
                                key={item?._id}
                            >
                                <Dropdown
                                    trigger={['click']}
                                    className={styles.Dropdown}
                                    overlay={menuSettings(item)}
                                >
                                    <EllipsisOutlined
                                        onClick={(e) => e.preventDefault()}
                                        rotate={90}
                                        className={styles.userItemSettingIcon}
                                    />
                                </Dropdown>

                                <img src={imgUrl} />
                                <h1>
                                    {item.title.length >= 23
                                        ? item.title.substr(0, 23) + '...'
                                        : item.title}
                                </h1>
                                <div
                                    className={`bottom-page-controll-block ${styles.itemFooter}`}
                                >
                                    <span>
                                        Создано:{' '}
                                        <small>
                                            {moment(item.createdAt).format(
                                                'DD.MM.YYYY в hh:mm'
                                            )}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <h5>Информации нет</h5>
            )}
        </content>
    );
};

export default Blog;
