import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import {
    EditOutlined,
    LoadingOutlined,
    GlobalOutlined,
} from '@ant-design/icons';
import styles from './styles/index.module.scss';
import { Button, Input, Modal, Tabs, Popover } from 'antd';
import Countries from './countries';
import Editor from '../Apartments/Edit/Helpers/editor';
import ListDistrics from './helpers/list-disctricts';
import FormDistrics from './helpers/form-district';

const { TabPane } = Tabs;

const Cities = () => {
    const [is_modal_city, set_is_modal_city] = useState(false);
    const [title_city, set_title_city] = useState('');
    const [seo_title, set_seo_title] = React.useState('');
    const [seo_keywords, set_seo_keywords] = React.useState('');
    const [seo_description, set_seo_description] = React.useState('');
    const [seo_content, set_seo_content] = React.useState('');
    const [city_id, set_city_id] = React.useState('');
    const [edit_status, set_edit_status] = React.useState(false);
    const [city, set_city] = React.useState(null);

    const [modal_form_districs, set_modal_form_districs] =
        React.useState(false);
    const [modal_list_districs, set_modal_list_districs] =
        React.useState(false);

    const dispatch = useDispatch();
    const getCityState = useSelector((state) => state.cities);

    React.useEffect(() => {
        dispatch(api.cities.getCities());
    }, []);

    const createCity = () => {
        clearState();

        return dispatch(
            api.cities.addCity({
                title_city,
                seo_title,
                seo_keywords,
                seo_description,
                seo_content,
            })
        );
    };

    const editCity = () => {
        clearState();
        return dispatch(
            api.cities.changeCity(
                title_city,
                city_id,
                seo_title,
                seo_keywords,
                seo_description,
                seo_content
            )
        );
    };

    const clearState = () => {
        set_title_city('');
        set_seo_title('');
        set_seo_keywords('');
        set_seo_description('');
        set_seo_content('');
        set_city_id('');
        set_is_modal_city(false);
        set_edit_status(false);
    };

    return (
        <content className={`cities-page-block ${styles.page}`}>
            <h1>Локация</h1>

            <Tabs defaultActiveKey="logovo.kz">
                <TabPane tab="logovo.kz" key="logovo.kz">
                    <FormDistrics
                        statusModalDistrics={modal_form_districs}
                        closeFormDistrics={() => {
                            set_modal_form_districs(false);
                        }}
                    />

                    <ListDistrics
                        statusModalDistrics={modal_list_districs}
                        closeFormDistrics={() => {
                            set_city(null);
                            set_modal_list_districs(false);
                        }}
                        city={city}
                    />

                    <Modal
                        width={'75%'}
                        title={
                            edit_status
                                ? 'Форма редактирования города'
                                : 'Форма добавления города'
                        }
                        visible={is_modal_city}
                        onOk={edit_status ? editCity : createCity}
                        onCancel={clearState}
                        okText={edit_status ? 'Редактировать' : 'Добавить'}
                    >
                        <Input
                            value={title_city}
                            onChange={(value) =>
                                set_title_city(value.target.value)
                            }
                            placeholder="Название города"
                        />

                        <div className={styles.optionBlock}>
                            <h6>SEO</h6>
                            <Input
                                value={seo_keywords}
                                onChange={(value) =>
                                    set_seo_keywords(value.target.value)
                                }
                                placeholder="Keywords"
                            />
                            <Input
                                value={seo_description}
                                onChange={(value) =>
                                    set_seo_description(value.target.value)
                                }
                                placeholder="Description"
                            />
                            <Input
                                value={seo_title}
                                onChange={(value) =>
                                    set_seo_title(value.target.value)
                                }
                                placeholder="Title"
                            />
                        </div>

                        <Editor
                            dispatch={dispatch}
                            api={api}
                            seo_content={seo_content}
                            setContent={(val) => set_seo_content(val)}
                        />
                    </Modal>

                    <p>Список городов для сервиса logovo.kz</p>

                    <>
                        <Button
                            type="primary"
                            onClick={() => {
                                set_is_modal_city(true);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Добавить город
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                set_modal_form_districs(true);
                            }}
                        >
                            Добавить район
                        </Button>
                    </>

                    {getCityState.request_city_status ? (
                        <LoadingOutlined className={styles.spinLoader} spin />
                    ) : getCityState.cities ? (
                        getCityState.cities.map((item) => {
                            return (
                                <div
                                    className={styles.itemCity}
                                    key={item?._id}
                                >
                                    <h4>{item?.title}</h4>

                                    <div className={styles.itemCityRight}>
                                        <Popover
                                            placement="left"
                                            content="Редактировать"
                                            title=""
                                        >
                                            <EditOutlined
                                                onClick={() => {
                                                    set_is_modal_city(true);
                                                    set_title_city(item?.title);
                                                    set_seo_title(
                                                        item?.seo_title
                                                    );
                                                    set_seo_keywords(
                                                        item?.seo_keywords
                                                    );
                                                    set_seo_description(
                                                        item?.seo_description
                                                    );
                                                    set_seo_content(
                                                        item?.content
                                                    );
                                                    set_edit_status(true);
                                                    set_city_id(item?._id);
                                                }}
                                                className={styles.itemButton}
                                            />
                                        </Popover>
                                        <Popover
                                            placement="left"
                                            className={styles.itemButton}
                                            content="Регионы"
                                            title=""
                                        >
                                            <GlobalOutlined
                                                onClick={() => {
                                                    set_city(item);
                                                    set_modal_list_districs(
                                                        true
                                                    );
                                                }}
                                            />
                                        </Popover>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <h2>Городов нет</h2>
                    )}
                </TabPane>
                <TabPane tab="Магазин logovo.kz" key="Магазин logovo.kz">
                    <Countries />
                </TabPane>
            </Tabs>
        </content>
    );
};

export default Cities;
