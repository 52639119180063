import React from 'react';
import { Form, Typography, Input } from 'antd';
import styles from '../../../styles/index.module.scss';

const { Text } = Typography;

const BuilderFormItemSeo = () => {

    return (
        <div className={styles.formSectionGroup}>

            <Text type="secondary">Seo</Text>

            <Form.Item
                label="Title"
                name="seo_title"
            >
                <Input
                    placeholder="Title"
                />
            </Form.Item>

            <Form.Item
                label="Keywords"
                name="seo_keywords"
            >
                <Input
                    placeholder="Keywords"
                />
            </Form.Item>

            <Form.Item
                label="Description"
                name="seo_description"
            >
                <Input
                    placeholder="Description"
                />
            </Form.Item>

        </div>
    )
}

export default BuilderFormItemSeo;