import Cities from '../../../../../Containers/Admin/Cities';
import CreateCity from '../../../../../Containers/Admin/Cities/Create';

export default [
    {
        path: '/cities',
        element: <Cities />,
    },
    {
        path: '/cities/create',
        element: <CreateCity />,
    },
];
