import React from 'react';
import { Tag, Button } from 'antd';
import { useSelector } from 'react-redux';
import styles from '../../styles/index.module.scss';
import { moment, api } from '../../../../../Services';
import { useDispatch } from 'react-redux';

const UserInfo = () => {

    const dispatch = useDispatch();
    const { edit_user } = useSelector(state => state?.users);
    const userName = `${edit_user?.user?.lastname} ${edit_user?.user?.name}`;

    const mailConfirmTrigger = () => {
        dispatch(api.users.mailConfirmTrigger(edit_user?.user?._id))
    }

    return (
        <div className={styles.blockInfoUser}>
            
            <div className={styles.sectionTool}>
                <h5>ФИО</h5>
                <Tag>{ userName && userName.length > 3 ? userName : 'Anonim' }</Tag>
            </div>

            <div className={styles.sectionTool}>
                <h5>Email</h5>
                <Tag>{ edit_user?.user?.email }</Tag>
            </div>

            <div className={styles.sectionTool}>
                <h5>Роль</h5>
                <div className={styles.more}>
                    <Tag>{ edit_user?.user?.role?.title } ({ edit_user?.user?.role?.description })</Tag>

                    {
                        edit_user?.accesses && edit_user?.accesses.length ?
                            <Tag color='lime'>
                                {
                                    edit_user?.accesses.map((item)=>`${item?.title}, `)
                                }
                            </Tag>
                        :
                            <></>
                    }
                </div>
            </div>

            <div className={styles.sectionTool}>
                <h5>Заблокирован</h5>
                <Tag color={edit_user?.user?.is_banned ? 'red' : 'lime'}>{ edit_user?.user?.is_banned ? 'Да' : 'Нет'}</Tag>
            </div>

            <div className={styles.sectionTool}>
                <h5>Подтверждение email</h5>
                {
                    edit_user?.user?.mail_confirmed ?
                        <Tag color='lime'>Почта подтверждена</Tag>
                    :
                        <div>
                            <Tag color='red'>Почта не подтверждена</Tag>
                            <Button onClick={mailConfirmTrigger}>Отправить письмо на подтверждение</Button>
                        </div>
                }
                
            </div>

            <div className={styles.sectionTool}>
                <h5>Подписка</h5>
                <Tag>
                    { edit_user?.user?.subscription ? 
                    `${edit_user?.user?.subscription?.rate?.title} (${ moment().isBefore(edit_user?.user?.subscription?.expiration) ? `Подписка активна до ` + moment(edit_user?.user?.subscription?.expiration).format('DD MMMM YYYY HH:mm') : moment().isAfter(edit_user?.user?.subscription?.expiration) ? 'Подписка истекла' : 'Сегодня последний день подписки.' })` 
                    : 
                        'Подписки нет' }
                </Tag>
            </div>

        </div>
    )

}

export default UserInfo;