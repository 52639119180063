"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createThemeByStyleSheet = void 0;
var tslib_1 = require("tslib");
var TOOLTIP_CSS_CONST = tslib_1.__importStar(require("@antv/l7plot-component/dist/lib/tooltip/constants"));
var CATEGORY_LEGEND_CSS_CONST = tslib_1.__importStar(require("@antv/l7plot-component/dist/lib/legend/category/constants"));
var CONTINUE_LEGEND_CSS_CONST = tslib_1.__importStar(require("@antv/l7plot-component/dist/lib/legend/continue/constants"));
/**
 * 根据主题样式表生成主题结构
 * @param styleSheet 主题样式表
 */
function createThemeByStyleSheet(styleSheet) {
    var _a, _b, _c;
    var shapeStyles = {
        point: {
            default: {
                fill: styleSheet.pointFillColor,
                size: styleSheet.pointSize,
                stroke: styleSheet.pointBorderColor,
                lineWidth: styleSheet.pointBorder,
                fillOpacity: styleSheet.pointFillOpacity,
            },
            active: {
                fill: styleSheet.pointActiveFillColor,
            },
            selected: {
                fill: styleSheet.pointSelectedFillColor,
            },
        },
    };
    return {
        mapStyle: styleSheet.mapStyle,
        defaultColor: styleSheet.brandColor,
        subColor: styleSheet.subColor,
        semanticRed: styleSheet.paletteSemanticRed,
        semanticGreen: styleSheet.paletteSemanticGreen,
        fontFamily: styleSheet.fontFamily,
        colors10: styleSheet.paletteQualitative10,
        colors20: styleSheet.paletteQualitative20,
        sequenceColors: styleSheet.paletteSequence,
        shapes: {
            point: ['circle', 'square'],
            line: ['line'],
        },
        sizes: [1, 10],
        geometries: {
            point: {
                circle: {
                    default: {
                        style: shapeStyles.point.default,
                    },
                    active: {
                        style: shapeStyles.point.active,
                    },
                    selected: {
                        style: shapeStyles.point.selected,
                    },
                },
            },
            line: {},
            polygon: {},
        },
        components: {
            legend: {
                category: {
                    domStyles: (_a = {},
                        _a[CATEGORY_LEGEND_CSS_CONST.CONTAINER_CLASS] = {
                            visibility: 'visible',
                            zIndex: 1,
                            backgroundColor: styleSheet.legendContainerFillColor,
                            boxShadow: styleSheet.legendContainerShadow,
                            borderRadius: "".concat(styleSheet.legendContainerBorderRadius, "px"),
                            color: styleSheet.legendTextFillColor,
                            fontFamily: styleSheet.fontFamily,
                            padding: '10px',
                            lineHeight: '1',
                            fontSize: "".concat(styleSheet.legendTextFontSize, "px"),
                        },
                        _a[CATEGORY_LEGEND_CSS_CONST.TITLE_CLASS] = {
                            fontSize: '13px',
                            lineHeight: '19px',
                            marginBottom: '8px',
                        },
                        _a[CATEGORY_LEGEND_CSS_CONST.LIST_CLASS] = {
                            margin: '0px',
                            padding: '0px',
                        },
                        _a[CATEGORY_LEGEND_CSS_CONST.LIST_ITEM_CLASS] = {
                            marginBottom: '2px',
                        },
                        _a[CATEGORY_LEGEND_CSS_CONST.MARKER_CLASS] = {
                            width: '10px',
                            height: '10px',
                        },
                        _a[CATEGORY_LEGEND_CSS_CONST.VALUE_CLASS] = {
                            paddingLeft: '8px',
                        },
                        _a),
                },
                continue: {
                    domStyles: (_b = {},
                        _b[CONTINUE_LEGEND_CSS_CONST.CONTAINER_CLASS] = {
                            visibility: 'visible',
                            zIndex: 1,
                            backgroundColor: styleSheet.legendContainerFillColor,
                            boxShadow: styleSheet.legendContainerShadow,
                            borderRadius: "".concat(styleSheet.legendContainerBorderRadius, "px"),
                            color: styleSheet.legendTextFillColor,
                            fontFamily: styleSheet.fontFamily,
                            padding: '10px',
                            lineHeight: 1,
                            fontSize: "".concat(styleSheet.legendTextFontSize, "px"),
                        },
                        _b[CONTINUE_LEGEND_CSS_CONST.TITLE_CLASS] = {
                            fontSize: '13px',
                            lineHeight: '19px',
                            marginBottom: '8px',
                        },
                        _b[CONTINUE_LEGEND_CSS_CONST.RIBBON_CLASS] = {
                            display: 'flex',
                            alignItems: 'center',
                        },
                        _b[CONTINUE_LEGEND_CSS_CONST.GRADIENT_BAR_CLASS] = {
                            width: '140px',
                            height: '14px',
                            margin: '0px 5px',
                        },
                        _b[CONTINUE_LEGEND_CSS_CONST.VALUE_RANGE_CLASS] = {
                            padding: '0px',
                        },
                        _b),
                },
            },
            tooltip: {
                // tooltip dom 样式
                domStyles: (_c = {},
                    _c[TOOLTIP_CSS_CONST.CONTAINER_CLASS] = {
                        visibility: 'visible',
                        zIndex: 999,
                        transition: 'visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1), ' +
                            'left 0.4s cubic-bezier(0.23, 1, 0.32, 1), ' +
                            'top 0.4s cubic-bezier(0.23, 1, 0.32, 1)',
                        backgroundColor: styleSheet.tooltipContainerFillColor,
                        boxShadow: styleSheet.tooltipContainerShadow,
                        borderRadius: "".concat(styleSheet.tooltipContainerBorderRadius, "px"),
                        color: styleSheet.tooltipTextFillColor,
                        fontSize: "".concat(styleSheet.tooltipTextFontSize, "px"),
                        fontFamily: styleSheet.fontFamily,
                        lineHeight: styleSheet.tooltipTextLineHeight,
                    },
                    _c[TOOLTIP_CSS_CONST.TITLE_CLASS] = {
                        marginBottom: '4px',
                    },
                    _c[TOOLTIP_CSS_CONST.LIST_CLASS] = {
                        margin: '0px',
                        padding: '0px',
                    },
                    _c[TOOLTIP_CSS_CONST.LIST_ITEM_CLASS] = {
                        marginBottom: '4px',
                    },
                    _c[TOOLTIP_CSS_CONST.NAME_CLASS] = {
                        color: styleSheet.tooltipItemNameFillColor,
                    },
                    _c[TOOLTIP_CSS_CONST.VALUE_CLASS] = {
                        color: styleSheet.tooltipItemValueFillColor,
                        marginLeft: '30px',
                    },
                    _c),
            },
            label: {
                style: {
                    textAnchor: 'center',
                    textOffset: [0, 0],
                    fill: styleSheet.labelFillColor,
                    fontSize: styleSheet.labelFontSize,
                    fontFamily: styleSheet.fontFamily,
                    fillColorDark: styleSheet.labelFillColorDark,
                    fillColorLight: styleSheet.labelFillColorLight,
                },
            },
        },
    };
}
exports.createThemeByStyleSheet = createThemeByStyleSheet;
