import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../styles/index.module.scss';

function SyncLog() {
    let history = useNavigate();

    return (
        <div className="statistic-block">
            <h1>Отладчик/Интеграция</h1>

            <div className={styles.listLogsControll}>
                <section
                    onClick={() => history('/logs/sync/big')}
                    className={styles.ItemLog}
                >
                    <h4>Bi Group</h4>
                </section>
                <section
                    onClick={() => history('/logs/sync/Bitrix')}
                    className={styles.ItemLog}
                >
                    <h4>BITRIX</h4>
                </section>
                <section
                    onClick={() => history('/logs/sync/macro')}
                    className={styles.ItemLog}
                >
                    <h4>Macro</h4>
                </section>
                <section
                    onClick={() => history('/logs/sync/mwc')}
                    className={styles.ItemLog}
                >
                    <h4>MWC</h4>
                </section>
            </div>
        </div>
    );
}

export default SyncLog;
