import React, {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import Icon from '@mdi/react';
import {mdiPlus} from '@mdi/js';

import {useMutation, useQuery} from '@apollo/client';
import {credit} from '@/Services/apollo/gqls';

import styles from '../styles/index.module.scss';
import ItemBank from './Helpers/item';
import AddBankForm from './Helpers/add-bank';

const CreditBanks = () => {

	const [modal, set_modal] = useState(false);

	const {data, loading} = useQuery(credit.getBanks);
	const [addBank] = useMutation(credit.addBank, {
		refetchQueries: [{
			query: credit.getBanks
		}],
		onCompleted: () => {
			set_modal(false)
		}
	});

	const [hideBank] = useMutation(credit.hideBank, {
		refetchQueries: [{
			query: credit.getBanks
		}]
	});

	return useMemo(() => (
		<content className="cities-page-block">
			<div className={styles.topLinks}>
				<Link to="/crediting/applications">Заявки</Link>
				<Link className={styles.activeLink} to="/crediting/banks">Банки</Link>
			</div>

			<AddBankForm addBank={addBank} close={() => set_modal(!modal)} modal={modal}
						 styles={styles}/>

			<div className={styles.headBlock}>
				<h1>Банки</h1>
				<Icon onClick={() => set_modal(!modal)} className={styles.addBank}
					  path={mdiPlus} size={1}/>
			</div>

			<div className={styles.listBanks}>

				{
					loading ?
						<div className={styles.loading}>
							<h4>Загрузка...</h4>
						</div>
						:
						<div className={styles.content}>
							{
								data.getBanks && data.getBanks.length ?
									data.getBanks.map((item) => <ItemBank hideBank={hideBank}
																		  styles={styles}
																		  item={item}/>)
									:
									<h6>Информации нет</h6>
							}
						</div>
				}

			</div>

		</content>
	), [
		data,
		loading,
		modal
	])
}

export default CreditBanks;