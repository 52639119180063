
const markerTypeParse = (cat) => {

	let type_marker = '';

	if(cat.premise_type == 'PRODUCT' || cat.marker_type == 'PRODUCT'){
		type_marker = 'Продукт';
	}else if(cat.premise_type == 'POL_LAMINAT' || cat.marker_type == 'POL_LAMINAT'){
		type_marker = 'Пол ламинат';
	}else if(cat.premise_type == 'POL_KERAMIKA' || cat.marker_type == 'POL_KERAMIKA'){
		type_marker = 'Пол керамика';
	}else if(cat.premise_type == 'PLINTUS' || cat.marker_type == 'PLINTUS'){
		type_marker = 'плинтус';
	}else if(cat.premise_type == 'STENA_KRASKA' || cat.marker_type == 'STENA_KRASKA'){
		type_marker = 'Стена краска';
	}else if(cat.premise_type == 'STENA_DEKOR' || cat.marker_type == 'STENA_DEKOR'){
		type_marker = 'Стена декор';
	}else if(cat.premise_type == 'STENA_KERAM' || cat.marker_type == 'STENA_KERAM'){
		type_marker = 'Стена керамика';
	}else if(cat.premise_type == 'STENA_KERAM_DEK' || cat.marker_type == 'STENA_KERAM_DEK'){
		type_marker = 'Стена керамика декор';
	}else if(cat.premise_type == 'STENA_FARTUK' || cat.marker_type == 'STENA_FARTUK'){
		type_marker = 'Стена фартук';
	}else if(cat.premise_type == 'POTOLOK' || cat.marker_type == 'POTOLOK'){
		type_marker = 'Потолок';
	}else if(cat.premise_type == 'GALTEL' || cat.marker_type == 'GALTEL'){
		type_marker = 'Галтель';
	}else if(cat.premise_type == 'SHTORY' || cat.marker_type == 'SHTORY'){
		type_marker = 'Шторы';
	}else if(cat.premise_type == 'MEBEL'){
		type_marker = 'Мебель';
	}else if(cat.marker_type == 'POL'){
		type_marker = 'Пол';
	}else if(cat.marker_type == 'STENA'){
		type_marker = 'Стена';
	}else if(cat.marker_type == 'DVER_STEKL_DVUH'){
		type_marker = 'Дверь стеклянная двухстворчатая';
	}else if(cat.marker_type == 'DVER_STEKL_ODN'){
		type_marker = 'Дверь стеклянная одностворчатая';
	}else if(cat.marker_type == 'DVER_GLUH_DVUH'){
		type_marker = 'Дверь глухая двухстворчатая';
	}else if(cat.marker_type == 'DVER_GLUH_ODN'){
		type_marker = 'Дверь глухая одностворчатая';
	}

	return type_marker;
}

export default markerTypeParse;