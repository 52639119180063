"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Grid = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var plot_1 = require("../../core/plot");
var constants_1 = require("./constants");
var grid_layer_1 = require("../../layers/grid-layer");
var layer_group_1 = require("../../core/layer/layer-group");
var Grid = /** @class */ (function (_super) {
    tslib_1.__extends(Grid, _super);
    function Grid() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 地图类型
         */
        _this.type = plot_1.Plot.PlotType.Grid;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Grid.prototype.getDefaultOptions = function () {
        return Grid.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Grid.prototype.createLayers = function (source) {
        this.gridLayer = new grid_layer_1.GridLayer(tslib_1.__assign({ source: source }, (0, util_1.pick)(this.options, grid_layer_1.GridLayer.LayerOptionsKeys)));
        var layerGroup = new layer_group_1.LayerGroup([this.gridLayer]);
        if (this.options.label) {
            this.labelLayer = this.createLabelLayer(this.source, this.options.label, this.options);
            layerGroup.addLayer(this.labelLayer);
        }
        return layerGroup;
    };
    /**
     * 更新图层
     */
    Grid.prototype.updateLayers = function (options) {
        var heatMapLayerConfig = (0, util_1.pick)(options, grid_layer_1.GridLayer.LayerOptionsKeys);
        this.gridLayer.update(heatMapLayerConfig);
        this.updateLabelLayer(this.source, options.label, this.options, this.labelLayer);
    };
    /**
     * 默认配置项
     */
    Grid.DefaultOptions = constants_1.DEFAULT_OPTIONS;
    return Grid;
}(plot_1.Plot));
exports.Grid = Grid;
