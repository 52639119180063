const initialState = {
    yaw: 0,
};

const virtualTours = (state = initialState, action) => {
    switch (action.type) {
        case 'VT_UPDATE_MOVE_YAW':
            return {
                ...state,
                yaw: action.yaw || 0,
            };

        default:
            return state;
    }
};

export default virtualTours;
