import React, {useEffect, useState} from 'react';

import config from '../../../../Config';

import {useDispatch, useSelector} from 'react-redux';

import {api} from '../../../../Services';
import styles from '../styles/index.module.scss';

const SyncLogBig = () => {

	const [shop, set_shop] = useState('');
	const [title_product, set_title_product] = useState('');
	const [articul_product, set_articul_product] = useState('');

	const dispatch = useDispatch();
	const libraryState = useSelector(state => state.library);

	useEffect(() => {

		dispatch(api.library.shops());

	}, []);

	const searchProducts = (type, value) => {

		let params = {};

		if(type == 'SHOP') {
			params = {
				shop: value
			}
		} else {
			params = {
				shop,
				title_product: type == 'TITLE' ? value : '',
				articul_product: type != 'TITLE' ? value : '',
			}
		}

		return dispatch(api.library.searchProducts(params));
	}

	const syncShops = () => {
		if(!libraryState.sync_request) {
			return dispatch(api.library.sync());
		}
	}

	return (
		<div className="statistic-block">

			<div className={styles.list_big_controll_head}>
				<h1>Отладчик/Магазины</h1>
			</div>

			<div className={styles.params_shops}>

				<select onChange={(val) => {
					set_shop(val.target.value)
					return searchProducts('SHOP', val.target.value);
				}}>
					<option value="">Выберите магазин</option>
					{
						libraryState.shops && libraryState.shops ?
							libraryState.shops.map((item) => {
								return <option value={item._id}>{item.title}</option>
							})
							:
							<option value="">Список пуст</option>
					}
				</select>

				<section className={styles.inputs}>
					<input value={title_product} onKeyDown={(val) => {
						if(val.key === 'Enter') {
							return searchProducts('TITLE', val.target.value);
						}
					}} onChange={(val) => {
						set_articul_product('');
						set_title_product(val.target.value);
					}} placeholder="Поиск по названию товара"/>
					<input onKeyDown={(val) => {
						if(val.key === 'Enter') {
							return searchProducts('ARTICUL', val.target.value);
						}
					}} onChange={(val) => {
						set_title_product('');
						set_articul_product(val.target.value);
					}} value={articul_product} placeholder="Поиск по артикулу товара"/>
					<input type="submit" onClick={() => syncShops()}
						   value={libraryState.sync_request ? "Подождите..." : "Синхронизировать"}/>
				</section>

			</div>

			{
				libraryState.search_products_request ?
					<h5>Загружаем...</h5>
					: libraryState.list_search_products && libraryState.list_search_products.length ?

						<div className="list_products_block">
							{
								libraryState.list_search_products.map((item) => {
									return (
										<div key={item._id} className="item_product">
											{
												item.photo ? (
														<div className="item_product_photo" style={{
															background: `url(${config.public}200/200/${item.photo.url}) center / contain no-repeat`
														}}></div>
													)
													: (
														<div className="item_product_photo"><h5>Нет
															картинки</h5></div>
													)
											}
											<p>Название: {item.title ? item.title : '-'}</p>
											<p>Магазин: {item.shop ? item.shop.title : '-'}</p>
											<p>Цена: {item.price} тг.</p>
											<p>Артикул: {item.article_number}</p>
											<p>Синхронизированно: {item.sync ? 'да' : 'нет'}</p>
										</div>
									)
								})
							}
						</div>

						:
						<h5>Ничего не найдено</h5>
			}

		</div>
	)
}

export default SyncLogBig;