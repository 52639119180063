import React, { createRef } from 'react';
import { Modal, Input, Form, Button } from 'antd';
import styles from '../../styles/vt.module.scss';

const CreateVirtualTour = ({ visible, close, createVirtualTour, loading }) => {
    const formRef = createRef();

    const createVirtualTourEvent = (fields) => {
        createVirtualTour({
            variables: {
                fields,
            },
        });
    };

    return (
        <>
            <Modal
                onCancel={close}
                title="Создание виртуального тура"
                open={visible}
                width="45%"
                footer={null}
            >
                <Form
                    name="basic"
                    onFinish={createVirtualTourEvent}
                    autoComplete="off"
                    ref={formRef}
                >
                    <Form.Item
                        label=""
                        name="title"
                        className={styles.formItem}
                        rules={[
                            {
                                required: true,
                                message: 'Заполните название виртуального тура',
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="Наименование виртуального тура"
                        />
                    </Form.Item>
                    <Form.Item
                        label=""
                        name="secondary_title"
                        className={styles.formItem}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Заполните вторичное название виртуального тура',
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="Вторичное наименование виртуального тура"
                        />
                    </Form.Item>
                    <Form.Item className={styles.formItem}>
                        {loading ? (
                            <Button>Создаем, подождите...</Button>
                        ) : (
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                            >
                                Создать тур и перейти к редактированию
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateVirtualTour;
