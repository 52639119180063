import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { api } from '../../../../Services';

import styles from '../styles/index.module.scss';

import { useDispatch } from 'react-redux';

import {
    Input,
    Tooltip,
    Button
} from 'antd';

const WidgetsCreate = () => {
    const history = useNavigate();

    const [plans, set_plans] = useState([]);
    const [title, set_title] = useState('');
    const [description, set_description] = useState('');
    const [colums, set_colums] = useState('2');
    const [type, set_type] = useState('DEFAULT');
    const [group_title, set_group_title] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'UPDATE_WIDGET_INFO',
            data: null,
        });

        dispatch({
            type: 'APARTMENT_PREVIEW_PHOTO',
            photo: null,
        });

        dispatch({
            type: 'SEARCH_PLANS',
            data: null,
        });

        dispatch(api.apartments.getApartmentsComplex());
    }, []);

    const createWidget = () => {
        let widgetOptions = {};

        return dispatch(
            api.widgets.createWidget(
                plans,
                title,
                description,
                colums,
                type,
                history,
                widgetOptions
            )
        );
    };

    const createGroupWidgets = () => {
        return dispatch(
            api.widgets.createWidget([], group_title, '', 0, type, history)
        );
    };

    return (
        <div className={`statistic-block ${styles.pageWrapper}`}>
            <h1>Создание виджета</h1>

            <section className="input-section-form">
                <FormControl className="select-option-style">
                    <InputLabel
                        style={{ fontSize: 17 }}
                        htmlFor="age-native-simple"
                    >
                        Выберите тип виджета*
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        fullWidth
                        onChange={(e) => {
                            set_type(e.target.value);
                            set_plans([]);
                            dispatch({
                                type: 'APARTMENT_PREVIEW_PHOTO',
                                photo: null,
                            });
                        }}
                        style={{ fontSize: 17 }}
                    >
                        <MenuItem style={{ fontSize: 17 }} value="DEFAULT">
                            Стандартный
                        </MenuItem>
                        <MenuItem style={{ fontSize: 17 }} value="3DTOURS">
                            Группа 3D туров
                        </MenuItem>
                    </Select>
                </FormControl>
            </section>

            <div>
                {type == '3DTOURS' ? (
                    <div className={styles.groupForm}>
                        <h3>Создание группы виджетов</h3>
                        <Input
                            placeholder="Введите название группы"
                            value={group_title}
                            size='large'
                            onChange={(val) =>
                                set_group_title(val.target.value) 
                            }
                        />
                        <Button style={{
                            marginTop: 5
                        }} type="primary" onClick={createGroupWidgets}>
                            Создать группу и перейти к редактированию
                        </Button>
                    </div>
                ) : (
                    <div>

                        <div className={styles.inputs_block}>
                            <Tooltip
                                zIndex={99999999}
                                trigger={['focus']}
                                title="Название виджета"
                                placement="topLeft"
                            >
                                <Input
                                    size="large"
                                    type="text"
                                    value={title}
                                    onChange={(val) =>
                                        set_title(val.target.value)
                                    }
                                    placeholder="Название виджета"
                                />
                            </Tooltip>
                            <Tooltip
                                zIndex={99999999}
                                trigger={['focus']}
                                title="Описание виджета"
                                placement="topLeft"
                            >
                                <Input
                                    size="large"
                                    type="text"
                                    value={description}
                                    onChange={(val) =>
                                        set_description(val.target.value)
                                    }
                                    placeholder="Описание виджета"
                                />
                            </Tooltip>
                            <Tooltip
                                zIndex={99999999}
                                trigger={['focus']}
                                title="Количество колонок"
                                placement="topLeft"
                            >
                                <Input
                                    size="large"
                                    type="number"
                                    value={colums}
                                    onChange={(val) =>
                                        set_colums(val.target.value)
                                    }
                                    placeholder="Количество колонок"
                                />
                            </Tooltip>
                        </div>

                        <Button type="primary" onClick={createWidget}>Создать</Button>

                    </div>
                )}
            </div>
        </div>
    );
};

export default WidgetsCreate;
