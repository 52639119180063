import axios from '../axios';
import config from '../../../Config';
import { message } from 'antd';

const apartments = {
    getVTByRealestate: (realestate_id) => async (dispatch) => {
        try {
            let { data } = await axios.get(
                '/v1/admin/apartments-complex/virtual-tours/list',
                {
                    params: {
                        realestate_id,
                    },
                }
            );

            dispatch({
                type: 'VERTUAL_TOURS_BY_REALESTATE',
                data,
            });

            return;
        } catch (err) {
            return message.error(
                err?.response?.data ||
                    'Ошибка. Попробуйте позже или обратитесь к системному администратору'
            );
        }
    },
    BindVT: (vt_id, id, type, type_collection) => async (dispatch) => {
        try {
            let { data } = await axios.post(
                '/v1/admin/apartments-complex/virtual-tours/bind',
                {
                    vt_id,
                    id,
                    type,
                    type_collection,
                }
            );

            if (type_collection === 'realestate') {
                dispatch(apartments.getVTByRealestate(id));
            } else if (type_collection === 'apartment_template') {
                dispatch({
                    type: 'VERTUAL_TOUR_UPDATE_APARTMENT',
                    vt_id,
                });
            }

            dispatch({
                type: 'VERTUAL_TOUR_UPDATE_SEARCH_LIST',
                vt_id,
            });

            message.success(data);

            return;
        } catch (err) {
            return message.error(
                err?.response?.data ||
                    'Ошибка. Попробуйте позже или обратитесь к системному администратору'
            );
        }
    },
    searchVirtialTours: (title) => async (dispatch) => {
        try {
            dispatch({
                type: 'REQUEST_VIRTUAL_TOURS',
                status: true,
            });

            let { data } = await axios.get(
                '/v1/admin/apartments-complex/virtual-tours',
                {
                    params: {
                        title,
                    },
                }
            );

            dispatch({
                type: 'UPDATE_VIRTUAL_TOURS',
                data,
            });

            dispatch({
                type: 'REQUEST_VIRTUAL_TOURS',
                status: false,
            });

            return;
        } catch (err) {
            return message.error(
                err?.response?.data ||
                    'Ошибка. Попробуйте позже или обратитесь к системному администратору'
            );
        }
    },
    chessForm:
        (form, options = {}) =>
        async (dispatch) => {
            try {
                let { data } = await axios.post(
                    '/v1/admin/apartments/chess/form',
                    {
                        ...form,
                        ...options,
                    }
                );

                dispatch({
                    type: 'UPDATE_CHESS',
                    data: {
                        ...form,
                        ...options,
                    },
                });

                return message.success(data);
            } catch (err) {
                return message.error(
                    err?.response?.data ||
                        'Ошибка. Попробуйте позже или обратитесь к системному администратору'
                );
            }
        },
    makeMainRealestate: (realestate_id, object_id) => async (dispatch) => {
        try {
            let { data } = await axios.post('/v1/admin/realestate/objects/main', {
                realestate_id,
                object_id,
            });

            dispatch({
                type: 'UPDATE_MAIN_REALESTATE_BY_OBJECT',
                realestate_id,
                object_id,
            });

            return message.success(data);
        } catch (err) {
            return message.error(
                err?.response?.data ||
                    'Ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    editObject:
        (title, realestate = [], id) =>
        async (dispatch) => {
            if (!title) {
                return message.warning('Заполните наименование объекта');
            }

            if (!realestate || realestate.length == 0) {
                return message.warning('Выберите жк');
            }

            try {
                await axios.post('/v1/admin/realestate/objects/edit', {
                    title,
                    realestate: JSON.stringify(realestate),
                    id,
                });

                dispatch({
                    type: 'CHANGE_STATUS_OBJECT',
                    payload: 'EDIT',
                });

                return dispatch(apartments.getObjects());
            } catch (err) {
                return message.error(
                    err?.response?.data ||
                        'Ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    deleteObject: (id) => async (dispatch) => {
        try {
            let { data } = await axios.delete(`/v1/admin/realestate/object/${id}`);
            message.success(data);

            return dispatch(apartments.getObjects());
        } catch (err) {
            return message.error(
                err?.response?.data ||
                    'Ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    createObject:
        (title, realestate = [], navigate = null, fromRealestate) =>
        async (dispatch) => {
            if (!title) {
                return message.warning('Заполните наименование объекта');
            }
            if (!fromRealestate) {
                if (!realestate || realestate.length == 0) {
                    return message.warning('Выберите жк');
                }
            }

            try {
                await axios.post('/v1/admin/realestate/objects/create', {
                    title,
                    realestate: JSON.stringify(realestate),
                });

                dispatch({
                    type: 'CHANGE_STATUS_OBJECT',
                    payload: 'CREATED',
                });

                message.success('Жк успешно создан');

                if (fromRealestate) {
                    return navigate.push('/apartments/objects');
                }
                return dispatch(apartments.getObjects());
            } catch (err) {
                return message.error(
                    err?.response?.data ||
                        'Ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    getObjects:
        (page = 1, text_search) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: true,
                });

                let { data } = await axios.get('/v1/admin/realestate/objects', {
                    params: {
                        page,
                        text_search,
                    },
                });

                dispatch({
                    type: 'UPDATE_OBJECTS_PAGES',
                    total: data?.total,
                });

                dispatch({
                    type: 'UPDATE_OBJECTS',
                    data: data?.data || [],
                });

                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: false,
                });

                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: false,
                });

                return message.error(
                    err?.response?.data ||
                        'Ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    searchRealestate:
        (builder_id, text_search, page, cities, type_content) =>
        async (dispatch) => {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: true,
            });

            try {
                let { data } = await axios.get('/v1/admin/realestate/search', {
                    params: {
                        page,
                        text_search,
                        builder_id: JSON.stringify(builder_id),
                        cities: JSON.stringify(cities),
                        type_content: JSON.stringify(type_content),
                    },
                });

                dispatch({
                    type: 'FIRST_REQUEST_REALESTATE',
                    status: false,
                });

                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: false,
                });

                if (data.length == 0) {
                    return message.success('Жк не найдено');
                }

                dispatch({
                    type: 'UPDATE_APARTMENTS',
                    data: data.length ? data : [],
                });
            } catch (err) {
                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    getRealestateForHomePage: async (limit = 50) => {
        let { data } = await axios.post(`/filter`, {
            search_type: 'COMPLEX',
            limit,
        });

        return data || [];
    },
    updateSortApartmentsComplex:
        (
            drag_complex,
            select_complex,
            drag_complex_sort_number,
            select_complex_sort_number
        ) =>
        async (dispatch) => {
            try {
                await axios.post(`/v1/admin/apartments-complex/update-sort`, {
                    drag_complex,
                    select_complex,
                });

                dispatch({
                    type: 'UPDATE_SORT_APARTMENTS',
                    drag_complex,
                    select_complex,
                    drag_complex_sort_number,
                    select_complex_sort_number,
                });

                return message.success('Информация сохранена');
            } catch (err) {
                if (err.response.status === 400) {
                    return message.warning(err.response.data);
                }

                return message.error('Ошибка. попробуйте позже');
            }
        },
    searchPlans: (apart_complex, nubmer_flat) => async (dispatch) => {
        if (apart_complex && nubmer_flat) {
            try {
                let { data } = await axios.get(`/v1/admin/widgets/get-plans`, {
                    params: {
                        apart_complex,
                        nubmer_flat,
                    },
                });

                dispatch({
                    type: 'SEARCH_PLANS',
                    data,
                });

                return;
            } catch (err) {
                return message.error('Ошибка. попробуйте позже');
            }
        }

        message.error('Заполните поле');
    },
    updateChessFlatPrice:
        (id, price, blockIndex, floorIndex, flatIndex, templateId) =>
        async () => {
            try {
                if (price != '') {
                    await axios.post(`/v1/admin/apartments/chess/price`, {
                        id,
                        price,
                        templateId,
                    });

                    message.success('Цена обновлена');

                    return;
                }

                message.error('Заполните поле');
            } catch (err) {
                return message.error('Ошибка. попробуйте позже');
            }
        },
    updateChessFlatTitle:
        (id, title, blockIndex, floorIndex, flatIndex) => async (dispatch) => {
            try {
                if (title != '') {
                    await axios.post(`/v1/admin/apartments/chess/title`, {
                        id,
                        title,
                    });

                    dispatch({
                        type: 'UPDATE_TITLE_FLAT_CHESS',
                        title,
                        blockIndex,
                        floorIndex,
                        flatIndex,
                    });

                    return;
                }

                message.error('Заполните поле');
            } catch (err) {
                return message.error('Ошибка. попробуйте позже');
            }
        },
    updateChessFlatSold:
        (id, status, blockIndex, floorIndex, flatIndex) => async (dispatch) => {
            try {
                await axios.post(`/v1/admin/apartments/chess/sold`, {
                    id,
                    status,
                });

                dispatch({
                    type: 'UPDATE_SOLD_FLAT_CHESS',
                    status,
                    blockIndex,
                    floorIndex,
                    flatIndex,
                });
            } catch (err) {
                return message.error('Ошибка. попробуйте позже');
            }
        },
    getApartmentChessById: (id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_APARTMENT_COMPLEX_CHESS',
            status: true,
        });

        dispatch({
            type: 'UPDATE_APARTMENT_COMPLEX_CHESS',
            data: null,
        });

        try {
            let { data } = await axios.get(`/v1/admin/apartments/chess/${id}`);

            dispatch({
                type: 'UPDATE_APARTMENT_COMPLEX_CHESS',
                data: data.blocks && data.blocks.length ? data : null,
            });

            dispatch({
                type: 'REQUEST_APARTMENT_COMPLEX_CHESS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENT_COMPLEX_CHESS',
                status: false,
            });

            if (err.response.status == 400) {
                return;
            }

            return message.error('Ошибка. попробуйте позже');
        }
    },
    updatePricesApartmentComplexAUTO: (id) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_APARTMENT_COMPLEX_PRICES',
            data: null,
        });

        try {
            let { data } = await axios.post(
                `/v1/admin/apartments-complex/${id}/prices/update`,
                {
                    type_request: 'AUTO',
                }
            );

            dispatch({
                type: 'UPDATE_APARTMENT_COMPLEX_PRICES',
                data: data.length ? data : null,
            });
        } catch (err) {
            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
    getPricesApartmentComplex: (id) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_APARTMENT_COMPLEX_PRICES',
            data: null,
        });

        dispatch({
            type: 'REQUEST_APARTMENT_COMPLEX_PRICES',
            status: true,
        });

        try {
            let { data } = await axios.post(
                `/v1/admin/apartments-complex/${id}/prices`
            );

            dispatch({
                type: 'UPDATE_APARTMENT_COMPLEX_PRICES',
                data: data.length ? data[0] : null,
            });

            dispatch({
                type: 'REQUEST_APARTMENT_COMPLEX_PRICES',
                status: false,
            });
        } catch (err) {
            message.error('Упс, ошибка. Проверьте Ваши права администратора');

            dispatch({
                type: 'REQUEST_APARTMENT_COMPLEX_PRICES',
                status: false,
            });
        }
    },
    updatePricesApartmentComplex: (fields) => async (dispatch) => {
        let { id } = fields;

        dispatch({
            type: 'UPDATE_APARTMENT_COMPLEX_PRICES',
            data: null,
        });

        dispatch({
            type: 'REQUEST_APARTMENT_COMPLEX_PRICES',
            status: true,
        });

        try {
            let { data } = await axios.post(
                `/v1/admin/apartments-complex/${id}/prices/update`,
                fields
            );

            dispatch({
                type: 'UPDATE_APARTMENT_COMPLEX_PRICES',
                data: data.length ? data : null,
            });

            dispatch({
                type: 'REQUEST_APARTMENT_COMPLEX_PRICES',
                status: false,
            });

            message.success('Информация и цены успешно обновлены', 'Сообщение');
        } catch (err) {
            message.error('Упс, ошибка. Проверьте Ваши права администратора');

            dispatch({
                type: 'REQUEST_APARTMENT_COMPLEX_PRICES',
                status: false,
            });
        }
    },
    soldApartment: (status, id) => async (dispatch) => {
        try {
            await axios.post('/v1/admin/apartments-complex/update/sold', {
                id,
                status: status ? true : false,
            });

            dispatch({
                type: 'UPDATE_APARTMENT_ONE_SOLD',
                id,
                status: status ? true : false,
            });

            message.success('Информация успешно обновлена');
        } catch (err) {
            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
    getApartmentsComplexForRealtors: (type) => async (dispatch) => {
        try {
            let { data } = await axios.get('/v1/admin/apartments-complex', {
                params: {
                    page: null,
                    all: true,
                    type,
                },
            });

            dispatch({
                type: 'REAL_ESTATE_FOR_REALTORS',
                data: data.length ? data : [],
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
    getApartmentsComplex:
        (page, all = true, arrRealEstate = []) =>
        async (dispatch) => {
            if (arrRealEstate.length > 10 && page == 1) {
                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: false,
                });

                dispatch({
                    type: 'APART_COMPLEX_REQ_NO_DATA',
                    status: true,
                });

                return;
            }

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: true,
            });

            dispatch({
                type: 'APART_COMPLEX_PAGE',
                page,
            });

            try {
                let { data } = await axios.get('/v1/admin/apartments-complex', {
                    params: {
                        page,
                        all,
                    },
                });

                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: false,
                });

                if (!data.length) {
                    dispatch({
                        type: 'APART_COMPLEX_REQ_NO_DATA',
                        status: true,
                    });

                    return;
                }

                dispatch({
                    type: 'APART_COMPLEX_REQ_NO_DATA',
                    status: false,
                });

                dispatch({
                    type: 'UPDATE_APARTMENTS',
                    data: data.length ? data : [],
                });
            } catch (err) {
                dispatch({
                    type: 'REQUEST_APARTMENTS_STATUS',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    editBlockApartment:
        (
            title,
            floors_count,
            id,
            seoTitle,
            seoKeywords,
            seoDescription,
            sync_id,
            sync_entrance,
            redirect_url
        ) =>
        async () => {
            if (title && floors_count) {
                try {
                    let { data } = await axios.post(
                        `/v1/admin/apartments/block/update-information`,
                        {
                            title,
                            floors_count,
                            id,
                            seoTitle,
                            seoKeywords,
                            seoDescription,
                            sync_id,
                            sync_entrance,
                            redirect_url,
                        }
                    );

                    message.success(data);
                    return;
                } catch (err) {
                    message.error(
                        'Ошибка. Проверьте Ваши права администратора'
                    );
                    return;
                }
            }

            return message.error('Заполните поля');
        },
    saveEditBlockareaPoint:
        (idBlockEdit, editAreaPointBlock, editAreaPointBlockSTR) =>
        async () => {
            try {
                let { data } = await axios.post(
                    `/v1/admin/apartments/block/update-area-points`,
                    {
                        id: idBlockEdit,
                        areaPoints: editAreaPointBlock,
                        areaPointString: editAreaPointBlockSTR,
                    }
                );

                message.success(data);
            } catch (err) {
                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    saveDotForPlan: (photo, x, y, title_room_id) => async (dispatch) => {
        try {
            let { data } = await axios.post(
                `/v1/admin/apartments/360/photo/save-dot-fo-plan`,
                {
                    id: photo._id,
                    y,
                    x,
                    title_room_id,
                }
            );

            message.success(data);
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
    addApartment: (fields, history) => async (dispatch) => {
        let { documents, type_realestate } = fields;

        if (documents && documents.length) {
            fields.documents = documents.map((doc) => doc.id);
        } else {
            delete fields.documents;
        }

        dispatch({
            type: 'REQUEST_APARTMENTS_STATUS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/apartments-complex', fields);

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            dispatch({
                type: 'PHOTO_APARTMENT_COMPLEX',
                photo: null,
            });

            if (type_realestate === 'realestate') {
                message.success('Жк успешно создан');
                return history('/apartments/objects');
            } else {
                message.success('Очередь успешно создана');
                return history('/apartments');
            }
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    editApartment: (fields, history) => async (dispatch) => {
        let { photo, id, documents } = fields;

        if (documents && documents.length) {
            fields.documents = documents.map((doc) => doc?.id || doc?._id);
        } else {
            delete fields.documents;
        }

        dispatch({
            type: 'REQUEST_APARTMENTS_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.post(
                `/v1/admin/apartments-complex/edit`,
                fields
            );

            dispatch({
                type: 'UPDATE_PREVIEW_PHOTO_REALESTATE',
                photo,
                id,
            });

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            dispatch({
                type: 'PHOTO_APARTMENT_COMPLEX',
                photo: null,
            });

            message.success(data);

            return history('/apartments');
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    deleteApartment: (id) => async (dispatch) => {
        try {
            await axios.delete(`/v1/admin/apartments-complex/${id}`);

            message.success('ЖК успешно удалено');

            return dispatch({
                type: 'REMOVE_REALESTATE',
                id,
            });
        } catch (err) {
            message.error('Ошибка. Проверьте права администратора');
            return;
        }
    },
    getApartmentById: (id) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_APARTMENT_FOR_EDIT',
            data: null,
        });

        dispatch({
            type: 'REQUEST_APARTMENTS_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/apartments-complex/${id}`);

            dispatch({
                type: 'UPDATE_APARTMENT_FOR_EDIT',
                data: data ? data : null,
            });

            dispatch({
                type: 'PHOTO_APARTMENT_COMPLEX',
                photo: {
                    full_url: `${config.public}${data.photo.url}`,
                    id: data.photo._id,
                    url: data.photo.url,
                },
            });

            if (data.image_complex_with_all_blocks) {
                dispatch({
                    type: 'PHOTO_COMPLEX_WITH_BLOCKS',
                    photo: {
                        full_url: `${config.public}${data.image_complex_with_all_blocks.url}`,
                        id: data.image_complex_with_all_blocks._id,
                        url: data.image_complex_with_all_blocks.url,
                    },
                });
            } else {
                dispatch({
                    type: 'PHOTO_COMPLEX_WITH_BLOCKS',
                    photo: null,
                });
            }

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    get360ApartmentById: (id, history) => async (dispatch) => {
        dispatch({
            type: 'CLEAR_TEMPLATE_FOR_360',
        });

        dispatch({
            type: 'UPDATE_APARTMENTS_360',
            data: null,
        });

        dispatch({
            type: 'REQUEST_APARTMENTS_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/apartments/360/${id}`);

            if (!data.template) {
                return history('/apartments/flats/templates');
            }

            dispatch({
                type: 'UPDATE_APARTMENTS_360',
                data: data && data.data ? data.data : null,
            });

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            dispatch({
                type: 'UPDATE_TEMPLATE_FOR_360',
                template: data && data.template ? data.template : null,
            });
        } catch (err) {
            console.log(1, err);

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    addPhotos360: (photo_360, photo_360_preview, id) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_APARTMENTS_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.post(`/v1/admin/apartments/360/add`, {
                photo_360,
                photo_360_preview,
                id,
            });

            dispatch({
                type: 'UPDATE_APARTMENTS_360',
                data,
            });

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            //clear files
            dispatch({
                type: 'PHOTO_APARTMENT_360',
                photo: null,
            });
            dispatch({
                type: 'PHOTO_APARTMENT_360_PREVIEW',
                photo: null,
            });
            //end clear files

            dispatch({
                type: 'UPDATE_APARTMENTS_360',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });
        }
    },
    remove360Photo: (id, apartmentId) => async (dispatch) => {
        try {
            let { data } = await axios.post('/v1/admin/apartments/360/remove', {
                id,
                apartmentId,
            });

            dispatch({
                type: 'UPDATE_APARTMENTS_360',
                data,
            });

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });
        }
    },
    addPoint: (data_vr, id, apartmentId) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_APARTMENTS_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.post('/v1/admin/apartments/360/add-point', {
                data_vr,
                id,
                apartmentId,
            });

            dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });

            dispatch({
                type: 'UPDATE_APARTMENTS_360',
                data,
            });
        } catch (err) {
            return dispatch({
                type: 'REQUEST_APARTMENTS_STATUS',
                status: false,
            });
        }
    },
};

export default apartments;
