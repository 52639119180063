import { gql } from '@apollo/client';

const subErrors = gql`
    subscription {
        eventError {
            title
            type
            _id
        }
    }
`;

const errorDelete = gql`
    mutation ($id: String) {
        errorDelete(id: $id)
    }
`;

export default {
    subErrors,
    errorDelete,
};
