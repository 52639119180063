import React, { useState } from 'react';

import { mdiArrowDown } from '@mdi/js';

import Icon from '@mdi/react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Button, Checkbox } from 'antd';

const ItemRedirect = ({
    item,
    styles,
    index,
    dispatch,
    api,
    check_status,
    selectItem,
}) => {
    let [edit_status, set_edit_status] = useState(false);
    let [redirect_url, set_redirect_url] = useState('');

    const editRedirect = () => {
        dispatch(api.redirect.editRedirect(item._id, redirect_url, index));
        set_edit_status(false);
    };

    const deleteRedirect = (id) => {
        dispatch(api.redirect.deleteRedirect(id));
    };

    return (
        <div
            className={`${styles.noSelectRedirectEdit} ${
                edit_status && styles.selectRedirectEdit
            }`}
        >
            <div className={`${styles.item}`}>
                <span className={styles.count}>{`${index + 1}`}</span>
                <div className={styles.itemLeft}>
                    <h5>{item.url}</h5>
                    <Icon
                        className="sidebar-icon-main"
                        path={mdiArrowDown}
                        size={1}
                    />
                    <h5>{item.redirect_url}</h5>
                </div>
                <div className={styles.itemRight}>
                    {!edit_status && (
                        <div className={styles.buttons}>
                            <Button size="small">
                                <Popconfirm
                                    placement="bottom"
                                    title="Ссылка будет удалена?"
                                    onConfirm={() => deleteRedirect(item._id)}
                                    okText="Удалить"
                                    cancelText="нет"
                                >
                                    <DeleteOutlined
                                        className={styles.itemRightIcon}
                                    />
                                </Popconfirm>
                            </Button>
                            <Button
                                size="small"
                                onClick={() => set_edit_status(true)}
                            >
                                <EditOutlined
                                    className={styles.itemRightIconEdit}
                                />
                            </Button>
                            {check_status && (
                                <Button size="small">
                                    <Checkbox
                                        onChange={(e) =>
                                            selectItem(
                                                e.target.checked,
                                                item?._id
                                            )
                                        }
                                    />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {edit_status && (
                <div className={styles.formChangeUrl}>
                    <h5>Актуальная ссылка</h5>
                    <input
                        type="text"
                        value={redirect_url}
                        onChange={(val) => set_redirect_url(val.target.value)}
                    />
                    <input
                        type="submit"
                        value="Сохранить"
                        onClick={editRedirect}
                    />
                </div>
            )}
        </div>
    );
};

export default ItemRedirect;
