import React from 'react';
import { Result, Image } from 'antd';
import config from '@/Config';
import styles from '../../styles/custom-chess.module.scss';
const FlatPlanPhoto = ({ form_flat_plan }) => {
    return (
        <div>
            {form_flat_plan?.url ? (
                <Image
                    className={styles.image}
                    src={`${config.public}${form_flat_plan?.url}`}
                />
            ) : (
                <Result
                    status="warning"
                    title="Планировка квартиры не загружена"
                    extra={
                        <Button type="primary" key="console">
                            загрузить
                        </Button>
                    }
                />
            )}
        </div>
    );
};

export default FlatPlanPhoto;
