import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api, moment } from '../../../../Services';
import styles from '../styles/index.module.scss';
import config from '../../../../Config';
import { Link, useParams } from 'react-router-dom';
import selectLibrary from '../../../../Services/Store/reducers/library/selector';
import { LoadingOutlined } from '@ant-design/icons';

import { List, Typography, Image, Tag } from 'antd';

const ChecksOfBonusCard = () => {
    const params = useParams();

    const dispatch = useDispatch();
    const stateLibrary = useSelector(selectLibrary);

    const [checks, set_checks] = useState([]);

    useEffect(() => {
        dispatch(api.library.getBonusCardChecks(params?.id));
    }, []);

    useEffect(() => {
        set_checks(stateLibrary.bonus_card_checks);
    }, [stateLibrary.bonus_card_checks]);

    return (
        <div className={styles.mainBlock}>
            <div className={styles.header}>
                <h2 className="title-page">Чеки</h2>
                <div className={styles.headerRight}>
                    <Link to="/library/bonus-cards">Бонусные карты</Link>
                    <Link to="/library/bonus-cards/types">Типы карт</Link>
                </div>
            </div>

            <div className={styles.container}>
                {stateLibrary.status_cards_request ? (
                    <div className={styles.blockAwait}>
                        <LoadingOutlined className={styles.blockAwaitIcon} />
                    </div>
                ) : (
                    <List
                        bordered={true}
                        size="small"
                        header={<div>Список чеков</div>}
                        dataSource={checks}
                        renderItem={(item: any) => (
                            <List.Item className={styles.itemCheck}>
                                <Image
                                    width={160}
                                    src={`${config.public}${item?.file?.url}`}
                                />
                                <div className={styles.itemCheckLeft}>
                                    <Typography.Text
                                        className={styles.textmargin}
                                    >
                                        {item?.shop?.title}
                                    </Typography.Text>

                                    <Typography.Text
                                        className={styles.textmargin}
                                    >
                                        Номер карты:{' '}
                                        <Tag>{item?.bonus_card?.number}</Tag>
                                    </Typography.Text>

                                    <Typography.Text
                                        className={styles.textmargin}
                                    >
                                        Тип карты:{' '}
                                        <Tag>
                                            {item?.bonus_card?.type_card?.name}
                                        </Tag>
                                    </Typography.Text>

                                    <Typography.Text
                                        className={styles.textmargin}
                                    >
                                        Загружено:{' '}
                                        <Tag color="lime">
                                            {moment(item?.createdAt).format(
                                                'DD MMMM YYYY в hh:mm'
                                            )}
                                        </Tag>
                                    </Typography.Text>
                                </div>
                            </List.Item>
                        )}
                    />
                )}
            </div>
        </div>
    );
};

export default ChecksOfBonusCard;
