import React, { useState } from 'react';
import ApartmentСomplex from './Helpers/ApartmentСomplex';
import styles from '../styles/index.module.scss';
import { Steps, Button, message } from 'antd';

const { Step } = Steps;

const CreateApartment = () => {
    const [step, set_step] = useState(0);
    const [type, set_type] = useState('');
    const [type_content, set_type_content] = useState('');
    const [object_title, set_object_title] = useState('');

    const prev = () => {
        if (step > 0) {
            if (step === 1) {
                set_type('');
            }
            set_step(step - 1);
        }
    };

    return (
        <content className={`page-create-apartment ${styles.pageForm}`}>
            <h1>Добавление нового жк/очереди</h1>

            <div style={{ marginTop: 20 }} className={styles.stepsBlock}>
                <Steps current={step}>
                    <Step
                        title="Жк или очередь"
                        description="Выберите жк или очередь"
                    />
                    <Step
                        title="Тип контента"
                        description="Выберите тип контента: standart, custom, demo"
                    />
                    <Step
                        title="Заполните форму"
                        description="Заполните форму ниже"
                    />
                </Steps>
            </div>

            {step === 0 && (
                <div className={styles.buttons}>
                    <div
                        onClick={() => {
                            set_type('realestate');
                            set_step(1);
                            set_object_title('');
                        }}
                        className={styles.button}
                    >
                        Жилой комплекс
                    </div>
                    <div
                        onClick={() => {
                            set_type('queue');
                            set_step(1);
                            set_object_title('');
                        }}
                        className={styles.button}
                    >
                        Очередь
                    </div>
                </div>
            )}

            {step === 1 && (
                <div className={styles.buttons}>
                    <div
                        onClick={() => {
                            set_step(2);
                            set_type_content('standart');
                        }}
                        className={styles.button}
                    >
                        Standart
                    </div>
                    <div
                        onClick={() => {
                            set_step(2);
                            set_type_content('custom');
                        }}
                        className={styles.button}
                    >
                        Custom
                    </div>
                    <div
                        onClick={() => {
                            set_step(2);
                            set_type_content('demo');
                        }}
                        className={styles.button}
                    >
                        Demo
                    </div>
                </div>
            )}

            {step === 2 && (
                <div>
                    <ApartmentСomplex
                        object_title={object_title}
                        type={type}
                        type_content={type_content}
                    />
                </div>
            )}

            <div className={styles.steps}>
                {step === 1 && (
                    <Button
                        style={{ marginRight: 15 }}
                        type="primary"
                        onClick={() => {
                            if (object_title) {
                                set_type('realestate');
                                set_step(step + 1);
                            } else {
                                return message.warning('Заполните поле');
                            }
                        }}
                    >
                        Далее
                    </Button>
                )}
                {step > 0 && <Button onClick={() => prev()}>Назад</Button>}
            </div>
        </content>
    );
};

export default CreateApartment;
