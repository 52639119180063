import Apartments from '@/Containers/Admin/Apartments';
import CreateApartment from '@/Containers/Admin/Apartments/Create';
import EditApartment from '@/Containers/Admin/Apartments/Edit';
import PricesApartment from '@/Containers/Admin/Apartments/prices';
import Chess from '@/Containers/Admin/Apartments/chess';
import Objects from '@/Containers/Admin/Apartments/Objects';

import CreateBlocks from '@/Containers/Admin/Blocks/Create';
import Flats from '@/Containers/Admin/Flats';
import CreateFlat from '@/Containers/Admin/Flats/Create';
import EditFlat from '@/Containers/Admin/Flats/Edit';
import Calculate from '@/Containers/Admin/Flats/Edit/Helpers/Calculate';
import BudgetListFinal from '@/Containers/Admin/Flats/Edit/Helpers/Calculate/BudgetListFinal';
import AddFloorsAndFlats from '@/Containers/Admin/Blocks/Floors';
import FlatTemplates from '@/Containers/Admin/Flats/Templates';

import SortRealestate from '@/Containers/Admin/Apartments/SortRealestate';

export default [
    {
        path: '/apartments',
        element: <Apartments />,
    },
    {
        path: '/realestate/sort',
        element: <SortRealestate />,
    },
    {
        path: '/apartments/objects',
        element: <Objects />,
    },
    {
        path: '/apartments/create',
        element: <CreateApartment />,
    },
    {
        path: '/apartments/edit/:id',
        element: <EditApartment />,
    },
    {
        path: '/apartments/chess/:id',
        element: <Chess />,
    },
    {
        path: '/apartments/blocks/:id',
        element: <CreateBlocks />,
    },
    {
        path: '/apartments/blocks/prices/:id',
        element: <PricesApartment />,
    },
    {
        path: '/apartments/blocks/:id/add-floors/:complex_id',
        element: <AddFloorsAndFlats />,
    },
    {
        path: '/apartments/flats',
        element: <Flats />,
    },
    {
        path: '/apartments/flats/templates',
        element: <FlatTemplates />,
    },
    {
        path: '/apartments/flats/templates/add',
        element: <CreateFlat />,
    },
    {
        path: '/apartments/flats/:id/edit',
        element: <EditFlat />,
    },
    {
        path: '/apartments/flats/:id/edit/calculate',
        element: <Calculate />,
    },
    {
        path: '/apartments/flats/:id/budget',
        element: <BudgetListFinal />,
    },
];
