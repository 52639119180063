export const calcCoords = (x, y, height, width, coords) => {
    let posXnative = x;
    let posYnative = y;
    let blockHeight = height;
    let blockwidth = width;

    let percentX = (posXnative / blockwidth) * 100;
    let percentY = (posYnative / blockHeight) * 100;

    let points = [...coords, [percentX, percentY]];

    let areaPointsString = '';
    points.map((point) => {
        areaPointsString = areaPointsString + `${point[0]}% ${point[1]}%,`;
    });

    areaPointsString = areaPointsString.substring(
        0,
        areaPointsString.length - 1
    );

    return {
        points,
        areaPointsString,
    };
};

export const updatePolygon = (points) => {
    let areaPointsString = '';
    points.forEach((point) => {
        areaPointsString = areaPointsString + `${point[0]}% ${point[1]}%,`;
    });

    areaPointsString = areaPointsString.substring(
        0,
        areaPointsString.length - 1
    );

    return areaPointsString;
};

export const deleteCoord = (coords, index) => {
    let points = coords.filter((_, idx) => {
        return Number(idx) != Number(index);
    });

    let areaPointsString = '';
    points.map((point) => {
        areaPointsString = areaPointsString + `${point[0]}% ${point[1]}%,`;
    });

    areaPointsString = areaPointsString.substring(
        0,
        areaPointsString.length - 1
    );

    return {
        points,
        areaPointsString,
    };
};
