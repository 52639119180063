import React from 'react';

const WidgetConnection = ({styles, widget_id}) => {
    return (
        <div className={styles.code_preview}>
            <h5>Javascript (установить внутри элемента head)</h5>
            <div className={styles.code_preview_section}>
                <code>{`<script defer src="https://api.logovo.kz/widget.js?wid=${widget_id}" id="wglgv"></script>`}</code>
            </div>
            <h5>Html (установить внутри элемента body)</h5>
            <div className={styles.code_preview_section}>
                <code>{`<div id="wgcnt_logovo"></div>`}</code>
            </div>
        </div>
    )
}

export default WidgetConnection;