import React from 'react';
import {Link} from 'react-router-dom';

import styles from '../styles/index.module.scss';

const CreditApplication = () => {
	return (
		<content className="cities-page-block">

			<div className={styles.topLinks}>
				<Link className={styles.activeLink} to="/crediting/applications">Заявки</Link>
				<Link to="/crediting/banks">Банки</Link>
			</div>

			<h1>Список заявок</h1>

			<p>Информации нет</p>

		</content>
	)
}

export default CreditApplication;