import React, { useState } from 'react';
import { Result, Button, Modal, Select, Tooltip, Popconfirm } from 'antd';
import { useSelector } from 'react-redux';
import config from '@/Config';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

const GroupTours = ({widget, styles, dispatch, api, id}) => {

    const [form_modal, set_form_modal] = useState(false);
    const [select_realestate, set_select_realestate] = useState();
    const [tour_modal, set_tour_modal] = useState(false);
    const [tour_flat_id, set_tour_flat_id] = useState('');
    const [select_flat, set_select_flat] = useState();
    const { request_apartments_status, apartments } = useSelector(state => state?.apartments);
    const { search_plans } = useSelector(state => state?.widgets);

    const handleSearchRealestate = (newValue) => {

        dispatch({
            type: 'CLEAR_APARTMENTS',
        });

        return dispatch(api.apartments.searchRealestate(
            '',
            newValue,
            1,
            '',
            ''
        ));
    };

    const handleSearchFlats = (newValue) => {
        return dispatch(api.apartments.searchPlans(select_realestate, newValue));
    };

    const addFlat = () => {
        return dispatch(api.widgets.updateWidgetOptions(id, {
            realestate_id: select_realestate,
            flat_id: select_flat
        }, 'add_flat'));
    }

    const deleteFlat = (flat_id) => {
        return dispatch(api.widgets.updateWidgetOptions(id, {
            flat_id
        }, 'delete_flat')); 
    }

    return (
        <div>

            <Modal 
                title="Предпросмотр 3D тура"
                open={tour_modal} 
                footer={null} 
                centered 
                destroyOnClose 
                onCancel={()=>{
                    set_tour_flat_id('');
                    set_tour_modal(false);
                }}
                width="90%"
            >
                <iframe className={styles.frameTour} src={`${config.tour_service}${tour_flat_id}?callback=false`} />
            </Modal>

            <Modal 
                title="Форма добавления квартиры"
                open={form_modal} 
                footer={null} 
                onCancel={()=>set_form_modal(false)}
                width="60%"
            >
                <Tooltip title="Поиск по названию жк" placement='topLeft'>
                    <Select
                        showSearch
                        loading={request_apartments_status}
                        value={select_realestate}
                        className={styles.selectInput}
                        placeholder="Поиск по названию жк"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchRealestate}
                        onChange={(value)=>set_select_realestate(value)}
                        notFoundContent={null}
                        size="large"
                        options={(apartments || []).map((item) => ({
                            value: item?._id,
                            label: item?.title,
                        }))}
                    />
                </Tooltip>
                {
                    select_realestate ?
                        <Tooltip title="Поиск квартиры по номеру" placement='topLeft'>
                            <Select
                                showSearch
                                loading={request_apartments_status}
                                value={select_flat}
                                className={styles.selectInput}
                                placeholder="Поиск квартиры по номеру"
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={handleSearchFlats}
                                onChange={(value)=>set_select_flat(value)}
                                notFoundContent={null}
                                size="large"
                                options={(search_plans || []).map((item) => ({
                                    value: item?._id,
                                    label: `${item?.template?.title} (номер квартиры: ${item?.title}), ${item?.apartment_complex_id?.title || ''}`,
                                }))}
                            />
                        </Tooltip>
                    :
                        <></>
                }

                {
                    select_flat && select_realestate ?
                        <Button onClick={addFlat} style={{marginTop: 15}} type='primary'>Добавить квартиру</Button>
                    :
                        <></>
                }

            </Modal>

            {
                widget?.flats && widget?.flats.length ?
                    <div className={styles.widgetGroupFlats}>
                        <div className={styles.header}>
                            <div></div>
                            <Button size="large" onClick={()=>set_form_modal(true)} type="primary" key="console">
                                добавить квартиру
                            </Button>
                        </div>
                        <div className={styles.content}>

                            {
                                widget?.flats.map((item)=>(
                                    <div className={styles.item} key={item?._id}>
                                        <div className={styles.left}>
                                            <div className={styles.image} style={{
                                                background: `url(${config.public}${item?.template?.preview_photo?.url}) center / cover no-repeat`
                                            }}></div>
                                            <h5>{ item?.title } (количество комнат: {item?.count_rooms || ''})</h5>
                                        </div>
                                        <div className={styles.right}>
                                            <Tooltip title="Предпросмотр"><Button onClick={()=>{
                                                set_tour_flat_id(item?._id);
                                                set_tour_modal(true);
                                            }} size="small"><EyeOutlined /></Button></Tooltip>
                                            <Popconfirm
                                                    title="Удалить квартиру с виджета?"
                                                    onConfirm={()=>deleteFlat(item?._id)}
                                                    okText="Удалить"
                                                    cancelText="Нет"
                                                >
                                                <Button type='primary' danger size="small"><DeleteOutlined /></Button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                ))
                            }
                            
                        </div>
                    </div>
                :
                    <Result
                        title="Квартир не найдено"
                        extra={
                        <Button onClick={()=>set_form_modal(true)} type="primary" key="console">
                            добавить квартиру
                        </Button>
                        }
                    />
            }
        </div>
    )
}

export default GroupTours;