import React, {useEffect} from 'react';
import {Button} from "antd";
import styles from "../../styles/index.module.scss";
import CategoryItem from "../../helpers/category-item";
import {Link} from 'react-router-dom';
import randomKey from "crypto-random-string";
import {LoadingOutlined} from '@ant-design/icons';

const ShopCategories = ({
	categories,
	api,
	dispatch,
	loading
}) => {

	useEffect(()=>{
		dispatch(api.library.categories('SHOP'));
	}, [])

	return (
		<>
			{categories && categories.length && <Link to="/library/categories/create?type=shop"><Button type="primary" className={styles.button}>Добавить</Button></Link>}

			<div className={styles.contentCategories}>
				{
					loading ?
						<div className={styles.loaderBlock}>
							<LoadingOutlined className={styles.loaderIcon}/>
						</div>
					: categories && categories.length ?
						categories.map( item => <CategoryItem type="shop" api={api} dispatch={dispatch} key={randomKey({length:15})} item={item} /> )
					:
						<div className={styles.loaderBlock}>
							<h6>Информации нет</h6>
							<Link to="/library/categories/create?type=shop"><Button type="primary" className={styles.button}>Добавить</Button></Link>
						</div>
				}
			</div>
		</>
	)
}

export default ShopCategories;