"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathLayer = void 0;
var tslib_1 = require("tslib");
var utils_1 = require("../../utils");
var lines_layer_1 = require("../lines-layer");
var LAYER_OPTIONS_KEYS = ['color', 'size', 'style', 'state', 'animate'];
var PathLayer = /** @class */ (function (_super) {
    tslib_1.__extends(PathLayer, _super);
    function PathLayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图层类型
         */
        _this.type = lines_layer_1.LinesLayer.LayerType.PathLayer;
        return _this;
    }
    /**
     * 获取默认配置
     */
    PathLayer.prototype.getDefaultOptions = function () {
        return (0, utils_1.deepAssign)({}, lines_layer_1.LinesLayer.DefaultOptions, {
            shape: 'line',
        });
    };
    /**
     * 图层配置项 Keys
     */
    PathLayer.LayerOptionsKeys = lines_layer_1.LinesLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return PathLayer;
}(lines_layer_1.LinesLayer));
exports.PathLayer = PathLayer;
