import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import styles from '../../styles/index.module.scss';
import selectLibrary from '@/Services/Store/reducers/library/selector';
import {
    LoadingOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    Modal,
    Input,
    Button,
    Form,
    Space,
    DatePicker,
    Select,
    InputNumber,
} from 'antd';

import { moment } from '@/Services';

const FormType = ({ visible, close, item }) => {
    const dispatch = useDispatch();
    const stateLibrary = useSelector(selectLibrary);

    const [state_form, set_state_form] = useState(null);

    useEffect(() => {
        if (item) {
            set_state_form({
                name: item?.name,
                bonus_amount: item?.bonus_amount,
                shops: item?.shops.map((shop) => {
                    return {
                        bonus_amount: shop.bonus_amount,
                        expiration: moment(shop.expiration),
                        shop: shop?.shop?._id,
                        id: shop?._id,
                    };
                }),
            });
        } else {
            set_state_form(null);
        }
    }, [item]);

    useEffect(() => {
        if (visible && !stateLibrary?.shops) {
            dispatch(api.library.shops());
        }
    }, [visible]);

    const addTypeCard = async (data) => {
        if (!stateLibrary.status_cards_request) {
            let res = await dispatch(api.library.addTypeCard(data));

            if (res.status) {
                close();
            }
            return;
        }
    };

    const editTypeCard = async (data) => {
        if (!stateLibrary.status_cards_request) {
            let res = await dispatch(api.library.editTypeCard(data, item?._id));

            if (res.status) {
                close();
            }
            return;
        }
    };

    const getDate = (date, dateString) => {
        console.log(date, dateString);
    };

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };

    return (
        <Modal
            title={
                item
                    ? 'Форма редактирования типа карты'
                    : 'Форма добавления типа карты'
            }
            visible={visible}
            footer={null}
            width="65%"
            onCancel={() => close()}
            destroyOnClose={true}
        >
            <Form
                onFinish={item ? editTypeCard : addTypeCard}
                autoComplete="off"
                initialValues={state_form}
            >
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'поле должно быть заполнено',
                        },
                    ]}
                >
                    <Input
                        size="large"
                        showCount
                        maxLength={30}
                        placeholder="Наименование типа карты"
                    />
                </Form.Item>

                <Form.Item
                    name="bonus_amount"
                    rules={[
                        {
                            required: true,
                            message: 'поле должно быть заполнено',
                        },
                    ]}
                >
                    <InputNumber
                        className={styles.inputStyle}
                        size="large"
                        showCount
                        maxLength={20}
                        placeholder="Сумма бонуса"
                    />
                </Form.Item>

                <Form.List name="shops">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'shop']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Заполните поле',
                                            },
                                        ]}
                                    >
                                        <Select
                                            size="large"
                                            style={{ minWidth: '350px' }}
                                            dropdownClassName={styles.zIndex}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.includes(input)
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        optionB.children.toLowerCase()
                                                    )
                                            }
                                            placeholder="Выберите магазин"
                                        >
                                            {stateLibrary.shops &&
                                            stateLibrary.shops.length ? (
                                                stateLibrary.shops.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item?._id}
                                                            value={item?._id}
                                                        >
                                                            {item?.title}
                                                        </Select.Option>
                                                    )
                                                )
                                            ) : (
                                                <Select.Option value={null}>
                                                    Магазинов не найдено
                                                </Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        {...restField}
                                        name={[name, 'bonus_amount']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Заполните поле',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            size="large"
                                            style={{ minWidth: '220px' }}
                                            placeholder="Сумма бонуса"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'expiration']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Выберите дату',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            placeholder="Срок действия"
                                            disabledDate={disabledDate}
                                            size="large"
                                            style={{ minWidth: '170px' }}
                                            dropdownClassName={styles.zIndex}
                                            onChange={getDate}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                    />
                                </Space>
                            ))}

                            {!item && (
                                <Form.Item>
                                    <Button
                                        size="large"
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Добавить магазин
                                    </Button>
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {stateLibrary.status_cards_request ? (
                            <LoadingOutlined />
                        ) : item ? (
                            'Редактировать'
                        ) : (
                            'Добавить'
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default FormType;
