const initialState = {
    categories: null,
    shop_categories: null,
    products: [],
    page_products: 0,
    FR_products: false,
    request_no_data_products: false,

    sub_categories: null,

    tree_categories: [],

    shops: null,
    shop: null,

    category_edit_item: null,

    shop_item_edit: {},
    library_product_edit: {},

    request: false,

    search_products_request: false,
    list_search_products: null,

    sync_request: false,

    criteria: [],

    status_cards_request: false,
    type_cards: [],
    bonus_cards: [],
    bonus_card_checks: [],
};

const library = (state = initialState, action) => {
    switch (action.type) {
        case 'STATUS_CARDS_REQUEST':
            return {
                ...state,
                status_cards_request: action.status,
            };
        case 'REMOVE_BONUS_CARD':
            return {
                ...state,
                bonus_cards: state.bonus_cards.filter(
                    (item) => item?._id !== action.id
                ),
            };
        case 'REMOVE_TYPE_CARD':
            return {
                ...state,
                type_cards: state.type_cards.filter(
                    (item) => item?._id !== action.id
                ),
            };
        case 'UPDATE_BONUS_CARD_CHECKS':
            return {
                ...state,
                bonus_card_checks: action.data || [],
            };
        case 'UPDATE_TYPE_CARDS':
            return {
                ...state,
                type_cards: action.data || [],
            };

        case 'UPDATE_BONUS_CARDS':
            return {
                ...state,
                bonus_cards: action.data || [],
            };

        case 'UPDATE_LIBRARY_TREE_SHOP_CATEGORIES':
            return {
                ...state,
                shop_categories:
                    action.data && action.data.length
                        ? action.data.map((item) => {
                              return {
                                  id: item?._id,
                                  value: item?._id,
                                  title: item?.title,
                                  pId: item?.parent_category
                                      ? item?.parent_category?._id
                                      : null,
                                  isLeaf: false,
                              };
                          })
                        : [],
            };

        case 'LIBRARY_SHOP_CATEGORIES':
            return {
                ...state,
                shop_categories: action.data,
            };

        case 'LIBRARY_UPDATE_EDIT_CATEGORY_ITEM':
            return {
                ...state,
                category_edit_item: action.data,
            };

        case 'UPDATE_LIBRARY_CRITERIA':
            return {
                ...state,
                criteria: action.data,
            };

        case 'UPDATE_LIBRARY_EDIT_SHOP':
            return {
                ...state,
                shop_item_edit: action.data,
            };

        case 'UPDATE_PRODUCT_EDIT':
            return {
                ...state,
                library_product_edit: action.data,
            };

        case 'UPDATE_LIBRARY_TREE_CATEGORIES':
            return {
                ...state,
                tree_categories:
                    action.data && action.data.length
                        ? action.data.map((item) => {
                              return {
                                  id: item?._id,
                                  value: item?._id,
                                  title: item?.title,
                                  pId: item?.parent_category
                                      ? item?.parent_category?._id
                                      : null,
                                  isLeaf: false,
                              };
                          })
                        : [],
            };

        case 'PRODUCTS_REQ_NO_DATA':
            return {
                ...state,
                request_no_data_products: action.status,
            };

        case 'CLEAR_PRODUCTS':
            return {
                ...state,
                products: [],
            };

        case 'PRODUCTS_REQ_FR':
            return {
                ...state,
                FR_products: true,
            };

        case 'PRODUCTS_PAGE':
            return {
                ...state,
                page_products: action.page,
            };

        case 'LIST_SEARCH_PRODUCTS':
            return {
                ...state,
                list_search_products: action.data,
            };

        case 'SYNC_SHOPS_REQUEST':
            return {
                ...state,
                sync_request: action.status,
            };

        case 'SEARCH_PRODUCTS_REQUEST':
            return {
                ...state,
                search_products_request: action.status,
            };

        case 'LIBRARY_SHOPS':
            return {
                ...state,
                shops: action.data,
            };

        case 'LIBRARY_SHOP_EDIT':
            return {
                ...state,
                shop: action.data,
            };

        case 'LIBRARY_REMOVE_SHOP': {
            let shops = [];

            if (state.shops.length == 1) {
                shops = null;
            } else {
                state.shops.map((item) => {
                    if (item._id != action.id) {
                        shops = [...shops, item];
                    }
                });
            }

            return {
                ...state,
                shops,
            };
        }

        case 'UPDATE_SUB_CATEGORIES':
            return {
                ...state,
                sub_categories: action.data,
            };

        case 'LIBRARY_REQUEST':
            return {
                ...state,
                request: action.status,
            };

        case 'LIBRARY_CATEGORIES':
            return {
                ...state,
                categories: action.data,
            };

        case 'LIBRARY_PRODUCTS':
            return {
                ...state,
                products: [...state.products, ...action.data],
            };

        case 'LIBRARY_REMOVE_PRODUCT': {
            let products = [];

            if (state.products.length == 1) {
                products = null;
            } else {
                state.products.map((item) => {
                    if (item._id != action.id) {
                        products = [...products, item];
                    }
                });
            }

            return {
                ...state,
                products,
            };
        }

        case 'LIBRARY_UPDATE_PRODUCT':
            return {
                ...state,
                products: state.products.map((item) => {
                    if (item._id == action.id) {
                        return action.data;
                    }

                    return item;
                }),
            };

        case 'LIBRARY_ADD_PRODUCT':
            return {
                ...state,
                products:
                    state.products && state.products.length
                        ? [action.data, ...state.products]
                        : [action.data],
            };

        default:
            return state;
    }
};

export default library;
