import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import { Button, Table } from 'antd';
import styles from '../../styles/index.module.scss';
import FormTemplateV2 from './form-template-v2';
import { VList } from 'virtuallist-antd';
import templateColumns from './funcs/table/template-columns';

const FlatTemplates = ({ realestate_id }) => {
    const dispatch = useDispatch();
    const getFlatState = useSelector((state) => state.flats);
    const [form_status, set_form_status] = useState(false);
    const [item_edit, set_item_edit] = useState(null);

    useEffect(() => {
        dispatch(api.flats.getTemplatesOfRealestate(realestate_id));
    }, []);

	const vComponents = useMemo(() => {
		return VList({
			height: 'calc(100vh - 300px)'
		})
	}, []);

    const removeTemplate = (template_id) => {
        return dispatch(api.flats.deleteTemplate(template_id));
    };

    const openFormTemplate = (item) => {
        set_form_status(true);
        set_item_edit(item || null);
    }

    return (
        <>

            <div className={styles.blockTemplates}>
                <div className={styles.blockTemplatesHeader}>
                    <div></div>
                    <Button
                        onClick={() => set_form_status(true)}
                        type="primary"
                    >
                        Добавить шаблон
                    </Button>
                </div>
                <div className={styles.blockTemplatesList}>
                    <Table
                        dataSource={getFlatState.templates}
                        columns={templateColumns(openFormTemplate, removeTemplate)}
                        scroll={{ y: 'calc(100vh - 300px)' }}
                        rowKey={(item) => item?._id}
                        components={vComponents}
                        pagination={false}
                        loading={getFlatState.request_flats_status}
                    />
                </div>
            </div>

            <FormTemplateV2
                visible={form_status}
                close={() => {
                    set_form_status(false);
                    set_item_edit(null);
                }}
                realestate_id={realestate_id}
                item={item_edit}
                loading={getFlatState?.request_form_status}
            />

        </>
    );
};

export default FlatTemplates;
