import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputMask from 'antd-mask-input';
import styles from '../styles/index.module.scss';
import { api } from '../../../../Services';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'antd';

const Realtors = () => {
    const dispatch = useDispatch();
    const history = useNavigate();

    const [name_company, set_name_company] = useState('');
    const [phone_user, set_phone_user] = useState('');
    const [email, set_email] = useState('');
    const [address, set_address] = useState('');
    const [password, set_password] = useState('');
    const [repeat_password, set_repeat_password] = useState('');

    const addUser = () => {
        return dispatch(
            api.users.addRealtor(
                {
                    name_company,
                    phone: phone_user,
                    email,
                    address,
                    password,
                    repeat_password,
                },
                history
            )
        );
    };

    return (
        <content>
            <h1 className="title-page">Добавление риэлтора</h1>

            <section className={styles.formRealtor}>
                <Input
                    size="large"
                    value={name_company}
                    onChange={(val) => set_name_company(val.target.value)}
                    placeholder="Название"
                />
                <InputMask
                    size="large"
                    mask="+7 000 000 00 00"
                    type="text"
                    placeholder="Номер телефона*"
                    value={phone_user}
                    onChange={(val) => set_phone_user(val.target.value)}
                />
                <Input
                    size="large"
                    value={email}
                    onChange={(val) => set_email(val.target.value)}
                    placeholder="Email риэлтора"
                />
                <Input
                    size="large"
                    value={address}
                    onChange={(val) => set_address(val.target.value)}
                    placeholder="Адрес офиса"
                />

                <section className={styles.formRealtorPassword}>
                    <Input
                        size="large"
                        type="password"
                        value={password}
                        placeholder="Пароль"
                        onChange={(val) => set_password(val.target.value)}
                    />
                    <Input
                        size="large"
                        type="password"
                        value={repeat_password}
                        placeholder="Повторите пароль"
                        onChange={(val) =>
                            set_repeat_password(val.target.value)
                        }
                    />
                </section>

                <Button
                    style={{ maxWidth: '200px' }}
                    onClick={addUser}
                    type="primary"
                    size="large"
                >
                    Добавить
                </Button>
            </section>
        </content>
    );
};

export default Realtors;
