import React, { useEffect, useRef, useState } from 'react';
import config from '../../../../Config';
import { TextField } from '@material-ui/core';

//change icon 360 #4
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { api } from '../../../../Services';
import { Autocomplete } from '@material-ui/lab';
import { EllipsisOutlined } from '@ant-design/icons';
import { Popconfirm, Menu, Dropdown } from 'antd';
import styles from '../styles/index.module.scss';

const FlatTemplates = () => {
    const [page, setPage] = useState(1);
    const [complexApartment, setComplexApartment] = useState('');

    const getFlatsState = useSelector((state) => state.flats);
    const getCacheState = useSelector((state) => state.cache);

    const dispatch = useDispatch();

    const refItem = useRef(null);

    useEffect(() => {
        dispatch({
            type: 'CLEAR_TEMPLATES',
        });

        dispatch(api.flats.getComplexAll());

        if (getCacheState.saveComplexCache) {
            dispatch(
                api.flats.getTemplates(
                    1,
                    getCacheState.saveComplexCache
                        ? getCacheState.saveComplexCache._id
                        : undefined
                )
            );
        } else {
            dispatch(api.flats.getTemplates(page, complexApartment));
        }
    }, []);

    const deleteTemplate = (id) => {
        return dispatch(api.flats.deleteTemplate(id));
    };

    const getMoreTemplates = () => {
        if (getCacheState.saveComplexCache) {
            dispatch(
                api.flats.getTemplates(
                    page + 1,
                    getCacheState.saveComplexCache
                        ? getCacheState.saveComplexCache._id
                        : undefined
                )
            );
        } else {
            dispatch(api.flats.getTemplates(page + 1, complexApartment));
        }

        setPage(page + 1);
    };

    const menuSettings = (flat) => (
        <Menu>
            <Menu.Item key="1">
                <Popconfirm
                    title="Квартира будет удалена?"
                    onConfirm={() => deleteTemplate(flat?._id)}
                    onCancel={() => {}}
                    okText="Удалить"
                    cancelText="Нет"
                    placement="bottomLeft"
                >
                    Удалить шаблон
                </Popconfirm>
            </Menu.Item>

            <Menu.Item key="2">
                <Link to={`/apartments/flats/${flat?._id}/edit`}>
                    Редактировать
                </Link>
            </Menu.Item>

            {flat?.virtual_tour && flat?.virtual_tour?._id ? (
                <Menu.Item key="3">
                    <Link to={`/virtual-tours/edit/${flat?.virtual_tour?._id}`}>
                        Редактировать 3D тур
                    </Link>
                </Menu.Item>
            ) : (
                <></>
            )}
        </Menu>
    );

    return (
        <div className={`cities-page-block ${styles.wrapper}`}>
            <div className={styles.headerContent}>
                <div className="header-page-main">
                    <h1>Шаблоны квартир</h1>
                    <Link to={`/apartments/flats/templates/add`}>
                        <Button type="primary" size="large">
                            Создать шаблон
                        </Button>
                    </Link>
                </div>
                <div className="search-block-complex-templates">
                    <Autocomplete
                        renderOption={(option) => (
                            <React.Fragment>
                                <h5 className="popper-option-style">
                                    {option.title}
                                </h5>
                            </React.Fragment>
                        )}
                        freeSolo
                        autoHighlight={true}
                        disablePortal
                        id="auto-select"
                        disableClearable={true}
                        underlineShow={false}
                        className="MuiAutocomplete-paper"
                        onChange={(event, value) => {
                            setPage(1);
                            setComplexApartment(value ? value._id : '');
                            dispatch({
                                type: 'CLEAR_TEMPLATES',
                            });
                            dispatch({
                                type: 'UPDATE_CACHE_COMPLEX',
                                cacheComplex: value,
                            });
                            dispatch(
                                api.flats.getTemplates(
                                    1,
                                    value ? value._id : undefined
                                )
                            );
                        }}
                        style={{
                            width: '100%',
                            margin: '0 0 10px 0',
                            padding: 0,
                        }}
                        autoComplete="false"
                        getOptionSelected={(option, value) =>
                            option.title === value.title
                        }
                        options={getFlatsState.complexApartments}
                        defaultValue={
                            getCacheState.saveComplexCache
                                ? getCacheState.saveComplexCache
                                : ''
                        }
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    style={{
                                        width: '100%',
                                        margin: 0,
                                        padding: '0',
                                        fontSize: 18,
                                    }}
                                    fullWidth
                                    placeholder="Выбрать ЖК"
                                    readonly
                                    {...params}
                                    floatingLabelFocusStyle={{
                                        color: 'red',
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        autoComplete: 'false',
                                        disableUnderline: true,
                                        className: {
                                            input: {
                                                fontSize: 22,
                                            },
                                            InputLabelProps: {
                                                style: { color: 'red' },
                                            },
                                        },
                                    }}
                                    label=""
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className={`content-items-flat ${styles.flatItems}`}>
                {getFlatsState.templates && getFlatsState.templates.length ? (
                    getFlatsState.templates.map((template, index) => {
                        let defaultHomeUrl =
                            template.preview_photo && template.preview_photo.url
                                ? `${config.public}270/250/${template.preview_photo.url}`
                                : require('../../../../assets/default-home.png');

                        return (
                            <div
                                key={template._id + index}
                                ref={refItem}
                                className={`block-item-flat ${styles.flatItem}`}
                            >
                                <div className={styles.menuButton}>
                                    <Dropdown
                                        trigger={['click']}
                                        className={styles.Dropdown}
                                        overlay={() => menuSettings(template)}
                                    >
                                        <EllipsisOutlined
                                            onClick={(e) => e.preventDefault()}
                                            rotate={90}
                                            className={styles.settingIcon}
                                        />
                                    </Dropdown>
                                </div>

                                <section
                                    style={{
                                        width: '100%',
                                        height: 170,
                                        background: `url(${defaultHomeUrl}) center / contain no-repeat`,
                                    }}
                                ></section>
                                <div className="block-item-flat-information">
                                    <p>{template.title}</p>

                                    {template.commercial ? (
                                        <h5>Коммерческая недвижимость</h5>
                                    ) : (
                                        <h5>
                                            <span>Кол-во комнат:</span>{' '}
                                            {template.count_rooms}
                                        </h5>
                                    )}

                                    <h5>
                                        <span>ЖК:</span>{' '}
                                        {template?.realestate &&
                                        template?.realestate.length
                                            ? template?.realestate.map(
                                                  (item) =>
                                                      item?.title + ', ' ||
                                                      'Не найдено'
                                              )
                                            : template?.apartment_complex_id
                                                  ?.title || 'Не найдено'}
                                    </h5>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <h3>...</h3>
                )}
            </div>
            {getFlatsState.request_flats_status == false &&
            getFlatsState.templates &&
            getFlatsState.templates.length ? (
                <span
                    onClick={() => {
                        getMoreTemplates();
                    }}
                    className="more-flats"
                >
                    Показать больше{' '}
                    {getFlatsState.templates
                        ? `(Найдено: ${getFlatsState.templates.length})`
                        : ''}
                </span>
            ) : (
                <span className="more-flats">Загружаем...</span>
            )}
        </div>
    );
};

export default FlatTemplates;
