import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputMask from 'antd-mask-input';
import styles from '../styles/index.module.scss';
import { api, moment } from '../../../../Services';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, Tabs, Result, DatePicker, Tag } from 'antd';

const EditRealtor = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { id } = useParams();

    const stateRealtor = useSelector((state) => state.users.realtor);
    const request_status_users = useSelector(
        (state) => state.users.request_status_users
    );

    const [first_req, set_first_req] = useState(false);

    const [name_company, set_name_company] = useState('');
    const [phone_user, set_phone_user] = useState('');
    const [email, set_email] = useState('');
    const [address, set_address] = useState('');

    const [expiration, set_expiration] = useState('');

    const [sub_modal, set_sub_modal] = useState(false);

    const [balance, set_balance] = useState('');

    useEffect(() => {
        set_first_req(true);

        if (!first_req) {
            dispatch(api.users.getRealtor(id));
        }

        if (stateRealtor) {
            set_name_company(stateRealtor.settings.company_name);
            set_phone_user('+7' + stateRealtor.phone);
            set_email(stateRealtor.email);
            set_address(stateRealtor.address);
        }
    }, [stateRealtor]);

    const editRealtor = () => {
        return dispatch(
            api.users.editRealtor(
                {
                    name_company,
                    phone: phone_user,
                    email,
                    address,
                    id: id,
                    expiration,
                },
                history
            )
        );
    };

    const cashIn = () => {
        return dispatch(
            api.users.cashIn({
                id: id,
                balance,
            })
        );
    };

    return (
        <content>
            <Modal
                width={'45%'}
                title="Настройки"
                open={sub_modal}
                footer={null}
                onCancel={() => set_sub_modal(false)}
            >
                <Tabs>
                    <Tabs.TabPane tab="Подписка" key="status">
                        <Result
                            status="success"
                            title={stateRealtor?.subscription?.rate?.title}
                            subTitle={
                                stateRealtor?.subscription?.rate?.title ===
                                'Начальный'
                                    ? 'Данный пакет стандартный и неограничен по времени'
                                    : moment(
                                          stateRealtor?.subscription?.expiration
                                      ).format(
                                          'Подписка активна. Дата завершения DD MMMM YYYY года'
                                      )
                            }
                            extra={null}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Настройка подписки" key="settings">
                        <section className={styles.sectionSettings}>
                            <h5>Тестовый период</h5>
                            {stateRealtor?.subscription?.rate?.title !==
                            'Начальный' ? (
                                <div>
                                    <Tag color="red">
                                        Платная подписка активна. Изменять дату
                                        запрещено!
                                    </Tag>
                                </div>
                            ) : (
                                <>
                                    <p
                                        style={{
                                            width: 'fill-content',
                                            marginbottom: 7,
                                        }}
                                        color="lime"
                                    >
                                        Выберите дату завершения подписки
                                    </p>
                                    <DatePicker
                                        popupStyle={{ zIndex: 999999 }}
                                        value={expiration}
                                        onChange={(date) =>
                                            set_expiration(date)
                                        }
                                        style={{ width: 320 }}
                                    />
                                </>
                            )}
                        </section>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Управление балансом" key="balance">
                        <section className={styles.sectionSettings}>
                            <div className={styles.sectionSetting}>
                                <Input
                                    onChange={(val) =>
                                        set_balance(val.target.value)
                                    }
                                    placeholder="Введите сумму, которую вы хотите пополнить"
                                />
                                <Button onClick={cashIn}>Пополнить</Button>
                            </div>
                            <div className={styles.sectionSettingInfo}>
                                <h5>Баланс риэлтора</h5>
                                <h2>{stateRealtor?.balance || 0} ₸</h2>
                            </div>
                        </section>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Управление лидами" key="leads">
                        <section className={styles.sectionSettings}>
                            <h5>
                                Для управления лидами и подарочными компаниями,
                                перейдите в специальный раздел
                            </h5>
                            <Button
                                onClick={() =>
                                    history(`/realtors/edit/${id}/leads`)
                                }
                            >
                                Управлять лидами
                            </Button>
                        </section>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>

            <h1 className="title-page">Редактирование риэлтора</h1>

            {!request_status_users ? (
                <section className={styles.formRealtor}>
                    <Input
                        size="large"
                        value={name_company}
                        onChange={(val) => set_name_company(val.target.value)}
                        placeholder="Название"
                    />
                    <InputMask
                        size="large"
                        mask="+7 000 000 00 00"
                        type="text"
                        placeholder="Номер телефона*"
                        value={phone_user}
                        onChange={(val) => set_phone_user(val.target.value)}
                    />
                    <Input
                        size="large"
                        value={email}
                        disabled
                        onChange={(val) => set_email(val.target.value)}
                        placeholder="Email риэлтора"
                    />
                    <Input
                        size="large"
                        value={address}
                        onChange={(val) => set_address(val.target.value)}
                        placeholder="Адрес офиса"
                    />

                    <div className={styles.block}>
                        <Button
                            style={{ maxWidth: '200px' }}
                            onClick={() => {
                                set_sub_modal(true);
                            }}
                            type="primary"
                            size="medium"
                        >
                            Настройки
                        </Button>
                    </div>

                    <Button
                        style={{ maxWidth: '200px' }}
                        onClick={editRealtor}
                        type="primary"
                        size="large"
                    >
                        Редактировать
                    </Button>
                </section>
            ) : (
                <h1 className="title-page">Подождите...</h1>
            )}
        </content>
    );
};

export default EditRealtor;
