import Builders from '../../../../../Containers/Admin/Builders';
import BuilderForm from '../../../../../Containers/Admin/Builders/form';

export default [
    {
        path: '/builders',
        element: <Builders />,
    },
    {
        path: '/builders/form',
        element: <BuilderForm />,
    }
];
