import { createSelector } from '@reduxjs/toolkit';

export const logs = state => state?.logs;

export const ReSelectMWC = createSelector(logs, (state) => {
    return {
        sync_logs_request_status: state?.sync_logs_request_status,
        sync_realestate_data: state?.sync_realestate_data
    }
});

export const ReSelectOwnerRealestateMWC = createSelector(logs, (state) => {
    return {
        sync_owner_logs_request_status: state?.sync_owner_logs_request_status,
        sync_owner_realestate_data: state?.sync_owner_realestate_data
    }
});

export const ReSelectFlats = createSelector(logs, (state) => {
    return {
        sync_owner_logs_request_status: state?.sync_owner_logs_request_status,
        sync_owner_flats_data: state?.sync_owner_flats_data
    }
});

