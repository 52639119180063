import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { api } from '@/Services';

import styles from './styles/index.module.scss';
import {
    ApartmentOutlined,
    BankOutlined,
    CopyOutlined,
    LoadingOutlined,
    ProfileOutlined,
    RiseOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import ChartComp, { GeographicHeatMap } from './Helpers/Chart';

const { Content } = Layout;

const Admin = () => {
    const [query_map, set_query_map] = useState('');

    const dispatch = useDispatch();
    const getStateStatistic = useSelector((state) => state.statistic);

    useEffect(() => {
        dispatch(api.statistic.get(query_map));
    }, [query_map]);

    useEffect(() => {
        document.body.addEventListener('keydown', (e) => {
            if (e.shiftKey && e.keyCode === 77) {
                set_query_map('map_type_ip');
                return;
            }
        });
    }, []);

    return (
        <div className={styles.mainblock}>
            {getStateStatistic.request_status_statistic ? (
                <div className={styles.loadBlock}>
                    <LoadingOutlined className={styles.loadIcon} />
                </div>
            ) : getStateStatistic.information ? (
                <>
                    <Content className={styles.mainblockSection}>
                        <section>
                            <h1>Всего жилых комплексов</h1>
                            <h5>
                                {
                                    getStateStatistic.information
                                        .apartmentsComplex
                                }
                            </h5>
                            <BankOutlined className={styles.icon} />
                        </section>
                        <section>
                            <h1>Всего блоков</h1>
                            <h5>{getStateStatistic.information.blocks}</h5>
                            <BankOutlined className={styles.icon} />
                        </section>
                        <section>
                            <h1>Всего шаблонов для квартир</h1>
                            <h5>{getStateStatistic.information.templates}</h5>
                            <CopyOutlined className={styles.icon} />
                        </section>
                        <section>
                            <h1>Всего квартир</h1>
                            <h5>{getStateStatistic.information.flats}</h5>
                            <BankOutlined className={styles.icon} />
                        </section>

                        <section>
                            <h1>Зарегистрировано пользователей</h1>
                            <h5>{getStateStatistic.information.users}</h5>
                            <UserOutlined className={styles.icon} />
                        </section>
                        <section>
                            <h1>Пользователей за месяц</h1>
                            <h5>
                                ~{getStateStatistic?.information?.connections}
                            </h5>
                            <ApartmentOutlined className={styles.icon} />
                        </section>
                        <section>
                            <h1>запросов в месяц</h1>
                            <h5>~{getStateStatistic?.information?.requests}</h5>
                            <RiseOutlined className={styles.icon} />
                        </section>
                        <section>
                            <h1>Загружено файлов</h1>
                            <h5>{getStateStatistic.information.files}</h5>
                            <ProfileOutlined className={styles.icon} />
                        </section>
                    </Content>

                    <ChartComp
                        title="Общие показатели"
                        styles={styles}
                        data={getStateStatistic?.information?.charts}
                    />
                    <ChartComp
                        title="Посещаемость"
                        styles={styles}
                        data={getStateStatistic?.information?.attendance}
                    />
                    <GeographicHeatMap
                        title="География посещаемости"
                        styles={styles}
                        connections={
                            getStateStatistic?.information?.connections
                        }
                        data={getStateStatistic?.information?.mapDots || []}
                    />
                </>
            ) : (
                <h5>Информации нет</h5>
            )}
        </div>
    );
};

export default Admin;
