import React, { useEffect, useState } from 'react';
import styles from '../styles/index.module.scss';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, NoData } from '@/Components';
import FormObject from './Helpers/form-object';
import keyRandom from 'crypto-random-string';
import config from '@/Config';
import {
    Dropdown,
    Menu,
    Popconfirm,
    Tag,
    Pagination,
    Button,
    Tooltip,
    Typography
} from 'antd';
import { EllipsisOutlined, CheckOutlined } from '@ant-design/icons';
import { selectObjects } from '@/Services/Store/reducers/apartments/selector';
import FilterObjects from './Helpers/filter';

const { Title } = Typography;

const Objects = () => {
    const [form_modal, set_form_modal] = useState(false);
    const [type_modal, set_type_modal] = useState('');
    const [edit_object, set_edit_object] = useState(null);
    const [current_page, set_current_page] = useState(1);
    const [search_value, set_search_value] = useState('');

    const dispatch = useDispatch();
    const objects = useSelector(selectObjects);
    const realestateState = useSelector((state) => state.apartments);
    const realestateList = useSelector(
        (state) => state?.flats?.complexApartments
    );

    useEffect(() => {
        dispatch(api.apartments.getObjects(1));
        dispatch(api.flats.getComplexAll());
    }, []);

    const closeModal = () => {
        set_form_modal(false);
        set_type_modal('');
        set_edit_object(null);
    };

    const openModalCreateObject = () => {
        dispatch({
            type: 'CHANGE_STATUS_OBJECT',
            payload: '',
        });

        set_type_modal('create');
        set_form_modal(true);
    };

    const deleteObject = (object) => {
        dispatch(api.apartments.deleteObject(object?._id));
    };

    const editObject = (object) => {
        dispatch({
            type: 'CHANGE_STATUS_OBJECT',
            payload: '',
        });

        let realestate_ids = [];

        if (object?.realestate && object?.realestate.length) {
            object?.realestate.forEach((item) => {
                realestate_ids.push(item?._id);
            });
        }

        set_edit_object({
            ...object,
            realestate: realestate_ids,
        });
        set_type_modal('edit');
        set_form_modal(true);
    };

    const menuSettings = (object) => {
        return (
            <Menu>
                <Menu.Item key="2" onClick={() => editObject(object)}>
                    Редактировать
                </Menu.Item>
                <Menu.Item key="1">
                    <Popconfirm
                        title="Объект будет удален"
                        onConfirm={() => deleteObject(object)}
                        onCancel={() => {}}
                        okText="Удалить"
                        cancelText="Нет"
                        placement="bottomLeft"
                    >
                        Удалить объект
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
    };

    const makeMainRealestate = (realestate_id, object_id) => {
        dispatch(api.apartments.makeMainRealestate(realestate_id, object_id));
    };

    useEffect(() => {
        dispatch(api.apartments.getObjects(current_page, search_value));
    }, [current_page, search_value]);

    return (
        <div className={`page-apartment ${styles.pageContent}`}>
            <FormObject
                edit_object={edit_object}
                type_modal={type_modal}
                status_object={realestateState?.status_object || ''}
                realestate={realestateList || []}
                isModalVisible={form_modal}
                closeModal={closeModal}
                dispatch={dispatch}
                api={api}
            />

            <div className={styles.pageHeader}>
                <Title level={4}>Объекты</Title>
                <Button size="large" type="primary">
                    <span onClick={openModalCreateObject}>Добавить объект</span>
                </Button>
            </div>

            <FilterObjects
                loading={realestateState?.request_apartments_status}
                searchObjects={(val) => set_search_value(val)}
            />

            <div className={styles.descriptionHead}>
                <Tag color="volcano">
                    Кликните по жк, чтобы сделать его основным
                </Tag>
            </div>

            <div className={styles.pageContent} style={{ padding: '10px 0' }}>
                {realestateState?.request_apartments_status ||
                objects.length == 0 ? (
                    <Loader height={450} />
                ) : objects && objects.length ? (
                    <div className={styles.objectsContent}>
                        {objects.map((item) => (
                            <div
                                key={keyRandom({ length: 15 })}
                                className={styles.itemObject}
                            >
                                <div className={styles.itemObjectHeader}>
                                    <p>{item?.title}</p>
                                    <Dropdown
                                        trigger={['click']}
                                        className={styles.Dropdown}
                                        overlay={() => menuSettings(item)}
                                    >
                                        <EllipsisOutlined
                                            onClick={(e) => e.preventDefault()}
                                            rotate={90}
                                            className={styles.settingIcon}
                                        />
                                    </Dropdown>
                                </div>

                                <div className={styles.childListRealestate}>
                                    {item?.realestate &&
                                    item?.realestate.length ? (
                                        item?.realestate.map((RE) => (
                                            <div
                                                key={keyRandom({ length: 15 })}
                                                onClick={makeMainRealestate.bind(
                                                    null,
                                                    RE?._id,
                                                    item?._id
                                                )}
                                                className={`${
                                                    styles.itemRealestate
                                                } ${
                                                    RE.main &&
                                                    styles.itemMainRealestate
                                                }`}
                                            >
                                                <div
                                                    className={
                                                        styles.iconGreenMainRealestate
                                                    }
                                                >
                                                    {' '}
                                                    <Tooltip title="Главный жк объекта">
                                                        <CheckOutlined />
                                                    </Tooltip>
                                                </div>
                                                <div
                                                    style={{
                                                        background: `url(${config.public}${RE?.photo?.url}) center / cover no-repeat`,
                                                    }}
                                                    className={
                                                        styles.photoRealestate
                                                    }
                                                ></div>
                                                <h6>{RE?.title}</h6>
                                            </div>
                                        ))
                                    ) : (
                                        <span>Жк не найдено</span>
                                    )}
                                </div>
                            </div>
                        ))}
                        {realestateState.total_objects > 0 && (
                            <div className={styles.pagination}>
                                <Pagination
                                    onChange={(val) => set_current_page(val)}
                                    current={current_page}
                                    showSizeChanger={false}
                                    pageSize={20}
                                    defaultCurrent={1}
                                    total={realestateState.total_objects}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <NoData height={450} />
                )}
            </div>
        </div>
    );
};

export default Objects;
