import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api, moment } from '../../../../Services';
import config from '../../../../Config';
import styles from '../styles/index.module.scss';
import { Link } from 'react-router-dom';
import selectLibrary from '../../../../Services/Store/reducers/library/selector';
import { Table, Dropdown, Menu, Popconfirm, Popover, Tag } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import FormBonusCard from './helpers/form';
import currencyFormatter from 'currency-formatter';

const BonusCards = () => {
    const dispatch = useDispatch();
    const stateLibrary = useSelector(selectLibrary);

    const [modal_status, set_modal_status] = useState(false);
    const [edit_bonus_card, set_edit_bonus_card] = useState(null);

    const [columns] = useState([
        {
            title: '№ карты',
            dataIndex: 'number',
            key: 'number',
            render: (number) => <p>{number}</p>,
        },
        {
            title: 'ФИО',
            dataIndex: 'username',
            key: 'username',
            render: (username) => <p>{username}</p>,
        },
        {
            title: 'Жилой комплекс',
            dataIndex: 'realestate',
            key: 'realestate',
            render: (realestate) => <p>{realestate}</p>,
        },
        {
            title: 'Номер квартиры',
            dataIndex: 'apartment_number',
            key: 'apartment_number',
            render: (apartment_number) => <p>{apartment_number}</p>,
        },
        {
            title: 'Тип карты',
            dataIndex: 'type_card',
            key: 'type_card',
            width: 360,
            render: (type_card) => {
                return (
                    <div className={styles.listShops}>
                        <p>{type_card?.name}</p>
                        <div className={styles.listShopsContent}>
                            {type_card && type_card.shops.length ? (
                                type_card.shops.map((shop, index) => {
                                    return (
                                        <Popover
                                            placement="bottom"
                                            content={
                                                <div
                                                    className={
                                                        styles.popoverContent
                                                    }
                                                >
                                                    <span>
                                                        Сумма бонуса:{' '}
                                                        <b>
                                                            {currencyFormatter.format(
                                                                shop?.bonus_amount,
                                                                {
                                                                    code: 'EUR',
                                                                    symbol: '',
                                                                }
                                                            ) + '₸'}
                                                        </b>
                                                    </span>
                                                    <span>
                                                        Срок действия до
                                                        <b>
                                                            {moment(
                                                                shop?.expiration
                                                            ).format(
                                                                ' DD MMMM YYYY'
                                                            )}
                                                        </b>
                                                    </span>
                                                    <span>
                                                        Осталось дней:{' '}
                                                        <b>
                                                            {moment(
                                                                shop?.expiration
                                                            ).diff(
                                                                moment(),
                                                                'days'
                                                            ) < 0 ? (
                                                                <Tag color="red">
                                                                    0
                                                                </Tag>
                                                            ) : (
                                                                <Tag color="lime">
                                                                    {moment(
                                                                        shop?.expiration
                                                                    ).diff(
                                                                        moment(),
                                                                        'days'
                                                                    )}
                                                                </Tag>
                                                            )}
                                                        </b>
                                                    </span>
                                                    <span>
                                                        Статус:{' '}
                                                        <b>
                                                            {moment(
                                                                shop?.expiration
                                                            ).diff(
                                                                moment(),
                                                                'days'
                                                            ) < 0 ? (
                                                                <Tag color="red">
                                                                    Просрочена
                                                                </Tag>
                                                            ) : (
                                                                <Tag color="green">
                                                                    Активна
                                                                </Tag>
                                                            )}
                                                        </b>
                                                    </span>
                                                </div>
                                            }
                                            title={shop?.shop?.title}
                                        >
                                            <div
                                                key={shop?._id}
                                                className={styles.shopItem}
                                                style={{
                                                    left: index * 16,
                                                    background: `url(${config.public}${shop?.shop?.icon?.url}) center / cover no-repeat`,
                                                }}
                                            ></div>
                                        </Popover>
                                    );
                                })
                            ) : (
                                <p>Тип карты не найден</p>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: '✎',
            key: '_id',
            width: 30,
            render: (item) => {
                return (
                    <Dropdown
                        key={item}
                        trigger={['click']}
                        className={styles.DropdownItem}
                        overlay={menuSettings(item)}
                    >
                        <EllipsisOutlined
                            onClick={(e) => e.preventDefault()}
                            rotate={90}
                            className={styles.userItemSettingIcon}
                        />
                    </Dropdown>
                );
            },
        },
    ]);

    const menuSettings = (item) => {
        return (
            <Menu>
                <Menu.Item key="1">
                    <Link to={`/library/bonus-cards/checks/${item?._id}`}>
                        Чеки
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={() => {
                        set_modal_status(true);
                        set_edit_bonus_card(item);
                    }}
                >
                    Редактировать
                </Menu.Item>
                <Menu.Item key="3">
                    <a
                        target="_blank"
                        href={`${config.domain}/bonus-card/${item?.number}`}
                    >
                        Посмотреть
                    </a>
                </Menu.Item>
                <Menu.Item key="4">
                    <Popconfirm
                        title="Карта будет удалена"
                        onConfirm={() =>
                            dispatch(api.library.deleteBonusCard(item?._id))
                        }
                        onCancel={() => {}}
                        okText="Удалить"
                        cancelText="Нет"
                        placement="bottomLeft"
                    >
                        Удалить
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
    };

    useEffect(() => {
        dispatch(api.library.getBonusCards());
    }, []);

    return (
        <div className={styles.mainBlock}>
            <FormBonusCard
                item={edit_bonus_card}
                close={() => {
                    set_modal_status(false);
                    set_edit_bonus_card(null);
                }}
                visible={modal_status}
            />

            <div className={styles.header}>
                <h2 className="title-page">Бонусные карты</h2>
                <div className={styles.headerRight}>
                    <Link to="/library/bonus-cards/types">Типы карт</Link>
                    <span onClick={() => set_modal_status(!modal_status)}>
                        Добавить карту
                    </span>
                </div>
            </div>

            <div className={styles.container}>
                <Table
                    loading={stateLibrary.status_cards_request}
                    bordered
                    filterMode="menu"
                    columns={columns}
                    dataSource={stateLibrary?.bonus_cards || []}
                />
            </div>
        </div>
    );
};

export default BonusCards;
