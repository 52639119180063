import React, { useState, useEffect } from 'react';
import { Modal, Input, Result, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import config from '@/Config';

const { Search } = Input;

//updated

const FormRealestate = ({isOpen, close, api, styles, id}) => {
    const dispatch = useDispatch();
    const { request_apartments_status, apartments } = useSelector(state => state?.apartments);

    const [txt, set_txt] = useState('');

    useEffect(()=>{

        if(isOpen){

            dispatch({
                type: 'CLEAR_APARTMENTS',
            });

            dispatch(api.apartments.searchRealestate(
                '',
                txt,
                1,
                '',
                ''
            ));
        }

    }, [isOpen])

    const searchRealesate = () => {

        dispatch({
            type: 'CLEAR_APARTMENTS',
        });

        return dispatch(api.apartments.searchRealestate(
            '',
            txt,
            1,
            '',
            ''
        ));
    }

    const addRealestate = (realestate_id) => {
        return dispatch(api.widgets.updateRealestatePlans(
            'add_realestate',
            id,
            realestate_id
        ));  
    }

    return (
        <Modal 
            title="Добавление жк" 
            open={isOpen} 
            footer={null}
            onCancel={close}
            width={'60%'}
        >
            <Search 
                onSearch={searchRealesate} 
                onChange={(value)=>set_txt(value.target.value)} 
                size="large" 
                placeholder="Поиск по наименованию жк" 
                loading={request_apartments_status}
            />
            <div className={styles.searchResultBlockForm}>
                {
                    apartments && apartments.length ?
                        <div className={styles.listRealestate}>
                            {
                                apartments.map((item)=>(
                                    <div className={styles.item} key={item?._id + item?.createdAt} onClick={()=>addRealestate(item?._id)}>
                                        <div className={styles.image} style={{
                                            background: `url(${config?.public}${item?.photo?.url}) center / cover no-repeat`
                                        }}/>
                                        <h5>{item?.title}</h5>
                                        <div className={styles.countFlats}>
                                            <Tooltip title="Количество квартир">{item?.price_information?.number_apartments || 0}</Tooltip>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    :
                    <Result
                        title="Ничего не найдено"
                        extra={null}
                    />
                }
            </div>
        </Modal>
    );

}

export default FormRealestate;