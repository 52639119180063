import axios from '../axios';
import { SessionToken } from '../../utils/session';
import { NotificationManager } from 'react-notifications';
import { notification } from 'antd';

const auth = {
    checkToken: () => async (dispatch) => {
        try {
            await axios.post('/v1/token');

            return;
        } catch (err) {
            return dispatch(auth.logout());
        }
    },
    login: (phoneOrEmail, password) => async (dispatch) => {
        if (phoneOrEmail && password) {
            dispatch({
                type: 'REQUEST_STATUS_LOGIN',
                status: true,
            });

            try {
                let { data } = await axios.post('/v1/authentication', {
                    admin: true,
                    email: phoneOrEmail,
                    password,
                });

                if (data.type) {
                    NotificationManager.error(
                        'Пользователь не является администратором'
                    );
                    return;
                }

                dispatch({
                    type: 'REQUEST_STATUS_LOGIN',
                    status: false,
                });

                SessionToken.set(data.token);

                dispatch({
                    type: 'UPDATE_USER',
                    user: data?.user,
                });

                window['AUTHED'] = true;
                return (location.href = '/');
            } catch (err) {
                dispatch({
                    type: 'REQUEST_STATUS_LOGIN',
                    status: false,
                });

                if (err.response.status == 400) {
                    return notification.error({
                        message: 'Ошибка доступа',
                        description: err.response.data,
                        duration: 10,
                    });
                }
                return notification.error({
                    message: 'Ошибка',
                    description: 'Произошла ошибка. Попробуйте позже',
                    duration: 10,
                });
            }
        }

        notification.warning({
            message: 'Ошибка',
            description: 'Заполните все поля',
            duration: 10,
        });
    },
    getProfile: () => async () => {
        //getProfile
        return;
    },
    logout: () => async (dispatch) => {
        SessionToken.remove();
        localStorage.removeItem('persist:root');

        dispatch({
            type: 'UPDATE_USER_LOGGEDIN',
            logedIn: false,
        });

        dispatch({
            type: 'UPDATE_USER',
            user: null,
            logedIn: false,
        });

        return (location.href = '/');
    },
};

export default auth;
