import axios from '../axios';
import { message } from 'antd';
import _ from 'underscore';

const library = {
    getBonusCardChecks: (bonus_card_id) => async (dispatch) => {
        try {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: true,
            });

            let { data } = await axios.get('/v1/admin/library/bonus-card-checks', {
                params: {
                    bonus_card_id,
                },
            });

            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });

            return dispatch({
                type: 'UPDATE_BONUS_CARD_CHECKS',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });
            return message.warning(err.response.data);
        }
    },
    editBonusCard: (fields, id) => async (dispatch) => {
        try {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: true,
            });

            await axios.post('/v1/admin/library/edit-bonus-card', {
                fields,
                id,
            });

            dispatch(library.getBonusCards());

            return {
                status: true,
            };
        } catch (err) {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });

            message.warning(err.response.data);

            return {
                status: false,
            };
        }
    },
    deleteBonusCard: (id) => async (dispatch) => {
        try {
            dispatch({
                type: 'REMOVE_BONUS_CARD',
                id,
            });

            await axios.delete('/v1/admin/library/bonus-card', {
                params: {
                    id,
                },
            });

            return;
        } catch (err) {
            message.warning(err.response.data);

            return {
                status: false,
            };
        }
    },
    deleteTypeCard: (id) => async (dispatch) => {
        try {
            dispatch({
                type: 'REMOVE_TYPE_CARD',
                id,
            });

            await axios.delete('/v1/admin/library/type-card', {
                params: {
                    id,
                },
            });

            return;
        } catch (err) {
            message.warning(err.response.data);

            return {
                status: false,
            };
        }
    },
    editTypeCard: (fields, id) => async (dispatch) => {
        if (!fields.shops || (fields.shops && fields.shops.length == 0)) {
            message.warning('Добавьте магазин');

            return {
                status: false,
            };
        }

        let getUniqShops = _.uniq(fields.shops, (shop) => shop.shop);

        fields = {
            ...fields,
            shops: getUniqShops,
        };

        try {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: true,
            });

            await axios.post('/v1/admin/library/edit-type-card', {
                fields,
                id,
            });

            dispatch(library.getTypeCards());

            return {
                status: true,
            };
        } catch (err) {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });

            message.warning(err.response.data);

            return {
                status: false,
            };
        }
    },
    addBonusCard: (fields) => async (dispatch) => {
        try {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: true,
            });

            await axios.post('/v1/admin/library/add-bonus-card', {
                fields,
            });

            dispatch(library.getBonusCards());

            return {
                status: true,
            };
        } catch (err) {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });

            message.warning(err.response.data);

            return {
                status: false,
            };
        }
    },
    addTypeCard: (fields) => async (dispatch) => {
        if (!fields.shops || (fields.shops && fields.shops.length == 0)) {
            message.warning('Добавьте магазин');

            return {
                status: false,
            };
        }

        let getUniqShops = _.uniq(fields.shops, (shop) => shop.shop);

        fields = {
            ...fields,
            shops: getUniqShops,
        };

        try {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: true,
            });

            await axios.post('/v1/admin/library/add-type-card', {
                fields,
            });

            dispatch(library.getTypeCards());

            return {
                status: true,
            };
        } catch (err) {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });

            message.warning(err.response.data);

            return {
                status: false,
            };
        }
    },
    getBonusCards: () => async (dispatch) => {
        try {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: true,
            });

            let { data } = await axios.get('/v1/admin/library/bonus-cards');

            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });

            return dispatch({
                type: 'UPDATE_BONUS_CARDS',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });
            return message.warning(err.response.data);
        }
    },
    getTypeCards: () => async (dispatch) => {
        try {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: true,
            });

            let { data } = await axios.get('/v1/admin/library/type-cards');

            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });

            return dispatch({
                type: 'UPDATE_TYPE_CARDS',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'STATUS_CARDS_REQUEST',
                status: false,
            });
            return message.warning(err.response.data);
        }
    },
    saveAccessShop: (email, pwd, repeat_pwd, shop_id) => async () => {
        if (!email && !pwd && !repeat_pwd) {
            return message.warning('Заполните поля');
        }

        if (pwd !== repeat_pwd) {
            return message.warning('Пароли не совпадают');
        }

        try {
            let { data } = await axios.post('/v1/admin/library/shops/access', {
                email,
                password: pwd,
                shop_id,
            });

            return message.success(data);
        } catch (err) {
            return message.warning(err.response.data);
        }
    },
    getCategoryById: (id, type) => async (dispatch) => {
        dispatch({
            type: 'LIBRARY_UPDATE_EDIT_CATEGORY_ITEM',
            data: null,
        });

        try {
            let { data } = await axios.get(`/v1/admin/library/categories/${id}`, {
                params: {
                    type,
                },
            });

            dispatch({
                type: 'LIBRARY_UPDATE_EDIT_CATEGORY_ITEM',
                data: data || null,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    deleteCriteria: (id, type) => async (dispatch) => {
        try {
            let { data } = await axios.post(
                '/v1/admin/library/products/criteria/delete',
                {
                    id,
                    type,
                }
            );

            return dispatch({
                type: 'UPDATE_LIBRARY_CRITERIA',
                data,
            });
        } catch (err) {
            return message.warning(
                'Ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    editCriteria: (title, id, type) => async (dispatch) => {
        if (title) {
            try {
                let { data } = await axios.post(
                    '/v1/admin/library/products/criteria/edit',
                    {
                        title,
                        id,
                        type,
                    }
                );

                return dispatch({
                    type: 'UPDATE_LIBRARY_CRITERIA',
                    data,
                });
            } catch (err) {
                return message.warning(
                    'Ошибка. Проверьте Ваши права администратора'
                );
            }
        }

        return message.warning('Заполните поля');
    },
    addCriteria: (title, type) => async (dispatch) => {
        if (title) {
            try {
                let { data } = await axios.post(
                    '/v1/admin/library/products/criteria/create',
                    {
                        title,
                        type,
                    }
                );

                return dispatch({
                    type: 'UPDATE_LIBRARY_CRITERIA',
                    data,
                });
            } catch (err) {
                return message.warning(
                    'Ошибка. Проверьте Ваши права администратора'
                );
            }
        }

        return message.warning('Заполните поля');
    },
    getCriteria: (type) => async (dispatch) => {
        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/library/products/criteria', {
                params: {
                    type,
                },
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            return dispatch({
                type: 'UPDATE_LIBRARY_CRITERIA',
                data,
            });
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            return message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    getShop: (id) => async (dispatch) => {
        try {
            let { data } = await axios.get(`/v1/admin/library/shops`, {
                params: {
                    id,
                },
            });

            return dispatch({
                type: 'UPDATE_LIBRARY_EDIT_SHOP',
                data: data || {},
            });
        } catch (err) {
            return message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    sync: () => async (dispatch) => {
        dispatch({
            type: 'SYNC_SHOPS_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/library/sync');

            dispatch({
                type: 'SYNC_SHOPS_REQUEST',
                status: false,
            });

            return message.success(data);
        } catch (err) {
            dispatch({
                type: 'SYNC_SHOPS_REQUEST',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    searchProducts: (fields) => async (dispatch) => {
        let { shop, title_product, articul_product } = fields;

        dispatch({
            type: 'SEARCH_PRODUCTS_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/library/search-products', {
                params: {
                    shop,
                    title_product,
                    articul_product,
                },
            });

            dispatch({
                type: 'LIST_SEARCH_PRODUCTS',
                data,
            });

            dispatch({
                type: 'SEARCH_PRODUCTS_REQUEST',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'SEARCH_PRODUCTS_REQUEST',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    treeCategories:
        (type = '') =>
        async (dispatch) => {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: true,
            });

            try {
                let { data } = await axios.get('/v1/admin/library/categories', {
                    params: {
                        select: 'all',
                        type,
                    },
                });

                if (type == 'SHOP') {
                    dispatch({
                        type: 'UPDATE_LIBRARY_TREE_SHOP_CATEGORIES',
                        data,
                    });
                } else {
                    dispatch({
                        type: 'UPDATE_LIBRARY_TREE_CATEGORIES',
                        data,
                    });
                }

                return dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });
            } catch (err) {
                console.log(22, err);

                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    categories:
        (type = 'default', tree = '', status_loading = true) =>
        async (dispatch) => {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: status_loading,
            });

            try {
                let { data } = await axios.get('/v1/admin/library/categories', {
                    params: {
                        select: 'tree',
                        type,
                        tree,
                    },
                });

                if (type == 'SHOP') {
                    if (tree == 'tree') {
                        dispatch({
                            type: 'UPDATE_LIBRARY_TREE_SHOP_CATEGORIES',
                            data: data.length ? data : null,
                        });
                    } else {
                        dispatch({
                            type: 'LIBRARY_SHOP_CATEGORIES',
                            data: data.length ? data : null,
                        });
                    }
                } else {
                    if (tree == 'tree') {
                        dispatch({
                            type: 'UPDATE_LIBRARY_TREE_CATEGORIES',
                            data: data.length ? data : null,
                        });
                    } else {
                        dispatch({
                            type: 'LIBRARY_CATEGORIES',
                            data: data.length ? data : null,
                        });
                    }
                }

                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });

                return;
            } catch (err) {
                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    subCategories: (id) => async (dispatch) => {
        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get(
                `/v1/admin/library/categories/get-sub-categories/${id}`
            );

            dispatch({
                type: 'UPDATE_SUB_CATEGORIES',
                data: data.length ? data : null,
            });

            dispatch({
                type: 'UPDATE_LIBRARY_TREE_CATEGORIES',
                data,
            });

            return dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            return message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    createCategory: (fields, history) => async (dispatch) => {
        let {
            title,
            file,
            sub_category,
            copy_category,
            select_category,
            type_form,
        } = fields;

        if (!title) {
            return message.warning('Заполните поля');
        }

        if (sub_category || copy_category) {
            if (!select_category) {
                return message.warning('Выберите категорию');
            }
        }

        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/admin/library/categories/create',
                {
                    title,
                    icon: file?.id,
                    category_id: select_category,
                    type: sub_category ? 'SUB' : 'MAIN',
                    copy_cat: copy_category ? true : false,
                    select_copy_cat: select_category,
                    type_form,
                }
            );

            dispatch({
                type: 'LIBRARY_CATEGORIES',
                data: data.length ? data : null,
            });

            dispatch({
                type: 'APARTMENT_PREVIEW_PHOTO',
                photo: null,
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.success('Категория успешно добавлена');

            return history(`/library/categories?type=${type_form}`);
        } catch (err) {
            console.error(1, err);

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            return message.error('Ошибка. попробуйте чуть позже');
        }
    },
    editCategory: (fields, history) => async (dispatch) => {
        let { title, file, id, sub, select_category, typeForm } = fields;

        if (String(select_category) == String(id)) {
            return message.warning('Выберите другую подкатегорию');
        }

        if (sub) {
            if (!select_category) {
                return message.warning('Выберите категорию');
            }
        }

        if (!title) {
            return message.warning('Заполните все поля');
        }

        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            await axios.post('/v1/admin/library/categories/edit', {
                title,
                icon: file?.id,
                category_id: id,
                type: sub ? 'SUB' : 'MAIN',
                category_parent_id: select_category,
                type_form: typeForm,
            });

            dispatch({
                type: 'APARTMENT_PREVIEW_PHOTO',
                photo: null,
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.success('Категория успешно обновлена');

            return history(`/library/categories?type=${typeForm}`);
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.error('Ошибка. попробуйте чуть позже');
            return {
                status: false,
            };
        }
    },
    products: (page, text_search, clear, shop) => async (dispatch) => {
        if (clear) {
            dispatch({
                type: 'CLEAR_PRODUCTS',
            });
        }

        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        let params = {
            page,
            text_search,
        };

        if (shop && shop !== '') {
            params.shop = JSON.stringify(shop);
        }

        try {
            let { data } = await axios.get('/v1/admin/library/products', {
                params,
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            if (!data.length) {
                return message.success('Товаров больше не найдено');
            }

            dispatch({
                type: 'LIBRARY_PRODUCTS',
                data: data.length ? data : [],
                clear,
            });
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.error('Ошибка, проверьте ваши права администратора');
        }
    },
    shops: () => async (dispatch) => {
        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/library/shops');

            dispatch({
                type: 'LIBRARY_SHOPS',
                data: data.length ? data : null,
            });

            return dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    removeShop: (id) => async (dispatch) => {
        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            await axios.post('/v1/admin/library/shops/remove', {
                id,
            });

            dispatch({
                type: 'LIBRARY_REMOVE_SHOP',
                id,
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.success('Магазин успешно удален', 'Сообщение');

            return {
                status: 1,
            };
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
            return {
                status: 0,
            };
        }
    },
    createShop:
        (title, logo, phone, address, instagram, history, whatsapp) =>
        async (dispatch) => {
            if (title && logo && phone && address && instagram) {
                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: true,
                });

                try {
                    let { data } = await axios.post(
                        '/v1/admin/library/shops/create',
                        {
                            title,
                            logo,
                            phone,
                            address,
                            instagram,
                            whatsapp,
                        }
                    );

                    dispatch({
                        type: 'LIBRARY_SHOPS',
                        data: data.length ? data : null,
                    });

                    dispatch({
                        type: 'LIBRARY_REQUEST',
                        status: false,
                    });

                    return history('/library/shops');
                } catch (err) {
                    dispatch({
                        type: 'LIBRARY_REQUEST',
                        status: false,
                    });

                    return message.error(
                        'Ошибка. Проверьте Ваши права администратора'
                    );
                }
            }

            return message.error('Заполните поля');
        },
    editShop:
        (title, logo, id, phone, address, instagram, history, whatsapp) =>
        async (dispatch) => {
            try {
                let { data } = await axios.post('/v1/admin/library/shops/edit', {
                    title,
                    logo,
                    id,
                    phone,
                    address,
                    instagram,
                    whatsapp,
                });

                dispatch({
                    type: 'LIBRARY_SHOPS',
                    data: data.length ? data : null,
                });

                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });

                dispatch({
                    type: 'APARTMENT_PREVIEW_PHOTO',
                    photo: null,
                });

                return history('/library/shops');
            } catch (err) {
                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });

                return message.error(
                    'Ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    removeProduct: (id) => async (dispatch) => {
        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            await axios.post('/v1/admin/library/products/remove', {
                id,
            });

            dispatch({
                type: 'LIBRARY_REMOVE_PRODUCT',
                id,
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            return message.success('Товар успешно удален');
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
            return {
                status: 0,
            };
        }
    },
    createProduct: (fields, history) => async (dispatch) => {
        let { shop_category, title, shop, photos } = fields;

        let previewPhoto = null;

        if (!shop_category) {
            return message.warning('Категория не выбрана');
        }

        if (!shop) {
            return message.warning('магазин не выбран');
        }

        if (!title) {
            return message.warning('Заполните наименование товара');
        }

        if (photos && photos.length) {
            previewPhoto = photos.filter((photo) => photo.select)[0];

            if (!previewPhoto) {
                return message.warning('Не выбрана главная фотография');
            }
        } else {
            return message.warning('Загрузите фотографии товара');
        }

        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/admin/library/products/create',
                fields
            );

            dispatch({
                type: 'LIBRARY_ADD_PRODUCT',
                data: data,
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            dispatch({
                type: 'APARTMENT_PREVIEW_PHOTO',
                photo: null,
            });

            message.success('Товар успешно добавлен');

            return history('/library');
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            return message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    editProduct: (fields, history) => async (dispatch) => {
        let { shop_category, title, shop, id, photos } = fields;

        let previewPhoto = null;

        if (!shop_category) {
            return message.warning('Категория не выбрана');
        }

        if (!shop) {
            return message.warning('магазин не выбран');
        }

        if (!title) {
            return message.warning('Заполните наименование товара');
        }

        if (photos && photos.length) {
            previewPhoto = photos.filter((photo) => photo.select)[0];

            if (!previewPhoto) {
                return message.warning('Не выбрана главная фотография');
            }
        } else {
            return message.warning('Загрузите фотографии товара');
        }

        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/admin/library/products/edit',
                fields
            );

            dispatch({
                type: 'LIBRARY_UPDATE_PRODUCT',
                id,
                data,
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.success('Товар успешно изменен');

            return history('/library');
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            return message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    removeCategory:
        (id, type = '') =>
        async (dispatch) => {
            try {
                await axios.post('/v1/admin/library/categories/remove', {
                    id,
                    type,
                });

                dispatch({
                    type: 'APARTMENT_PREVIEW_PHOTO',
                    photo: null,
                });

                let requestType = type == 'shop' ? 'SHOP' : '';
                dispatch(library.categories(requestType, '', false));

                return message.success('Категория успешно удалена');
            } catch (err) {
                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        },
    removeSubCategory: (id, parent_category_id) => async (dispatch) => {
        let infoRemove = window.confirm(
            'Категория и продукты связанные с ней будут удалены!'
        );

        if (infoRemove) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: true,
            });

            try {
                let { data } = await axios.post(
                    '/v1/admin/library/categories/sub-categories/remove',
                    {
                        id,
                        parent_category_id,
                    }
                );

                dispatch({
                    type: 'UPDATE_SUB_CATEGORIES',
                    data: data.length ? data : null,
                });

                dispatch({
                    type: 'APARTMENT_PREVIEW_PHOTO',
                    photo: null,
                });

                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });

                message.success('Категория успешно удалена', 'Сообщение');
            } catch (err) {
                dispatch({
                    type: 'LIBRARY_REQUEST',
                    status: false,
                });

                message.error('Ошибка. Проверьте Ваши права администратора');
            }
        }
    },
    getProductByID: (id) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_PRODUCT_EDIT',
            data: {},
        });

        try {
            let { data } = await axios.get(`/v1/admin/library/product/${id}`);

            dispatch({
                type: 'UPDATE_PRODUCT_EDIT',
                data: data || {},
            });

            return;
        } catch (err) {
            return message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    getProductsByID: (id) => async (dispatch) => {
        dispatch({
            type: 'LIBRARY_REQUEST',
            status: true,
        });

        dispatch({
            type: 'CLEAR_PRODUCTS',
        });

        try {
            let { data } = await axios.get(`/v1/admin/library/products/${id}`);

            dispatch({
                type: 'LIBRARY_PRODUCTS',
                data: data.length ? data : [],
            });

            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'LIBRARY_REQUEST',
                status: false,
            });

            message.error('Упс, ошибка. Проверьте Ваши права администратора');
        }
    },
};

export default library;
