export default {
    api: 'https://api.logovo.kz/',
    domain: 'https://logovo.kz',
    dev_api: 'http://localhost:5724/',
    dev: false,
    public: 'https://files.logovo.kz/',
    public_preview: 'https://files.logovo.kz/preview/',
    app_version: '1.0.0',
    google_api_key: 'AIzaSyDrcjbw4pbp0c7rEDg6Xfm4tCF61rkptk8',
    server_file_logovo: 'https://file.logovo.kz/',
    graphqlURI: 'https://api.logovo.kz/graphql',
    dev_graphqlURI: 'http://localhost:5724/graphql',
    graphqlWSURI: 'wss://api.logovo.kz/graphql',
    tour_service: 'https://viewer.logovo.kz/',
    dev_graphqlWSURI: 'ws://localhost:5724/graphql',
    TOKEN: 'SESSION_TOKEN'
};
