import React from 'react';
import styles from '../../../styles/index.module.scss';
import {Link} from "react-router-dom";
import {useMutation, useQuery} from '@apollo/client';
import {budget} from '@/Services/apollo/gqls';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Popconfirm} from "antd";

const GroupsCategories = () => {

	let getGroups = useQuery(budget.getGroupBudgetCategories);
	let removeGroupCategory = useMutation(budget.removeGroupCategory, {
		fetchPolicy: 'no-cache',
		refetchQueries: [{
			query: budget.getGroupBudgetCategories
		}]
	});

	return (
		<div className={styles.mainBlock}>
			<div className={styles.headPageBudget}>
				<h1>Группы категорий</h1>
				<div className={styles.rightButtonCategories}>
					<Link to="/budget/categories/groups">Группы</Link>
					<Link to="/budget/categories">Категории</Link>
					<Link to="/budget">Шаблоны</Link>
				</div>
			</div>
			<div className={styles.mainBlockList}>
				{
					getGroups.loading ?
						<h5>Загрузка...</h5>
						:
						getGroups.data?.getGroupsBudgetCategories && getGroups.data?.getGroupsBudgetCategories.length ?
							<div>
								{
									getGroups.data?.getGroupsBudgetCategories.map((item) => {
										return (
											<div key={item._id} className={styles.itemGroup}>
												<h4>{item.title}</h4>
												<div>

													<Link to={`/budget/categories/groups/${item?._id}`}><EditOutlined className={styles.editGroup} /></Link>
													<Popconfirm
														placement="left"
														title="Группа будет удалена?"
														onConfirm={()=>{
															removeGroupCategory[0]({
																variables: {
																	group_id: item._id
																}
															})
														}}
														onCancel={()=>{}}
														okText="Удалить"
														cancelText="Нет"
													>
														<DeleteOutlined />
													</Popconfirm>

												</div>
											</div>
										)
									})
								}
							</div>
							:
							<h5>Групп не найдено</h5>
				}
			</div>
		</div>
	)

}

export default GroupsCategories;