import React, { useState } from 'react';
import { Result, Button, Popconfirm } from 'antd';
import FormRealestate from './form-realestate';
import config from '@/Config';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const WidgetPlans = ({widget, api, styles, id}) => {
    const dispatch = useDispatch();
    const [modal_form, set_modal_form] = useState(false);

    const removeRealestate = (realestate_id) => {
        return dispatch(api.widgets.updateRealestatePlans(
            'remove_realestate',
            id,
            realestate_id
        ));
    }

    return (
        <div>
            <FormRealestate id={id} styles={styles} api={api} isOpen={modal_form} close={()=>set_modal_form(false)}/>
            {
                widget?.realestate && widget?.realestate.length ?
                    <div className={styles.realestateContent}>
                        <div className={styles.realestateHeader}>
                            <div></div>
                            <Button size="large" onClick={()=>set_modal_form(true)} type="primary" key="console">
                                Добавить жк
                            </Button>
                        </div>
                        <div className={styles.realestateBody}>
                        {
                            widget?.realestate.map((item, index)=>(
                                <div className={styles.item} key={item?.photo?._id + index}>
                                    <div className={styles.left}>
                                        <div className={styles.image} style={{
                                            background: `url(${config.public}${item?.photo?.url}) center / cover no-repeat`
                                        }}></div>
                                        <h5>{item?.title} (количество квартир: {item?.price_information?.number_apartments || 0})</h5>
                                    </div>
                                    <div className={styles.right}>
                                        <Popconfirm
                                            title="Жк будет удален"
                                            onConfirm={()=>removeRealestate(item?._id)}
                                            okText="Удалить ЖК"
                                            cancelText="Нет"
                                        >
                                        <Button size="small" danger type="primary"><DeleteOutlined /></Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                :
                <Result
                    title="Нет добавленных жк"
                    extra={
                    <Button onClick={()=>set_modal_form(true)} type="primary" key="console">
                        Добавить жк
                    </Button>
                    }
                />
            }
        </div>
    )

}

export default WidgetPlans;