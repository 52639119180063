import React, {useState} from 'react';

import {useLazyQuery} from '@apollo/client';
import {library} from '@/Services/apollo/gqls';

import styles from '../../styles/index.module.scss';

import {BudgetCategories} from '@/Components';

const AddProductForm = ({
		template_id,
		status_form_add_product,
		closeModal,
		categories,

		status_add_sub_product,
		add_sub_product_id,
		add_category_id,
		marker,

		addProductForCategoryOfTemplate
	}) => {
	
	const [word, set_word] = useState('');
	const [product_id, set_product_id] = useState(null);
	const [modal_categories_status, set_modal_categories_status] = useState(false);
	
	const [select_category, set_select_category] = useState(null);
	const [select_marker, set_select_marker] = useState(null);
	const [type_cat, set_type_cat] = useState(null);
	
	const [searchProduct, dataProducts] = useLazyQuery(library.searchProduct, {
		variables:{
			word
		},
		fetchPolicy:'no-cache'
	});
	

	
	const addSubProduct = () => {
		if(product_id){
			
			addProductForCategoryOfTemplate({
				variables: {
					fields:{
						id_product:product_id,
						add_id_product:add_sub_product_id,
						id_template:template_id,
						id_category:add_category_id,
						marker
					}
				}
			});
			set_word('');
			closeModal();
			return
			
		}
		
		alert('Заполните форму')
		
	}
	
	const addProduct = () => {
		
		if(select_category && select_marker && product_id){
			
			addProductForCategoryOfTemplate({
				variables: {
					fields:{
						id_category:select_category._id,
						marker:select_marker._id,
						id_product:product_id,
						id_template:template_id
					}
				}
			});
			set_word('');
			set_product_id(null);
			closeModal();
			return
			
		}
		
		alert('Заполните форму')
		
		
	}
	
	return (
		<div className={`${styles.formAddProduct} ${status_form_add_product ? styles.formAddProductOpened : false}`}>
			<div className={styles.formAddProductOverlay} onClick={()=>closeModal()} ></div>
			
			<BudgetCategories select_category={(cat)=>{
				set_modal_categories_status(false);
				if(type_cat == 'CATEGORY'){
					set_select_category(cat)
				}else{
					set_select_marker(cat)
				}
			}} isMarker={type_cat == 'CATEGORY' ? false : true} close={()=>set_modal_categories_status(false)} status={modal_categories_status} data={categories}/>
			
			<div className={styles.formAddProductContent}>
				<h5>Добавление товара</h5>
				
				{
					!status_add_sub_product && (
						<div className={`${styles.formAddProductContentSection}`}>
							<div className={`${styles.formAddProductContentSectionCatAndMarker}`}>
								<h6 onClick={()=>{
									set_type_cat('CATEGORY')
									set_modal_categories_status(true)
								}}>{ select_category ? 'Категория: ' + select_category.title : 'Выберите категорию'  }</h6>
								<h6 onClick={()=>{
									set_type_cat('MARKER')
									set_modal_categories_status(true)
								}}>{ select_marker ? 'Маркер: ' + select_marker.title : 'Выберите маркер'  }</h6>
							</div>
						</div>
					)
				}
				
				<div className={styles.formAddProductContentSection}>
					<h6>Выберите товар</h6>
					<div className={styles.formSearchProduct}>
						<input value={word} onKeyDown={(val)=>{
							if(val.key == 'Enter'){
								searchProduct()
								
							}
						}} type="text" placeholder="Наименование или артикул товара" onChange={(val)=>set_word(val.target.value)} />
						<input type="submit" onClick={()=>searchProduct()} value="Искать" />
					</div>
					
					{
						dataProducts.loading ?
							<h6>Загрузка</h6>
							: dataProducts.data && dataProducts.data.searchProducts && dataProducts.data.searchProducts.length ?
								<select onChange={(val)=>set_product_id(val.target.value)}>
									<option value={null}>Выберите товар</option>
									{
										dataProducts.data.searchProducts.map((product)=>{
											return <option key={product._id} value={product._id}>{ product.title }</option>
										})
									}
								</select>
								:
								<h6>Товаров не найдено</h6>
					}
				
				</div>
				
				<input value="Добавить" className={styles.addProductButton} type="submit" onClick={status_add_sub_product ? addSubProduct : addProduct} />
			
			</div>
		</div>
	)
}

export default AddProductForm;
