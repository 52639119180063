"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcLayer = void 0;
var tslib_1 = require("tslib");
var utils_1 = require("../../utils");
var lines_layer_1 = require("../lines-layer");
var ArcLayer = /** @class */ (function (_super) {
    tslib_1.__extends(ArcLayer, _super);
    function ArcLayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图层类型
         */
        _this.type = lines_layer_1.LinesLayer.LayerType.ArcLayer;
        return _this;
    }
    /**
     * 获取默认配置
     */
    ArcLayer.prototype.getDefaultOptions = function () {
        return (0, utils_1.deepAssign)({}, lines_layer_1.LinesLayer.DefaultOptions, {
            shape: 'arc',
        });
    };
    return ArcLayer;
}(lines_layer_1.LinesLayer));
exports.ArcLayer = ArcLayer;
