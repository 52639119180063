import { produce } from 'immer';

const initialState = {
    list: null,

    getFlatForEdit: null,
    blockInformation: null,

    flatsForBlock: null,

    complexApartments: null,

    request_flats_status: false,
    request_flats_realestate_status: false,
    request_update_flat: false,
    status_request_form_template: false,

    templates: null,
    request_form_status: false,

    template_plan_photo: null,
    template_preview_photo: null,
};

const flats = (state = initialState, action) => {
    switch (action.type) {

        case 'REQUEST_FORM_TEMPLATE_STATUS': {

            return produce(state, draftState => {
                draftState.request_form_status = action?.status || false;
            });

        }

        case 'UPDATE_ONE_TEMPLATE_OF_REALESTATE': {

            let templates = state?.templates || [];

            return produce(state, draftState => {
                draftState.templates = templates.map((item)=>{
                    if(String(item?._id) === String(action?.item?._id)){
                        return action?.item;
                    }
                    return item;
                });
            });

        }

        case 'ADD_ONE_TEMPLATE_OF_REALESTATE': {

            let templates = state?.templates || [];

            return produce(state, draftState => {
                draftState.templates = [action?.item, ...templates];
            });

        }

        case 'UPDATE_TEMPLATES_OF_REALESTATE': {
            return {
                ...state,
                templates: action.data || [],
            };
        }

        case 'TEMPLATE_PLAN_PHOTO_FORM':
            return {
                ...state,
                template_plan_photo: action.data,
            };

        case 'TEMPLATE_PREVIEW_PHOTO_FORM':
            return {
                ...state,
                template_preview_photo: action.data,
            };

        case 'REQUEST_STATUS_FORM_TEMPLATE':
            return {
                ...state,
                status_request_form_template: action.status,
            };

        case 'PROMOTIOM_SINGLE_FLAT':
            return {
                ...state,
                list: state.list.filter((item) => {
                    if (action.flat_id === item._id) {
                        item.promotion = item?.promotion ? false : true;
                    }

                    return item;
                }),
            };

        case 'UPDATE_REDIRECT_URL_FLAT':
            return {
                ...state,
                list: state.list.map((item) => {
                    if (action.id == item._id) {
                        item.redirect_url = action.redirect_url;
                    }
                    return item;
                }),
            };

        case 'UPDATE_FLAT_SOLD':
            return {
                ...state,
                list: state.list.map((item) => {
                    if (action.id == item._id) {
                        item.sold = item.sold ? !item.sold : 1;
                    }
                    return item;
                }),
            };

        case 'UPDATE_FLATS': {
            let updateFlatsMore =
                state.list == null
                    ? action.data
                    : [...state.list, ...action.data];

            return {
                ...state,
                list: updateFlatsMore,
            };
        }

        case 'CLEAR_FLATS':
            return {
                ...state,
                list: null,
            };

        case 'REMOVE_TEMPLATE':
            return {
                ...state,
                templates: state.templates.filter(
                    (template) => template._id != action.id
                ),
            };

        case 'UPDATE_TEMPLATES': {
            let updateTemplatesMore =
                state.templates == null
                    ? action.data
                    : [...state.templates, ...action.data];

            return {
                ...state,
                templates: updateTemplatesMore,
            };
        }

        case 'CLEAR_TEMPLATES':
            return {
                ...state,
                templates: null,
            };

        case 'REQUEST_FLATS_REALESTATE_STATUS':
            return {
                ...state,
                request_flats_realestate_status: action.state,
            };

        case 'UPDATE_COMPLEX_APARTMENTS_FOR_TEMPLATES':
            return {
                ...state,
                complexApartments: action.data,
            };

        case 'UPDATE_BLOCK_FOR_FLATS':
            return {
                ...state,
                blockInformation: action.data,
            };

        case 'UPDATE_POINTS_FLAT_FOR_BLOCK':
            return {
                ...state,
                flatsForBlock: [...state.flatsForBlock],
            };

        case 'RESET_FLATS_POINTS':
            return {
                ...state,
                flatsForBlock: state.flatsForBlock.map((flat) => {
                    return {
                        ...flat,
                        plan_photo_points: {
                            x: 0,
                            y: 0,
                        },
                    };
                }),
            };

        case 'REMOVE_SINGLE_FLAT':
            return {
                ...state,
                blockInformation: {
                    ...state?.blockInformation,
                    flats:
                        state?.blockInformation?.flats &&
                        state?.blockInformation?.flats.length
                            ? state?.blockInformation?.flats.filter(
                                  (flat) =>
                                      String(flat?._id) !== String(action.id)
                              )
                            : [],
                },
            };

        case 'UPDATE_FLATS_FOR_BLOCK':
            return {
                ...state,
                flatsForBlock: action.data,
            };

        case 'REQUEST_FLATS_STATUS':
            return {
                ...state,
                request_flats_status: action.status,
            };

        case 'REQUEST_UPDATE_FLAT':
            return {
                ...state,
                request_update_flat: action.status,
            };

        default:
            return state;
    }
};

export default flats;
