import React from 'react';

const CreateSubCategory360 = () => {
	return (
		<div>
			<h4>CreateSubCategory360</h4>
		</div>
	)
}

export default CreateSubCategory360;