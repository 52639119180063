import CreditApplication from '@/Containers/Admin/Crediting/applications';
import CreditBanks from '@/Containers/Admin/Crediting/banks';

export default [
    {
        path: '/crediting/applications',
        element: <CreditApplication />,
    },
    {
        path: '/crediting/banks',
        element: <CreditBanks />,
    },
];
