import React, { useEffect, useState } from 'react';
import styles from '../styles/index.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';

import CriteriaItems from './helpers/criteria';
import UnitItems from './helpers/units';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const ProductCriteria = () => {
    const [tab, set_tab] = useState('PRODUCT_CRITERIA');

    const dispatch = useDispatch();
    const stateLibrary = useSelector((state) => state.library);

    useEffect(() => {
        dispatch(api.library.getCriteria(tab));
    }, [tab]);

    const changeTab = (key) => {
        set_tab(key);
    };

    return (
        <div className={styles.mainBlock}>
            <div className={styles.header}>
                <h2 className="title-page">Настройка критериев</h2>
            </div>

            <div className={styles.contentCriteria}>
                <Tabs defaultActiveKey="PRODUCT_CRITERIA" onChange={changeTab}>
                    <TabPane tab="Материалы" key="PRODUCT_CRITERIA">
                        <CriteriaItems
                            api={api}
                            dispatch={dispatch}
                            styles={styles}
                            stateLibrary={stateLibrary}
                            type={tab}
                        />
                    </TabPane>
                    <TabPane tab="Еденица измерения" key="PRODUCT_UNIT">
                        <UnitItems
                            api={api}
                            dispatch={dispatch}
                            styles={styles}
                            stateLibrary={stateLibrary}
                            type={tab}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default ProductCriteria;
