import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {api} from '@/Services';
import styles from "../styles/index.module.scss";
import {Link} from "react-router-dom";
import Item from "../helpers/shop-item";

const ShopsLibrary = () => {

	const dispatch = useDispatch();
	const stateLibrary = useSelector(state => state.library);

	useEffect(()=>{
		dispatch(api.library.shops());
	}, [])

	return (
		<div className={styles.mainBlock}>
			<div className={styles.header}>
				<h2 className="title-page">Магазины</h2>
				<Link to="/library/shops/create">Добавить магазин</Link>
			</div>

			<div className={styles.content}>
				{
					stateLibrary?.shops && stateLibrary?.shops.length ?
						stateLibrary?.shops.map( item => <Item api={api} dispatch={dispatch} key={item?._id} item={item} /> )
						: <></>
				}
			</div>

		</div>
	)
}

export default ShopsLibrary;