import React, { memo, useEffect } from 'react';
import { Input, Select, Form, Button } from 'antd';
import { useSelector } from 'react-redux';
import styles from '../styles/index.module.scss';
import { SearchOutlined } from '@ant-design/icons';

const FilterRealestate = ({ onSearch }) => {
    const [form] = Form.useForm();

    const { data_filter_meta, request_status_filter_meta } = useSelector(state => state?.realestate);

    useEffect(()=>{
        onSearch({}, data_filter_meta ? false : true);
    }, []);

    const search = (values) => {
        onSearch({
            cities: JSON.stringify(values.cities),
            type_content: JSON.stringify(values.type_content),
            builders: JSON.stringify(values.builders),
            search_text: values.search_text,
        }, data_filter_meta ? false : true);
    }

    return (
        <div className={styles.filter}>
            <Form
                name="basic"
                size="large"
                form={form}
                onFinish={search}
                disabled={request_status_filter_meta}
                className={styles.form}
            >
                <div className={styles.formItems} >
                    <Form.Item name="cities" className={styles.formItem}>
                        <Select
                            className={styles.input}
                            mode="multiple"
                            placeholder="Выберите город"
                            dropdownStyle={{
                                zIndex: 9999,
                            }}
                            options={data_filter_meta?.cities.map((item)=>({
                                label: item?.title,
                                value: item?._id
                            }))}
                        />
                    </Form.Item>

                    <Form.Item name="type_content" className={styles.formItem}>
                        <Select
                            className={styles.input}
                            mode="multiple"
                            placeholder="Выберите тип контента"
                            dropdownStyle={{
                                zIndex: 9999,
                            }}
                            options={[{
                                label: 'Standart',
                                value: 'standart'
                            }, {
                                label: 'Custom',
                                value: 'custom'
                            }, {
                                label: 'Demo',
                                value: 'demo'
                            }]}
                        />
                    </Form.Item>

                    <Form.Item name="builders" className={styles.formItem}>
                        <Select
                            className={styles.input}
                            mode="multiple"
                            placeholder="Выберите застройщика"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            dropdownStyle={{
                                zIndex: 9999,
                            }}
                            options={data_filter_meta?.builders.map((item)=>({
                                label: item?.title,
                                value: item?._id
                            }))}
                        />
                    </Form.Item>

                    <Form.Item name="search_text" className={styles.formItem}>
                        <Input
                            className={styles.input}
                            placeholder="Поиск по названию жк"
                        />
                    </Form.Item>

                    <Form.Item className={styles.formItem}>
                        <Button 
                            className={styles.input} 
                            type="primary" 
                            htmlType="submit" 
                            icon={<SearchOutlined />}
                        >
                            Поиск
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default memo(FilterRealestate);
