"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dot = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var plot_1 = require("../../core/plot");
var constants_1 = require("./constants");
var dot_layer_1 = require("../../layers/dot-layer");
var layer_group_1 = require("../../core/layer/layer-group");
var Dot = /** @class */ (function (_super) {
    tslib_1.__extends(Dot, _super);
    function Dot() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图表类型
         */
        _this.type = plot_1.Plot.PlotType.Dot;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Dot.prototype.getDefaultOptions = function () {
        return Dot.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Dot.prototype.createLayers = function (source) {
        this.dotLayer = new dot_layer_1.DotLayer(tslib_1.__assign({ source: source }, (0, util_1.pick)(this.options, dot_layer_1.DotLayer.LayerOptionsKeys)));
        var layerGroup = new layer_group_1.LayerGroup([this.dotLayer]);
        if (this.options.label) {
            this.labelLayer = this.createLabelLayer(source, this.options.label, this.options);
            layerGroup.addLayer(this.labelLayer);
        }
        return layerGroup;
    };
    /**
     * 更新图层
     */
    Dot.prototype.updateLayers = function (options) {
        var dotLayerConfig = (0, util_1.pick)(options, dot_layer_1.DotLayer.LayerOptionsKeys);
        this.dotLayer.update(dotLayerConfig);
        this.updateLabelLayer(this.source, options.label, this.options, this.labelLayer);
    };
    /**
     * 实现 legend 配置项
     */
    Dot.prototype.getLegendOptions = function () {
        var colorLegendItems = this.dotLayer.getColorLegendItems();
        if (colorLegendItems.length !== 0) {
            return { type: 'category', items: colorLegendItems };
        }
        return {};
    };
    /**
     * 默认配置项
     */
    Dot.DefaultOptions = constants_1.DEFAULT_OPTIONS;
    return Dot;
}(plot_1.Plot));
exports.Dot = Dot;
