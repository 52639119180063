const initialState = {
    photo_apartment_complex: null,
    photo_apartment_complex_with_blocks: null,
    photo_block: null,

    request_upload_status: false,

    //files for flat
    MATERIAL_BUDGET_EXCEL: null,
    MATERIAL_BUDGET_PDF: null,
    FLAT_ARRANGEMENT_AUTOCAD: null,
    APARTMENT_MODEL_3DMAX: null,
    APARTMENT_DESIGN_REVIT: null,
    APARTMENT_DESIGN_PDF: null,
    PLAN_FLAT: null,
    APARTMENT_INTERIOR: null,
    PHOTO_PREVIEW: null,

    //360
    photo_360: null,
    photo_360_preview: null,
    layout_360: null,

    plan_floor: null,

    logo_builder: null,

    search_file_result: null,
    search_file_request: false,
};

const files = (state = initialState, action) => {
    switch (action.type) {
        case 'PHOTO_LAYOUT_360':
            return {
                ...state,
                layout_360: action.photo,
            };

        case 'SELECT_PREVIEW_PHOTO':
            return {
                ...state,
                APARTMENT_INTERIOR: state.APARTMENT_INTERIOR.map((photo) => {
                    if (photo?.id == action.id) {
                        photo.select = true;
                    } else {
                        photo.select = false;
                    }
                    return photo;
                }),
            };

        case 'DELETE_PHOTO_FROM_PHOTOS':
            return {
                ...state,
                APARTMENT_INTERIOR: state.APARTMENT_INTERIOR.filter((photo) => {
                    if (photo?.id != action.id) {
                        return photo;
                    }
                }),
            };

        case 'CHANGE_PHOTOS_REALESTATE__EDIT': {
            let getPhotosWithoutMain = state?.APARTMENT_INTERIOR.filter(
                (item) => item?.id != action.photo.id
            );
            let updatePhotos = [action.photo, ...getPhotosWithoutMain];

            return {
                ...state,
                APARTMENT_INTERIOR: updatePhotos,
            };
        }
        case 'CHANGE_PRIVEW_PHOTO_REALESTATE__EDIT':
            return {
                ...state,
                photo_apartment_complex: action.photo,
            };

        case 'SEARCH_FILE_LOGOVO_SERVER':
            return {
                ...state,
                search_file_result: action.files,
            };

        case 'SEARCH_FILE_REQUEST_LOGOVO_SERVER':
            return {
                ...state,
                search_file_request: action.status,
            };

        case 'LOGO_PREVIEW_BUILDER':
            return {
                ...state,
                logo_builder: action.photo,
            };

        case 'APARTMENT_PREVIEW_PHOTO':
            return {
                ...state,
                PHOTO_PREVIEW: action.photo,
            };

        case 'PHOTO_PLAN_FLOOR':
            return {
                ...state,
                plan_floor: action.photo,
            };

        case 'PHOTO_APARTMENT_360':
            return {
                ...state,
                photo_360: action.photo,
            };

        case 'PHOTO_APARTMENT_360_PREVIEW':
            return {
                ...state,
                photo_360_preview: action.photo,
            };

        case 'PHOTO_APARTMENT_COMPLEX':
            return {
                ...state,
                photo_apartment_complex: action.photo,
            };

        case 'PHOTO_COMPLEX_WITH_BLOCKS':
            return {
                ...state,
                photo_apartment_complex_with_blocks: action.photo,
            };

        case 'REQUEST_UPLOAD_FILE':
            return {
                ...state,
                request_upload_status: action.status,
            };

        case 'PHOTO_BLOCK':
            return {
                ...state,
                photo_block: action.photo,
            };

        case 'MATERIAL_BUDGET_EXCEL':
            return {
                ...state,
                MATERIAL_BUDGET_EXCEL: action.id,
            };
        case 'MATERIAL_BUDGET_PDF':
            return {
                ...state,
                MATERIAL_BUDGET_PDF: action.id,
            };
        case 'FLAT_ARRANGEMENT_AUTOCAD':
            return {
                ...state,
                FLAT_ARRANGEMENT_AUTOCAD: action.id,
            };
        case 'APARTMENT_MODEL_3DMAX':
            return {
                ...state,
                APARTMENT_MODEL_3DMAX: action.id,
            };
        case 'APARTMENT_DESIGN_REVIT':
            return {
                ...state,
                APARTMENT_DESIGN_REVIT: action.id,
            };
        case 'APARTMENT_DESIGN_PDF':
            return {
                ...state,
                APARTMENT_DESIGN_PDF: action.id,
            };
        case 'PLAN_FLAT':
            return {
                ...state,
                PLAN_FLAT: action.photo,
            };
        case 'APARTMENT_INTERIOR':
            return {
                ...state,
                APARTMENT_INTERIOR: state.APARTMENT_INTERIOR
                    ? [...state.APARTMENT_INTERIOR, action.photo]
                    : [action.photo],
            };
        case 'APARTMENT_INTERIOR_ARRAY':
            return {
                ...state,
                APARTMENT_INTERIOR: action.photos,
            };

        case 'DELETE_IMAGE_APARTMENT_INTERIOR': {
            let photos = [];
            state.APARTMENT_INTERIOR.map((photo, index) => {
                if (index != action.index) {
                    photos = [...photos, photo];
                }
            });

            return {
                ...state,
                APARTMENT_INTERIOR: photos && photos.length > 0 ? photos : null,
            };
        }

        default:
            return state;
    }
};

export default files;
