import React from 'react';
import styles from '../../styles/index.module.scss';
import randomKey from 'crypto-random-string';
import {CloseOutlined} from '@ant-design/icons';

const SelectCategory = ({
	categories = [],
	is_visible,
	close_modal,
	get_category
}) => {

	const select_category = (cat) => {
		close_modal();
		return get_category(cat);
	}

	const renderMarker = (cat) => {
		return cat.marker_type ? 'MО' : cat.premise ? 'П' : cat.premise_type ? 'МП' : 'K'
	}

	const renderChild = (items) => {

		if(items && items.length){
			return items.map((item)=>(
				<div className={`${styles.item} ${styles.itemChild}`} key={randomKey({length: 15})}>
					<p onClick={()=>select_category(item)}>{item?.title} ({renderMarker(item)})</p>
					{item?.child_categories && item?.child_categories.length ? renderChild(item?.child_categories) : <></>}
				</div>
			))
		}

	}

	return (
		<div className={`${styles.selectCategoryModal} ${is_visible ? styles.selectCategoryModalOpened : {}}`}>
			{
				categories && categories.length ?
					<div className={styles.listBlock}>
						<div className={styles.listBlockHeader}>
							<p>Выберите категорию</p>
							<CloseOutlined onClick={close_modal} />
						</div>
						<div className={styles.listBlockContent}>
							{renderChild(categories)}
						</div>
					</div>
				:
					<div>
						<h5>Категорий не найдено</h5>
					</div>
			}
		</div>
	)
}

export default SelectCategory;