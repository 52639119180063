import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Result } from 'antd';
import styles from '../../styles/custom-chess.module.scss';
import config from '@/Config';
import PointItem from './point-item';
import { calcCoords, updatePolygon, deleteCoord } from '../funcs/calc-coords';

const SelectBlock = ({ saveCoords }) => {
    const blockRef = useRef(null);
    const chessStateData = useSelector((state) => state?.chessData?.data);
    const { coords, polygon } = useSelector((state) => state?.chess);

    const [state_coords, set_state_coords] = useState(coords || []);
    const [state_polygon, set_state_polygon] = useState(polygon || '');

    useEffect(() => {
        const mousedownPoint = (event) => {
            let { points, areaPointsString } = calcCoords(
                event.layerX,
                event.layerY,
                blockRef.current.clientHeight,
                blockRef.current.clientWidth,
                state_coords
            );

            set_state_coords(points);
            set_state_polygon(areaPointsString);

            saveCoords(points, areaPointsString);
        };

        if (blockRef?.current) {
            blockRef.current.addEventListener('click', mousedownPoint);

            return () => {
                blockRef?.current?.removeEventListener('click', mousedownPoint);
            };
        }
    }, [blockRef?.current, state_coords]);

    const updateMovePoint = (points) => {
        let areaPointsString = updatePolygon(points);

        set_state_coords(points);
        set_state_polygon(areaPointsString);

        saveCoords(points, areaPointsString);
    };

    const deletePoint = (index) => {
        let { points, areaPointsString } = deleteCoord(state_coords, index);

        set_state_coords(points);
        set_state_polygon(areaPointsString);

        saveCoords(points, areaPointsString);
    };

    return (
        <>
            {chessStateData?.image_complex_with_all_blocks ? (
                <div className={styles.selectBlockContent}>
                    <div className={styles.selectBlockImage} ref={blockRef}>
                        <img
                            src={`${config.public}${chessStateData?.image_complex_with_all_blocks?.url}`}
                        />
                    </div>

                    {state_coords && state_coords.length ? (
                        state_coords.map((point, index) => (
                            <PointItem
                                key={index}
                                point={point}
                                index={index}
                                blockRef={blockRef}
                                coords={state_coords}
                                updateMovePoint={updateMovePoint}
                                deletePoint={deletePoint}
                            />
                        ))
                    ) : (
                        <></>
                    )}

                    {state_polygon && (
                        <div
                            className={styles.polygon}
                            style={{
                                clipPath: `polygon(${state_polygon})`,
                            }}
                        ></div>
                    )}
                </div>
            ) : (
                <Result
                    title="Понорамная фотография жк с блоками не загружена"
                    extra={null}
                />
            )}
        </>
    );
};

export default SelectBlock;
