"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DotDensity = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var plot_1 = require("../../core/plot");
var constants_1 = require("./constants");
var layer_group_1 = require("../../core/layer/layer-group");
var dot_density_layer_1 = require("../../layers/dot-density-layer");
var DotDensity = /** @class */ (function (_super) {
    tslib_1.__extends(DotDensity, _super);
    function DotDensity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 地图类型
         */
        _this.type = plot_1.Plot.PlotType.DotDensity;
        return _this;
    }
    /**
     * 获取默认配置
     */
    DotDensity.prototype.getDefaultOptions = function () {
        return DotDensity.DefaultOptions;
    };
    /**
     * 创建图层
     */
    DotDensity.prototype.createLayers = function (source) {
        this.dotDensityLayer = new dot_density_layer_1.DotDensityLayer(tslib_1.__assign({ source: source }, (0, util_1.pick)(this.options, dot_density_layer_1.DotDensityLayer.LayerOptionsKeys)));
        var layerGroup = new layer_group_1.LayerGroup([this.dotDensityLayer]);
        return layerGroup;
    };
    /**
     * 更新图层
     */
    DotDensity.prototype.updateLayers = function (options) {
        var bubbleLayerConfig = (0, util_1.pick)(options, dot_density_layer_1.DotDensityLayer.LayerOptionsKeys);
        this.dotDensityLayer.update(bubbleLayerConfig);
    };
    /**
     * 默认配置项
     */
    DotDensity.DefaultOptions = constants_1.DEFAULT_OPTIONS;
    return DotDensity;
}(plot_1.Plot));
exports.DotDensity = DotDensity;
