import React, { useState } from 'react';
import { Form, Typography, Upload, Space, Modal } from 'antd';
import styles from '../../../styles/index.module.scss';
import { useDispatch } from 'react-redux';
import { api } from '../../../../../../Services';
import { Image } from '../../../../../../Components';
import { InboxOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { Dragger } = Upload;

const BuilderFormItemLogo = () => {

    const dispatch = useDispatch();

    const form = Form.useFormInstance();
    const logo = Form.useWatch('logo', form);

    const [preview_image, set_preview_image] = useState(null);

    const customRequest = async ({ file, onSuccess, data }) => {

        const getFile = await dispatch(api.files.standartUploadFile(file, data?.uploadType));

        let response = {};

        response = {
            success: true,
            file: {
                name: file.name,
                size: file.size,
                type: file.type,
                info: getFile
            },
        };

        onSuccess(response);
    };

    const getValueFromEvent = (info) => {

        let data = [];

        if(info?.fileList && info?.fileList.length){
            info?.fileList.forEach((item)=>{
                if(item?.status === 'done'){
                    data.push({
                        uid: item?.response?.file?.info?.id,
                        name: item?.response?.file?.info?.url,
                        status: 'done',
                        url: item?.response?.file?.info?.full_url,
                    });
                }
            })
        }

        return data;
    }

    const handlePreview = async (file) => {
        set_preview_image(file?.response?.file?.info?.full_url || file?.url);
    };

    return (
        <div className={styles.formSectionGroup}>

            <Text type="secondary">Логотип застройщика</Text>

            <Form.Item
                name="logo"
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
                getValueFromEvent={getValueFromEvent}
            >
                <Dragger
                    maxCount={1}
                    accept=".svg"
                    name='file'
                    headers={{
                        authorization: localStorage.getItem('SESSION_TOKEN'),
                    }}
                    defaultFileList={logo}
                    data={{
                        uploadType: 'DOCUMENT',
                        form_key: 'DOCUMENT'
                    }}
                    customRequest={({ file, onSuccess, data }) => customRequest({ file, onSuccess, data })}
                    listType="picture-card"
                    onPreview={handlePreview}
                > 
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>    
                    <Space direction="vertical">          
                        <Text>Выберите или перетащите изображение</Text>
                        <Text type="secondary">Доступны изображения формата svg</Text>
                    </Space>   
                </Dragger>
            </Form.Item>

            <Modal 
                open={preview_image ? true : false} 
                title="Просмотр изображения" 
                footer={null} 
                onCancel={()=>set_preview_image(null)}
                centered
                width="40%"
                destroyOnClose
            >
                <Image
                    src={preview_image}
                />
            </Modal>

        </div>
    )
}

export default BuilderFormItemLogo;