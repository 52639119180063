import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {FlipToBack} from '@material-ui/icons';
import {Input, Modal, Popconfirm} from 'antd';
import styles from '../../styles/index.module.scss';
import {DeleteOutlined} from '@ant-design/icons';

const FlatItem = ({ 
	item, 
	deleteFlat, 
	api, 
	dispatch,
	editAreaPointsFlat,
	saveEditAreaPointsFlat,
}) => {

	const [editStatus, setEditStatus] = useState(false);
	const [title, setTitle] = useState(item.title.replace(' ', ''));

	const [statusAreaPoints, setStatusAreaPoints] = useState(false);

	const edittitleEvent = () => {
		setEditStatus(false);

		let changeTitle = title.replace(/\s/g, '');

		item.title = changeTitle;
		setTitle(changeTitle)
		return dispatch(api.flats.editTitle(changeTitle, item._id));
	}

	return (
		<div className={`flat-main-item-child ${styles.itemFlatOfBlock}`}>

			<Modal
				title="Изменение номера квартиры"
				visible={editStatus}
				onOk={()=>edittitleEvent()}
				onCancel={()=>setEditStatus(false)}
			>
				<Input value={title} onChange={(val)=>setTitle(val.target.value)}/>
			</Modal>

			<h6 onClick={()=>{ setEditStatus(true) }}>{ item.title }</h6>

			<div className="flat-main-item-controlls">
				{
					statusAreaPoints ?
						<span onClick={()=>{
							setStatusAreaPoints(false);
							saveEditAreaPointsFlat(item._id);
						}} title="Удалить" className="block-icon-remove-flat button-icon-remove-flat-save-area-points">Сохранить</span>
					:
						<span onClick={()=>{
							setStatusAreaPoints(true);
							editAreaPointsFlat(item.area_points, item.area_points_for_css, item._id);
						}} title="Редактировать" className={`block-icon-remove-flat ${styles.editFlatPoints}`}><FlipToBack/></span>
				}
				<Popconfirm
					className={styles.deleteFlat}
					title="Квартира будет удалена?"
					onConfirm={()=>deleteFlat(item._id)}
					onCancel={()=>{}}
					okText="Удалить"
					cancelText="Нет">
					<DeleteOutlined className={styles.deleteFlatIcon} />
				</Popconfirm>
				<Link to={`/apartments/flats/${item.template}/edit`}>Редактирование информации</Link>
			</div>			
		</div>
	)

}

export default FlatItem;