import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';

import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import config from '@/Config';
class Apollo {
    constructor() {
        this.dev = process.env.NODE_ENV === 'development';
        this.wsURI = this.dev ? config.dev_graphqlWSURI : config.graphqlWSURI;
        this.gqlURI = this.dev ? config.dev_graphqlURI : config.graphqlURI;
        this.token = localStorage.getItem(config.TOKEN) || 'NONE';

        this.httpLink = new createUploadLink({
            uri: this.gqlURI,
        });
        this.wsLink = new GraphQLWsLink(
            createClient({
                url: this.wsURI,
                connectionParams: {
                    token: this.token,
                },
            })
        );

        this.link = null;
        this.authLink = null;
    }

    init() {
        this.setupLinks();
        return this.setupClient();
    }

    setupLinks() {
        this.link = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            this.wsLink,
            this.httpLink
        );

        this.authLink = new ApolloLink((operation, forward) => {
            operation.setContext({
                headers: {
                    authorization: this.token,
                },
            });
            return forward(operation);
        });
    }

    setupClient() {
        const client = new ApolloClient({
            link: this.authLink.concat(this.link),
            cache: new InMemoryCache(),
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                },
            },
        });

        return client;
    }
}

export default Apollo;
