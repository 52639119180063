import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';
import config from '../../../../Config';
import { Button, Image } from 'antd';
import FilesFlat from './Helpers/files/';
import Switch from 'react-input-switch';
import styles from '../styles/index.module.scss';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { Select, Input, Tooltip } from 'antd';

const { Option } = Select;

const CreateFlat = () => {
    const history = useNavigate();
    const [title, setTitle] = useState('');
    const [squareMetres, setSquareMetres] = useState('');
    const [countRooms, setCountRooms] = useState('');
    const [materialPrice, setMaterialPrice] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [squareMetrePrice, setSquareMetrePrice] = useState('');
    const [ceilingHeight, setCeilingHeight] = useState('');

    const [seoTitle, setSeoTitle] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');
    const [seoDescription, setSeoDescription] = useState('');

    const [commercial, set_commercial] = React.useState(0);

    const dispatch = useDispatch();
    const getFilesState = useSelector((state) => state.files);

    const getApartmentsState = useSelector((state) => state.apartments);
    const [apartmentComplex, setApartmentComplex] = useState([]);

    useEffect(() => {
        clearInformation();
        dispatch(api.apartments.getApartmentsComplex());
    }, []);

    const clearInformation = () => {
        dispatch({
            type: 'APARTMENT_PREVIEW_PHOTO',
            photo: null,
        });

        setTitle('');
        setSquareMetres('');
        setCountRooms('');
        setMaterialPrice('');
        setTotalPrice('');
        setSquareMetrePrice('');
        setCeilingHeight('');

        dispatch({
            type: 'MATERIAL_BUDGET_EXCEL',
            id: null,
        });
        dispatch({
            type: 'MATERIAL_BUDGET_PDF',
            id: null,
        });
        dispatch({
            type: 'FLAT_ARRANGEMENT_AUTOCAD',
            id: null,
        });
        dispatch({
            type: 'APARTMENT_MODEL_3DMAX',
            id: null,
        });
        dispatch({
            type: 'APARTMENT_DESIGN_REVIT',
            id: null,
        });
        dispatch({
            type: 'APARTMENT_DESIGN_PDF',
            id: null,
        });

        dispatch({
            type: 'PHOTO_PREVIEW',
            photo: null,
        });

        dispatch({
            type: 'PLAN_FLAT',
            photo: null,
        });

        dispatch({
            type: 'APARTMENT_INTERIOR_ARRAY',
            photos: null,
        });
    };

    const addFlat = async () => {
        let fields = {
            title,
            square_metres: squareMetres,
            count_rooms: countRooms,
            material_price: materialPrice,
            total_price: totalPrice,
            square_metre_price: squareMetrePrice,
            ceiling_height: ceilingHeight,
            files: [
                getFilesState.MATERIAL_BUDGET_EXCEL,
                getFilesState.MATERIAL_BUDGET_PDF,
                getFilesState.FLAT_ARRANGEMENT_AUTOCAD,
                getFilesState.APARTMENT_MODEL_3DMAX,
                getFilesState.APARTMENT_DESIGN_REVIT,
                getFilesState.APARTMENT_DESIGN_PDF,
            ],
            checkFiles: {
                MATERIAL_BUDGET_EXCEL: getFilesState.MATERIAL_BUDGET_EXCEL,
                MATERIAL_BUDGET_PDF: getFilesState.MATERIAL_BUDGET_PDF,
                FLAT_ARRANGEMENT_AUTOCAD:
                    getFilesState.FLAT_ARRANGEMENT_AUTOCAD,
                APARTMENT_MODEL_3DMAX: getFilesState.APARTMENT_MODEL_3DMAX,
                APARTMENT_DESIGN_REVIT: getFilesState.APARTMENT_DESIGN_REVIT,
                APARTMENT_DESIGN_PDF: getFilesState.APARTMENT_DESIGN_PDF,
            },
            plan_photo: getFilesState.PLAN_FLAT,
            preview_photo: getFilesState.PHOTO_PREVIEW,
            apartmentComplex,
            seoKeywords,
            seoTitle,
            seoDescription,
            commercial,
        };

        let { status } = await dispatch(api.flats.create(fields));

        if (status) {
            clearInformation();
            return history('/apartments/flats/templates');
        }
    };

    return (
        <content className="cities-page-block">
            <div className="create-flat-block-form">
                <div className="header-content-page">
                    <h1>Добавление шаблона</h1>
                </div>
                <div className="form-flat-block">
                    <div className="row">
                        <div className="col-md-8">
                            <section
                                className="input-section-form input-section-form-switch"
                                style={{ marginTop: 15 }}
                            >
                                <p>Коммерческая недвижимость</p>
                                <Switch
                                    value={commercial}
                                    onChange={set_commercial}
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        setTitle(val.target.value);
                                    }}
                                    placeholder="Название*"
                                    value={title}
                                    suffix={
                                        <Tooltip title="Название">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        setSquareMetres(val.target.value);
                                    }}
                                    value={squareMetres}
                                    placeholder="Квадратные метры"
                                    type="text"
                                    suffix={
                                        <Tooltip title="Квадратные метры">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        setCountRooms(val.target.value);
                                    }}
                                    value={countRooms}
                                    placeholder="Количество комнат"
                                    type="text"
                                    suffix={
                                        <Tooltip title="Количество комнат">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        setTotalPrice(val.target.value);
                                    }}
                                    value={totalPrice}
                                    placeholder="Стоимость"
                                    type="text"
                                    suffix={
                                        <Tooltip title="Стоимость">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        setSquareMetrePrice(val.target.value);
                                    }}
                                    value={squareMetrePrice}
                                    placeholder="Стоимость за квадратный метр"
                                    type="text"
                                    suffix={
                                        <Tooltip title="Стоимость за квадратный метр">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </section>

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                <Input
                                    size="large"
                                    onChange={(val) => {
                                        setCeilingHeight(val.target.value);
                                    }}
                                    value={ceilingHeight}
                                    placeholder="Высота потолка"
                                    type="text"
                                    suffix={
                                        <Tooltip title="Высота потолка">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </section>

                            {!commercial && (
                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <Input
                                        size="large"
                                        onChange={(val) => {
                                            setMaterialPrice(val.target.value);
                                        }}
                                        value={materialPrice}
                                        placeholder="Стоимость за материал"
                                        type="text"
                                        suffix={
                                            <Tooltip title="Стоимость за материал">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </section>
                            )}

                            <section
                                className="input-section-form"
                                style={{ marginTop: 15 }}
                            >
                                {getApartmentsState.request_apartments_status ? (
                                    <h6>Загружается список жилых комплексов</h6>
                                ) : getApartmentsState.apartments ? (
                                    <Select
                                        showSearch={true}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                                0 ||
                                            option.props.value
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                                0
                                        }
                                        optionFilterProp="children"
                                        size="large"
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Выберите жк"
                                        onChange={(val) => {
                                            setApartmentComplex(val);
                                        }}
                                        value={apartmentComplex}
                                        optionLabelProp="label"
                                    >
                                        {getApartmentsState?.apartments.map(
                                            (item) => {
                                                return (
                                                    <Option
                                                        value={item?._id}
                                                        label={item?.title}
                                                    >
                                                        {item.title}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                ) : (
                                    <h5>Жилых комплексов не найдено</h5>
                                )}
                            </section>

                            <div className="line-block"></div>
                            <FilesFlat />
                        </div>

                        <div className="col-md-4">
                            <div className="plan-block-file">
                                <h4>Планировка квартиры</h4>
                                {getFilesState.request_upload_status ? (
                                    <Button
                                        type="primary"
                                        className={styles.buttonLoad}
                                    >
                                        <h4>
                                            Загружаем...{' '}
                                            <LoadingOutlined
                                                className={
                                                    styles.buttonLoadIcon
                                                }
                                            />
                                        </h4>
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        className={styles.buttonLoad}
                                    >
                                        <h4>Загрузить файл</h4>
                                        <input
                                            type="file"
                                            onChange={(val) => {
                                                dispatch(
                                                    api.files.uploadFile(
                                                        val.target.files[0],
                                                        'svg-image'
                                                    )
                                                );
                                            }}
                                        />
                                    </Button>
                                )}
                                {getFilesState.PLAN_FLAT ? (
                                    <Image
                                        src={
                                            config.public +
                                            getFilesState.PLAN_FLAT.url
                                        }
                                    />
                                ) : (
                                    <div></div>
                                )}
                            </div>

                            <div className="plan-block-file">
                                <h4>Превью квартиры</h4>
                                {getFilesState.request_upload_status ? (
                                    <Button
                                        type="primary"
                                        className={styles.buttonLoad}
                                    >
                                        <h4>
                                            Загружаем...{' '}
                                            <LoadingOutlined
                                                className={
                                                    styles.buttonLoadIcon
                                                }
                                            />
                                        </h4>
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        className={styles.buttonLoad}
                                    >
                                        <h4>Загрузить файл</h4>
                                        <input
                                            type="file"
                                            onChange={(val) => {
                                                dispatch(
                                                    api.files.uploadFile(
                                                        val.target.files[0],
                                                        'IMAGE_PREVIEW'
                                                    )
                                                );
                                            }}
                                        />
                                    </Button>
                                )}
                                {getFilesState.PHOTO_PREVIEW ? (
                                    <Image
                                        src={`${config.public}${getFilesState.PHOTO_PREVIEW.url}`}
                                    />
                                ) : (
                                    <div></div>
                                )}
                            </div>

                            <div className="plan-block-file seo-block-form">
                                <h4>SEO</h4>
                                <Input
                                    size="large"
                                    type="text"
                                    value={seoKeywords}
                                    onChange={(val) =>
                                        setSeoKeywords(val.target.value)
                                    }
                                    placeholder="Keywords"
                                    suffix={
                                        <Tooltip title="Keywords">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                                <Input
                                    size="large"
                                    type="text"
                                    value={seoDescription}
                                    onChange={(val) =>
                                        setSeoDescription(val.target.value)
                                    }
                                    placeholder="Description"
                                    suffix={
                                        <Tooltip title="Description">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                                <Input
                                    size="large"
                                    type="text"
                                    value={seoTitle}
                                    onChange={(val) =>
                                        setSeoTitle(val.target.value)
                                    }
                                    placeholder="Title"
                                    suffix={
                                        <Tooltip title="Title">
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.45)',
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Button type="primary" onClick={addFlat.bind(undefined)}>
                    Добавить
                </Button>
            </div>
        </content>
    );
};

export default CreateFlat;
