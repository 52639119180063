import { gql } from '@apollo/client';

const userSubscription = gql`
    subscription ($test: String) {
        getUserSub(test: $test) {
            _id
        }
    }
`;

const recentEvent = gql`
    subscription ($user_id: String) {
        recentEvent(user_id: $user_id) {
            _id
            text
        }
    }
`;

const getUser = gql`
    query {
        getUser {
            _id
            email
            role {
                type
                title
            }
        }
    }
`;

export default {
    userSubscription,
    getUser,
    recentEvent
};
