import { moment } from '../../../../../Services';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const builderColumns = () => [
    {
        title: 'Наименование',
        dataIndex: 'title',
    },
    {
        title: 'Дата создания',
        render: (item) => {
            return moment(item?.createdAt).format('DD MMMM YYYYг.');
        }
    },
    {
        title: 'Действия',
        render: (item) => {
            return (
                <Link to={`/builders/form?builder_id=${item._id}`}>
                    <Button
                        title="Редактировать"
                        size='small'
                        type='dashed'
                    >
                        <EditOutlined/>
                    </Button>
                </Link>
            )
        },
        width: 130
    },
];