import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Link} from 'react-router-dom';

import Icon from '@mdi/react';
import {mdiClose} from '@mdi/js';

import {Delete, Edit} from '@material-ui/icons';

import {api} from '../../../Services/';
import moment from 'moment';

import SettingItem from './Helpers/setting-section';

const Pages = () => {

	const dispatch = useDispatch();
	const getPages = useSelector(state => state.pages);

	const [modalSetting, setModalSetting] = useState(false);


	useEffect(() => {

		dispatch({
			type: 'UPDATE_PAGE_BY_ID',
			data: null
		});

		dispatch(api.pages.get());

	}, []);

	const removePage = (id) => {
		dispatch(api.pages.delete(id));
	}

	return (
		<div>

			<div
				className={modalSetting ? 'modal-setting-for-pages modal-setting-for-pages-open' : 'modal-setting-for-pages'}>
				<div className="modal-setting-for-pages-content">
					<div className="modal-setting-for-pages-content-head">
						<h5>Основные настройки для страниц</h5>
						<span onClick={() => setModalSetting(!modalSetting)}><Icon size={1}
																				   className="modal-setting-for-pages-content-head-icon-close"
																				   path={mdiClose}/></span>
					</div>
					<div className="modal-setting-for-pages-content-body">

						<SettingItem modalSetting={modalSetting} api={api} dispatch={dispatch}
									 title="SEO настройки для главной страницы" type="SEO_HOME"/>
						<SettingItem modalSetting={modalSetting} api={api} dispatch={dispatch}
									 title="SEO настройки для страницы 'О логово'" type="ABOUT_LOGOVO"/>
						<SettingItem modalSetting={modalSetting} api={api} dispatch={dispatch}
									 title="SEO настройки для страницы 'Связаться с Logovo'"
									 type="CONTACT_LOGOVO"/>
						<SettingItem modalSetting={modalSetting} api={api} dispatch={dispatch}
									 title="SEO настройки для страницы 'Для застройщика'"
									 type="BUILDERS_SEO_PAGE_LOGOVO"/>
						<SettingItem modalSetting={modalSetting} api={api} dispatch={dispatch}
									 title="SEO настройки для страницы 'Для рекламодателя на тур 360'"
									 type="ADV_SEO_PAGE_LOGOVO"/>
						<SettingItem modalSetting={modalSetting} api={api} dispatch={dispatch}
									 title="SEO настройки для страницы 'Застройщики Казахстана'"
									 type="BUILDERS_OF_KAZAKHSTAN_SEO_PAGE_LOGOVO"/>
						<SettingItem modalSetting={modalSetting} api={api} dispatch={dispatch}
									 title="SEO настройки для страницы 'Hовости o новостройках Казахстана'"
									 type="NEWS_SEO_PAGE_LOGOVO"/>

					</div>
				</div>
			</div>

			<content>

				<div className="page-block-header">
					<h1 className="title-page">Страницы</h1>
					{
						!getPages.request_status ?
							<span
								onClick={() => setModalSetting(!modalSetting)}>Настройки</span>
							:
							<div></div>
					}
				</div>

				{
					getPages.request_status ?
						<h4>Загружаем информацию</h4>
						: getPages.list && getPages.list.length ?
							<div className="block-users-items">
								{
									getPages.list.map((item, index) => {

										let div = document.createElement('div');
										div.innerHTML = item.content;
										let firstImage = div.getElementsByTagName('img')[0]
										let imgSrc = firstImage ? firstImage.src : "";

										return (
											<div className="item-page" key={index} style={{
												background: `url(${imgSrc}) center / cover no-repeat`
											}}>
												<h1>{item.title}</h1>
												<div className="bottom-page-controll-block">
													<span>Создано: <b>{moment(item.createdAt).format('DD.MM.YYYY в hh:mm')}</b></span>
													<div
														className="bottoms-page-controll-block">
														<Delete
															onClick={() => removePage(item._id)}
															className="bottom-page-controll-block-icon bottom-page-controll-block-icon-remove"/>
														<Link to={`/pages/edit/${item._id}`}>
															<Edit
																className="bottom-page-controll-block-icon bottom-page-controll-block-icon-edit"/>
														</Link>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
							:
							<h5>Информации нет</h5>
				}

			</content>

		</div>
	)
}

export default Pages;