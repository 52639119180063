import React, { useEffect, useState } from 'react';
import styles from '../../styles/index.module.scss';
import { Button, Input, Select, Switch, TreeSelect } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import config from '@/Config';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

const { Option } = Select;

const CreateCategory = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const typeForm = query.get('type'); //shop

    const history = useNavigate();
    const dispatch = useDispatch();
    const stateLibrary = useSelector((state) => state.library);
    const stateFiles = useSelector((state) => state.files);
    const categories =
        typeForm == 'shop'
            ? stateLibrary?.shop_categories || null
            : stateLibrary?.tree_categories || null;

    const [title, set_title] = useState('');
    const [sub_category, set_sub_category] = useState(false);
    const [copy_category, set_copy_category] = useState(false);
    const [select_category, set_select_category] = useState(null);

    useEffect(() => {
        dispatch(api.library.treeCategories(typeForm == 'shop' ? 'SHOP' : ''));
    }, []);

    const addCategory = () => {
        dispatch(
            api.library.createCategory(
                {
                    title,
                    file: stateFiles.PHOTO_PREVIEW,
                    sub_category,
                    copy_category,
                    select_category,
                    type_form: typeForm,
                },
                history
            )
        );
    };

    return (
        <div className={styles.mainBlock}>
            <div className={styles.header}>
                <h2 className="title-page">Форма создания категории</h2>
            </div>

            <div className={styles.pageCategories}>
                <div className={styles.sectionForm}>
                    <Input
                        size="large"
                        value={title}
                        onChange={(value) => set_title(value.target.value)}
                        placeholder="Название категории"
                    />
                </div>

                <div className={styles.sectionForm}>
                    <div className={styles.optionForm}>
                        <div className={styles.optionFormSwitch}>
                            <p>Подкатегория</p>
                            <Switch
                                size="small"
                                defaultChecked={sub_category}
                                checked={sub_category}
                                onChange={(value) => {
                                    set_sub_category(value);
                                    set_select_category(null);
                                    set_copy_category(false);
                                }}
                            />
                        </div>

                        <div className={styles.optionFormSelect}>
                            {sub_category ? (
                                categories && categories.length ? (
                                    <TreeSelect
                                        virtual={false}
                                        size="large"
                                        treeDataSimpleMode
                                        style={{
                                            width: '100%',
                                        }}
                                        value={select_category}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                        }}
                                        placeholder="Выберите категорию"
                                        onChange={(value) =>
                                            set_select_category(value)
                                        }
                                        treeData={categories || []}
                                    />
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>

                {typeForm != 'shop' && (
                    <div className={styles.sectionForm}>
                        <div className={styles.optionForm}>
                            <div className={styles.optionFormSwitch}>
                                <p>Копирование категории</p>
                                <Switch
                                    size="small"
                                    defaultChecked={copy_category}
                                    checked={copy_category}
                                    onChange={(value) => {
                                        set_sub_category(false);
                                        set_select_category(null);
                                        set_copy_category(value);
                                    }}
                                />
                            </div>

                            <div className={styles.optionFormSelect}>
                                {copy_category ? (
                                    stateLibrary?.categories &&
                                    stateLibrary?.categories.length ? (
                                        <Select
                                            value={select_category}
                                            onChange={(value) =>
                                                set_select_category(value)
                                            }
                                            className={styles.formSectionSelect}
                                            filterOption={(input, option) =>
                                                option.children.includes(input)
                                            }
                                            showSearch
                                            size="large"
                                            placeholder="Выберите категорию"
                                        >
                                            {stateLibrary?.categories &&
                                            stateLibrary?.categories.length ? (
                                                stateLibrary?.categories.map(
                                                    (item) => (
                                                        <Option
                                                            key={item?._id}
                                                            value={item?._id}
                                                        >
                                                            {item?.title}
                                                        </Option>
                                                    )
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </Select>
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.sectionForm}>
                    <div className={styles.optionForm}>
                        {stateFiles?.request_upload_status ? (
                            <Button type="primary" className={styles.button}>
                                <LoadingOutlined />
                            </Button>
                        ) : (
                            <Button type="primary" className={styles.button}>
                                Иконка категории
                                <input
                                    type="file"
                                    onChange={(val) =>
                                        dispatch(
                                            api.files.uploadFile(
                                                val.target.files[0],
                                                'IMAGE_PREVIEW'
                                            )
                                        )
                                    }
                                />
                            </Button>
                        )}

                        {stateFiles?.PHOTO_PREVIEW ? (
                            <div
                                className={styles.imageBlock}
                                style={{
                                    background: `url(${config.public}${stateFiles?.PHOTO_PREVIEW?.url}) center / cover no-repeat`,
                                }}
                            ></div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <Button
                    type="primary"
                    className={styles.button}
                    onClick={addCategory}
                >
                    Добавить
                </Button>
            </div>
        </div>
    );
};

export default CreateCategory;
