import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../../../Config';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';

import FlatItem from './Helpers/flatItem';

import styles from '../styles/index.module.scss';
import { Popover } from 'antd';

const AddFloorsAndFlats = () => {
    const { id, complex_id } = useParams();
    const dispatch = useDispatch();
    const refPoint = useRef();

    const [height_plan, set_height_plan] = useState(50);

    const [floor, setFloor] = useState(1);

    const [title, setTitle] = useState('');

    const [copyFloorStatus, setCopyFloorStatus] = useState(false);
    const [copyFloor, setCopyFloor] = useState(1);

    const [statusAreaBlock, setStatusAreaBlock] = useState(false);
    const [blockAreas, setBlockAreas] = useState([]);
    const [areaPoints, setAreaPoints] = useState('');

    const [viewFlat, setViewFlat] = useState(null);

    const [downIndexPointStatus, setDownIndexPointStatus] = useState(false);
    const [downIndexPoint, setDownIndexPoint] = useState(null);

    const getStateBlock = useSelector((state) => state.flats);

    const getStateTemplates = useSelector((state) => state.flats.templates);
    const [template, setTemplate] = useState('none');

    const getStateFiles = useSelector((state) => state.files);

    const [statusEditAreaBlock, setEditStatusAreaBlock] = useState(false);
    const [EditBlockAreas, setEditBlockAreas] = useState([]);
    const [EditAreaPoints, setEditAreaPoints] = useState('');

    useEffect(() => {
        dispatch({
            type: 'CLEAR_TEMPLATES',
        });
        dispatch(api.blocks.getFloorByBlock(id, complex_id, floor));
        dispatch(api.flats.getTemplates(0, complex_id, false));
    }, []);

    const renderItemsFloor = (floors_count, statusCopy) => {
        if (statusCopy) {
            let flatsNum =
                Number(floors_count) -
                (Number(floors_count) - Number(floor)) -
                1;
            let flatArray = [];

            for (let y = 0; y < flatsNum; y++) {
                flatArray.push(y);
            }

            return flatArray.map((item, index) => {
                return (
                    <MenuItem style={{ fontSize: 17 }} value={index + 1}>
                        {index + 1}
                    </MenuItem>
                );
            });
        }

        let flatsNum = Number(floors_count);
        let flatArray = [];

        for (let y = 0; y < flatsNum; y++) {
            flatArray.push(y);
        }

        return flatArray.map((item, index) => {
            return (
                <MenuItem style={{ fontSize: 17 }} value={index + 1}>
                    {index + 1}
                </MenuItem>
            );
        });
    };

    const uploadPlanFloor = (files) => {
        dispatch(api.files.uploadFile(files[0], 'plan-floor'));
    };

    const addFloorInfo = () => {
        return dispatch(
            api.blocks.addFloorInfo(
                id,
                complex_id,
                floor,
                getStateFiles.plan_floor
            )
        );
    };

    const mathAreaPoints = (areas) => {
        let areaPointsString = '';
        setBlockAreas(areas);
        setEditBlockAreas(areas);
        areas.map((point) => {
            areaPointsString = areaPointsString + `${point[0]}% ${point[1]}%,`;
        });

        setAreaPoints(
            areaPointsString.substring(0, areaPointsString.length - 1)
        );
        setEditAreaPoints(
            areaPointsString.substring(0, areaPointsString.length - 1)
        );
    };

    const addPoint = (event) => {
        let posXnative = event.nativeEvent.offsetX;
        let posYnative = event.nativeEvent.offsetY;
        let blockHeight = document.getElementById(
            'area-block-panorama-select'
        ).clientHeight;
        let blockwidth = document.getElementById(
            'area-block-panorama-select'
        ).clientWidth;

        let percentX = (posXnative / blockwidth) * 100;
        let percentY = (posYnative / blockHeight) * 100;

        let newBlockAreas;

        if (statusEditAreaBlock) {
            newBlockAreas = [...EditBlockAreas, [percentX, percentY]];
            mathAreaPoints(newBlockAreas);
        } else {
            newBlockAreas = [...blockAreas, [percentX, percentY]];
            mathAreaPoints(newBlockAreas);
        }
    };

    const removePointFromAreas = (index) => {
        let resRemovePoint = window.confirm(
            'Вы дейтсвительно хотите удалить точку?'
        );

        if (resRemovePoint) {
            let newBlockAreas = [];

            if (statusEditAreaBlock) {
                EditBlockAreas.map((point, indexPoint) => {
                    if (indexPoint != index) {
                        newBlockAreas = [...newBlockAreas, point];
                    }
                });
                mathAreaPoints(newBlockAreas);
            } else {
                blockAreas.map((point, indexPoint) => {
                    if (indexPoint != index) {
                        newBlockAreas = [...newBlockAreas, point];
                    }
                });
                mathAreaPoints(newBlockAreas);
            }
        }
    };

    const moveMousePoint = (event) => {
        if (downIndexPointStatus) {
            let posXnative = event.nativeEvent.offsetX;
            let posYnative = event.nativeEvent.offsetY;
            let blockHeight = document.getElementById(
                'area-block-panorama-select'
            ).clientHeight;
            let blockwidth = document.getElementById(
                'area-block-panorama-select'
            ).clientWidth;

            let percentX = (posXnative / blockwidth) * 100;
            let percentY = (posYnative / blockHeight) * 100;

            if (statusEditAreaBlock) {
                EditBlockAreas[downIndexPoint] = [percentX, percentY];
                mathAreaPoints(EditBlockAreas);
            } else {
                blockAreas[downIndexPoint] = [percentX, percentY];
                mathAreaPoints(blockAreas);
            }
        }
    };

    const createFlatWithPoints = () => {
        dispatch(
            api.flats.createwithPoints({
                block_id: id,
                apartment_complex_id: complex_id,
                area_points: blockAreas,
                area_points_for_css: areaPoints,
                floor,
                title,
                template,
            })
        );

        setStatusAreaBlock(!statusAreaBlock);
        setBlockAreas([]);
        setAreaPoints('');
        setTitle('');
    };

    const copyFloorEvent = () => {
        dispatch(
            api.flats.copyFloor({
                block_id: id,
                apartment_complex_id: complex_id,
                floor,
                copyFloor,
            })
        );
    };

    const deleteFlat = (id) => {
        dispatch(
            api.flats.delete(id, {
                block_id: id,
                apartment_complex_id: complex_id,
                floor,
            })
        );
    };

    const saveEditAreaPointsFlat = (idFlat) => {
        setEditStatusAreaBlock(false);
        setEditBlockAreas([]);
        setEditAreaPoints('');

        dispatch(
            api.flats.updateFlatAreaPoints(
                idFlat,
                EditBlockAreas,
                EditAreaPoints
            )
        );
    };

    return (
        <div>
            {getStateBlock.blockInformation ? (
                <content className="cities-page-block">
                    <h1>Управление планировками этажей</h1>
                    <p>
                        ЖК {getStateBlock.blockInformation.complex_id.title},
                        блок {getStateBlock.blockInformation.title}
                    </p>

                    <section
                        className="input-section-form"
                        style={{ marginTop: 15 }}
                    >
                        <FormControl className="select-option-style">
                            <InputLabel
                                style={{ fontSize: 17 }}
                                htmlFor="age-native-simple"
                            >
                                Выберите этаж*
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={floor}
                                fullWidth
                                onChange={(val) => {
                                    setFloor(val.target.value);
                                    dispatch(
                                        api.blocks.getFloorByBlock(
                                            id,
                                            complex_id,
                                            val.target.value
                                        )
                                    );
                                }}
                                style={{ fontSize: 17 }}
                            >
                                {renderItemsFloor(
                                    getStateBlock.blockInformation.floors_count
                                )}
                            </Select>
                        </FormControl>
                    </section>

                    <div className="line-main-full-width"></div>

                    <section
                        className="input-section-form section-upload-floor-image"
                        style={{ marginTop: 15 }}
                    >
                        <h5>
                            Загрузить или изменить фотографию планировки этажа
                        </h5>
                        {getStateFiles.request_upload_status ? (
                            <h3>Загружаем</h3>
                        ) : (
                            <div className="upload-floor-image-block">
                                <input
                                    type="file"
                                    onChange={(val) => {
                                        uploadPlanFloor(val.target.files);
                                    }}
                                />
                            </div>
                        )}
                        {getStateBlock.request_flats_status ? (
                            <h3>Подождите...</h3>
                        ) : getStateFiles.plan_floor ? (
                            <div className="plan-preview-photo-floor-main-block">
                                <div
                                    className="plan-preview-photo-floor"
                                    style={{
                                        background: `url(${getStateFiles.plan_floor.full_url}) left / contain no-repeat`,
                                    }}
                                ></div>
                                <h5
                                    onClick={() => {
                                        addFloorInfo();
                                    }}
                                >
                                    Прикрепить фотографию
                                </h5>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </section>

                    <div className="line-main-full-width"></div>

                    {getStateBlock.blockInformation.floor_info ? (
                        <div className="section-with-plan-floor">
                            {statusAreaBlock ? (
                                <span
                                    onClick={() => {
                                        setStatusAreaBlock(!statusAreaBlock);
                                        setBlockAreas([]);
                                        setAreaPoints('');
                                    }}
                                >
                                    Сбросить
                                </span>
                            ) : (
                                <span
                                    onClick={() => {
                                        setStatusAreaBlock(!statusAreaBlock);
                                    }}
                                >
                                    Добавить квартиру
                                </span>
                            )}

                            {Number(floor) > 1 ? (
                                <div>
                                    {copyFloorStatus ? (
                                        <span
                                            style={{ marginLeft: 15 }}
                                            onClick={() => {
                                                setCopyFloorStatus(
                                                    !copyFloorStatus
                                                );
                                            }}
                                        >
                                            Копировать квартиры с этажа
                                            (закрыть)
                                        </span>
                                    ) : (
                                        <span
                                            style={{ marginLeft: 15 }}
                                            onClick={() => {
                                                setCopyFloorStatus(
                                                    !copyFloorStatus
                                                );
                                            }}
                                        >
                                            Копировать квартиры с этажа
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <div></div>
                            )}

                            {copyFloorStatus && Number(floor) > 1 && (
                                <div className={`copy-floor-block`}>
                                    <h6>Копирование этажа</h6>
                                    <hr />
                                    <p>Выберите этаж и нажмите копировать</p>

                                    <section
                                        className="input-section-form"
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormControl className="select-option-style">
                                            <InputLabel
                                                style={{ fontSize: 17 }}
                                                htmlFor="age-native-simple"
                                            >
                                                Выберите этаж*
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={copyFloor}
                                                fullWidth
                                                onChange={(val) => {
                                                    setCopyFloor(
                                                        val.target.value
                                                    );
                                                }}
                                                style={{ fontSize: 17 }}
                                            >
                                                {renderItemsFloor(
                                                    getStateBlock
                                                        .blockInformation
                                                        .floors_count,
                                                    true
                                                )}
                                            </Select>
                                        </FormControl>
                                    </section>

                                    <button
                                        onClick={() => {
                                            copyFloorEvent();
                                        }}
                                    >
                                        Копировать
                                    </button>
                                </div>
                            )}

                            <div className={`${styles.blockPlanImage}`}>
                                <Popover
                                    content="Высота планировки относительно высоты монитора"
                                    title=""
                                >
                                    <div className={styles.optionsWidth}>
                                        <span
                                            onClick={() => set_height_plan(50)}
                                            className={
                                                height_plan == 50
                                                    ? styles.activeOption
                                                    : {}
                                            }
                                        >
                                            50%
                                        </span>
                                        <span
                                            onClick={() => set_height_plan(75)}
                                            className={
                                                height_plan == 75
                                                    ? styles.activeOption
                                                    : {}
                                            }
                                        >
                                            75%
                                        </span>
                                        <span
                                            onClick={() => set_height_plan(100)}
                                            className={
                                                height_plan == 100
                                                    ? styles.activeOption
                                                    : {}
                                            }
                                        >
                                            100%
                                        </span>
                                    </div>
                                </Popover>
                            </div>

                            <section
                                className="blocks-content-visual"
                                style={{
                                    width: `${height_plan}%`,
                                }}
                            >
                                <div className="main-block-with-pamorama">
                                    <div
                                        id="main-photo-panorama"
                                        className={`main-photo-panorama`}
                                    >
                                        {viewFlat ? (
                                            <div
                                                style={{
                                                    clipPath: `polygon(${viewFlat.area_points_for_css})`,
                                                }}
                                                id="area-block-panorama-select"
                                                className="area-block-panorama-select-background area-block-panorama-select"
                                            ></div>
                                        ) : (
                                            <div></div>
                                        )}

                                        <img
                                            src={`${config.public}${getStateBlock.blockInformation.floor_info.photo.url}`}
                                        />

                                        <div
                                            className="event-points-block"
                                            onClick={(event) => {
                                                if (
                                                    statusAreaBlock ||
                                                    statusEditAreaBlock
                                                ) {
                                                    return addPoint(
                                                        event,
                                                        'default'
                                                    );
                                                }
                                            }}
                                            onMouseMove={(e) => {
                                                return moveMousePoint(e);
                                            }}
                                        ></div>

                                        {statusAreaBlock &&
                                        blockAreas &&
                                        blockAreas.length ? (
                                            blockAreas.map((point, index) => {
                                                return (
                                                    <div
                                                        onDoubleClick={() => {
                                                            return removePointFromAreas(
                                                                index
                                                            );
                                                        }}
                                                        ref={refPoint}
                                                        onMouseDown={() => {
                                                            setDownIndexPointStatus(
                                                                true
                                                            );
                                                            setDownIndexPoint(
                                                                index
                                                            );
                                                        }}
                                                        onMouseUp={() => {
                                                            setDownIndexPointStatus(
                                                                false
                                                            );
                                                            setDownIndexPoint(
                                                                null
                                                            );
                                                        }}
                                                        key={index}
                                                        className="dot-point-area"
                                                        style={{
                                                            left: `${point[0]}%`,
                                                            top: `${point[1]}%`,
                                                        }}
                                                    ></div>
                                                );
                                            })
                                        ) : (
                                            <div></div>
                                        )}

                                        {statusEditAreaBlock &&
                                        EditBlockAreas &&
                                        EditBlockAreas.length ? (
                                            EditBlockAreas.map(
                                                (point, index) => {
                                                    return (
                                                        <div
                                                            onDoubleClick={() => {
                                                                return removePointFromAreas(
                                                                    index
                                                                );
                                                            }}
                                                            ref={refPoint}
                                                            onMouseDown={() => {
                                                                setDownIndexPointStatus(
                                                                    true
                                                                );
                                                                setDownIndexPoint(
                                                                    index
                                                                );
                                                            }}
                                                            onMouseUp={() => {
                                                                setDownIndexPointStatus(
                                                                    false
                                                                );
                                                                setDownIndexPoint(
                                                                    null
                                                                );
                                                            }}
                                                            key={index}
                                                            className="dot-point-area"
                                                            style={{
                                                                left: `${point[0]}%`,
                                                                top: `${point[1]}%`,
                                                            }}
                                                        ></div>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div></div>
                                        )}

                                        {statusAreaBlock ? (
                                            <div
                                                onMouseMove={(e) => {
                                                    if (statusAreaBlock) {
                                                        return moveMousePoint(
                                                            e
                                                        );
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    if (statusAreaBlock) {
                                                        return addPoint(
                                                            event,
                                                            'area'
                                                        );
                                                    }
                                                }}
                                                id="area-block-panorama-select"
                                                className="area-block-panorama-select-background area-block-panorama-select"
                                                style={{
                                                    clipPath: `polygon(${areaPoints})`,
                                                }}
                                            ></div>
                                        ) : (
                                            <div></div>
                                        )}

                                        {statusEditAreaBlock ? (
                                            <div
                                                onMouseMove={(e) => {
                                                    if (statusEditAreaBlock) {
                                                        return moveMousePoint(
                                                            e
                                                        );
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    if (statusEditAreaBlock) {
                                                        return addPoint(
                                                            event,
                                                            'area'
                                                        );
                                                    }
                                                }}
                                                id="area-block-panorama-select"
                                                className="area-block-panorama-select-background area-block-panorama-select"
                                                style={{
                                                    clipPath: `polygon(${EditAreaPoints})`,
                                                }}
                                            ></div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                            </section>

                            {statusAreaBlock ? (
                                <section
                                    className="input-section-form"
                                    style={{ marginTop: 15 }}
                                >
                                    <TextField
                                        fullWidth
                                        onChange={(val) => {
                                            setTitle(val.target.value);
                                        }}
                                        id="standard-basic"
                                        label="Номер квартиры*"
                                        inputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                        }}
                                        value={title}
                                        InputLabelProps={{
                                            style: { fontSize: 16 },
                                        }}
                                    />
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={template}
                                        fullWidth
                                        onChange={(val) => {
                                            setTemplate(val.target.value);
                                        }}
                                        style={{
                                            fontSize: 17,
                                            marginTop: 15,
                                        }}
                                    >
                                        <MenuItem
                                            style={{ fontSize: 17 }}
                                            value="none"
                                        >
                                            Выберите шаблон для квартиры
                                        </MenuItem>
                                        {getStateTemplates &&
                                        getStateTemplates.length ? (
                                            getStateTemplates.map((item) => {
                                                return (
                                                    <MenuItem
                                                        style={{ fontSize: 17 }}
                                                        value={item._id}
                                                    >
                                                        {item.title}{' '}
                                                        {item.commercial
                                                            ? `(Коммерческая недвижимость)`
                                                            : ``}
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <MenuItem style={{ fontSize: 17 }}>
                                                Шаблонов не найдено
                                            </MenuItem>
                                        )}
                                    </Select>
                                </section>
                            ) : (
                                <div></div>
                            )}

                            {statusAreaBlock ? (
                                <span
                                    onClick={() => {
                                        createFlatWithPoints();
                                    }}
                                >
                                    Добавить квартиру
                                </span>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    ) : (
                        <h5>Планировка этажа не загружена.</h5>
                    )}

                    <div
                        className="line-main-full-width"
                        style={{
                            marginTop: 20,
                        }}
                    ></div>

                    <div
                        className="list-flats-block"
                        style={{
                            marginBottom: 25,
                        }}
                    >
                        <h4>Список квартир на этаже {floor}</h4>
                        {getStateBlock.blockInformation.flats &&
                        getStateBlock.blockInformation.flats.length ? (
                            getStateBlock.blockInformation.flats.map(
                                (item, index) => {
                                    return (
                                        <div
                                            onMouseEnter={() =>
                                                setViewFlat(item)
                                            }
                                            onMouseLeave={() =>
                                                setViewFlat(null)
                                            }
                                            className="flat-main-item"
                                            key={index}
                                        >
                                            <FlatItem
                                                api={api}
                                                dispatch={dispatch}
                                                item={item}
                                                deleteFlat={(id) => {
                                                    deleteFlat(id);
                                                }}
                                                editAreaPointsFlat={(
                                                    points,
                                                    pointsString
                                                ) => {
                                                    setEditStatusAreaBlock(
                                                        true
                                                    );
                                                    setEditBlockAreas(points);
                                                    setEditAreaPoints(
                                                        pointsString
                                                    );
                                                }}
                                                saveEditAreaPointsFlat={(
                                                    flatId
                                                ) =>
                                                    saveEditAreaPointsFlat(
                                                        flatId
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                }
                            )
                        ) : (
                            <h5>На данном этаже квартир не найдено</h5>
                        )}
                    </div>
                </content>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default AddFloorsAndFlats;
