import React, { useState } from 'react';
import { Input, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import styles from '../../../styles/index.module.scss';

const realestateTableColumns =  (customSort, cities, search_text) => ([
    {
        title: "№",
        key: "number",
        render(item, record, index) {
            return index + 1;
        },
        width: 75
    },
    {
        title: "Сортировочный номер",
        dataIndex: 'sort',
        width: 175
    },
    {
        title: "Наименование",
        render(item) {
            return (<>
                <Highlighter
                    searchWords={[search_text]}
                    autoEscape={true}
                    textToHighlight={item?.title}
                    highlightClassName={styles.Highlighter}
                />
            </>);
        },
    },
    {
        title: "Город",
        render(item) {
            return item?.city?.title;
        },
        filters: cities.map((item)=>({
            text: item?.title,
            value: item?.title,
        })),
        filterSearch: true
    },
    {
        title: "Переместить по номеру места",
        key: "custom_sort",
        render(item, rec, index) {
            return <InputSort id={item?._id} number={index + 1} customSort={customSort} />;
        },
        width: 225
    },
]);

const InputSort = ({
    customSort,
    number,
    id
}) => {

    const [num, set_num] = useState(number);

    return (
        <>
            <Tooltip title="Введите номер места, после нажмите Enter">
                <Input 
                    value={num}
                    onChange={(value)=>set_num(value?.target?.value)}
                    onPressEnter={()=>customSort(id, num - 1)}
                />
            </Tooltip>
        </>
    )
}

export default realestateTableColumns;