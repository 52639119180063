import axios from '../axios';
import { NotificationManager } from 'react-notifications';

const redirect = {
    getRedirects: (page, search_text, limit) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_REDIRECT_LINKS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/redirects', {
                params: {
                    page,
                    search_text,
                    limit,
                },
            });

            dispatch({
                type: 'UPDATE_REDIRECT_LINKS',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    create: (url, redirect_url, history) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_REDIRECT_LINKS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/redirects/create', {
                url,
                redirect_url,
            });

            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            return history('/redirects');
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            if (err.response.status == 422) {
                NotificationManager.error('Проверьте правильно ссылок');
                return;
            }

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    deleteRedirect: (id, type, ids) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_REDIRECT_LINKS',
            status: true,
        });

        try {
            let { data } = await axios.post('/v1/admin/redirects/delete', {
                id,
                type,
                ids,
            });

            dispatch({
                type: 'UPDATE_REDIRECT_LINKS',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            dispatch(redirect.getRedirects(1, '', 50));

            NotificationManager.success('Удалено');
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    editRedirect: (id, url, index) => async (dispatch) => {
        if (url == '') {
            NotificationManager.warning('Заполните поле');
            return;
        }

        dispatch({
            type: 'REQUEST_STATUS_REDIRECT_LINKS',
            status: true,
        });

        try {
            await axios.post('/v1/admin/redirects/edit', {
                id,
                url,
            });

            dispatch({
                type: 'UPDATE_REDIRECT_INDEX',
                index,
                url,
            });

            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            NotificationManager.success('Ссылка была изменена');
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    searchRedirects: (text) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_STATUS_REDIRECT_LINKS',
            status: true,
        });

        dispatch({
            type: 'CLEAR_LIST_REDIRECTS',
        });

        try {
            let { data } = await axios.post('/v1/admin/redirects/search', {
                text,
            });

            dispatch({
                type: 'SEARCH_REDIRECT_LINKS',
                data,
            });

            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_STATUS_REDIRECT_LINKS',
                status: false,
            });

            NotificationManager.error(
                'Упс, ошибка. Проверьте Ваши права администратора'
            );
        }
    },
};

export default redirect;
