"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plot = void 0;
var tslib_1 = require("tslib");
var map_1 = require("../map");
var utils_1 = require("../../utils");
var text_layer_1 = require("../../layers/text-layer");
var types_1 = require("../../types");
var source_1 = require("../../adaptor/source");
var util_1 = require("@antv/util");
var theme_1 = require("../../theme");
var DEFAULT_OPTIONS = {
    autoFit: false,
};
var Plot = /** @class */ (function (_super) {
    tslib_1.__extends(Plot, _super);
    function Plot(container, options) {
        var _this = this;
        if (typeof container === 'string' || container instanceof Element) {
            if (options === undefined) {
                throw new Error('options is undefined');
            }
            _this = _super.call(this, options) || this;
            _this.container = _this.createContainer(container);
            _this.theme = _this.createTheme();
            _this.scene = _this.createScene();
            _this.registerResources();
            _this.initSource();
        }
        else {
            _this = _super.call(this, container) || this;
        }
        return _this;
    }
    /**
     * 初始化数据
     */
    Plot.prototype.initSource = function () {
        this.source = this.createSource();
        this.render();
        this.inited = true;
    };
    /**
     * 初始化图层事件
     */
    Plot.prototype.initLayersEvent = function () {
        //
    };
    /**
     * 获取默认配置
     */
    Plot.prototype.getDefaultOptions = function () {
        return Plot.DefaultOptions;
    };
    /**
     * 创建 source 实例
     */
    Plot.prototype.createSource = function () {
        var _a = this.options.source, data = _a.data, aggregation = _a.aggregation, sourceCFG = tslib_1.__rest(_a, ["data", "aggregation"]);
        aggregation && source_1.MappingSource.aggregation(sourceCFG, aggregation);
        var source = new types_1.Source(data, sourceCFG);
        return source;
    };
    /**
     * 创建数据标签图层
     */
    Plot.prototype.createLabelLayer = function (source, label, plotLayerConfig) {
        var _a = plotLayerConfig || {}, visible = _a.visible, minZoom = _a.minZoom, maxZoom = _a.maxZoom, _b = _a.zIndex, zIndex = _b === void 0 ? 0 : _b;
        var textLayer = new text_layer_1.TextLayer(tslib_1.__assign({ name: 'labelLayer', visible: visible, minZoom: minZoom, maxZoom: maxZoom, zIndex: zIndex + 0.1, source: source }, label));
        return textLayer;
    };
    /**
     * 更新数据标签图层
     */
    Plot.prototype.updateLabelLayer = function (source, label, plotLayerConfig, labelLayer) {
        if (label) {
            if (labelLayer) {
                labelLayer.update(tslib_1.__assign({}, label));
            }
            else {
                labelLayer = this.createLabelLayer(source, label, plotLayerConfig);
                this.layerGroup.addLayer(labelLayer);
            }
        }
        else if (label === false) {
            labelLayer && this.layerGroup.removeLayer(labelLayer);
        }
    };
    /**
     * 渲染
     */
    Plot.prototype.render = function () {
        var _this = this;
        var layerGroup = this.createLayers(this.source);
        if (this.inited) {
            this.layerGroup.removeAllLayer();
            layerGroup.addTo(this.scene);
            this.layerGroup = layerGroup;
            // this.scene.render();
        }
        else {
            this.layerGroup = layerGroup;
            if (this.scene['sceneService'].loaded) {
                this.onSceneLoaded();
            }
            else {
                this.scene.once('loaded', function () {
                    _this.onSceneLoaded();
                });
            }
        }
        this.initLayersEvent();
    };
    /**
     * scene 加载成功回调
     */
    Plot.prototype.onSceneLoaded = function () {
        var _this = this;
        this.sceneLoaded = true;
        if (this.layerGroup.isEmpty()) {
            this.onLayersLoaded();
        }
        else {
            this.layerGroup.once('inited-all', function () {
                _this.onLayersLoaded();
            });
        }
        this.layerGroup.addTo(this.scene);
    };
    /**
     * 图层加载成功回调
     */
    Plot.prototype.onLayersLoaded = function () {
        this.layersLoaded = true;
        this.initComponents();
        this.loaded = true;
        this.emit('loaded');
    };
    /**
     * 挂载到容器
     * 用于高级图表 L7Plot 挂载单个图表示例
     */
    Plot.prototype.attachToScene = function (scene, theme) {
        this.scene = scene;
        this.theme = theme ? theme : (0, theme_1.getTheme)('default');
        this.registerResources();
        this.initSource();
    };
    /**
     * 取消挂载到容器上的 scene
     * 用于高级图表 L7Plot 挂载单个图表示例
     */
    Plot.prototype.unattachFromScene = function () {
        var _a;
        this.removeAllLayer();
        (_a = this.tooltip) === null || _a === void 0 ? void 0 : _a.destroy();
    };
    /**
     * 添加到容器
     * 用于 L7 Scene 与图表混合使用场景
     */
    Plot.prototype.addToScene = function (scene) {
        this.attachToScene(scene);
    };
    /**
     * 从容器上移除
     * 用于 L7 Scene 与图表混合使用场景
     */
    Plot.prototype.removeFromScene = function () {
        var _a;
        this.removeAllLayer();
        this.removeScaleControl();
        this.removeZoomControl();
        this.removeLayerMenuControl();
        this.removeLegendControl();
        (_a = this.tooltip) === null || _a === void 0 ? void 0 : _a.destroy();
    };
    /**
     * 更新: 更新配置且重新渲染
     */
    Plot.prototype.update = function (options) {
        this.updateOption(options);
        if (options.map && !(0, util_1.isEqual)(this.lastOptions.map, this.options.map)) {
            this.updateMap(options.map);
        }
        this.scene.setEnableRender(false);
        if (options.source && !(0, util_1.isEqual)(this.lastOptions.source, this.options.source)) {
            var _a = options.source, data = _a.data, sourceConfig = tslib_1.__rest(_a, ["data"]);
            this.changeData(data, sourceConfig);
        }
        // this.updateLayers(options);
        this.scene.setEnableRender(true);
        this.render();
        this.updateComponents();
        this.emit('update');
    };
    /**
     * 更新: 更新数据
     */
    Plot.prototype.changeData = function (data, cfg) {
        var _this = this;
        this.options.source = (0, utils_1.deepAssign)({}, this.options.source, tslib_1.__assign({ data: data }, cfg));
        var _a = this.options.source, aggregation = _a.aggregation, sourceCFG = tslib_1.__rest(_a, ["aggregation"]);
        aggregation && source_1.MappingSource.aggregation(sourceCFG, aggregation);
        this.source.setData(this.options.source.data, sourceCFG);
        // 更新 legend
        // TODO: 数据更新后，图层尚未执行更新，后续加图层 update 事件来解决
        var legend = this.options.legend;
        if (legend) {
            setTimeout(function () {
                _this.updateLegendControl(legend);
            });
        }
        this.emit('change-data');
    };
    /**
     * 默认的 options 配置项
     */
    Plot.DefaultOptions = DEFAULT_OPTIONS;
    /**
     * 地图图表类型
     */
    Plot.PlotType = types_1.PlotType;
    return Plot;
}(map_1.Map));
exports.Plot = Plot;
