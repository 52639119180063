import React, { useEffect, useState } from 'react';

import {
    Input,
    Slider,
    Checkbox,
    Tooltip,
    Button,
    Collapse,
    Radio,
} from 'antd';
import Card from './card';

const { Panel } = Collapse;

const FormEdit = ({
    id,
    widgetState,
    widget,
    dispatch,
    styles,
    api
}) => {
    const [title, set_title] = useState(widget?.title || '');
    const [description, set_description] = useState(widget?.description || '');
    const [colums, set_colums] = useState(widget?.colums || '');

    const [border_radius, set_border_radius] = useState(widget?.options?.border_radius || 0);
    const [width_border, set_width_border] = useState(widget?.options?.width_border || 1);
    const [color_border, set_color_border] = useState(widget?.options?.color_border || '');
    const [background_card, set_background_card] = useState(widget?.options?.background_card || '');
    const [color_text_r_and_s, set_color_text_r_and_s] = useState(widget?.options?.color_text_r_and_s || '');
    const [a_background_and_color_card, set_a_background_and_color_card] =
        useState(widget?.options?.a_background_and_color_card || '');
    const [status_count_rooms, set_status_count_rooms] = useState(widget?.options?.status_count_rooms || false);
    const [status_square_metres, set_status_square_metres] = useState(widget?.options?.status_square_metres || false);
    const [status_total_price, set_status_total_price] = useState(widget?.options?.status_total_price || false);
    const [status_square_metre_price, set_status_square_metre_price] =
        useState(widget?.options?.status_square_metre_price || false);
    const [status_apartment_number, set_status_apartment_number] =
        useState(widget?.options?.status_apartment_number || false);
    const [show_chess, set_show_chess] = useState(widget?.options?.show_chess || false);

    const [background, set_background] = useState(widget?.options?.background || '');
    const [room_buttons_gap, set_room_buttons_gap] = useState(widget?.options?.room_buttons_gap || 0);
    const [room_buttons_position, set_room_buttons_position] = useState(widget?.options?.room_buttons_position || 'left');
    const [
        room_buttons_horizontal_padding,
        set_room_buttons_horizontal_padding,
    ] = useState(widget?.options?.room_buttons_horizontal_padding || 0);

    const [show_logo_widget, set_show_logo_widget] = useState(widget?.options?.show_logo_widget || false);
    const [show_logo_tour, set_show_logo_tour] = useState(widget?.options?.show_logo_tour || false);
    const [show_popup_tour, set_show_popup_tour] = useState(widget?.options?.show_popup_tour || false);
    const [background_chess_block, set_background_chess_block] = useState(widget?.options?.background_chess_block || 'transparent');
    const [hide_buttons, set_hide_buttons] = useState(widget?.options?.hide_buttons || false);
    const [application_button, set_application_button] = useState(widget?.options?.application_button || false);

    const [chess_color_sold_flat, set_chess_color_sold_flat] = useState(widget?.options?.chess_color_sold_flat || 'transparent');
    const [chess_color_booked_flat, set_chess_color_booked_flat] = useState(widget?.options?.chess_color_booked_flat || 'transparent');
    const [chess_color_free_flat, set_chess_color_free_flat] = useState(widget?.options?.chess_color_free_flat || 'transparent');

    const [status_apartments_sold, set_status_apartments_sold] = useState(widget?.options?.status_apartments_sold || false);

    const [status_booking, set_status_booking] = useState(widget?.options?.status_booking || false);

    useEffect(() => {
        dispatch({
            type: 'SEARCH_PLANS',
            data: null,
        });

    }, []);

    const updateWidget = () => {
        let widgetOptions = {
            border_radius,
            width_border,
            color_border,
            background_card,
            color_text_r_and_s,
            a_background_and_color_card,
            status_count_rooms,
            status_square_metres,
            status_total_price,
            status_square_metre_price,
            show_chess,
            room_buttons_horizontal_padding,
            room_buttons_position,
            room_buttons_gap,
            background,
            show_popup_tour,
            show_logo_tour,
            show_logo_widget,
            background_chess_block,
            hide_buttons,
            application_button,
            chess_color_sold_flat,
            chess_color_booked_flat,
            chess_color_free_flat,
            status_apartment_number,
            status_apartments_sold,
            status_booking
        };

        return dispatch(
            api.widgets.editWidget(
                title,
                description,
                id,
                colums,
                widgetOptions
            )
        );
    };

    return (
        <div className={`statistic-block ${styles.pageWrapper}`}>

            <div className={styles.previewWidget}>
                <Card styles={styles} options={{
                    width_border,
                    status_total_price,
                    color_text_r_and_s,
                    status_square_metre_price,
                    status_count_rooms,
                    status_square_metres,
                    status_apartment_number,
                    a_background_and_color_card,
                    background_card,
                    color_border,
                    border_radius,
                    plan: null
                }} />
            </div>

            <div className={styles.inputs_block}>
                <Tooltip
                    zIndex={99999999}
                    trigger={['focus']}
                    title="Название виджета"
                    placement="topLeft"
                >
                    <Input
                        size="large"
                        type="text"
                        value={title}
                        onChange={(val) => set_title(val.target.value)}
                        placeholder="Название виджета"
                    />
                </Tooltip>
                <Tooltip
                    zIndex={99999999}
                    trigger={['focus']}
                    title="Описание виджета"
                    placement="topLeft"
                >
                    <Input
                        size="large"
                        type="text"
                        value={description}
                        onChange={(val) => set_description(val.target.value)}
                        placeholder="Описание виджета"
                    />
                </Tooltip>
                <Tooltip
                    zIndex={99999999}
                    trigger={['focus']}
                    title="Количество колонок"
                    placement="topLeft"
                >
                    <Input
                        size="large"
                        type="number"
                        value={colums}
                        onChange={(val) => set_colums(val.target.value)}
                        placeholder="Количество колонок"
                    />
                </Tooltip>
            </div>

            <Collapse>
                <Panel
                    forceRender={true}
                    header="Опции для карточек (планировки)"
                    key="1"
                >
                    <div className={styles.blockOptions}>
                        <div className={styles.blockContent}>
                            <div className={styles.itemOption}>
                                <p>Скругление углов</p>
                                <Slider
                                    value={border_radius}
                                    onChange={(value) =>
                                        set_border_radius(value)
                                    }
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Толщина бордера</p>
                                <Slider
                                    min={1}
                                    max={7}
                                    value={width_border}
                                    onChange={(value) =>
                                        set_width_border(value)
                                    }
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Фон карточки</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_background_card(value.target.value)
                                    }
                                    value={background_card}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Цвет бордера</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_color_border(value.target.value)
                                    }
                                    value={color_border}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={status_count_rooms}
                                    onChange={(val) =>
                                        set_status_count_rooms(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Количество комнат
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={status_square_metres}
                                    onChange={(val) =>
                                        set_status_square_metres(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Площадь
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={status_total_price}
                                    onChange={(val) =>
                                        set_status_total_price(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Стоимость квартиры
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={status_square_metre_price}
                                    onChange={(val) =>
                                        set_status_square_metre_price(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Стоимость за м²
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={status_apartment_number}
                                    onChange={(val) =>
                                        set_status_apartment_number(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Номер квартиры
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <p>Фон и цвет дополнительной информации</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_a_background_and_color_card(
                                            value.target.value
                                        )
                                    }
                                    value={a_background_and_color_card}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Цвет текста комнатности и помещения</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_color_text_r_and_s(
                                            value.target.value
                                        )
                                    }
                                    value={color_text_r_and_s}
                                />
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel forceRender={true} header="Опции для кнопок" key="2">
                    <div className={styles.blockOptions}>
                        <div className={styles.blockContent}>
                            <div className={styles.itemOption}>
                                <p>Gap (внешние отступы)</p>
                                <Slider
                                    min={0}
                                    max={40}
                                    value={room_buttons_gap}
                                    onChange={(value) =>
                                        set_room_buttons_gap(value)
                                    }
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>
                                    Horizontal (горизонтальные внутренние
                                    отступы)
                                </p>
                                <Slider
                                    min={0}
                                    max={30}
                                    value={room_buttons_horizontal_padding}
                                    onChange={(value) =>
                                        set_room_buttons_horizontal_padding(
                                            value
                                        )
                                    }
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Позиционирование кнопок</p>
                                <Radio.Group
                                    value={room_buttons_position}
                                    onChange={(val) =>
                                        set_room_buttons_position(
                                            val.target.value
                                        )
                                    }
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="left">
                                        Слева
                                    </Radio.Button>
                                    <Radio.Button value="center">
                                        Центр
                                    </Radio.Button>
                                    <Radio.Button value="right">
                                        Справа
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel forceRender={true} header="Шахматка" key="3">
                    <div className={styles.blockOptions}>
                        <div className={styles.blockContent}>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={show_chess}
                                    onChange={(val) =>
                                        set_show_chess(val.target.checked)
                                    }
                                >
                                    Показ шахматки
                                </Checkbox>
                            </div>  
                            <div className={styles.itemOption}>
                                <p>Фон блока</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_background_chess_block(
                                            value.target.value
                                        )
                                    }
                                    value={background_chess_block}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Проданная квартира (цвет ячейки)</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_chess_color_sold_flat(
                                            value.target.value
                                        )
                                    }
                                    value={chess_color_sold_flat}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Свободная квартира (цвет ячейки)</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_chess_color_free_flat(
                                            value.target.value
                                        )
                                    }
                                    value={chess_color_free_flat}
                                />
                            </div> 
                            <div className={styles.itemOption}>
                                <p>Забронированная квартира (цвет ячейки)</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_chess_color_booked_flat(
                                            value.target.value
                                        )
                                    }
                                    value={chess_color_booked_flat}
                                />
                            </div> 
                        </div>
                    </div>
                </Panel>
                <Panel forceRender={true} header="Дополнительные опции" key="4">
                    <div className={styles.blockOptions}>
                        <div className={styles.blockContent}>
                            <div className={styles.itemOption}>
                                <p>Основной фон виджета</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_background(value.target.value)
                                    }
                                    value={background}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={show_logo_widget}
                                    onChange={(val) =>
                                        set_show_logo_widget(val.target.checked)
                                    }
                                >
                                    Логотип (виджет)
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={show_logo_tour}
                                    onChange={(val) =>
                                        set_show_logo_tour(val.target.checked)
                                    }
                                >
                                    Логотип (3D тур)
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={show_popup_tour}
                                    onChange={(val) =>
                                        set_show_popup_tour(val.target.checked)
                                    }
                                >
                                    Popup (3D тур)
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={hide_buttons}
                                    onChange={(val) =>
                                        set_hide_buttons(val.target.checked)
                                    }
                                >
                                    Скрыть кнопки (3D тур)
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={application_button}
                                    onChange={(val) =>
                                        set_application_button(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Прием заявок
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={status_apartments_sold}
                                    onChange={(val) =>
                                        set_status_apartments_sold(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Показывать проданные квартиры
                                </Checkbox>
                            </div>
                            <div className={styles.itemOption}>
                                <Checkbox
                                    checked={status_booking}
                                    onChange={(val) =>
                                        set_status_booking(
                                            val.target.checked
                                        )
                                    }
                                >
                                    Бронирование
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>

            <div className={styles.save_widget_block} style={{ marginTop: 10 }}>
                {widgetState.create_status ? (
                    <Button type="primary">Обновляем...</Button>
                ) : (
                    <Button type="primary" onClick={updateWidget}>
                        Обновить настройки
                    </Button>
                )}
            </div>

        </div>
    );
};

export default FormEdit;
