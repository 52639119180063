import Pages from '../../../../../Containers/Admin/Pages';
import CreatePage from '../../../../../Containers/Admin/Pages/Create';
import EditPage from '../../../../../Containers/Admin/Pages/Edit';

export default [
    {
        path: '/pages',
        element: <Pages />,
    },
    {
        path: '/pages/create',
        element: <CreatePage />,
    },
    {
        path: '/pages/edit/:id',
        element: <EditPage />,
    },
];
