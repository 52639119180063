import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { Header } from '@/Components';
import styles from './styles/layer.module.scss';

const Layer = ({ login = false, Sidebar, user }) => {
    if (login) {
        return (
            <Layout>
                <Outlet />
            </Layout>
        );
    } else {
        return (
            <Layout className={styles.layer}>
                <Header />
                <Layout>
                    <Sidebar user={user} />
                    <div className={styles.content}>
                        <Outlet />
                    </div>
                </Layout>
            </Layout>
        );
    }
};

export default Layer;
