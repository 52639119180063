import React, { useEffect, useState } from 'react';
import { Input, InputNumber, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import styles from '../../styles/index.module.scss';
import { useMutation } from '@apollo/client';
import { budget } from '@/Services/apollo/gqls';

const FormForProduct = ({ form_calc_status, product, template_id }) => {
    const [step_one, set_step_one] = useState({
        type_calc: product?.calculate?.step_one?.type_calc || '',
        variable: product?.calculate?.step_one?.variable || 0,
    });

    const [step_two, set_step_two] = useState({
        conditions: product?.calculate?.step_two?.conditions || '',
        values: product?.calculate?.step_two?.values || '',
    });

    const [step_three, set_step_three] = useState({
        conditions: product?.calculate?.step_three?.conditions || '',
        values: product?.calculate?.step_three?.values || '',
    });

    const dispatch = useDispatch();

    const [updateCalculateOfProduct] = useMutation(
        budget.updateCalculateOfProduct
    );

    useEffect(() => {
        set_step_one({
            type_calc: product?.calculate?.step_one?.type_calc || '',
            variable: product?.calculate?.step_one?.variable || 0,
        });
        set_step_two({
            conditions: product?.calculate?.step_two?.conditions || '',
            values: product?.calculate?.step_two?.values || '',
        });
        set_step_three({
            conditions: product?.calculate?.step_three?.conditions || '',
            values: product?.calculate?.step_three?.values || '',
        });
    }, [product]);

    const closeModal = () => {
        dispatch({
            type: 'BUDGET_CALC_OF_PRODUCT',
            status: false,
            product: null,
        });
    };

    const saveValues = () => {
        closeModal();

        let params = {
            step_one,
            step_two,
            step_three,
        };

        updateCalculateOfProduct({
            variables: {
                fields: {
                    product_id: product?.id_product?._id,
                    id: product?._id,
                    values: params,
                    template_id,
                },
            },
            refetchQueries: [
                {
                    query: budget.getCategoriesOfBudgetTemplate,
                    variables: {
                        template: template_id,
                    },
                },
            ],
        });
    };

    return (
        <Modal
            title={`Заполните данные для ${product?.id_product?.title || '-'}`}
            centered
            zIndex={9999999}
            visible={form_calc_status}
            cancelText="Отмена"
            onOk={saveValues}
            onCancel={closeModal}
            wrapClassName={styles.modalFormForProduct}
            width="55%"
        >
            <div className={styles.step}>
                <p>1</p>
                <div className={styles.form}>
                    <b>Площадь маркера</b>
                    <select
                        value={step_one?.type_calc}
                        onChange={(val) => {
                            let updateObj = {
                                ...step_one,
                                type_calc: val.target.value,
                            };

                            set_step_one(updateObj);
                        }}
                        className={styles.formSelect}
                    >
                        <option value=""></option>
                        <option value="adding">+</option>
                        <option value="subtract">-</option>
                        <option value="multiply">*</option>
                        <option value="division">/</option>
                    </select>

                    <InputNumber
                        value={step_one?.variable}
                        onChange={(value) => {
                            let updateObj = {
                                ...step_one,
                                variable: value,
                            };

                            set_step_one(updateObj);
                        }}
                    />
                </div>
            </div>
            <div className={styles.step}>
                <p>2</p>
                <Input
                    value={step_two?.conditions}
                    placeholder="Введите значение для условий через запятую в формате: 00-00"
                    onChange={(value) => {
                        let updateState = {
                            ...step_two,
                            conditions: value.target.value,
                        };
                        set_step_two(updateState);
                    }}
                />
                <Input
                    value={step_two?.values}
                    placeholder="Введите значение через запятую"
                    onChange={(value) => {
                        let updateState = {
                            ...step_two,
                            values: value.target.value,
                        };
                        set_step_two(updateState);
                    }}
                />
            </div>
            <div className={styles.step}>
                <p>3</p>
                <Input
                    value={step_three?.conditions}
                    placeholder="Введите значение для условий через запятую в формате: 00-00"
                    onChange={(value) => {
                        let updateState = {
                            ...step_three,
                            conditions: value.target.value,
                        };
                        set_step_three(updateState);
                    }}
                />
                <Input
                    value={step_three?.values}
                    placeholder="Введите значение через запятую"
                    onChange={(value) => {
                        let updateState = {
                            ...step_three,
                            values: value.target.value,
                        };
                        set_step_three(updateState);
                    }}
                />
            </div>
        </Modal>
    );
};

export default FormForProduct;
