import React, { useEffect, memo } from 'react';
import Switch from 'react-input-switch';
import config from '@/Config';
import { Link } from 'react-router-dom';
import { mdiBeakerRemoveOutline, mdiFileEditOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Loader } from '@/Components';
import { moment, api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles/index.module.scss';
import { Result } from 'antd';

const RealestateItems = () => {
    const dispatch = useDispatch();

    const { request_status_realestate, realestate_list } = useSelector(state => state?.realestate);
    const getUserState = useSelector((state) => state.user);

    useEffect(() => {
        dispatch({
            type: 'FLAT_TOGGLE_CONTEXT_MENU',
            blockIndex: null,
            floorIndex: null,
            flatIndex: null,
        });

    }, []);

    const soldApartment = (status, id) => {
        return dispatch(api.apartments.soldApartment(status, id));
    };

    const deleteApartment = (id, title) => {
        let statusDeleteConfirm = window.confirm(
            `Жк ${title} будет удален без восстановления?`
        );
        if (statusDeleteConfirm) {
            return dispatch(api.apartments.deleteApartment(id));
        }
        return;
    };

    return (
        <div>
            {request_status_realestate ? (
                <Loader />
            ) : realestate_list && realestate_list.length ? (
                <div className="list-complex-content-block">
                    <div
                        style={{ margin: 0 }}
                        className={`page-apartment-grid ${styles.listRealestate}`}
                    >
                        {realestate_list.map((apartment) => {
                            let urlPhoto =
                                config.public +
                                '450/300/' +
                                apartment?.photo?.url;
                            return (
                                <div
                                    key={apartment?._id}
                                    main-id-apart={apartment?._id}
                                    main-sort-apart={apartment?.sort}
                                    className={`item-card-apart`}
                                >

                                    <div
                                        main-id-apart={apartment?._id}
                                        main-sort-apart={apartment?.sort}
                                        className="apartment-block-content-item"
                                    >
                                        <div
                                            className="apartment-image-block"
                                            style={{
                                                background: `url(${urlPhoto}) center / cover no-repeat`,
                                            }}
                                        ></div>
                                        <div className="apartment-block-content-item-overlay">
                                            <div className="apartment-block-content-item-overlay-top">
                                                <h2>{apartment.title}</h2>
                                            </div>
                                            <div className="apartment-block-content-item-overlay-bottom">
                                                <section className="icons-settings-apart-complex-block">
                                                    <Link
                                                        data-title="Управление секциями"
                                                        className="apartment-block-content-item-overlay-bottom-link-blocks icon-settings-apart-complex-sections"
                                                        title="Редактировать"
                                                        to={`/apartments/blocks/${apartment._id}`}
                                                    ></Link>

                                                    {apartment?.type_content ===
                                                        'custom' ||
                                                    apartment?.type_content ===
                                                        'demo' ? (
                                                        <></>
                                                    ) : (
                                                        <Link
                                                            data-title="Управление ценами"
                                                            className="apartment-block-content-item-overlay-bottom-link-blocks icon-settings-apart-complex-prices"
                                                            title="Редактировать"
                                                            to={`/apartments/blocks/prices/${apartment._id}`}
                                                        ></Link>
                                                    )}
                                                    {apartment?.type_content ===
                                                    'demo' ? (
                                                        <></>
                                                    ) : (
                                                        <Link
                                                            data-title="Шахматка"
                                                            className="apartment-block-content-item-overlay-bottom-link-blocks icon-settings-apart-complex-chess"
                                                            title="Редактировать"
                                                            to={`/apartments/chess/${apartment._id}`}
                                                        ></Link>
                                                    )}
                                                </section>

                                                <small>
                                                    {moment(
                                                        apartment.createdAt
                                                    ).format(
                                                        'Создано: DD/MM/YYYY в HH:mm'
                                                    )}
                                                </small>

                                                <div className="apartment-block-content-item-overlay-settings">
                                                    <div className="apartment-block-content-item-overlay-settings-left">
                                                        <Link
                                                            title="Редактировать"
                                                            to={`/apartments/edit/${apartment._id}`}
                                                        >
                                                            <Icon
                                                                className="apartment-edit-icon"
                                                                size={1}
                                                                path={
                                                                    mdiFileEditOutline
                                                                }
                                                            />
                                                        </Link>
                                                        {getUserState?.user
                                                            ?.role?.type ==
                                                        'SUPERADMIN' ? (
                                                            <span>
                                                                <Icon
                                                                    onClick={() => {
                                                                        deleteApartment(
                                                                            apartment._id,
                                                                            apartment.title
                                                                        );
                                                                    }}
                                                                    className="apartment-delete-icon"
                                                                    size={1}
                                                                    path={
                                                                        mdiBeakerRemoveOutline
                                                                    }
                                                                />
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    <div className="apartment-block-content-item-overlay-settings-right">
                                                        <p>Продан</p>
                                                        <Switch
                                                            value={
                                                                apartment.sold
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            onChange={() =>
                                                                soldApartment(
                                                                    apartment.sold
                                                                        ? !apartment.sold
                                                                        : 1,
                                                                    apartment._id
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <Result
                    status="404"
                    title="Жк не найдено"
                    subTitle="Извините, но по данному запросу ничего не найдено"
                    extra={null}
                />
            )}

        </div>
    );
};

export default memo(RealestateItems);
