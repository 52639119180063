"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHINA_BOUNDARY_STYLE = exports.GEO_AREA_URL = exports.GEO_DATA_URL = exports.DEFAULT_AREA_GRANULARITY = exports.DEFAULT_OPTIONS = void 0;
var plot_1 = require("../../core/plot");
var utils_1 = require("../../utils");
/**
 * 默认配置项
 */
exports.DEFAULT_OPTIONS = (0, utils_1.deepAssign)({}, plot_1.Plot.DefaultOptions, {
    source: {
        data: [],
        joinBy: {
            geoField: 'adcode',
        },
    },
    viewLevel: {
        level: 'country',
        adcode: '100000',
    },
    chinaBorder: true,
});
/**
 * 行政数据默认显示粒度
 */
exports.DEFAULT_AREA_GRANULARITY = {
    world: 'country',
    country: 'province',
    province: 'city',
    city: 'district',
    district: 'district',
};
/**
 * 行政地理数据服务地址
 */
// export const GEO_DATA_URL = 'http://localhost:8080';
exports.GEO_DATA_URL = 'https://gw.alipayobjects.com/os/alisis/geo-data-v0.1.2';
exports.GEO_AREA_URL = "".concat(exports.GEO_DATA_URL, "/choropleth-data");
/**
 * 中国国界样式
 */
exports.CHINA_BOUNDARY_STYLE = {
    // 国界
    national: {
        color: 'red',
        width: 1,
        opacity: 1,
    },
    // 争议
    dispute: {
        color: 'red',
        width: 1,
        opacity: 0.8,
        dashArray: [2, 4],
    },
    // 海洋
    coast: {
        color: 'blue',
        width: 0.7,
        opacity: 0.8,
    },
    // 港澳
    hkm: {
        color: 'gray',
        width: 0.7,
        opacity: 0.8,
        dashArray: [2, 4],
    },
};
