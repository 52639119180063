import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { budget } from '@/Services/apollo/gqls';
import currencyFormatter from 'currency-formatter';
import CategoryItems from './Helpers/categories';
import { Loader } from '@/Components';
import { mdiArrowLeft, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';

import styles from '../styles/index.module.scss';

import AddProductForm from './Helpers/add-product';

import SubProducts from '@/Services/Context/sub-products';
import FormForProduct from './Helpers/modal-form-for-product';
import RangePrices from './Helpers/range-prices';

const CreateBudget = () => {
    const { id } = useParams();
    const history = useNavigate();

    const [title, set_title] = useState('');
    const [status_form_add_product, set_status_form_add_product] =
        useState(false);
    const [sub_products, set_sub_products] = useState([]);
    const [range_price_modal, set_range_price_modal] = useState(false);

    const [status_add_sub_product, set_status_add_sub_product] =
        useState(false);
    const [add_sub_product_id, set_add_sub_product_id] = useState(null);
    const [add_category_id, set_add_category_id] = useState(null);
    const [marker_id, set_marker_id] = useState(null);

    const dispatch = useDispatch();

    const getStateBudget = useSelector((state) => state.budget);

    let { data, loading } = useQuery(budget.getCategoriesOfBudgetTemplate, {
        fetchPolicy: 'network-only',
        variables: {
            template: id,
        },
    });

    const [updateTitleTemplate] = useMutation(budget.updateTitleTemplate, {
        onCompleted: () => {
            return history('/budget');
        },
    });

    const [removeProductFromBudget, removeProductFromBudgetLoading] =
        useMutation(budget.removeProductFromBudget, {
            refetchQueries: [
                {
                    query: budget.getCategoriesOfBudgetTemplate,
                    variables: {
                        template: id,
                    },
                },
            ],
        });

    const [addProductForCategoryOfTemplate] = useMutation(
        budget.addProductForCategoryOfTemplate,
        {
            fetchPolicy: 'no-cache',
            refetchQueries: [
                {
                    query: budget.getCategoriesOfBudgetTemplate,
                    variables: {
                        template: id,
                    },
                },
            ],
        }
    );

    const procentRes = (price, discont) => {
        let result = Math.round((price / 100) * discont);
        return (
            currencyFormatter.format(price - result, {
                code: 'EUR',
                symbol: '',
            }) + '₸'
        );
    };

    useEffect(() => {
        dispatch({
            type: 'BUDGET_CALC_OF_PRODUCT',
            status: false,
            product: null,
        });

        if (data && data.getCategoriesOfBudgetTemplate) {
            set_title(data.getCategoriesOfBudgetTemplate.template.title);
        }
    }, [loading]);

    const updateSubProduct = (id) => {
        let searchProduct = sub_products.filter(
            (product) => product.id_product._id != id
        );
        set_sub_products([...searchProduct]);
    };

    return useMemo(
        () => (
            <SubProducts.Provider value={{ sub_products, set_sub_products }}>
                <content className={styles.mainBlock}>
                    {!loading ? (
                        <div className={styles.mainBlock}>
                            <RangePrices
                                template={
                                    data?.getCategoriesOfBudgetTemplate
                                        ?.template || null
                                }
                                template_id={id}
                                styles={styles}
                                status={range_price_modal}
                                close={() => set_range_price_modal(false)}
                            />

                            <FormForProduct
                                template_id={id}
                                product={getStateBudget?.product || null}
                                form_calc_status={
                                    getStateBudget?.modal_calc_product || false
                                }
                            />

                            <AddProductForm
                                addProductForCategoryOfTemplate={
                                    addProductForCategoryOfTemplate
                                }
                                marker={marker_id}
                                add_category_id={add_category_id}
                                add_sub_product_id={add_sub_product_id}
                                status_add_sub_product={status_add_sub_product}
                                categories={
                                    data.getCategoriesOfBudgetTemplate
                                        .categories
                                }
                                closeModal={() => {
                                    set_status_form_add_product(false);
                                    set_status_add_sub_product(false);
                                    set_add_sub_product_id(null);
                                    set_marker_id(null);
                                }}
                                status_form_add_product={
                                    status_form_add_product
                                }
                                template_id={id}
                            />

                            <h1>Редактирование бюджет шаблона</h1>
                            <input
                                onChange={(val) => set_title(val.target.value)}
                                type="text"
                                placeholder="Название Шаблона"
                                value={title}
                            />

                            <div className={styles.budgetFormHead}>
                                <input
                                    type="submit"
                                    onClick={() => {
                                        set_range_price_modal(true);
                                    }}
                                    value="Диапазон цен"
                                    style={{ marginRight: 10 }}
                                />
                                <Link
                                    to={`/budget/categories/groups/${data?.getCategoriesOfBudgetTemplate?.template?.group_categories_id}/${id}`}
                                >
                                    Редактировать
                                </Link>
                                <input
                                    type="submit"
                                    onClick={() =>
                                        set_status_form_add_product(true)
                                    }
                                    value="Добавить товар"
                                />
                            </div>

                            {sub_products && sub_products.length ? (
                                <div
                                    style={{ width: '100%', marginLeft: 0 }}
                                    className={styles.tableProducts}
                                >
                                    <Icon
                                        className={styles.backButton}
                                        path={mdiArrowLeft}
                                        size={1}
                                        onClick={() => {
                                            set_sub_products(null);
                                        }}
                                    />
                                    <table border="1">
                                        <thead>
                                            <tr className={styles.trHead}>
                                                <th>Наименование</th>
                                                <th>Описание</th>
                                                <th>Площ. Кол-во</th>
                                                <th>Ед. изм.</th>

                                                <th>Цена за ед.</th>
                                                <th>Скидка</th>
                                                <th>Сумма со скидкой</th>
                                                <th>
                                                    Название магазина, телефон,
                                                    адрес, фото товара
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sub_products.map((product) => {
                                                return (
                                                    <tr
                                                        className={
                                                            styles.trBody
                                                        }
                                                        key={
                                                            product._id +
                                                            `_${
                                                                Math.random() *
                                                                200000
                                                            }`
                                                        }
                                                    >
                                                        <td>
                                                            {
                                                                product
                                                                    .id_product
                                                                    .title
                                                            }
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .discount_description
                                                                ? product
                                                                      .id_product
                                                                      .discount_description
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product?.area_and_number
                                                                ? product?.area_and_number
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.unit
                                                                ? product.unit
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .price
                                                                ? currencyFormatter.format(
                                                                      product
                                                                          .id_product
                                                                          .price,
                                                                      {
                                                                          code: 'EUR',
                                                                          symbol: '',
                                                                      }
                                                                  ) + '₸'
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .discount
                                                                ? product
                                                                      .id_product
                                                                      .discount +
                                                                  '%'
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .discount
                                                                ? procentRes(
                                                                      Number(
                                                                          product
                                                                              .id_product
                                                                              .price
                                                                      ),
                                                                      Number(
                                                                          product
                                                                              .id_product
                                                                              .discount
                                                                      )
                                                                  )
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            Название магазина,
                                                            телефон, адрес, фото
                                                            товара
                                                        </td>
                                                        <td>
                                                            <Icon
                                                                path={mdiDelete}
                                                                size={1}
                                                                onClick={() => {
                                                                    updateSubProduct(
                                                                        product
                                                                            .id_product
                                                                            ._id
                                                                    );

                                                                    removeProductFromBudget(
                                                                        {
                                                                            variables:
                                                                                {
                                                                                    fields: {
                                                                                        type: 'SUB',
                                                                                        product_id:
                                                                                            product
                                                                                                .id_product
                                                                                                ._id,
                                                                                    },
                                                                                },
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <CategoryItems
                                    removeProductFromBudgetLoading={
                                        removeProductFromBudgetLoading
                                    }
                                    removeProductFromBudget={
                                        removeProductFromBudget
                                    }
                                    openAddSubProduct={(
                                        id_product,
                                        cat_id,
                                        marker_id
                                    ) => {
                                        set_status_add_sub_product(true);
                                        set_add_sub_product_id(id_product);
                                        set_add_category_id(cat_id);
                                        set_status_form_add_product(true);
                                        set_marker_id(marker_id);
                                    }}
                                    styles={styles}
                                    template_id={id}
                                    data={
                                        data.getCategoriesOfBudgetTemplate
                                            .categories
                                    }
                                />
                            )}

                            <span
                                className={styles.saveTemplateButton}
                                onClick={() => {
                                    if (title) {
                                        updateTitleTemplate({
                                            variables: {
                                                fields: {
                                                    template_id: id,
                                                    title,
                                                },
                                            },
                                        });
                                        return;
                                    }
                                    alert('Заполните поле с названием шаблона');
                                }}
                            >
                                Сохранить
                            </span>
                        </div>
                    ) : (
                        <Loader height={220} />
                    )}
                </content>
            </SubProducts.Provider>
        ),
        [
            data,
            title,
            status_form_add_product,
            sub_products,
            loading,
            getStateBudget,
            range_price_modal,
        ]
    );
};

export default CreateBudget;
