import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';

import styles from '../styles/index.module.scss';

const CreateRedirect = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const getRedirectState = useSelector((state) => state.redirect);

    const [url, set_url] = React.useState('');
    const [redirect_url, set_redirect_url] = React.useState('');

    const create = () => {
        return dispatch(api.redirect.create(url, redirect_url, history));
    };

    return (
        <content className="cities-page-block">
            <h1>Добавление редирект ссылки</h1>

            <input
                className={styles.input}
                placeholder="Битая ссылка"
                value={url}
                onChange={(val) => set_url(val.target.value)}
            />
            <input
                className={styles.input}
                placeholder="Редирект"
                value={redirect_url}
                onChange={(val) => set_redirect_url(val.target.value)}
            />

            {getRedirectState.request_status ? (
                <Button variant="contained">Подождите</Button>
            ) : (
                <Button
                    variant="contained"
                    onClick={() => {
                        create();
                    }}
                >
                    Добавить
                </Button>
            )}
        </content>
    );
};

export default CreateRedirect;
