const initialState = {
	list:[],
	page:0,
	FR:false,
	
	request_status:false
}

const games = (state = initialState, action) => {
	switch(action.type){

    case 'PLAYERS_FR' :
      return {
        ...state,
        FR:true
      }

    case 'PLAYERS_PAGE' :
      return {
        ...state,
        page:action.page
      }

    case 'PLAYER_BAN_GAME' :
      return {
        ...state,
        list:state.list.map((player, index)=>{
        	if(index == action.index){
        		return {
        			...player,
        			is_banned:true
        		}
        	}

        	return player;
        })
      }

    case 'UPDATE_LIST_PLAYERS_GAME' :
      return {
        ...state,
        list:[...state.list, ...action.data]
      }

		case 'REQUEST_STATUS_GAMES' :
			return {
				...state,
				request_status:action.status
			}

		default :
			return state
	}
}

export default games;