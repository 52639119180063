import React, { useEffect, memo } from 'react';
import { 
    Drawer, 
    Form, 
    Checkbox,
    Button,
    Tooltip,
    Input,
    Upload,
    Popover,
    Tag
} from 'antd';
import { useDispatch } from 'react-redux';
import styles from '../../styles/index.module.scss';
import { LoadingOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { api, formatBytes, moment } from '@/Services';
import config from '@/Config';

const { Dragger } = Upload;

const FormTemplateV2 = ({
    item,
    visible,
    close,
    loading,
    realestate_id
}) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const square_metres = Number(Form.useWatch('square_metres', { form, preserve: true })) || 0;
    const square_metre_price = Math.round(Number(Form.useWatch('square_metre_price', { form, preserve: true }))) || 0;

    useEffect(()=>{

        const update_total_price = ((square_metres || 0) * (square_metre_price || 0)) || 0;
        const update_square_metre_price = ((update_total_price || 0) / (square_metres || 0)) || 0;

        form.setFieldsValue({
            total_price: String(Math.round(update_total_price) || 0),
            square_metre_price: String(Math.round(update_square_metre_price) || 0)
        });

    }, [square_metres, square_metre_price]);
    

    useEffect(()=>{
        form.setFieldsValue({
            commercial: item?.commercial || false,
            title: item?.title || '',
            square_metres: item?.square_metres || '',
            count_rooms: item?.count_rooms || '',
            total_price: item?.total_price || '',
            square_metre_price: item?.square_metre_price || '',
            ceiling_height: item?.ceiling_height || '',
            seo_title: item?.seo_title || '',
            seo_keywords: item?.seo_keywords || '',
            seo_description: item?.seo_description || '',
            plan_photo: item?.plan_photo?._id || '',
            preview_photo: item?.preview_photo?._id || '',
        });
    }, [item]);

    const onFinish = (values) => {

        let fields = {
            ...values,
            apartmentComplex: [realestate_id],
        };
        if (item) {
            return dispatch(
                api.flats.editTemplateFromRealestate({
                    ...fields,
                    id: item?._id,
                }, closeFormWithReset)
            );
        } else {
            return dispatch(api.flats.createTemplateFromRealestate(fields, closeFormWithReset));
        }

    }

    const customRequest = async ({ file, onSuccess, data }) => {

        const getFile = await dispatch(api.files.standartUploadFile(file, data?.uploadType));

        if(data?.form_key === 'plan_photo'){
            form.setFieldsValue({
                plan_photo: getFile?.id
            });
        } else if(data?.form_key === 'preview_photo'){
            form.setFieldsValue({
                preview_photo: getFile?.id
            });
        }
        onSuccess()
    };

    const contentPopoverFile = (file) => {
        return (
            <div className={styles.contentPopoverFile}>
                <p>Дата загрузки: <Tag>{moment(file?.createdAt).format('DD MMMM YYYY в HH:mm')}</Tag></p>
                <p>Размер (оригинал): {formatBytes( (file?.original_size || 0) * (1024 * 1024) )}</p>
                <p>Размер (сжатый): {formatBytes( (file?.size || 0) * (1024 * 1024) )}</p>
            </div>
          );
    }

    const removeFileFromListUploaded = (file, type) => {

        if(type === 'plan_photo'){
            form.setFieldsValue({
                plan_photo: item && item?.plan_photo ? item?.plan_photo?._id : ''
            })
        } else if(type === 'preview_photo'){
            form.setFieldsValue({
                preview_photo: item && item?.preview_photo ? item?.preview_photo?._id : ''
            })
        }

        return true;

    }

    const closeFormWithReset = () => {
        form.resetFields();
        return close();
    }

    return (
        <Drawer
            width="70%"
            title={
                item
                    ? 'Форма редактирование шаблона'
                    : 'Форма добавления шаблона'
            }
            open={visible}
            footer={null}
            onClose={closeFormWithReset}
        >
            <Form
                name="basic"
                size="large"
                form={form}
                onFinish={onFinish}
                disabled={loading}
            >
                <Form.Item
                    name="commercial"
                    className={styles.formTemplateItemStyle}
                    valuePropName="checked"
                >
                    <Checkbox>
                        Коммерческая недвижимость
                    </Checkbox>
                </Form.Item>

                <Tooltip
                    zIndex={99999999}
                    trigger={['focus', 'hover']}
                    title="Наименование шаблона"
                    placement="topLeft"
                >
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Заполните наименование шаблона',
                            },
                        ]}
                        className={styles.formTemplateItemStyle}
                    >
                        <Input
                            maxLength={35}
                            showCount
                            placeholder="Наименование шаблона"
                        />
                    </Form.Item>
                </Tooltip>

                <Tooltip
                    zIndex={99999999}
                    trigger={['focus', 'hover']}
                    title="Площадь квартиры (м²)"
                    placement="topLeft"
                >
                    <Form.Item
                        label=""
                        name="square_metres"
                        rules={[
                            {
                                required: true,
                                message: 'Заполните площадь квартиры',
                            },
                        ]}
                        className={styles.formTemplateItemStyle}
                    >
                        <Input
                            placeholder="Площадь квартиры (м²)"
                            type="number"
                        />
                    </Form.Item>
                </Tooltip>

                <Tooltip
                    zIndex={99999999}
                    trigger={['focus', 'hover']}
                    title="Количество комнат"
                    placement="topLeft"
                >
                    <Form.Item
                        label=""
                        name="count_rooms"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите количество комнат',
                            },
                        ]}
                        className={styles.formTemplateItemStyle}
                    >
                        <Input
                            placeholder="Количество комнат"
                            type="number"
                        />
                    </Form.Item>
                </Tooltip>

                <Tooltip
                    zIndex={99999999}
                    trigger={['focus', 'hover']}
                    title="Стоимость квартиры рассчитывается автоматически, используя значения площади и стоимость м²."
                    placement="topLeft"
                >
                    <Form.Item
                        label=""
                        name="total_price"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите стоимость квартиры',
                            },
                        ]}
                        className={styles.formTemplateItemStyle}
                    >
                        <Input
                            placeholder="Стоимость"
                            type="number"
                            disabled
                        />
                    </Form.Item>
                </Tooltip>

                <Tooltip
                    zIndex={99999999}
                    trigger={['focus', 'hover']}
                    title="Укажите стоимость за м²"
                    placement="bottomLeft"
                >
                    <Form.Item
                        label=""
                        name="square_metre_price"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите стоимость за м²',
                            },
                        ]}
                        className={styles.formTemplateItemStyle}
                    >
                        <Input
                            placeholder="стоимость за м²"
                            type="number"
                        />
                    </Form.Item>
                </Tooltip>

                <Tooltip
                    zIndex={99999999}
                    trigger={['focus', 'hover']}
                    title="Укажите высоту потолка"
                    placement="topLeft"
                >
                    <Form.Item
                        label=""
                        name="ceiling_height"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите высоту потолка',
                            },
                        ]}
                        className={styles.formTemplateItemStyle}
                    >
                        <Input
                            placeholder="Высота потолка"
                            type="number"
                        />
                    </Form.Item>
                </Tooltip>

                <div className={styles.groupformInputs}>
                    <Tooltip
                        zIndex={99999999}
                        trigger={['focus', 'hover']}
                        title="Seo title"
                        placement="topLeft"
                    >
                        <Form.Item
                            label=""
                            name="seo_title"
                            className={styles.formTemplateItemStyle}
                        >
                            <Input
                                placeholder="Seo title"
                                type="text"
                            />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip
                        zIndex={99999999}
                        trigger={['focus', 'hover']}
                        title="Seo keywords"
                        placement="topLeft"
                    >
                        <Form.Item
                            label=""
                            name="seo_keywords"
                            className={styles.formTemplateItemStyle}
                        >
                            <Input
                                placeholder="Seo keywords"
                                type="text"
                            />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip
                        zIndex={99999999}
                        trigger={['focus', 'hover']}
                        title="Seo description"
                        placement="topLeft"
                    >
                        <Form.Item
                            label=""
                            name="seo_description"
                            className={styles.formTemplateItemStyle}
                        >
                            <Input
                                placeholder="Seo description"
                                type="text"
                            />
                        </Form.Item>
                    </Tooltip>
                </div>

                <div className={styles.blockUploadImages}>
                    <div className={styles.uploadImage}>
                        <Form.Item
                            label=""
                            name="plan_photo"
                            className={styles.buttonFile}
                            rules={[
                                {
                                    required: true,
                                    message: 'Загрузите планировку (высокое качество)',
                                },
                            ]}
                        >
                            <ImgCrop
                                quality={1}
                                rotationSlider
                                showReset
                                aspectSlider
                                showGrid
                                modalTitle="Редактирование файла"
                                modalWidth="65vw"
                                resetText="Сбросить"
                                modalOk={<><UploadOutlined /> Загрузить</>}
                            >
                                <Dragger
                                    action="http://185.102.75.211:5727/v1/admin/files"
                                    maxCount={1}
                                    accept="image/png, image/jpeg, image/webp"
                                    name='file'
                                    headers={{
                                        authorization: localStorage.getItem('SESSION_TOKEN'),
                                    }}
                                    data={{
                                        uploadType: 'IMAGE_PREVIEW',
                                        form_key: 'plan_photo'
                                    }}
                                    listType="picture"
                                    customRequest={customRequest}
                                    onRemove={(file)=>removeFileFromListUploaded(file, 'plan_photo')}
                                >

                                    {
                                        item && item?.plan_photo ?
                                            <Popover 
                                                title="Актуальная планировка"
                                                content={()=>contentPopoverFile(item?.plan_photo)}
                                            >
                                                <img src={`${config?.public}${item?.plan_photo?.url}`} />
                                            </Popover>
                                        :
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                    }
                                    
                                    <p className="ant-upload-text">Выберите планировку квартиры (высокое качество)</p>
                                    <p>Для выбора доступны только png, jpeg, webp файлы, также ограничение в максимум 1 файл</p>
                                </Dragger>
                            </ImgCrop>
                        </Form.Item>
                    </div>
                    <div className={styles.uploadImage}>
                        <Form.Item
                            label=""
                            name="preview_photo"
                            className={styles.buttonFile}
                            rules={[
                                {
                                    required: true,
                                    message: 'Загрузите планировку (низкое качество)',
                                },
                            ]}
                        >
                            <ImgCrop
                                quality={1}
                                rotationSlider
                                showReset
                                aspectSlider
                                showGrid
                                modalTitle="Редактирование файла"
                                modalWidth="65vw"
                                resetText="Сбросить"
                                modalOk={<><UploadOutlined /> Загрузить</>}
                            >
                                <Dragger
                                    action="http://185.102.75.211:5727/v1/admin/files"
                                    maxCount={1}
                                    accept="image/png, image/jpeg, image/webp"
                                    name='file'
                                    headers={{
                                        authorization: localStorage.getItem('SESSION_TOKEN'),
                                    }}
                                    data={{
                                        uploadType: 'IMAGE_PREVIEW',
                                        form_key: 'preview_photo'
                                    }}
                                    listType="picture"
                                    customRequest={customRequest}
                                    onRemove={(file)=>removeFileFromListUploaded(file, 'preview_photo')}
                                >
                                    {
                                        item && item?.preview_photo ?
                                            <Popover 
                                                title="Актуальная планировка"
                                                content={()=>contentPopoverFile(item?.preview_photo)}
                                            >
                                                <img src={`${config?.public}${item?.preview_photo?.url}`} />
                                            </Popover>
                                        :
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                    }
                                    <p className="ant-upload-text">Выберите планировку квартиры (низкое качество)</p>
                                    <p>Для выбора доступны только png, jpeg, webp файлы, также ограничение в максимум 1 файл</p>
                                </Dragger>
                            </ImgCrop>
                        </Form.Item>
                    </div>
                </div>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {loading ? <LoadingOutlined /> : item?._id ? 'Редактировать' : 'Добавить'}
                    </Button>
                </Form.Item>
            </Form>

        </Drawer>
    )
}

export default memo(FormTemplateV2);