import React, { useEffect, useState } from 'react';
import { Modal, Button, Checkbox, Form, Input, InputNumber } from 'antd';

const ModalForm = ({ modal_info, set_modal_info, api, dispatch }) => {
    const [form_state, set_form_state] = useState({});

    useEffect(() => {
        set_form_state({
            title: modal_info?.flat?.title || '',
            square_metres_price: modal_info?.flat?.square_metre_price || '',
            sold: modal_info?.flat?.sold || false,
            secondary: modal_info?.flat?.secondary || false,
        });
    }, [modal_info]);

    const saveform = (data) => {
        return dispatch(api.apartments.chessForm(data, modal_info));
    };

    return (
        <Modal
            footer={null}
            width="55%"
            okText="Сохранить"
            destroyOnClose={true}
            title={
                modal_info?.type_trigger === 'BLOCK'
                    ? 'Изменение цен по блоку'
                    : modal_info?.type_trigger === 'FLOOR'
                    ? 'Изменение цен по этажу'
                    : `Управление квартирой №${modal_info?.flat?.title}`
            }
            visible={modal_info?.open}
            onOk={() => {}}
            onCancel={() => set_modal_info(null)}
        >
            <Form
                initialValues={form_state}
                name="basic"
                labelCol={{
                    span: modal_info?.type_trigger === 'FLAT' ? 4 : 0,
                }}
                wrapperCol={{
                    span: 8,
                }}
                layout="horizontal"
                onFinish={saveform}
                onFinishFailed={() => {}}
                autoComplete="off"
            >
                {modal_info?.type_trigger === 'FLAT' && (
                    <Form.Item
                        label="Номер квартиры"
                        name="title"
                        rules={[
                            {
                                required: true,
                                type: 'string',
                                message: 'Пожалуйста заполните номер квартиры',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                )}

                <Form.Item
                    label="Цена за м²"
                    name="square_metres_price"
                    rules={[
                        {
                            required: true,
                            type: 'number',
                            message: 'Укажите цену за м²',
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        max={10000000}
                    />
                </Form.Item>

                {modal_info?.type_trigger === 'FLAT' && (
                    <>
                        <Form.Item
                            name="secondary"
                            wrapperCol={{
                                offset: 4,
                            }}
                            valuePropName="checked"
                        >
                            <Checkbox>Вторичное жилье</Checkbox>
                        </Form.Item>

                        <Form.Item
                            name="sold"
                            wrapperCol={{
                                offset: 4,
                            }}
                            valuePropName="checked"
                        >
                            <Checkbox>Продана</Checkbox>
                        </Form.Item>
                    </>
                )}

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalForm;
