import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import ReduxThunk from 'redux-thunk';

class Store {
    constructor(reducers) {
        this.persistConfig = {
            key: 'root',
            storage: storage,
            blacklist: [
                'builders',
                'files',
                'pages',
                'logs',
                'games',
                'users',
                'apartments',
                'library',
                'flats',
                'redirect',
                'budget',
                'chess',
                'chessData',
                'widgets',
                'statistic',
                'realestate'
            ],
        };
        this.middleware = [];
        this.enhancers = [];
        this.reducers = reducers;
        this.dev = process.env.NODE_ENV === 'development';
        this.persist = persistReducer(this.persistConfig, this.reducers);
    }

    middlewares() {
        if (this.dev) {
            this.middleware.push(logger);
        }
        this.middleware.push(ReduxThunk);
        this.enhancers.push(applyMiddleware(...this.middleware));
    }

    init() {
        this.middlewares();

        const store = createStore(this.persist, compose(...this.enhancers));
        const persistor = persistStore(store);

        return {
            store,
            persistor,
        };
    }
}

export default Store;
