"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Heatmap = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var plot_1 = require("../../core/plot");
var constants_1 = require("./constants");
var heatmap_layer_1 = require("../../layers/heatmap-layer");
var layer_group_1 = require("../../core/layer/layer-group");
var Heatmap = /** @class */ (function (_super) {
    tslib_1.__extends(Heatmap, _super);
    function Heatmap() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 地图类型
         */
        _this.type = plot_1.Plot.PlotType.Heatmap;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Heatmap.prototype.getDefaultOptions = function () {
        return Heatmap.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Heatmap.prototype.createLayers = function (source) {
        this.heatmapLayer = new heatmap_layer_1.HeatmapLayer(tslib_1.__assign({ source: source }, (0, util_1.pick)(this.options, heatmap_layer_1.HeatmapLayer.LayerOptionsKeys)));
        var layerGroup = new layer_group_1.LayerGroup([this.heatmapLayer]);
        if (this.options.label) {
            this.labelLayer = this.createLabelLayer(this.source, this.options.label, this.options);
            layerGroup.addLayer(this.labelLayer);
        }
        return layerGroup;
    };
    /**
     * 更新图层
     */
    Heatmap.prototype.updateLayers = function (options) {
        var heatMapLayerConfig = (0, util_1.pick)(options, heatmap_layer_1.HeatmapLayer.LayerOptionsKeys);
        this.heatmapLayer.update(heatMapLayerConfig);
        this.updateLabelLayer(this.source, options.label, this.options, this.labelLayer);
    };
    /**
     * 实现 legend 配置项
     */
    Heatmap.prototype.getLegendOptions = function () {
        var _a;
        var sizeLegendItems = this.heatmapLayer.layer.getLegendItems('size');
        if (Array.isArray(sizeLegendItems) && sizeLegendItems.length !== 0) {
            var min = sizeLegendItems[0].value;
            var max = sizeLegendItems[sizeLegendItems.length - 1].value;
            var colors = (_a = this.heatmapLayer.options.style) === null || _a === void 0 ? void 0 : _a.colorsRamp.map(function (_a) {
                var color = _a.color;
                return color;
            });
            return { type: 'continue', min: min, max: max, colors: colors };
        }
        return {};
    };
    /**
     * 默认配置项
     */
    Heatmap.DefaultOptions = constants_1.DEFAULT_OPTIONS;
    return Heatmap;
}(plot_1.Plot));
exports.Heatmap = Heatmap;
