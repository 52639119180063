const initialState = {
    builders: null,
    count_builders: 0,

    builder: null,
    builderIndex: null,

    request_builder_status: false,
    form_status_request: false,
    form_errors: null,

    request_status_form: false,
    builder_form_edit: null,
};

const builders = (state = initialState, action) => {
    switch (action.type) {

        case 'BUILDER_FORM_EDIT':
            return {
                ...state,
                builder_form_edit: action.data || null,
            };

        case 'REQUEST_BUILDER_FORM_STATUS':
            return {
                ...state,
                request_status_form: action.status || false,
            };

        case 'BUILDER_FORM_ERRORS':
            return {
                ...state,
                form_errors: action.errors,
            };
        case 'BUILDER_FORM_STATUS_REQ':
            return {
                ...state,
                form_status_request: action.status,
            };

        case 'UPDATE_BUILDER':
            return {
                ...state,
                builder: action.data,
                builderIndex: action.builderIndex,
            };

        case 'UPDATE_BUILDERS_DICR':
            return {
                ...state,
                builders: state.builders.filter((item) => {
                    if (String(item._id) != String(action.id)) {
                        return item;
                    }
                }),
            };

        case 'UPDATE_BUILDERS':
            return {
                ...state,
                builders: action?.data?.data && action?.data?.data.length ? action?.data?.data : [],
                count_builders: action?.data?.count || 0,
            };

        case 'CHANGE_BUILDER':
            return {
                ...state,
                builders: state.builders.map((item, index) => {
                    if (index == action.index) {
                        item.title = action.title;
                    }

                    return item;
                }),
            };

        case 'REQUEST_BUILDER_STATUS':
            return {
                ...state,
                request_builder_status: action.status,
            };

        default:
            return state;
    }
};

export default builders;
