import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { budget } from '@/Services/apollo/gqls';
import styles from '../../../../styles/index.module.scss';
import markerTypeParse from '../../marker-type-parse';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Input, message, Modal, Popconfirm, Select } from 'antd';
import { MOList, MPList } from '@/Common/budget_markers';
import CreateGroupCategoryModal from './create-category';
import keyRandom from 'crypto-random-string';

const { Option } = Select;

const EditBudgetGroupCategories = () => {
    let params = useParams();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [edit_category, set_edit_category] = useState(null);
    const [category_title, set_category_title] = useState('');
    const [marker_type, set_marker_type] = useState('');
    const [premise_type, set_premise_type] = useState('');
    const [
        select_premise_for_edit_category,
        set_select_premise_for_edit_category,
    ] = useState(null);

    const [create_category_form_modal, set_create_category_form_modal] =
        useState(false);

    let getDefaultCategories = useQuery(budget.getGroupCategories, {
        variables: {
            group_id: params?.id,
        },
    });

    let createGroupCategory = useMutation(budget.createGroupBudgetCategory, {
        fetchPolicy: 'no-cache',
        refetchQueries: [
            {
                query: budget.getGroupCategories,
                variables: {
                    group_id: params?.id,
                },
            },
        ],
    });

    let removeBudgetGroupCategory = useMutation(
        budget.removeBudgetGroupCategory
    );

    let editGroupCategoryOfBudget = useMutation(
        budget.editGroupCategoryOfBudget
    );

    const renderTypeMarker = (cat) => {
        return cat.marker_type
            ? 'МО'
            : cat.premise
            ? 'П'
            : cat.premise_type
            ? 'МП'
            : 'K';
    };

    const renderChildCats = (cats) => {
        return (
            <div>
                {cats && cats.length ? (
                    cats.map((cat, index) => {
                        return (
                            <div
                                key={cat._id + index}
                                className={styles.itemCategoryChildItem}
                            >
                                <section className={styles.itemCategoryChild}>
                                    <div
                                        className={styles.itemCategoryChildHead}
                                    >
                                        <h4 title={cat?._id}>
                                            {cat.title}
                                            <b>{renderTypeMarker(cat)} </b>{' '}
                                            {markerTypeParse(cat) ? (
                                                <small>
                                                    {markerTypeParse(cat)}
                                                </small>
                                            ) : (
                                                ''
                                            )}
                                            {cat?.associate_premise_with_category_status && (
                                                <small
                                                    style={{
                                                        backgroundColor:
                                                            '#4079c6',
                                                    }}
                                                >
                                                    Основное помещение
                                                </small>
                                            )}
                                            {cat?.associate_premise_with_category && (
                                                <small
                                                    style={{
                                                        backgroundColor:
                                                            '#57b842',
                                                    }}
                                                >
                                                    Связь с помещением:{' '}
                                                    {
                                                        cat
                                                            ?.associate_premise_with_category
                                                            ?.title
                                                    }
                                                </small>
                                            )}
                                        </h4>
                                        <div
                                            className={styles.buttonsOfCategory}
                                        >
                                            <EditOutlined
                                                onClick={() => {
                                                    set_category_title(
                                                        cat?.title
                                                    );
                                                    setIsModalVisible(true);
                                                    set_edit_category(cat);
                                                    set_select_premise_for_edit_category(
                                                        cat?.associate_premise_with_category
                                                            ? cat
                                                                  ?.associate_premise_with_category
                                                                  ?._id
                                                            : null
                                                    );
                                                    set_marker_type(
                                                        cat?.marker_type
                                                    );
                                                    set_premise_type(
                                                        cat?.premise_type
                                                    );
                                                }}
                                                className={
                                                    styles.buttonsOfCategoryEdit
                                                }
                                            />

                                            <Popconfirm
                                                placement="left"
                                                title="Категория и товары будут удалены?"
                                                onConfirm={() => {
                                                    removeBudgetGroupCategory[0](
                                                        {
                                                            variables: {
                                                                id: cat._id,
                                                            },
                                                            refetchQueries: [
                                                                {
                                                                    query: budget.getGroupCategories,
                                                                    variables: {
                                                                        group_id:
                                                                            params?.id,
                                                                    },
                                                                },
                                                            ],
                                                        }
                                                    );
                                                }}
                                                onCancel={() => {}}
                                                okText="Удалить"
                                                cancelText="Нет"
                                            >
                                                <DeleteOutlined />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                    {renderChildCats(
                                        cat.child_categories &&
                                            cat.child_categories.length
                                            ? cat.child_categories
                                            : []
                                    )}
                                </section>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className={styles.mainBlock}>
                <CreateGroupCategoryModal
                    renderTypeMarker={renderTypeMarker}
                    group_id={params?.id}
                    createGroupCategory={createGroupCategory}
                    premises={
                        getDefaultCategories?.data?.getPremisesGroup || []
                    }
                    categories={
                        getDefaultCategories?.data?.getGroupCategories || []
                    }
                    isModalVisible={create_category_form_modal}
                    closeModal={() => set_create_category_form_modal(false)}
                />

                <Modal
                    title={`Редактирование категории: ${edit_category?.title}`}
                    wrapClassName={styles.modalCreateCloneTemplate}
                    visible={isModalVisible}
                    onOk={() => {
                        editGroupCategoryOfBudget[0]({
                            variables: {
                                fields: {
                                    title: category_title,
                                    marker_type,
                                    premise_type,
                                    category_id: edit_category?._id,
                                    premise_category:
                                        select_premise_for_edit_category,
                                },
                            },
                            refetchQueries: [
                                {
                                    query: budget.getGroupCategories,
                                    variables: {
                                        group_id: params?.id,
                                    },
                                },
                            ],
                            onCompleted: () => {
                                set_category_title('');
                                setIsModalVisible(false);
                                set_edit_category(null);
                                set_select_premise_for_edit_category(null);
                                set_marker_type('');
                                set_premise_type('');
                            },
                            onError: (err) => {
                                return message.warning(err?.message);
                            },
                        });
                    }}
                    onCancel={() => setIsModalVisible(false)}
                    width="45%"
                    okText={
                        editGroupCategoryOfBudget[1].loading
                            ? 'Подождите...'
                            : 'Редактировать'
                    }
                    cancelText="отмена"
                >
                    <Input
                        value={category_title}
                        onChange={(val) => set_category_title(val.target.value)}
                        placeholder="Введите название категории"
                    />

                    {edit_category?.marker_type ||
                    edit_category?.premise_type ? (
                        <div className={styles.markersBlock}>
                            <div
                                className={styles.markersBlockSection}
                                style={{
                                    marginRight: 10,
                                }}
                            >
                                <h5>МО</h5>
                                <Select
                                    dropdownStyle={{ zIndex: 999999999 }}
                                    placeholder="Выберите маркер МО"
                                    size="large"
                                    onChange={(val) => {
                                        set_marker_type(val);
                                        set_premise_type('');
                                    }}
                                    value={marker_type}
                                >
                                    <option value="">Выберите маркер МО</option>
                                    {MOList.map((marker, index) => (
                                        <Option
                                            key={`${marker?.value}_${index}`}
                                            value={marker?.value}
                                        >
                                            {marker?.title}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.markersBlockSection}>
                                <h5>МП</h5>
                                <Select
                                    dropdownStyle={{ zIndex: 999999999 }}
                                    placeholder="Выберите маркер МП"
                                    size="large"
                                    onChange={(val) => {
                                        set_marker_type('');
                                        set_premise_type(val);
                                    }}
                                    value={premise_type}
                                >
                                    <option value="">Выберите маркер МП</option>
                                    {MPList.map((marker, index) => (
                                        <Option
                                            key={`${marker?.value}_${index}`}
                                            value={marker?.value}
                                        >
                                            {marker?.title}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {edit_category?.associate_premise_with_category ||
                    (edit_category?.premise &&
                        !edit_category?.associate_premise_with_category_status) ? (
                        <div className={styles.markersBlock}>
                            <div
                                className={styles.markersBlockSection}
                                style={{
                                    marginRight: 10,
                                }}
                            >
                                <h5>
                                    Выбрать помещение из списка (необязательно)
                                </h5>
                                {getDefaultCategories?.data?.getPremisesGroup &&
                                getDefaultCategories?.data?.getPremisesGroup
                                    .length ? (
                                    <Select
                                        dropdownStyle={{ zIndex: 999999999 }}
                                        className={styles.typeMarkerSelect}
                                        placeholder="Выбрать помещение из списка (необязательно)"
                                        defaultValue=""
                                        value={select_premise_for_edit_category}
                                        size="large"
                                        onChange={(value) =>
                                            set_select_premise_for_edit_category(
                                                value
                                            )
                                        }
                                    >
                                        {getDefaultCategories?.data?.getPremisesGroup.map(
                                            (item) => (
                                                <Option
                                                    key={keyRandom({
                                                        length: 15,
                                                    })}
                                                    className={
                                                        styles.typeMarkerOption
                                                    }
                                                    value={item?._id}
                                                >
                                                    {item?.title}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </Modal>

                <div className={styles.headPageBudget}>
                    <h1>
                        Редактирование группы{' '}
                        {
                            getDefaultCategories?.data
                                ?.getGroupCategoryBudgetInfo?.title
                        }
                    </h1>
                    <div className={styles.rightButtonCategories}>
                        <div></div>
                        <div></div>

                        {params?.template_id ? (
                            <div className={styles.rightButtons}>
                                <span
                                    onClick={() =>
                                        set_create_category_form_modal(true)
                                    }
                                >
                                    Добавить категорию
                                </span>
                                <Link
                                    style={{ margin: 0 }}
                                    to={`/budget/edit/${params?.template_id}`}
                                >
                                    Вернуться
                                </Link>
                            </div>
                        ) : (
                            <Link style={{ margin: 0 }} to={`/budget`}>
                                Шаблоны
                            </Link>
                        )}
                    </div>
                </div>

                {getDefaultCategories.loading ? (
                    <div className={styles.defaultBlockCenter}>
                        <h2>Загрузка</h2>
                    </div>
                ) : getDefaultCategories.data.getGroupCategories &&
                  getDefaultCategories.data.getGroupCategories.length ? (
                    <div className={styles.listCategories}>
                        {getDefaultCategories.data.getGroupCategories.map(
                            (cat, index) => {
                                return (
                                    <div
                                        key={cat._id + index}
                                        className={styles.itemCategory}
                                    >
                                        <section
                                            className={styles.itemCategoryChild}
                                        >
                                            <div
                                                className={
                                                    styles.itemCategoryChildHead
                                                }
                                            >
                                                <h4>
                                                    {cat.title}
                                                    <b>
                                                        {renderTypeMarker(cat)}{' '}
                                                    </b>{' '}
                                                    {markerTypeParse(cat) ? (
                                                        <small>
                                                            {markerTypeParse(
                                                                cat
                                                            )}
                                                        </small>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {cat?.associate_premise_with_category_status && (
                                                        <small
                                                            style={{
                                                                backgroundColor:
                                                                    '#4079c6',
                                                            }}
                                                        >
                                                            Основное помещение
                                                        </small>
                                                    )}
                                                    {cat?.associate_premise_with_category && (
                                                        <small
                                                            style={{
                                                                backgroundColor:
                                                                    '#57b842',
                                                            }}
                                                        >
                                                            Связь с помещением:{' '}
                                                            {
                                                                cat
                                                                    ?.associate_premise_with_category
                                                                    ?.title
                                                            }
                                                        </small>
                                                    )}
                                                </h4>
                                                <div
                                                    className={
                                                        styles.lineItemCat
                                                    }
                                                ></div>
                                                <div
                                                    className={
                                                        styles.buttonsOfCategory
                                                    }
                                                >
                                                    <EditOutlined
                                                        onClick={() => {
                                                            set_category_title(
                                                                cat?.title
                                                            );
                                                            setIsModalVisible(
                                                                true
                                                            );
                                                            set_edit_category(
                                                                cat
                                                            );
                                                            set_select_premise_for_edit_category(
                                                                cat?.associate_premise_with_category
                                                                    ? cat?.associate_premise_with_category
                                                                    : null
                                                            );
                                                            set_marker_type(
                                                                cat?.marker_type
                                                            );
                                                            set_premise_type(
                                                                cat?.premise_type
                                                            );
                                                        }}
                                                        className={
                                                            styles.buttonsOfCategoryEdit
                                                        }
                                                    />
                                                    <Popconfirm
                                                        placement="left"
                                                        title="Категория и товары будут удалены?"
                                                        onConfirm={() => {
                                                            removeBudgetGroupCategory[0](
                                                                {
                                                                    variables: {
                                                                        id: cat._id,
                                                                    },
                                                                    refetchQueries:
                                                                        [
                                                                            {
                                                                                query: budget.getGroupCategories,
                                                                                variables:
                                                                                    {
                                                                                        group_id:
                                                                                            params?.id,
                                                                                    },
                                                                            },
                                                                        ],
                                                                }
                                                            );
                                                        }}
                                                        onCancel={() => {}}
                                                        okText="Удалить"
                                                        cancelText="Нет"
                                                    >
                                                        <DeleteOutlined />
                                                    </Popconfirm>
                                                </div>
                                            </div>

                                            {renderChildCats(
                                                cat.child_categories
                                            )}
                                        </section>
                                    </div>
                                );
                            }
                        )}
                    </div>
                ) : (
                    <div className={styles.defaultBlockCenter}>
                        <h2>Категорий не найдено</h2>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditBudgetGroupCategories;
