import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { api } from '@/Services';
import styles from './styles/index.module.scss';
import { Button } from 'antd';
import Filter from './Helpers/filter';
import FlatItem from './Helpers/flat-item';

const Flats = () => {
    const [select_realestate, set_select_realestate] = useState('');
    const [search_text, set_search_text] = useState('');
    const [sort, set_sort] = useState('');

    const [start_date, set_start_date] = useState('');
    const [end_date, set_end_date] = useState('');

    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const getFlatsState = useSelector((state) => state.flats);
    const scrollRef = useRef(null);

    useEffect(() => {
        dispatch({
            type: 'CLEAR_FLATS',
        });

        dispatch(
            api.flats.filterFlats(
                {
                    realestate:
                        select_realestate != ''
                            ? JSON.stringify(select_realestate)
                            : '',
                    text_search: search_text,
                },
                page
            )
        );
    }, []);

    const getMoreFlats = () => {
        dispatch(
            api.flats.filterFlats(
                {
                    realestate: JSON.stringify(select_realestate),
                    text_search: search_text,
                    sort: sort,
                    dates: JSON.stringify({
                        start: start_date,
                        end: end_date,
                    }),
                },
                page + 1
            )
        );
        setPage(page + 1);
    };

    const deleteFlat = (id, field) => {
        return dispatch(api.flats.delete(id, field));
    };

    const promotionFlat = (id) => {
        return dispatch(api.flats.promotionFlat(id));
    };

    const soldFlat = (status, id) => {
        return dispatch(api.flats.soldFlat(id, status));
    };

    const search = (value, type) => {
        setPage(1);

        dispatch({
            type: 'CLEAR_FLATS',
        });

        if (type == 'select') {
            set_select_realestate(value);
        } else if (type == 'text') {
            set_search_text(value);
        } else if (type == 'date') {
            set_start_date(value[0]);
            set_end_date(value[1]);
        } else {
            set_sort(value);
        }

        let fields = {
            realestate:
                type == 'select'
                    ? JSON.stringify(value)
                    : select_realestate != ''
                    ? JSON.stringify(select_realestate)
                    : '',
            text_search: type == 'text' ? value : search_text,
            sort: type == 'sort' ? value : sort,
            dates: JSON.stringify({
                start: type == 'date' ? value[0] : start_date,
                end: type == 'date' ? value[1] : end_date,
            }),
        };

        return dispatch(api.flats.filterFlats(fields));
    };

    return (
        <div ref={scrollRef} className={styles.wrapper}>
            <content className="cities-page-block">
                <div className={styles.headPageFlats}>
                    <div className="header-page-main">
                        <h1>Квартиры</h1>

                        <Link to={`/apartments/flats/templates`}>
                            <Button type="primary" size="large">
                                Шаблоны квартир
                            </Button>
                        </Link>
                    </div>

                    <Filter
                        search={(value, type) => search(value, type)}
                        search_text={search_text}
                        select_realestate={select_realestate}
                        set_search_text={set_search_text}
                        set_select_realestate={set_select_realestate}
                        api={api}
                        flatState={getFlatsState}
                        dispatch={dispatch}
                        sort={sort}
                    />
                </div>

                <div className="content-items-flat">
                    {getFlatsState.list && getFlatsState.list.length ? (
                        getFlatsState.list.map((flat) => (
                            <FlatItem
                                key={flat?._id}
                                promotionFlat={promotionFlat}
                                flat={flat}
                                soldFlat={soldFlat}
                                deleteFlat={deleteFlat}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                </div>

                {getFlatsState?.list && getFlatsState?.list.length == 0 ? (
                    <div className={styles.notFound}>
                        <h3>Квартир не найдено</h3>
                    </div>
                ) : (
                    <></>
                )}

                {getFlatsState.request_flats_status == false ? (
                    <span
                        onClick={() => {
                            getMoreFlats();
                        }}
                        className="more-flats"
                    >
                        Показать больше
                    </span>
                ) : (
                    <span className="more-flats">Загружаем...</span>
                )}
            </content>
        </div>
    );
};

export default Flats;
