import axios from './axios';
import auth from './Auth';
import cities from './Cities';
import builders from './Builders';
import apartments from './Apartments';
import files from './files';
import blocks from './Blocks';
import flats from './Flats';
import statistic from './Statistic';
import users from './Users';
import pages from './Pages';
import blog from './Blog';
import library from './Library';
import widgets from './Widgets';
import logs from './Logs';
import redirect from './Redirect';
import games from './Games';
import budget from './Budget';
import chess from './chess';
import realestate from './Realestate';

export default {
    auth,
    cities,
    builders,
    apartments,
    files,
    blocks,
    axios,
    flats,
    statistic,
    users,
    pages,
    blog,
    library,
    games,
    widgets,
    logs,
    redirect,
    budget,
    chess,
    realestate
};
