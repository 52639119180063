class GetUrlImage {
    constructor(content) {
        this.content = content;
        this.regex = /(https?:\/\/[^ ]*\.(?:gif|png|jpg|jpeg|webp))/i;
        this.src = '';
    }
    regexLink() {
        let regTest = new RegExp(this.regex).test(this.content);

        if (regTest) {
            this.src = this.regex.exec(this.content)[1];
            this.src = this.src.replace('https://files.logovo.kz', '');
        }

        return this.src;
    }
}

export default GetUrlImage;
