import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Slider, Input, Button, Radio, Collapse } from 'antd';

const { Panel } = Collapse;

const WidgetGroupFormEdit = ({ widget, styles, api }) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    //options
    const [border_radius, set_border_radius] = useState(widget.options?.border_radius || 0);
    const [height, set_height] = useState(widget.options?.height || 400);
    const [background_button, set_background_button] = useState(widget.options?.background_button || '');
    const [color_button, set_color_button] = useState(widget.options?.color_button || '');
    const [bottom_margin_button, set_bottom_margin_button] = useState(widget.options?.bottom_margin_button || 5);
    const [side_margin_button, set_side_margin_button] = useState(widget.options?.side_margin_button || 2);
    const [border_radius_button, set_border_radius_button] = useState(widget.options?.border_radius_button || 2);
    const [position_buttons, set_position_buttons] = useState(widget.options?.position_buttons || 'center');
    const [open_line_height, set_open_line_height] = useState(false);
    //end options

    const updateWidgetOptions = () => {
        let widgetOptions = {
            border_radius,
            height,
            background_button,
            color_button,
            bottom_margin_button,
            side_margin_button,
            border_radius_button,
            position_buttons,
        };

        return dispatch(api.widgets.updateWidgetOptions(id, widgetOptions));
    };

    return (
        <div
            className={`statistic-block ${styles.blockGroup360} ${styles.pageWrapper}`}
        >

            <div
                className={`${styles.lineExampleHeight} ${
                    open_line_height && styles.lineExampleHeightOpened
                }`}
                style={{ height }}
            ></div>

            <Collapse>
                <Panel
                    forceRender={true}
                    header="Основные опции"
                    key="1"
                >
                    <div className={styles.blockOptions}>
                        <div className={styles.blockContent}>
                            <div className={styles.itemOption}>
                                <p>Скругление углов</p>
                                <Slider
                                    step={1}
                                    min={2}
                                    max={15}
                                    defaultValue={border_radius}
                                    onChange={(value) => set_border_radius(value)}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Скругление углов для кнопок</p>
                                <Slider
                                    step={1}
                                    min={2}
                                    max={15}
                                    defaultValue={border_radius_button}
                                    onChange={(value) => {
                                        set_border_radius_button(value);
                                    }}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Высота</p>
                                <Slider
                                    step={100}
                                    min={400}
                                    max={800}
                                    defaultValue={height}
                                    onChange={(value) => {
                                        setTimeout(() => {
                                            set_open_line_height(false);
                                        }, 2200);

                                        set_height(value);
                                        set_open_line_height(true);
                                    }}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Нижний отступ для кнопок</p>
                                <Slider
                                    step={1}
                                    min={5}
                                    max={15}
                                    defaultValue={bottom_margin_button}
                                    onChange={(value) => {
                                        set_bottom_margin_button(value);
                                    }}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Боковые отступы для кнопок</p>
                                <Slider
                                    step={1}
                                    min={2}
                                    max={10}
                                    defaultValue={side_margin_button}
                                    onChange={(value) => {
                                        set_side_margin_button(value);
                                    }}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Фон кнопки</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_background_button(value.target.value)
                                    }
                                    value={background_button}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Цвет текста кнопки</p>
                                <Input
                                    type="color"
                                    onChange={(value) =>
                                        set_color_button(value.target.value)
                                    }
                                    value={color_button}
                                />
                            </div>
                            <div className={styles.itemOption}>
                                <p>Позиционирование кнопок</p>
                                <Radio.Group
                                    defaultValue={position_buttons}
                                    onChange={(val) =>
                                        set_position_buttons(val.target.value)
                                    }
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="left">Слева</Radio.Button>
                                    <Radio.Button value="center">
                                        Центр
                                    </Radio.Button>
                                    <Radio.Button value="right">
                                        Справа
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>
            <Button
                className={styles.buttonSave}
                type="primary"
                onClick={updateWidgetOptions}
            >
                Сохранить настройки
            </Button>
        </div>
    );
};

export default WidgetGroupFormEdit;
