import Realtors from '@/Containers/Admin/Realtors';
import CreateRealtor from '@/Containers/Admin/Realtors/create';
import EditRealtor from '@/Containers/Admin/Realtors/edit';
import Leads from '@/Containers/Admin/Realtors/Leads';
import ListLeads from '@/Containers/Admin/Realtors/Leads/helpers/listLeads';

export default [
    {
        path: '/realtors',
        element: <Realtors />,
    },
    {
        path: '/realtors/create',
        element: <CreateRealtor />,
    },
    {
        path: '/realtors/edit/:id',
        element: <EditRealtor />,
    },
    {
        path: '/realtors/edit/:id/leads',
        element: <Leads />,
    },
    {
        path: '/realtors/edit/:realtor_id/leads/:company_id',
        element: <ListLeads />,
    },
];
