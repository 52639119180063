const initialState = {
    list: [],
    data: {},

    page: 0,
    FR: false,

    request_status: false,
};

const redirect = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_REDIRECT_INDEX':
            return {
                ...state,
                list: state.list.map((item, index) => {
                    if (index == action.index) {
                        item.redirect_url = action.url;
                    }
                    return item;
                }),
            };

        case 'REDIRECT_FR':
            return {
                ...state,
                FR: true,
            };

        case 'REDIRECT_PAGE':
            return {
                ...state,
                page: action.page,
            };

        case 'CLEAR_LIST_REDIRECTS':
            return {
                ...state,
                list: [],
            };

        case 'SEARCH_REDIRECT_LINKS':
            return {
                ...state,
                list: action.data,
            };

        case 'UPDATE_REDIRECT_LINKS':
            return {
                ...state,
                data: action.data,
            };

        case 'REQUEST_STATUS_REDIRECT_LINKS':
            return {
                ...state,
                request_status: action.status,
            };

        default:
            return state;
    }
};

export default redirect;
