import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Select } from '@/Components';

import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Input, message, Modal, Popconfirm } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { budget } from '@/Services/apollo/gqls';

import styles from '../styles/index.module.scss';

import { MOList, MPList } from '@/Common/budget_markers';
import markerTypeParse from './Helpers/marker-type-parse';
import { DeleteOutlined } from '@ant-design/icons';

const BudgetCategories = () => {
    const [form, set_form] = useState(false);
    const [form_select_cat, set_form_select_cat] = useState(false);
    const [title, set_title] = useState('');
    const [select_category, set_select_category] = useState(null);
    const [marker, set_marker] = useState(false);
    const [marker_type, set_marker_type] = useState('');
    const [premise, set_premise] = useState(false);
    const [premise_type, set_premise_type] = useState('');
    const [group_title, set_group_title] = useState('');
    const [
        associate_premise_with_category,
        set_associate_premise_with_category,
    ] = useState('');
    const [
        associate_premise_with_category_status,
        set_associate_premise_with_category_status,
    ] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);

    let getDefaultCategories = useQuery(budget.getDefaultCategories);
    let createDefaultBudgetCategory = useMutation(
        budget.createDefaultBudgetCategory,
        {
            fetchPolicy: 'no-cache',
            refetchQueries: [
                {
                    query: budget.getDefaultCategories,
                },
            ],
            onCompleted: () => {
                set_title('');
                set_select_category(null);
                return set_form(!form);
            },
        }
    );

    let createGroupBudgetCategories = useMutation(
        budget.createGroupBudgetCategories,
        {
            fetchPolicy: 'no-cache',
            refetchQueries: [
                {
                    query: budget.getGroupBudgetCategories,
                },
            ],
            onCompleted: () => {
                message.success('Группа успешно создана');
                return;
            },
        }
    );

    let removeDefaultBudgetCategory = useMutation(
        budget.removeDefaultBudgetCategory
    );

    const addCategory = () => {
        let variables = {};

        variables.title = title;
        variables.marker = marker;
        variables.marker_type = marker_type;
        variables.premise = premise_type ? false : premise;
        variables.premise_type = premise_type;
        variables.associate_premise_with_category =
            associate_premise_with_category;
        variables.associate_premise_with_category_status =
            associate_premise_with_category_status;

        if (select_category) {
            variables.parent_category = select_category._id;
        }

        if (!createDefaultBudgetCategory[1].loading) {
            set_select_category(null);
            set_marker_type('');
            set_premise(false);
            set_marker(false);
            set_premise_type('');
            set_associate_premise_with_category('');
            set_associate_premise_with_category_status(false);

            createDefaultBudgetCategory[0]({
                variables: {
                    category: variables,
                },
                refetchQueries: [{ query: budget.getDefaultCategories }],
            });
        }
    };

    const renderChildCats = (cats) => {
        return (
            <div>
                {cats && cats.length ? (
                    cats.map((cat, index) => {
                        return (
                            <div
                                key={cat._id + index}
                                className={styles.itemCategoryChildItem}
                            >
                                <section className={styles.itemCategoryChild}>
                                    <div
                                        className={styles.itemCategoryChildHead}
                                    >
                                        <h4>
                                            {cat.title}
                                            <b>
                                                {cat.marker_type
                                                    ? ' МО'
                                                    : cat.premise
                                                    ? ' П'
                                                    : cat.premise_type
                                                    ? ' МП'
                                                    : ' K'}{' '}
                                            </b>{' '}
                                            {markerTypeParse(cat) ? (
                                                <small>
                                                    {markerTypeParse(cat)}
                                                </small>
                                            ) : (
                                                ''
                                            )}{' '}
                                        </h4>

                                        <Popconfirm
                                            placement="left"
                                            title="Категория будет удалена?"
                                            onConfirm={() => {
                                                removeDefaultBudgetCategory[0]({
                                                    variables: {
                                                        id: cat._id,
                                                    },
                                                    refetchQueries: [
                                                        {
                                                            query: budget.getDefaultCategories,
                                                        },
                                                    ],
                                                });
                                            }}
                                            onCancel={() => {}}
                                            okText="Удалить"
                                            cancelText="Нет"
                                        >
                                            <DeleteOutlined />
                                        </Popconfirm>
                                    </div>
                                    {renderChildCats(
                                        cat.child_categories &&
                                            cat.child_categories.length
                                            ? cat.child_categories
                                            : []
                                    )}
                                </section>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className={styles.mainBlock}>
                <Modal
                    title="Создание группы категорий"
                    wrapClassName={styles.modalCreateCloneTemplate}
                    visible={isModalVisible}
                    onOk={() => {
                        createGroupBudgetCategories[0]({
                            variables: {
                                title: group_title,
                            },
                            onCompleted: () => {
                                setIsModalVisible(false);
                            },
                        });
                    }}
                    onCancel={() => setIsModalVisible(false)}
                    width="45%"
                    okText={
                        createGroupBudgetCategories[1]?.loading
                            ? 'Подождите...'
                            : 'Создать'
                    }
                    cancelText="отмена"
                >
                    <Input
                        value={group_title}
                        onChange={(val) => set_group_title(val.target.value)}
                        placeholder="Введите название группы"
                    />
                </Modal>

                <div className={styles.headPageBudget}>
                    <h1>Категории</h1>
                    <div className={styles.rightButtonCategories}>
                        <span
                            onClick={() => setIsModalVisible(!isModalVisible)}
                        >
                            Создать группу
                        </span>
                        <Link to="/budget/categories/groups">Группы</Link>
                        {!getDefaultCategories.loading && (
                            <span onClick={() => set_form(!form)}>
                                Добавить категорию
                            </span>
                        )}
                    </div>
                </div>

                {form && (
                    <div className={styles.formCreateCategory}>
                        <div
                            className={
                                form_select_cat
                                    ? `${styles.form_select_cat} ${styles.form_select_cat_opened}`
                                    : styles.form_select_cat
                            }
                        >
                            <div className={styles.form_select_cat_content}>
                                <Select.Categories
                                    selectCategory={(cat) => {
                                        set_form_select_cat(false);
                                        set_select_category(cat);

                                        if (cat.premise) {
                                            set_premise_type('PRODUCT');
                                            set_marker_type('');
                                            set_marker(false);
                                        } else {
                                            set_premise_type('');
                                        }
                                    }}
                                    data={
                                        getDefaultCategories.data
                                            .getDefaultBudgetCategories
                                    }
                                    dataTwo={
                                        getDefaultCategories.data
                                            .getBudgetCategoriesTest
                                    }
                                />
                                <Icon
                                    size={2}
                                    onClick={() =>
                                        set_form_select_cat(!form_select_cat)
                                    }
                                    color="#fff"
                                    className={
                                        styles.form_select_cat_close_icon
                                    }
                                    path={mdiClose}
                                />
                            </div>
                        </div>

                        <section className={styles.sectionFormCat}>
                            <span>Название категории*</span>
                            <input
                                value={title}
                                onChange={(val) => {
                                    if (!associate_premise_with_category) {
                                        set_title(val.target.value);
                                    }
                                }}
                                placeholder="Название категории*"
                            />

                            <div className={styles.blockSelectCat}>
                                <span>Категория (необязательно)</span>
                                {select_category && (
                                    <div>
                                        <h4>{select_category.title}</h4>
                                    </div>
                                )}
                                <input
                                    onClick={() =>
                                        set_form_select_cat(!form_select_cat)
                                    }
                                    className={styles.buttonSelectCat}
                                    type="submit"
                                    value="Выбрать категорию"
                                />
                            </div>

                            <div className={styles.checkBoxesContent}>
                                <div className={styles.checkBoxInput}>
                                    <div
                                        className={styles.checkBoxInputContent}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={marker}
                                            onChange={() => {
                                                set_premise(false);
                                                set_premise_type('');
                                                set_marker(!marker);
                                                set_marker_type(
                                                    !marker
                                                        ? MOList[0].value
                                                        : ''
                                                );
                                            }}
                                            value="Маркер"
                                        />
                                        <p>Маркер</p>
                                    </div>
                                    {marker || select_category?.premise ? (
                                        <div
                                            className={`${styles.checkBoxInputContent} ${styles.checkBoxInputContentSelect}`}
                                        >
                                            <select
                                                onChange={(value) => {
                                                    set_marker(true);
                                                    set_marker_type(
                                                        value.target.value
                                                    );
                                                    set_premise(false);
                                                    set_premise_type('');
                                                }}
                                                value={marker_type}
                                            >
                                                {MOList.map((item) => (
                                                    <option
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                <div
                                    className={styles.checkBoxInput}
                                    style={{ marginLeft: 5 }}
                                >
                                    {!select_category?.premise ? (
                                        <div
                                            className={
                                                styles.checkBoxInputContent
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                checked={premise}
                                                onChange={() => {
                                                    set_marker(false);
                                                    set_premise_type('');
                                                    set_premise(
                                                        select_category?.premise
                                                            ? select_category?.premise
                                                            : !premise
                                                    );
                                                    if (!premise == false) {
                                                        set_associate_premise_with_category(
                                                            ''
                                                        );
                                                    }
                                                }}
                                                value="Помещение"
                                            />
                                            <p>Помещение</p>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {premise ? (
                                        <div
                                            className={
                                                styles.checkBoxInputContent
                                            }
                                            style={{ marginLeft: 10 }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={
                                                    associate_premise_with_category_status
                                                }
                                                onChange={() => {
                                                    set_associate_premise_with_category(
                                                        ''
                                                    );
                                                    set_associate_premise_with_category_status(
                                                        !associate_premise_with_category_status
                                                    );
                                                }}
                                                value="Основное"
                                            />
                                            <p>Основное</p>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {select_category?.premise ? (
                                        <div
                                            className={`${styles.checkBoxInputContent}`}
                                        >
                                            <select
                                                onChange={(value) => {
                                                    set_premise(false);
                                                    set_premise_type(
                                                        value.target.value
                                                    );
                                                    set_marker(false);
                                                    set_marker_type('');
                                                }}
                                                value={premise_type}
                                            >
                                                {MPList.map((item) => (
                                                    <option
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>

                            {premise ? (
                                <div className={styles.sectionListOptions}>
                                    <p>Связать категорию с помещением</p>
                                    <select
                                        value={associate_premise_with_category}
                                        onChange={(value) => {
                                            set_associate_premise_with_category_status(
                                                false
                                            );
                                            set_associate_premise_with_category(
                                                value.target.value
                                            );

                                            let getCat =
                                                getDefaultCategories.data?.getPremiseBudgetCategories?.filter(
                                                    (item) =>
                                                        item._id ==
                                                        value.target.value
                                                );
                                            set_title(getCat[0].title);
                                        }}
                                    >
                                        <option value="">
                                            Выберите помещение
                                        </option>
                                        {getDefaultCategories.loading ? (
                                            <option value="">Загрузка</option>
                                        ) : getDefaultCategories.data
                                              ?.getPremiseBudgetCategories &&
                                          getDefaultCategories.data
                                              ?.getPremiseBudgetCategories
                                              .length ? (
                                            getDefaultCategories.data?.getPremiseBudgetCategories.map(
                                                (item) => (
                                                    <option value={item._id}>
                                                        {item.title}
                                                    </option>
                                                )
                                            )
                                        ) : (
                                            <option value="">
                                                Категорий не найдено
                                            </option>
                                        )}
                                    </select>
                                </div>
                            ) : (
                                <></>
                            )}

                            <input
                                type="submit"
                                onClick={addCategory}
                                value={
                                    createDefaultBudgetCategory[1].loading
                                        ? 'Создаем...'
                                        : 'Добавить'
                                }
                            />
                        </section>
                    </div>
                )}

                {getDefaultCategories.loading ? (
                    <div className={styles.defaultBlockCenter}>
                        <h2>Загрузка</h2>
                    </div>
                ) : getDefaultCategories.data.getDefaultBudgetCategories &&
                  getDefaultCategories.data.getDefaultBudgetCategories
                      .length ? (
                    <div className={styles.listCategories}>
                        {getDefaultCategories.data.getDefaultBudgetCategories.map(
                            (cat, index) => {
                                return (
                                    <div
                                        key={cat._id + index}
                                        className={styles.itemCategory}
                                    >
                                        <section
                                            className={styles.itemCategoryChild}
                                        >
                                            <div
                                                className={
                                                    styles.itemCategoryChildHead
                                                }
                                            >
                                                <h4>
                                                    {cat.title}
                                                    <b>
                                                        {cat.marker_type
                                                            ? ' МО'
                                                            : cat.premise
                                                            ? ' П'
                                                            : cat.premise_type
                                                            ? ' МП'
                                                            : ' K'}
                                                    </b>{' '}
                                                    {markerTypeParse(cat) ? (
                                                        <small>
                                                            {markerTypeParse(
                                                                cat
                                                            )}
                                                        </small>
                                                    ) : (
                                                        ''
                                                    )}
                                                </h4>
                                                <div
                                                    className={
                                                        styles.lineItemCat
                                                    }
                                                ></div>
                                                <Popconfirm
                                                    placement="left"
                                                    title="Категория будет удалена?"
                                                    onConfirm={() => {
                                                        removeDefaultBudgetCategory[0](
                                                            {
                                                                variables: {
                                                                    id: cat._id,
                                                                },
                                                                refetchQueries:
                                                                    [
                                                                        {
                                                                            query: budget.getDefaultCategories,
                                                                        },
                                                                    ],
                                                            }
                                                        );
                                                    }}
                                                    onCancel={() => {}}
                                                    okText="Удалить"
                                                    cancelText="Нет"
                                                >
                                                    <DeleteOutlined />
                                                </Popconfirm>
                                            </div>

                                            {renderChildCats(
                                                cat.child_categories
                                            )}
                                        </section>
                                    </div>
                                );
                            }
                        )}
                    </div>
                ) : (
                    <div className={styles.defaultBlockCenter}>
                        <h2>Категорий не найдено</h2>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BudgetCategories;
