import axios from '../axios';
import { message } from 'antd';

const auth = {
    getBuilder: (builder_id) => async (dispatch) => {

        dispatch({
            type: 'REQUEST_BUILDER_FORM_STATUS',
            status: true,
        });

        try {
            const { data } = await axios.get('/v2/admin/builder/get', {
                params: {
                    builder_id 
                }
            });

            dispatch({
                type: 'BUILDER_FORM_EDIT',
                data: data,
            });

            return dispatch({
                type: 'REQUEST_BUILDER_FORM_STATUS',
                status: false,
            });

        } catch (err) {
            console.error(1, err);

            message.error(
                'Ошибка. Проверьте Ваши права администратора'
            );
            return dispatch({
                type: 'REQUEST_BUILDER_FORM_STATUS',
                status: false,
            });
        }
    },
    editBuilderv2: (fields) => async (dispatch) => {

        dispatch({
            type: 'REQUEST_BUILDER_FORM_STATUS',
            status: true,
        });

        try {
            await axios.post('/v2/admin/builder/edit', fields);

            dispatch({
                type: 'REQUEST_BUILDER_FORM_STATUS',
                status: false,
            });

            return message.success('Застройщик успешно обновлен');
        } catch (err) {
            console.error(1, err);

            message.error(
                'Ошибка. Проверьте Ваши права администратора'
            );
            return dispatch({
                type: 'REQUEST_BUILDER_FORM_STATUS',
                status: false,
            });
        }
    },
    addBuilderv2: (fields, form) => async (dispatch) => {

        dispatch({
            type: 'REQUEST_BUILDER_FORM_STATUS',
            status: true,
        });

        try {
            await axios.post('/v2/admin/builder/create', fields);

            dispatch({
                type: 'REQUEST_BUILDER_FORM_STATUS',
                status: false,
            });

            message.success('Застройщик успешно добавлен');

            form.resetFields();

            return;
        } catch (err) {
            console.error(1, err);

            message.error(
                'Ошибка. Проверьте Ваши права администратора'
            );
            return dispatch({
                type: 'REQUEST_BUILDER_FORM_STATUS',
                status: false,
            });
        }
    },
    saveWebhook: (id, type_webhook, url_webhook, macro_domain, macro_app_secret) => async (dispatch) => {
        try {
            dispatch({
                type: 'BUILDER_FORM_STATUS_REQ',
                status: true,
            });

            await axios.post('/v1/admin/builders/update', {
                type_form: 'webhook',
                id,
                type_webhook,
                url_webhook,
                macro_domain,
                macro_app_secret
            });
            dispatch({
                type: 'BUILDER_FORM_STATUS_REQ',
                status: false,
            });
            message.success('Информация успешно обновлена');
            return;
        } catch (err) {
            dispatch({
                type: 'BUILDER_FORM_STATUS_REQ',
                status: false,
            });
            dispatch({
                type: 'BUILDER_FORM_ERRORS',
                errors: err.response.data || [],
            });

            if (err.response.status === 422 && err.response.data?.url_webhook) {
                return message.error(err.response.data?.url_webhook?.message);
            }

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    getAllObjects: () => async (dispatch) => {
        try {
            let { data } = await axios.get('/v1/admin/realestate/objects/all');

            return dispatch({
                type: 'UPDATE_OBJECTS',
                data,
            });
        } catch (err) {
            if (err.response.status == 400) {
                message.error(err.response.data);
                return;
            }

            message.error('Ошибка. Попробуйте позже');
            return;
        }
    },
    addUser: (builder_id, email, pwd) => async () => {
        if (email && pwd) {
            try {
                await axios.post('/v1/admin/builders/add-user', {
                    email,
                    password: pwd,
                    builder_id,
                });

                message.success('Пользователь успешно добавлен');
                return;
            } catch (err) {
                if (err.response.status == 400) {
                    message.error(err.response.data);
                    return;
                }

                message.error('Ошибка. Попробуйте позже');
                return;
            }
        }

        message.error('Заполните поля');
    },
    getBuilders: (page, limit, search_text, type_select) => async (dispatch) => {
        dispatch({
            type: 'REQUEST_BUILDER_STATUS',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/builders', {
                params: {
                    page,
                    limit,
                    search_text,
                    type_select
                }
            });

            dispatch({
                type: 'UPDATE_BUILDERS',
                data: data,
            });

            dispatch({
                type: 'REQUEST_BUILDER_STATUS',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'REQUEST_BUILDER_STATUS',
                status: false,
            });

            message.error('Ошибка. Проверьте Ваши права администратора');
        }
    },
    addBuilder:
        (title, description, phone, year, email, history, idLogo) =>
        async (dispatch) => {
            if (title && year && idLogo && idLogo.id) {
                dispatch({
                    type: 'REQUEST_BUILDER_STATUS',
                    status: true,
                });

                try {
                    await axios.post('/v1/admin/builders', {
                        title,
                        description,
                        phone,
                        year,
                        email,
                        idLogo: idLogo.id,
                    });

                    dispatch({
                        type: 'REQUEST_BUILDER_STATUS',
                        status: false,
                    });

                    return history('/builders');
                } catch (err) {
                    console.error(1, err);
                    dispatch({
                        type: 'REQUEST_BUILDER_STATUS',
                        status: false,
                    });

                    message.error(
                        'Ошибка. Проверьте Ваши права администратора'
                    );
                    return;
                }
            }

            return message.warning('Заполните обязательные поля');
    },
    changeBuilder:
        (
            title,
            id,
            index,
            phone,
            desc,
            year,
            logoId,
            history,
            seoTitle,
            seoKeywords,
            seoDescription,
            email
        ) =>
        async (dispatch) => {
            if (title && phone && logoId && year && email) {
                try {
                    await axios.post('/v1/admin/builders/update', {
                        title,
                        id,
                        phone,
                        logoId,
                        year,
                        desc,
                        seoTitle,
                        seoKeywords,
                        seoDescription,
                        email,
                    });

                    dispatch({
                        type: 'CHANGE_BUILDER',
                        index,
                        title,
                        phone,
                        email,
                    });

                    message.success('Информация успешно обновлена');
                    return history('/builders');
                } catch (err) {
                    console.log(333, err);
                    message.error(
                        'Ошибка. Проверьте Ваши права администратора'
                    );
                    return;
                }
            }

            return message.warning('Заполните обязательные поля');
        },
    removeBuilder: (id, title) => async (dispatch) => {
        try {
            let resMes = window.confirm(`Застройщик ${title} будет удален`);

            if (resMes) {
                await axios.delete(`/v1/admin/builders/${id}`);

                dispatch({
                    type: 'UPDATE_BUILDERS_DICR',
                    id,
                });

                return message.success('Застройщик успешно удален');
            }
        } catch (err) {
            message.error('Ошибка. Проверьте Ваши права суперадмина');
        }
    },
};

export default auth;
