import React, { useState } from 'react';
import Ticker from 'react-ticker';
import { moment } from '@/Services';
import { Link } from 'react-router-dom';

const VTItem = ({ item, styles }) => {
    const [speed_text, set_speed_text] = useState(0);

    return (
        <Link
            key={item?._id}
            to={`/virtual-tours/edit/${item?._id}`}
            onMouseOver={() => {
                set_speed_text(5.6);
            }}
            onMouseOut={() => {
                set_speed_text(0);
            }}
        >
            <div className={styles.vt_item}>
                <Ticker speed={speed_text} style={{ marginRight: 20 }}>
                    {() => (
                        <b>
                            {item?.title} (
                            {item?.secondary_title || 'Не определено'}){' '}
                        </b>
                    )}
                </Ticker>
                <p>Просмотров: {item?.views || 0}</p>
                <p>Автор: {item?.author?.email || 'Не определен'}</p>
                <p>
                    Дата создания:{' '}
                    {moment(item?.createdAt).format('DD MMMM YYYY в HH:mm')}
                </p>
            </div>
        </Link>
    );
};

export default VTItem;
