import React from 'react';
import config from '@/Config';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, Popconfirm } from 'antd';
import styles from '../styles/index.module.scss';
import { EllipsisOutlined } from '@ant-design/icons';
import currencyFormatter from 'currency-formatter';

const Item = ({ item, dispatch, api }) => {
    const menuSettings = (
        <Menu>
            <Menu.Item key="edit_product" onClick={() => {}}>
                <Link to={`/library/products/edit/${item?._id}`}>
                    Редактировать
                </Link>
            </Menu.Item>
            <Menu.Item key="remove_product" onClick={() => {}}>
                <Popconfirm
                    title="Продукт будет удален"
                    onConfirm={() =>
                        dispatch(api.library.removeProduct(item?._id))
                    }
                    onCancel={() => {}}
                    okText="Удалить"
                    cancelText="Нет"
                    placement="bottomLeft"
                >
                    Удалить
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="category-item-block product-item-block">
            <Dropdown
                trigger={['click']}
                className={styles.Dropdown}
                overlay={menuSettings}
            >
                <EllipsisOutlined
                    onClick={(e) => e.preventDefault()}
                    rotate={90}
                    className={styles.userItemSettingIcon}
                />
            </Dropdown>

            <div className="product-item-block-overlay">
                {item?.photo?.url && (
                    <div
                        className="product-item-photo"
                        style={{
                            background: `url(${config.public}/300/250/${item?.photo?.url}) center / cover no-repeat`,
                        }}
                    ></div>
                )}
                <p>{item?.title}</p>
                {item?.price && (
                    <h6>
                        {currencyFormatter.format(item?.price, {
                            code: 'EUR',
                            symbol: '',
                        }) + '₸'}
                    </h6>
                )}
                <h6>Магазин: {item?.shop?.title || '-'}</h6>
            </div>
        </div>
    );
};

export default Item;
