import Games from '@/Containers/Admin/Games';
import SquidGame from '@/Containers/Admin/Games/pages/squidGame';

export default [
    {
        path: '/games',
        element: <Games />,
    },
    {
        path: '/games/squid-game',
        element: <SquidGame />,
    },
];
