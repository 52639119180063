import React, { useEffect } from 'react';
import styles from '../../../styles/index.module.scss';
import { Link, useParams } from 'react-router-dom';
import { api } from '@/Services';
import { useDispatch, useSelector } from 'react-redux';
import Item from './Helpers/item';
import AddListButton from './Helpers/add_list_button';

const Calculate = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const stateBudget = useSelector((state) => state.budget);

    useEffect(() => {
        dispatch(api.budget.getBudgetTemplate(params.id));
    }, []);

    return (
        <content className="cities-page-block">
            <div className={styles.calculateBlock}>
                <div className={styles.calculateBlockHeader}>
                    <h4>Настройка расчета бюджета</h4>
                    <Link to={`/apartments/flats/${params.id}/budget`}>
                        Посмотреть бюджет
                    </Link>
                </div>

                <AddListButton
                    zIndex={905}
                    type="DOORS"
                    title="Добавить дверь"
                    params={params}
                    styles={styles}
                    dispatch={dispatch}
                    api={api}
                />

                {stateBudget?.budget_template ? (
                    stateBudget.budget_template?.doors &&
                    stateBudget.budget_template?.doors.length ? (
                        stateBudget.budget_template?.doors.map(
                            (item, index) => (
                                <Item
                                    budget_template_info={
                                        stateBudget?.budget_template
                                    }
                                    type_render="DOOR"
                                    index={index}
                                    key={item._id}
                                    api={api}
                                    params={params}
                                    dispatch={dispatch}
                                    item={item}
                                />
                            )
                        )
                    ) : (
                        <h6>Вы не добавили ни одной двери</h6>
                    )
                ) : (
                    <></>
                )}

                <div className={styles.lineBlock}></div>

                <AddListButton
                    zIndex={904}
                    type="PREMISE"
                    title="Добавить помещение"
                    params={params}
                    styles={styles}
                    dispatch={dispatch}
                    api={api}
                />

                {stateBudget?.budget_template ? (
                    stateBudget.budget_template.premises &&
                    stateBudget.budget_template.premises.length ? (
                        stateBudget.budget_template.premises.map(
                            (item, index) => (
                                <Item
                                    budget_template_info={
                                        stateBudget?.budget_template
                                    }
                                    type_render="PREMISE"
                                    index={800 - index}
                                    key={item._id}
                                    api={api}
                                    params={params}
                                    dispatch={dispatch}
                                    item={item}
                                />
                            )
                        )
                    ) : (
                        <h6>Вы не добавили ни одного помещения</h6>
                    )
                ) : (
                    <></>
                )}
            </div>
        </content>
    );
};

export default Calculate;
