"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDrillStepDefaultConfig = exports.isEqualDrillSteps = exports.topojson2geojson = exports.getGeoAreaConfig = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var topojson = tslib_1.__importStar(require("topojson-client"));
var constants_1 = require("./constants");
var getGeoAreaConfig = function (geoArea) {
    var config = { url: constants_1.GEO_AREA_URL, type: 'topojson', extension: 'topo.json' };
    if ((0, util_1.isUndefined)(geoArea))
        return config;
    if (typeof geoArea === 'string') {
        config.url = geoArea;
    }
    else {
        config.url = (geoArea === null || geoArea === void 0 ? void 0 : geoArea.url) || constants_1.GEO_AREA_URL;
        config.type = geoArea.type;
        config.extension = geoArea.type === 'topojson' ? 'topo.json' : 'json';
    }
    return config;
};
exports.getGeoAreaConfig = getGeoAreaConfig;
var topojson2geojson = function (json) {
    try {
        var geojson = topojson.feature(json, 'json');
        return geojson;
    }
    catch (err) {
        throw new Error('Failed convert to geojson from topojson, topojson default layer name "json"');
    }
};
exports.topojson2geojson = topojson2geojson;
var isEqualDrillSteps = function (newSteps, oldSteps) {
    if (newSteps.length !== oldSteps.length) {
        return false;
    }
    for (var index = 0; index < newSteps.length; index++) {
        var _a = newSteps[index], level = _a.level, granularity = _a.granularity;
        if (oldSteps[index].level !== level || oldSteps[index].granularity !== granularity) {
            return false;
        }
    }
    return true;
};
exports.isEqualDrillSteps = isEqualDrillSteps;
var getDrillStepDefaultConfig = function (options) {
    var config = (0, util_1.pick)(options, ['source', 'color', 'style', 'state', 'label', 'tooltip']);
    return config;
};
exports.getDrillStepDefaultConfig = getDrillStepDefaultConfig;
