import axios from '../axios';
import {NotificationManager} from 'react-notifications';

const widgets = {
	banPlayer: (player_id, player_index) => async (dispatch) => {

		try {

			await axios.post('/v1/admin/games/squid-game-players/ban', {
				player_id
			});

			dispatch({
				type:'PLAYER_BAN_GAME',
				index:player_index
			});

		}catch(err){

			dispatch({
				type:'REQUEST_STATUS_GAMES',
				status:false
			});

			NotificationManager.error('Ошибка.');
			

		}

	},
	getPlayersOfSquidGame: (page) => async (dispatch) => {

		dispatch({
			type:'REQUEST_STATUS_GAMES',
			status:true
		});

		try{

			let { data } = await axios.get('/v1/admin/games/squid-game-players', {
				params:{
					page
				}
			});

			dispatch({
				type:'UPDATE_LIST_PLAYERS_GAME',
				data
			});

			dispatch({
				type:'REQUEST_STATUS_GAMES',
				status:false
			});

			

		}catch(err){

			dispatch({
				type:'REQUEST_STATUS_GAMES',
				status:false
			});

			NotificationManager.error('Ошибка.');
			

		}

	}
}

export default widgets;