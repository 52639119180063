import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { budget } from '@/Services/apollo/gqls';
import currencyFormatter from 'currency-formatter';
import CategoryItems from './Helpers/categories';
import { mdiArrowLeft, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { Loader } from '@/Components';

import styles from '@/Containers/Admin/Budget/styles/index.module.scss';

import SubProducts from '@/Services/Context/sub-products';

const BudgetListFinal = () => {
    const params = useParams();

    const [title, set_title] = useState('');
    const [range_price_status, set_range_price_status] = useState(false);

    const [price_square_meter, set_price_square_meter] = useState(0);
    const [square, set_square] = useState(0);

    const [status_form_add_product, set_status_form_add_product] =
        useState(false);
    const [sub_products, set_sub_products] = useState([]);

    let getBudget = useQuery(budget.getBudgetOfFlatTemplate, {
        fetchPolicy: 'network-only',
        variables: {
            flat_template_id: params.id,
            budget_type: 'EDIT',
        },
    });

    let loading = getBudget.loading;
    let data = {
        getCategoriesOfBudgetTemplate:
            getBudget?.data?.getBudgetOfFlatTemplate || null,
    };

    const [removeProductFromBudget, removeProductFromBudgetLoading] =
        useMutation(budget.removeProductFromBudget, {
            refetchQueries: [
                {
                    query: budget.getBudgetOfFlatTemplate,
                    variables: {
                        template: params.id,
                    },
                },
            ],
        });

    const procentRes = (price, discont) => {
        let result = Math.round((price / 100) * discont);
        return (
            currencyFormatter.format(price - result, {
                code: 'EUR',
                symbol: '',
            }) + '₸'
        );
    };

    useEffect(() => {
        if (data && data.getCategoriesOfBudgetTemplate) {
            set_title(data.getCategoriesOfBudgetTemplate.template.title);
            rangePrice();
        }
    }, [loading]);

    const rangePrice = () => {
        let { count_rooms } = data.getCategoriesOfBudgetTemplate.FlatTemplate;
        let { total_price, price_range } =
            data.getCategoriesOfBudgetTemplate.template;
        let rangePriceData = price_range;

        let priceSquareMeter = data?.getCategoriesOfBudgetTemplate?.FlatTemplate
            ?.square_of_budget
            ? total_price /
              data?.getCategoriesOfBudgetTemplate?.FlatTemplate
                  ?.square_of_budget
            : 0;

        set_square(
            data?.getCategoriesOfBudgetTemplate?.FlatTemplate
                ?.square_of_budget || 0
        );
        set_price_square_meter(priceSquareMeter);
        total_price = priceSquareMeter;

        if (
            count_rooms === 1 &&
            rangePriceData?.one_room &&
            rangePriceData?.one_room?.min &&
            rangePriceData?.one_room?.max
        ) {
            if (
                total_price > rangePriceData?.one_room?.min &&
                total_price < rangePriceData?.one_room?.max
            ) {
                set_range_price_status(true);
            }
        } else if (
            count_rooms === 2 &&
            rangePriceData?.two_room &&
            rangePriceData?.two_room?.min &&
            rangePriceData?.two_room?.max
        ) {
            if (
                total_price > rangePriceData?.two_room?.min &&
                total_price < rangePriceData?.two_room?.max
            ) {
                set_range_price_status(true);
            }
        } else if (
            count_rooms === 3 &&
            rangePriceData?.three_room &&
            rangePriceData?.three_room?.min &&
            rangePriceData?.three_room?.max
        ) {
            if (
                total_price > rangePriceData?.three_room?.min &&
                total_price < rangePriceData?.three_room?.max
            ) {
                set_range_price_status(true);
            }
        } else if (
            count_rooms >= 4 &&
            rangePriceData?.four_room &&
            rangePriceData?.four_room?.min &&
            rangePriceData?.four_room?.max
        ) {
            if (
                total_price > rangePriceData?.four_room?.min &&
                total_price < rangePriceData?.four_room?.max
            ) {
                set_range_price_status(true);
            }
        }
    };

    const updateSubProduct = (id) => {
        let searchProduct = sub_products.filter(
            (product) => product.id_product._id != id
        );
        set_sub_products([...searchProduct]);
    };

    return useMemo(
        () => (
            <SubProducts.Provider value={{ sub_products, set_sub_products }}>
                <content className={styles.mainBlock}>
                    {!loading ? (
                        <div className={styles.mainBlock}>
                            <div
                                className={`${styles.mainBlock} ${styles.mainBlockHead}`}
                            >
                                <Link
                                    className={`${styles.mainBlockHeadIconBack}`}
                                    to={`/apartments/flats/${params.id}/edit/calculate`}
                                >
                                    <Icon size={1} path={mdiArrowLeft} />
                                </Link>
                                <h1>
                                    Бюджет {title} (
                                    <span
                                        className={
                                            range_price_status
                                                ? styles.successPrice
                                                : styles.warningPrice
                                        }
                                    >
                                        {procentRes(
                                            data?.getCategoriesOfBudgetTemplate
                                                ?.template?.total_price || 0,
                                            0
                                        )}
                                    </span>
                                    ) | цена за м²:{' '}
                                    {currencyFormatter.format(
                                        price_square_meter || 0,
                                        {
                                            code: 'EUR',
                                            symbol: '',
                                        }
                                    ) + '₸ '}
                                    | площадь {Number(square).toFixed(2)}м²
                                </h1>
                            </div>

                            {sub_products && sub_products.length ? (
                                <div
                                    style={{ width: '100%', marginLeft: 0 }}
                                    className={styles.tableProducts}
                                >
                                    <Icon
                                        className={styles.backButton}
                                        path={mdiArrowLeft}
                                        size={1}
                                        onClick={() => {
                                            set_sub_products(null);
                                        }}
                                    />
                                    <table border="1">
                                        <thead>
                                            <tr className={styles.trHead}>
                                                <th>Наименование</th>
                                                <th>Описание</th>
                                                <th>Площ. Кол-во</th>
                                                <th>Ед. изм.</th>

                                                <th>Цена за ед.</th>
                                                <th>Скидка</th>
                                                <th>Сумма со скидкой</th>
                                                <th>
                                                    Название магазина, телефон,
                                                    адрес, фото товара
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sub_products.map((product) => {
                                                return (
                                                    <tr
                                                        className={
                                                            styles.trBody
                                                        }
                                                        key={
                                                            product._id +
                                                            `_${
                                                                Math.random() *
                                                                200000
                                                            }`
                                                        }
                                                    >
                                                        <td>
                                                            {
                                                                product
                                                                    .id_product
                                                                    .title
                                                            }
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .discount_description
                                                                ? product
                                                                      .id_product
                                                                      .discount_description
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.area_and_number
                                                                ? product.area_and_number
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.unit
                                                                ? product.unit
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .price
                                                                ? currencyFormatter.format(
                                                                      product
                                                                          .id_product
                                                                          .price,
                                                                      {
                                                                          code: 'EUR',
                                                                          symbol: '',
                                                                      }
                                                                  ) + '₸'
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .discount
                                                                ? product
                                                                      .id_product
                                                                      .discount +
                                                                  '%'
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            {product.id_product
                                                                .discount
                                                                ? procentRes(
                                                                      Number(
                                                                          product
                                                                              .id_product
                                                                              .price
                                                                      ),
                                                                      Number(
                                                                          product
                                                                              .id_product
                                                                              .discount
                                                                      )
                                                                  )
                                                                : '-'}
                                                        </td>
                                                        <td>
                                                            Название магазина,
                                                            телефон, адрес, фото
                                                            товара
                                                        </td>
                                                        <td>
                                                            <Icon
                                                                path={mdiDelete}
                                                                size={1}
                                                                onClick={() => {
                                                                    updateSubProduct(
                                                                        product
                                                                            .id_product
                                                                            ._id
                                                                    );

                                                                    removeProductFromBudget(
                                                                        {
                                                                            variables:
                                                                                {
                                                                                    fields: {
                                                                                        type: 'SUB',
                                                                                        product_id:
                                                                                            product
                                                                                                .id_product
                                                                                                ._id,
                                                                                    },
                                                                                },
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <CategoryItems
                                    removeProductFromBudgetLoading={
                                        removeProductFromBudgetLoading
                                    }
                                    removeProductFromBudget={
                                        removeProductFromBudget
                                    }
                                    openAddSubProduct={() => {
                                        set_status_form_add_product(true);
                                    }}
                                    styles={styles}
                                    template_id={params.id}
                                    data={
                                        data?.getCategoriesOfBudgetTemplate
                                            ?.categories || null
                                    }
                                />
                            )}
                        </div>
                    ) : (
                        <Loader height={250} />
                    )}
                </content>
            </SubProducts.Provider>
        ),
        [data, title, status_form_add_product, sub_products, loading]
    );
};

export default BudgetListFinal;
