import React, { useState, useEffect } from 'react';
import { Input, Popover } from 'antd';
import styles from '../../styles/custom-chess.module.scss';

const BlockSyncForm = ({ saveSync, data_sync_entrance, data_sync_id }) => {
    const [sync_entrance, set_sync_entrance] = useState(
        data_sync_entrance || ''
    );
    const [sync_id, set_sync_id] = useState(data_sync_id || '');

    useEffect(() => {
        saveSync(sync_entrance, sync_id);
    }, [sync_entrance, sync_id]);

    return (
        <div className={styles.form}>
            <Popover
                title="Укажите id блока застройщика"
                placement="topLeft"
                content=""
            >
                <Input
                    className={styles.formInput}
                    size="large"
                    placeholder="Укажите id блока застройщика"
                    value={sync_id}
                    onChange={(e) => {
                        set_sync_id(e.target.value);
                    }}
                />
            </Popover>
            <Popover
                title="Укажите Entrance (Только для bi group)"
                placement="topLeft"
                content=""
            >
                <Input
                    className={styles.formInput}
                    size="large"
                    placeholder="Укажите Entrance (Только для bi group)"
                    value={sync_entrance}
                    onChange={(e) => {
                        set_sync_entrance(e.target.value);
                    }}
                />
            </Popover>
        </div>
    );
};

export default BlockSyncForm;
