import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ApartmentСomplex from './Helpers/ApartmentСomplex';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../Services';
import styles from '../styles/index.module.scss';
import { Tabs } from 'antd';
import FlatTemplates from './Helpers/flat-templates';

const EditApartment = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const getApartmentState = useSelector((state) => state.apartments);

    const getStatePrograms = useSelector((state) =>
        (state?.statistic?.settings || []).filter(
            (item) => item.type == 'STATE_PROGRAMS'
        )
    );

    const getMortgage = useSelector((state) => state.statistic.mortgage);

    useEffect(() => {
        dispatch(api.apartments.getApartmentById(id));
        dispatch(api.cities.getCities());
        dispatch(api.builders.getBuilders(null, null, null, 'all'));
        dispatch(api.statistic.getMortgage());
        dispatch(api.statistic.settings());
        dispatch(api.flats.getTemplatesForRealestate(id));
        dispatch(api.builders.getAllObjects());
        dispatch(api.apartments.getVTByRealestate(id));
    }, []);

    return (
        <content className={`page-create-apartment ${styles.pageForm}`}>
            <Tabs>
                <Tabs.TabPane tab="Редактирование жк" key="1">
                    <h1>
                        Редактирование жк{' '}
                        {getApartmentState?.getApartmentForEdit?.title}
                    </h1>
                    {getApartmentState.request_apartments_status ? (
                        <div>
                            <h3>Подождите...</h3>
                        </div>
                    ) : getApartmentState.getApartmentForEdit && 
                        getStatePrograms && 
                        getStatePrograms.length &&
                        getMortgage &&
                        getMortgage.length ? (
                        <ApartmentСomplex
                            getMortgage={getMortgage}
                            getStatePrograms={getStatePrograms || null}
                            item={getApartmentState?.getApartmentForEdit}
                        />
                    ) : (
                        <div></div>
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Шаблоны" key="2">
                    <FlatTemplates realestate_id={id} />
                </Tabs.TabPane>
            </Tabs>
        </content>
    );
};

export default EditApartment;
