import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/index.module.scss';
import RealestateItems from './Helpers/realestate-items';
import { Button, Pagination, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/Services';
import FilterRealestate from './Helpers/filter';

const { Title } = Typography;

const Apartments = () => {
    const dispatch = useDispatch();

    const { realestate_count } = useSelector(state => state?.realestate);

    let [fields, set_fields] = useState({});
    let [page, set_page] = useState(1);
    let [limit, set_limit] = useState(30);

    const search = (fields, status_meta) => {

        set_fields(fields);

        return dispatch(api.realestate.searchRealestate({
            ...fields,
            page: 1,
            limit: 30
        }, status_meta));

    }

    const paginationEvent = (page, limit) => {

        set_page(page);
        set_limit(limit);

        return dispatch(api.realestate.searchRealestate({
            ...fields,
            page,
            limit
        }, false));

    }

    return (
        <div className={`page-apartment ${styles.pageContent}`}>
            <div className={styles.pageHeader}>
                <Title level={4}>Очереди и ЖК</Title>
                <Button size="large" type="primary">
                    <Link to="/apartments/create">Добавить очередь/жк</Link>
                </Button>
            </div>

            <FilterRealestate
                onSearch={search}
            />

            <RealestateItems/>

            <div className={styles.pagination}>
                <Pagination 
                    current={page}
                    pageSize={limit}
                    hideOnSinglePage={true}
                    total={realestate_count} 
                    onChange={paginationEvent}
                />
            </div>

        </div>
    );
};

export default Apartments;
