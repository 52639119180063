import React, { useState, useEffect, memo } from 'react';
import { Popover, Button, Input, Popconfirm } from 'antd';
import styles from '../../styles/custom-chess.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';

const FlatItem = ({
    item,
    addFlats,
    addPenthouse,
    //selectBoxes,
    //index,
    selectBox,
    api,
    updateFlatPlanModal,
}) => {

    const dispatch = useDispatch();
    const [select_box, set_select_box] = useState(false);
    const [visible, setVisible] = useState(false);
    const [sold, set_sold] = useState(item?.flat?.sold || false);
    const [inputValues, setInputValues] = useState({
        title: item?.flat?.title || '',
        count_rooms:
            item?.flat?.count_rooms || item?.flat?.template?.count_rooms || '',
        square_metres:
            item?.flat?.square_metres ||
            item?.flat?.template?.square_metres ||
            '',
        square_metre_price:
            item?.flat?.square_metre_price ||
            item?.flat?.template?.square_metre_price ||
            '',
        total_price:
            item?.flat?.total_price || item?.flat?.template?.total_price || '',
        floor: item?.flat?.floor || '',
        comment: item?.flat?.comment || '',
    });

    const [saveTimeout, setSaveTimeout] = useState(null);

    const { selected_boxes } = useSelector((state) => state.chess);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        let updateState = {
            ...inputValues,
            [name]: value,
        };

        if(name === 'total_price'){

            let calcPriceSquare = Math.round(Number(value) / Number(inputValues?.square_metres || 1));

            setInputValues((prevValues) => ({
                ...prevValues,
                square_metre_price: calcPriceSquare,
                [name]: value,
            }));

            updateState.square_metre_price = calcPriceSquare;

        } else if(name === 'square_metre_price'){

            let calcTotalPrice = Math.round(Number(value) * Number(inputValues?.square_metres || 1));

            setInputValues((prevValues) => ({
                ...prevValues,
                total_price: calcTotalPrice,
                [name]: value,
            }));

            updateState.total_price = calcTotalPrice;

        } else if(name === 'square_metres'){

            let calcTotalPrice = Math.round(Number(value) * Number(inputValues?.square_metre_price || 1));

            setInputValues((prevValues) => ({
                ...prevValues,
                total_price: calcTotalPrice,
                [name]: value,
            }));

            updateState.total_price = calcTotalPrice;

        } else {

            setInputValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));

        }

        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }
        setSaveTimeout(setTimeout(() => saveData(updateState), 1000));
    };

    useEffect(() => {
        setSaveTimeout({
            title: item?.flat?.title || '',
            count_rooms: item?.flat?.template?.count_rooms || '',
            square_metres: item?.flat?.template?.square_metres || '',
            square_metre_price: item?.flat?.template?.square_metre_price || '',
            total_price: item?.flat?.template?.total_price || '',
            floor: item?.flat?.floor || '',
            comment: item?.flat?.comment || '',
            sold: item?.flat?.sold || '',
        });
    }, []);

    const saveData = (data) => {
        dispatch(
            api.chess.updateFlat(
                {
                    ...data,
                    flat_id: item?.flat?._id,
                    sold: sold,
                    status: sold ? 'SOLD' : 'FREE',
                },
                item?.flat?.apartment_complex_id
            )
        );
    };

    const changeFlatSold = (status) => {
        dispatch(
            api.chess.updateFlat({
                ...inputValues,
                sold: status,
                status: !sold ? 'SOLD' : 'FREE',
                flat_id: item?.flat?._id,
            })
        );

        set_sold(status);
    };

    const deleteFlat = () => {
        set_select_box(false);
        dispatch(
            api.chess.deleteFlat({
                ...item?.flat,
                flat_id: item?.flat?._id,
            })
        );
    };

    const content = (
        <div className={styles.contextMenuBlock}>
            <Button className={styles.button} onClick={addFlats} type="primary">
                {selected_boxes > 1 ? 'Добавить квартиры' : 'Добавить квартиру'}
            </Button>
            {(selected_boxes === 2 || selected_boxes === 3) && (
                <Button
                    className={styles.button}
                    onClick={addPenthouse}
                    type="primary"
                >
                    Добавить пентхаус
                </Button>
            )}
        </div>
    );

    return (
        <Popover
            trigger={['contextMenu']}
            content={content}
            title="Выберите функцию добавления"
        >
            <div
                className={styles.box}
                style={{
                    background: select_box
                        ? '#1677ff'
                        : item?.flat && !sold
                        ? '#5BCD3E'
                        : item?.flat && sold
                        ? 'rgb(221,233,255)'
                        : '',
                    border: `1px solid ${
                        select_box
                            ? '#1677ff'
                            : item?.flat && !sold
                            ? '#5BCD3E'
                            : item?.flat && sold
                            ? 'rgb(221,233,255)'
                            : ''
                    }`,
                    gridArea: item?.flat && `flat_${item?.flat?._id}`,
                }}
                onClick={() => {
                    if (!item?.flat) {
                        set_select_box(!select_box);
                        selectBox(item);
                    }
                }}
            >
                {item?.flat && (
                    <div className={styles.flatForm}>
                        <div className={styles.buttonForm}>
                            <h1>{inputValues?.title || '-'}</h1>
                            <Button onClick={() => setVisible(true)}>
                                <PlusCircleOutlined /> Открыть форму
                            </Button>
                        </div>

                        {visible && (
                            <div
                                className={styles.blockFormFlat}
                                style={{
                                    background: select_box
                                        ? '#1677ff'
                                        : item?.flat && !sold
                                        ? '#5BCD3E'
                                        : item?.flat && sold
                                        ? 'rgb(221,233,255)'
                                        : '',
                                }}
                            >
                                <div className={styles.gorizontInputs}>
                                    <Popover
                                        content="Номер квартиры"
                                        trigger={['hover']}
                                        placement="left"
                                    >
                                        <Input
                                            placeholder="Номер квартиры"
                                            name="title"
                                            value={inputValues?.title}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                    <Popover
                                        content="Количество комнат"
                                        trigger={['hover']}
                                        placement="right"
                                    >
                                        <Input
                                            placeholder="Количество комнат"
                                            name="count_rooms"
                                            value={inputValues?.count_rooms}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                </div>
                                <div className={styles.gorizontInputs}>
                                    <Popover
                                        content="Площадь квартиры"
                                        trigger={['hover']}
                                        placement="left"
                                    >
                                        <Input
                                            placeholder="Площадь квартиры"
                                            name="square_metres"
                                            value={inputValues?.square_metres}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                    <Popover
                                        content="Цена за m2"
                                        trigger={['hover']}
                                        placement="right"
                                    >
                                        <Input
                                            placeholder="Цена за m2"
                                            name="square_metre_price"
                                            value={
                                                inputValues?.square_metre_price
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                </div>
                                <Popover
                                    content="Стоимость квартиры"
                                    trigger={['hover']}
                                    placement="left"
                                >
                                    <Input
                                        placeholder="Стоимость квартиры"
                                        name="total_price"
                                        value={inputValues?.total_price}
                                        onChange={handleInputChange}
                                    />
                                </Popover>
                                {inputValues?.floor === '' && (
                                    <Popover
                                        content="Этаж"
                                        trigger={['hover']}
                                        placement="left"
                                    >
                                        <Input
                                            placeholder="Этаж"
                                            name="floor"
                                            value={inputValues?.floor}
                                            onChange={handleInputChange}
                                        />
                                    </Popover>
                                )}

                                <Popover
                                    content="Комментарий"
                                    trigger={['hover']}
                                    placement="left"
                                >
                                    <Input
                                        placeholder="Комментарий"
                                        name="comment"
                                        value={
                                            item?.flat?.soldinputValues?.comment
                                        }
                                        onChange={handleInputChange}
                                    />
                                </Popover>

                                <Popover
                                    content="Статус квартиры. (нажмите, чтобы изменить статус)"
                                    trigger={['hover']}
                                    placement="left"
                                >
                                    <Button
                                        onClick={() => changeFlatSold(!sold)}
                                    >
                                        {sold ? 'Продано' : 'В продаже'}
                                    </Button>
                                </Popover>

                                <Popover
                                    content="Настройка планировка"
                                    trigger={['hover']}
                                    placement="left"
                                >
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            updateFlatPlanModal({
                                                flat_id: item?.flat?._id,
                                                status: true,
                                                form_flat_preview:
                                                    item?.flat?.template
                                                        ?.preview_photo,
                                                form_flat_plan:
                                                    item?.flat?.template
                                                        ?.plan_photo,
                                                flat_floor: item?.floor,
                                                flat_number: item?.flat?.title,
                                                realestate_id:
                                                    item?.flat
                                                        ?.apartment_complex_id?._id,
                                                block_id: item?.flat?.block_id?._id,
                                                coords: item?.flat?.area_points,
                                                polygon:
                                                    item?.flat
                                                        ?.area_points_for_css,
                                            })
                                        }
                                    >
                                        Планировка
                                    </Button>
                                </Popover>
                                <Popconfirm
                                    title="Вы действительно хотите удалить квартиру?"
                                    onConfirm={deleteFlat}
                                    okText="Удалить"
                                    cancelText="Нет"
                                >
                                    <Button danger type="primary">
                                        Удалить квартиру
                                    </Button>
                                </Popconfirm>
                                <Popover
                                    content="закрыть форму"
                                    trigger={['hover']}
                                    placement="top"
                                >
                                    <Button
                                        danger
                                        onClick={() => setVisible(false)}
                                        style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            right: '-30px',
                                            zIndex: 10,
                                        }}
                                        shape="circle"
                                        icon={<CloseOutlined />}
                                    />
                                </Popover>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Popover>
    );
};

export default memo(FlatItem);
