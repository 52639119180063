import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../../Services';
import styles from '../../styles/index.module.scss';
import selectLibrary from '../../../../../Services/Store/reducers/library/selector';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Input, Button, Form, Select } from 'antd';

const FormBonusCard = ({ visible, close, item }) => {
    const dispatch = useDispatch();
    const stateLibrary = useSelector(selectLibrary);

    const [state_form, set_state_form] = useState(null);

    useEffect(() => {
        if (item) {
            set_state_form({
                username: item?.username,
                realestate: item?.realestate,
                apartment_number: item?.apartment_number,
                type_card: item?.type_card?._id,
            });
        } else {
            set_state_form(null);
        }
    }, [item]);

    useEffect(() => {
        if (visible && !stateLibrary?.shops) {
            dispatch(api.library.getTypeCards());
        }
    }, [visible]);

    const addCard = async (data) => {
        if (!stateLibrary.status_cards_request) {
            let res = await dispatch(api.library.addBonusCard(data));

            if (res.status) {
                close();
            }
            return;
        }
    };

    const editCard = async (data) => {
        if (!stateLibrary.status_cards_request) {
            let res = await dispatch(
                api.library.editBonusCard(data, item?._id)
            );

            if (res.status) {
                close();
            }
            return;
        }
    };

    return (
        <Modal
            title={
                item
                    ? 'Форма редактирования бонусной карты'
                    : 'Форма добавления бонусной карты'
            }
            open={visible}
            footer={null}
            width="65%"
            onCancel={() => close()}
            destroyOnClose={true}
            zIndex={3000}
        >
            <Form
                onFinish={item ? editCard : addCard}
                autoComplete="off"
                initialValues={state_form}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'поле должно быть заполнено',
                        },
                    ]}
                >
                    <Input
                        size="large"
                        showCount
                        maxLength={50}
                        placeholder="ФИО"
                    />
                </Form.Item>

                <Form.Item
                    name="realestate"
                    rules={[
                        {
                            required: true,
                            message: 'поле должно быть заполнено',
                        },
                    ]}
                >
                    <Input
                        size="large"
                        showCount
                        maxLength={30}
                        placeholder="Жилой комплекс"
                    />
                </Form.Item>

                <Form.Item
                    name="apartment_number"
                    rules={[
                        {
                            required: true,
                            message: 'поле должно быть заполнено',
                        },
                    ]}
                >
                    <Input
                        size="large"
                        showCount
                        maxLength={5}
                        placeholder="Номер квартиры"
                    />
                </Form.Item>

                <Form.Item
                    name="type_card"
                    rules={[
                        {
                            required: true,
                            message: 'поле должно быть заполнено',
                        },
                    ]}
                >
                    <Select
                        size="large"
                        style={{ minWidth: '160px' }}
                        dropdownClassName={styles.zIndex}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                        }
                        placeholder="Выберите тип карты"
                    >
                        {stateLibrary.type_cards &&
                        stateLibrary.type_cards.length ? (
                            stateLibrary.type_cards.map((item) => (
                                <Select.Option
                                    key={item?._id}
                                    value={item?._id}
                                >
                                    {item?.name}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value={null}>
                                Информации не найдено
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {stateLibrary.status_cards_request ? (
                            <LoadingOutlined />
                        ) : item ? (
                            'Редактировать'
                        ) : (
                            'Добавить'
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default FormBonusCard;
