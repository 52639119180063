import React, { useState } from 'react';

import { DeleteOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const ItemTitleRoom = ({ item, styles, dispatch, api }) => {
    const [edit_status, set_edit_status] = useState(false);
    const [title, set_title] = useState(item.title);

    const deleteSetting = () => {
        dispatch(api.statistic.deleteSetting(item._id));
    };

    const updateSetting = () => {
        dispatch(api.statistic.updateSetting(item._id, title));
    };

    return (
        <div className={styles.itemSetting}>
            {edit_status ? (
                <Input
                    size="large"
                    value={title}
                    onChange={(value) => set_title(value.target.value)}
                />
            ) : (
                <h4>{item.value}</h4>
            )}

            {edit_status ? (
                <div className={styles.itemSettingControl}>
                    <CheckOutlined
                        onClick={updateSetting}
                        className={styles.itemSettingControlIcon}
                    />
                </div>
            ) : (
                <div className={styles.itemSettingControl}>
                    <EditOutlined
                        onClick={() => set_edit_status(true)}
                        className={styles.itemSettingControlIcon}
                    />
                    <DeleteOutlined
                        onClick={deleteSetting}
                        className={styles.itemSettingControlIcon}
                    />
                </div>
            )}
        </div>
    );
};

export default ItemTitleRoom;
