const MOList = [
    {
        value: 'PRODUCT',
        title: 'Товар',
    },
    {
        value: 'POL',
        title: 'Пол',
    },
    {
        value: 'POL_LAMINAT',
        title: 'Пол-Ламинат',
    },
    {
        value: 'POL_KERAMIKA',
        title: 'Пол-Керамика',
    },
    {
        value: 'PLINTUS',
        title: 'Плинтус',
    },
    {
        value: 'STENA',
        title: 'Стена',
    },
    {
        value: 'STENA_KRASKA',
        title: 'Стена-Краска',
    },
    {
        value: 'STENA_DEKOR',
        title: 'Стена-Декор',
    },
    {
        value: 'STENA_KERAM',
        title: 'Стена-Керамика',
    },
    {
        value: 'STENA_KERAM_DEK',
        title: 'Стена-Керам.Дек.',
    },
    {
        value: 'STENA_FARTUK',
        title: 'Стена-Фартук',
    },
    {
        value: 'POTOLOK',
        title: 'Потолок',
    },
    {
        value: 'GALTEL',
        title: 'Галтель',
    },
    {
        value: 'SHTORY',
        title: 'Шторы',
    },
    {
        value: 'DVER_STEKL_DVUH',
        title: 'Дверь-Стекл.Двух.',
    },
    {
        value: 'DVER_STEKL_ODN',
        title: 'Дверь-Стекл.Одн.',
    },
    {
        value: 'DVER_GLUH_DVUH',
        title: 'Дверь-Глух.Двух.',
    },
    {
        value: 'DVER_GLUH_ODN',
        title: 'Дверь-Глух.Одн.',
    },
];

const MPList = [
    {
        value: 'PRODUCT',
        title: 'Товар',
    },
    {
        value: 'POL_LAMINAT',
        title: 'Пол-Ламинат',
    },
    {
        value: 'POL_KERAMIKA',
        title: 'Пол-Керамика',
    },
    {
        value: 'PLINTUS',
        title: 'Плинтус',
    },
    {
        value: 'STENA_KRASKA',
        title: 'Стена-Краска',
    },
    {
        value: 'STENA_DEKOR',
        title: 'Стена-Декор',
    },
    {
        value: 'STENA_KERAM',
        title: 'Стена-Керамика',
    },
    {
        value: 'STENA_KERAM_DEK',
        title: 'Стена-Керам.Дек.',
    },
    {
        value: 'STENA_FARTUK',
        title: 'Стена-Фартук',
    },
    {
        value: 'POTOLOK',
        title: 'Потолок',
    },
    {
        value: 'GALTEL',
        title: 'Галтель',
    },
    {
        value: 'SHTORY',
        title: 'Шторы',
    },
    {
        value: 'MEBEL',
        title: 'Мебель',
    },
];

export { MOList, MPList };
