import React from 'react';
import config from '@/Config';
import Icon from '@mdi/react';

import {
  mdiEyeOff
} from '@mdi/js';

const ItemBank = ({
	item,
	styles,
	hideBank
}) => {
	return (
		<div className={styles.itemBank}>

			<span onClick={()=>hideBank({
				variables:{
					id:item._id
				}
			})} className={styles.DeleteBank}><Icon color="#fff" size={1} path={mdiEyeOff} /></span>

			<div className={styles.itemBankLeft}>
				<img src={`${config.public}100/100/${item.logo.url}`} />
			</div>
			<div className={styles.itemBankRight}>
				<p>Наименование: { item.title }</p>
				<p>Процентная ставка: { item.interest_rate ? item.interest_rate : '-' }</p>
				<p>Взнос: { item.down_payment ? item.down_payment : '-'}</p>
				<p>Срок кредита: { item.credit_period ? item.credit_period : '-'}</p>
				<p>API: { item.url_api ? item.url_api : '-'}</p>
			</div>
		</div>
	)
}

export default ItemBank;