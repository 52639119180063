import React from 'react';
import styles from './styles/main.module.scss';
import { Button, Tag, Popover } from 'antd';
import FlatItem from './Helpers/Flat';
import currencyFormatter from 'currency-formatter';

const ChessGrid = ({ block, set_modal_info }) => {
    const contentPopover = (type, flat = null, floor = null) => {
        return (
            <div className={styles.popoverButtons}>
                {type == 'FLAT' && (
                    <div className={styles.popoverContent}>
                        <p>
                            <b>Стоимость: </b>
                            <Tag color="geekblue">
                                {currencyFormatter.format(flat?.total_price, {
                                    code: 'EUR',
                                    symbol: '',
                                }) + '₸'}
                            </Tag>
                        </p>
                        <p>
                            <b>Стоимость за м²: </b>
                            <Tag color="geekblue">
                                {currencyFormatter.format(
                                    flat?.square_metre_price,
                                    {
                                        code: 'EUR',
                                        symbol: '',
                                    }
                                ) + '₸'}
                            </Tag>
                        </p>
                        <p>
                            <b>Продано: </b>
                            <Tag color="geekblue">
                                {flat?.sold ? 'Да' : 'Нет'}
                            </Tag>
                        </p>
                        <p>
                            <b>Вторичное: </b>
                            <Tag color="geekblue">
                                {flat?.secondary ? 'Да' : 'Нет'}
                            </Tag>
                        </p>
                    </div>
                )}

                <Button
                    className={styles.popoverButton}
                    onClick={() =>
                        set_modal_info({
                            open: true,
                            type_trigger: type,
                            flat,
                            block: block?._id || null,
                            floor,
                        })
                    }
                    type="primary"
                >
                    Открыть форму
                </Button>
            </div>
        );
    };

    return (
        <div className={styles.columnGrid}>
            <Popover
                placement="bottom"
                title={`Обновить цены блока ${block?.title}`}
                content={contentPopover('BLOCK')}
            >
                <h2>Блок - {block.title}</h2>
            </Popover>
            <div className={styles.columnGridChess}>
                {block.floors &&
                    block.floors.map((floor) => {
                        return (
                            <div
                                key={Math.round(Math.random() * 100000000)}
                                className={styles.columnGridFloor}
                            >
                                <Popover
                                    placement="right"
                                    title={`Обновить цены ${floor.floor} этажа, блока ${block?.title}`}
                                    content={contentPopover(
                                        'FLOOR',
                                        null,
                                        floor?.floor || null
                                    )}
                                >
                                    <span>{floor.floor}</span>
                                </Popover>
                                {floor.flats && floor.flats.length ? (
                                    floor.flats.map((flat) => {
                                        return (
                                            <FlatItem
                                                key={flat?._id}
                                                contentPopover={contentPopover}
                                                flat={flat}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className={styles.floorClear}>
                                        <h6>На этаже нет квартир</h6>
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default ChessGrid;
