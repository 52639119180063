import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles/index.module.scss';

const Logs = () => {
    let history = useNavigate();

    return (
        <div className="statistic-block">
            <h1>Отладчик</h1>

            <div className={styles.listLogsControll}>
                <section
                    onClick={() => history('/logs/sync')}
                    className={styles.ItemLog}
                >
                    <h4>Интеграция</h4>
                </section>
                <section
                    onClick={() => history('/logs/shops')}
                    className={styles.ItemLog}
                >
                    <h4>Магазины</h4>
                </section>
                <section
                    onClick={() => history('/logs/connections')}
                    className={styles.ItemLog}
                >
                    <h4>Посещаемость</h4>
                </section>
                <section
                    onClick={() => history('/logs/ai-search')}
                    className={styles.ItemLog}
                >
                    <h4>AI поиск</h4>
                </section>
                <section
                    onClick={() => history('/logs/xml-feeds')}
                    className={styles.ItemLog}
                >
                    <h4>XML feeds</h4>
                </section>
            </div>
        </div>
    );
};

export default Logs;
