import lang from 'suneditor/src/lang';

export const editorOptions = {
    buttonList: [
        ['font', 'fontSize', 'formatBlock'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['removeFormat'],
        ['fontColor', 'hiliteColor', 'align', 'list'],
        ['link'],
        ['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        ['undo', 'redo'],
    ],
    height: '270px',
    lang: lang?.ru
}