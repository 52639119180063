import { createSelector } from 'reselect';

const stateRealestate = (state) => state?.apartments?.apartments || [];
const allStateRealestate = (state) => state?.apartments || [];
const stateObjects = (state) => state?.apartments?.objects || [];

const selectRealestate = createSelector(stateRealestate, (items) => items);

export const selectObjects = createSelector(stateObjects, (items) => items);

export const allSelectRealestate = createSelector(
    allStateRealestate,
    (items) => items
);
export default selectRealestate;
