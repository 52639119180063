import React, { useState, useEffect } from 'react';
import { Button, Result, Pagination } from 'antd';
import styles from './styles/vt.module.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import { virtual_tours } from '@/Services/apollo/gqls';
import CreateVirtualTour from './forms/Create';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@/Components';
import { LoadingOutlined } from '@ant-design/icons';
import VTItem from './helpers/vt_item';

const VirtualTours = () => {
    const navigate = useNavigate();
    const [create_form, set_create_form] = useState(false);
    const [page, set_page] = useState(1);
    const [limit] = useState(25);

    let [getVirtualTours, { data, loading }] = useLazyQuery(
        virtual_tours.getVirtualTours,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    useEffect(() => {
        getVirtualTours({
            variables: {
                fields: {
                    page,
                    limit,
                },
            },
        });
    }, [page, limit]);

    let [createVirtualTour, createVirtualTourData] = useMutation(
        virtual_tours.createVirtualTours,
        {
            onCompleted: (data) => {
                return navigate(
                    `/virtual-tours/edit/${data?.createVirtualTour?._id}`
                );
            },
        }
    );

    return (
        <>
            <CreateVirtualTour
                visible={create_form}
                close={() => set_create_form(false)}
                createVirtualTour={createVirtualTour}
                loading={createVirtualTourData?.loading || false}
            />
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h1>
                        Виртуальные туры (туров в базе:{' '}
                        {loading ? (
                            <LoadingOutlined />
                        ) : data &&
                          data.getVirtualTours &&
                          data.getVirtualTours?.total ? (
                            data.getVirtualTours?.total
                        ) : (
                            0
                        )}
                        )
                    </h1>
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => set_create_form(!create_form)}
                    >
                        Создать тур
                    </Button>
                </div>
                <div className={styles.content}>
                    {loading ? (
                        <Loader height={550} />
                    ) : data &&
                      data.getVirtualTours?.data &&
                      data.getVirtualTours?.data.length ? (
                        <>
                            <div className={styles.vt_list}>
                                {data.getVirtualTours?.data.map((item) => (
                                    <VTItem
                                        item={item}
                                        key={item?._id}
                                        styles={styles}
                                    />
                                ))}
                            </div>
                            {data.getVirtualTours?.total > limit && (
                                <div className={styles.pagination}>
                                    <Pagination
                                        onChange={(val) => set_page(val)}
                                        current={page}
                                        showSizeChanger={false}
                                        pageSize={limit}
                                        defaultCurrent={1}
                                        total={data.getVirtualTours?.total}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <Result
                            status="404"
                            title="Информации нет"
                            subTitle="Виртуальных туров не найдено"
                            extra={null}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default VirtualTours;
