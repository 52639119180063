import React, { useState } from 'react';
import styles from '@/Containers/Admin/Budget/styles/index.module.scss';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import currencyFormatter from 'currency-formatter';
import ProductItem from './product-item';

const ItemCat = (props) => {
    const [hide, set_hide] = useState(false);
    const [sub_products, set_sub_products] = useState(null);
    const [parent_product_id, set_parent_product_id] = useState(null);

    const removeSubProduct = (id) => {
        let filterSubProducts = sub_products.filter(
            (e) => e.id_product._id != id
        );
        set_sub_products(filterSubProducts.length ? filterSubProducts : null);
        return;
    };

    if (
        !props.item.marker &&
        !props.item.premise_type &&
        props.item.status_view
    ) {
        return (
            <div
                className={`${styles.itemCategoryWithProducts}`}
                onClick={(event) => {
                    event.stopPropagation();
                    set_hide(!hide);
                    return;
                }}
            >
                <div className={styles.itemCategoryWithProductsHeader}>
                    <div className={styles.itemCategoryWithProductsHeaderTitle}>
                        <Icon
                            className={
                                hide
                                    ? styles.openArrowBottom
                                    : styles.openArrowRight
                            }
                            size={1.3}
                            path={mdiChevronRight}
                        />
                        <h4>{props.item.title}</h4>
                    </div>
                    <div>
                        {currencyFormatter.format(props.item.price, {
                            code: 'EUR',
                            symbol: '',
                        }) + '₸'}
                    </div>
                </div>

                <div
                    className={
                        (hide && sub_products && sub_products.length) ||
                        (hide &&
                            props.item.products &&
                            props.item.products.length)
                            ? styles.itemCategoryWithProductsArrow
                            : false
                    }
                >
                    {hide && sub_products && sub_products.length ? (
                        <div
                            className={`${styles.subProductItems} ${styles.tableProducts}`}
                        >
                            <span
                                onClick={(event) => {
                                    event.stopPropagation();
                                    set_sub_products(null);
                                }}
                            >
                                Закрыть подтовары
                            </span>
                            <table border="1">
                                <thead>
                                    <tr className={styles.trHead}>
                                        <th>Наименование</th>
                                        <th>Описание</th>
                                        <th>Площ. Кол-во</th>
                                        <th>Ед. изм.</th>

                                        <th>Цена за ед.</th>
                                        <th>Скидка</th>
                                        <th>Сумма со скидкой</th>
                                        <th>
                                            Название магазина, телефон, адрес,
                                            фото товара
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sub_products.map((product) => {
                                        return (
                                            <ProductItem
                                                key={product._id}
                                                parent_product_id={
                                                    parent_product_id
                                                }
                                                removeProductFromBudgetLoading={
                                                    props.removeProductFromBudgetLoading
                                                }
                                                cat_info={props.item}
                                                removeSubProduct={(id) =>
                                                    removeSubProduct(id)
                                                }
                                                sub
                                                removeProductFromBudget={
                                                    props.removeProductFromBudget
                                                }
                                                template_id={props.template_id}
                                                category_id={props.item._id}
                                                product={product}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : hide &&
                      props.item.products &&
                      props.item.products.length ? (
                        <div className={styles.tableProducts}>
                            <table border="1">
                                <thead>
                                    <tr className={styles.trHead}>
                                        <th>Наименование</th>
                                        <th>Описание</th>
                                        <th>Площ. Кол-во</th>
                                        <th>Ед. изм.</th>

                                        <th>Цена за ед.</th>
                                        <th>Скидка</th>
                                        <th>Сумма со скидкой</th>
                                        <th>
                                            Название магазина, телефон, адрес,
                                            фото товара
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.item.products.map((product) => {
                                        return (
                                            <ProductItem
                                                key={product._id}
                                                parent_product_id={
                                                    parent_product_id
                                                }
                                                removeProductFromBudgetLoading={
                                                    props.removeProductFromBudgetLoading
                                                }
                                                cat_info={props.item}
                                                main_parent_product_id={(id) =>
                                                    set_parent_product_id(id)
                                                }
                                                listSubProducts={(items) =>
                                                    set_sub_products(items)
                                                }
                                                openAddSubProduct={
                                                    props.openAddSubProduct
                                                }
                                                removeProductFromBudget={
                                                    props.removeProductFromBudget
                                                }
                                                template_id={props.template_id}
                                                category_id={props.item._id}
                                                product={product}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : hide &&
                      props.item.products &&
                      props.item.products.length == 0 ? (
                        <div className={styles.no_products_block}>
                            <h5>Товаров нет</h5>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className={`${styles.itemCategorySubCats} ${
                        hide ? styles.itemCategorySubCatsOpened : false
                    }`}
                >
                    {props.item.child_categories &&
                    props.item.child_categories.length ? (
                        props.renderItemCat
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default ItemCat;
