const initialState = {
    list: [],
    roles: [],

    page: 0,
    FR: false,

    realtors: [],
    realtor: null,

    request_status_users: false,
    request_status_roles: false,

    realtor_companies: [],
    realtor_company_leads: [],
    info_user_company: null,
    info_company: null,
    realtor_leads_request: false,

    edit_user: null,
    edit_user_drawer: false,
    request_user_tools: false,
    access_data_service: [],
    request_access_data_service: false,
};

const users = (state = initialState, action) => {
    switch (action.type) {

        case 'REQUEST_ACCESS_DATA_SERVICE':
            return {
                ...state,
                request_access_data_service: action.status || false,
            };

        case 'ACCESS_DATA_SERVICE':
            return {
                ...state,
                access_data_service: action.data || [],
            };

        case 'DATA_USER_TOOLS_INFO':
            return {
                ...state,
                edit_user: action.data || null,
            };

        case 'DRAWER_USER_TOOLS_INFO':
            return {
                ...state,
                edit_user_drawer: action.status || false,
            };

        case 'REQUEST_USER_TOOLS_INFO':
            return {
                ...state,
                request_user_tools: action.status || false,
            };

        case 'REALTOR_LEAD_UPDATE_LIST':
            return {
                ...state,
                realtor_company_leads: action.data || [],
            };

        case 'REALTOR_INFO_COMPANY':
            return {
                ...state,
                info_user_company: action.user || null,
            };

        case 'REALTOR_LEAD_UPDATE_COMPANIES':
            return {
                ...state,
                realtor_companies: action.data || [],
            };

        case 'REALTOR_LEAD_REQUEST':
            return {
                ...state,
                realtor_leads_request: action.status,
            };

        case 'NEW_ROLE_USER': {
            let getSearchRole =
                state.roles && state.roles.length
                    ? state.roles.filter(
                          (role) => role?._id == String(action.role)
                      )
                    : [];

            return {
                ...state,
                list: state.list.map((user) => {
                    if (user?._id == String(action.id)) {
                        if (getSearchRole.length) {
                            user.role = getSearchRole[0];
                        }
                    }
                    return user;
                }),
            };
        }

        case 'BLOCK_USER':
            return {
                ...state,
                list: state.list.map((user) => {
                    if (user?._id == String(action.id)) {
                        user.is_banned = action.status;
                    }
                    return user;
                }),
            };

        case 'UPDATE_ROLES':
            return {
                ...state,
                roles: action.data,
            };

        case 'REQUEST_STATUS_ROLES':
            return {
                ...state,
                request_status_roles: action.status,
            };

        case 'DELETE_REALTOR':
            return {
                ...state,
                realtors:
                    state.realtors.length > 1
                        ? state.realtors.filter((item) => item._id != action.id)
                        : [],
            };

        case 'SET_REALTOR':
            return {
                ...state,
                realtor: action.data,
            };

        case 'CLEAR_REALTOR':
            return {
                ...state,
                realtor: null,
            };

        case 'UPDATE_REALTORS':
            return {
                ...state,
                realtors: action.data,
            };

        case 'USERS_FR':
            return {
                ...state,
                FR: true,
            };

        case 'USERS_PAGE':
            return {
                ...state,
                page: action.page,
            };

        case 'UPDATE_USERS':
            return {
                ...state,
                list: action.clean
                    ? [...action.data]
                    : [...state.list, ...action.data],
            };

        case 'REQUEST_STATUS_USERS':
            return {
                ...state,
                request_status_users: action.status,
            };

        default:
            return state;
    }
};

export default users;
