import React, { useState, useEffect } from 'react';

import { Button, Input } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import styles from '../../styles/index.module.scss';

import ItemMortgage from './item-morgage';

const Mortgage = ({ api }) => {
    const getMortgage = useSelector((state) => state.statistic.mortgage);

    const dispatch = useDispatch();

    const [status, setStatus] = useState(false);
    const [mortgage_type, set_mortgage_type] = useState('');

    useEffect(() => {
        status && dispatch(api.statistic.getMortgage());
    }, [status]);

    const addMortgage = () => {
        dispatch(api.statistic.addMortgage(mortgage_type));
    };

    return (
        <div className={styles.Mortgage_wrapper}>
            <div className="section-settings-block-head">
                <h3>Ипотека</h3>
                <Button
                    type="primary"
                    onClick={() => {
                        setStatus(!status);
                    }}
                >
                    {status ? 'Скрыть' : 'Показать'}
                </Button>
            </div>

            {status ? (
                <div className={styles.form}>
                    <div className={styles.form_add_setting}>
                        <Input
                            size="large"
                            value={mortgage_type}
                            onChange={(val) =>
                                set_mortgage_type(val.target.value)
                            }
                            type="text"
                            placeholder="Название"
                        />
                        <Button size="large" onClick={addMortgage}>
                            Добавить
                        </Button>
                    </div>

                    <hr />

                    <div className={styles.list_mortgage}>
                        {getMortgage && getMortgage.length ? (
                            getMortgage.map((val) => {
                                return (
                                    <ItemMortgage
                                        key={val._id}
                                        dispatch={dispatch}
                                        api={api}
                                        item={val}
                                        styles={styles}
                                    />
                                );
                            })
                        ) : (
                            <h2>Список пуст</h2>
                        )}
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default Mortgage;
