import _ from 'lodash';

import dashboard from './dashboard';
import apartments from './apartments';
import blog from './blog';
import budget from './budget';
import builders from './builders';
import cities from './cities';
import credit from './credit';
import games from './games';
import library from './library';
import logs from './logs';
import pages from './pages';
import realtors from './realtors';
import redirects from './redirects';
import settings from './settings';
import users from './users';
import virtualTours from './virtual-tours';
import widgets from './widgets';
import analytics from './analytics';

const components = _.concat(
    dashboard,
    apartments,
    blog,
    budget,
    builders,
    cities,
    credit,
    games,
    library,
    logs,
    pages,
    realtors,
    redirects,
    settings,
    users,
    virtualTours,
    widgets,
    analytics
);

export default components;
