import React from 'react';
import { Form, Input, Button, Popconfirm, Tag } from 'antd';
import styles from '../../styles/custom-chess.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const BlockForm = ({
    api,
    realestate_id,
    type,
    title,
    floors_count,
    max_num_apartments_floor,
    area_points,
    area_points_for_css,
    sync_id,
    sync_entrance,
}) => {
    const dispatch = useDispatch();
    const stateChess = useSelector((state) => state.chess);

    const eventForm = (fields) => {
        if (!stateChess?.request_status_create_block) {
            dispatch(
                api.chess.formBlock({
                    ...fields,
                    area_points: area_points || [],
                    area_points_string: area_points_for_css || '',
                    sync_entrance: sync_entrance || '',
                    sync_id: sync_id || '',
                    typeForm: type,
                    realestate_id,
                    block_id: stateChess?.edit_block_id,
                })
            );
        }
    };

    const removeBlock = () => {
        if (!stateChess?.request_status_create_block) {
            dispatch(
                api.chess.removeBlock({
                    block_id: stateChess?.edit_block_id,
                    realestate_id,
                })
            );
        }
    };

    const eventFloorToolsButton = (type) => {
        if (!stateChess?.request_status_create_block) {
            dispatch(
                api.chess.formBlock({
                    typeForm: type,
                    block_id: stateChess?.edit_block_id,
                    realestate_id,
                })
            );
        }
    };

    return (
        <>
            <Form
                name="basic"
                initialValues={{
                    title: title || '',
                    floors_count: floors_count || '',
                    max_num_apartments_floor: max_num_apartments_floor || '',
                }}
                onFinish={eventForm}
                className={styles.form}
            >
                <Form.Item
                    label="Наименование блока"
                    labelCol={{
                        span: 6,
                        offset: 0,
                    }}
                    labelAlign="left"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Заполните наименование блока',
                        },
                    ]}
                    className={styles.formInput}
                >
                    <Input
                        size="large"
                        placeholder="Введите наименование блока"
                    />
                </Form.Item>

                <Form.Item
                    label="Количество этажей"
                    labelCol={{
                        span: 6,
                        offset: 0,
                    }}
                    labelAlign="left"
                    name="floors_count"
                    rules={[
                        {
                            required: true,
                            message: 'Укажите количество этажей',
                        },
                    ]}
                    className={styles.formInput}
                >
                    <Input
                        size="large"
                        disabled={type === 'edit' ? true : false}
                        placeholder="Укажите количество этажей"
                    />
                </Form.Item>

                <Form.Item
                    label="Количество квартир на этаже"
                    labelCol={{
                        span: 6,
                        offset: 0,
                    }}
                    labelAlign="left"
                    name="max_num_apartments_floor"
                    rules={[
                        {
                            required: true,
                            message:
                                'Укажите максимальное количество квартир на этаже',
                        },
                    ]}
                    className={styles.formInput}
                >
                    <Input
                        size="large"
                        disabled={type === 'edit' ? true : false}
                        placeholder="Укажите максимальное количество квартир на этаже"
                    />
                </Form.Item>

                <Form.Item>
                    <Button size="medium" type="primary" htmlType="submit">
                        {stateChess?.request_status_create_block ? (
                            <LoadingOutlined />
                        ) : type === 'create' ? (
                            'Добавить'
                        ) : (
                            'редактировать'
                        )}
                    </Button>
                </Form.Item>
            </Form>
            {type === 'edit' && (
                <>
                    <div className={styles.blockDelete}>
                        <h5>Управление этажами</h5>
                        <div className={styles.blockFloorButtons}>
                            <Popconfirm
                                title="Верхний этаж будет удален"
                                onConfirm={() =>
                                    eventFloorToolsButton('removeFloor')
                                }
                                okText="Удалить"
                                cancelText="Нет"
                            >
                                <Button type="primary" danger>
                                    {stateChess?.request_status_create_block ? (
                                        <LoadingOutlined />
                                    ) : (
                                        'Удалить верхний этаж'
                                    )}
                                </Button>
                            </Popconfirm>
                            <Popconfirm
                                title="Количество квартир на этаже будет уменьшено на 1 (удаляется крайняя правая колонка)"
                                onConfirm={() =>
                                    eventFloorToolsButton('removeColumn')
                                }
                                okText="Удалить"
                                cancelText="Нет"
                            >
                                <Button type="primary" danger>
                                    {stateChess?.request_status_create_block ? (
                                        <LoadingOutlined />
                                    ) : (
                                        'Уменьшить количество квартир на этаже на 1'
                                    )}
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className={styles.blockDelete}>
                        <h5>Удаление блока</h5>
                        <div className={styles.blockDeleteDescription}>
                            <Tag color="red">
                                Блок и связанные квартиры будут удалены
                            </Tag>
                        </div>
                        <Popconfirm
                            title="Блок будет удален"
                            description="При удалении блока удаляются квартиры связанные с этим блоком"
                            onConfirm={removeBlock}
                            okText="Удалить"
                            cancelText="Нет"
                        >
                            <Button type="primary" danger>
                                Удалить блок
                            </Button>
                        </Popconfirm>
                    </div>
                </>
            )}
        </>
    );
};

export default BlockForm;
