"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DotDensityLayer = void 0;
var tslib_1 = require("tslib");
var utils_1 = require("../../utils");
var dot_layer_1 = require("../dot-layer");
var LAYER_OPTIONS_KEYS = ['color', 'size', 'style', 'state', 'animate'];
var DotDensityLayer = /** @class */ (function (_super) {
    tslib_1.__extends(DotDensityLayer, _super);
    function DotDensityLayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图层类型
         */
        _this.type = dot_layer_1.DotLayer.LayerType.DotDensity;
        /**
         * 图层是否具有交互属性
         */
        _this.interaction = false;
        return _this;
    }
    /**
     * 获取默认配置
     */
    DotDensityLayer.prototype.getDefaultOptions = function () {
        return (0, utils_1.deepAssign)({}, dot_layer_1.DotLayer.DefaultOptions, {
            shape: 'dot',
            size: 1,
        });
    };
    /**
     * 图层配置项 Keys
     */
    DotDensityLayer.LayerOptionsKeys = dot_layer_1.DotLayer.LayerConfigkeys.concat(LAYER_OPTIONS_KEYS);
    return DotDensityLayer;
}(dot_layer_1.DotLayer));
exports.DotDensityLayer = DotDensityLayer;
