import axios from '../axios';
import { NotificationManager } from 'react-notifications';
import { message } from 'antd';

const statistic = {
    updateSetting: (id, title, seo_content) => async (dispatch) => {
        if (title) {
            try {
                let { data } = await axios.put(`/v1/admin/statistic/settings`, {
                    title,
                    id,
                    seo_content,
                });

                dispatch(statistic.settings());

                return message.success(data);
            } catch (err) {
                return NotificationManager.error(err.response.data);
            }
        }

        return message.warning('Заполните поля');
    },
    savesetting: (title, type) => async (dispatch) => {
        if (title) {
            try {
                let { data } = await axios.post(`/v1/admin/statistic/settings`, {
                    title,
                    type,
                });

                dispatch(statistic.settings());

                return message.success(data);
            } catch (err) {
                return NotificationManager.error(err.response.data);
            }
        }

        return message.warning('Заполните поля');
    },
    deleteSetting: (id) => async (dispatch) => {
        try {
            await axios.delete(`/v1/admin/statistic/settings`, {
                params: {
                    id,
                },
            });

            dispatch(statistic.settings());
        } catch (err) {
            return NotificationManager.error(err.response.data);
        }
    },
    deleteMortgage: (id) => async (dispatch) => {
        let resMes = window.confirm(
            'Удалив информацию, с квартир также удалится статус'
        );

        if (resMes) {
            try {
                let { data } = await axios.post(
                    `/v1/admin/statistic/settings/delete-mortgage`,
                    {
                        id,
                    }
                );

                dispatch({
                    type: 'UPDATE_MORGAGE',
                    data,
                });
            } catch (err) {
                return NotificationManager.error(
                    'Ошибка. Проверьте Ваши права администратора'
                );
            }
        }
    },
    editMortgage: (id, title) => async (dispatch) => {
        try {
            let { data } = await axios.post(
                `/v1/admin/statistic/settings/edit-mortgage`,
                {
                    title,
                    id,
                }
            );

            dispatch({
                type: 'UPDATE_MORGAGE',
                data,
            });
        } catch (err) {
            NotificationManager.error(
                'Ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    addMortgage: (value) => async (dispatch) => {
        try {
            let { data } = await axios.post(
                `/v1/admin/statistic/settings/add-mortgage`,
                {
                    title: value,
                }
            );

            dispatch({
                type: 'UPDATE_MORGAGE',
                data,
            });
        } catch (err) {
            NotificationManager.error(
                'Ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    getCounts: () => async (dispatch) => {
        try {
            let { data } = await axios.post(`/v1/admin/analytics/counts`);

            dispatch({
                type: 'UPDATE_ANALYTIC_INFORMATION_COUNTS',
                data,
            });
        } catch (err) {
            NotificationManager.error(
                'Ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    getAnalytics:
        (complexParam, typeParam, sortParam, type_page, shop = false) =>
        async (dispatch) => {
            dispatch({
                type: 'STATUS_REQUEST_ANALYTIC',
                status: true,
            });

            let query = {};

            console.log(
                3333,
                complexParam,
                typeParam,
                sortParam,
                type_page,
                shop
            );

            if (complexParam && complexParam != '') {
                query.complex = complexParam;
            }

            if (typeParam && typeParam != '') {
                query.type = typeParam;
            }

            if (sortParam) {
                query.sort = sortParam;
            }

            if (type_page) {
                query.type_page = type_page;
            }

            if (shop) {
                query.shop = shop;
            }

            try {
                let { data } = await axios.post(`/v1/admin/analytics`, query);

                dispatch({
                    type: 'UPDATE_ANALYTIC_INFORMATION',
                    data: data.analytics,
                });

                dispatch({
                    type: 'UPDATE_ANALYTIC_INFORMATION_COUNTS',
                    data: {
                        calls: data.callsCount,
                        applications: data.applicationsCount,
                    },
                });

                dispatch({
                    type: 'STATUS_REQUEST_ANALYTIC',
                    status: false,
                });
            } catch (err) {
                dispatch({
                    type: 'STATUS_REQUEST_ANALYTIC',
                    status: false,
                });

                NotificationManager.error(
                    'Ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    get:
        (type_map = '') =>
        async (dispatch) => {
            dispatch({
                type: 'STATUS_REQUEST_STATISTIC',
                status: true,
            });

            try {
                let { data } = await axios.get('/v1/admin/statistic', {
                    params: {
                        type_map,
                    },
                });

                dispatch({
                    type: 'UPDATE_STATISTIC_INFORMATION',
                    data,
                });

                dispatch({
                    type: 'STATUS_REQUEST_STATISTIC',
                    status: false,
                });
            } catch (err) {
                dispatch({
                    type: 'STATUS_REQUEST_STATISTIC',
                    status: false,
                });

                NotificationManager.error(
                    'Ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    getMortgage: () => async (dispatch) => {
        try {
            let { data } = await axios.get(
                '/v1/admin/statistic/settings/get-mortgage'
            );

            dispatch({
                type: 'UPDATE_MORGAGE',
                data,
            });
        } catch (err) {
            NotificationManager.error(
                'Ошибка. Проверьте Ваши права администратора'
            );
        }
    },
    settings:
        (type = '') =>
        async (dispatch) => {
            let params = {};

            if (type) {
                params.type = type;
            }

            dispatch({
                type: 'STATUS_REQUEST_SETTINGS',
                status: true,
            });

            try {
                let { data } = await axios.get('/v1/admin/statistic/settings', {
                    params,
                });

                dispatch({
                    type: 'UPDATE_STATISTIC_SETTINGS',
                    data,
                });

                dispatch({
                    type: 'STATUS_REQUEST_SETTINGS',
                    status: false,
                });
            } catch (err) {
                dispatch({
                    type: 'STATUS_REQUEST_SETTINGS',
                    status: false,
                });

                NotificationManager.error(
                    'Ошибка. Проверьте Ваши права администратора'
                );
            }
        },
    saveSettingPrices: (fields) => async (dispatch) => {
        let { standart, business, comfort, premium } = fields;

        if (standart && business && comfort && premium) {
            dispatch({
                type: 'STATUS_REQUEST_SETTINGS',
                status: true,
            });

            try {
                let { data } = await axios.post(
                    '/v1/admin/statistic/settings/prices',
                    {
                        fields,
                    }
                );

                dispatch({
                    type: 'UPDATE_STATISTIC_SETTINGS',
                    data,
                });

                dispatch({
                    type: 'STATUS_REQUEST_SETTINGS',
                    status: false,
                });

                NotificationManager.success('Информация обновлена');
                return;
            } catch (err) {
                dispatch({
                    type: 'STATUS_REQUEST_SETTINGS',
                    status: false,
                });

                NotificationManager.error('Ошибка, попробуйте позже');
                return;
            }
        }

        NotificationManager.error('Заполните все поля');
    },
    saveSettingFreeDownload: (status) => async (dispatch) => {
        dispatch({
            type: 'STATUS_REQUEST_SETTINGS',
            status: true,
        });

        try {
            let { data } = await axios.post(
                '/v1/admin/statistic/settings/free-download',
                {
                    status,
                }
            );

            dispatch({
                type: 'UPDATE_STATISTIC_SETTINGS',
                data,
            });

            dispatch({
                type: 'STATUS_REQUEST_SETTINGS',
                status: false,
            });

            NotificationManager.success('Информация обновлена');
        } catch (err) {
            dispatch({
                type: 'STATUS_REQUEST_SETTINGS',
                status: false,
            });

            NotificationManager.error('Ошибка, попробуйте позже');
        }
    },
};

export default statistic;
