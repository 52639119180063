import React, { useEffect, useRef, useState } from 'react';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

let configSun = {
    height: 240,
    buttonList: [
        [
            'undo',
            'redo',
            'font',
            'fontSize',
            'formatBlock',
            'paragraphStyle',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'textStyle',
            'removeFormat',
            'outdent',
            'indent',
            'align',
            'horizontalRule',
            'list',
            'lineHeight',
            'table',
            'link',
            'image' /** 'video', 'audio' 'math', */, // You must add the 'katex' library at options to use the 'math' plugin.
            /** 'imageGallery', */ // You must add the "imageGalleryUrl".
            'fullScreen',
            'showBlocks',
            'codeView',
            'preview',
        ], //'print', 'save'
    ],
};

const Editor = ({ dispatch, setContent, seo_content, api }) => {
    const [toggleCodeView, setToggleCodeView] = useState(false);

    const editorRef = useRef();
    const content = seo_content || '';

    const handleImageUploadBefore = (files, info, uploadHandler) => {
        (async () => {
            let imgSrc = await dispatch(
                api.files.uploadFile(files[0], 'IMAGE_FOR_POSTS')
            );

            const response = {
                result: [
                    {
                        url: imgSrc,
                        name: files[0].name,
                        size: files[0].size,
                    },
                ],
            };

            uploadHandler(response);
        })();

        uploadHandler();
    };

    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    useEffect(() => {
        console.log(666, toggleCodeView);
    }, [toggleCodeView]);

    return (
        <div>
            <SunEditor
                onImageUploadBefore={handleImageUploadBefore}
                setOptions={configSun}
                setContents={content}
                defaultValue={content}
                onChange={(val) => setContent(val)}
                getSunEditorInstance={getSunEditorInstance}
                toggleCodeView={(statusCodeView) => {
                    setToggleCodeView(statusCodeView);
                }}
            />
        </div>
    );
};

export default Editor;
