"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mappingLayer = exports.getDefaultState = void 0;
var util_1 = require("@antv/util");
var layer_1 = require("../../adaptor/layer");
var defaultHighlightColor = '#2f54eb';
var defaultState = {
    active: {
        fill: false,
        stroke: defaultHighlightColor,
        lineWidth: 1.5,
        lineOpacity: 0.8,
    },
    select: {
        fill: false,
        stroke: defaultHighlightColor,
        lineWidth: 1.5,
        lineOpacity: 0.8,
    },
};
var getDefaultState = function (state) {
    if ((0, util_1.isUndefined)(state)) {
        return defaultState;
    }
    if (state.active === false) {
        defaultState.active = Object.assign(defaultState.active, { fill: false, stroke: false });
    }
    else if (typeof state.active === 'object') {
        if (state.active.fill === false) {
            defaultState.active.fill = false;
        }
        else if (typeof state.active.fill === 'string') {
            defaultState.active.fill = state.active.fill;
        }
        if (state.active.stroke === false) {
            defaultState.active.stroke = false;
        }
        else if (typeof state.active.stroke === 'string') {
            defaultState.active.stroke = state.active.stroke;
        }
        if (typeof state.active.lineWidth === 'number') {
            defaultState.active.lineWidth = state.active.lineWidth;
        }
        if (typeof state.active.lineOpacity === 'number') {
            defaultState.active.lineOpacity = state.active.lineOpacity;
        }
    }
    if (state.select === false) {
        defaultState.select = Object.assign(defaultState.select, { fill: false, stroke: false });
    }
    else if (typeof state.select === 'object') {
        if (state.select.fill === false) {
            defaultState.select.fill = false;
        }
        else if (typeof state.select.fill === 'string') {
            defaultState.select.fill = state.select.fill;
        }
        if (state.select.stroke === false) {
            defaultState.select.stroke = false;
        }
        else if (typeof state.select.stroke === 'string') {
            defaultState.select.stroke = state.select.stroke;
        }
        if (typeof state.select.lineWidth === 'number') {
            defaultState.select.lineWidth = state.select.lineWidth;
        }
        if (typeof state.select.lineOpacity === 'number') {
            defaultState.select.lineOpacity = state.select.lineOpacity;
        }
    }
    return defaultState;
};
exports.getDefaultState = getDefaultState;
function mappingLayer(layer, strokeLayer, highlightLayer, selectFillLayer, selectStrokeLayer, options) {
    var color = options.color, style = options.style, state = options.state;
    var defaultState = (0, exports.getDefaultState)(state);
    var fillState = {
        active: defaultState.active.fill === false ? false : { color: defaultState.active.fill },
        select: false,
    };
    var fillStyle = { opacity: style === null || style === void 0 ? void 0 : style.opacity };
    var fillBottomColor = style === null || style === void 0 ? void 0 : style.fillBottomColor;
    var strokeSize = style === null || style === void 0 ? void 0 : style.lineWidth;
    var strokeColor = style === null || style === void 0 ? void 0 : style.stroke;
    var strokeStyle = { opacity: style === null || style === void 0 ? void 0 : style.lineOpacity, dashArray: style === null || style === void 0 ? void 0 : style.lineDash, lineType: style === null || style === void 0 ? void 0 : style.lineType };
    /**
     * 映射填充面图层
     */
    // shape
    layer_1.MappingLayer.shape(layer, 'fill');
    // color
    color && layer_1.MappingLayer.color(layer, color);
    // style
    fillStyle && layer_1.MappingLayer.style(layer, fillStyle);
    // state
    fillState && layer_1.MappingLayer.state(layer, fillState);
    // bottomColor
    fillBottomColor && layer.setBottomColor(fillBottomColor);
    /**
     * 描边图层
     */
    // shape
    layer_1.MappingLayer.shape(strokeLayer, 'line');
    // size
    strokeSize && layer_1.MappingLayer.size(strokeLayer, strokeSize);
    // color
    strokeColor && layer_1.MappingLayer.color(strokeLayer, strokeColor);
    // style
    strokeStyle && layer_1.MappingLayer.style(strokeLayer, strokeStyle);
    /**
     * 高亮图层
     */
    if (defaultState.active.stroke) {
        var color_1 = defaultState.active.stroke;
        var size = defaultState.active.lineWidth || strokeSize;
        var style_1 = { opacity: defaultState.active.lineOpacity };
        // shape
        layer_1.MappingLayer.shape(highlightLayer, 'line');
        // size
        size && layer_1.MappingLayer.size(highlightLayer, size);
        // color
        color_1 && layer_1.MappingLayer.color(highlightLayer, color_1);
        // style
        style_1 && layer_1.MappingLayer.style(highlightLayer, style_1);
    }
    /**
     * 选中填充图层
     */
    if (defaultState.select.fill) {
        var color_2 = defaultState.select.fill;
        // shape
        layer_1.MappingLayer.shape(selectFillLayer, 'fill');
        // color
        color_2 && layer_1.MappingLayer.color(selectFillLayer, color_2);
        // style
        fillStyle && layer_1.MappingLayer.style(selectFillLayer, fillStyle);
        // state
        layer_1.MappingLayer.state(selectFillLayer, { select: false, active: false });
    }
    /**
     * 选中描边图层
     */
    if (defaultState.select.stroke) {
        var color_3 = defaultState.select.stroke;
        var size = defaultState.select.lineWidth || strokeSize;
        var style_2 = { opacity: defaultState.select.lineOpacity };
        // shape
        layer_1.MappingLayer.shape(selectStrokeLayer, 'line');
        // size
        size && layer_1.MappingLayer.size(selectStrokeLayer, size);
        // color
        color_3 && layer_1.MappingLayer.color(selectStrokeLayer, color_3);
        // style
        style_2 && layer_1.MappingLayer.style(selectStrokeLayer, style_2);
    }
}
exports.mappingLayer = mappingLayer;
