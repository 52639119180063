import React, { useState } from 'react';
import styles from '../styles/index.module.scss';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Switch from 'react-input-switch';
import config from '../../../../Config';

const flatItem = ({ flat, deleteFlat, soldFlat, promotionFlat }) => {
    const [load_image, set_load_image] = useState(true);

    const menuSettings = (
        <Menu>
            <Menu.Item key="1">
                <Popconfirm
                    title="Квартира будет удалена?"
                    onConfirm={() => deleteFlat(flat?._id)}
                    onCancel={() => {}}
                    okText="Удалить"
                    cancelText="Нет"
                    placement="bottomLeft"
                >
                    Удалить квартиру
                </Popconfirm>
            </Menu.Item>

            <Menu.Item
                key="promotion-flat"
                onClick={() => promotionFlat(flat?._id)}
            >
                {flat?.promotion ? 'Не участвует в акции' : 'Участвует в акции'}
            </Menu.Item>

            <Menu.Item key="2">
                <Link to={`/apartments/flats/${flat.template._id}/edit`}>
                    Редактировать шаблон
                </Link>
            </Menu.Item>

            {flat?.template?.virtual_tour ? (
                <Menu.Item key="3">
                    <Link
                        to={`/virtual-tours/edit/${flat?.template?.virtual_tour}`}
                    >
                        Редактировать 3D тур
                    </Link>
                </Menu.Item>
            ) : (
                <></>
            )}

            <Menu.Item key="4">
                <Link to={`/apartments/edit/${flat.apartment_complex_id?._id}`}>
                    Редактировать ЖК
                </Link>
            </Menu.Item>

            <Menu.Item key="5">
                <Link
                    to={`/apartments/blocks/${flat.apartment_complex_id?._id}`}
                >
                    Редактировать блоки ЖК
                </Link>
            </Menu.Item>

            <Menu.Item key="6">
                <Link
                    to={`/apartments/blocks/${flat.block_id?._id}/add-floors/${flat.apartment_complex_id?._id}`}
                >
                    Редактировать этажи блока
                </Link>
            </Menu.Item>

            <Menu.Item key="7">
                <a
                    target="_blank"
                    href={`https://logovo.kz/${flat?.apartment_complex_id?.slug}/${flat?.block_id?.title}/${flat?._id}`}
                >
                    Посмотреть на logovo.kz
                </a>
            </Menu.Item>

            <Menu.Item key="8">
                <a
                    target="_blank"
                    href={`https://viewer.logovo.kz/${flat?._id}`}
                >
                    Посмотреть 3D тур
                </a>
            </Menu.Item>
        </Menu>
    );

    let defaultHomeUrl =
        flat?.template?.preview_photo && flat?.template?.preview_photo.url
            ? `${config.public}270/250/${flat?.template?.preview_photo?.url}`
            : require('../../../../assets/default-home.png');

    return (
        <div className={`block-item-flat ${styles.flatItem}`}>
            {flat?.promotion ? (
                <div className={styles.iconPromotion}></div>
            ) : (
                <></>
            )}

            <div className={styles.menuButton}>
                <Dropdown
                    trigger={['click']}
                    className={styles.Dropdown}
                    overlay={menuSettings}
                >
                    <EllipsisOutlined
                        onClick={(e) => e.preventDefault()}
                        rotate={90}
                        className={styles.settingIcon}
                    />
                </Dropdown>
            </div>

            <div className={styles.blockImage}>
                <div
                    className={`${styles.blockImageLoader} ${
                        !load_image ? styles.blockImageLoaded : {}
                    }`}
                >
                    <LoadingOutlined />
                </div>
                <img
                    onLoad={() => set_load_image(false)}
                    src={defaultHomeUrl}
                />
            </div>

            {/*
                <section
                style={{
                    background: `url(${defaultHomeUrl}) center / contain no-repeat`,
                }}
                className={styles.flatItemPhoto}
            ></section>
                */}

            <div className="block-item-flat-information">
                <p>{flat?.title}</p>
                <h5>
                    <span>ЖК:</span>{' '}
                    {flat?.apartment_complex_id
                        ? flat?.apartment_complex_id?.title
                        : '-'}
                </h5>
                <h5>
                    <span>БЛОК:</span>{' '}
                    {flat?.block_id ? (
                        flat?.block_id.title
                    ) : (
                        <b style={{ color: 'red' }}>Не найден или удален</b>
                    )}
                </h5>
                <h5>
                    <span>Кол-во комнат:</span> {flat?.template?.count_rooms}
                </h5>
                <h5>
                    <span>Адрес:</span>{' '}
                    {flat?.apartment_complex_id
                        ? flat?.apartment_complex_id?.address
                        : '-'}
                </h5>
                <h5>
                    <span>Застройщик:</span>{' '}
                    {flat?.apartment_complex_id
                        ? flat?.apartment_complex_id?.builder?.title
                        : '-'}
                </h5>
                <h5>
                    <span>Этаж:</span> {flat?.floor}
                </h5>
                <h5>
                    <span>Просмотров:</span> {flat?.views || 0}
                </h5>
            </div>

            <div className="block-item-flat-information block-item-flat-buttons block-item-flat-buttons-controll-section">
                <div
                    className={`block-item-flat-buttons-left ${styles.itamFlat}`}
                ></div>
                <div className="block-item-flat-buttons-right">
                    <p>Продано</p>
                    <Switch
                        value={flat.sold ? 1 : 0}
                        onChange={() =>
                            soldFlat(flat.sold ? !flat.sold : 1, flat._id)
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default flatItem;
