import React from 'react';

const CheckLoadedFile = ({
							 type,
							 state,
							 files
						 }) => {
	return (
		<div className="check-files-block">
			{
				type == 'MATERIAL_BUDGET_EXCEL' ?
					<div>
						{
							state.MATERIAL_BUDGET_EXCEL ?
								<div>
									<h4>Файл загружен</h4>

									{
										files && files.length ?
											files.map((item) => {
												if(item && item.type == 'MATERIAL_BUDGET_EXCEL') {
													return (
														<div>
															<b>Цена: {item.price ? item.price + ' тг.' : '-'}</b>
															<p>Ссылка: {item.url ? item.url : '-'}</p>
														</div>
													)
												}
											})
											:
											<></>
									}
								</div>
								:
								<div></div>
						}
					</div>
					: type == 'MATERIAL_BUDGET_PDF' ?
						<div>
							{
								state.MATERIAL_BUDGET_PDF ?
									<div>
										<h4>Файл загружен</h4>

										{
											files && files.length ?
												files.map((item) => {
													if(item && item.type == 'MATERIAL_BUDGET_PDF') {
														return (
															<div>
																<b>Цена: {item.price ? item.price + ' тг.' : '-'}</b>
																<p>Ссылка: {item.url ? item.url : '-'}</p>
															</div>
														)
													}
												})
												:
												<></>
										}
									</div>
									:
									<div></div>
							}
						</div>
						: type == 'FLAT_ARRANGEMENT_AUTOCAD' ?
							<div>
								{
									state.FLAT_ARRANGEMENT_AUTOCAD ?
										<div>
											<h4>Файл загружен</h4>

											{
												files && files.length ?
													files.map((item) => {
														if(item && item.type == 'FLAT_ARRANGEMENT_AUTOCAD') {
															return (
																<div>
																	<b>Цена: {item.price ? item.price + ' тг.' : '-'}</b>
																	<p>Ссылка: {item.url ? item.url : '-'}</p>
																</div>
															)
														}
													})
													:
													<></>
											}
										</div>
										:
										<div></div>
								}
							</div>
							: type == 'APARTMENT_MODEL_3DMAX' ?
								<div>
									{
										state.APARTMENT_MODEL_3DMAX ?
											<div>
												<h4>Файл загружен</h4>

												{
													files && files.length ?
														files.map((item) => {
															if(item && item.type == 'APARTMENT_MODEL_3DMAX') {
																return (
																	<div>
																		<b>Цена: {item.price ? item.price + ' тг.' : '-'}</b>
																		<p>Ссылка: {item.url ? item.url : '-'}</p>
																	</div>
																)
															}
														})
														:
														<></>
												}
											</div>
											:
											<div></div>
									}
								</div>
								: type == 'APARTMENT_DESIGN_REVIT' ?
									<div>
										{
											state.APARTMENT_DESIGN_REVIT ?
												<div>
													<h4>Файл загружен</h4>

													{
														files && files.length ?
															files.map((item) => {
																if(item && item.type == 'APARTMENT_DESIGN_REVIT') {
																	return (
																		<div>
																			<b>Цена: {item.price ? item.price + ' тг.' : '-'}</b>
																			<p>Ссылка: {item.url ? item.url : '-'}</p>
																		</div>
																	)
																}
															})
															:
															<></>
													}
												</div>
												:
												<div></div>
										}
									</div>
									: type == 'APARTMENT_DESIGN_PDF' ?
										<div>
											{
												state.APARTMENT_DESIGN_PDF ?
													<div>
														<h4>Файл загружен</h4>

														{
															files && files.length ?
																files.map((item) => {
																	if(item && item.type == 'APARTMENT_DESIGN_PDF') {
																		return (
																			<div>
																				<b>Цена: {item.price ? item.price + ' тг.' : '-'}</b>
																				<p>Ссылка: {item.url ? item.url : '-'}</p>
																			</div>
																		)
																	}
																})
																:
																<></>
														}
													</div>
													:
													<div></div>
											}
										</div>
										:
										<div></div>
			}
		</div>
	)
}

export default CheckLoadedFile;