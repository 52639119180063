"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Flow = void 0;
var tslib_1 = require("tslib");
var util_1 = require("@antv/util");
var plot_1 = require("../../core/plot");
var constants_1 = require("./constants");
var arc_layer_1 = require("../../layers/arc-layer");
var dot_layer_1 = require("../../layers/dot-layer");
var text_layer_1 = require("../../layers/text-layer");
var layer_group_1 = require("../../core/layer/layer-group");
var Flow = /** @class */ (function (_super) {
    tslib_1.__extends(Flow, _super);
    function Flow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * 图表类型
         */
        _this.type = plot_1.Plot.PlotType.Flow;
        return _this;
    }
    /**
     * 获取默认配置
     */
    Flow.prototype.getDefaultOptions = function () {
        return Flow.DefaultOptions;
    };
    /**
     * 创建图层
     */
    Flow.prototype.createLayers = function (source) {
        this.flowLayer = new arc_layer_1.ArcLayer(tslib_1.__assign({ name: 'flowLayer', source: source }, (0, util_1.pick)(this.options, arc_layer_1.ArcLayer.LayerOptionsKeys)));
        var layerGroup = new layer_group_1.LayerGroup([this.flowLayer]);
        if (this.options.radiation) {
            this.radiationLayer = this.createRadiationLayer(source);
            layerGroup.addLayer(this.radiationLayer);
        }
        if (this.options.label) {
            this.labelLayer = this.createLabelLayer(source, this.options.label);
            layerGroup.addLayer(this.labelLayer);
        }
        return layerGroup;
    };
    /**
     * 解析流向图起终点数据
     */
    Flow.prototype.parserPointData = function (source) {
        // const pointMap = {};
        var data = [];
        source.data.dataArray.forEach(function (item) {
            var coordinates = item.coordinates;
            // [startPoint, endPoint]
            var _a = tslib_1.__read(coordinates, 2), endPoint = _a[1];
            // if (isUndefined(pointMap[startPoint.toString()])) {
            //   data.push({ ...item, coordinates: startPoint });
            //   pointMap[startPoint.toString()] = true;
            // }
            data.push(tslib_1.__assign(tslib_1.__assign({}, item), { coordinates: endPoint }));
        });
        return data;
    };
    /**
     * 创建辐射圈图层
     */
    Flow.prototype.createRadiationLayer = function (source) {
        var _this = this;
        var data = this.parserPointData(source);
        var _a = this.options.radiation || {}, _b = _a.enabled, enabled = _b === void 0 ? true : _b, color = _a.color, _c = _a.size, size = _c === void 0 ? 20 : _c, _d = _a.shape, shape = _d === void 0 ? 'circle' : _d, _e = _a.animate, animate = _e === void 0 ? true : _e;
        var radiationLayer = new dot_layer_1.DotLayer({
            name: 'radiationLayer',
            source: {
                data: data,
                parser: { type: 'json', coordinates: 'coordinates' },
            },
            visible: enabled,
            color: color,
            size: size,
            shape: shape,
            animate: animate,
        });
        var updateCallback = function () {
            var data = _this.parserPointData(_this.source);
            radiationLayer.layer.setData(data);
        };
        source.on('update', updateCallback);
        radiationLayer.on('remove', function () {
            source.off('update', updateCallback);
        });
        return radiationLayer;
    };
    /**
     * 创建数据标签图层
     */
    Flow.prototype.createLabelLayer = function (source, label) {
        var _this = this;
        var data = this.parserPointData(source);
        var _a = this.options, visible = _a.visible, minZoom = _a.minZoom, maxZoom = _a.maxZoom, _b = _a.zIndex, zIndex = _b === void 0 ? 0 : _b;
        var labelLayer = new text_layer_1.TextLayer(tslib_1.__assign({ name: 'labelLayer', source: {
                data: data,
                parser: { type: 'json', coordinates: 'coordinates' },
            }, visible: visible, minZoom: minZoom, maxZoom: maxZoom, zIndex: zIndex + 0.1 }, label));
        var updateCallback = function () {
            var data = _this.parserPointData(_this.source);
            labelLayer.layer.setData(data);
        };
        source.on('update', updateCallback);
        labelLayer.on('remove', function () {
            source.off('update', updateCallback);
        });
        return labelLayer;
    };
    /**
     * 更新图层
     */
    Flow.prototype.updateLayers = function (options) {
        var flowLayerConfig = (0, util_1.pick)(options, arc_layer_1.ArcLayer.LayerOptionsKeys);
        this.flowLayer.update(flowLayerConfig);
        if (options.radiation) {
            if (this.radiationLayer) {
                var radiation = tslib_1.__assign(tslib_1.__assign({}, options.radiation), { visible: options.radiation.enabled });
                this.radiationLayer.update(radiation);
            }
            else {
                this.radiationLayer = this.createRadiationLayer(this.source);
                this.layerGroup.addLayer(this.radiationLayer);
            }
        }
        this.updateLabelLayer(this.source, options.label, this.options, this.labelLayer);
    };
    /**
     * 实现 legend 配置项
     */
    Flow.prototype.getLegendOptions = function () {
        var colorLegendItems = this.flowLayer.getColorLegendItems();
        if (colorLegendItems.length !== 0) {
            return { type: 'category', items: colorLegendItems };
        }
        return {};
    };
    /**
     * 默认配置项
     */
    Flow.DefaultOptions = constants_1.DEFAULT_OPTIONS;
    return Flow;
}(plot_1.Plot));
exports.Flow = Flow;
